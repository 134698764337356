import { Button, styled } from '@mui/material';

export const PageW = styled('div')`
  padding-left: 88px;
  position: relative;
  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 16px;
  }
`;

export const ColumnsW = styled('div')`
  display: flex;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const PageHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  max-width: 1062px;
  @media (max-width: 1023px) {
    margin-bottom: 16px;
  }
`;

export const Column = styled('div')`
  display: flex;
  flex-direction: column;
  width: 507px;
  min-width: 507px;
  margin-right: 48px;
  @media (max-width: 1023px) {
    width: 100%;
    min-width: 0px;
  }
`;

// TODO: move to shared component - EditButton
export const ButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const ActivationStatusValueW = styled('div')`
  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StatusButton = styled(Button)`
  text-transform: none;
  font-size: 12px;
  line-height: 14px;
  border-radius: 8px;
  box-shadow: none;
  padding: 3px 8px;
  background: ${({ theme }) => theme.palette.primary.p040};
  @media (max-width: 1023px) {
    margin-left: 8px;
  }
`;

export const StatusText = styled('div')`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  &.info {
    color: ${({ theme }) => theme.palette.info.main};
  }
  &.warning {
    color: ${({ theme }) => theme.palette.danger.main};
  }
`;
