import { TextFieldProps } from '@mui/material/TextField';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';

import { EmailValidationParams } from 'api/types';

import { TextField } from '../TextField';
import { InputHint, InputW } from './styled';

type TextFieldControllerProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> & {
    maxLength?: number;
    format?: (value: string) => unknown;
    hint?: string;
    emailTrigger?: boolean;
    checkEmailExsist?: UseMutateAsyncFunction<
      AxiosResponse,
      unknown,
      EmailValidationParams,
      unknown
    >;
  };

export function TextFieldController<T extends FieldValues>({
  control,
  name,
  rules,
  maxLength,
  format,
  hint,
  emailTrigger,
  checkEmailExsist,
  ...otherProps
}: TextFieldControllerProps<T>) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    ...otherProps,
  });

  const [isFocused, setIsFocused] = useState(false);
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  return (
    <InputW>
      <TextField
        onChange={(e) => {
          format ? onChange(format(e.target.value)) : onChange(e);
        }}
        onBlur={() => {
          onBlur();
          const validate = validateEmail(value);
          console.log('validate', validate);
          if (emailTrigger && checkEmailExsist && validate) {
            checkEmailExsist({ email: value });
          }
          setIsFocused(false);
        }}
        onFocus={() => setIsFocused(true)}
        value={value}
        name={name}
        inputRef={ref}
        error={Boolean(error)}
        helperText={error && error.message}
        autoComplete="off"
        inputProps={{
          maxLength,
        }}
        {...otherProps}
      />
      {hint && isFocused && <InputHint>{hint}</InputHint>}
    </InputW>
  );
}
