import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { API_HANDLERS } from 'api/apiHandlers';
import { requestForToken } from 'api/firebase';

interface UseActivateTokenReturn {
  isLoading: boolean;
  refetch: () => void;
}

export function useActivateToken(): UseActivateTokenReturn {
  const [token, setToken] = useState<string>();

  useEffect(() => {
    (async () => {
      const token = await requestForToken();
      setToken(token);
    })();
  }, []);

  const { isLoading, refetch } = useQuery(
    ['activateToken'],
    () => API_HANDLERS.WEBPUSH.SUBSCRIBE({ token: localStorage.getItem('webPushToken') as string }),
    {
      enabled: !!token,
    },
  );

  return {
    isLoading,
    refetch,
  };
}
