import { ReactElement } from 'react';

import { ReactComponent as PhoneIcon } from 'assets/icons/profileSet/call.svg';
import { ReactComponent as CrownIcon } from 'assets/icons/profileSet/crown.svg';
import { ReactComponent as HouseIcon } from 'assets/icons/profileSet/house.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/profileSet/sms.svg';
import { ReactComponent as StarIcon } from 'assets/icons/profileSet/star.svg';

export const ICONS: Record<string, ReactElement> = {
  phone: <PhoneIcon />,
  email: <EmailIcon />,
  position: <StarIcon />,
  address: <HouseIcon />,
  date: <CrownIcon />,
};
