import { Button, Typography, styled } from '@mui/material';

export const AuthorName = styled(Typography)`
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  bottom: -18px;
  left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 500px) {
    width: 200px;
  }

  @media (max-width: 400px) {
    width: 120px;
  }

  @media (max-width: 1023px) {
    bottom: -25px;
  }
`;

export const PublishedDateW = styled(Typography)`
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  bottom: -18px;
  right: 16px;
  @media (max-width: 1023px) {
    bottom: -25px;
  }
`;

export const ActionsW = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const RemoveButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
