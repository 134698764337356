import { ReactElement, RefObject } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactionStats } from 'api';
import { ReactComponent as ProcessIcon } from 'assets/icons/process-automation.svg';
import { ReactionsWrapper } from 'components/ReactionsWrapper';
import { usePostDetailsPage } from 'features/postDetails/containers/PostDetailsPage/usePostDetailsPage';
import { useModal } from 'hooks';
import { InViewItemRef } from 'types';

import { PostItemActions } from '../../PostItemActions';
import { ReactionConfirmDialog } from '../../ReactionConfirmDialog';
import { PollResults } from '../PollResults';
import { IconW, LeftSide, PostTitle, PostW, RightSide, StrongW } from '../styled';
import { PollDescription, Container } from './styled';

interface PollPostProps {
  postRef: RefObject<HTMLDivElement>;
  lastItemRef?: InViewItemRef;
  handlePostClick?: () => void;
  comments_count?: number;
  id: string;
  postId: string;
  reactions?: ReactionStats;
}

export function PollPost({
  postRef,
  lastItemRef,
  handlePostClick,
  comments_count,
  id,
  postId,
  reactions,
}: PollPostProps): ReactElement {
  const { t } = useTranslation();
  const { handleOpenModal, handleCloseModal, isOpen } = useModal();

  const { post } = usePostDetailsPage(id);
  const options = post?.poll?.options || [];

  return (
    <Container ref={postRef}>
      <IconW>
        <ProcessIcon />
      </IconW>

      <PostW ref={lastItemRef} onClick={handlePostClick} isAutomatedPost>
        <LeftSide>
          <PostTitle>
            <Trans
              i18nKey="post.listItem.pollPost.titleFormatted"
              values={{
                title: post?.title,
              }}
            >
              The poll &quot;<StrongW>{post?.title}</StrongW>&quot; is finished!
            </Trans>
          </PostTitle>
          <PollDescription>
            <div>{t('post.listItem.pollPost.description')}</div>
          </PollDescription>

          <PollResults options={options} />

          <ReactionsWrapper
            reactions={reactions}
            handleOpenModal={handleOpenModal}
            id={postId}
            isAutoPost
          />
        </LeftSide>

        <RightSide>
          <PostItemActions isPreview={false} commentsCount={comments_count} />
        </RightSide>
      </PostW>

      <ReactionConfirmDialog
        title={t('reactions.title')}
        onClose={handleCloseModal}
        postId={postId}
        open={isOpen}
      />
    </Container>
  );
}
