import { RefObject, createRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface UseListScrollToReturn {
  refs: { [key: string]: RefObject<HTMLElement> };
  activeItemId?: string;
}

export function useListScrollTo(list: { id: string }[]): UseListScrollToReturn {
  const { hash } = useLocation();
  const activeItemId = hash.slice(1);

  const refs = list.reduce((acc: { [key: string]: RefObject<HTMLElement> }, item) => {
    acc[item.id] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (activeItemId && refs[activeItemId]) {
      refs[activeItemId].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [activeItemId, refs]);

  return {
    refs,
    activeItemId,
  };
}
