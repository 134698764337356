import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { PostListItem as Post } from 'api';
import { ReactComponent as EditIcon } from 'assets/icons/containerActions/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { Avatar, ConfirmDialog } from 'components';
import { useAuth, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { DocumentsType } from 'types';
import { toCapitalize } from 'utils';

import { useDocumentActions } from './hooks/useDocumentActions';
import { Actions } from './styled';

type PostListItemProps = Pick<Post, 'author' | 'level' | 'title'> & {
  documentId: string;
  tag?: string;
};

export const DocumentActions = ({ author, documentId, tag, level, title }: PostListItemProps) => {
  const { isManager, user } = useAuth();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const { isOpenDeleteModal, handleCloseDeleteModal, handleOpenDeleteModal, onDeleteDocument } =
    useDocumentActions(documentId);

  const { avatar, first_name, id } = author;
  const navigate = useNavigate();

  const editLink = tag
    ? Links.resources.documents.edit.documentId
    : Links.profile.privateDocuments.edit.documentId;
  const onNavigateToEditDocument = () => {
    navigate(
      generatePath(editLink, {
        documentId,
      }),
    );
  };

  const dialogTitle = t('document.list.confirmRemoveModalTitle', { docTitle: title });
  const dialogSubtitle = tag ? toCapitalize(tag) : t('profile.privateDocuments.title');

  const isPublicShow = isManager || user?.id === author.id;
  const isPrivateShow = user?.id === author.id || (isManager && level === DocumentsType.SHARED);

  const isShowButton = tag ? isPublicShow : isPrivateShow;

  return (
    <Actions>
      {isShowButton && (
        <IconButton
          onClick={onNavigateToEditDocument}
          sx={{ opacity: '0.4', paddingTop: '0px', paddingRight: isMobile ? '6px' : '8px' }}
        >
          <EditIcon />
        </IconButton>
      )}
      {isShowButton && (
        <IconButton
          onClick={handleOpenDeleteModal}
          sx={{
            paddingTop: isMobile ? '0px' : '8px',
            paddingLeft: isMobile ? '6px' : '8px',
            marginBottom: '8px',
          }}
        >
          <TrashIcon />
        </IconButton>
      )}
      {!isMobile && (
        <Avatar
          wrapperSx={{ marginTop: 'auto', cursor: 'pointer' }}
          userId={id}
          filename={avatar?.small || ''}
          size="s"
          alt={first_name}
        />
      )}
      <ConfirmDialog
        title={dialogTitle}
        subTitle={dialogSubtitle}
        open={isOpenDeleteModal}
        onSubmit={onDeleteDocument}
        onClose={handleCloseDeleteModal}
      />
    </Actions>
  );
};
