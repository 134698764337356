import { UserDetailsResponse } from '../api';
import { Attachment } from './attachment';
import { EquipmentPayload } from './equipments';
import { TaskListItem } from './task';

export enum NotificationType {
  post_comment = 'post_comment',
  assigned = 'task_assigned',
  task_comment = 'task_comment',
  welcome = 'welcome_employee',
  removed = 'task_removed',
  edited = 'task_edited',
  completed = 'task_completed',
  birthday = 'birthday',
  birthday_today = 'birthday_today',
  due = 'task_due',
  custom = 'custom',
  equipment_completed = 'equipment_request_completed',
  equipment_expires_soon = 'equipment_expires_soon',
  post_new = 'post_new',
  poll_new = 'poll_new',
}

export type Notification =
  | NotificationTaskAssigned
  | NotificationPostComment
  | NotificationTaskComment
  | NotificationWelcomeEmployee
  | NotificationTaskEdited
  | NotificationTaskRemoved
  | NotificationTaskCompleted
  | NotificationBirthday
  | NotificationBirthdayToday
  | NotificationTaskDue
  | NotificationCustom
  | NotificationEquipmentCompleted
  | NotificationEquipmentExpiresSoon
  | NotificationPostNew
  | NotificationPollNew;

export interface CompanyDetail {
  id: string;
  created_at: string;
  updated_at: string;
  status: string;
  name: string;
  industry: string;
  size: string;
  company_number: string;
  have_active_manager: boolean;
}

interface NotificationBase {
  id: string;
  is_read: boolean;
  created_at: string;
  updated_at: string;
  company: CompanyDetail;
}

interface NotificationTaskAssigned extends NotificationBase {
  type: NotificationType.assigned;
  payload: {
    assigned_by: UserDetailsResponse;
    task: TaskListItem;
  };
}

interface NotificationPostComment extends NotificationBase {
  type: NotificationType.post_comment;
  payload: {
    posted_by: UserDetailsResponse;
    task: TaskListItem;
  };
}

interface NotificationTaskComment extends NotificationBase {
  type: NotificationType.task_comment;
  payload: {
    posted_by: UserDetailsResponse;
    task: TaskListItem;
  };
}

interface NotificationWelcomeEmployee extends NotificationBase {
  type: NotificationType.welcome;
  payload: {
    employee: UserDetailsResponse;
    post?: TaskListItem;
  };
}

interface NotificationTaskEdited extends NotificationBase {
  type: NotificationType.edited;
  payload: {
    edited_by: UserDetailsResponse;
    task: TaskListItem;
  };
}

interface NotificationTaskRemoved extends NotificationBase {
  type: NotificationType.removed;
  payload: {
    deleted_by: UserDetailsResponse;
    task: TaskListItem;
  };
}

interface NotificationTaskCompleted extends NotificationBase {
  type: NotificationType.completed;
  payload: {
    completed_by: UserDetailsResponse;
    task: TaskListItem;
  };
}

interface NotificationBirthday extends NotificationBase {
  type: NotificationType.birthday;
  payload: {
    user: UserDetailsResponse;
    is_post_action_disabled: boolean;
    expires_at?: string;
  };
}

interface NotificationBirthdayToday extends NotificationBase {
  type: NotificationType.birthday_today;
  payload: {
    user: UserDetailsResponse;
    is_post_action_disabled: boolean;
    expires_at?: string;
  };
}

interface NotificationTaskDue extends NotificationBase {
  type: NotificationType.due;
  payload: {
    post: TaskListItem;
  };
}

interface NotificationCustom extends NotificationBase {
  type: NotificationType.custom;
  payload: {
    title: string;
    description: string;
    attachments: Attachment[];
  };
}

interface NotificationEquipmentCompleted extends NotificationBase {
  type: NotificationType.equipment_completed;
  payload: {
    equipment: EquipmentPayload;
  };
}

interface NotificationEquipmentExpiresSoon extends NotificationBase {
  type: NotificationType.equipment_expires_soon;
  payload: {
    equipment: EquipmentPayload;
  };
}

interface NotificationPostNew extends NotificationBase {
  type: NotificationType.post_new;
  payload: {
    posted_by: UserDetailsResponse;
    post: TaskListItem;
  };
}

interface NotificationPollNew extends NotificationBase {
  type: NotificationType.poll_new;
  payload: {
    posted_by: UserDetailsResponse;
    poll?: TaskListItem;
    post?: TaskListItem;
  };
}
