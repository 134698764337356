import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { createLink } from 'utils';

interface UseUserDetailsQueryReturn {
  isLoading: boolean;
  data: string;
}

interface UseUserDetailsQueryProps {
  employeeId: string;
  filename: string;
}

export function useUserAvatarQuery({
  employeeId,
  filename,
}: UseUserDetailsQueryProps): UseUserDetailsQueryReturn {
  const { data, isLoading } = useQuery(
    ['userAvatar', employeeId],
    () => API_HANDLERS.USER.GET_AVATAR(employeeId, filename),
    {
      enabled: !!filename,
    },
  );
  return {
    isLoading,
    data: data?.data ? createLink(data.data) : '',
  };
}
