import { RegisterOptions } from 'react-hook-form';

import { getMaxLengthText, getMinLengthText, required } from 'validations';

export const LINK_FIELDS_LENGTH = {
  title: {
    min: 1,
    max: 15,
  },
  link: {
    min: 1,
    max: 500,
  },
};

export const titleRules: RegisterOptions = {
  ...required,
  minLength: {
    value: LINK_FIELDS_LENGTH.title.min,
    message: getMinLengthText(LINK_FIELDS_LENGTH.title.min),
  },
  maxLength: {
    value: LINK_FIELDS_LENGTH.title.max,
    message: getMaxLengthText(LINK_FIELDS_LENGTH.title.max),
  },
};

export const linkRules: RegisterOptions = {
  ...required,
  minLength: {
    value: LINK_FIELDS_LENGTH.link.min,
    message: getMinLengthText(LINK_FIELDS_LENGTH.link.min),
  },
  maxLength: {
    value: LINK_FIELDS_LENGTH.link.max,
    message: getMaxLengthText(LINK_FIELDS_LENGTH.link.max),
  },
};
