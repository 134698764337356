import { RegisterOptions } from 'react-hook-form';

import {
  getMaxLengthText,
  getMinLengthText,
  REQUIRED,
  WRONG_EMAIL_FORMAT,
  WRONG_PASSWORD_FORMAT,
} from './messages';
import { EMAIL_REGEX, PASSWORD_FORMAT_REGEX } from './regexps';

export const required: RegisterOptions = {
  required: {
    message: REQUIRED,
    value: true,
  },
};

export const emailRules: RegisterOptions = {
  pattern: {
    message: WRONG_EMAIL_FORMAT,
    value: EMAIL_REGEX,
  },
};

export const passwordRules: RegisterOptions = {
  ...required,
  minLength: {
    value: 8,
    message: getMinLengthText(8),
  },
  maxLength: {
    value: 60,
    message: getMaxLengthText(60),
  },
  pattern: {
    message: WRONG_PASSWORD_FORMAT,
    value: PASSWORD_FORMAT_REGEX,
  },
};

export const emptyStringTransform = <T extends string>(value: T, originalValue: T): T | null =>
  String(originalValue).trim() === '' ? null : value;
