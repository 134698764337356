import { useMutation } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { queryClient } from 'api/queryClient';

export function useCreatePostReactions() {
  const { mutate: createPostReaction, isLoading } = useMutation(API_HANDLERS.REACTIONS.CREATE, {
    onSuccess: () => {
      queryClient.invalidateQueries(['posts']);
      queryClient.invalidateQueries(['post-details']);
      queryClient.invalidateQueries(['reactions']);
    },
  });

  return {
    createPostReaction,
    isLoading,
  };
}
