import { TaskFormPayload } from 'api';
import { TaskFormValues } from 'types';
import { removeExtraSpaces } from 'utils';

interface FormValuesAdapterProps extends TaskFormValues {
  attachment_ids: string[];
}

export function formValuesAdapter({
  title,
  description,
  attachment_ids,
  status,
  assignee,
  images,
  docs,
  deadline,
  ...otherProps
}: FormValuesAdapterProps): TaskFormPayload {
  return {
    title: removeExtraSpaces(title),
    description: removeExtraSpaces(description),
    type: 'tasks',
    ...(status && {
      task: {
        state: status,
        deadline,
      },
    }),
    ...(assignee && { assigned_to: assignee }),
    ...(!!attachment_ids.length && { attachment_ids }),
    ...otherProps,
  };
}
