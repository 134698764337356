import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const ContentW = styled('div')`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.p050};
  margin-top: 66px;
`;

export const LinkW = styled(Link)`
  color: ${({ theme }) => theme.palette.info.main};
`;
