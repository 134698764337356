import { AchievementFormValues, Achievement } from 'types';

import { achievementsColors } from '../../constants';

interface DefaultValuesAdapterProps {
  data?: Achievement;
}

export function defaultValuesAdapter({ data }: DefaultValuesAdapterProps): AchievementFormValues {
  return {
    title: data?.title ?? '',
    description: data?.description ?? '',
    color: data?.color ?? achievementsColors[0],
  };
}
