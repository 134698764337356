import { BoxProps } from '@mui/material';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { Links } from 'settings';
import { TaskStatus } from 'types';

import { Description, EmptyStateIconW, EmptyStateW, NavLinkW } from './styled';

interface EmptyStateProps {
  status?: TaskStatus;
  wrapperSx?: BoxProps['sx'];
}

export function EmptyState({ wrapperSx }: EmptyStateProps): ReactElement {
  const link = Links.we.createDocument;

  return (
    <EmptyStateW sx={{ ...wrapperSx }}>
      <EmptyStateIconW />

      <Description variant="body2">
        <Trans i18nKey="we.lastUpdatedDocuments.emptyState">
          Nothing is here yet. <NavLinkW to={link}>Add a document!</NavLinkW>
        </Trans>
      </Description>
    </EmptyStateW>
  );
}
