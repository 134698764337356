import { styled } from '@mui/material';

export const BarW = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 13px 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1023px) {
    padding: 10px 10px 10px 16px;
  }
`;

export const Text = styled('span')`
  display: block;
  border-radius: 12px;
  color: ${({ theme }) => theme.palette.primary.p080};
  white-space: nowrap;

  @media (max-width: 1023px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
  }
`;
