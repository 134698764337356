import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { User } from 'types';

import { API_HANDLERS } from '../../apiHandlers';
import { UsersListRequestParams, UsersResponseSuccess, UserListPage } from '../../types';
import { usersListAdapter } from '../useInfiniteUsersListQuery/adapter';

interface UseUsersListQueryReturn {
  usersList: User[];
  isLoading: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage?: boolean;
  countOfActiveUsers: number;
}

interface UseInfiniteUsersListProps extends UsersListRequestParams {
  inView: boolean;
}

export function useInfiniteUsersListQuery({
  detailed,
  rand,
  status,
  limit,
  offset,
  inView,
}: UseInfiniteUsersListProps): UseUsersListQueryReturn {
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['employees', detailed, rand, status, limit, offset],
    ({ pageParam }) => {
      return API_HANDLERS.USERS.GET_INFINITE({
        ...(detailed && { detailed }),
        ...(rand && { rand }),
        ...(status && { status }),
        ...(limit && { limit }),
        ...(typeof offset === 'number' && { offset }),
        ...(pageParam && pageParam),
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        let totalDownloadedUsers = 0;
        const totalUsers = (lastPage.data as unknown as UsersResponseSuccess).count.total;
        allPages.forEach((page) => {
          const data = (page as unknown as UserListPage).data;
          const dataLength = data.data.length;
          data ? (totalDownloadedUsers = totalDownloadedUsers + dataLength) : undefined;
        });
        if (totalUsers > totalDownloadedUsers) {
          return { limit, offset: totalDownloadedUsers };
        }
        return undefined;
      },
    },
  );
  let countOfActiveUsers = 0;
  const userPages = data?.pages.length
    ? data?.pages.map((page) => {
        countOfActiveUsers = (page.data as unknown as UsersResponseSuccess).count.active;
        return (page.data as unknown as UsersResponseSuccess).data;
      })
    : [];
  const usersList = userPages.flat();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return {
    usersList: usersListAdapter(usersList),
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    countOfActiveUsers,
  };
}
