import { styled } from '@mui/material';

export const Attachments = styled('div')<{ isCustom?: boolean }>`
  flex: 1;
  margin-left: 25px;
  display: flex;
  flex-wrap: noWrap;
  gap: 16px;
  flex-direction: row-reverse;

  @media (max-width: 1023px) {
    margin-left: 0px;
    margin-top: 16px;
    max-width: 348px;
    justify-content: ${({ isCustom }) => isCustom && 'center'};
  }
`;

export const ShowLessButton = styled('div')`
  width: 100%;
  margin: 10px 0;
  padding: 5px;
  text-align: center;
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.white.main};
    background: ${({ theme }) => theme.palette.info.main};
    border-radius: 10px;
    transition: 0.5s;
  }

  @media (max-width: 500px) {
    margin: 5px 0 0 0;
  }
`;
