import { t } from 'i18next';

import { ReactComponent as PollIcon } from 'assets/icons/startPage/poll.svg';
import { ReactComponent as PostIcon } from 'assets/icons/startPage/post.svg';
import { Links } from 'settings';

export const actionsOptions = [
  {
    label: t('we.posts.createButton'),
    icon: <PostIcon />,
    link: Links.we.createPost,
  },
  {
    label: t('we.poll.createButton'),
    icon: <PollIcon />,
    link: Links.we.createPoll,
  },
];
