import { Avatar as MuiAvatar, AvatarProps as AvatarPropsMUI } from '@mui/material';
import { ReactElement, SyntheticEvent } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { useUserAvatarQuery } from 'api';
import { ReactComponent as MaximizeIcon } from 'assets/icons/profileSet/camera.svg';
import EmptyAvatar from 'assets/images/avatarEmpty.png';
import { Links } from 'settings';

import { AvatarW, UserName, HoverW, DefaultAvatar, AssigneeInitials } from './styled';

export type AvatarSize =
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'mobTable'
  | 'notification';

interface AvatarProps extends AvatarPropsMUI {
  size?: AvatarSize;
  wrapperSx?: AvatarPropsMUI['sx'];
  isAvailableToUpload?: boolean;
  userId: string;
  filename?: string;
  onClick?: (e: SyntheticEvent) => void;
  withNavigation?: boolean;
  initials?: string;
}

export function Avatar({
  size = 'm',
  alt,
  wrapperSx,
  isAvailableToUpload,
  filename = '',
  userId,
  onClick,
  withNavigation = true,
  initials,
  ...otherProps
}: AvatarProps): ReactElement {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: avatarData } = useUserAvatarQuery({ employeeId: userId, filename: filename });

  const detailsLink = pathname.includes('resources')
    ? Links.resources.employees.employee.index
    : Links.we.employees.employee.index;

  function goToDetailsPage(id: string) {
    navigate(
      generatePath(detailsLink, {
        employeeId: id,
      }),
    );
  }

  function handleOnClick(event: SyntheticEvent) {
    onClick?.(event);

    if (withNavigation) {
      goToDetailsPage(userId);
    }
  }

  return (
    <AvatarW size={size} sx={wrapperSx} onClick={handleOnClick}>
      <MuiAvatar alt={alt} src={avatarData} {...otherProps}>
        {initials ? (
          <AssigneeInitials>{initials}</AssigneeInitials>
        ) : (
          <DefaultAvatar src={EmptyAvatar} alt="avatar" />
        )}

        {isAvailableToUpload && <MaximizeIcon className="camera-icon" />}
      </MuiAvatar>

      {isAvailableToUpload && (
        <HoverW className="hovered-wrap">
          <MaximizeIcon className="camera-icon" />
        </HoverW>
      )}
      {alt && <UserName size={size}>{alt}</UserName>}
    </AvatarW>
  );
}
