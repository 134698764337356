import { Equipment, EquipmentPayload } from 'types/equipments';

export function equipmentAdapter(list?: EquipmentPayload[]): Equipment[] {
  if (!list) {
    return [];
  }

  return list.map(
    ({
      user_id,
      author_id,
      expire_at,
      ordered_at,
      is_editable,
      custom_fields,
      attachment_order,
      assigned_to_id,
      ...otherProps
    }) => ({
      userId: user_id,
      authorId: author_id,
      expireAt: expire_at,
      orderedAt: ordered_at,
      isEditable: is_editable,
      customFields: custom_fields,
      attachmentOrder: attachment_order,
      assignedToId: assigned_to_id,
      ...otherProps,
    }),
  );
}
