import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES } from 'constants/index';
import { QuickLink } from 'types';

interface UseLinkDetailsQueryReturn {
  isLoading: boolean;
  link?: QuickLink;
}

export function useLinkDetailsQuery(linkId: string): UseLinkDetailsQueryReturn {
  const { data, isLoading } = useQuery(
    [QUERIES.QUICK_LINK_DETAILS, linkId],
    () => API_HANDLERS.QUICK_LINKS.GET_DETAILS(linkId),
    {
      cacheTime: 0,
    },
  );
  return {
    isLoading,
    link: data?.data,
  };
}
