const INVALID_CREDENTIALS = 4028;
const INVALID_OTT = 4029;
const EMAIL_IS_USED = 4031;
const COMPANY_NAME_IS_USED = 4032;
const UNAUTHORIZED = 4008;
const NOT_CONFIRMED_ACCOUNT = 4033;
const ATTACHMENT_FILE_TYPE_ERROR = 4062;
const ATTACHMENT_COUNT_LIMIT_REACHED = 4063;
const LOGIN_DEACTIVATED_USER = 4034;
const TOO_LARGE_FILE = 4060;
const SUBSCRIPTION_EXPIRED = 4100;
const SUBSCRIPTION_EXPIRED_EMPLOYEE = 4101;
const UPDATE_NOT_ALLOWED = 4041;
const PENDING_USER_RESET_PASSWORD = 4035;
const VIEW_OPERATION_IS_NOT_ALLOWED = 4042;
const INVALID_REQUEST = 6000;

export {
  INVALID_CREDENTIALS,
  INVALID_OTT,
  EMAIL_IS_USED,
  COMPANY_NAME_IS_USED,
  UNAUTHORIZED,
  NOT_CONFIRMED_ACCOUNT,
  ATTACHMENT_FILE_TYPE_ERROR,
  ATTACHMENT_COUNT_LIMIT_REACHED,
  LOGIN_DEACTIVATED_USER,
  TOO_LARGE_FILE,
  SUBSCRIPTION_EXPIRED,
  SUBSCRIPTION_EXPIRED_EMPLOYEE,
  UPDATE_NOT_ALLOWED,
  PENDING_USER_RESET_PASSWORD,
  VIEW_OPERATION_IS_NOT_ALLOWED,
  INVALID_REQUEST,
};
