import { styled } from '@mui/material';

export const ListsW = styled('div')`
  display: flex;
  justify-content: space-between;
  height: 100%;
  &.swiper {
    height: 600px;
    &.swiper-pagination {
      bottom: -20px;
    }
  }
`;

export const ContainerW = styled('div')`
  width: 100%;
  padding: 24px;
  position: relative;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  max-height: 475px;
  @media (max-width: 1023px) {
    max-height: unset;
    padding: 56px 16px 16px;
  }
`;
