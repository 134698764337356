import { Typography, styled } from '@mui/material';

export const TitleName = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
`;

export const TitleCategory = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
`;

export const iconButtonStyle = {
  height: 'fit-content',
};

export const ActionsWrap = styled('div')<{ isEditable: boolean }>`
  @media (min-width: 1024px) {
    display: flex;
    justify-content: ${({ isEditable }) => (isEditable ? 'start' : 'end')};
    min-width: 80px;
  }
`;
