import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  margin-top: 16px;
`;

export const FooterW = styled('div')`
  display: flex;
  align-items: center;
`;

export const CountOfAttachments = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const Tag = styled('div')`
  padding: 3px 12px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-bottom: 16px;
`;

export const StyledDate = styled('div')`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.4);
  padding-right: 8px;
`;
