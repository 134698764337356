import { Control, useForm } from 'react-hook-form';

import { AchievementFormValues } from 'types';

interface UseAchievementFormProps {
  isEditMode: boolean;
  defaultValues: AchievementFormValues;
  onSubmit: (data: AchievementFormValues) => void;
}

interface UseAchievementFormReturn {
  isSubmitting: boolean;
  isDirty: boolean;
  control: Control<AchievementFormValues>;
  handleFormSubmit: () => void;
}

export function useAchievementForm({
  isEditMode,
  defaultValues,
  onSubmit,
}: UseAchievementFormProps): UseAchievementFormReturn {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<AchievementFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleFormSubmit = handleSubmit((data: AchievementFormValues) => {
    onSubmit(data);
    isEditMode && reset(data);
  });

  return {
    isSubmitting,
    isDirty,
    control,
    handleFormSubmit,
  };
}
