import { Links } from 'settings';

type LinksConfig = { link: string; state: { expired: boolean } };

export const EXPIRED_LINKS_CONFIG: Record<string, LinksConfig> = {
  company_registration: {
    link: Links.auth.registration.index,
    state: { expired: true },
  },
  user_registration: {
    link: Links.auth.signUp.expired,
    state: { expired: true },
  },
  password_reset: {
    link: Links.auth.resetExpired,
    state: { expired: true },
  },
  employee_activation: {
    link: Links.auth.signUp.expired,
    state: { expired: true },
  },
};
