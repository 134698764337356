import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEmailValidateUserMutation } from 'api/mutations/useEmailValidateUserMutation';
import {
  FormGroup,
  TextFieldController,
  SubmitRoundedButton,
  PasswordController,
  Form,
} from 'components';
import { FIELDS_VALUE_LENGTH } from 'constants/fieldsLength';
import { LanguageSelector } from 'features/languageSelector';
import { useWindowSize } from 'hooks';
import { ApiErrors, CompanyPersonalDataFormValues, RegisterCompanyFormSteps } from 'types';
import { passwordRules } from 'validations';

import { usePersonalDataForm } from './hooks';
import { firstNameRules, lastNameRules, emailFieldRules } from './rules';
import { InputW, WranDescription } from './styled';
import { submitBtnStyles, submitBtnMobileStyles } from './styles';

interface PersonalDataFormProps {
  setStep: (step: RegisterCompanyFormSteps) => void;
  defaultValues: CompanyPersonalDataFormValues;
  storeValues: (values: CompanyPersonalDataFormValues) => void;
  errors?: Record<string, ApiErrors['errors']> | null;
}

export function PersonalDataForm({
  setStep,
  defaultValues,
  storeValues,
  errors,
}: PersonalDataFormProps) {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { handleFormSubmit, control } = usePersonalDataForm({
    setStep,
    defaultValues,
    storeValues,
    errors,
  });
  const formGroupMarginBottom = isMobile ? 24 : 56;
  const [passwordHide, setPasswordHide] = useState<boolean>(true);
  const { emailValidationMutation } = useEmailValidateUserMutation({ setPasswordHide });
  return (
    <div>
      <Form onSubmit={handleFormSubmit}>
        <FormGroup row marginBottom={formGroupMarginBottom}>
          <InputW>
            <TextFieldController
              control={control}
              name="firstName"
              id="firstName"
              label={t('companyRegistration.personalDataForm.firstName')}
              placeholder={t('companyRegistration.personalDataForm.firstName')}
              rules={firstNameRules}
              maxLength={FIELDS_VALUE_LENGTH.firstName.max}
            />
          </InputW>
          {!isMobile && (
            <InputW>
              <TextFieldController
                name="lastName"
                id="lastName"
                control={control}
                label={t('companyRegistration.personalDataForm.lastName')}
                placeholder={t('companyRegistration.personalDataForm.lastName')}
                rules={lastNameRules}
                maxLength={FIELDS_VALUE_LENGTH.lastName.max}
              />
            </InputW>
          )}
        </FormGroup>
        {isMobile && (
          <FormGroup marginBottom={formGroupMarginBottom}>
            <InputW>
              <TextFieldController
                name="lastName"
                id="lastName"
                control={control}
                label={t('companyRegistration.personalDataForm.lastName')}
                placeholder={t('companyRegistration.personalDataForm.lastName')}
                rules={lastNameRules}
                maxLength={FIELDS_VALUE_LENGTH.lastName.max}
              />
            </InputW>
          </FormGroup>
        )}
        <FormGroup marginBottom={formGroupMarginBottom}>
          <TextFieldController
            name="email"
            id="email"
            control={control}
            type="email"
            emailTrigger={true}
            checkEmailExsist={emailValidationMutation}
            label={t('companyRegistration.personalDataForm.email')}
            placeholder={t('companyRegistration.personalDataForm.email')}
            autoComplete="new-password"
            rules={emailFieldRules}
            maxLength={254}
          />
        </FormGroup>
        <FormGroup marginBottom={isMobile || passwordHide ? 44 : 16} row>
          {passwordHide && (
            <PasswordController
              className="personalData"
              name="password"
              id="password"
              control={control}
              fullWidth={isMobile}
              label={t('companyRegistration.personalDataForm.password')}
              placeholder={t('companyRegistration.personalDataForm.password')}
              autoComplete="new-password"
              rules={passwordRules}
              maxLength={60}
            />
          )}
          {!passwordHide && (
            <WranDescription>{t('companyRegistration.warnMessage.tittle')}</WranDescription>
          )}
          <SubmitRoundedButton sx={isMobile ? submitBtnMobileStyles : submitBtnStyles} />
        </FormGroup>
      </Form>

      <Box sx={{ marginTop: '24px' }}>
        <LanguageSelector />
      </Box>
    </div>
  );
}
