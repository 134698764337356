import { useQuery } from '@tanstack/react-query';
import { fromUnixTime, isPast } from 'date-fns';
import { useNavigate } from 'react-router';

import { API_HANDLERS } from 'api/apiHandlers';
import { useAuth, useSubscription } from 'hooks';
import { Links } from 'settings';
import { getUnixTimestamp } from 'utils';

export function useCurrentSubscriptionQuery() {
  const { saveSubscription } = useSubscription();
  const navigate = useNavigate();

  const { isManager } = useAuth();

  const { data, isLoading, refetch } = useQuery(
    ['subscription-details'],
    () => API_HANDLERS.SUBSCRIPTION.DETAILS(),
    {
      enabled: isManager,
      onSuccess: (data) => {
        saveSubscription(data.data);

        const unixTimestamp = getUnixTimestamp(data.data?.expires_at || '');
        const dateFromUnix = fromUnixTime(unixTimestamp);
        const isExpired = isPast(dateFromUnix);

        if (isExpired) {
          navigate(Links.actions.subscriptions);
        }
      },
    },
  );

  return {
    currentSubscriptions: data?.data,
    isLoading,
    refetch,
  };
}
