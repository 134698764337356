import { useMutation } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';

// TODO: remove if unnecessary
// onSuccess: async () => {
// if (data.data.auth_url) {
//   // clickLink(data.data.auth_url);
//   window.open(data.data.auth_url);
// }
// },

export function useCalendarAuthMutation() {
  const { mutateAsync: calendarAuthMutation } = useMutation(API_HANDLERS.CALENDAR.SIGN_IN);

  return {
    calendarAuthMutation,
  };
}
