import { CalendarEventsList, CalendarEventApi } from 'types';

export function formatEvents(list: CalendarEventApi[]): CalendarEventsList {
  return list.map((item: CalendarEventApi) => {
    const startDate = item.start;
    const endDate = item.end;

    return {
      ...item,
      title: item.summary || item.description,
      startTime: new Date(startDate),
      endTime: new Date(endDate),
    };
  });
}
