import { deleteCurrentToken } from 'api/firebase';
import { Links } from 'settings';

export function handleLogout() {
  deleteCurrentToken();
  localStorage.removeItem('user');
  localStorage.removeItem('expiresAt');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('selectedTeam');
  localStorage.removeItem('webPushToken');

  if (window.location.pathname.includes('sign-in')) {
    return;
  }

  window.location.replace(Links.auth.signIn);
}
