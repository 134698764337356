import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { API_HANDLERS } from 'api/apiHandlers';
import { TASK_MANAGEMENT_LIST_LIMIT } from 'constants/index';
import { InViewItemRef, InstanceType, Comment } from 'types';

interface UseCommentsListQueryReturn {
  isLoading: boolean;
  comments: Comment[];
  lastItemRef: InViewItemRef;
  isFetchingNextPage: boolean;
  total: number;
  isEmptyList: boolean;
}

interface UseCommentsListQueryProps {
  limit: number;
  offset?: number;
  postId: string;
  type: InstanceType;
}

export function useCommentsListQuery({
  limit,
  postId,
  type,
  offset,
}: UseCommentsListQueryProps): UseCommentsListQueryReturn {
  const { ref, inView } = useInView();

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery(
    ['comments-list', limit, offset, type, postId],
    ({ pageParam }) =>
      API_HANDLERS.COMMENTS.GET_LIST({
        type,
        post_id: postId,
        ...(offset && { offset }),
        ...(limit && { limit }),
        ...(pageParam && pageParam),
      }),

    {
      getNextPageParam: (lastPage, allPages) => {
        const total = lastPage.data.total;
        if (!total) return;
        const loadedItems = allPages.reduce((prev, curr) => prev + curr.data.data.length, 0);
        return total > loadedItems ? { offset: loadedItems, limit } : undefined;
      },
      cacheTime: 0,
      enabled: !!postId,
    },
  );

  useEffect(() => {
    if (inView && hasNextPage && limit === TASK_MANAGEMENT_LIST_LIMIT) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage, limit]);

  const isEmptyList = data?.pages.length === 1 && data?.pages[0].data.data === null;
  const commentsList = isEmptyList ? [] : data?.pages.map((page) => page.data.data).flat();

  return {
    isLoading,
    comments: commentsList || [],
    lastItemRef: ref,
    isFetchingNextPage,
    total: data?.pages[0].data.total || 0,
    isEmptyList,
  };
}
