import { ReactElement, useEffect } from 'react';
import { Outlet, matchPath, useNavigate } from 'react-router-dom';

import { BREAKPOINTS } from 'constants/index';
import { NavigationBar } from 'features/navigation';
import { useSubscription, useWindowSize } from 'hooks';
import { Links } from 'settings';

import { PageContentW, PageW } from './styled';

export function AppLayout(): ReactElement {
  const navigate = useNavigate();

  const { width } = useWindowSize();

  const { isExpired } = useSubscription();

  const arrOfLinks = [
    Links.actions.employeesInvite,
    Links.we.employees.invite,
    Links.resources.employees.invite,
    Links.resources.employees.index,
    Links.we.employees.index,
    Links.we.employees.employee.extended,
    Links.resources.employees.employee.extended,
    Links.profile.extended.extended,
    Links.we.employees.employee.createDocument,
    Links.actions.createDocument,
    Links.actions.createNotification,
    Links.resources.createCategory,
    Links.resources.edit.categoryId,
    Links.resources.documents.edit.documentId,
    Links.resources.createDocument,
    Links.resources.createTaggedDocument.tagged.tag,
    Links.profile.createDocument,
    Links.profile.privateDocuments.edit.documentId,
    Links.we.createTask,
    Links.actions.createTask,
    Links.profile.createTask,
    Links.we.taskManagement.taskDetails,
    Links.profile.taskManagement.taskDetails,
    Links.profile.taskManagement.index,
    Links.we.taskManagement.index,
    Links.actions.createPost,
    Links.we.createPost,
    Links.we.posts.index,
    Links.resources.companyInfo,
    Links.profile.equipmentList.equipment.index,
    Links.profile.equipmentList.createEquipment,
    Links.actions.createEquipment,
    Links.profile.equipmentList.equipment.equipmentId,
    Links.actions.createPoll,
    Links.we.createPoll,
    Links.profile.achievements.createAchievement,
    Links.profile.achievements.achievement.achievementId.index,
    Links.profile.achievements.achievement.achievementId.createGoal,
    Links.profile.achievements.achievement.achievementId.goal.goalId,
    Links.profile.achievements.achievement.index,
  ];

  const isNavbarHidden =
    width <= BREAKPOINTS.web &&
    arrOfLinks.some((route) => !!matchPath(route, window.location.pathname));

  useEffect(() => {
    if (isExpired) {
      navigate(Links.actions.subscriptions);
    }
  }, [isExpired, navigate]);

  return (
    <PageW>
      {!isNavbarHidden && <NavigationBar />}

      <PageContentW sx={isNavbarHidden ? { marginBottom: 'auto' } : {}} id="main-page">
        <Outlet />
      </PageContentW>
    </PageW>
  );
}
