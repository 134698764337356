import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';

import { API_HANDLERS } from 'api/apiHandlers';
import { EmailValidationParams } from 'api/types';

interface UseEmailValidateUserMutationReturn {
  emailValidationMutation: UseMutateAsyncFunction<
    AxiosResponse,
    unknown,
    EmailValidationParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseEmailValidateUserMutation {
  setPasswordHide?: Dispatch<SetStateAction<boolean>>;
}

export function useEmailValidateUserMutation({
  setPasswordHide,
}: UseEmailValidateUserMutation): UseEmailValidateUserMutationReturn {
  const { mutateAsync: emailValidationMutation, isLoading } = useMutation(
    API_HANDLERS.USER.VALIDATE_EMAIL,
    {
      onSuccess: (data) => {
        if (data?.data && setPasswordHide) {
          setPasswordHide(false);
        } else if (!data?.data && setPasswordHide) {
          setPasswordHide(true);
        }
      },
    },
  );

  return {
    emailValidationMutation,
    isLoading,
  };
}
