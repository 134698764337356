import { ReactElement } from 'react';

import { ReactComponent as AdligoTextLogo } from 'assets/icons/logos/textLogo.svg';
import startLogo from 'assets/images/startLogo.png';
import { useAuth, useSubscription } from 'hooks';

import { NavLink } from '../NavLink';
import { MENU_ITEMS } from './config';
import { LogoW, ListItems, NavBarW, DividerW, ListItemsMainW, Logo } from './styled';

export function NavigationBar(): ReactElement {
  const { user } = useAuth();

  const { isExpired } = useSubscription();

  const { ACTIONS, WE, ME, RESOURCES } = MENU_ITEMS(user?.id || '', user?.avatar?.small || '');

  return (
    <NavBarW disabled={isExpired}>
      <ListItemsMainW>
        <ListItems>
          <NavLink
            to={ACTIONS.path}
            icon={ACTIONS.icon}
            label={ACTIONS.label}
            disabled={isExpired}
          />
          <NavLink to={WE.path} icon={WE.icon} label={WE.label} disabled={isExpired} />
          <NavLink to={ME.path} icon={ME.icon} label={ME.label} disabled={isExpired} />
          <NavLink
            to={RESOURCES.path}
            icon={RESOURCES.icon}
            label={RESOURCES.label}
            disabled={isExpired}
          />
        </ListItems>
        <DividerW />
      </ListItemsMainW>

      <LogoW>
        <Logo src={startLogo} alt="logo" />
        <AdligoTextLogo />
      </LogoW>
    </NavBarW>
  );
}
