import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Links } from 'settings';
import { InViewItemRef, Notification as NotificationType } from 'types';

import { Notification } from '../../components';
import { ContentW, FooterW, ListW, TitleW } from './styled';

interface PreviewNotificationListProps {
  notifications: NotificationType[];
  lastItemId: string;
  lastItemRef: InViewItemRef;
  markAsRead: (id: string) => void;
}

export function PreviewNotificationList({
  notifications,
  lastItemRef,
  lastItemId,
  markAsRead,
}: PreviewNotificationListProps): ReactElement {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <>
      <ContentW>
        <TitleW>{t('notifications.title')}</TitleW>

        <ListW>
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              isPreview
              lastItemRef={notification.id === lastItemId ? lastItemRef : undefined}
              addToIsRead={(id: string) => markAsRead(id)}
              {...notification}
            />
          ))}
        </ListW>
      </ContentW>
      <FooterW>
        <NavLink to={Links.we.notifications} state={{ from: pathname }}>
          {t('notifications.previewLink')}
        </NavLink>
      </FooterW>
    </>
  );
}
