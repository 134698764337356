import { alert } from 'components';
import { INVITE_FORM_EMAILS_LIMIT } from 'constants/toasterMessages';
import { removeExtraListItems } from 'utils';

import { UserListItem } from '../../types';

export function handleRemoveExtraItems(list: UserListItem[], limit: number): UserListItem[] {
  const items = removeExtraListItems(list, limit);

  if (list.length > limit) {
    alert.error(INVITE_FORM_EMAILS_LIMIT);
  }

  return items;
}
