import { styled } from '@mui/material';

export const ThirdImagesW = styled('div')`
  width: 145px;
  height: 72px;
  position: relative;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const MoreImages = styled('div')`
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 145px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.white.main};
  @media (max-width: 1023px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
  }
`;

export const RightImagesW = styled('div')`
  @media (max-width: 1023px) {
    flex: 1;
  }
`;
