import { ReactElement, ReactNode, useRef, useState } from 'react';
import Avatar from 'react-avatar-editor';

import { alert } from 'components/Alerts';
import { useWindowSize } from 'hooks';
import { fileSizeConverter } from 'utils';
import { toLargeFile } from 'validations';

import {
  ActivateButton,
  DialogFooter,
  FooterActions,
  RemoveButton,
  Dialog,
  Slider,
} from './styled';

interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  acceptFormats: string;
  multiple: boolean;
  children: ReactNode;
  handleFile: (file: File) => void;
  maxAvatarSize: number;
}

export const AvatarEditor = ({
  multiple,
  acceptFormats,
  children,
  handleFile,
  style,
  maxAvatarSize,
  id = 'file-upload',
  ...otherProps
}: Props): ReactElement => {
  const { isMobile } = useWindowSize();
  const editorRef = useRef<null>(null);
  const emptyPicture = {
    cropperOpen: false,
    img: '',
    zoom: 1,
    name: '',
    type: '',
  };

  const [isOpen, setOpen] = useState(false);
  const [picture, setPicture] = useState(emptyPicture);

  const handleSlider = (_: Event, value: number | number[]) => {
    setPicture({
      ...picture,
      zoom: value as number,
    });
  };

  const handleCancel = () => {
    setOpen(false);
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const handleSave = () => {
    // TODO remove ts-ignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canvasScaled = editorRef.current.getImageScaledToCanvas();

    canvasScaled.toBlob((blob: Blob) => {
      const file = new File([blob], picture.name, { type: picture.type });
      handleFile(file);
      setPicture(emptyPicture);
      setOpen(false);
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const currentFile = event.currentTarget.files[0];

      const url = URL.createObjectURL(currentFile);
      const sizeInMB = fileSizeConverter(currentFile.size);

      if (sizeInMB > maxAvatarSize) {
        alert.error(toLargeFile(15));
        event.currentTarget.value = '';

        return;
      }

      setPicture({
        ...picture,
        img: url,
        cropperOpen: true,
        name: currentFile.name,
        type: currentFile.type,
      });

      setOpen(true);
    }
  };

  function onInputClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    (event.target as HTMLInputElement).value = '';
  }

  const editorSize = isMobile ? 250 : 500;

  return (
    <>
      <Dialog open={isOpen}>
        <Avatar
          ref={editorRef}
          image={picture.img || ''}
          width={editorSize}
          height={editorSize}
          borderRadius={editorSize}
          scale={picture.zoom}
        />
        <DialogFooter>
          <Slider
            aria-label="raceSlider"
            value={picture.zoom}
            min={1}
            max={10}
            step={0.1}
            onChange={handleSlider}
          ></Slider>
          <FooterActions>
            <RemoveButton variant="contained" color="warning" onClick={handleCancel}>
              Cancel
            </RemoveButton>
            <ActivateButton variant="contained" color="warning" onClick={handleSave}>
              Save
            </ActivateButton>
          </FooterActions>
        </DialogFooter>
      </Dialog>
      <label htmlFor={id} style={{ cursor: 'pointer', position: 'relative', ...style }}>
        {children}
      </label>
      <input
        multiple={multiple}
        id={id}
        type="file"
        hidden
        accept={acceptFormats}
        onChange={handleFileChange}
        onClick={onInputClick}
        {...otherProps}
      />
    </>
  );
};
