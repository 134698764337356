import { styled, Typography } from '@mui/material';

export const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const TitleW = styled(Typography)`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-left: 8px;
`;

export const HeaderW = styled('div')`
  display: flex;
  align-items: center;
`;

export const ActionsW = styled('div')``;
