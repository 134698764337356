import i18n from 'i18n';
import { ClothesSize, UserEmergencyRelation } from 'types';

export const EDIT_FORM_FIELDS_LENGTH = {
  companyDepartment: {
    min: 0,
    max: 70,
  },
  position: {
    min: 0,
    max: 70,
  },
  address1: {
    min: 0,
    max: 70,
  },
  address2: {
    min: 0,
    max: 70,
  },
  city: {
    min: 0,
    max: 45,
  },
  province: {
    min: 0,
    max: 45,
  },
  postCode: {
    min: 0,
    max: 15,
  },
  ssn: {
    min: 9,
    max: 15,
  },
  employmentNumber: {
    min: 0,
    max: 16,
  },
  education: {
    min: 0,
    max: 200,
  },
  allergies: {
    min: 0,
    max: 70,
  },
  comments: {
    min: 0,
    max: 500,
  },
  relationContactFirstName: {
    min: 0,
    max: 40,
  },
  relationContactLastName: {
    min: 0,
    max: 40,
  },
  activationComment: {
    min: 0,
    max: 500,
  },
  license: {
    min: 0,
    max: 70,
  },
  equipment: {
    min: 0,
    max: 70,
  },
};

export const CLOTHES_SIZE_OPTIONS = [
  { value: ClothesSize.xs, label: ClothesSize.xs.toUpperCase() },
  { value: ClothesSize.s, label: ClothesSize.s.toUpperCase() },
  { value: ClothesSize.m, label: ClothesSize.m.toUpperCase() },
  { value: ClothesSize.l, label: ClothesSize.l.toUpperCase() },
  { value: ClothesSize.xl, label: ClothesSize.xl.toUpperCase() },
  { value: ClothesSize.xxl, label: ClothesSize.xxl.toUpperCase() },
  { value: ClothesSize.xxxl, label: ClothesSize.xxxl.toUpperCase() },
];

export const RELATION_OPTIONS = [
  { value: UserEmergencyRelation.Parent, label: i18n.t('employee.contact.parent') },
  { value: UserEmergencyRelation.Child, label: i18n.t('employee.contact.child') },
  { value: UserEmergencyRelation.Sibling, label: i18n.t('employee.contact.sibling') },
  { value: UserEmergencyRelation.Friend, label: i18n.t('employee.contact.friend') },
  { value: UserEmergencyRelation.Partner, label: i18n.t('employee.contact.partner') },
  { value: UserEmergencyRelation.Other, label: i18n.t('employee.contact.other') },
];

export const LICENSE_AND_EQUIPMENT_MAX_COUNT = 20;
