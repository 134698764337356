import { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  UserStatus,
  UserRole,
  CompanySize,
  ClothesSize,
  UserEmergencyRelation,
  PostDraft,
  AccessLevel,
  Attachment,
  InstanceType,
  DocumentsType,
  SubscriptionsPeriod,
  SubscriptionPlan,
  Comment,
  Subscription,
  ProductDetails,
  Company,
  PaymentDetails,
  CalendarEvent,
  CalendarTypes,
  Language,
  Target,
  QuickLink,
  TargetFormValues,
  QuickLinkFormValues,
  Notification,
  RegisterEquipmentRequest,
  RequestEquipmentRequest,
  Achievement,
  GoalStatus,
  AchievementGoalAssignedType,
  CompanyStatus,
} from 'types';
import { EquipmentPayload } from 'types/equipments';

export interface ClientConfig extends AxiosRequestConfig {
  authorizeRequest?: boolean;
}

export type AxiosResponseSuccess<Data> = Promise<AxiosResponse<Data>>;

// AUTH
export interface RegisterCompanyParams {
  name: string;
  industry: string;
  size: CompanySize;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  lang: string;
  company_number: string;
}

export interface ConfirmRegistrationParams {
  ott: string;
  user?: {
    first_name: string;
    last_name: string;
    password: string;
    country_code: number;
    phone: string;
    company_id?: string | undefined;
  };
}

export interface ValidateOttParams {
  ott: string;
}

export interface ValidateOttResponse {
  email: string;
}

export interface LoginRequestParams {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: {
    access_token: string;
    refresh_token: string;
    expires_at: number;
  };
  user: {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    avatar?: { small: string; medium: string };
    status: UserStatus;
    role: UserRole;
  };
}

export interface InviteEmployeeParams {
  users: {
    email: string;
    role: UserRole;
  }[];
  lang: string;
}

export interface UsersListRequestParams {
  detailed?: boolean;
  rand?: boolean;
  status?: UserStatus[];
  limit?: number;
  offset?: number;
}

export interface UserPayload {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  country_code: number;
  phone: string;
  avatar?: {
    small: string;
    medium: string;
  };
  position: string;
  birth_date: string;
  address_1?: string;
  address_2?: string;
  postcode: string;
  city: string;
  region: string;
  country: string;
  status: UserStatus;
  role: UserRole[];
  invited_by_id?: string;
}

export interface CompanyPayoad {
  id: string;
  name: string;
  industry: string;
  size: number;
  vat: string;
  company_number: string;
  address_1: string;
  address_2: string;
  city: string;
  region: string;
  postcode: string;
  country: string;
  email: string;
  support_email: string;
  website: string;
  country_code: string | number;
  phone: string;
  status: CompanyStatus;
  created_at: string;
  updated_at: string;
}
export interface UsersInfiniteListResponseSuccess {
  pages: UserListPage[];
  pageParams: unknown[];
}

export interface UserListPage {
  data: {
    data: UsersResponseSuccess[];
  };
}

export interface UsersResponseSuccess {
  data: Array<UserPayload>;
  count: {
    active: number;
    total: number;
  };
}

export interface CompanyResponseSuccess {
  data: Array<CompanyPayoad>;
}

export interface DeactivateUserRequestParams {
  comment: string;
  userId: string;
}

export interface UserDetailsResponse {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  country_code: number;
  phone: string;
  avatar?: {
    small: string;
    medium: string;
  };
  position?: string;
  birth_date?: string | null;
  address_1?: string;
  address_2?: string;
  postcode?: string;
  city?: string;
  region?: string;
  country?: string;
  department: string;
  ssn?: string;
  employment_number?: string;
  employed_at?: string;
  education?: string;
  equipment?: EquipmentPayload[];
  licences?: string[];
  clothes_size?: ClothesSize | string;
  allergies?: string;
  comments?: string;
  emergency?: {
    first_name?: string;
    last_name?: string;
    country_code?: number;
    phone?: string;
    relation?: UserEmergencyRelation;
  };
  status: UserStatus;
  status_comment?: string;
  role: UserRole[];
  created_at: string;
  updated_at: string;
  lang?: Language;
}

export interface EditUserRequestParams {
  user: Partial<UserDetailsResponse>;
  id: string;
}

export type UserGetAvatarResponseSuccess = Blob;

export interface UpdateAvatarResponseSuccess {
  small: string;
  medium: string;
}

export type CreatePostDraftResponse = PostDraft;

export interface NewPostPayload {
  title: string;
  description: string;
  attachment_ids?: string[];
  type: string;
  assigned_to?: string[];
}
export interface NewCategoryPayload {
  title: string;
  description: string;
  icon: string;
  visibility?: string;
  assigned_to?: string[];
}

export interface PublishPostRequestParams {
  postId: string;
  post: Partial<NewPostPayload>;
}
export interface CreateCategoryRequestParams {
  categoryId: string;
  category: Partial<NewPostPayload>;
}

export interface AnswerPostRequestParams {
  postId: string;
  option_index: number;
}

export interface DeletePostRequestParams {
  postId: string;
}

export interface SubscibeWebpushNotificationsRequestParams {
  token: string;
}

export type AddAttachmentResponse = Attachment;

export interface GetAttachmentDetailsRequestParams {
  fileUrl: string;
}

export interface GetPostsListParams {
  limit?: number;
  offset?: number;
  access?: AccessLevel;
  type: InstanceType;
  assigned?: string;
  author?: string;
  status?: string;
  state?: string;
  include_reactions?: boolean;
}

export interface Poll {
  options: OptionsPoll[];
  deadline: string;
}

export interface OptionsPoll {
  text: string;
  votes: number;
  is_chosen: boolean;
}

interface Employee {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  avatar?: {
    small: string;
    medium: string;
  };
  status: UserStatus;
  role: UserRole;
}

export type PostAuthor = Employee;
export type TaskAssignee = Employee;

export enum PostSubTypes {
  WELCOME = 'welcome',
  KPI_TARGET = 'completed_kpi_target',
  BIRTHDAY = 'birthday',
  POLL = 'poll_finished',
}

export interface PostAssignee {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  role: string;
  status: string;
  avatar?: {
    small: string;
    medium: string;
  };
}

export interface CategoryAssignee {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  status: string;
  avatar?: {
    small: string;
    medium: string;
  };
  lang: string;
}
export interface PostListItem {
  id: string;
  author: PostAuthor;
  title: string;
  description: string;
  attachments?: Attachment[];
  type: string;
  level?: DocumentsType;
  status: string;
  published_at?: string;
  tags?: string[];
  created_at: string;
  updated_at: string;
  assigned_to?: PostAssignee[];
  reactions_stats: ReactionStats;
  task: {
    state: string;
    deadline?: string | null;
  };
  poll?: Poll;
  updated_by?: Employee;
  comments_count?: number;
  meta?: {
    sub_type: PostSubTypes;
    poll_id?: string;
    kpi_target_id?: string;
  };
}
export interface CategoryListItem {
  id: string;
  title: string;
  description: string;
  icon: string;
  visibility: string[];
  created_at: string;
  updated_at: string;
  updated_by?: Employee;
  assigned_to?: PostAssignee[];
}

export interface CategoryListItemExt {
  id: string;
  title: string;
  description: string;
  icon: string;
  tag?: string;
  visibility: string[];
  created_at: string;
  updated_at: string;
  updated_by?: Employee;
  assigned_to?: PostAssignee[];
}

export enum ReactionTypes {
  LIKE = '👍',
  HEART = '❤️',
}

export interface ReactionStats {
  total: number;
  preview_names: string[];
  active_reaction: ReactionTypes;
}

export interface PostsListsResponseSuccess {
  data: PostListItem[];
  total: number;
}

export interface GetPostDetailsParams {
  postId: string;
  include_reactions?: boolean;
}
export interface GetCategoryDetailsParams {
  categoryId: string;
}

export type CategoryDetailsResponseSuccess = CategoryListItem;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CategoryListSuccessResponse = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UserCompanyListSuccessResponse = any;

export interface DeleteCategoryRequestParams {
  categoryId: string;
}

export type PostsDetailsResponseSuccess = PostListItem;

export interface TaskFormPayload {
  title: string;
  description: string;
  assignee?: string[];
  task?: {
    state: string;
    deadline?: string | null;
  };
  order?: string[];
  type: string;
}

export interface GetProductsListParams {
  period: SubscriptionsPeriod;
  currency: string;
}

export type ProductsListSuccessResponse = SubscriptionPlan[];

export interface GetProductDetailsParams {
  productId: string;
}

export type GetProductDetailsResponseSuccess = ProductDetails;

export type GetSubscriptionDetailsResponse = Subscription;

export interface CountryIpResponse {
  ip: string;
  country: string;
}

export interface CreateCommentParams {
  postId: string;
  content: string;
}

export type CreateCommentSuccessResponse = Comment;

export interface GetCommentsListParams {
  post_id: string;
  limit: string;
  offset: string;
}

export interface GetCommentsListResponse {
  data: Comment[];
  total: 0;
}

export interface CreateSubscriptionParams {
  base_price_id: string;
  extra_price_id: string;
  currency: string;
}

export interface CreateSubscriptionResponse {
  checkout_session_url: string;
}

export interface UpdateSubscriptionParams {
  base_price_id: string;
  extra_price_id: string;
}

export type UpdateSubscriptionResponse = Subscription;

export interface CancelSubscriptionParams {
  comment: string;
}

export type CompanyDetailsResponse = Company;

export type PaymentDetailsResponse = PaymentDetails;

export type EditCompanyResponse = Company;

export type EditCompanyParams = Partial<Company>;

export type GetCalendarEventsParams = {
  start: string;
  end: string;
  provider: CalendarTypes;
};

export type GetCalendarEventsResponse = CalendarEvent[];

export interface CalendarSignInProps {
  type: CalendarTypes;
}

export interface ForgotPasswordRequestParams {
  email: string;
}

export interface RestorePasswordRequestParams {
  ott: string;
  password: string;
}

export interface KpiDetailsResponse {
  vision: string;
  targets: Target[];
}

type KpiFormValues = TargetFormValues;

export type EditKpiParams = Partial<KpiFormValues>;

export interface DeleteTargetRequestParams {
  targetId: string;
}

export type AddTargetRequestParams = TargetFormValues;

export interface AddVisionParams {
  vision: string;
}

export type TargetDetailsResponse = Target;

export interface GetKpiListParams {
  limit?: number;
  kpi_status?: string;
}

export interface ChangeTargetsOrderRequestParams {
  targetsIds: string[];
}

export type UserQuickLinksResponse = QuickLink[];

export type QuickLinksPresetResponse = QuickLink[];

export type CreateQuickLinkRequestParams = QuickLinkFormValues;

export type CreateQuickLinkResponse = QuickLink;

export type AddLinkFromPresetResponse = {
  quick_link_preset_id: string;
};

export interface EditQuickLinkRequestParams extends QuickLinkFormValues {
  linkId: string;
}

export type EditQuickLinkResponse = QuickLink;

export type QuickLinkDetailsResponse = QuickLink;

export interface ChangeLinksOrderRequestParams {
  linksIds: string[];
}

export interface ActivateUserRequestParams {
  user: EditUserRequestParams['user'] & { comment?: string };
  userId: string;
}
export interface EmailValidationParams {
  email: string;
}

export interface ReInviteUserRequestParams {
  userId: string;
}

export interface FinishActivationRequestParams {
  user: {
    password: string;
  };
  ott: string;
}

export interface GetNotificationsListParams {
  limit: string;
  since_id?: string;
}

export interface GetNotificationsListResponse {
  data: Notification[];
  total: number;
}

export interface GetNotificationsCounterResponse {
  amount: number;
}

export interface UnreadNotificationAmountResponse {
  amount: number;
}

export interface ActivateNotificationParams {
  id: string;
  action: string;
}

export type GetUserEquipmentsListResponse = EquipmentPayload[];

export interface RegisterEquipmentRequestParams {
  userId: string;
  data: RegisterEquipmentRequest;
}

export interface DeleteEquipmentRequestParams {
  userId: string;
  equipmentId: string;
}

export interface GetEquipmentRequestParams {
  userId: string;
  equipmentId: string;
}

export interface EditEquipmentRequestParams extends RegisterEquipmentRequestParams {
  equipmentId: string;
}

export interface RequestEquipmentRequestParams {
  userId: string;
  data: RequestEquipmentRequest;
}

export interface RegisterRequestedEquipmentRequestParams {
  userId: string;
  equipmentId: string;
}

export interface AddAttachmentRequestParams {
  file: FormData;
}
export interface AddVideoAttachmentRequestParams {
  video_id: string;
  file_extension: string;
}

export interface DeleteAttachmentRequestParams {
  attachmentId: string;
}

export interface GetReactionRequestParams {
  postId: string;
}

export type GetReactionListResponse = Array<{
  user: UserSignature;
  emoji: ReactionTypes;
  created_at: string;
}>;

export interface UserSignature {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  avatar?: { small: string; medium: string };
  status: UserStatus;
  role: UserRole;
  lang: string;
}

export interface CreateReactionRequestParams {
  postId: string;
  reaction: ReactionTypes;
}

export interface DeleteReactionRequestParams {
  postId: string;
}

export interface CreateAchievementRequest {
  title: string;
  color: string;
  description?: string;
}

export interface CreateAchievementRequestParams {
  userId: string;
  data: CreateAchievementRequest;
}

export interface GetAchievementsRequestParams {
  userId: string;
  limit?: number;
  exclude_accomplished?: boolean;
}

export type GetAchievementsListResponse = Array<Achievement>;

export interface CreateGoalRequestParams {
  userId: string;
  achievementId: string;
  data: CreateGoalRequest;
}

export interface CreateGoalRequest {
  title: string;
  description: string;
  status: GoalStatus;
  deadline?: string | null;
  attachment_ids?: string[];
  assigned_to_ids?: string[];
  assigned_type: AchievementGoalAssignedType;
}

export interface GoalFormValues {
  title: string;
  description: string;
  status: GoalStatus;
  images: Attachment[];
  docs: Attachment[];
  videos: Attachment[];
  deadline?: string | null;
  assignee?: string[];
}

export interface GetAchievementRequestParams {
  userId: string;
  achievementId: string;
}

export interface UpdateAchievementRequest {
  title: string;
  color: string;
  description?: string;
}

export interface EditAchievementRequestParams {
  userId: string;
  achievementId: string;
  data: UpdateAchievementRequest;
}

export interface GetGoalRequestParams {
  userId: string;
  achievementId: string;
  goalId: string;
}

export interface UpdateAchievementGoalRequest {
  title: string;
  description: string;
  status: GoalStatus;
  deadline?: string | null;
  attachment_ids?: string[];
  assigned_to_ids?: string[];
  assigned_type: AchievementGoalAssignedType;
}

export interface EditGoalRequestParams {
  userId: string;
  achievementId: string;
  goalId: string;
  data: UpdateAchievementGoalRequest;
}

export interface PostCommentToAchievementGoalRequest {
  content: string;
}

export interface CommentGoalRequestParams {
  userId: string;
  achievementId: string;
  goalId: string;
  data: PostCommentToAchievementGoalRequest;
}

export interface DeleteAchievementRequestParams {
  userId: string;
  achievementId: string;
}

export interface DeleteGoalRequestParams {
  userId: string;
  achievementId: string;
  goalId: string;
}
