import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonW } from './styled';

export function GetStartedButton(): ReactElement {
  const { t } = useTranslation();

  return (
    <ButtonW type="submit" variant="contained" color="info">
      {t('login.title.getStarted')}
    </ButtonW>
  );
}
