import { ReactElement } from 'react';

import { VisionFormValues } from 'types';

import { VisionForm } from '../VisionForm';
import { useEditVisionForm } from './useEditVisionForm';

interface EditVisionFormProps {
  defaultValues: VisionFormValues;
}

export function EditVisionForm({ defaultValues }: EditVisionFormProps): ReactElement {
  const { handleSubmit, isLoading } = useEditVisionForm();

  return (
    <VisionForm
      mode="edit"
      handleSubmit={handleSubmit}
      defaultValues={defaultValues}
      isSubmitting={isLoading}
    />
  );
}
