import { TextFieldProps } from '@mui/material';
import { ReactElement } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

type CountryCodeSelectProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> &
  PhoneInputProps;

export function CountryCodeSelect<T extends FieldValues>({
  name,
  control,
  rules,
  specialLabel = '',
  placeholder = '',
  ...otherProps
}: CountryCodeSelectProps<T>): ReactElement {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <PhoneInput
      value={value}
      onChange={onChange}
      specialLabel={specialLabel}
      placeholder={placeholder}
      onEnterKeyPress={(e) => {
        e.preventDefault();
        return;
      }}
      onKeyDown={(e) => {
        e.preventDefault();
        return;
      }}
      {...otherProps}
    />
  );
}
