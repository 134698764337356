export const imagesFormGroupStyles = {
  justifyContent: 'flex-start',
  gap: '16px',
  marginBottom: '24px',
};

export const imagesFormGroupMobileStyles = {
  justifyContent: 'flex-start',
  gap: '8px',
  marginBottom: '8px',
};

export const filesFormGroupStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '24px',
};

export const filesFormGroupMobileStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '8px',
};
