import { InputAdornment, styled } from '@mui/material';

export const InputW = styled('div')`
  position: relative;
  &.personalData {
    max-width: 75%;
  }
  @media (max-width: 1023px) {
    width: 100%;
    &.personalData {
      max-width: 100%;
    }
  }
`;

export const InputAdornmentW = styled(InputAdornment)`
  position: absolute;
  right: 0;
  top: 40%;

  @media (max-width: 1023px) {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
