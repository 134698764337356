import { ProductsListSuccessResponse } from 'api';
import { Product, ProductName, StripeProduct, SubscriptionsPeriod } from 'types';

export function formatProducts(frequency: string, data: ProductsListSuccessResponse) {
  const groupedProducts = data.reduce<Record<string, StripeProduct>>(
    (products, { name, prices, id }) => {
      const priceId = Object.keys(prices[frequency as SubscriptionsPeriod])[0];
      const priceDesc = Object.values(prices[frequency as SubscriptionsPeriod])[0][0];

      products[name] = {
        id,
        name,
        price: { ...priceDesc, id: priceId },
      };

      return products;
    },
    {} as Record<ProductName, Product>,
  );

  return groupedProducts;
}
