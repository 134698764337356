import { ChangeEvent, useCallback, useMemo } from 'react';
import { Control, UseFormGetValues, useForm } from 'react-hook-form';

import { useUsersListQuery } from 'api';
import { useFormAttachments } from 'hooks';
import { Attachment, PollFormValues, PostDraft, SelectOption } from 'types';
import { getFullName, objectsDifferences } from 'utils';

import { MAX_OPTIONS_NUMBER, MIN_OPTIONS_NUMBER } from '../../OptionsFields/constants';
import { AllOption } from '../constants';

interface UsePollFormProps {
  draft?: PostDraft | null;
  isEditMode: boolean;
  isPollMutationLoading: boolean;
  defaultValues: (draft?: PostDraft) => PollFormValues;
  onSubmit: (data: PollFormValues, attachmentIds: string[]) => void;
}

interface UsePollFormReturn {
  isSubmitting: boolean;
  isLoading: boolean;
  isDirty: boolean;
  isSubmitted: boolean;
  isValidOptionsNumber: boolean;
  control: Control<PollFormValues>;
  attachedImages: Attachment[];
  attachedDocs: Attachment[];
  attachedVideos: Attachment[];
  usersOptions: SelectOption[];
  progress: number;
  getValues: UseFormGetValues<PollFormValues>;
  clearDeadline: () => void;
  handleFormSubmit: () => void;
  handleAddImages: (files: FileList) => Promise<void>;
  handleAddDocuments: (files: FileList) => Promise<void>;
  handleAddVideos: (files: FileList) => Promise<void>;
  handleDeleteAttachment: (attachmentId: string, type: 'images' | 'docs' | 'videos') => void;
  handleAssigneeChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function usePollForm({
  draft,
  isEditMode,
  isPollMutationLoading,
  defaultValues,
  onSubmit,
}: UsePollFormProps): UsePollFormReturn {
  const defaultFormValues = defaultValues(!isEditMode && draft ? draft : undefined);

  const {
    control,
    reset,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { isDirty, isSubmitting, isSubmitted },
  } = useForm<PollFormValues>({
    defaultValues: defaultFormValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const attachedImages = watch('images');
  const attachedDocs = watch('docs');
  const attachedVideos = watch('videos');
  const options = watch('options');

  const isValidOptionsNumber = useMemo(() => {
    const filledOptionsLength = options.filter(({ value }) => !!value).length;

    return filledOptionsLength >= MIN_OPTIONS_NUMBER && filledOptionsLength <= MAX_OPTIONS_NUMBER;
  }, [options]);

  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  const usersOptions = usersList.map((user) => ({
    value: user.id,
    label:
      user.firstName && user.firstName
        ? getFullName({ firstName: user.firstName, lastName: user.lastName })
        : user.email,
  }));

  const filesChanges = objectsDifferences(
    { images: attachedImages, docs: attachedDocs, videos: attachedVideos },
    {
      images: defaultFormValues.images,
      docs: defaultFormValues.docs,
      videos: defaultFormValues.videos,
    },
  );

  const {
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    isLoadingFileUpload,
    progress,
  } = useFormAttachments({
    setFormValue: setValue,
    attachedDocs,
    attachedImages,
    attachedVideos,
  });

  const clearDeadline = useCallback(() => {
    setValue('deadline', null, { shouldDirty: true });
  }, [setValue]);

  const handleAssigneeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;

      if (!!value && value.length > 1) {
        if (value?.at(-1) === AllOption.value) {
          setValue('visibility', [AllOption.value]);
        } else if (value.includes(AllOption.value)) {
          setValue(
            'visibility',
            (value as unknown as string[]).filter((value) => value !== AllOption.value),
          );
        }
      }
    },
    [setValue],
  );

  const handleFormSubmit = handleSubmit(({ visibility, ...data }: PollFormValues) => {
    if (!isValidOptionsNumber) return;

    const attachmentsIds = [...attachedImages, ...attachedDocs, ...attachedVideos].map(
      (file) => file.id,
    );
    const visibilityIds =
      visibility?.length === 1 && visibility.includes(AllOption.value) ? undefined : visibility;

    onSubmit({ ...data, visibility: visibilityIds }, attachmentsIds);
    isEditMode && reset({ visibility, ...data });
  });

  return {
    isLoading: isPollMutationLoading || isLoadingFileUpload || isLoadingUsersList,
    isSubmitting,
    isDirty: isDirty || !!Object.keys(filesChanges).length,
    isSubmitted,
    isValidOptionsNumber,
    control,
    attachedImages,
    attachedDocs,
    attachedVideos,
    usersOptions,
    progress,
    getValues,
    clearDeadline,
    handleFormSubmit,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    handleAssigneeChange,
  };
}
