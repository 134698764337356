import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { ScrollToTop } from 'components';
import { ContextProviders } from 'context';

import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ContextProviders>
      <ScrollToTop />
      <App />
    </ContextProviders>
  </React.StrictMode>,
);
reportWebVitals();
