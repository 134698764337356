import { Button, styled, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const PageW = styled('div')`
  padding-left: 88px;
  position: relative;
  @media (max-width: 1023px) {
    padding-left: 0px;
  }
`;

export const ColumnsW = styled('div')`
  display: flex;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const PageHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  max-width: 1062px;

  @media (max-width: 1023px) {
    margin-bottom: 16px;
  }
`;

export const Column = styled('div')`
  display: flex;
  flex-direction: column;
  width: 513px;
  min-width: 507px;
  margin-right: 48px;
  @media (max-width: 1023px) {
    width: 100%;
    min-width: 0px;
  }
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const Footer = styled('div')`
  padding: 8px 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p040};
  background: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  margin-bottom: 20px;
  max-width: 1078px;
`;

export const LinkW = styled(Link)`
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
`;

export const NavLinkW = styled(NavLink)`
  color: ${({ theme }) => theme.palette.info.main};
`;
