import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormGroup,
  TextFieldController,
  SubmitRoundedButton,
  PasswordController,
  Form,
  CountryCodeSelect,
  FullPageProgress,
} from 'components';
import { useWindowSize } from 'hooks';
import { stripNonDigits } from 'utils';
import { passwordRules } from 'validations';
import './input.css';

import { useSignUpForm } from './hooks';
import { FIELDS_VALUE_LENGTH, firstNameRules, lastNameRules, phoneNumberRules } from './rules';
import { InputW, PasswordInputW, PhoneInputW, WranDescription } from './styled';
import { countryCodeMobileSx, countryCodeSelectSx, submitBtnMobileSx, submitBtnSx } from './styles';

export function SignUpForm(): ReactElement {
  const { handleFormSubmit, control, isLoading, email_exist } = useSignUpForm();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const codeSx = isMobile
    ? { ...countryCodeSelectSx, ...countryCodeMobileSx }
    : countryCodeSelectSx;

  return (
    <>
      {isLoading && <FullPageProgress />}

      <Form onSubmit={handleFormSubmit} sx={{ marginTop: isMobile ? '32px' : '56px' }}>
        <FormGroup row={!isMobile} marginBottom={isMobile ? 0 : 56}>
          <InputW>
            <TextFieldController
              control={control}
              name="firstName"
              id="firstName"
              label={t('signUp.form.firstName')}
              placeholder={t('signUp.form.firstName')}
              rules={firstNameRules}
              maxLength={FIELDS_VALUE_LENGTH.firstName.max}
            />
          </InputW>
          <InputW>
            <TextFieldController
              name="lastName"
              id="lastName"
              control={control}
              label={t('signUp.form.lastName')}
              placeholder={t('signUp.form.lastName')}
              rules={lastNameRules}
              maxLength={FIELDS_VALUE_LENGTH.lastName.max}
            />
          </InputW>
        </FormGroup>
        <FormGroup row marginBottom={isMobile ? 0 : 56}>
          <PhoneInputW>
            <CountryCodeSelect
              control={control}
              name="countryCode"
              id="countryCode"
              label={t('signUp.form.country')}
              placeholder={t('signUp.form.country')}
              inputStyle={codeSx}
            />
          </PhoneInputW>
          <PhoneInputW>
            <TextFieldController
              name="phone"
              id="phone"
              control={control}
              label={t('signUp.form.phone')}
              placeholder={t('signUp.form.phone')}
              format={stripNonDigits}
              maxLength={FIELDS_VALUE_LENGTH.phone.max}
              rules={phoneNumberRules}
              hint={t('common.phoneHint')}
            />
          </PhoneInputW>
        </FormGroup>
        <FormGroup marginBottom={isMobile ? 24 : 16}>
          <TextFieldController
            name="email"
            id="email"
            control={control}
            type="email"
            label={t('signUp.form.email')}
            placeholder={t('signUp.form.email')}
            autoComplete="new-password"
            maxLength={FIELDS_VALUE_LENGTH.email.max}
            disabled
          />
        </FormGroup>
        <FormGroup marginBottom={0} row sx={{ alignItems: 'flex-end', flexFlow: 'row' }}>
          {!email_exist && (
            <PasswordInputW>
              <PasswordController
                name="password"
                id="password"
                control={control}
                fullWidth={false}
                label={t('signUp.form.password')}
                placeholder={t('signUp.form.password')}
                autoComplete="new-password"
                rules={passwordRules}
                maxLength={FIELDS_VALUE_LENGTH.password.max}
              />
            </PasswordInputW>
          )}
          {email_exist && (
            <WranDescription>{t('companyRegistration.warnMessage.tittle')}</WranDescription>
          )}
          <SubmitRoundedButton
            disabled={isLoading}
            sx={isMobile || email_exist ? submitBtnMobileSx : submitBtnSx}
          />
        </FormGroup>
      </Form>
    </>
  );
}
