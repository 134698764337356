import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CreatePostDraftResponse } from 'api';
import {
  Backdrop,
  BaseTextFieldController,
  ConfirmDialog,
  Form,
  FormGroup,
  SubmitRoundedButton,
  FileUploadButtons,
  FormAttachments,
  RichEditorController,
  SelectController,
} from 'components';
import { NOTIFICATION_FIELDS_LENGTH } from 'constants/fieldsLength';
import { useWindowSize } from 'hooks';
import { textFieldStyles, textFieldMobileStyles, richEditorStyles } from 'styles';
import { User } from 'types';
import { REQUIRED } from 'validations';

import { useCreateNotificationForm } from './hooks';
import { descriptionFieldRules, titleFieldRules } from './rules';
import { FormAndPreviewW, FromContainer } from './styled';

interface CreateNotificationFormProps {
  usersList: User[];
  draft: CreatePostDraftResponse;
}

export function CreateNotificationForm({
  draft,
  usersList,
}: CreateNotificationFormProps): ReactElement {
  const { t } = useTranslation();

  const {
    control,
    handleUsersChange,
    handleFormSubmit,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    attachedImages,
    attachedDocs,
    attachedVideos,
    handleDeleteAttachment,
    usersOptions,
    isLoading,
    isOpen,
    progress,
    handleSubmitConfirmModal,
    handleCloseConfirmModal,
  } = useCreateNotificationForm({ draft, usersList });

  const { isMobile } = useWindowSize();

  const formGroupMarginBottom = isMobile ? 8 : 36;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;

  return (
    <>
      {isLoading && <Backdrop progress={progress} />}

      <FormAndPreviewW>
        <FromContainer>
          <Form onSubmit={handleFormSubmit}>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="title"
                control={control}
                placeholder={t('notifications.create.form.placeholders.title')}
                sx={fieldStyles}
                maxLength={NOTIFICATION_FIELDS_LENGTH.title.max}
                rules={titleFieldRules}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="users"
                control={control}
                placeholder={t('notifications.create.form.placeholders.list')}
                options={usersOptions}
                multipleSelect={true}
                sx={{
                  height: 'auto',
                  minHeight: isMobile ? '64px' : '85px',
                  opacity: 1,
                  '.MuiSelect-select.MuiSelect-select': {
                    height: 'auto',
                    padding: isMobile ? '12px 36px 12px 18px' : '20px 60px 20px 37px',
                    '& > div': {
                      margin: 0,
                    },
                  },
                  '.MuiSvgIcon-root.MuiSelect-icon': {
                    top: isMobile ? '12px' : '22px',
                    right: isMobile ? '16px' : '24px',
                  },
                }}
                rules={{
                  required: REQUIRED,
                }}
                type="notification"
                handleChange={handleUsersChange}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom} row>
              <RichEditorController
                name="description"
                control={control}
                placeholder={t('notifications.create.form.placeholders.description')}
                maxLength={NOTIFICATION_FIELDS_LENGTH.description.max}
                rules={descriptionFieldRules}
                sx={richEditorStyles(isMobile)}
              />

              {isMobile && (
                <FormAttachments
                  attachedImages={attachedImages}
                  attachedDocs={attachedDocs}
                  attachedVideos={attachedVideos}
                  handleDeleteAttachment={handleDeleteAttachment}
                  // draftId={draft.id}
                />
              )}
              <FileUploadButtons
                handleAddImages={handleAddImages}
                handleAddDocuments={handleAddDocuments}
                handleAddVideos={handleAddVideos}
                classNames="notMarginTop"
              />
            </FormGroup>

            {!isMobile && (
              <FormAttachments
                attachedImages={attachedImages}
                attachedDocs={attachedDocs}
                attachedVideos={attachedVideos}
                handleDeleteAttachment={handleDeleteAttachment}
              />
            )}
            {!isMobile && <SubmitRoundedButton sx={{ marginLeft: 'auto', marginTop: '32px' }} />}
          </Form>
        </FromContainer>

        {isMobile && (
          <SubmitRoundedButton
            sx={{ marginLeft: 'auto', marginTop: '32px' }}
            className="fixed-bottom"
            onClick={handleFormSubmit}
          />
        )}
      </FormAndPreviewW>

      <ConfirmDialog
        subTitle={t('notifications.create.modal.exit')}
        title={t('notifications.create.modal.title')}
        open={isOpen}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
