import { saveAs } from 'file-saver';

interface HandleFileDownloadProps {
  blobFile: Blob;
  fileName: string;
}

export async function handleFileDownload({
  blobFile,
  fileName,
}: HandleFileDownloadProps): Promise<void> {
  saveAs(blobFile, fileName);
}
