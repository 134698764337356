import { IconButton, styled } from '@mui/material';

export const ContainerW = styled('div')<{ isCreateGoal?: boolean }>`
  position: relative;
  @media (max-width: 1023px) {
    margin-top: ${({ isCreateGoal }) => !isCreateGoal && '8px'};
  }
`;

export const PickerW = styled('div')`
  position: absolute;
  visibility: hidden;
  top: 0;
  right: 0;
`;

export const IconButtonW = styled(IconButton)`
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 17px 8px -12px rgba(0, 71, 255, 0.12);
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const DateButtonW = styled(IconButtonW)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.info.main};

  .remove {
    color: ${({ theme }) => theme.palette.white.main};
    width: 16px;
    height: 16px;
    display: none;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.info.main};

    .day,
    .month {
      display: none;
    }

    .remove {
      display: block;
    }
  }
`;

export const Day = styled('span')`
  color: ${({ theme }) => theme.palette.white.main};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

export const Month = styled('span')`
  color: ${({ theme }) => theme.palette.white.main};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export const ButtonW = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    margin-bottom: 0px;
  }
`;

export const ButtonLabel = styled('span')`
  font-size: 12px;
  line-height: 10px;
  color: ${({ theme }) => theme.palette.primary.p030};
  margin-top: 8px;
  @media (max-width: 1023px) {
    display: none;
  }
`;
