import { Button, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';

export const ContentW = styled('div')`
  width: 850px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 15px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const ContainerTitle = styled(Typography)`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 16px;
`;

export const ActionItem = styled('div')<{ isBlocked: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: ${({ theme }) => theme.palette.white.main};
  opacity: ${({ isBlocked }) => (isBlocked ? '0.5' : 'unset')};
  color: ${({ theme }) => theme.palette.primary.p080};
  backdrop-filter: blur(20px);
  border-radius: 15px;
  cursor: pointer;

  svg {
    margin-right: 18px;
    width: 32px;
    height: 32px;
  }

  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 14px 16px;
    svg {
      margin-right: 8px;
      width: 32px;
      height: 32px;
    }
  }
`;

export const ActionsList = styled('div')`
  display: grid;
  grid-template-columns: repeat(1fr, 1);
  row-gap: 16px;
`;

export const ButtonW = styled(Button)`
  padding: 0;
  border-radius: 15px;
  width: 100%;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-transform: none;
`;

export const LogoImage = styled('img')`
  width: 30px;
  height: 30px;
  display: block;
  margin-right: 24px;
  margin-left: 6px;
  @media (max-width: 1023px) {
      margin-right: 14px;
      width: 24px;
      height: 24px;
    }
  }
`;

export const TooltipW = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#E5F5FE',
    color: theme.palette.primary.p080,
    boxShadow: '0px 3px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12)',
    borderRadius: '4px',
    fontSize: '16px',
    maxWidth: 340,
    padding: '4px 8px',
    margin: 0,
    left: 0,
    bottom: 0,
    right: 'unset',
  },
}));
