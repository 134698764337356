import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { Equipment } from 'types/equipments';

import { equipmentAdapter } from './adapter';

interface UseEquipmentsQueryReturn {
  isLoading: boolean;
  equipments: Equipment[];
}

interface UseEquipmentsQueryProps {
  userId: string;
}

export function useEquipmentsQuery({ userId }: UseEquipmentsQueryProps): UseEquipmentsQueryReturn {
  const { data, isLoading } = useQuery(['equipments', userId], () =>
    API_HANDLERS.USER.EQUIPMENTS(userId),
  );

  return {
    isLoading,
    equipments: equipmentAdapter(data?.data || []),
  };
}
