import { styled, MenuItem, Menu } from '@mui/material';

import MoreIcon from 'assets/icons/more-icon.svg';

export const MoreButton = styled('div')`
  width: 27px;
  height: 27px;
  background-image: url(${MoreIcon});
  background-size: 100%;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1023px) {
    width: 20px;
    height: 20px;
    transform: translateY(-20px);
  }
`;

export const MenuW = styled(Menu)`
  & > .MuiPaper-root {
    box-shadow: 0px 3px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12);
    border-radius: 8px !important;
    margin-left: 10px;
    width: 145px;
    padding: 0px 8px;

    @media (max-width: 1023px) {
      margin-left: -8px;
    }
  }
`;

export const MenuItemW = styled(MenuItem)`
  position: relative;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  height: 42px;
  min-height: 42px;

  & svg {
    margin-right: 16px;

    & path {
      stroke: #9ca3af;
    }
  }

  &:before {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -8px;
    width: 3px;
    background: #009bf2;
    border-radius: 0px 4px 4px 0px;
  }

  &:hover {
    background: #e5f5fe;

    & svg {
      & path {
        stroke: #009bf2;
      }
    }

    &:before {
      display: block;
    }
  }
`;
