import { useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRegisterCompanyMutation } from 'api';
import { Links } from 'settings';
import {
  RegisterCompanyFormSteps,
  CompanyInfoFormValues,
  CompanyPersonalDataFormValues,
  ApiErrors,
} from 'types';

import { registerRequestPayloadAdapter } from '../adapters';
import { companyProfileFormValues, personalDataDefaultValues } from './config';

interface LocationState {
  repeatRegistration: boolean;
}

interface UseRegistrationPageLogicReturn {
  step: RegisterCompanyFormSteps;
  setStep: (step: RegisterCompanyFormSteps) => void;
  pageTitle: ReactNode;
  personalDataValues: CompanyPersonalDataFormValues;
  companyProfileValues: CompanyInfoFormValues;
  setPersonalDataValues: (values: CompanyPersonalDataFormValues) => void;
  setCompanyProfileValues: (values: CompanyInfoFormValues) => void;
  handleRegisterSubmit: () => void;
  isLoading: boolean;
  errors?: Record<string, ApiErrors['errors']> | null;
}

interface LocationState {
  expired: boolean;
}

export function useRegistrationPageLogic(): UseRegistrationPageLogicReturn {
  const [step, setStep] = useState(RegisterCompanyFormSteps.PERSONAL_DATA);
  const [personalDataValues, setPersonalDataValues] = useState(personalDataDefaultValues);
  const [companyProfileValues, setCompanyProfileValues] = useState(companyProfileFormValues);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { state } = useLocation();

  const { registerCompanyMutation, isLoading, errors } = useRegisterCompanyMutation({
    resend: false,
    showPersonalInfoForm,
  });

  const repeatRegistration = (state as LocationState)?.expired;

  const pageTitle = t(
    repeatRegistration
      ? 'companyRegistration.title.repeatRegistration'
      : 'companyRegistration.title.registration',
  );

  async function handleRegisterSubmit() {
    const requestPayload = registerRequestPayloadAdapter({
      ...personalDataValues,
      ...companyProfileValues,
    });

    await registerCompanyMutation(requestPayload);
    navigate(Links.auth.registration.success, { state: { requestPayload } });
  }

  function showPersonalInfoForm() {
    setStep(RegisterCompanyFormSteps.PERSONAL_DATA);
  }

  return {
    step,
    setStep,
    pageTitle,
    personalDataValues,
    companyProfileValues,
    setPersonalDataValues,
    setCompanyProfileValues,
    handleRegisterSubmit,
    isLoading,
    errors,
  };
}
