import { useQuery } from '@tanstack/react-query';

import { useCurrentSubscriptionQuery } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { SWEDEN_COUNTRY_CODE } from 'constants/index';
import {
  SubscriptionsPeriod,
  Product,
  ProductName,
  SubscriptionStatus,
  SubscriptionType,
} from 'types';

import { isProductDisabled } from './isProductDisabled';

interface UseProductsListProps {
  period: SubscriptionsPeriod;
  location?: string;
}

export function useStripeProducts({ period, location }: UseProductsListProps) {
  const { currentSubscriptions } = useCurrentSubscriptionQuery();
  const { data, isLoading, refetch } = useQuery(
    ['products', period],
    () =>
      API_HANDLERS.PRODUCTS.GET_LIST({
        period,
        currency: !location || location === SWEDEN_COUNTRY_CODE ? 'sek' : 'eur',
      }),
    {
      enabled: !!period && !!location,
    },
  );

  const isTrialPeriod = currentSubscriptions?.type === SubscriptionType.trial;

  const groupedProducts = data?.data.reduce<Record<string, Product>>(
    (products, { name, prices, id }) => {
      const priceId = Object.keys(prices[period])[0];
      const priceDesc = Object.values(prices[period])[0][0];

      const isCurrentPlanNameMatch =
        name.toLowerCase() === currentSubscriptions?.type.toLowerCase();
      const isActiveStatus = currentSubscriptions?.status === SubscriptionStatus.active;
      const isCanceledStatus = currentSubscriptions?.status === SubscriptionStatus.canceled;
      const isPeriodMatch = currentSubscriptions?.period === period;
      const isActivePlan = isCurrentPlanNameMatch && isActiveStatus && isPeriodMatch;

      // TODO: remove canceled_at condition once BE part is ready
      const isCanceled =
        isCurrentPlanNameMatch &&
        (isCanceledStatus || Boolean(currentSubscriptions.canceled_at)) &&
        isPeriodMatch;

      products[name] = {
        id,
        name,
        price: { ...priceDesc, id: priceId },
        isActive: isActivePlan,
        disabled: isProductDisabled({
          productName: name,
          activeProduct: currentSubscriptions,
          period,
          isTrialPeriod,
          isActivePlan,
          subscriptionIsCanceled: Boolean(currentSubscriptions?.canceled_at),
        }),
        // set "blocked" status to products other than isCanceled
        ...(Boolean(currentSubscriptions?.canceled_at) && { isBlocked: !isCanceled, isCanceled }),
      };

      return products;
    },
    {} as Record<ProductName, Product>,
  );

  return {
    refetch,
    isLoading,
    products: groupedProducts as Record<string, Product>,
  };
}
