import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePostDetails } from 'api';
import { GoBackButtonWithTitle, Spinner } from 'components';
import { useCreateDraft, useWindowSize } from 'hooks';

import { CreateDocumentForm, PageHeader } from '../../components';
import { PageW } from './styled';

export function CreateDocumentsPage(): ReactElement {
  const params = useParams();
  const { isMobile } = useWindowSize();
  const { post, isLoading: isDocumentLoading } = usePostDetails({
    postId: params?.documentId || '',
    queryName: 'document-details',
  });

  const { t } = useTranslation();

  const isEdit = !!post;
  const { draft, isLoading } = useCreateDraft(!params?.documentId);

  if (isLoading || isDocumentLoading) {
    return <Spinner />;
  }

  return (
    <PageW>
      {isEdit ? (
        <GoBackButtonWithTitle
          containerSx={{ marginBottom: isMobile ? '16px' : '36px' }}
          title={t('document.create.editTitle')}
        />
      ) : (
        <PageHeader pageTitle={t('document.create.title')} />
      )}
      {(draft || post) && (
        <CreateDocumentForm
          draft={draft ? draft : undefined}
          isEdit={isEdit}
          document={post ? post : undefined}
        />
      )}
    </PageW>
  );
}
