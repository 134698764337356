import { ReactElement } from 'react';

import { useCurrentSubscriptionQuery } from 'api';
import { TopMenu } from 'components';
import { DocumentCategories } from 'features/documentCategories';
import { EmployeeListPreview } from 'features/employeesPreview';
import { Links } from 'settings';

import { OtherResources } from '../../components';
import { useResourcesPageLogic } from './hooks';
import { PageW, RowW } from './styled';

export function ResourcesPage(): ReactElement {
  const { isUsersListLoading, usersList } = useResourcesPageLogic();

  useCurrentSubscriptionQuery();

  return (
    <PageW>
      <TopMenu />
      <DocumentCategories />
      <RowW>
        <EmployeeListPreview
          wrapperSx={{ maxWidth: '724px', marginBottom: '16px' }}
          isUsersListLoading={isUsersListLoading}
          usersList={usersList}
          linkInviteEmployee={Links.resources.employees.invite}
          linkOfListEmployees={Links.resources.employees.index}
        />
        <OtherResources />
      </RowW>
    </PageW>
  );
}
