import { styled, Typography } from '@mui/material';

export const ContentW = styled('div')`
  position: relative;
  height: auto;
  width: auto;
  margin-top: 24px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  overflow: hidden;
  padding: 18px 24px;
`;

export const TitleW = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 16px;
`;

export const ListW = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  height: 410px;
  padding: 0 16px;
  background: #f5f5f5;
`;
