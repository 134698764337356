import { BoxProps } from '@mui/material';
import { ReactElement, SyntheticEvent } from 'react';

import { useAttachmentDetails } from 'api';
import { Spinner } from 'components';
import { handleFileDownload, hideDocNameMidPart } from 'utils';

import { FileName, FileW, FileIconW, DownloadIconW, Badge, ContainerW, Wrapper } from './styled';

interface DocumentAttachmentProps {
  fileName: string;
  fileUrl: string;
  documentId: string;
  postId?: string;
  moreFiles?: number | null;
  wrapperSx?: BoxProps['sx'];
  isPreview?: boolean;
  hasMoreFilesClick?: () => void;
}

const lengthLimit = 8;

export function ListItemDocumentAttachment({
  fileName,
  fileUrl,
  documentId,
  postId,
  moreFiles,
  wrapperSx,
  isPreview = false,
  hasMoreFilesClick,
}: DocumentAttachmentProps): ReactElement {
  const { isRefetching, refetch } = useAttachmentDetails({
    postId,
    attachmentId: documentId,
    fileName,
    fileUrl,
    enabled: false,
  });

  const hasMoreFiles = Boolean(moreFiles);
  const lastDotIndex = fileName.lastIndexOf('.');
  const nameWithoutExtension = fileName.slice(0, lastDotIndex);

  const canDownload = !hasMoreFiles && !isPreview;

  const title =
    nameWithoutExtension.length > lengthLimit
      ? hideDocNameMidPart({ docName: fileName, limit: lengthLimit })
      : fileName;

  async function handleDownloadClick() {
    if (!canDownload) {
      return;
    }

    const { data } = await refetch();

    if (data?.data) {
      await handleFileDownload({ blobFile: data.data, fileName });
    }
  }

  if (isRefetching) {
    return (
      <ContainerW>
        <Spinner size="2.5rem" />
      </ContainerW>
    );
  }

  function handleClick(e: SyntheticEvent) {
    e.stopPropagation();
    if (hasMoreFiles && hasMoreFilesClick) {
      hasMoreFilesClick();
    }
  }

  return (
    <FileW canDownload={canDownload} onClick={handleClick} sx={wrapperSx}>
      <Wrapper className="download-icon-wrapper" onClick={handleDownloadClick}>
        <DownloadIconW className="download-icon" />
      </Wrapper>
      <FileIconW className="file-icon" onClick={handleDownloadClick} />
      <FileName>{title}</FileName>

      {Boolean(moreFiles) && <Badge>+{moreFiles}</Badge>}
    </FileW>
  );
}
