import { styled } from '@mui/material';

export const PageW = styled('div')`
  max-width: 1113px;
  width: 100%;
  margin-left: 88px;
  position: relative;

  @media (max-width: 1023px) {
    margin-left: 0px;
    padding-bottom: 96px;
  }
`;

export const RowW = styled('div')`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;
