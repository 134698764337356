import { ReactElement, SyntheticEvent } from 'react';

import { AchievementsTab, AchievementsTabsValues } from 'types';

import { TabW, TabsW } from './styled';
import { tabItemStyles, tabsStyles } from './styles';

interface TabsProps {
  tabs: AchievementsTab[];
  activeTab: AchievementsTabsValues;
  isAccomplishedTabDisabled: boolean;
  handleChange: (e: SyntheticEvent<Element, Event>, newValue: AchievementsTabsValues) => void;
}

export function Tabs({
  activeTab,
  tabs,
  isAccomplishedTabDisabled,
  handleChange,
}: TabsProps): ReactElement {
  return (
    <TabsW
      value={activeTab}
      onChange={handleChange}
      sx={tabsStyles}
      TabIndicatorProps={{
        style: {
          transition: 'none',
        },
      }}
    >
      {tabs.map(({ value, label }) => (
        <TabW
          key={value}
          value={value}
          label={label}
          disabled={value === AchievementsTabsValues.ACCOMPLISHED && isAccomplishedTabDisabled}
          sx={tabItemStyles}
        />
      ))}
    </TabsW>
  );
}
