import { styled, Typography } from '@mui/material';

export const StepsW = styled('div')`
  display: flex;
  width: 100%;
  margin: 15px 0 30px;
`;

export const Step = styled(Typography, {
  shouldForwardProp: (propname) => propname !== 'isCurrentStep',
})<{ isCurrentStep: boolean }>(({ isCurrentStep, theme }) => ({
  color: `${isCurrentStep ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.3)'}  `,
  fontSize: '24px',
  lineHeight: '36px',
  cursor: 'default',
  [theme.breakpoints.down('desktop')]: {
    fontSize: '18px',
  },
  '&:nth-of-type(1)': {
    marginRight: '30px',
  },
}));

export const FirstStep = styled(Step)(({ theme, isCurrentStep }) => ({
  color: `${isCurrentStep ? theme.palette.primary.p080 : theme.palette.info.main}`,
  cursor: `${isCurrentStep ? 'default' : 'pointer'}`,
}));
