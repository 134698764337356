import { styled } from '@mui/material';

import { theme } from 'styles';

export const EmptyW = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 287px;
`;

export const StyledText = styled('div')`
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  max-width: 490px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
`;

export const UploadButton = styled('div')`
  color: ${theme.palette.info.main};
  display: inline;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 8px;
`;
