import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  position: absolute;
  display: flex;
  right: 0;
  top: -40px;

  @media (max-width: 1023px) {
    right: 15px;
  }
`;
