import { generatePath } from 'react-router-dom';

import { useAchievementsQuery } from 'api';
import { Links } from 'settings';

interface UseProfileAchievementsProps {
  employeeId: string;
}

export function useProfileAchievements({ employeeId }: UseProfileAchievementsProps) {
  const { data: achievements } = useAchievementsQuery({
    userId: employeeId,
    limit: 3,
    exclude_accomplished: true,
  });

  const isAchievementsList = !!achievements.length;

  const addAchievementLink = generatePath(Links.profile.achievements.createAchievement, {
    employeeId,
  });
  const viewAchieventsLink = generatePath(Links.profile.achievements.achievement.index, {
    employeeId,
  });

  return {
    achievements,
    isAchievementsList,
    addAchievementLink,
    viewAchieventsLink,
  };
}
