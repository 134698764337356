import { Button, styled } from '@mui/material';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

export const PageW = styled('div')`
  padding-left: 88px;
  display: flex;
  position: relative;
  flex-direction: column;
  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 16px;
  }
`;

export const ContentW = styled('div')<{ isPollPost?: boolean }>`
  width: 705px;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 705px;
    flex-shrink: 0;
  }
`;

export const ContentWithRemoveW = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const ActionsW = styled('div')`
  margin-left: 328px;
  display: grid;
  gap: 16px;
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MobileActionsW = styled('div')`
  display: none;

  @media (max-width: 1023px) {
    width: fit-content;
    display: flex;
  }
`;

export const RemoveIconW = styled(TrashIcon)`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`;

export const EditIconW = styled(EditIcon)`
  display: none;

  @media (max-width: 1023px) {
    display: block;
    margin-left: -8px;
    width: 22px;
    height: 22px;
  }
`;
