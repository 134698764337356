import { styled } from '@mui/material';

export const LabelW = styled('div')`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
