import { fromUnixTime, isPast } from 'date-fns';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCurrentSubscriptionQuery } from 'api';
import { useSubscription } from 'hooks';
import { Links } from 'settings';
import { SubscriptionsPeriod, SubscriptionType } from 'types';
import { getUnixTimestamp } from 'utils';

export function useSubscriptionsPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isExpired, subscription } = useSubscription();

  const { currentSubscriptions } = useCurrentSubscriptionQuery();
  const unixTimestamp = getUnixTimestamp(currentSubscriptions?.expires_at || '');
  const dateFromUnix = fromUnixTime(unixTimestamp);
  const isCurrentExpired = isPast(dateFromUnix);
  const isCurrentTrial = currentSubscriptions?.type === SubscriptionType.trial;
  const isSubExpired =
    (isCurrentExpired && !currentSubscriptions?.started_at) ||
    (isCurrentExpired && !isCurrentTrial) ||
    (isCurrentExpired && isCurrentTrial);

  const [isToastOpen, setToastOpen] = useState(isSubExpired);

  const [period, setPeriod] = useState(SubscriptionsPeriod.month);
  const [open, setIsOpen] = useState(false);

  const paymentFinished = searchParams.get('payment_completed');

  useEffect(() => {
    setToastOpen(isSubExpired);
  }, [isCurrentExpired, currentSubscriptions?.started_at, isCurrentTrial, isSubExpired]);

  useEffect(() => {
    if (paymentFinished) {
      setIsOpen(true);
    }
    setPeriod(subscription?.period || SubscriptionsPeriod.month);
  }, [paymentFinished, subscription]);

  function handleCloseModal() {
    searchParams.delete('payment_completed');
    setSearchParams(searchParams);
    setIsOpen(false);
  }

  function handleToastClose() {
    setToastOpen(false);
  }

  const goBack = () => {
    navigate(Links.actions.index);
  };

  return {
    open,
    isExpired,
    period,
    goBack,
    setPeriod,
    handleCloseModal,
    isToastOpen,
    handleToastClose,
  };
}
