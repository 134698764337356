import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { LOGOUT_MESSAGE } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

import { bc } from '../broadcastChannel';

interface UseLogoutMutationReturn {
  logoutMutation: UseMutateAsyncFunction<AxiosResponse<unknown, unknown>, unknown, void, unknown>;
  isLoading: boolean;
}

export function useLogoutMutation(): UseLogoutMutationReturn {
  const { mutateAsync: logoutMutation, isLoading } = useMutation(API_HANDLERS.AUTH.LOGOUT, {
    onSuccess: () => {
      bc?.postMessage(LOGOUT_MESSAGE);
    },
  });

  return {
    logoutMutation,
    isLoading,
  };
}
