import '@wangeditor/editor/dist/css/style.css';
import './modal.css';

import {
  IDomEditor,
  IEditorConfig,
  IToolbarConfig,
  i18nChangeLanguage,
  i18nAddResources,
} from '@wangeditor/editor';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import i18next, { t } from 'i18next';
import { useState, ReactElement, CSSProperties, useEffect } from 'react';

import ArrowLeftIcon from 'assets/icons/arrowLeftSlim.svg';
import { useWindowSize } from 'hooks';
import { formatLink } from 'utils';

import {
  EditorHelperText,
  EditorW,
  RichEditorW,
  ToolbarShowButton,
  ToolbarW,
  RichEditorC,
} from './styled';

// rich editor settings
const lang = i18next.language || window.localStorage.i18nextLng;
i18nChangeLanguage(lang);
i18nAddResources('sv', {
  link: {
    edit: t('richEditor.actions.edit'),
    insert: t('richEditor.actions.insert'),
    unLink: t('richEditor.actions.unLink'),
    view: t('richEditor.actions.view'),
  },
  textStyle: {
    bold: t('richEditor.actions.bold'),
    italic: t('richEditor.actions.italic'),
    underline: t('richEditor.actions.underline'),
    through: t('richEditor.actions.through'),
    code: t('richEditor.actions.code'),
  },
  emotion: {
    title: t('richEditor.actions.emotion'),
  },
  listModule: {
    orderedList: t('richEditor.actions.orderedList'),
    unOrderedList: t('richEditor.actions.unOrderedList'),
  },
});

const toolbarConfig: Partial<IToolbarConfig> = {
  toolbarKeys: [
    'bold',
    'italic',
    'underline',
    'through',
    'numberedList',
    'bulletedList',
    'insertLink',
    'code',
    'emotion',
  ],
  modalAppendToBody: true,
};

const editorConfig = (
  placeholder?: string,
  maxLength?: number,
  autoFocus?: boolean,
): Partial<IEditorConfig> => ({
  placeholder,
  maxLength,
  autoFocus: Boolean(autoFocus),
  customPaste: (editor: IDomEditor, event: ClipboardEvent): boolean => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const parsedText = event.clipboardData.getData('text/plain');
    const editorText = editor.getText();
    const textLength = editorText.length + parsedText.length;

    if (maxLength && textLength > maxLength) {
      event.preventDefault();
      return false;
    }

    return true;
  },
  MENU_CONF: {
    fontFamily: {
      fontFamilyList: ['Outfit'],
    },
    insertLink: {
      parseLinkUrl: formatLink,
    },
    parseLink: {
      parseLinkUrl: formatLink,
    },
  },
  hoverbarKeys: {
    link: {
      menuKeys: ['editLink', 'unLink', 'viewLink'],
    },
    text: {
      menuKeys: [],
    },
    code: {
      menuKeys: [],
    },
    divider: {
      menuKeys: [],
    },
    image: {
      menuKeys: [],
    },
    pre: {
      menuKeys: [],
    },
    table: {
      menuKeys: [],
    },
    video: {
      menuKeys: [],
    },
  },
});

export type RichEditorProps = {
  placeholder: string;
  value?: string;
  maxLength?: number;
  sx?: CSSProperties;
  isError?: boolean;
  errorText?: string;
  onChange?: (value: string) => void;
  onBlur?: (value?: string) => void;
  onFocus?: (value?: string) => void;
  isModal?: boolean;
  isResetField?: boolean;
  withRightPadding?: boolean;
};

export function RichEditor({
  value,
  placeholder,
  maxLength,
  sx,
  errorText,
  onChange,
  onBlur,
  onFocus,
  isModal,
  isResetField,
  withRightPadding,
}: RichEditorProps): ReactElement {
  const { isMobile } = useWindowSize();

  const [editor, setEditor] = useState<IDomEditor | null>(null);
  const [isOpen, setOpen] = useState(false);
  const [html, setHtml] = useState(value || '');

  editor?.on('modalOrPanelShow', () => {
    const modals = document?.querySelectorAll('.w-e-modal');

    if (modals) {
      modals.forEach((modal) => {
        const fieldWrappers = modal.querySelectorAll('.babel-container');

        fieldWrappers.forEach((wrapper) => {
          const label = wrapper.querySelector('span') as HTMLSpanElement;
          const labelText = label.innerText;

          if (labelText === 'Link source' || labelText === 'link.url') {
            label.innerText = t('richEditor.titles.link');
          }

          if (labelText === 'Link text' || labelText === 'link.text') {
            label.innerText = t('richEditor.titles.text');
          }
        });

        if (!modal.querySelector('.modal-title')) {
          const title =
            fieldWrappers.length === 1
              ? t('richEditor.titles.editLink')
              : t('richEditor.titles.addLink');
          modal.children[0].insertAdjacentHTML(
            'afterbegin',
            `<div class="modal-header">
              <div class="modal-header-button">
                <img src="${ArrowLeftIcon}" alt="Kiwi standing on oval">
              </div>
              <h3 class="modal-title">${title}</h3>
             </div>`,
          );
          const headerCloseButton = modal.querySelector('.modal-header') as Element;
          headerCloseButton.addEventListener('click', () => {
            editor?.hidePanelOrModal();
            editor?.focus();
          });
        }

        if (!modal.querySelector('.close-btn')) {
          modal
            ?.querySelector('.button-container')
            ?.insertAdjacentHTML(
              'afterbegin',
              `<button type="button" class="close-btn">${t('richEditor.actions.cancel')}</button>`,
            );
          const closeButton = modal.querySelector('.close-btn') as Element;
          closeButton.addEventListener('click', () => {
            editor?.hidePanelOrModal();
            editor?.focus();
          });
        }

        modal.querySelectorAll('button')[0].innerText = t('richEditor.actions.cancel');
        modal.querySelectorAll('button')[1].innerText = t('richEditor.actions.save');
      });
    }
  });

  const handleToolbarOpen = () => {
    setOpen((prevState) => !prevState);
    editor?.focus(true);
    editor?.moveReverse(1);
    editor?.move(1);
  };

  const handleEditorChange = (value: string) => {
    setHtml(value);
    onChange?.(value);
  };

  useEffect(() => {
    if (isResetField !== undefined) {
      editor?.clear();
    }
  }, [isResetField, editor]);

  return (
    <RichEditorC style={sx}>
      {errorText ? <EditorHelperText>{errorText}</EditorHelperText> : null}
      <RichEditorW
        onBlur={() => onBlur?.(html)}
        onFocus={() => onFocus?.(html)}
        isError={Boolean(errorText)}
        isModal={isModal}
      >
        <ToolbarW isOpen={isOpen}>
          <Toolbar editor={editor} defaultConfig={toolbarConfig} mode="default" />
          {isMobile ? <ToolbarShowButton isOpen={isOpen} onClick={handleToolbarOpen} /> : null}
        </ToolbarW>
        <EditorW withRightPadding={withRightPadding}>
          <Editor
            defaultConfig={editorConfig(placeholder, maxLength)}
            value={value || html}
            onCreated={setEditor}
            onChange={(editor) => {
              if (!editor.getText().length) {
                handleEditorChange('');
                return;
              }
              handleEditorChange(editor.getHtml());
            }}
            mode="default"
            style={{ height: isMobile ? '100px' : '157px' }}
          />
        </EditorW>
      </RichEditorW>
    </RichEditorC>
  );
}
