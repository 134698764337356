export const QUERIES = {
  COMPANY_DETAILS: 'company-details',
  USER_DETAILS: 'user-details',
  USER_COMPANY_LIST: 'company-list',
  TASKS_LIST: 'tasks-list',
  TASK_DETAILS: 'task-details',
  CALENDAR_EVENTS: 'calendar-events',
  DOCUMENTS_LIST: 'documents',
  LAST_UPDATED_DOCS: 'last-updated-documents',
  VISION_AND_TARGETS: 'kpi',
  TARGET_DETAILS: 'target-details',
  QUICK_LINKS_LIST: 'quick-links-list',
  QUICK_LINKS_PRESET: 'quick-links-preset',
  QUICK_LINK_DETAILS: 'quick-link-details',
  EMPLOYEES_LIST: 'employees',
  NOTIFICATIONS_LIST: 'notifications-list',
  NOTIFICATIONS_UNREAD: 'notifications-counter',
  EQUIPMENTS_LIST: 'equipments',
  ACTIVATE_TOKEN: 'activateToken',
};
