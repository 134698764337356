import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { ReactComponent as AddGoal } from 'assets/icons/addIcon.svg';
import { useAuth } from 'hooks';
import { Links } from 'settings';
import { Achievement, User } from 'types';

import { AchievementCard } from '../AchievementCard';
import { GoalEmptyState } from '../GoalEmptyState';
import { GoalsList } from '../GoalsList';
import { SectionW, EmptyStateW, Title, AddButton, AccomplishedLabel } from './styled';

interface AchievementSectionProps
  extends Pick<Achievement, 'id' | 'title' | 'description' | 'color' | 'progress' | 'goals'> {
  employeeId: string;
  ownerId: string;
  isFullView: boolean;
  usersList: User[];
  isProfilePreview?: boolean;
}

export function AchievementSection({
  id,
  title,
  description,
  color,
  progress,
  goals = [],
  employeeId,
  usersList,
  isFullView,
  isProfilePreview,
  ownerId,
}: AchievementSectionProps): ReactElement {
  const isGoalsList = !!goals?.length;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user: currentUser, isManager } = useAuth();
  const currentUserId = currentUser?.id ?? '';

  const disableAddGoal =
    (!isManager && currentUserId !== ownerId) ||
    (isManager && employeeId === currentUserId && ownerId !== currentUserId);

  const addGoalLink = generatePath(
    Links.profile.achievements.achievement.achievementId.createGoal,
    {
      achievementId: id,
      employeeId,
    },
  );

  const achievementDetailsLink = generatePath(
    Links.profile.achievements.achievement.achievementId.index,
    {
      achievementId: id,
      employeeId,
    },
  );

  return (
    <SectionW isFullView={isFullView}>
      <AchievementCard
        key={id}
        title={title}
        color={color}
        description={description}
        progress={progress}
        onClick={() => {
          navigate(achievementDetailsLink);
        }}
      />
      {isGoalsList ? (
        <>
          {progress === 100 ? (
            <AccomplishedLabel>
              {t('career.achievements.accomplishedAchievement')}
            </AccomplishedLabel>
          ) : (
            <AddButton
              disabled={disableAddGoal}
              onClick={() => {
                !disableAddGoal && navigate(addGoalLink);
              }}
            >
              <AddGoal />
              <Title>{t('career.achievements.addGoal')}</Title>
            </AddButton>
          )}
          <GoalsList
            data={goals}
            isProfilePreview={isProfilePreview}
            achievementId={id}
            employeeId={employeeId}
            usersList={usersList}
          />
        </>
      ) : (
        <EmptyStateW>
          <GoalEmptyState addGoalLink={addGoalLink} />
        </EmptyStateW>
      )}
    </SectionW>
  );
}
