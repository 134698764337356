import { PostListItem } from 'api';
import { Attachment, PostDraft } from 'types';

interface DefaultValuesAdapterProps {
  draft?: PostDraft;
  document?: PostListItem;
}

interface DefaultValuesAdapterReturn {
  title: string;
  description: string;
  images: Attachment[];
  docs: Attachment[];
  videos: Attachment[];
}

export function defaultValuesAdapter({
  draft,
  document,
}: DefaultValuesAdapterProps): DefaultValuesAdapterReturn {
  const imagesList =
    draft?.attachments?.filter((file) => file.type === 'image') ||
    document?.attachments?.filter((file) => file.type === 'image') ||
    [];
  const documentsList =
    draft?.attachments?.filter((file) => file.type === 'document') ||
    document?.attachments?.filter((file) => file.type === 'document') ||
    [];
  const videosList =
    draft?.attachments?.filter((file) => file.type === 'video') ||
    document?.attachments?.filter((file) => file.type === 'video') ||
    [];

  return {
    title: document?.title || '',
    description: document?.description || '',
    images: imagesList,
    docs: documentsList,
    videos: videosList,
  };
}
