import { IconButton, styled, Typography, Button } from '@mui/material';

export const FormContainer = styled('div')`
  width: 671px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 36px 25px;
  position: relative;
  z-index: 99;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
    margin-bottom: 110px;
  }
`;

export const CloseButtonW = styled(IconButton)`
  margin-left: auto;
  position: fixed;
  top: 79px;
  right: 88px;
`;

export const ButtonsW = styled('div')`
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    flex-direction: row;
    column-gap: 16px;
  }
`;

export const FormFooter = styled('div')`
  display: flex;
`;

export const LastUpdateW = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p040};
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-top: 6px;
  }
`;

export const AuthorName = styled(Typography)`
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  bottom: -18px;
  left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 500px) {
    width: 200px;
  }

  @media (max-width: 400px) {
    width: 120px;
  }

  @media (max-width: 1023px) {
    bottom: -25px;
  }
`;

export const PublishedDateW = styled(Typography)`
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  bottom: -18px;
  right: 16px;
  @media (max-width: 1023px) {
    bottom: -25px;
  }
`;

export const RemoveButtonW = styled(Button)`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    text-transform: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.palette.primary.p080};
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
  }
`;
