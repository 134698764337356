import { styled } from '@mui/material';

export const InputHint = styled('span')`
  position: absolute;
  bottom: -22px;
  left: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.p050};
`;

export const InputW = styled('div')`
  position: relative;
  width: 100%;
`;
