import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { EditEquipmentRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseEditEquipmentMutationReturn {
  editEquipmentMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    EditEquipmentRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseEditEquipmentMutationProps {
  successText?: string;
  invalidateQueries?: string[];
}

export function useEditEquipmentMutation({
  successText,
  invalidateQueries,
}: UseEditEquipmentMutationProps): UseEditEquipmentMutationReturn {
  const { mutateAsync: editEquipmentMutation, isLoading } = useMutation(
    API_HANDLERS.EQUIPMENTS.EDIT,
    {
      onSuccess: () => {
        successText && alert.success(successText);
        invalidateQueries && queryClient.invalidateQueries(invalidateQueries);
      },
    },
  );

  return {
    editEquipmentMutation,
    isLoading,
  };
}
