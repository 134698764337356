export const tagsFieldStyles = (disabled: boolean) => ({
  height: 'auto',
  minHeight: '85px',
  opacity: disabled ? '0.5' : 1,
  '.MuiSelect-select.MuiSelect-select': {
    height: disabled ? '85px' : 'auto',
  },
});

export const tagsFieldMobileStyles = (disabled: boolean) => ({
  height: 'auto',
  minHeight: '60px',
  opacity: disabled ? '0.5' : 1,
  '.MuiSelect-select.MuiSelect-select': {
    height: 'auto',
  },
});
