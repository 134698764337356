import { ReactElement } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { RichEditor, RichEditorProps } from 'components/Fields/RichEditor';

export type RichEditorControllerProps<T extends FieldValues> = UseControllerProps<T> &
  RichEditorProps & {
    name: string;
  };

export function RichEditorController<T extends FieldValues>({
  name,
  placeholder,
  maxLength,
  onFocus,
  sx,
  control,
  rules,
  isModal,
  isResetField,
  withRightPadding,
}: RichEditorControllerProps<T>): ReactElement {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: rules?.required,
    },
  });

  return (
    <RichEditor
      value={value}
      placeholder={placeholder}
      maxLength={maxLength}
      sx={sx}
      isError={Boolean(error && error.message)}
      errorText={error?.message}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      isModal={isModal}
      isResetField={isResetField}
      withRightPadding={withRightPadding}
    />
  );
}
