export const submitBtnSx = {
  marginLeft: 'auto',

  '@media (max-width: 1023px)': {
    position: 'absolute',
    top: '130px',
    right: 0,
    width: '56px',
    height: '56px',
  },
};

export const hintsSx = {
  '@media (max-width: 1023px)': {
    left: 'unset',
  },
};
