import { ReactElement } from 'react';

import { AchievementFormValues } from 'types';

import { AchievementForm } from '../AchievementForm';
import { useCreateAchievementForm } from './hooks';

interface CreateAchievementFormProps {
  defaultValues: AchievementFormValues;
}

export function CreateAchievementForm({ defaultValues }: CreateAchievementFormProps): ReactElement {
  const { handleSubmit, isLoading } = useCreateAchievementForm();

  return (
    <AchievementForm isLoading={isLoading} defaultValues={defaultValues} onSubmit={handleSubmit} />
  );
}
