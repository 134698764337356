import { ReactElement } from 'react';

import { VisionForm } from '../VisionForm';
import { useCreateVisionForm } from './useCreateVisionForm';

export function CreateVisionForm(): ReactElement {
  const { handleSubmit, isLoading, defaultValues } = useCreateVisionForm();

  return (
    <VisionForm
      mode="create"
      handleSubmit={handleSubmit}
      defaultValues={defaultValues}
      isSubmitting={isLoading}
    />
  );
}
