import { Dialog as DialogMUI, DialogContent as DialogContentMUI, styled } from '@mui/material';
import { Swiper as ReactSwiper, SwiperSlide as Slide } from 'swiper/react';

export const Dialog = styled(DialogMUI)(() => ({
  '.MuiDialog-paper': {
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(20px)',
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
}));

export const DialogContent = styled(DialogContentMUI)`
  padding: 0px;
  display: flex;
`;

export const Image = styled('img')`
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const SwiperSlide = styled(Slide)`
  display: flex;
  background: rgba(255, 255, 255);
`;

export const Swiper = styled(ReactSwiper)`
  padding: 0px;
`;

export const DialogActions = styled('div')`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  column-gap: 24px;
  padding-top: 16px;
`;

export const ButtonW = styled('div')`
  cursor: pointer;
  z-index: 2;
  svg {
    width: 24px;
    height: 24px;
  }
`;
