import { ReactElement } from 'react';

import { Backdrop } from 'components';
import { Achievement } from 'types';

import { AchievementForm } from '../AchievementForm';
import { useEditAchievementForm } from './hooks';

interface EditAchievementFormProps {
  data?: Achievement;
  isDetailsLoading: boolean;
  isDetailsLoaded: boolean;
  handleRemoveClick: () => void;
}

export function EditAchievementForm({
  isDetailsLoading,
  isDetailsLoaded,
  data,
  handleRemoveClick,
}: EditAchievementFormProps): ReactElement {
  const { isEditAchievementLoading, defaultValues, author, createdAt, handleSubmit } =
    useEditAchievementForm({ data });

  return (
    <>
      {isDetailsLoading && <Backdrop />}

      {isDetailsLoaded && (
        <AchievementForm
          mode="edit"
          isLoading={isEditAchievementLoading}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          author={author}
          createdAt={createdAt}
          handleRemoveClick={handleRemoveClick}
        />
      )}
    </>
  );
}
