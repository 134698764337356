import { BoxProps, Tooltip } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Avatar, PreviewContainer, Spinner } from 'components';
import { useSubscription, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { User } from 'types';
import { getFullName } from 'utils';

import { EmployeesListW } from './styled';

interface EmployeeListPreviewProps {
  isUsersListLoading: boolean;
  usersList: User[];
  linkInviteEmployee: string;
  linkOfListEmployees: string;
  wrapperSx?: BoxProps['sx'];
}

export function EmployeeListPreview({
  isUsersListLoading,
  usersList,
  linkInviteEmployee,
  linkOfListEmployees,
  wrapperSx,
}: EmployeeListPreviewProps): ReactElement {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const { isTrial } = useSubscription();

  const titleLink = pathname.includes('resources')
    ? Links.resources.employees.index
    : Links.we.employees.index;

  const inviteEmployeeLink = isTrial ? '' : linkInviteEmployee;

  return (
    <PreviewContainer
      title={t('we.employeeList.title')}
      handleTitleClick={() => navigate(titleLink)}
      addIconLink={inviteEmployeeLink}
      maximizeIconLink={linkOfListEmployees}
      containerSx={{ height: isMobile ? 'unset' : '182px', ...wrapperSx }}
      headerSx={{ marginBottom: isMobile ? '11px' : '24px' }}
    >
      <EmployeesListW>
        {isUsersListLoading && <Spinner />}
        {usersList.map(({ id, firstName, lastName, email, avatar }) => {
          const fullName = getFullName({ firstName, lastName });
          const tooltipTitle = fullName || email;

          return (
            <Tooltip key={id} title={tooltipTitle}>
              <span>
                <Avatar key={id} alt={firstName} userId={id} filename={avatar?.small || ''} />
              </span>
            </Tooltip>
          );
        })}
      </EmployeesListW>
    </PreviewContainer>
  );
}
