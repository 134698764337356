import { isEqual } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PostListItem } from 'api';
import { TIME_DATE_FORMAT_WITH_COMMA } from 'constants/index';
import { useWindowSize } from 'hooks';
import { Attachment, CategoriesListType, InViewItemRef } from 'types';
import { formattedDate } from 'utils';

import { DocumentActions } from '../DocumentActions';
import { DocumentAttachments } from '../DocumentAttachments';
import { DocumentMainInfo } from '../DocumentMainInfo';
import { CardW } from './styled';

interface DocumentItemPropsType extends PostListItem {
  tag?: string;
  lastItemRef?: InViewItemRef;
  categoryMap: CategoriesListType;
}

export const DocumentItem = ({
  title,
  description,
  created_at,
  tag,
  attachments,
  id,
  author,
  level,
  published_at,
  updated_at,
  lastItemRef,
  tags,
  categoryMap,
}: DocumentItemPropsType) => {
  const [isExpandedCard, setIsExpandedCard] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const isNotUpdated = isEqual(new Date(published_at || ''), new Date(updated_at));
  const date = isNotUpdated
    ? formattedDate(new Date(published_at || ''), TIME_DATE_FORMAT_WITH_COMMA)
    : t('document.list.updatedOn', {
        date: formattedDate(new Date(updated_at), TIME_DATE_FORMAT_WITH_COMMA),
      });

  const tagName = tag;

  const TAG = tag && tagName ? categoryMap[tagName] : null;

  const documentTags =
    tags
      ?.map((tag) => {
        const tagName = tag;
        return categoryMap[tagName];
      })
      .filter((tag) => tag !== undefined && tag !== null) || [];

  return (
    <CardW sx={{ maxHeight: isExpandedCard || isMobile ? 'none' : '241px' }} ref={lastItemRef}>
      <DocumentMainInfo
        title={title}
        tag={TAG}
        documentTags={documentTags}
        created_at={created_at}
        isExpandedCard={isExpandedCard}
        description={description}
        setIsExpandedCard={setIsExpandedCard}
        attachmentsLength={attachments ? attachments.length : 0}
        date={date}
        author={author}
        id={id}
        level={level}
        tags={tag}
        categoryMap={categoryMap}
      />
      {attachments && attachments.length && (
        <DocumentAttachments
          attachments={attachments as Attachment[]}
          postId={id}
          isExpandedCard={isExpandedCard}
          setIsExpandedCard={setIsExpandedCard}
        />
      )}
      {!isMobile && (
        <DocumentActions author={author} documentId={id} tag={tag} level={level} title={title} />
      )}
    </CardW>
  );
};
