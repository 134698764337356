import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { AuthPageWrapper, AuthPageTitle } from 'features/auth';
import { AuthPageLink } from 'types';

import { SignInForm } from '../SignInForm';
import { TextW } from './styled';

type LocationState = {
  companyRegistration?: boolean;
  userSignUp?: boolean;
};

export function SignInPage(): ReactElement {
  const { state = {} } = useLocation();
  const userSignUp = (state as LocationState)?.userSignUp;
  const { t } = useTranslation();

  const firstTimeSignIn = Boolean(state);

  const pageTitle = t(firstTimeSignIn ? 'login.title.firstTime' : 'login.title.regular');
  const subtitle = t('login.subtitle.userSignUp');

  return (
    <AuthPageWrapper link={firstTimeSignIn ? undefined : AuthPageLink.signUp}>
      <AuthPageTitle>{pageTitle}</AuthPageTitle>

      {firstTimeSignIn && userSignUp && <TextW>{subtitle}</TextW>}

      <SignInForm firstTimeSignIn={Boolean(firstTimeSignIn)} />
    </AuthPageWrapper>
  );
}
