import { Box } from '@mui/material';
import { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGoalDetailsQuery, useUsersListQuery } from 'api';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { GoBackButtonWithTitle, PageWrapper, Backdrop, ConfirmDialog } from 'components';
import { RefetchGoalDetails } from 'features/career/types';
import { CommentsList } from 'features/comments';
import { useAuth } from 'hooks';

import { AddGoalCommentForm, EditGoalForm } from '../../components';
import { useDeleteGoal } from './hooks';
import {
  ActionsW,
  ButtonW,
  CommentsCountW,
  CommentsW,
  ContentW,
  Count,
  FormW,
  PageHeaderW,
} from './styled';
import { commentsListWrapSx } from './styles';

export function EditGoalPage(): ReactElement {
  const { t } = useTranslation();
  const commentsSectionRef = useRef<HTMLDivElement>(null);
  const { achievementId = '', employeeId: userId = '', goalId = '' } = useParams();
  const { user: currentUser, isManager } = useAuth();
  const currentUserId = currentUser?.id ?? '';

  const executeScroll = () =>
    commentsSectionRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });

  const {
    data,
    isLoading: isDetailsLoading,
    isSuccess: isDetailsLoaded,
    refetch: refetchGoal,
  } = useGoalDetailsQuery({
    userId,
    achievementId,
    goalId,
  });

  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  const {
    isConfirmDeleteLoading,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    handleDeleteGoal,
    handleRemoveClick,
  } = useDeleteGoal();

  const goalOwnerId = data?.owner_id ?? '';
  const isRemoveAvailable =
    (userId === goalOwnerId && currentUserId === goalOwnerId) ||
    (isManager && currentUserId !== goalOwnerId && currentUserId !== userId);

  const commentsCount = data?.comments?.length ?? 0;
  const commentsWording = commentsCount > 1 ? 'comments.total.plural' : 'comments.total.singular';

  return (
    <>
      <PageWrapper>
        <PageHeaderW>
          <GoBackButtonWithTitle title={t('career.achievements.edit.title')} />
          {!!commentsCount && (
            <CommentsCountW onClick={executeScroll}>
              <Count>
                {commentsCount} {t(commentsWording)}
              </Count>
            </CommentsCountW>
          )}
        </PageHeaderW>
        <>
          <ContentW>
            <FormW>
              {(isDetailsLoading || isLoadingUsersList) && <Backdrop />}
              {data && isDetailsLoaded && (
                <EditGoalForm
                  data={data}
                  usersList={usersList}
                  isDetailsLoaded={isDetailsLoaded}
                  handleRemoveClick={handleRemoveClick}
                  refetchGoal={refetchGoal as unknown as RefetchGoalDetails}
                />
              )}
            </FormW>
            <ActionsW>
              {isRemoveAvailable && (
                <ButtonW onClick={handleRemoveClick} endIcon={<TrashIcon />}>
                  {t('tasks.details.removeButton')}
                </ButtonW>
              )}
            </ActionsW>
          </ContentW>
          {isDetailsLoaded && (
            <CommentsW ref={commentsSectionRef}>
              <Box sx={{ marginTop: '32px' }}>
                <AddGoalCommentForm userId={userId} achievementId={achievementId} goalId={goalId} />
              </Box>
              <CommentsList
                comments={data?.comments ?? []}
                isLoading={false}
                isFetchingNextPage={false}
                lastItemRef={undefined}
                listWrapSx={commentsListWrapSx}
              />
            </CommentsW>
          )}
        </>
      </PageWrapper>

      <ConfirmDialog
        title={t('career.goals.remove.title')}
        subTitle={t('career.goals.edit.title')}
        open={isDeleteModalOpen}
        onSubmit={handleDeleteGoal}
        onClose={handleCloseDeleteModal}
        isLoading={isConfirmDeleteLoading}
      />
    </>
  );
}
