import { useMutation } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';

import { queryClient } from '../queryClient';

interface UseActivateMutationProps {
  onSuccessFunc?: () => void;
  invalidateQuery: string;
}

export function useActivatePendingUserMutation({
  onSuccessFunc,
  invalidateQuery,
}: UseActivateMutationProps) {
  const { mutateAsync: activateUserMutation, isLoading } = useMutation(
    API_HANDLERS.USER.ACTIVATE_PENDING,
    {
      onSuccess: () => {
        onSuccessFunc?.();
        queryClient.invalidateQueries([invalidateQuery]);
      },
    },
  );

  return {
    activateUserMutation,
    isLoading,
  };
}
