import { Language } from 'types';

export const textAreaFieldStyles = {
  width: '100%',

  '& .MuiInputBase-root': {
    borderRadius: '15px',
    padding: 0,
  },

  '.MuiInputBase-input': {
    padding: '24px 100px 24px 24px',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '18px',
  },
};

export const textAreaFieldMobileStyles = (lang: Language) => ({
  width: '100%',

  '& .MuiInputBase-root': {
    borderRadius: '15px',
    padding: 0,
  },

  '.MuiInputBase-input': {
    padding: lang && lang === Language.swedish ? '16px 120px 16px 12px' : '16px 50px 16px 12px',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
  },
});

export const formStyles = {
  position: 'relative',
  borderRadius: '15px',
  width: '560px',
  maxWidth: '560px',

  '@media (max-width: 1023px)': {
    width: '100%',
    maxWidth: '560px',
  },
};

export const loaderStyles = {
  position: 'absolute',
  zIndex: '10',
  top: '0',
  width: '100%',
  left: '0',
};

export const commentRichEditorStyles = (isMobile: boolean) => ({
  width: isMobile ? '100%' : '560px',
  maxWidth: '560px',
  marginBottom: isMobile ? '8px' : '0',
});
