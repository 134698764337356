import { ReactElement } from 'react';

import { Backdrop, Form, SelectField, SubmitRoundedButton } from 'components';

import { EmailListItem } from '../EmailListItem';
import { USER_OPTIONS } from './constants';
import { useInviteEmployeesForm } from './hooks';
import { FormW, ListW, FieldsW } from './styled';

interface InviteEmployeesFormProps {
  setFormHasContent: (value: boolean) => void;
}

export function InviteEmployeesForm({ setFormHasContent }: InviteEmployeesFormProps): ReactElement {
  const {
    handleSubmit,
    handleRemoveItem,
    inputRef,
    usersList,
    isLoading,
    isManager,
    handleUserItemOnChange,
    handleUserSetRole,
  } = useInviteEmployeesForm({ setFormHasContent });

  return (
    <>
      {isLoading && <Backdrop />}

      <Form
        onSubmit={handleSubmit}
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <FormW isManager={isManager}>
          <ListW>
            {usersList &&
              usersList.map(({ id, email, role }, index) => {
                return (
                  <FieldsW key={index} isManager={isManager}>
                    <EmailListItem
                      id={id}
                      role={role}
                      email={email}
                      handleRemove={handleRemoveItem}
                      handleOnChange={handleUserItemOnChange}
                      inputRef={index === usersList.length - 1 ? inputRef : null}
                    />
                    {isManager ? (
                      <SelectField
                        key={role}
                        id={id}
                        options={USER_OPTIONS}
                        defaultValue={role || USER_OPTIONS[0].value}
                        name={email}
                        onChange={handleUserSetRole}
                      />
                    ) : null}
                  </FieldsW>
                );
              })}
          </ListW>
          <SubmitRoundedButton
            disabled={isLoading}
            sx={{ marginTop: '32px', marginLeft: 'auto' }}
            className="fixed-bottom"
          />
        </FormW>
      </Form>
    </>
  );
}
