import { useMutation } from '@tanstack/react-query';
import { useForm, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { Links } from 'settings';
import { ActivateUserPasswordFormValues } from 'types';

import { defaultValues } from './config';

interface UseCreatePasswordFormReturn {
  isLoading: boolean;
  handleFormSubmit: () => void;
  control: Control<ActivateUserPasswordFormValues>;
}

interface LocationState {
  ott: string;
}

export function useCreatePasswordForm(): UseCreatePasswordFormReturn {
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ActivateUserPasswordFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { t } = useTranslation();
  const { ott } = state as LocationState;

  const { mutateAsync: createPasswordResetMutation, isLoading } = useMutation(
    API_HANDLERS.AUTH.FINISH_ACTIVATION,
    {
      onSuccess: () => {
        alert.success(t('activateEmployee.passwordCreated'));
        navigate(Links.auth.signIn);
      },
    },
  );

  const handleFormSubmit = handleSubmit(async ({ password }) => {
    await createPasswordResetMutation({ user: { password }, ott });
  });

  return {
    isLoading: isSubmitting || isLoading,
    control,
    handleFormSubmit,
  };
}
