import i18n from 'i18n';
import { DocumentsType } from 'types';

export const MAX_TITLE_LENGTH = 70;
export const MAX_ARTICLE_LENGTH = 1000;

export const DOCUMENTS_TYPE = [
  { value: DocumentsType.PUBLIC, label: i18n.t('document.create.docTypes.public') },
  { value: DocumentsType.PRIVATE, label: i18n.t('document.create.docTypes.private') },
  { value: DocumentsType.SHARED, label: i18n.t('document.create.docTypes.shared') },
];
