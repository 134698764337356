import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { useModal } from 'hooks';
import { Product, SubscriptionsPeriod } from 'types';

import { useProductDetails } from './useProductDetails';

interface UseSubscriptionProps {
  product: Product;
  extraProduct: Product;
  period: SubscriptionsPeriod;
}

export function useSubscriptionDetails({ product, extraProduct, period }: UseSubscriptionProps) {
  const { data } = useQuery(['product-details', product.id], () =>
    API_HANDLERS.PRODUCTS.GET_DETAILS({ productId: product.id }),
  );

  const { mutateAsync: startSubscriptionMutation, isLoading: isLoadingStartSubscription } =
    useMutation(API_HANDLERS.SUBSCRIPTION.CREATE, {
      onSuccess: (data) => {
        if (data.data.checkout_session_url) {
          window.location.replace(data.data.checkout_session_url);
        }
      },
    });

  const { mutateAsync: updateSubscriptionMutation, isLoading: isLoadingUpdateSubscription } =
    useMutation(API_HANDLERS.SUBSCRIPTION.UPDATE, {
      onSuccess: () => {
        queryClient.invalidateQueries(['subscription-details']);
      },
    });

  const { isOpen, handleCloseModal, handleOpenModal } = useModal();

  const {
    isOpen: isOpenUpgradeConfirm,
    handleCloseModal: handleCloseModalUpgradeConfirm,
    handleOpenModal: handleOpenUpgradeConfirmModal,
  } = useModal();

  const {
    isOpen: isOpenUpgrade,
    handleCloseModal: handleCloseUpgradeModal,
    handleOpenModal: handleOpenUpgradeModal,
  } = useModal();

  const {
    isOpen: isOpenInfo,
    handleCloseModal: handleCloseInfoModal,
    handleOpenModal: handleOpenInfoModal,
  } = useModal();

  const {
    isOpen: isOpenCancel,
    handleCloseModal: handleCloseCancelModal,
    handleOpenModal: handleOpenCancelModal,
  } = useModal();

  const subscriptionDetails = useProductDetails({
    productDetails: data?.data,
    mainProduct: product,
    extraProduct,
    period,
  });

  async function handleStartSubscription() {
    await startSubscriptionMutation({
      base_price_id: product.price.id,
      extra_price_id: extraProduct.price.id,
      currency: product.price.currency,
    });
  }

  async function handleUpdateSubscription() {
    await updateSubscriptionMutation({
      base_price_id: product.price.id,
      extra_price_id: extraProduct.price.id,
    });
    handleCloseUpgradeModal();
    handleOpenUpgradeConfirmModal();
  }

  return {
    isOpen,
    handleCloseModal,
    handleOpenModal,
    startSubscriptionMutation,
    isLoadingStartSubscription,
    handleStartSubscription,
    handleUpdateSubscription,
    isLoadingUpdateSubscription,
    isOpenUpgrade,
    handleCloseUpgradeModal,
    handleOpenUpgradeModal,
    subscriptionDetails,
    isOpenUpgradeConfirm,
    handleCloseModalUpgradeConfirm,
    isOpenInfo,
    handleCloseInfoModal,
    handleOpenInfoModal,
    isOpenCancel,
    handleCloseCancelModal,
    handleOpenCancelModal,
  };
}
