import { styled, Typography } from '@mui/material';

import { theme } from 'styles';

export const CardW = styled('div')`
  background: #ffffff;
  box-shadow: 0px 34px 34px rgba(13, 46, 97, 0.05);
  border-radius: 15px;
  padding: 20px 16px 16px;
  cursor: pointer;
  &:hover {
    background: ${theme.palette.info.main};
  }
  @media (max-width: 1023px) {
    padding: 16px 32px 16px 14px;
    &.collapsed {
      display: flex;
      justify-content: center;
      padding: 14px;
    }
  }
`;

export const TitleW = styled('div')`
  display: flex;
  align-items: center;
  svg {
    width: 36px;
    height: 36px;
  }
`;

export const TitleText = styled('div')`
  font-size: 18px;
  line-height: 30px;
  margin-left: 16px;

  @media (max-width: 1023px) {
    line-height: 24px;
    font-weight: 500;
  }
`;

export const Description = styled(Typography)`
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: rgba(0, 0, 0, 0.5);
`;

export const DocumentCategoryHeaderW = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DocumentCategoryActionW = styled('div')`
  display: flex;
  align-items: center;
`;
