export const attachmentStyles = {
  width: '118px',
  height: '118px',
  marginRight: '0px',
};

export const attachmentMobileStyles = {
  width: '87px',
  height: '87px',
  marginRight: '0px',
};

export const avatarWrapperStyles = { position: 'absolute', bottom: '-18px', left: '-18px' };
export const avatarWrapperMobileStyles = { position: 'absolute', bottom: '-32px', left: '0px' };
