import { isBefore } from 'date-fns';
import { ReactElement, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';

import { useActivateNotification } from 'api/queries/useActivateNotification';
import { ReactComponent as CustomDefaultAvatar } from 'assets/icons/logos/starLogo.svg';
import { Avatar } from 'components';
import { useAuth } from 'hooks';
import i18n from 'i18n';
import { Links } from 'settings';
import {
  InViewItemRef,
  NotificationType as NotificationEnum,
  Notification as NotificationType,
} from 'types';
import { getUserFromNotification } from 'utils/getUserFromNotification';

import { NotificationDescription } from '../NotificationDescription';
import {
  ActivateButtonW,
  AdligoStarLogoW,
  BlueCircle,
  ContentW,
  DateW,
  FlexW,
  StrongW,
  CompanyWrapper,
} from './styled';
import { formatDateTime } from './utils';

type NotificationProps = NotificationType & {
  isPreview?: boolean;
  addToIsRead?: (id: string) => void;
  lastItemRef?: InViewItemRef;
  isActive?: boolean;
};

interface LocationI {
  pathname: string;
  state?: { from: string };
}

export function Notification({
  isPreview = false,
  lastItemRef,
  addToIsRead,
  isActive,
  ...notification
}: NotificationProps): ReactElement {
  const { ref: viewRef, inView } = useInView({
    threshold: 0.7,
  });
  const payloadBy = getUserFromNotification(notification);
  const [isRead, setIsRead] = useState(notification.is_read);

  const isBirthdayType =
    notification.type === NotificationEnum.birthday ||
    notification.type === NotificationEnum.birthday_today;

  const isDefaultLogo =
    notification.type === NotificationEnum.welcome ||
    notification.type === NotificationEnum.due ||
    notification.type === NotificationEnum.equipment_completed ||
    notification.type === NotificationEnum.equipment_expires_soon;

  const isDisabled = isBirthdayType
    ? notification.payload.is_post_action_disabled ||
      isBefore(new Date(notification.payload?.expires_at ?? ''), new Date())
    : false;

  const [isActivatePressed, setIsActivatePresed] = useState(isDisabled);
  const { isManager } = useAuth();

  const activateButtonText = i18n.t('activateEmployee.activate');

  const [activateId, setActivateId] = useState<string>();
  const navigate = useNavigate();
  const { state, pathname } = useLocation() as LocationI;

  useActivateNotification({ notificationId: activateId || '' });

  function handleRedirect() {
    navigate(`${Links.we.notifications}#${notification.id}`, {
      state: { from: state?.from ?? pathname },
    });
  }

  useEffect(() => {
    if (inView && !isRead) {
      setTimeout(() => {
        addToIsRead?.(notification.id);
        setIsRead(true);
      }, 1000);
    }
  }, [inView, isRead, notification, addToIsRead]);

  return (
    <ContentW
      isPreview={isPreview}
      ref={(el) => {
        lastItemRef?.(el);
        viewRef(el);
      }}
      onClick={() => handleRedirect()}
      className={isActive ? 'activeClass' : ''}
    >
      {!isRead && <BlueCircle />}

      <FlexW isPreview={isPreview}>
        {notification.type === 'custom' ? (
          <>
            <CustomDefaultAvatar className="default-avatar-icon" />
            <NotificationDescription isPreview={isPreview} {...notification} />
          </>
        ) : (
          <>
            {payloadBy && (
              <Avatar
                size="notification"
                filename={payloadBy?.avatar?.small ?? 'small.png'}
                userId={payloadBy?.id}
                wrapperSx={{ marginRight: '16px' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            )}

            {isDefaultLogo && <AdligoStarLogoW />}

            <NotificationDescription isPreview={isPreview} {...notification} />
          </>
        )}
      </FlexW>
      {!isPreview && isManager && isBirthdayType && (
        <ActivateButtonW
          variant="contained"
          color="info"
          sx={{
            maxWidth: 'none',
          }}
          disabled={isActivatePressed}
          onClick={(e) => {
            e.stopPropagation();
            setIsActivatePresed(true);
            setActivateId(notification.id);
          }}
        >
          {activateButtonText}
        </ActivateButtonW>
      )}
      <CompanyWrapper>
        <DateW isPreview={isPreview}>{formatDateTime(new Date(notification.created_at))}</DateW>
        <StrongW>{notification.company.name}</StrongW>
      </CompanyWrapper>
    </ContentW>
  );
}
