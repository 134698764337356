import { ReactComponent as EnglishIcon } from 'assets/icons/languages/english.svg';
import { ReactComponent as SwedishIcon } from 'assets/icons/languages/swedish.svg';
import i18n from 'i18n';
import { Language } from 'types';

export const ENGLISH = {
  icon: <EnglishIcon />,
  label: i18n.t('languageToggle.english'),
  optionLabel: i18n.t('languageToggle.swedish'),
  optionIcon: <SwedishIcon />,
  oppositeValue: Language.swedish,
};

export const SWEDISH = {
  icon: <SwedishIcon />,
  label: i18n.t('languageToggle.swedish'),
  optionLabel: i18n.t('languageToggle.english'),
  optionIcon: <EnglishIcon />,
  oppositeValue: Language.english,
};
