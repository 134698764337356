import { useQuery } from '@tanstack/react-query';

import { useCurrentSubscriptionQuery } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { useAuth, useModal } from 'hooks';

import { detailsAdapter } from '../adapter';
import { useCurrentSubscriptionDetails } from './useCurrentSubscriptionDetails';

export const useCompanyDetails = () => {
  const { isManager } = useAuth();

  const { currentSubscriptions } = useCurrentSubscriptionQuery();

  const { isOpen, handleOpenModal, handleCloseModal } = useModal();

  const { data: companyDetailsResponse, isLoading } = useQuery(['company-details'], () =>
    API_HANDLERS.COMPANY.DETAILS(),
  );

  const { data: paymentResponse, isFetching } = useQuery(
    ['payment-details'],
    () => API_HANDLERS.PAYMENT._(currentSubscriptions?.current_payment_method_id || ''),
    {
      // should start if user is manager and there is active subscription with card details
      enabled: isManager && Boolean(currentSubscriptions?.current_payment_method_id),
    },
  );

  const { activeProducts, isLoadingProducts } = useCurrentSubscriptionDetails({
    subscription: currentSubscriptions,
  });

  const companyDetails = detailsAdapter({
    subscription: currentSubscriptions,
    activeProducts,
    company: companyDetailsResponse?.data,
    paymentDetails: paymentResponse?.data,
  });

  return {
    isLoading: isLoading || isFetching || isLoadingProducts,
    isOpen,
    handleOpenModal,
    handleCloseModal,
    companyDetails,
    company: companyDetailsResponse?.data,
  };
};
