import { ReactElement } from 'react';

import { DocumentAttachment, FormGroup, ImageAttachment, VideoAttachment } from 'components';
import { FILE_NAME_LENGTH_LIMIT } from 'constants/index';
import { useWindowSize } from 'hooks';
import { Attachment } from 'types';
import { hideDocNameMidPart } from 'utils';

import {
  imagesFormGroupStyles,
  imagesFormGroupMobileStyles,
  filesFormGroupStyles,
  filesFormGroupMobileStyles,
} from './styles';

interface FormAttachmentsProps {
  attachedImages: Attachment[];
  attachedDocs: Attachment[];
  attachedVideos: Attachment[];
  handleDeleteAttachment: (attachmentId: string, type: 'images' | 'docs' | 'videos') => void;
  draftId?: string;
  hasDownloadDoc?: boolean;
  blockDelete?: boolean;
}

export function FormAttachments({
  attachedImages,
  attachedDocs,
  attachedVideos,
  draftId,
  handleDeleteAttachment,
  hasDownloadDoc,
  blockDelete,
}: FormAttachmentsProps): ReactElement {
  const { isMobile } = useWindowSize();

  return (
    <>
      {attachedImages.length > 0 && (
        <FormGroup row sx={isMobile ? imagesFormGroupMobileStyles : imagesFormGroupStyles}>
          {attachedImages.map(({ id, file, file_url }) => (
            <ImageAttachment
              key={id}
              postId={draftId}
              attachmentId={id}
              fileName={file.small}
              fileUrl={file_url?.small}
              handleDelete={() => handleDeleteAttachment(id, 'images')}
              blockDelete={blockDelete}
            />
          ))}
        </FormGroup>
      )}
      {attachedVideos.length > 0 && (
        <FormGroup row sx={isMobile ? imagesFormGroupMobileStyles : imagesFormGroupStyles}>
          {attachedVideos.map(({ id, file }: { id: string; file: { video_uid: string } }) => (
            <VideoAttachment
              key={id}
              postId={draftId}
              attachmentId={file.video_uid}
              handleDelete={() => handleDeleteAttachment(id, 'videos')}
            />
          ))}
        </FormGroup>
      )}
      {attachedDocs.length > 0 && (
        <FormGroup sx={isMobile ? filesFormGroupMobileStyles : filesFormGroupStyles}>
          {attachedDocs.map(({ file, id, file_url }) => {
            const fileName =
              file.original.length > FILE_NAME_LENGTH_LIMIT
                ? hideDocNameMidPart({
                    docName: file.original,
                    limit: FILE_NAME_LENGTH_LIMIT,
                  })
                : file.original;

            return (
              <DocumentAttachment
                key={id}
                postId={draftId}
                attachmentId={id}
                fileName={fileName}
                handleDelete={() => handleDeleteAttachment(id, 'docs')}
                hasDownload={hasDownloadDoc}
                originalFileName={file.original}
                originalFileUrl={file_url?.original}
                blockDelete={blockDelete}
              />
            );
          })}
        </FormGroup>
      )}
    </>
  );
}
