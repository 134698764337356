import { TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker as DatePickerMUI,
  DatePickerProps as PickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactElement, useState } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/closeRounded.svg';
import { BaseTextField } from 'components';
import { DAY_ONLY, MONTH_SHORT } from 'constants/index';
import { formattedDate } from 'utils';

import {
  ButtonLabel,
  ButtonW,
  ContainerW,
  DateButtonW,
  Day,
  IconButtonW,
  Month,
  PickerW,
} from './styled';

type DatePickerProps = Omit<PickerProps<unknown, unknown>, 'onChange' | 'value' | 'renderInput'> & {
  placeholder?: string;
  label?: string;
  sx?: TextFieldProps['sx'];
  isCreateGoal?: boolean;
  clearValue?: () => void;
};

export function DatePickerWithIcon<T extends FieldValues>({
  control,
  disabled,
  name,
  rules,
  placeholder,
  sx,
  label,
  isCreateGoal,
  clearValue,
  ...otherProps
}: DatePickerProps & UseControllerProps<T>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  });

  const day = value ? formattedDate(new Date(value), DAY_ONLY) : '';
  const month = value ? formattedDate(new Date(value), MONTH_SHORT) : '';

  const clickHandler = value ? clearValue : () => setIsOpen(true);

  return (
    <ContainerW isCreateGoal={isCreateGoal}>
      <ButtonW onClick={clickHandler}>
        {value ? (
          <DateButtonW>
            <Day className="day">{day}</Day>
            <Month className="month">{month}</Month>

            <RemoveIcon className="remove" />
          </DateButtonW>
        ) : (
          <IconButtonW>
            <CalendarIcon />
          </IconButtonW>
        )}

        <ButtonLabel>{label}</ButtonLabel>
      </ButtonW>

      <PickerW>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePickerMUI
            {...otherProps}
            inputFormat="MM/dd/yyyy"
            mask="__/__/____"
            InputProps={{
              disableUnderline: true,
            }}
            PopperProps={{
              placement: 'bottom-end',
            }}
            onChange={onChange}
            value={value}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            renderInput={(params) => (
              <BaseTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
                sx={{
                  '.MuiInputAdornment-root': {
                    position: 'absolute',
                    right: '24px',
                  },
                  '.MuiButtonBase-root': {
                    margin: 0,
                  },
                  ...sx,
                }}
              />
            )}
          />
        </LocalizationProvider>
      </PickerW>
    </ContainerW>
  );
}
