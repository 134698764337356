import { TableProps as TablePropsMUI } from '@mui/material';
import { ReactElement, RefObject } from 'react';

import { useWindowSize } from 'hooks';
import { useListScrollTo } from 'hooks/useListScrollTo';
import { TableColumnsConfig } from 'types';

import { initialWindowSizeProps } from '../constants';
import { TableContainer, StyledTableCell, StyledTableRow, HTableRow, HTableCell } from './styled';

export type TableElement<T> = Record<string, T>;

interface TableProps<T, OnClickT> extends TablePropsMUI {
  columns: TableColumnsConfig<T>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  isLoading: boolean;
  onClickRow?: (props: OnClickT) => void;
}

export function Table<T, OnClickT>({
  columns,
  data,
  onClickRow,
}: TableProps<T, OnClickT>): ReactElement {
  const { isMobile } = useWindowSize(initialWindowSizeProps);
  const { refs, activeItemId } = useListScrollTo(data);
  return (
    <TableContainer>
      {!isMobile && (
        <thead>
          <HTableRow>
            {columns.map((column) => (
              <HTableCell sx={{ width: column.width }} key={column.key as string}>
                {column.label}
              </HTableCell>
            ))}
          </HTableRow>
        </thead>
      )}

      <tbody>
        {data.map((row, index) => {
          const handleClick = () =>
            onClickRow && row.isClickable
              ? onClickRow({ userId: row.userId, id: row.id } as unknown as OnClickT)
              : null;

          const isExpireDate = !!row.expiryDate;
          const isActive = activeItemId === row.id.toString();

          return (
            <StyledTableRow
              ref={refs[row.id] as RefObject<HTMLTableRowElement>}
              key={row.id}
              onClick={handleClick}
              className={`${row.class} row-${index + 1} ${isActive ? 'activeClass' : ''}`}
            >
              {columns.map((column) => {
                return (
                  <>
                    {isMobile && (
                      <HTableCell
                        sx={{ width: column.width }}
                        key={column.label as string}
                        isExpireDate={isExpireDate}
                      >
                        {column.label}
                      </HTableCell>
                    )}
                    <StyledTableCell
                      sx={{ width: column.width }}
                      key={String(column.key)}
                      isExpireDate={isExpireDate}
                      isExpandedView={row.isExpanded}
                    >
                      {row[column.key]}
                    </StyledTableCell>
                  </>
                );
              })}
            </StyledTableRow>
          );
        })}
      </tbody>
    </TableContainer>
  );
}
