import { UserDetailsResponse } from 'api';
import { EditEmployeeFormValues, UserEmergencyRelation } from 'types';
import { removeExtraSpaces } from 'utils';

export function formValuesAdapter({
  firstName,
  lastName,
  countryCode,
  birthDate,
  address1,
  address2,
  postCode,
  employmentNumber,
  employmentDate,
  licenses,
  clothesSize,
  relation,
  relationContactFirstName,
  relationContactLastName,
  relationContactPhone,
  relationCountryCode,
  equipment,
  ...otherProps
}: Partial<EditEmployeeFormValues>): Partial<UserDetailsResponse> {
  const emergency = {
    ...(typeof relation === 'string' && {
      relation: relation as UserEmergencyRelation,
    }),
    ...(typeof relationContactFirstName === 'string' && { first_name: relationContactFirstName }),
    ...(typeof relationContactLastName === 'string' && { last_name: relationContactLastName }),
    ...(typeof relationContactPhone === 'string' && { phone: relationContactPhone }),
    ...(relationCountryCode && { country_code: Number(relationCountryCode) }),
  };

  return {
    ...(firstName && { first_name: removeExtraSpaces(firstName) }),
    ...(lastName && { last_name: removeExtraSpaces(lastName) }),
    ...(typeof countryCode === 'string' && { country_code: Number(countryCode) }),
    ...((birthDate || birthDate === null) && { birth_date: birthDate || '' }),
    ...(typeof address1 === 'string' && { address_1: address1 }),
    ...(typeof address2 === 'string' && { address_2: address2 }),
    ...(typeof postCode === 'string' && { postcode: postCode }),
    ...(typeof employmentNumber === 'string' && {
      employment_number: employmentNumber,
    }),
    ...((employmentDate || employmentDate === null) && { employed_at: employmentDate || '' }),
    ...(licenses && { licenses: [...new Set(licenses)] }),
    ...(equipment && { equipment: [...new Set(equipment)] }),
    ...(typeof clothesSize === 'string' && { clothes_size: clothesSize }),
    ...(Object.keys(emergency).length > 0 && { emergency: emergency }),
    ...otherProps,
  };
}
