export const attachmentWrapperStyles = { height: '128px', width: '128px', marginRight: 0 };
export const attachmentWrapperMobileStyles = { height: '112px', width: '112px', marginRight: 0 };

export const docAttachmentSx = {
  height: '128px',
  width: '128px',
  gridColumn: '3 / 4',
  marginRight: 0,
};

export const imgAttachmentSx = {
  height: '128px',
  width: '128px',
  gridColumn: '2 / 3',
  marginRight: 0,
};
