import { IconButton } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { AddAndExtendActions, Backdrop, ConfirmDialog, GoBackButtonWithTitle } from 'components';
import { achievementsTabs } from 'features/career/constants';
import { useModal, useWindowSize } from 'hooks';
import { AchievementsTabsValues } from 'types';

import { AchievementEmptyState, AchievementsList, TabPanel, Tabs } from '../../components';
import { useAchievementsPage, useAchievementsTabs } from './hooks';
import { EmptyStateW, PageW, ContentW, Header, StyledAddIcon } from './styled';
import { accomplishedDialogSx } from './styles';

interface LocationI extends Omit<Location, 'state'> {
  state?: {
    accomplishedAchievement?: string;
  };
}

export function AchievementsPage(): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const location = useLocation() as unknown as LocationI;
  const navigate = useNavigate();
  const { handleCloseModal: handleCloseAchievementModal } = useModal();
  const { activeTab, handleChange } = useAchievementsTabs();

  const achievementDialogName = location?.state?.accomplishedAchievement ?? '';

  const handleCloseConfirmModal = useCallback(() => {
    handleCloseAchievementModal();
    navigate(location, {
      replace: true,
    });
  }, [handleCloseAchievementModal, location, navigate]);

  const {
    isLoading,
    activeAchievements,
    accomplishedAchievements,
    isAchievementsList,
    isActiveAchievementsList,
    isAccomplishedAchievementsList,
    employeeId,
    addAchievementLink,
    usersList,
  } = useAchievementsPage();

  return (
    <>
      <PageW>
        <Header>
          <GoBackButtonWithTitle title={t('career.achievements.title')} />
          {isAchievementsList && (
            <NavLink to={addAchievementLink} state={{ prev: location.pathname }}>
              <IconButton>
                <StyledAddIcon />
              </IconButton>
            </NavLink>
          )}
        </Header>

        <ContentW>
          {isLoading ? (
            <Backdrop />
          ) : isAchievementsList ? (
            <>
              <Tabs
                activeTab={activeTab}
                tabs={achievementsTabs}
                isAccomplishedTabDisabled={!isAccomplishedAchievementsList}
                handleChange={handleChange}
              />
              <TabPanel path={AchievementsTabsValues.ACTIVE} value={activeTab}>
                {isActiveAchievementsList ? (
                  <AchievementsList
                    isMobile={isMobile}
                    data={activeAchievements}
                    isFullView
                    employeeId={employeeId}
                    usersList={usersList}
                  />
                ) : (
                  <EmptyStateW>
                    <AchievementEmptyState addAchievementLink={addAchievementLink} />
                  </EmptyStateW>
                )}
              </TabPanel>
              <TabPanel path={AchievementsTabsValues.ACCOMPLISHED} value={activeTab}>
                <AchievementsList
                  isMobile={isMobile}
                  data={accomplishedAchievements}
                  isFullView
                  employeeId={employeeId}
                  usersList={usersList}
                />
              </TabPanel>
            </>
          ) : (
            <EmptyStateW>
              <AddAndExtendActions addIconLink={addAchievementLink} />
              <AchievementEmptyState addAchievementLink={addAchievementLink} />
            </EmptyStateW>
          )}
        </ContentW>
      </PageW>

      {!!achievementDialogName && (
        <ConfirmDialog
          title={t('career.achievements.onAchievementAccomplished', {
            achievementName: achievementDialogName,
          })}
          open={!!achievementDialogName}
          onSubmit={handleCloseConfirmModal}
          dialogSx={accomplishedDialogSx}
        />
      )}
    </>
  );
}
