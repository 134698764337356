import { PostDraft, TaskFormValues, TaskStatus } from 'types';

interface DefaultValuesAdapterProps {
  draft: PostDraft | null;
  status?: TaskStatus;
  assigneeId?: string;
}

export function defaultValuesAdapter({
  draft,
  status,
  assigneeId,
}: DefaultValuesAdapterProps): TaskFormValues {
  const imagesList = draft?.attachments?.filter((file) => file.type === 'image') || [];
  const documentsList = draft?.attachments?.filter((file) => file.type === 'document') || [];
  const videosList = draft?.attachments?.filter((file) => file.type === 'video') || [];

  return {
    title: '',
    description: '',
    images: imagesList,
    docs: documentsList,
    videos: videosList,
    status: status || TaskStatus.to_do,
    assignee: assigneeId ? [assigneeId as string] : undefined,
    deadline: null,
  };
}
