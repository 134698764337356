import { NewCategoryPayload } from 'api';
import { removeExtraSpaces } from 'utils';

interface FormValuesAdapterProps {
  title: string;
  description: string;
  assigned_to?: string[];
  icon: string;
  visibility?: string;
}

export function formValuesAdapter({
  title,
  description,
  ...otherProps
}: FormValuesAdapterProps): NewCategoryPayload {
  return {
    title: removeExtraSpaces(title),
    description: removeExtraSpaces(description),
    ...otherProps,
  };
}
