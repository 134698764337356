export const imagesWrapperStyles = { width: '145px', height: '140px', marginRight: '0px' };
export const imagesWrapperMobileStyles = { width: '129px', height: '124px', marginRight: '0px' };

export const BlockWrapperStyles = {
  marginRight: '24px',
  width: '540px',
  height: '236px',
};

export const BlockWrapperMobileStyles = {
  width: '100%',
  height: 'auto',
  maxWidth: '540px',
  padding: '16px',
};
