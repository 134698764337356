import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QUERIES } from 'constants/index';
import { $FixTypeLater } from 'types';
import { showResponseError } from 'utils';

import { retryHandler } from './utils';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => retryHandler(failureCount, error as AxiosError),
    },
    mutations: {
      retry: (failureCount, error) => retryHandler(failureCount, error as AxiosError),
    },
  },
  queryCache: new QueryCache({
    onError: (error: $FixTypeLater, query) => {
      // user is not logged-in to any of calendars
      if (
        (error.response.status === 401 &&
          (query.queryKey.includes(QUERIES.CALENDAR_EVENTS) ||
            query.queryKey.includes(QUERIES.ACTIVATE_TOKEN))) ||
        (error.response.status === 422 && query.queryKey.includes(QUERIES.TASK_DETAILS))
      ) {
        return;
      }

      showResponseError(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: $FixTypeLater) => {
      showResponseError(error);
    },
  }),
});
