import { ReactElement, RefObject, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactionStats, useTargetDetailsQuery } from 'api';
import { ReactComponent as ProcessIcon } from 'assets/icons/process-automation.svg';
import { Spinner } from 'components';
import { ReactionsWrapper } from 'components/ReactionsWrapper';
import { TargetItem } from 'features/kpi';
import { useModal } from 'hooks';
import { Links } from 'settings';
import { InViewItemRef } from 'types';

import { PostItemActions } from '../../PostItemActions';
import { ReactionConfirmDialog } from '../../ReactionConfirmDialog';
import { Container, IconW, LeftSide, PostTitle, PostW } from '../styled';
import { LinkW, PostDescription, RightSide, TargetW } from './styled';

interface KpiTargetPostProps {
  postRef: RefObject<HTMLDivElement>;
  lastItemRef?: InViewItemRef;
  handlePostClick?: () => void;
  targetId: string;
  comments_count?: number;
  id: string;
  reactions?: ReactionStats;
}

export function KpiTargetPost({
  targetId,
  postRef,
  lastItemRef,
  handlePostClick,
  comments_count,
  id,
  reactions,
}: KpiTargetPostProps): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, data } = useTargetDetailsQuery(targetId);

  const { handleOpenModal, handleCloseModal, isOpen } = useModal();

  function handleLinkClick(e: SyntheticEvent) {
    e.stopPropagation();
    navigate(Links.we.kpi.index);
  }

  return (
    <Container ref={postRef}>
      <IconW>
        <ProcessIcon />
      </IconW>

      <PostW ref={lastItemRef} onClick={handlePostClick} sx={{ maxHeight: 'unset' }}>
        <LeftSide>
          <PostTitle>{t('completedKpiTargetPost.title')}</PostTitle>
          <PostDescription>{t('completedKpiTargetPost.description1')}</PostDescription>

          <TargetW>
            {isLoading && <Spinner />}
            {data?.data && !isLoading && <TargetItem {...data?.data} isFromPost />}
          </TargetW>

          <PostDescription>{t('completedKpiTargetPost.description2')}</PostDescription>
          <LinkW onClick={handleLinkClick}>{t('completedKpiTargetPost.link')}</LinkW>
          <ReactionsWrapper
            reactions={reactions}
            handleOpenModal={handleOpenModal}
            id={id}
            isAutoPost
          />
        </LeftSide>
        <RightSide>
          <PostItemActions commentsCount={comments_count} />
        </RightSide>
      </PostW>

      <ReactionConfirmDialog
        title={t('reactions.title')}
        onClose={handleCloseModal}
        postId={id}
        open={isOpen}
      />
    </Container>
  );
}
