import { Box } from '@mui/material';
import { ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemImageAttachment as Attachment } from 'components';
import { useWindowSize } from 'hooks';
import { Attachment as AttachmentType } from 'types';

import { IMAGES_COUNT } from './constants';
import { MoreImages, ThirdImagesW, RightImagesW } from './styled';
import {
  oneImageStyles,
  twoImageStyles,
  oneImageMobileStyles,
  twoImageMobileStyles,
} from './styles';

interface OnlyImagesLayoutProps {
  images: AttachmentType[];
  postId: string;
  onOpenGallery: () => void;
  goToPostDetails: () => void;
}

export function OnlyImagesLayout({
  images,
  postId,
  onOpenGallery,
  goToPostDetails,
}: OnlyImagesLayoutProps): ReactElement {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const threeImages = images.slice(0, IMAGES_COUNT);
  const [first, second, third] = threeImages;

  const moreImages = images.length > IMAGES_COUNT ? images.length - IMAGES_COUNT : null;

  const handleMoreImagesOnClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    onOpenGallery();
  };

  if (images.length === 1) {
    const { id, file, file_url } = images[0];

    return (
      <Attachment
        attachmentId={id}
        fileName={file.small}
        fileUrl={file_url?.small}
        postId={postId}
        wrapperSx={isMobile ? oneImageMobileStyles : oneImageStyles}
        onOpenGallery={onOpenGallery}
      />
    );
  }

  if (images.length === 2) {
    return (
      <Box display="flex">
        {images.map(({ id, file, file_url }) => (
          <Attachment
            key={id}
            attachmentId={id}
            fileName={file.small}
            fileUrl={file_url?.small}
            postId={postId}
            wrapperSx={isMobile ? twoImageMobileStyles : twoImageStyles}
            onOpenGallery={onOpenGallery}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box display="flex">
      <Attachment
        attachmentId={first.id}
        fileName={first.file.small}
        fileUrl={first.file_url?.small}
        postId={postId}
        onOpenGallery={onOpenGallery}
        wrapperSx={
          isMobile
            ? { width: '100%', height: '128px', marginRight: '8px', flex: 1 }
            : { width: '145px', height: '153px', marginRight: '8px' }
        }
      />

      <RightImagesW>
        <Attachment
          attachmentId={second.id}
          fileName={second.file.small}
          fileUrl={second.file_url?.small}
          postId={postId}
          wrapperSx={
            isMobile
              ? { width: '100%', height: '60px', marginBottom: '8px' }
              : { width: '145px', height: '72px', marginBottom: '8px' }
          }
          onOpenGallery={onOpenGallery}
        />
        <ThirdImagesW>
          <Attachment
            attachmentId={third.id}
            fileName={third.file.small}
            fileUrl={third.file_url?.small}
            postId={postId}
            wrapperSx={
              isMobile ? { width: '100%', height: '60px' } : { width: '145px', height: '72px' }
            }
            moreImages={moreImages}
            onOpenGallery={onOpenGallery}
            hasMoreImagesClick={goToPostDetails}
          />
          {moreImages && (
            <MoreImages onClick={handleMoreImagesOnClick}>
              {t('common.moreImages', { count: moreImages as number })}
            </MoreImages>
          )}
        </ThirdImagesW>
      </RightImagesW>
    </Box>
  );
}
