import { useState } from 'react';

import { CALENDAR_DATE_KEY_FORMAT } from 'constants/index';
import { useSessionState } from 'hooks';
import { CalendarEvent, CalendarTypes, CalendarEventApi } from 'types';
import { formattedDate, clickLink } from 'utils';

import { GOOGLE_CALENDAR_LINK, MS_CALENDAR_LINK } from './constants';
import { useCalendarAuthMutation } from './useCalendarAuthMutation';
import { useCalendarDates } from './useCalendarDates';
import { useEventsQuery } from './useEventsQuery';

export function useCalendar() {
  const { datesRange, timeMin, timeMax } = useCalendarDates();

  const [groupedEvents, setGroupedEvents] = useState<Record<string, CalendarEvent[]> | null>(null);
  const [selectedDate, setSelectedDate] = useState(() =>
    formattedDate(new Date(), CALENDAR_DATE_KEY_FORMAT),
  );
  const [events, setEvents] = useState<null | CalendarEventApi[]>(null);
  const [eventsProvider, setEventsProvider] = useSessionState<null | CalendarTypes>('provider');

  const { isFetching } = useEventsQuery({
    timeMin,
    timeMax,
    selectedDate,
    setEvents,
    setGroupedEvents,
    eventsProvider,
    setEventsProvider,
  });

  const { calendarAuthMutation } = useCalendarAuthMutation();

  // TODO: refactor into one function
  async function handleGoogleSignIn() {
    const { data } = await calendarAuthMutation({ type: CalendarTypes.google });
    setEventsProvider(CalendarTypes.google);

    if (data.auth_url) {
      clickLink(data.auth_url);
    }
  }

  async function handleMicrosoftSignIn() {
    const { data } = await calendarAuthMutation({ type: CalendarTypes.ms });
    setEventsProvider(CalendarTypes.ms);

    if (data.auth_url) {
      clickLink(data.auth_url);
    }
  }

  const monthLabel = formattedDate(new Date(selectedDate), 'MMMM');
  const calendarLink =
    eventsProvider === CalendarTypes.google ? GOOGLE_CALENDAR_LINK : MS_CALENDAR_LINK;

  return {
    isFetching,
    events,
    monthLabel,
    datesRange,
    selectedDate,
    setSelectedDate,
    handleGoogleSignIn,
    handleMicrosoftSignIn,
    groupedEvents: groupedEvents || {},
    calendarLink,
  };
}
