import { TextFieldProps } from '@mui/material/TextField';
import { ReactElement } from 'react';

import { StyledTextField } from './styled';

export function CompanySelectBaseTextField({
  name,
  size,
  variant = 'standard',
  error,
  value = '',
  label,
  onBlur,
  ...otherProps
}: TextFieldProps): ReactElement {
  const newValue: string = value ? `${value}` : '';
  const newDisaplayLabel = newValue.length > 10 ? `${newValue.slice(0, 10)}...` : newValue;

  return (
    <StyledTextField
      fullWidth
      size={size}
      variant={variant}
      error={error}
      value={newDisaplayLabel}
      label={newDisaplayLabel}
      autoComplete="off"
      onBlur={onBlur}
      {...otherProps}
    />
  );
}
