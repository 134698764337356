import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { useEquipmentsQuery } from 'api/queries/useEquipmentsQuery';
import { useAuth } from 'hooks';
import { Links } from 'settings';
import { Equipment, EquipmentCategory, EquipmentStatus } from 'types';

import { registeredEquipmentAdapter } from '../adapters/registeredEquipmentAdapter';
import { OnRegisteredClickT } from '../types';

interface UseRegisteredEquipmentTableProps {
  userId: string;
  category: EquipmentCategory | null;
  isSwedish: boolean;
  onTrashIconClick: ({ id, name }: Pick<Equipment, 'id' | 'name'>) => void;
}

export function useRegisteredEquipmentTable({
  userId,
  category,
  isSwedish,
  onTrashIconClick,
}: UseRegisteredEquipmentTableProps) {
  const navigate = useNavigate();
  const { isManager, user } = useAuth();
  const { equipments, isLoading: isEquipmentsListLoading } = useEquipmentsQuery({ userId });
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const getIsExpanded = useCallback((id: string) => expandedItems.includes(id), [expandedItems]);

  const setIsExpanded = useCallback((id: string) => {
    setExpandedItems((prev: string[]) => {
      return prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id];
    });
  }, []);

  const handleViewEquipment = ({ id, userId }: OnRegisteredClickT) => {
    const equipmentLink = generatePath(Links.profile.equipmentList.equipment.equipmentId, {
      equipmentId: id,
      employeeId: userId,
    });

    id && userId && navigate(equipmentLink);
  };

  const regiteredEquipment = useMemo(
    () => equipments.filter((equipment) => equipment.status === EquipmentStatus.ACTIVE),
    [equipments],
  );

  const data = useMemo(
    () =>
      regiteredEquipment.filter((equipment) => (category ? equipment.category === category : true)),
    [category, regiteredEquipment],
  );

  return {
    isLoading: isEquipmentsListLoading,
    equipmentList: registeredEquipmentAdapter({
      isManager,
      currentUserId: user?.id,
      data,
      isSwedish,
      onTrashIconClick,
      onEditIconClick: handleViewEquipment,
      getIsExpanded,
      setIsExpanded,
    }),
    isFilterShown: !!regiteredEquipment.length,
    handleViewEquipment,
  };
}
