import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, SubmitRoundedButton, FormGroup, PasswordController } from 'components';
import { useWindowSize } from 'hooks';
import { passwordRules } from 'validations';

import { InputW } from './styled';
import { hintsSx, submitBtnSx } from './styles';
import { useCreatePasswordForm } from './useCreatePasswordForm';

export function CreatePasswordForm(): ReactElement {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const { control, handleFormSubmit } = useCreatePasswordForm();

  return (
    <Form sx={{ marginTop: isMobile ? '32px' : '70px' }} onSubmit={handleFormSubmit}>
      <FormGroup marginBottom={isMobile ? 32 : 18} row>
        <InputW>
          <PasswordController
            name="password"
            id="password"
            control={control}
            label={t('activateEmployee.passwordPlaceholder')}
            placeholder={t('activateEmployee.passwordPlaceholder')}
            rules={passwordRules}
            maxLength={60}
            hintsSx={hintsSx}
            withAdornment
          />
        </InputW>
        <SubmitRoundedButton sx={submitBtnSx} />
      </FormGroup>
    </Form>
  );
}
