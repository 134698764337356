import {
  ImageGallery,
  ListItemDocumentAttachment,
  ListItemImageAttachment,
  ListItemVideoAttachment,
  VideoGallery,
} from 'components';
import { useModal, useWindowSize } from 'hooks';
import { Attachment } from 'types';

import { Attachments, ShowLessButton } from './styled';
import {
  attachmentWrapperStyles,
  attachmentWrapperMobileStyles,
  docAttachmentSx,
  imgAttachmentSx,
} from './styles';

interface DocumentAttachmentsPropsType {
  attachments: Attachment[];
  postId?: string;
  setIsExpandedCard: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedCard: boolean;
  isCustom?: boolean;
}

const COUNT_OF_ATTACHMENTS_WITHOUT_EXPAND = 3;

export const DocumentAttachments = ({
  attachments,
  postId,
  isExpandedCard,
  setIsExpandedCard,
  isCustom,
}: DocumentAttachmentsPropsType) => {
  const { isOpen, handleOpenModal, handleCloseModal } = useModal();
  const {
    isOpen: isVideoOpen,
    handleOpenModal: handleOpenVideoModal,
    handleCloseModal: handleCloseVideoModal,
  } = useModal();
  const { isMobile } = useWindowSize();

  const attachmentsLength = attachments.length;
  const imagesList = attachments.filter((attachment) => attachment.type === 'image');
  const docsList = attachments.filter((attachment) => attachment.type === 'document');
  const videosList = attachments.filter((attachment) => attachment.type === 'video');
  const hasMoreImages = imagesList.length > 1 ? imagesList.length - 1 : null;
  const hasMoreDocs = docsList.length > 1 ? docsList.length - 1 : null;
  const hasMoreVideos = videosList.length > 1 ? videosList.length - 1 : null;
  const attachmentWStyles = isMobile ? attachmentWrapperMobileStyles : attachmentWrapperStyles;

  if (attachmentsLength <= COUNT_OF_ATTACHMENTS_WITHOUT_EXPAND) {
    return (
      <>
        <Attachments isCustom={isCustom}>
          {docsList.map((doc) => (
            <ListItemDocumentAttachment
              key={doc.id}
              wrapperSx={attachmentWStyles}
              postId={postId}
              documentId={doc.id}
              fileName={doc.file.original}
              fileUrl={doc.file_url?.original}
            />
          ))}
          {imagesList.map((img) => (
            <ListItemImageAttachment
              key={img.id}
              wrapperSx={attachmentWStyles}
              postId={postId}
              attachmentId={img.id}
              fileName={img.file.small}
              fileUrl={img.file_url?.small}
              onOpenGallery={handleOpenModal}
            />
          ))}
          {videosList.map((video) => (
            <ListItemVideoAttachment
              key={video.id}
              video={video}
              wrapperSx={attachmentWStyles}
              onOpenGallery={handleOpenVideoModal}
            />
          ))}
        </Attachments>
        {isOpen && (
          <ImageGallery
            open={isOpen}
            onClose={handleCloseModal}
            postId={postId}
            imageList={imagesList}
          />
        )}
        {isVideoOpen && (
          <VideoGallery open={isVideoOpen} onClose={handleCloseVideoModal} videoList={videosList} />
        )}
      </>
    );
  }

  return (
    <>
      <Attachments
        sx={{
          flexDirection: isExpandedCard ? 'row' : 'row-reverse',
          justifyContent: isExpandedCard ? 'flex-end' : 'flex-start',
          flexWrap: 'wrap',
        }}
        isCustom={isCustom}
      >
        {isExpandedCard ? (
          <>
            {imagesList.map((img) => (
              <ListItemImageAttachment
                key={img.id}
                wrapperSx={attachmentWStyles}
                postId={postId}
                attachmentId={img.id}
                fileName={img.file.small}
                fileUrl={img.file_url?.small}
                onOpenGallery={handleOpenModal}
              />
            ))}
            {videosList.map((video) => (
              <ListItemVideoAttachment
                key={video.id}
                video={video}
                wrapperSx={attachmentWStyles}
                onOpenGallery={handleOpenModal}
              />
            ))}
            {docsList.map((doc) => (
              <ListItemDocumentAttachment
                key={doc.id}
                wrapperSx={attachmentWStyles}
                postId={postId}
                documentId={doc.id}
                fileName={doc.file.original}
                fileUrl={doc.file_url?.original}
              />
            ))}
          </>
        ) : (
          <>
            {docsList.length ? (
              <ListItemDocumentAttachment
                key={docsList[0].id}
                wrapperSx={docAttachmentSx}
                postId={postId}
                documentId={docsList[0].id}
                fileName={docsList[0].file.original}
                fileUrl={docsList[0].file_url?.original}
                moreFiles={hasMoreDocs ? docsList.length - 1 : 0}
                hasMoreFilesClick={() => setIsExpandedCard(true)}
              />
            ) : null}
            {videosList.length ? (
              <ListItemVideoAttachment
                key={videosList[0].id}
                video={videosList[0]}
                wrapperSx={attachmentWStyles}
                onOpenGallery={handleOpenModal}
                moreVideos={hasMoreVideos}
                expandViewClick={() => setIsExpandedCard(true)}
              />
            ) : null}
            {imagesList.length ? (
              <ListItemImageAttachment
                key={imagesList[0].id}
                wrapperSx={imgAttachmentSx}
                postId={postId}
                attachmentId={imagesList[0].id}
                fileName={imagesList[0].file.small}
                fileUrl={imagesList[0].file_url?.small}
                moreImages={hasMoreImages ? imagesList.length - 1 : 0}
                hasMoreImagesClick={() => setIsExpandedCard(true)}
                onOpenGallery={handleOpenModal}
              />
            ) : null}
          </>
        )}
        <ImageGallery
          open={isOpen}
          onClose={handleCloseModal}
          postId={postId}
          imageList={imagesList}
        />
        <VideoGallery open={isVideoOpen} onClose={handleCloseVideoModal} videoList={videosList} />
        {isExpandedCard && isCustom && (
          <ShowLessButton onClick={() => setIsExpandedCard(false)}>Show less</ShowLessButton>
        )}
      </Attachments>
    </>
  );
};
