import { Dialog as DialogMUI, Slider as SliderMUI, styled, Button } from '@mui/material';

export const Dialog = styled(DialogMUI)(({ theme }) => ({
  '.MuiDialog-paper': {
    maxWidth: '746px',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(20px)',
    borderRadius: '15px',
  },

  '.MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
  },
  [theme.breakpoints.down('desktop')]: {
    '.MuiDialog-paper': {
      margin: 'auto 8px',
    },
  },
}));

export const Slider = styled(SliderMUI)(({ theme }) => ({
  '.MuiSlider-thumb, .MuiSlider-track': {
    background: theme.palette.info.main,
    color: theme.palette.info.main,
  },
}));

export const DialogFooter = styled('div')`
  padding: 16px 24px;
`;

export const FooterActions = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const ActionBtn = styled(Button)`
  text-transform: none;
  background: ${({ theme }) => theme.palette.white.main};
  border: ${({ theme }) => `1px solid ${theme.palette.info.main}`};
  color: ${({ theme }) => theme.palette.info.main};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  box-shadow: none;
  padding: 7px 16px;
  min-width: 100px;

  @media (max-width: 1023px) {
    line-height: 14px;
    padding: 5px 10px;
  }
`;

export const RemoveButton = styled(ActionBtn)`
  margin-right: 8px;
  &:hover {
    background: ${({ theme }) => theme.palette.white.main};
  }
`;

export const ActivateButton = styled(ActionBtn)`
  position: relative;
  min-height: 40px;
  background: ${({ theme }) => theme.palette.info.main};
  color: ${({ theme }) => theme.palette.white.main};

  &:hover {
    background: ${({ theme }) => theme.palette.info.main};
    color: ${({ theme }) => theme.palette.white.main};
  }
`;
