import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';
import { InviteEmployeeParams } from 'api/types';

interface UseInviteEmployeeMutationReturn {
  inviteEmployeeMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    InviteEmployeeParams,
    unknown
  >;
  isLoading: boolean;
}

export function useInviteEmployeeMutation(): UseInviteEmployeeMutationReturn {
  const { mutateAsync: inviteEmployeeMutation, isLoading } = useMutation(
    (data: InviteEmployeeParams) => {
      return API_HANDLERS.USER.INVITE(data);
    },
  );

  return {
    inviteEmployeeMutation,
    isLoading,
  };
}
