import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { GoBackButtonWithTitle } from 'components';
import { Links } from 'settings';

import { PageHeaderW } from './styled';

export function PageHeader(): ReactElement {
  const { t } = useTranslation();

  return (
    <PageHeaderW>
      <GoBackButtonWithTitle title={t('tasks.tasksManagement.pageTitle')} />
      <NavLink to={Links.profile.createTask}>
        <IconButton>
          <AddIcon style={{ opacity: '0.4' }} />
        </IconButton>
      </NavLink>
    </PageHeaderW>
  );
}
