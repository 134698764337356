import { useCallback, useEffect } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useCallbackPrompt } from 'hooks';
import { TargetFormValues } from 'types';

interface UseVisionFormProps {
  mode: 'create' | 'edit';
  handleSubmit: (data: TargetFormValues) => void;
  defaultValues: TargetFormValues;
}

interface UseVisionFormReturn {
  control: Control<TargetFormValues>;
  isOpen: boolean;
  isLoading: boolean;
  goBack: () => void;
  handleFormSubmit: () => void;
  handleSubmitConfirmModal: () => void;
  handleCloseConfirmModal: () => void;
  isDirty: boolean;
  value: string | number;
  target: string | number;
}

export function useTargetForm({
  mode,
  handleSubmit: submitForm,
  defaultValues,
}: UseVisionFormProps): UseVisionFormReturn {
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { isSubmitting, isDirty, isSubmitted, errors },
  } = useForm<TargetFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isDirty && !isSubmitted,
  );

  const value = watch('value');
  const target = watch('target');

  const handleFormSubmit = handleSubmit(async (data) => {
    await submitForm(data);
  });

  async function handleSubmitConfirmModal() {
    confirmNavigation();
  }

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (value !== '' && target !== '') {
      if (+target < +value) {
        setError('value', { message: t('kpi.targetForm.validation.lessThanValue') });
        setError('target', { message: t('kpi.targetForm.validation.lessThanValue') });
      } else {
        clearErrors(['value', 'target']);
      }
    }
  }, [clearErrors, setError, t, target, value]);

  useEffect(() => {
    if (isSubmitted && Object.keys(errors).length === 0) {
      goBack();
    }
  }, [isSubmitted, goBack, errors, mode]);

  return {
    control,
    handleFormSubmit,
    isOpen: showPrompt,
    handleSubmitConfirmModal,
    isLoading: isSubmitting,
    goBack,
    handleCloseConfirmModal: cancelNavigation,
    isDirty,
    value,
    target,
  };
}
