import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { PostListItem } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES, TASK_MANAGEMENT_LIST_LIMIT } from 'constants/index';
import { InViewItemRef, InstanceType } from 'types';

interface UseTasksListQueryReturn {
  isLoading: boolean;
  tasks: PostListItem[];
  activeTasks: PostListItem[];
  lastItemRef: InViewItemRef;
  isFetchingNextPage: boolean;
  total: number;
  isEmptyList: boolean;
}

interface UseTaskListQueryProps {
  type: InstanceType;
  limit: number;
  offset?: number;
  assigned?: string;
  author?: string;
  state?: string;
}

export function useTasksListQuery({
  type,
  limit,
  offset,
  assigned,
  author,
  state,
}: UseTaskListQueryProps): UseTasksListQueryReturn {
  const { ref, inView } = useInView();

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery(
    [QUERIES.TASKS_LIST, type, limit, offset, assigned, author, state],
    ({ pageParam }) =>
      API_HANDLERS.POST.GET_LIST({
        type,
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(assigned && { assigned }),
        ...(author && { author }),
        ...(state && { state }),
        ...(pageParam && pageParam),
      }),

    {
      getNextPageParam: (lastPage, allPages) => {
        const total = lastPage.data.total;
        if (!total) return;
        const loadedItems = allPages.reduce((prev, curr) => prev + curr.data.data.length, 0);
        return total > loadedItems ? { offset: loadedItems, limit } : undefined;
      },
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (inView && hasNextPage && limit === TASK_MANAGEMENT_LIST_LIMIT) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage, limit]);

  const isEmptyList = data?.pages.length === 1 && data?.pages[0].data.data === null;
  const tasksList = isEmptyList ? [] : data?.pages.map((page) => page.data.data).flat();
  const activeTasks = tasksList?.filter((item) => item.task.state !== 'done') || [];

  return {
    isLoading,
    tasks: tasksList || [],
    activeTasks,
    lastItemRef: ref,
    isFetchingNextPage,
    total: data?.pages[0].data.total || 0,
    isEmptyList,
  };
}
