import { useEffect, useMemo } from 'react';
import {
  Control,
  UseFormRegister,
  UseFormGetValues,
  useForm,
  UseFormSetError,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useAuth, useFormAttachments } from 'hooks';
import { Attachment, EquipmentFormValues, PostDraft, SelectOption, User, UserRole } from 'types';
import { getFullName, objectsDifferences } from 'utils';

interface UseEquipmentFormProps {
  draft?: PostDraft | null;
  isEditMode: boolean;
  isEquipmentMutationLoading: boolean;
  usersList: User[];
  defaultValues: (isDefaultEditable: boolean, draft?: PostDraft) => EquipmentFormValues;
  onSubmit: (data: EquipmentFormValues, attachmentIds: string[]) => void;
}

interface UseEquipmentFormReturn {
  isSubmitting: boolean;
  isLoading: boolean;
  isManager: boolean;
  isCurrentUserProfile: boolean;
  isSelectedCurrentUser: boolean;
  isCreateFromProfile: boolean;
  isDirty: boolean;
  isSubmitted: boolean;
  control: Control<EquipmentFormValues>;
  usersOptions: SelectOption[];
  assigneeOptions: SelectOption[];
  attachedImages: Attachment[];
  attachedDocs: Attachment[];
  attachedVideos: Attachment[];
  progress: number;
  register: UseFormRegister<EquipmentFormValues>;
  getValues: UseFormGetValues<EquipmentFormValues>;
  setError: UseFormSetError<EquipmentFormValues>;
  handleFormSubmit: () => void;
  handleAddImages: (files: FileList) => Promise<void>;
  handleAddDocuments: (files: FileList) => Promise<void>;
  handleAddVideos: (files: FileList) => Promise<void>;
  handleDeleteAttachment: (attachmentId: string, type: 'images' | 'docs' | 'videos') => void;
}

export function useEquipmentForm({
  draft,
  isEditMode,
  isEquipmentMutationLoading,
  usersList,
  defaultValues,
  onSubmit,
}: UseEquipmentFormProps): UseEquipmentFormReturn {
  const { isManager, user } = useAuth();
  const { employeeId } = useParams();

  const isCurrentUserProfile = !!employeeId && user?.id === employeeId;

  const defaultFormValues = defaultValues(
    !isManager || isCurrentUserProfile,
    !isEditMode && draft ? draft : undefined,
  );

  const {
    reset,
    setValue,
    control,
    getValues,
    setError,
    watch,
    handleSubmit,
    register,
    formState: { isDirty, isSubmitting, isSubmitted },
  } = useForm<EquipmentFormValues>({
    defaultValues: defaultFormValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const attachedImages = watch('images');
  const attachedDocs = watch('docs');
  const attachedVideos = watch('videos');
  const selectedUser = watch('user');
  const selectedAssignee = watch('assignee');
  const customFields = watch('customFields');

  const isCustomFieldsValid = customFields?.every(
    ({ key, value }) => (!!key && !!value) || (!key && !value),
  );

  const isSelectedCurrentUser = !!selectedUser && selectedUser === user?.id;

  const usersOptions = useMemo(
    () =>
      usersList.map((user) => ({
        value: user.id,
        label:
          user.firstName && user.lastName
            ? getFullName({ firstName: user.firstName, lastName: user.lastName })
            : user.email,
        disabled: user.id === selectedAssignee,
      })),
    [selectedAssignee, usersList],
  );

  const assigneeOptions = useMemo(
    () =>
      usersList
        .filter(({ role }) => role === UserRole.Manager)
        .map((user) => ({
          value: user.id,
          label:
            user.firstName && user.lastName
              ? getFullName({ firstName: user.firstName, lastName: user.lastName })
              : user.email,
          disabled: user.id === selectedUser,
        })),
    [selectedUser, usersList],
  );

  const filesChanges = objectsDifferences(
    { images: attachedImages, docs: attachedDocs, videos: attachedVideos },
    {
      images: defaultFormValues.images,
      docs: defaultFormValues.docs,
      videos: defaultFormValues.videos,
    },
  );

  useEffect(() => {
    if (employeeId) {
      setValue('user', employeeId);
    } else if (!isManager && user) {
      setValue('user', user.id ?? '');
    }
  }, [employeeId, isManager, setValue, user]);

  useEffect(() => {
    if (isManager && isSelectedCurrentUser) {
      setValue('isEditable', true);
    }
  }, [isManager, isSelectedCurrentUser, setValue]);

  const {
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    isLoadingFileUpload,
    progress,
  } = useFormAttachments({
    setFormValue: setValue,
    attachedDocs,
    attachedImages,
    attachedVideos,
  });

  const handleFormSubmit = handleSubmit((data: EquipmentFormValues) => {
    if (!isCustomFieldsValid) {
      return;
    }

    const attachmentsIds = [...attachedDocs, ...attachedImages, ...attachedVideos].map(
      (file) => file.id,
    );

    onSubmit(data, attachmentsIds);
    isEditMode && reset(data);
  });

  return {
    isLoading: isEquipmentMutationLoading || isLoadingFileUpload,
    isSubmitting,
    isManager,
    isCurrentUserProfile,
    isSelectedCurrentUser,
    isCreateFromProfile: !!employeeId,
    isSubmitted,
    isDirty: isDirty || !!Object.keys(filesChanges).length,
    control,
    usersOptions,
    assigneeOptions,
    attachedImages,
    attachedVideos,
    attachedDocs,
    progress,
    register,
    getValues,
    setError,
    handleFormSubmit,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
  };
}
