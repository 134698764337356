import { useEffect, useState } from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';

import { API_HANDLERS } from 'api/apiHandlers';
import { PrivateDocumentsAttachments } from 'features/employeeDetails/components/PrivateDocuments/types';
import { Attachment } from 'types';
import { createLink, handleFileDownload } from 'utils';

type ImageGalleryProps = {
  onClose: () => void;
  imageList: Attachment[] | PrivateDocumentsAttachments[];
  postId?: string;
  f?: string;
  open: boolean;
};

export const useImageGallery = ({ imageList, postId, open }: ImageGalleryProps) => {
  const [images, setImages] = useState<ReactImageGalleryItem[]>();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      const promises = Promise.all(
        imageList.map((image) => {
          const apiQuery = API_HANDLERS.ATTACHMENTS.DETAILS({
            fileUrl: image.file_url.large,
          });

          return apiQuery;
        }),
      );
      promises.then((res) => {
        setImages(
          res.map((img) => ({
            original: createLink(img.data),
            thumbnail: createLink(img.data),
          })),
        );
        setIsLoading(false);
      });
    }
  }, [imageList, postId, open]);

  const handleDownloadClick = () => {
    const image = imageList[activeIndex];
    if (image) {
      const promise = API_HANDLERS.ATTACHMENTS.DETAILS({ fileUrl: image.file_url.large });

      promise.then(async (res) => {
        if (res.data) {
          await handleFileDownload({ blobFile: res.data, fileName: image.file.large });
        }
      });
    }
  };

  return {
    isLoading,
    images,
    handleDownloadClick,
    setActiveIndex,
  };
};
