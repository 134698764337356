import { useNavigate } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';

import { CloseButtonW, HeaderW, PageTitle } from './styled';

interface PageHeaderBoldTitleProps {
  title: string;
  onClickCloseIcon?: () => void;
  buttonPosition?: 'fixed' | 'static' | 'relative' | 'absolute';
  classNames?: string;
  withCloseBtn?: boolean;
}

export const PageHeaderBoldTitle = ({
  title,
  onClickCloseIcon,
  buttonPosition = 'fixed',
  classNames,
  withCloseBtn = true,
}: PageHeaderBoldTitleProps) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const onCloseClick = onClickCloseIcon || goBack;

  return (
    <HeaderW>
      <PageTitle className={classNames} variant="h1">
        {title}
      </PageTitle>

      {withCloseBtn && (
        <CloseButtonW onClick={onCloseClick} buttonPosition={buttonPosition}>
          <CloseIcon />
        </CloseButtonW>
      )}
    </HeaderW>
  );
};
