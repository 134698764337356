import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePublishPostMutation } from 'api';
import { PollFormValues, PostDraft } from 'types';

import { formValuesAdapter } from '../adapter';

interface UseCreatePollFormProps {
  draft: PostDraft | null;
}

export function useCreatePollForm({ draft }: UseCreatePollFormProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    publishPostMutation: createPollMutation,
    isLoading,
    isSuccess,
  } = usePublishPostMutation({
    successText: t('toasts.poll.success.create'),
  });

  const handleSubmit = useCallback(
    async (data: PollFormValues, attachmentIds: string[]) => {
      await createPollMutation({
        postId: draft?.id ?? '',
        post: {
          ...formValuesAdapter(data),
          ...(!!attachmentIds.length && { attachment_ids: attachmentIds }),
        },
      });
    },
    [createPollMutation, draft],
  );

  useEffect(() => {
    isSuccess && navigate(-1);
  }, [isSuccess, navigate]);

  return {
    handleSubmit,
    isLoading,
  };
}
