import { IconButton, styled, Typography } from '@mui/material';

export const FromContainer = styled('div')`
  width: 671px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 36px 25px;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
    margin-bottom: 110px;
  }
`;

export const CloseButtonW = styled(IconButton)`
  margin-left: auto;
  position: fixed;
  top: 79px;
  right: 88px;
`;

export const PublicDocumentNote = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p040};
  white-space: break-spaces;

  @media (max-width: 1023px) {
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Footer = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
