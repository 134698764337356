export const multiSelectStyles = (isMobile: boolean) => ({
  height: 'auto',
  minHeight: isMobile ? '64px' : '85px',
  opacity: 1,
  '.MuiSelect-select.MuiSelect-select': {
    height: 'auto',
    padding: isMobile ? '12px 40px 12px 18px' : '20px 60px 20px 37px',
    '& > div': {
      margin: 0,
    },
  },
  '.MuiSvgIcon-root.MuiSelect-icon': {
    top: isMobile ? '12px' : '22px',
  },
});

export const optionSx = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  padding: '5px',
  width: '58px',
  height: '58px',
  borderRadius: '15px',
};

export const dropdownContainerSx = (isMobile: boolean) => ({
  maxWidth: '600px',
  paddingLeft: '16px',
  '.MuiList-root': {
    maxHeight: '320px',
    paddingLeft: isMobile ? '8px' : '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: isMobile ? 'space-evenly' : 'flex-start',
  },
});

export const selectSx = {
  '.MuiSelect-select.MuiSelect-select': {
    paddingLeft: '24px',
  },
};

export const selectMobileSx = {
  minHeight: '60px',

  '.MuiSelect-select.MuiSelect-select': {
    padding: '10px 16px',
    '&.Mui-disabled': {
      height: '100%',
    },
  },
};
