export const EMAIL_REGEX =
  /^((?![\.\-])([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_FORMAT_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,60}$/;

export const PASSWORD_LENGTH_REGEX = /(?=.{8,60})/;
export const NUMERIC_VALUES_REGEX = /(?=.*\d)/;
export const LOWERCASE_VALUES_REGEX = /(?=.*?[a-z])/;
export const UPPERCASE_VALUES_REGEX = /(?=.*?[A-Z])/;
export const SPECIAL_SYMBOLS_REGEX = /(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
export const SAFE_CHARACTERS_REGEX = /^[0-9a-zA-ZåäöÅÄÖ$\-_.+!*'(),&\s]*$/;
export const NO_START_WITH_WHITE_SPACE = /.*\S.*/;
