import { Button, styled } from '@mui/material';

export const ButtonsW = styled('div')`
  width: 415px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1023px) {
    width: 212px;
  }
`;

export const ButtonW = styled(Button, {
  shouldForwardProp: (propname) =>
    propname !== 'isActive' && propname !== 'left' && propname !== 'right',
})<{ isActive: boolean; left?: boolean; right?: boolean }>(({ left, right, isActive, theme }) => ({
  width: '50%',
  height: '60px',
  borderRadius: '0',
  textTransform: 'none',
  fontWeight: '500',
  fontSize: '24px',
  background: isActive ? theme.palette.info.main : theme.palette.white.main,
  color: isActive ? theme.palette.white.main : theme.palette.primary.p080,
  boxShadow: 'none',
  lineHeight: 1,

  '&:hover': {
    background: isActive ? theme.palette.info.main : theme.palette.white.main,
    color: isActive ? theme.palette.white.main : theme.palette.primary.p080,
  },

  borderBottomLeftRadius: left ? '20px' : '0',
  borderTopLeftRadius: left ? '20px' : '0',
  borderBottomRightRadius: right ? '20px' : '0',
  borderTopRightRadius: right ? '20px' : '0',

  [theme.breakpoints.down('desktop')]: {
    height: '30px',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

export const ContentW = styled('div')`
  max-width: 1106px;
  margin-bottom: 48px;
  width: 100%;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    margin-bottom: 8px;
  }
`;

export const SaveLabel = styled(Button, {
  shouldForwardProp: (propname) => propname !== 'isYear',
})<{ isYear: boolean }>(({ isYear, theme }) => ({
  width: '117px',
  height: '32px',
  fontWeight: '450',
  fontSize: '16px',
  lineHeight: '22px',
  textAlign: 'center',
  color: theme.palette.white.main,
  background: isYear ? theme.palette.error.main : 'rgba(255, 153, 0, 0.35)',
  borderRadius: '10px',
  textTransform: 'uppercase',
  padding: '5px 0',
  position: 'absolute',
  right: '-141px',
  top: '20%',

  '&:hover': {
    background: isYear ? theme.palette.error.main : 'rgba(255, 153, 0, 0.35)',
  },
  [theme.breakpoints.down('desktop')]: {
    position: 'static',
    width: 'fit-content',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
}));
