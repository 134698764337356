import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { PostAuthor, TaskFormPayload } from 'api';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import {
  Backdrop,
  BaseTextFieldController,
  ConfirmDialog,
  Form,
  FormGroup,
  SubmitRoundedButton,
  FileUploadButtons,
  SelectController,
  DatePickerWithIcon,
  FormAttachments,
  Avatar,
  RichEditorController,
} from 'components';
import { TIME_DATE_FORMAT_WITH_COMMA } from 'constants/index';
import { useWindowSize } from 'hooks';
import { textFieldStyles, textFieldMobileStyles, richEditorStyles } from 'styles';
import { TaskFormValues, User } from 'types';
import { formattedDate, getFullName } from 'utils';
import { required } from 'validations';

import { AllOption, statusOptions, TASK_FIELDS_LENGTH } from './constants';
import { useTaskForm } from './hooks';
import { descriptionFieldRules, titleFieldRules } from './rules';
import {
  AuthorName,
  ButtonsW,
  FormContainer,
  FormFooter,
  LastUpdateW,
  PublishedDateW,
  RemoveButtonW,
} from './styled';
import {
  avatarWrapperStyles,
  avatarWrapperMobileStyles,
  multiSelectStyles,
  descriptionWrapSx,
  descriptionMobileWrapSx,
} from './styles';

interface TaskFormProps {
  mode: 'create' | 'edit';
  handleSubmit: (data: TaskFormPayload, postId: string) => void;
  draftId: string;
  defaultValues: TaskFormValues;
  isSubmitting?: boolean;
  updatedBy?: string;
  updatedAt?: string;
  author?: PostAuthor;
  usersList: User[];
  createdAt?: string;
  canDelete?: boolean;
  handleOpenModal?: () => void;
  shouldReset?: boolean;
  setShouldReset?: (value: boolean) => void;
}

export function TaskForm({
  draftId,
  handleSubmit,
  mode,
  defaultValues,
  isSubmitting,
  updatedBy,
  updatedAt,
  author,
  usersList,
  createdAt,
  canDelete,
  handleOpenModal,
  shouldReset,
  setShouldReset,
}: TaskFormProps): ReactElement {
  const {
    control,
    isOpen,
    isLoading,
    attachedImages,
    attachedDocs,
    attachedVideos,
    isDirty,
    usersOptions,
    progress,
    handleSubmitConfirmModal,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    handleFormSubmit,
    clearDeadline,
    handleCloseConfirmModal,
    handleAssigneeChange,
  } = useTaskForm({
    draftId,
    mode,
    handleSubmit,
    defaultValues,
    usersList,
    shouldReset,
    setShouldReset,
  });
  const { isMobile } = useWindowSize();

  const { t } = useTranslation();

  const isEditMode = mode === 'edit';
  const loading = isLoading || isSubmitting;
  const formGroupMarginBottom = isMobile ? 8 : 24;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;
  return (
    <>
      {loading && <Backdrop progress={progress} />}

      <FormContainer className={isEditMode ? 'editMode' : ''}>
        <Form onSubmit={handleFormSubmit}>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <BaseTextFieldController
              name="title"
              control={control}
              placeholder={t('task.create.form.placeholders.title')}
              sx={fieldStyles}
              maxLength={TASK_FIELDS_LENGTH.title.max}
              rules={titleFieldRules}
              preventSpaceOnStart
            />
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <SelectController
              name="status"
              control={control}
              placeholder={t('task.create.form.placeholders.status')}
              options={statusOptions}
              rules={required}
            />
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <SelectController
              name="assignee"
              control={control}
              placeholder={t('task.create.form.placeholders.assignee')}
              options={[AllOption, ...usersOptions]}
              multipleSelect
              sx={multiSelectStyles(isMobile)}
              handleChange={handleAssigneeChange}
              type="assignee"
            />
          </FormGroup>

          <FormAttachments
            attachedImages={attachedImages}
            attachedDocs={attachedDocs}
            attachedVideos={attachedVideos}
            handleDeleteAttachment={handleDeleteAttachment}
            draftId={draftId}
            hasDownloadDoc={isEditMode}
          />

          <FormGroup
            sx={isMobile ? descriptionMobileWrapSx : descriptionWrapSx}
            marginBottom={formGroupMarginBottom}
            row
          >
            <RichEditorController
              name="description"
              control={control}
              placeholder={t('task.create.form.placeholders.description')}
              maxLength={TASK_FIELDS_LENGTH.description.max}
              rules={descriptionFieldRules}
              sx={{
                ...richEditorStyles(isMobile),
                ...(isMobile ? { maxWidth: '100%' } : { minWidth: '551px' }),
              }}
            />

            <ButtonsW>
              <DatePickerWithIcon
                control={control}
                name="deadline"
                label={t('task.create.form.placeholders.deadline')}
                clearValue={clearDeadline}
              />
              <FileUploadButtons
                handleAddImages={handleAddImages}
                handleAddDocuments={handleAddDocuments}
                handleAddVideos={handleAddVideos}
              />
            </ButtonsW>
          </FormGroup>

          <FormFooter>
            {updatedBy && updatedAt && (
              <LastUpdateW>
                <span>
                  {t('tasks.details.updateBy', {
                    fullName: updatedBy,
                  })}
                </span>
                <span>{formattedDate(new Date(updatedAt), TIME_DATE_FORMAT_WITH_COMMA)}</span>
              </LastUpdateW>
            )}
            {!isMobile || (isMobile && isEditMode) ? (
              <SubmitRoundedButton disabled={isEditMode && !isDirty} sx={{ marginLeft: 'auto' }} />
            ) : null}
          </FormFooter>
          {canDelete && (
            <RemoveButtonW onClick={() => handleOpenModal?.()} endIcon={<TrashIcon />}>
              {t('tasks.details.removeButton')}
            </RemoveButtonW>
          )}
        </Form>

        {author && (
          <>
            <Avatar
              size={isMobile ? 'xs' : 's'}
              userId={author.id}
              filename={author.avatar?.small || ''}
              wrapperSx={isMobile ? avatarWrapperMobileStyles : avatarWrapperStyles}
            />
            <AuthorName>
              {getFullName({ firstName: author.first_name, lastName: author.last_name })}
            </AuthorName>
          </>
        )}
        {createdAt && <PublishedDateW>{createdAt}</PublishedDateW>}
      </FormContainer>
      {isMobile && !isEditMode && (
        <SubmitRoundedButton
          disabled={isEditMode && !isDirty}
          sx={{ marginLeft: 'auto' }}
          className="fixed-bottom"
          onClick={handleFormSubmit}
        />
      )}

      <ConfirmDialog
        subTitle={t('task.create.modal.exit')}
        title={t('task.create.modal.title')}
        open={isOpen}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
