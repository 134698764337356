import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileTabsValues } from '../types';
import { TabsW, Tab } from './styled';

type MobileTabsProps = {
  activeTab: MobileTabsValues;
  onChangeActiveTab: Dispatch<SetStateAction<MobileTabsValues>>;
};

export const MobileTabs = ({ onChangeActiveTab, activeTab }: MobileTabsProps) => {
  const { t } = useTranslation();
  return (
    <TabsW>
      <Tab
        onClick={() => onChangeActiveTab(MobileTabsValues.NEWSFEED)}
        className={activeTab === MobileTabsValues.NEWSFEED ? 'active' : ''}
      >
        {t('we.mobileTabs.newsfeed')}
      </Tab>
      <Tab
        onClick={() => onChangeActiveTab(MobileTabsValues.TOOLS)}
        className={activeTab === MobileTabsValues.TOOLS ? 'active' : ''}
      >
        {t('we.mobileTabs.tools')}
      </Tab>
    </TabsW>
  );
};
