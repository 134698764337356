import { ButtonProps } from '@mui/material';

interface GetSubmitButtonConfigProps {
  isActive: boolean;
  isBlocked?: boolean;
  disabled: boolean;
  cancel: () => void;
  subscribe: () => void;
  upgrade: () => void;
  isTrial: boolean;
}

interface GetSubmitButtonConfigReturn {
  variant: ButtonProps['variant'];
  text: string;
  onClick: () => void;
}

export function getSubmitButtonConfig({
  isActive,
  disabled,
  cancel,
  subscribe,
  upgrade,
  isTrial,
  isBlocked,
}: GetSubmitButtonConfigProps): GetSubmitButtonConfigReturn {
  if (isTrial) {
    return { variant: 'contained', text: 'subscriptions.buttons.subscribe', onClick: subscribe };
  }

  if (isBlocked) {
    return { variant: 'contained', text: 'subscriptions.buttons.subscribe', onClick: () => {} };
  }

  if (isActive) {
    return { variant: 'outlined', text: 'subscriptions.buttons.cancel', onClick: cancel };
  }

  if (!disabled && !isActive) {
    return { variant: 'contained', text: 'subscriptions.buttons.upgrade', onClick: upgrade };
  }

  return { variant: 'contained', text: 'subscriptions.buttons.subscribe', onClick: subscribe };
}
