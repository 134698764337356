import { useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';

import { EquipmentCategory, EquipmentFilterFormValues } from 'types';

import { categoryOptions } from '../components/constants';

interface UseEquipmentFilterReturn {
  category: EquipmentCategory | null;
  control: Control<EquipmentFilterFormValues>;
}

export function useEquipmentFilter(): UseEquipmentFilterReturn {
  const { control, watch } = useForm<EquipmentFilterFormValues>({
    defaultValues: { category: 'all' },
  });

  const categoryValue = watch('category');

  const currentCategory = useMemo(
    () => categoryOptions.find(({ value }) => value === categoryValue),
    [categoryValue],
  );

  return {
    control,
    category: currentCategory?.value ?? null,
  };
}
