import { useQuery } from '@tanstack/react-query';

import { PostListItem } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UsePostDetailsReturn {
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  isSuccess: boolean;
  post?: PostListItem;
}

interface UsePostDetailsProps {
  postId: string;
  queryName: string;
  include_reactions?: boolean;
}

export function usePostDetails({
  postId,
  queryName,
  include_reactions = false,
}: UsePostDetailsProps): UsePostDetailsReturn {
  const { data, isLoading, fetchStatus, isError, isFetched, isSuccess } = useQuery(
    [queryName, postId],
    () => API_HANDLERS.POST.GET_DETAILS({ postId, include_reactions }),
    {
      enabled: !!postId,
      cacheTime: 0,
    },
  );

  return {
    isLoading: isLoading && fetchStatus !== 'idle',
    post: data?.data,
    isError,
    isFetched,
    isSuccess,
  };
}
