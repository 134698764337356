import { Button, styled } from '@mui/material';

export const ContentW = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 24px;
  margin-top: 24px;

  @media (max-width: 1023px) {
    padding: 16px 8px;
    margin-bottom: 16px;
  }
`;

export const SectionW = styled('div')`
  color: ${({ theme }) => theme.palette.primary.p080};
  border-radius: 20px;
  font-weight: 400;
`;

export const HeaderW = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    margin-bottom: 16px;
  }
`;

export const VisionTitle = styled('div')`
  font-size: 24px;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const ButtonW = styled('div')`
  margin-bottom: 24px;
  font-size: 24px;
`;

export const EditButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const VisionDescription = styled(SectionW)`
  font-size: 18px;
  line-height: 28px;
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
  padding: 24px;

  & p {
    margin: 12px 0;

    &:has(code) {
      margin: 4px 0;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  & h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    font-size: inherit;
  }

  & a {
    color: ${({ theme }) => theme.palette.info.main};
  }

  @media (max-width: 1023px) {
    padding: 16px;
  }
`;
