import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AddAndExtendActions, Spinner } from 'components';
import { useAuth } from 'hooks';
import { Links } from 'settings';
import { cropText } from 'utils';

import { EmptyStateKpi, TargetItem } from '../../components';
import { useGetKpiDetailsQuery } from '../../hooks';
import { TARGETS_LIMIT, VISION_LENGTH } from './constants';
import { ContentW, PreviewSection, Section, Subtitle, VisionW } from './styled';

export function KpiPreview(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isManager } = useAuth();

  const { isLoading, vision, targets } = useGetKpiDetailsQuery({
    limit: TARGETS_LIMIT,
    kpi_status: 'active',
  });

  function handleVisionClick() {
    if (isManager) {
      navigate(Links.we.kpi.editVision);
    }
  }

  const visionContent = vision ? (
    <VisionW
      isManager={isManager}
      onClick={handleVisionClick}
      dangerouslySetInnerHTML={{ __html: cropText(vision, VISION_LENGTH) }}
    />
  ) : (
    <Box sx={{ marginTop: '26px' }}>
      <EmptyStateKpi type="vision" />
    </Box>
  );

  const firstThreeTargets = targets.slice(0, 3);

  const targetsContent =
    firstThreeTargets.length > 0 ? (
      <>
        {firstThreeTargets.map((target) => (
          <TargetItem key={target.id} {...target} isPreview />
        ))}
      </>
    ) : (
      <Box sx={{ marginTop: '26px' }}>
        <EmptyStateKpi type="targets" />
      </Box>
    );

  return (
    <PreviewSection>
      <AddAndExtendActions maximizeIconLink={Links.we.kpi.index} />
      {isLoading && <Spinner />}

      {!isLoading && (
        <ContentW>
          <Section>
            <Subtitle>{t('kpi.preview.vision')}</Subtitle>
            {visionContent}
          </Section>

          <Section>
            <Subtitle>{t('kpi.preview.ourTargets')}</Subtitle>
            <div>{targetsContent}</div>
          </Section>
        </ContentW>
      )}
    </PreviewSection>
  );
}
