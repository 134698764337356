import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { Subscription } from 'types';
import { combineProductByType } from 'utils';

interface UseCurrentSubscriptionDetailsProps {
  subscription?: Subscription;
}

export function useCurrentSubscriptionDetails({
  subscription,
}: UseCurrentSubscriptionDetailsProps) {
  const { data, isFetching } = useQuery(
    ['products'],
    () =>
      API_HANDLERS.PRODUCTS.GET_LIST({
        period: (subscription as Subscription).period,
        currency: (subscription as Subscription).currency,
      }),
    {
      // should start only if there is active subscription
      enabled: Boolean(subscription && subscription?.period),
    },
  );

  const filteredProducts =
    data?.data.filter((product) =>
      product.name.toLowerCase().includes((subscription as Subscription).type),
    ) || [];

  const activeProducts =
    subscription && Boolean(filteredProducts.length)
      ? combineProductByType({
          products: filteredProducts,
          subscription,
        })
      : null;

  return {
    isLoadingProducts: isFetching,
    activeProducts,
  };
}
