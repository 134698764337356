import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDeleteEquipmentMutation } from 'api';
import { useModal } from 'hooks';
import { Equipment } from 'types';

type SelectedEquipmentType = Pick<Equipment, 'id' | 'name'>;

export function useDeleteEquipment() {
  const [selectedEquipment, setSelectedEquipment] = useState<SelectedEquipmentType | null>(null);
  const { employeeId } = useParams();

  const {
    isOpen: isDeleteModalOpen,
    handleOpenModal: openDeleteModal,
    handleCloseModal: closeDeleteModal,
  } = useModal();

  const handleCloseDeleteModal = useCallback(() => {
    closeDeleteModal();
    setSelectedEquipment(null);
  }, [closeDeleteModal]);

  const { deleteEquipmentMutation, isLoading: isConfirmDeleteLoading } = useDeleteEquipmentMutation(
    { onSuccessHandler: handleCloseDeleteModal },
  );

  const onTrashIconClick = useCallback(
    (equipment: SelectedEquipmentType) => {
      setSelectedEquipment(equipment);
      openDeleteModal();
    },
    [openDeleteModal],
  );

  const handleDeleteEquipment = useCallback(() => {
    deleteEquipmentMutation({
      userId: employeeId ?? '',
      equipmentId: selectedEquipment?.id ?? '',
    });
  }, [deleteEquipmentMutation, employeeId, selectedEquipment?.id]);

  return {
    isConfirmDeleteLoading,
    selectedEquipment,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    handleDeleteEquipment,
    onTrashIconClick,
  };
}
