import { alert } from 'components';
import { ATTACHMENTS_LIMIT } from 'constants/index';
import i18n from 'i18n';
import { TypeOfPost } from 'types';

export function prepareFilesBeforeUpload(
  addedFilesCount: number,
  files: FileList,
  postType?: TypeOfPost,
) {
  // case #1: we have already 20 items
  if (addedFilesCount >= ATTACHMENTS_LIMIT) {
    alert.error(i18n.t('toasts.attachmentsLimit', { postType }));
    return;
  }

  const filesCountToAdd = ATTACHMENTS_LIMIT - addedFilesCount;

  // case #2: we don't have 20 items yet, but trying to add more
  if (filesCountToAdd < files.length) {
    alert.error(i18n.t('toasts.attachmentsLimit', { postType }));
  }

  const filesToAdd = [...files].slice(0, filesCountToAdd);

  return filesToAdd;
}
