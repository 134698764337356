import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GoBackButtonWithTitle, PageWrapper, Spinner } from 'components';
import { useAuth } from 'hooks';

import { EditVisionForm } from '../../components';
import { useGetKpiDetailsQuery } from '../../hooks';

const TARGETS_LIMIT = 3;

export function EditVisionPage(): ReactElement {
  const { t } = useTranslation();
  const { isManager } = useAuth();
  const navigate = useNavigate();
  const { isLoading, vision } = useGetKpiDetailsQuery({ limit: TARGETS_LIMIT });

  if (!isManager) {
    navigate(-1);
  }

  if (isLoading) {
    <Spinner />;
  }

  return (
    <PageWrapper>
      <GoBackButtonWithTitle
        title={t('kpi.visionForm.editTitle')}
        containerSx={{ marginBottom: '35px' }}
      />
      <EditVisionForm defaultValues={{ vision }} />
    </PageWrapper>
  );
}
