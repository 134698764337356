import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { EmptyState } from 'components';
import { useAuth } from 'hooks';
import { Links } from 'settings';

import { EmptyStateIconW, FlexW, NavLinkW } from './styled';

interface EmptyStateKpiProps {
  type: 'targets' | 'vision';
}

export function EmptyStateKpi({ type }: EmptyStateKpiProps): ReactElement {
  const { isManager } = useAuth();

  const visionEmptyState = (
    <Trans i18nKey="kpi.preview.emptyState.vision">
      Nothing is here yet.
      <NavLinkW isDisabled={!isManager} to={isManager ? Links.we.kpi.createVision : ''}>
        Add a vision
      </NavLinkW>
      !
    </Trans>
  );

  const targetsEmptyState = (
    <Trans i18nKey="kpi.preview.emptyState.targets">
      Nothing is here yet.
      <NavLinkW isDisabled={!isManager} to={isManager ? Links.we.kpi.createTarget : ''}>
        Create a KPI
      </NavLinkW>
      <br />
      to add a new one!
    </Trans>
  );

  const emptyState = type === 'targets' ? targetsEmptyState : visionEmptyState;

  return (
    <EmptyState>
      <FlexW>
        <EmptyStateIconW />
        <div>{emptyState}</div>
      </FlexW>
    </EmptyState>
  );
}
