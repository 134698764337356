import { IconButton, styled } from '@mui/material';

export const StyledIconBtn = styled(IconButton)`
  background: ${({ theme }) => theme.palette.danger.main};
  box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
  width: 85px;
  height: 85px;
  color: ${({ theme }) => theme.palette.white.main};

  &:hover {
    background: ${({ theme }) => theme.palette.danger.dark};
  }

  svg {
    width: 1.5em;
    height: 1.5em;
  }

  @media (max-width: 1023px) {
    width: 65px;
    height: 65px;
    svg {
      width: 1em;
      height: 1em;
    }
  }
`;
