import { styled, Typography } from '@mui/material';

export const ContainerW = styled('div')<{ isListData: boolean }>`
  padding: 24px 0 0;
  width: 100%;
  min-height: ${({ isListData }) => (isListData ? 'auto' : '338px')};
  border-radius: 15px;
  position: relative;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  margin-bottom: 40px;

  @media (max-width: 520px) {
    min-height: 182px;
    padding-bottom: ${({ isListData }) => (isListData ? 0 : '30px')};
    margin-bottom: 20px;
  }
`;

export const TitleW = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 18px;
  margin-left: 24px;

  @media (max-width: 1024px) {
    margin-left: 16px;
  }
`;

export const ActionsW = styled('div')`
  position: absolute;
  right: 15px;
  top: 24px;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 32px;
`;

export const AddButtonW = styled('div')`
  display: flex;
  align-items: center;
`;

export const TitleIconW = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export const SpinnerW = styled('div')`
  position: relative;
  height: 144px;
  padding: 32px 0 16px;
`;
