import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES } from 'constants/index';

export function useTargetDetailsQuery(targetId: string) {
  const { data, isLoading } = useQuery(
    [QUERIES.TARGET_DETAILS, targetId],
    () => API_HANDLERS.KPI.TARGET_DETAILS(targetId),
    {
      cacheTime: 0,
      refetchOnMount: true,
    },
  );

  return {
    isLoading,
    data,
  };
}
