import { styled } from '@mui/material';

export const ContentW = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 32px 24px;
  margin-top: 24px;
  min-height: 235px;
  position: relative;

  @media (max-width: 1023px) {
    padding: 16px 8px;
  }
`;

export const CenteredW = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ListW = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 16px;
  column-gap: 14px;

  @media (max-width: 1240px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1023px) {
    row-gap: 8px;
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 520px) {
    display: flex;
    flex-direction: column;
  }
`;
