import { Button, styled } from '@mui/material';

export const ButtonMUI = styled(Button)`
  text-transform: none;
  padding: 0;
  width: 100%;
  height: 40px;
  background-color: white;
  display: flex;
  padding: 11px 8px;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168);

  &:hover {
    background-color: white;
  }
`;
