import { BoxProps } from '@mui/system';
import { ReactElement, ReactNode } from 'react';

import { GoBackArrow } from '../GoBackArrow';
import { ButtonContainer, TitleW, ActionsW, HeaderW } from './styled';

interface GoBackButtonProps {
  title: string;
  containerSx?: BoxProps['sx'];
  titleSx?: BoxProps['sx'];
  headerSx?: BoxProps['sx'];
  Actions?: ReactNode;
  onClick?: () => void;
}

export function GoBackButtonWithTitle({
  title,
  containerSx,
  titleSx,
  headerSx,
  Actions,
  onClick,
}: GoBackButtonProps): ReactElement {
  return (
    <ButtonContainer sx={containerSx}>
      <HeaderW sx={headerSx}>
        <GoBackArrow onClick={onClick} />
        <TitleW className="title" sx={titleSx}>
          {title}
        </TitleW>
      </HeaderW>

      {Actions && <ActionsW>{Actions}</ActionsW>}
    </ButtonContainer>
  );
}
