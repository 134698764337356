import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateCustomNotificationMutation } from 'api/mutations/useCreateCustomNotificationMutation';
import { useCallbackPrompt } from 'hooks';
import { useFormAttachments } from 'hooks/useFormAttachments';
import { Attachment, CreateNotificationFormValues, PostDraft, SelectOption, User } from 'types';
import { getFullName, objectsDifferences } from 'utils';

import { defaultValuesAdapter, formValuesAdapter } from '../adapters';
import { AllOption } from '../constants';

interface UseCreateNotificationFormReturn {
  control: Control<CreateNotificationFormValues>;
  isLoading: boolean;
  attachedImages: Attachment[];
  attachedDocs: Attachment[];
  attachedVideos: Attachment[];
  attachments?: Attachment[];
  isOpen: boolean;
  notificationTitle: string;
  notificationDescription: string;
  usersOptions: SelectOption[];
  progress: number;
  goBack: () => void;
  handleFormSubmit: () => void;
  handleAddImages: (files: FileList) => Promise<void>;
  handleAddDocuments: (files: FileList) => Promise<void>;
  handleAddVideos: (files: FileList) => Promise<void>;
  handleCloseConfirmModal: () => void;
  handleSubmitConfirmModal: () => void;
  handleDeleteAttachment: (attachmentId: string, type: 'images' | 'docs' | 'videos') => void;
  handleUsersChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface UseCreateNotificationFormProps {
  draft: PostDraft;
  usersList: User[];
}

export function useCreateNotificationForm({
  draft,
  usersList,
}: UseCreateNotificationFormProps): UseCreateNotificationFormReturn {
  const usersOptions = usersList.map((user) => ({
    value: user.id,
    label:
      user.firstName && user.firstName
        ? getFullName({ firstName: user.firstName, lastName: user.lastName })
        : user.email,
  }));

  const defaultValues = defaultValuesAdapter({ draft });
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, isDirty, isSubmitted, errors },
  } = useForm<CreateNotificationFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const attachedImages = watch('images');
  const attachedDocs = watch('docs');
  const attachedVideos = watch('videos');
  const notificationTitle = watch('title');
  const notificationDescription = watch('description');

  const filesChanges = objectsDifferences(
    { images: attachedImages, docs: attachedDocs, videos: attachedVideos },
    {
      images: defaultValues.images,
      docs: defaultValues.docs,
      videos: defaultValues.videos,
    },
  );

  const navigate = useNavigate();

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    (isDirty || Object.keys(filesChanges).length > 0) && !isSubmitted,
  );

  const { createCustomNotification, isLoading: isCreateNotificationLoading } =
    useCreateCustomNotificationMutation({
      successText: t('toasts.notification.successCreate'),
      setIsSuccess,
    });

  const attachments =
    [...attachedDocs, ...attachedImages, ...attachedVideos].length > 0
      ? [...attachedDocs, ...attachedImages, ...attachedVideos]
      : undefined;

  const {
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    isLoadingFileUpload,
    progress,
  } = useFormAttachments({
    setFormValue: setValue,
    attachedDocs,
    attachedImages,
    attachedVideos,
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    const attachment_ids = [...attachedImages, ...attachedDocs, ...attachedVideos].map(
      (file) => file.id,
    );
    const { title, description, users } = data;

    const usersData =
      users?.length === 1 && users.includes(AllOption.value)
        ? usersList.map(({ id }) => id)
        : users;

    await createCustomNotification({
      notification: formValuesAdapter({
        title,
        description,
        user_ids: usersData,
        attachment_ids,
      }),
    });
  });

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  async function handleSubmitConfirmModal() {
    confirmNavigation();
  }

  const handleUsersChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;

      if (!!value && value.length > 1) {
        if (value?.at(-1) === AllOption.value) {
          setValue('users', [AllOption.value]);
        } else if (value.includes(AllOption.value)) {
          setValue(
            'users',
            (value as unknown as string[]).filter((value) => value !== AllOption.value),
          );
        }
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (isSubmitted && Object.keys(errors).length === 0 && isSuccess) {
      goBack();
    }
  }, [isSubmitted, goBack, errors, isSuccess]);

  return {
    control,
    isLoading: isSubmitting || isCreateNotificationLoading || isLoadingFileUpload,
    attachments,
    attachedImages,
    attachedDocs,
    attachedVideos,
    isOpen: showPrompt,
    notificationTitle,
    notificationDescription,
    usersOptions: [AllOption, ...usersOptions],
    progress,
    goBack,
    handleAddImages,
    handleFormSubmit,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    handleUsersChange,
    handleSubmitConfirmModal,
    handleCloseConfirmModal: cancelNavigation,
  };
}
