import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthPageWrapper, AuthPageTitle } from 'features/auth';
import { LanguageSelector } from 'features/languageSelector';
import { AuthPageLink } from 'types';

import { CreatePasswordForm, TermsAndConditions } from '../../components';

export function FinishUserActivationPage(): ReactElement {
  const { t } = useTranslation();
  const styles = { padding: '85px 96px 0 96px' };

  return (
    <AuthPageWrapper link={AuthPageLink.signIn} contentSx={styles}>
      <AuthPageTitle>{t('activateEmployee.pageTitle')}</AuthPageTitle>

      <CreatePasswordForm />
      <TermsAndConditions />
      <Box sx={{ marginTop: '16px' }}>
        <LanguageSelector />
      </Box>
    </AuthPageWrapper>
  );
}
