import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { DeletePostRequestParams } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UseDeletePostMutationReturn {
  deletePostMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    DeletePostRequestParams,
    unknown
  >;
  isLoading: boolean;
}

export function useDeletePostMutation(onSuccessHandler?: () => void): UseDeletePostMutationReturn {
  const { mutateAsync: deletePostMutation, isLoading } = useMutation(API_HANDLERS.POST.DELETE, {
    onSuccess: () => {
      onSuccessHandler?.();
    },
  });

  return {
    deletePostMutation,
    isLoading,
  };
}
