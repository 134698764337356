interface HideWordLimitProps {
  docName: string;
  limit: number;
}

export function hideDocNameMidPart({ docName, limit }: HideWordLimitProps) {
  const halfOfLimit = limit / 2;
  const lastDotIndex = docName.lastIndexOf('.');

  const nameWithoutExtension = docName.slice(0, lastDotIndex);
  const extension = docName.slice(lastDotIndex);

  const start = nameWithoutExtension.slice(0, halfOfLimit);
  const end = nameWithoutExtension.slice(-halfOfLimit);

  return `${start}...${end}${extension}`;
}
