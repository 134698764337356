import { styled, Typography } from '@mui/material';

export const HeaderW = styled('div')`
  margin-bottom: 48px;
  display: flex;
  align-items: flex-start;
  padding-top: 25px;
  @media (max-width: 1023px) {
    margin-bottom: 16px;
    padding-top: 0px;
  }
`;

export const PageTitle = styled(Typography)`
  font-weight: 700;
  font-size: 70px;
  line-height: 70px;
  color: ${({ theme }) => theme.palette.primary.p080};
  white-space: break-spaces;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }
`;

export const CloseButtonW = styled('div')`
  margin-left: auto;
  position: fixed;
  top: 79px;
  right: 88px;
  cursor: pointer;
  @media (max-width: 1023px) {
    position: static;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
