import { TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker as DatePickerMUI,
  DatePickerProps as PickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactElement } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { BaseTextField } from 'components';

type DatePickerProps = Omit<PickerProps<unknown, unknown>, 'onChange' | 'value' | 'renderInput'> & {
  placeholder: string;
  sx: TextFieldProps['sx'];
};

export function DatePicker<T extends FieldValues>({
  control,
  disabled,
  name,
  rules,
  placeholder,
  sx,
  ...otherProps
}: DatePickerProps & UseControllerProps<T>): ReactElement {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerMUI
        {...otherProps}
        inputFormat="dd/MM/yyyy"
        mask="__/__/____"
        InputProps={{
          disableUnderline: true,
        }}
        PopperProps={{
          placement: 'bottom-end',
        }}
        onChange={onChange}
        value={value}
        renderInput={(params) => (
          <BaseTextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder,
            }}
            error={Boolean(error)}
            helperText={error && error.message}
            sx={{
              '.MuiInputAdornment-root': {
                position: 'absolute',
                right: '24px',
              },
              '.MuiButtonBase-root': {
                margin: 0,
              },
              '& .MuiInputBase-root.Mui-error': {
                border: 'none',
              },
              ...sx,
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
