import { styled } from '@mui/material';

export const TableContainer = styled('table')`
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
  border-spacing: unset;
`;

export const HTableRow = styled('tr')(({}) => ({
  cursor: 'pointer',
  position: 'sticky',
  display: 'flex',
  padding: '18px 24px',
  columnGap: '1%',
}));

export const HTableCell = styled('td')<{ isExpireDate?: boolean }>(({ isExpireDate }) => ({
  color: 'rgba(0, 0, 0, 0.2)',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  border: 'none',
  wordBreak: 'break-all',

  '@media (max-width: 1024px)': {
    marginBottom: '8px',
    '&:first-of-type': {
      display: 'none',
    },
    '&:nth-of-type(5)': {
      marginTop: `${isExpireDate ? '-60px' : '-44px'}  `,
      marginLeft: '50%',
    },
  },
}));

export const StyledTableRow = styled('tr')(({ theme }) => ({
  cursor: 'pointer',
  position: 'sticky',
  display: 'flex',
  padding: '18px 24px',
  columnGap: '1%',

  '@media (max-width: 1024px)': {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  '@media (max-width: 520px)': {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },

  '&.row-1&::after': {
    border: 'none',
  },

  '&.not-hovered&:hover': {
    backgroundColor: 'transparent',
  },

  '&.inactive': {
    opacity: '0.3',
  },

  ':hover': {
    backgroundColor: theme.palette.white.main,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    left: '24px',
    right: '24px',
    borderBottom: `1px solid ${theme.palette.white.main}`,
    top: '0px',
  },
  [theme.breakpoints.down('desktop')]: {
    padding: '18px 16px',
    flexDirection: 'row',
  },

  '&.activeClass': {
    animationName: 'example',
    animationDuration: '7s',

    '@keyframes example': {
      from: {
        boxShadow: 'inset 0px 0px 15px rgba(0, 0, 0, 1)',
      },
      to: {
        boxShadow: 'inset 0px 0px 0px rgba(0, 0, 0, 0.1)',
      },
    },
  },
}));

export const StyledTableCell = styled('td')<{ isExpandedView: boolean; isExpireDate?: boolean }>(
  ({ theme, isExpandedView, isExpireDate }) => ({
    borderBottom: 'none',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.p080,
    wordBreak: 'break-all',
    margin: isExpandedView ? 0 : 'auto 0px',

    '@media (min-width: 1025px)': {
      '&:nth-of-type(2), &:nth-of-type(3)': {
        marginTop: '18px',
      },
    },

    '@media (max-width: 1024px)': {
      fontSize: '12px',
      lineHeight: '16px',
      marginBottom: '16px',
      '&:nth-of-type(6)': {
        marginTop: `${isExpireDate ? '-32px' : '-20px'}  `,
        marginLeft: '50%',
      },
    },

    '&:first-of-type': {
      order: '-1',
    },
    '&:nth-of-type(2)': {
      '@media (max-width: 1024px)': {
        order: '-2',
      },
    },

    '&:last-child': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      '@media (max-width: 1024px)': {
        order: '-1',
        marginTop: '0',
        justifyContent: 'flex-end',
        height: '40px',
      },
    },

    [theme.breakpoints.down('desktop')]: {
      padding: '0px',
    },
  }),
);
