import { styled } from '@mui/material';

export const TableContainer = styled('table')`
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  width: 100%;
  overflow: hidden;
  border-spacing: unset;
`;

export const HTableRow = styled('tr')(({}) => ({
  cursor: 'pointer',
  position: 'sticky',
  // hide last border
  display: 'flex',
  padding: '18px 0px 0px',
}));

export const HTableCell = styled('td')(({}) => ({
  color: 'rgba(0, 0, 0, 0.2)',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  border: 'none',
  wordBreak: 'break-all',
  padding: '0px 24px',
}));

export const StyledTableRow = styled('tr')(({ theme }) => ({
  cursor: 'pointer',
  position: 'sticky',
  // hide last border
  display: 'flex',
  padding: '18px 0px',
  '&:last-child td, &:last-child th': {
    border: 0,
  },

  '&.row-1&::after': {
    border: 'none',
  },

  '&.not-hovered&:hover': {
    backgroundColor: 'transparent',
  },

  '&.inactive': {
    opacity: '0.3',
  },

  ':hover': {
    backgroundColor: theme.palette.white.main,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    left: '24px',
    right: '24px',
    borderBottom: `1px solid ${theme.palette.white.main}`,
    top: '0px',
  },
  [theme.breakpoints.down('desktop')]: {
    padding: '18px 16px',
  },
}));

export const StyledTableCell = styled('td')(({ theme }) => ({
  borderBottom: 'none',
  fontSize: '14px',
  lineHeight: '18px',
  color: theme.palette.primary.p080,
  wordBreak: 'break-all',
  margin: 'auto 0px',
  padding: '0px 24px',
  [theme.breakpoints.down('desktop')]: {
    padding: '0px',
  },
}));
