import { styled } from '@mui/material';

export const CardW = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);

  border-radius: 15px;
  padding: 24px 24px 16px;
  display: flex;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 16px 13px;
  }
`;
