import { ChangeEvent, ReactElement } from 'react';
import { Control, Controller, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseTextField, FormGroup } from 'components';
import { POLL_FORM_FIELDS_LENGTH } from 'constants/index';
import { useWindowSize } from 'hooks';
import { textFieldMobileStyles, textFieldStyles } from 'styles';
import { PollFormValues } from 'types';
import { REQUIRED } from 'validations';

import { initialWindowSizeProps } from '../constants';
import { optionFieldRules } from '../PollForm/validation';
import { useOptionsFields } from './hooks';
import { FieldsW } from './styled';
import { inputStyles } from './styles';

interface OptionsFieldsProps {
  control: Control<PollFormValues>;
  isSubmitted: boolean;
  isValidOptionsNumber: boolean;
  disabled: boolean;
  getValues: UseFormGetValues<PollFormValues>;
}

export function OptionsFields({
  control,
  isSubmitted,
  isValidOptionsNumber,
  disabled,
  getValues,
}: OptionsFieldsProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize(initialWindowSizeProps);
  const { fields, handleChange } = useOptionsFields({
    control,
    getValues,
  });

  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;
  return (
    <FormGroup marginBottom={isMobile ? 16 : 24}>
      {fields.map(({ value, id }, index) => {
        return (
          <FieldsW key={id}>
            <Controller
              render={({ field: { onChange, ...field }, fieldState: { error } }) => {
                const isRequired = !isValidOptionsNumber && !field.value;
                const isError = isSubmitted && (isRequired || !!error);

                return (
                  <BaseTextField
                    placeholder={t('poll.create.form.placeholders.option', { number: index + 1 })}
                    sx={fieldStyles}
                    InputProps={{
                      disableUnderline: true,
                      sx: inputStyles,
                    }}
                    inputProps={{
                      maxLength: POLL_FORM_FIELDS_LENGTH.option.max,
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onChange(e);
                      handleChange(e, index);
                    }}
                    error={isError}
                    helperText={isError && isRequired && REQUIRED}
                    required={isRequired}
                    disabled={disabled}
                    {...field}
                  />
                );
              }}
              name={`options.${index}.value`}
              control={control}
              defaultValue={value}
              rules={optionFieldRules}
            />
          </FieldsW>
        );
      })}
    </FormGroup>
  );
}
