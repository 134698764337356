import { ReactElement } from 'react';

import { Avatar } from 'components';
import { TIME_DATE_FORMAT_WITH_COMMA, TIME_DATE_MONTH_WITH_COMMA } from 'constants/index';
import { useAuth, useWindowSize } from 'hooks';
import { Comment, InViewItemRef } from 'types';
import { formattedDate, getFullName } from 'utils';

import { CommentText, CommentW, DateTime, UserName } from './styled';
import { avatarWrapperStyles, avatarWrapperMobileStyles } from './styles';

interface CommentListItemProps {
  comment: Comment;
  lastItemRef?: InViewItemRef;
}

export function CommentListItem({ comment, lastItemRef }: CommentListItemProps): ReactElement {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();

  const { created_at, author, content } = comment;
  const commentDate = new Date(created_at);
  const isCurrentYear = commentDate.getFullYear() === new Date().getFullYear();
  const dateFormat = isCurrentYear ? TIME_DATE_MONTH_WITH_COMMA : TIME_DATE_FORMAT_WITH_COMMA;

  const isCommentOwner = user?.id === author.id;
  const userName = getFullName({ firstName: author.first_name, lastName: author.last_name });

  return (
    <CommentW isCommentOwner={isCommentOwner} ref={lastItemRef}>
      {!isCommentOwner && <UserName>{userName}</UserName>}
      <CommentText dangerouslySetInnerHTML={{ __html: content }} />
      <DateTime isCommentOwner={isCommentOwner}>{formattedDate(commentDate, dateFormat)}</DateTime>
      <Avatar
        size={isMobile ? 'xs' : 's'}
        userId={author.id}
        filename={author.avatar?.small || ''}
        wrapperSx={{
          ...(isMobile ? avatarWrapperMobileStyles : avatarWrapperStyles),
          ...(isCommentOwner && {
            right: isMobile ? '-31px' : '-20px',
            left: 'unset',
          }),
        }}
      />
    </CommentW>
  );
}
