import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCommentGoalMutation } from 'api';
import { useWindowSize } from 'hooks';
import { removeExtraSpaces } from 'utils';

interface UseAddGoalCommentFormProps {
  userId: string;
  achievementId: string;
  goalId: string;
}

export const useAddGoalCommentForm = ({
  userId,
  achievementId,
  goalId,
}: UseAddGoalCommentFormProps) => {
  const [isResetField, setResetField] = useState(false);
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const { commentGoalMutation, isLoading } = useCommentGoalMutation({
    successText: t('comments.alerts.created'),
    invalidateQueries: ['goal-details'],
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitted },
  } = useForm<{ content: string }>({
    defaultValues: {
      content: '',
    },
  });

  const handleFormSubmit = handleSubmit(async ({ content }) => {
    if (content.trim() === '') {
      return;
    }
    const data = { content: removeExtraSpaces(content) };
    await commentGoalMutation({ data, userId, achievementId, goalId });

    setResetField((prev) => !prev);
    reset();
  });

  return {
    isLoading,
    isMobile,
    isResetField,
    isDirty,
    isSubmitted,
    control,
    handleFormSubmit,
  };
};
