import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line
import {
  useCreateCategoryMutation,
  useEditCategoryMutation,
  useUsersListQuery,
} from 'api';
import { QUERIES } from 'constants/index';
import { useCallbackPrompt } from 'hooks';
import { CreateCategoryFormValues, SelectOption } from 'types';
import { getFullName } from 'utils';

import { formValuesAdapter } from '../adapters';
import { AllOption } from '../constants';

interface UseCreateCategoryFormReturn {
  handleFormSubmit: () => void;
  handleAssigneeChange: (e: ChangeEvent<HTMLInputElement>) => void;
  control: Control<CreateCategoryFormValues>;
  isLoading: boolean;
  handleCloseButton: () => void;
  handleCloseModal: () => void;
  handleSubmitConfirmModal: () => void;
  isOpen: boolean;
  isDirty: boolean;
  isNotDisableButton: boolean;
  usersOptions: SelectOption[];
}

interface UseCreateCategoryFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  category: any;
  isEdit?: boolean;
}

export function useCreateCategoryForm({
  category,
  isEdit,
}: UseCreateCategoryFormProps): UseCreateCategoryFormReturn {
  const categoryId = category?.id as string;
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty, isSubmitted, errors },
  } = useForm<CreateCategoryFormValues>({
    defaultValues: category,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    (isDirty && !isSubmitted) || Object.keys(errors).length > 0,
  );

  const { createCategoryMutation, isLoading: isCreateCategoryLoading } = useCreateCategoryMutation({
    successText: t('toasts.category.success.create'),
    setIsSuccess,
    invalidateQueries: [QUERIES.LAST_UPDATED_DOCS, QUERIES.DOCUMENTS_LIST],
  });

  const { editCategoryMutation, isLoading: isEditCategoryLoading } = useEditCategoryMutation({
    successText: t('toasts.category.success.edit'),
    setIsSuccess,
    invalidateQueries: [QUERIES.LAST_UPDATED_DOCS, QUERIES.DOCUMENTS_LIST],
  });

  const handleAssigneeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;

      if (!!value && value.length > 1) {
        if (value?.at(-1) === AllOption.value) {
          setValue('assigned_to', [AllOption.value]);
        } else if (value.includes(AllOption.value)) {
          setValue(
            'assigned_to',
            (value as unknown as string[]).filter((value) => value !== AllOption.value),
          );
        }
      }
    },
    [setValue],
  );

  const handleFormSubmit = handleSubmit(async (data) => {
    const { title, description, icon, assigned_to } = data;

    const adaptedData = {
      category: formValuesAdapter({
        title,
        description,
        icon,
        assigned_to,
        visibility: assigned_to?.includes(AllOption.value) ? 'public' : 'private',
      }),
      categoryId: categoryId,
    };

    if (assigned_to?.includes(AllOption.value)) {
      delete adaptedData.category['assigned_to'];
    }

    if (isEdit) {
      await editCategoryMutation(adaptedData);
      return;
    }
    await createCategoryMutation(adaptedData);
  });

  function handleCloseButton() {
    navigate(-1);
  }

  async function handleSubmitConfirmModal() {
    if (isEdit) {
      confirmNavigation();
      navigate(-1);
      return;
    }
    confirmNavigation();
    navigate(-1);
  }

  useEffect(() => {
    if (isSubmitted && Object.keys(errors).length === 0 && isSuccess) {
      navigate(-1);
    }
  }, [isSubmitted, navigate, errors, isSuccess]);

  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  const usersOptions = usersList.map((user) => ({
    value: user.id,
    label:
      user.firstName && user.firstName
        ? getFullName({ firstName: user.firstName, lastName: user.lastName })
        : user.email,
  }));

  useEffect(() => {
    if (category) {
      setValue('title', category.title);
      setValue('icon', category.icon);
      setValue(
        'assigned_to',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        category.assigned_to ? category.assigned_to.map((user: any) => user.id) : ['all'],
      );
    }
  }, [category, setValue]);

  return {
    control,
    handleFormSubmit,
    handleAssigneeChange,
    isLoading:
      isSubmitting || isEditCategoryLoading || isCreateCategoryLoading || isLoadingUsersList,
    handleCloseButton,
    handleCloseModal: cancelNavigation,
    isOpen: showPrompt,
    handleSubmitConfirmModal,
    isDirty,
    isNotDisableButton: isDirty,
    usersOptions,
  };
}
