import { useEditLinkMutation } from 'api';
import { QuickLinkFormValues } from 'types';
import { removeExtraSpaces } from 'utils';

export function useEditLink(linkId: string) {
  const { editLinkMutation, isLoading } = useEditLinkMutation(linkId);

  async function handleSubmit(data: QuickLinkFormValues) {
    const { title, url } = data;
    const titleValue = removeExtraSpaces(title);
    const urlValue = removeExtraSpaces(url);

    await editLinkMutation({
      ...data,
      linkId,
      url: urlValue,
      title: titleValue,
    });
  }

  return {
    handleSubmit,
    isLoading,
  };
}
