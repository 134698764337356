import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { QUERIES } from 'constants/index';

export function useCreateQuickLinkMutation() {
  const { t } = useTranslation();

  const { mutateAsync: createLinkMutation, isLoading } = useMutation(
    API_HANDLERS.QUICK_LINKS.CREATE_LINK,
    {
      onSuccess: () => {
        alert.success(t('toasts.quickLinks.success.create'));
        queryClient.invalidateQueries([QUERIES.QUICK_LINKS_LIST]);
      },
    },
  );

  return { isLoading, createLinkMutation };
}
