import { ButtonProps } from '@mui/material';
import { ReactElement } from 'react';

import { ButtonMUI } from './styled';

function Button({ children, ...otherProps }: ButtonProps): ReactElement {
  return (
    <ButtonMUI variant="contained" color="info" {...otherProps}>
      {children}
    </ButtonMUI>
  );
}

export { Button };
export { GoBackArrow } from './GoBackArrow';
export { SubmitRoundedButton } from './SubmitRoundedButton';
export { CancelRoundedButton } from './CancelRoundedButton';
export { GoBackButtonWithTitle } from './GoBackButtonWithTitle';
export { MaximizeButton } from './MaximizeButton';
