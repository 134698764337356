import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Form,
  TextFieldController,
  SubmitRoundedButton,
  FormGroup,
  PasswordController,
} from 'components';
import { useWindowSize } from 'hooks';
import { passwordRules, required } from 'validations';

import { hintsSx, submitBtnSx } from './styles';
import { useCreatePasswordForm } from './useCreatePasswordForm';

export function CreatePasswordForm(): ReactElement {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const { control, handleFormSubmit, validatePasswords } = useCreatePasswordForm();

  return (
    <Form sx={{ marginTop: isMobile ? '32px' : '56px' }} onSubmit={handleFormSubmit}>
      <FormGroup marginBottom={isMobile ? 32 : 56}>
        <PasswordController
          name="password"
          id="password"
          control={control}
          label={t('createPassword.password')}
          placeholder={t('createPassword.password')}
          rules={passwordRules}
          maxLength={60}
          hintsSx={hintsSx}
        />
      </FormGroup>
      <FormGroup marginBottom={isMobile ? 24 : 56}>
        <TextFieldController
          name="repeatPassword"
          id="repeatPassword"
          control={control}
          type="password"
          label={t('createPassword.passwordRepeat')}
          placeholder={t('createPassword.passwordRepeat')}
          maxLength={60}
          onBlur={validatePasswords}
          rules={required}
        />
      </FormGroup>

      <SubmitRoundedButton sx={submitBtnSx} />
    </Form>
  );
}
