import { Badge, Button, styled } from '@mui/material';

export const Wrapper = styled('div')`
  position: relative;
`;

export const StyledBtn = styled(Button)`
  padding: 5px;
  min-width: unset;
  border-radius: 50%;
`;

export const BadgeW = styled(Badge)`
  & .MuiBadge-badge {
    background-color: #ff0000;
    min-width: 11px;
    width: 11px;
    height: 11px;
    top: 5px;
    right: 5px;
  }
`;
