import { BoxProps, IconButton } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { ReactComponent as MaximizeIcon } from 'assets/icons/containerActions/maximize.svg';

import { ContentW, HeaderW, Title, ActionsW } from './styled';

interface PreviewContainerProps {
  title: string;
  handleTitleClick?: () => void;
  addIconLink?: string;
  maximizeIconLink?: string;
  children: ReactNode;
  containerSx?: BoxProps['sx'];
  headerSx?: BoxProps['sx'];
}

export function PreviewContainer({
  title,
  handleTitleClick,
  addIconLink,
  maximizeIconLink,
  children,
  containerSx,
  headerSx,
}: PreviewContainerProps): ReactElement {
  return (
    <ContentW sx={containerSx}>
      <HeaderW sx={headerSx}>
        <Title onClick={handleTitleClick} sx={{ cursor: handleTitleClick ? 'pointer' : 'default' }}>
          {title}
        </Title>

        <ActionsW>
          {addIconLink && (
            <NavLink to={addIconLink}>
              <IconButton>
                <AddIcon style={{ opacity: '0.4' }} />
              </IconButton>
            </NavLink>
          )}
          {maximizeIconLink && (
            <NavLink to={maximizeIconLink}>
              <IconButton>
                <MaximizeIcon style={{ opacity: '0.4' }} />
              </IconButton>
            </NavLink>
          )}
        </ActionsW>
      </HeaderW>

      {children}
    </ContentW>
  );
}
