import { ReactElement, RefObject } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactionStats } from 'api';
import { ReactComponent as ProcessIcon } from 'assets/icons/process-automation.svg';
import { ReactComponent as BirthdayIcon } from 'assets/icons/welcomePostIcon.svg';
import birthdayPost from 'assets/images/birthdayPost.png';
import { ReactionsWrapper } from 'components/ReactionsWrapper';
import { useModal } from 'hooks';
import { InViewItemRef } from 'types';

import { PostItemActions } from '../../PostItemActions';
import { ReactionConfirmDialog } from '../../ReactionConfirmDialog';
import { Container, IconW, LeftSide, LinkW, PostTitle, PostW, RightSide } from '../styled';
import { BirthdayDescription, BirthdayImage } from './styled';

interface BirthdayPostProps {
  authorName: string;
  onNavigateToNewUser: () => void;
  postRef: RefObject<HTMLDivElement>;
  lastItemRef?: InViewItemRef;
  handlePostClick?: () => void;
  comments_count?: number;
  id: string;
  reactions?: ReactionStats;
}

export function BirthdayPost({
  authorName,
  onNavigateToNewUser,
  postRef,
  lastItemRef,
  handlePostClick,
  comments_count,
  id,
  reactions,
}: BirthdayPostProps): ReactElement {
  const { t } = useTranslation();
  const { handleOpenModal, handleCloseModal, isOpen } = useModal();

  return (
    <Container ref={postRef}>
      <IconW>
        <BirthdayIcon />
        <ProcessIcon />
      </IconW>

      <PostW ref={lastItemRef} onClick={handlePostClick} isAutomatedPost>
        <LeftSide>
          <BirthdayImage src={birthdayPost} alt="birthday post image" />
          <PostTitle>
            {t('post.listItem.birthdayPost.title', {
              user: authorName,
            })}
          </PostTitle>
          <BirthdayDescription>
            <Trans
              i18nKey="post.listItem.birthdayPost.description"
              values={{
                authorName,
              }}
            >
              {' '}
              It&quote;s
              <LinkW
                onClick={(e) => {
                  e.stopPropagation();
                  onNavigateToNewUser();
                }}
              >
                {authorName}
              </LinkW>
              birthday today! Congratulations! 🥳
            </Trans>
          </BirthdayDescription>
          <ReactionsWrapper
            reactions={reactions}
            handleOpenModal={handleOpenModal}
            id={id}
            isAutoPost
          />
        </LeftSide>

        <RightSide>
          <PostItemActions isPreview={false} commentsCount={comments_count} />
        </RightSide>
      </PostW>

      <ReactionConfirmDialog
        title={t('reactions.title')}
        onClose={handleCloseModal}
        postId={id}
        open={isOpen}
      />
    </Container>
  );
}
