import { UserDetailsResponse } from 'api/types';
import { UserExtendedDetails } from 'types';

export function userDetailsAdapter(user: UserDetailsResponse): UserExtendedDetails {
  const {
    first_name,
    last_name,
    country_code,
    birth_date,
    address_1,
    address_2,
    employment_number,
    status_comment,
    licences,
    emergency,
    employed_at,
    clothes_size,
    created_at,
    updated_at,
    position,
    postcode,
    ssn,
    city,
    region,
    country,
    department,
    education,
    allergies,
    comments,
    ...otherProps
  } = user;

  return {
    firstName: first_name || '',
    lastName: last_name || '',
    countryCode: country_code,
    position: position || '',
    birthDate: birth_date || '',
    address1: address_1 || '',
    address2: address_2 || '',
    postcode: postcode || '',
    city: city || '',
    region: region || '',
    country: country || '',
    department: department || '',
    ssn: ssn || '',
    employmentNumber: employment_number || '',
    employedAt: employed_at || '',
    education: education || '',
    clothesSize: clothes_size || '',
    allergies: allergies || '',
    comments: comments || '',
    statusComment: status_comment || '',
    createdAt: created_at || '',
    updatedAt: updated_at || '',
    ...(emergency && {
      emergency: {
        firstName: emergency?.first_name || '',
        lastName: emergency?.last_name || '',
        countryCode: emergency?.country_code || '',
        phone: emergency?.phone || '',
        relation: emergency?.relation || '',
      },
    }),
    ...otherProps,
  };
}
