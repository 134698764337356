import { useCallback, useEffect } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useAuth, useCallbackPrompt } from 'hooks';
import { QuickLinkAccess, QuickLinkFormValues } from 'types';
import { formatLink } from 'utils';

interface UseQuickLinkFormProps {
  mode: 'create' | 'edit';
  handleSubmit: (data: QuickLinkFormValues) => void;
  defaultValues: QuickLinkFormValues;
}

interface UseVisionFormReturn {
  control: Control<QuickLinkFormValues>;
  isOpen: boolean;
  isLoading: boolean;
  goBack: () => void;
  handleFormSubmit: () => void;
  handleSubmitConfirmModal: () => void;
  handleCloseConfirmModal: () => void;
  isDirty: boolean;
}

export function useQuickLinkForm({
  mode,
  handleSubmit: submitForm,
  defaultValues,
}: UseQuickLinkFormProps): UseVisionFormReturn {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isSubmitted, errors },
  } = useForm<QuickLinkFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { isManager } = useAuth();
  const navigate = useNavigate();

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isDirty && !isSubmitted,
  );

  const handleFormSubmit = handleSubmit(async (data) => {
    data.url = formatLink(data.url);
    data.access =
      isManager && (data.access === true || data.access === QuickLinkAccess.public)
        ? QuickLinkAccess.public
        : QuickLinkAccess.private;
    await submitForm(data);
  });

  async function handleSubmitConfirmModal() {
    confirmNavigation();
  }

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (isSubmitted && Object.keys(errors).length === 0) {
      goBack();
    }
  }, [isSubmitted, goBack, errors, mode]);

  return {
    control,
    handleFormSubmit,
    isOpen: showPrompt,
    handleSubmitConfirmModal,
    isLoading: isSubmitting,
    goBack,
    handleCloseConfirmModal: cancelNavigation,
    isDirty,
  };
}
