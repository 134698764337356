import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { DeleteAchievementRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UseDeleteAchievementMutationProps {
  invalidateQueries?: string[];
  onSuccessAction?: () => void;
}

interface UseDeleteAchievementMutationReturn {
  deleteAchievementMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    DeleteAchievementRequestParams,
    unknown
  >;
  isLoading: boolean;
}

export function useDeleteAchievementMutation({
  invalidateQueries,
  onSuccessAction,
}: UseDeleteAchievementMutationProps): UseDeleteAchievementMutationReturn {
  const { mutateAsync: deleteAchievementMutation, isLoading } = useMutation(
    API_HANDLERS.ACHIEVEMENTS.DELETE_ACHIEVEMENT,
    {
      onSuccess: () => {
        invalidateQueries && queryClient.invalidateQueries(invalidateQueries);
        onSuccessAction?.();
      },
    },
  );

  return {
    deleteAchievementMutation,
    isLoading,
  };
}
