import { styled } from '@mui/material';

export const ContentW = styled('div')`
  width: 100%;
  max-width: 1106px;

  @media (max-width: 1023px) {
    width: 95%;
    margin: 0 auto;
    padding: 0 10px;
  }
`;

export const TopMenuW = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: top;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;
