import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendarEmpty.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/googleLogo.svg';
import { ReactComponent as MicrosoftIcon } from 'assets/icons/signInMicrosoft.svg'; // button is SVG because in figma this button exist only like SVG
import { Spinner } from 'components';

import { ConnectButton } from '../ConnectButton';
import { ButtonsWrapper, ContainerW, GoogleButtonWrapper, GoogleSignInText, NoteW } from './styled';

interface EmptyStateProps {
  handleGoogleSignIn: () => void;
  handleMicrosoftSignIn: () => void;
  isLoading: boolean;
}

export function EmptyState({
  handleGoogleSignIn,
  handleMicrosoftSignIn,
  isLoading,
}: EmptyStateProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ContainerW>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <CalendarIcon />
          <NoteW>{t('calendar.emptyState.title')}</NoteW>
          <ButtonsWrapper>
            <GoogleButtonWrapper>
              <ConnectButton
                icon={<GoogleIcon />}
                text={<GoogleSignInText>Open with Google Calendar</GoogleSignInText>}
                onClick={handleGoogleSignIn}
              />
            </GoogleButtonWrapper>
            <MicrosoftIcon style={{ cursor: 'pointer' }} onClick={handleMicrosoftSignIn} />
          </ButtonsWrapper>
        </>
      )}
    </ContainerW>
  );
}
