import { getDay, parse, startOfWeek, format, formatRFC3339, subHours } from 'date-fns';
import * as Locales from 'date-fns/locale';
import { ReactElement, useMemo } from 'react';
import './calendar-custom.css';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';

import { CalendarEventsList } from 'types';

const locales = {
  'sv-SE': Locales.sv,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: locales,
});

interface CalendarProps {
  events: CalendarEventsList;
  selectedDate: string;
}

export function Calendar({ events, selectedDate }: CalendarProps): ReactElement {
  const { timeToScroll } = useMemo(() => {
    const formattedDate = formatRFC3339(new Date(selectedDate)).split('T')[0];
    const timeNow = formatRFC3339(new Date()).split('T')[1];
    const dateTime = formattedDate + 'T' + timeNow;

    const dateWithSubHours = subHours(new Date(dateTime), 2);

    return {
      timeToScroll: dateWithSubHours,
    };
  }, [selectedDate]);

  return (
    <BigCalendar
      culture="sv-SE"
      localizer={localizer}
      events={events}
      startAccessor="startTime"
      endAccessor="endTime"
      style={{ height: '320px' }}
      defaultView="day"
      date={selectedDate}
      onNavigate={() => {}}
      scrollToTime={timeToScroll}
    />
  );
}
