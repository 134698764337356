import { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommentsListQuery, useUsersListQuery } from 'api';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ConfirmDialog, GoBackButtonWithTitle, Spinner } from 'components';
import { AddCommentForm, CommentsList } from 'features/comments';
import { EditTaskForm } from 'features/tasks';
import { InstanceType } from 'types';

import { useTaskDetailsPage } from './hooks';
import { ButtonW, CommentsCountW, CommentsW, Count, FormW, PageHeaderW, PageW } from './styled';

export function TaskDetailsPage(): ReactElement {
  const { t } = useTranslation();

  const commentsSectionRef = useRef<HTMLDivElement>(null);

  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  const {
    task,
    isOpen,
    isLoading,
    canDelete,
    handleOpenModal,
    handleDeleteTask,
    handleCloseModal,
  } = useTaskDetailsPage();

  const {
    comments,
    isLoading: isLoadingComments,
    isFetchingNextPage,
    lastItemRef,
    total,
  } = useCommentsListQuery({
    limit: 20,
    postId: task?.id || '',
    type: InstanceType.tasks,
  });

  const commentsWording = total > 1 ? 'comments.total.plural' : 'comments.total.singular';

  const executeScroll = () =>
    commentsSectionRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });

  if (isLoading || isLoadingUsersList) {
    return <Spinner />;
  }

  return (
    <PageW>
      <PageHeaderW>
        <GoBackButtonWithTitle title={t('tasks.details.pageTitle')} />
        {total > 0 && (
          <CommentsCountW onClick={executeScroll}>
            <Count>
              {total} {t(commentsWording)}
            </Count>
          </CommentsCountW>
        )}
      </PageHeaderW>

      {task && usersList.length > 0 && (
        <FormW>
          <EditTaskForm
            task={task}
            usersList={usersList}
            canDelete={canDelete}
            handleOpenModal={handleOpenModal}
          />
          {canDelete && (
            <ButtonW onClick={handleOpenModal} endIcon={<TrashIcon />}>
              {t('tasks.details.removeButton')}
            </ButtonW>
          )}
        </FormW>
      )}

      {task && (
        <CommentsW ref={commentsSectionRef}>
          <AddCommentForm postId={task.id} />
          <CommentsList
            postId={task.id}
            comments={comments}
            isLoading={isLoadingComments}
            isFetchingNextPage={isFetchingNextPage}
            lastItemRef={lastItemRef}
          />
        </CommentsW>
      )}

      <ConfirmDialog
        title={t('tasks.details.removeModal.title', { taskName: task?.title })}
        subTitle={t('tasks.details.removeModal.subtitle')}
        open={isOpen}
        onSubmit={handleDeleteTask}
        onClose={handleCloseModal}
      />
    </PageW>
  );
}
