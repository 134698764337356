import { styled, Dialog as DialogMUI, Button, Typography } from '@mui/material';

export const Dialog = styled(DialogMUI)(({ theme }) => ({
  '.MuiDialog-paper': {
    width: '746px',
    padding: '24px 48px 40px 24px',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(20px)',
    borderRadius: '15px',
  },

  '.MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
  },

  [theme.breakpoints.down('desktop')]: {
    '.MuiDialog-paper': {
      padding: '16px',
      margin: '32px 8px auto',
      maxHeight: 'unset',
      height: 'fit-content',
    },
  },
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  textTransform: 'none',
  fontSize: '24px',
  lineHeight: '24px',
  color: theme.palette.primary.p080,
  marginBottom: '48px',

  '.MuiButton-startIcon': {
    fontSize: '2rem',
    marginRight: '24px',
  },

  '.MuiSvgIcon-root': {
    width: '1.5em',
    height: '1.5em',
  },
  [theme.breakpoints.down('desktop')]: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 500,
    marginBottom: '16px',
  },
}));

export const TotalCountW = styled(Typography)`
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-top: 24px;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px;
  }
`;
