import { IconButton, styled, Typography } from '@mui/material';

export const FromContainer = styled('div')`
  width: 671px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 36px 25px;
  margin-right: 36px;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
    margin-bottom: 110px;
    margin-right: 0px;
  }
`;

export const RichEditorW = styled('div')`
  @media (max-width: 1023px) {
    margin-bottom: 8px;
  }
`;

export const CloseButtonW = styled(IconButton)`
  margin-left: auto;
  position: fixed;
  top: 79px;
  right: 88px;
`;

export const FormAndPreviewW = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const PreviewTitle = styled(Typography)`
  font-size: 14px;
  line-height: 10px;
  color: ${({ theme }) => theme.palette.primary.p030};
  position: absolute;
  top: -32px;
`;
