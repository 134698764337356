import { styled } from '@mui/material';

export const EmployeesListW = styled('div')`
  display: flex;
  overflow: hidden;
  column-gap: 24px;
  @media (max-width: 1023px) {
    column-gap: 16px;
    height: 84px;
    flex-wrap: wrap;
  }
`;
