import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useAuth, useSubscription } from 'hooks';
import { Links } from 'settings';

import { ACTIONS_CONFIG } from './config';
import { ActionItem, ActionsList, ButtonW, ContainerTitle, ContentW, TooltipW } from './styled';

export function ActionOptionsList(): ReactElement {
  const { t } = useTranslation();
  const { isManager } = useAuth();
  const { isTrial } = useSubscription();

  return (
    <ContentW>
      {!isManager && <ContainerTitle>{t('actions.employee.addA')}</ContainerTitle>}

      <ActionsList>
        {Object.values(ACTIONS_CONFIG(isManager)).map(({ icon, label, link }) => {
          const isBlocked =
            isTrial &&
            (link === Links.actions.employeesInvite || link === Links.actions.offboarding);

          return (
            <ButtonW key={label} color="info">
              <NavLink
                to={isBlocked ? '' : link}
                key={label}
                style={{ textDecoration: 'none', width: '100%' }}
              >
                {isTrial ? (
                  <TooltipW
                    title={t(
                      link === Links.actions.employeesInvite
                        ? 'blockedOnboardingHint'
                        : link === Links.actions.offboarding
                        ? 'blockedOffboardingHint'
                        : '',
                    )}
                  >
                    <ActionItem isBlocked={isBlocked}>
                      {icon}
                      {label}
                    </ActionItem>
                  </TooltipW>
                ) : (
                  <ActionItem isBlocked={isBlocked}>
                    {icon}
                    {label}
                  </ActionItem>
                )}
              </NavLink>
            </ButtonW>
          );
        })}
      </ActionsList>
    </ContentW>
  );
}
