export const submitBtnStyles = {
  position: 'absolute',
  right: 0,
  marginLeft: '31px',
  top: '70px',
};

export const submitBtnMobileStyles = {
  position: 'absolute',
  right: 0,
  top: '106px',
};
