export function getCookie(cookieName: string): string | undefined {
  const cookie: Record<string, string> = {};

  document.cookie.split(';').forEach(function (el) {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });

  return cookie[cookieName];
}
