import { SxProps, Theme } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageGallery, VideoAttachment, VideoGallery } from 'components';
import { useModal, useWindowSize } from 'hooks';
import { Attachment } from 'types';

import { initialWindowSizeProps } from '../constants';
import { DocumentAttachment } from '../DocumentAttachment';
import { ImageAttachment } from '../ImageAttachment';
import { EquipmentAttachmentContainer, ShowLess, ShowMore } from './styled';

interface EquipmentAttachmentsProps {
  isExpanded: boolean;
  setIsExpanded: () => void;
  attachments: Attachment[];
  attachmentOrder?: string[];
}

export function EquipmentAttachments({
  isExpanded,
  setIsExpanded,
  attachments: attachmentsList,
  attachmentOrder = [],
}: EquipmentAttachmentsProps) {
  const { isOpen, handleOpenModal, handleCloseModal } = useModal();
  const {
    isOpen: isVideoOpen,
    handleOpenModal: handleVideoOpenModal,
    handleCloseModal: handleVideoCloseModal,
  } = useModal();
  const { isMobile } = useWindowSize(initialWindowSizeProps);

  const { t } = useTranslation();

  function handleShowMore(e: SyntheticEvent) {
    e.stopPropagation();
    setIsExpanded();
  }

  function handleShowLess(e: SyntheticEvent) {
    e.stopPropagation();
    setIsExpanded();
  }

  if (!attachmentsList) {
    return null;
  }

  const attachments = attachmentsList.sort(
    (a, b) =>
      a.type.localeCompare(b.type) || attachmentOrder.indexOf(a.id) - attachmentOrder.indexOf(b.id),
  );

  const imagesList = attachments?.filter((attachment) => attachment.type === 'image') || [];
  const videoList = attachments?.filter((attachment) => attachment.type === 'video') || [];
  const showMore = attachments.length > 2;

  const desktopStyle = showMore ? null : ({ width: '100px', objectFit: 'cover' } as SxProps<Theme>);
  const mobileStyle = showMore ? null : ({ width: '100%' } as SxProps<Theme>);
  return (
    <>
      {isOpen && <ImageGallery open={isOpen} onClose={handleCloseModal} imageList={imagesList} />}
      {isVideoOpen && (
        <VideoGallery open={isVideoOpen} onClose={handleVideoCloseModal} videoList={videoList} />
      )}
      {showMore && !isExpanded ? (
        <div style={{ position: 'relative' }}>
          <ShowMore onClick={handleShowMore}>{t('equipments.readMoreButton')}</ShowMore>
          {attachments[0].type === 'image' ? (
            <ImageAttachment
              attachmentId={attachments[0].id}
              fileName={attachments[0].file.medium}
              fileUrl={attachments[0].file_url.medium}
            />
          ) : attachments[0].type === 'video' ? (
            <VideoAttachment
              attachmentId={attachments[0].file.video_uid}
              wrapperSx={{ height: '58px', width: '100%' }}
            />
          ) : (
            <DocumentAttachment
              documentId={attachments[0].id}
              fileName={attachments[0].file.original}
              fileUrl={attachments[0].file_url.original}
            />
          )}
        </div>
      ) : (
        <EquipmentAttachmentContainer showMore={showMore}>
          {attachments.map((attachment) =>
            attachment.type === 'image' ? (
              <ImageAttachment
                wrapperSx={isMobile ? mobileStyle : desktopStyle}
                attachmentId={attachment.id}
                fileName={attachment.file.medium}
                fileUrl={attachment.file_url.medium}
                onOpenGallery={handleOpenModal}
              />
            ) : attachment.type === 'video' ? (
              <VideoAttachment
                onOpenGallery={handleVideoOpenModal}
                attachmentId={attachment.file.video_uid}
                wrapperSx={{ height: '58px', width: '100%' }}
              />
            ) : (
              <DocumentAttachment
                documentId={attachment.id}
                fileName={attachment.file.original}
                fileUrl={attachment.file_url.original}
                wrapperSx={isMobile ? mobileStyle : desktopStyle}
              />
            ),
          )}
          {isExpanded && (
            <ShowLess onClick={handleShowLess}>{t('equipments.readLessButton')}</ShowLess>
          )}
        </EquipmentAttachmentContainer>
      )}
    </>
  );
}
