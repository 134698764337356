import { styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const EmptyStateContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: calc(100% - 100px);
  margin-top: 60px;

  @media (max-width: 520px) {
    height: auto;
    margin-top: 0;
  }
`;

export const EmptyStateField = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyStateImage = styled('img')`
  margin-top: 10px;

  @media (max-width: 520px) {
    margin-top: 0;
  }
`;

export const EmptyStateTitle = styled(Typography)`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.p040};
  white-space: break-spaces;
  margin-top: 24px;
`;

export const NavLinkW = styled(NavLink)`
  color: ${({ theme }) => theme.palette.info.main};
`;
