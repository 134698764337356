import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

export const TabsW = styled(MuiTabs)`
  margin-bottom: 24px;
  border-bottom: 1px solid #d1d5db;
`;

export const TabW = styled(MuiTab)`
  padding: 16px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.secondary.light};
  text-transform: none;
`;
