import { styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState.svg';

export const EmptyStateW = styled('div')`
  width: 100%;
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;

  @media (max-width: 1023px) {
    height: 182px;
    margin-top: 16px;
    padding: 24px;
  }
`;

export const NavLinkW = styled(NavLink)`
  color: ${({ theme }) => theme.palette.info.main};
`;

export const EmptyStateIconW = styled(EmptyStateIcon)`
  opacity: 0.4;
`;

export const Description = styled(Typography)`
  max-width: 260px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
