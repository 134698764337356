import { Button, styled, Typography } from '@mui/material';

export const ContentW = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 24px;
  margin-top: 32px;

  @media (max-width: 1023px) {
    margin-top: 16px;
    padding: 16px 0;
    max-height: 518px;
  }
`;

export const TitleW = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
    padding: 0 8px;
  }
`;

export const ListW = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 16px;
  column-gap: 14px;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (max-width: 1240px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const MobileListW = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 16px;
  column-gap: 14px;
  padding: 0 8px;

  @media (max-width: 1240px) {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 520px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Filler = styled('div')`
  flex-grow: auto;
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  border-radius: 10px;
  width: 100%;
  margin-top: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const MobileW = styled('div')`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`;
