import { useEffect, useCallback, ChangeEvent } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { TaskFormPayload } from 'api';
import { useCallbackPrompt, useFormAttachments } from 'hooks';
import { Attachment, SelectOption, TaskFormValues, User } from 'types';
import { objectsDifferences, getFullName } from 'utils';

import { formValuesAdapter } from '../adapters';
import { AllOption } from '../constants';

interface UseTaskFormProps {
  draftId: string;
  mode: 'create' | 'edit';
  handleSubmit: (data: TaskFormPayload, postId: string) => void;
  defaultValues: TaskFormValues;
  usersList: User[];
  shouldReset?: boolean;
  setShouldReset?: (value: boolean) => void;
}

interface UseTaskFormReturn {
  control: Control<TaskFormValues>;
  isOpen: boolean;
  isLoading: boolean;
  attachedDocs: Attachment[];
  attachedImages: Attachment[];
  attachedVideos: Attachment[];
  usersOptions: SelectOption[];
  isDirty: boolean;
  progress: number;
  goBack: () => void;
  clearDeadline: () => void;
  handleFormSubmit: () => void;
  handleAddImages: (files: FileList) => Promise<void>;
  handleAddDocuments: (files: FileList) => Promise<void>;
  handleAddVideos: (files: FileList) => Promise<void>;
  handleDeleteAttachment: (attachmentId: string, type: 'images' | 'docs' | 'videos') => void;
  handleSubmitConfirmModal: () => void;
  handleCloseConfirmModal: () => void;
  handleAssigneeChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function useTaskForm({
  draftId,
  handleSubmit: submitForm,
  defaultValues,
  usersList,
  mode,
  shouldReset,
  setShouldReset,
}: UseTaskFormProps): UseTaskFormReturn {
  const usersOptions = usersList.map((user) => ({
    value: user.id,
    label:
      user.firstName && user.firstName
        ? getFullName({ firstName: user.firstName, lastName: user.lastName })
        : user.email,
  }));

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, isDirty, isSubmitted, errors },
    reset,
  } = useForm<TaskFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();

  const attachedImages = watch('images');
  const attachedDocs = watch('docs');
  const attachedVideos = watch('videos');

  const {
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    isLoadingFileUpload,
    progress,
  } = useFormAttachments({
    setFormValue: setValue,
    attachedDocs,
    attachedImages,
    attachedVideos,
  });

  const filesChanges = objectsDifferences(
    { images: attachedImages, docs: attachedDocs, videos: attachedVideos },
    {
      images: defaultValues.images,
      docs: defaultValues.docs,
      videos: defaultValues.videos,
    },
  );

  const formHasChanges = isDirty || Object.keys(filesChanges).length > 0;

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    formHasChanges && !isSubmitted,
  );

  const handleFormSubmit = handleSubmit(async ({ assignee, ...data }) => {
    let editInput = data;
    if (data.deadline && typeof data.deadline !== 'string') {
      const deadline = data.deadline as unknown as Date;
      const utcDeadline = new Date(
        Date.UTC(deadline?.getFullYear(), deadline?.getMonth(), deadline?.getDate()),
      );
      editInput = {
        ...data,
        deadline: utcDeadline.toISOString(),
      };
    }

    const attachment_ids = [...attachedImages, ...attachedDocs, ...attachedVideos].map(
      (file) => file.id,
    );
    const assignedTo =
      assignee?.length === 1 && assignee.includes(AllOption.value)
        ? usersList.map(({ id }) => id)
        : assignee;

    const formValues = formValuesAdapter({ ...editInput, attachment_ids, assignee: assignedTo });
    await submitForm(formValues, draftId);
    reset({ assignee, ...data });
  });

  async function handleSubmitConfirmModal() {
    confirmNavigation();
  }

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  function clearDeadline() {
    setValue('deadline', null, { shouldDirty: true });
  }

  const handleAssigneeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;

      if (!!value && value.length > 1) {
        if (value?.at(-1) === AllOption.value) {
          setValue('assignee', [AllOption.value]);
        } else if (value.includes(AllOption.value)) {
          setValue(
            'assignee',
            (value as unknown as string[]).filter((value) => value !== AllOption.value),
          );
        }
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (shouldReset) {
      reset(defaultValues);
      setShouldReset?.(false);
    }
  }, [defaultValues, reset, shouldReset, setShouldReset]);

  useEffect(() => {
    if (mode === 'create' && isSubmitted && Object.keys(errors).length === 0) {
      goBack();
    }
  }, [isSubmitted, goBack, errors, mode]);

  return {
    control,
    handleFormSubmit,
    isOpen: showPrompt,
    handleSubmitConfirmModal,
    attachedDocs,
    attachedImages,
    attachedVideos,
    usersOptions,
    progress,
    isLoading: isSubmitting || isLoadingFileUpload,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    clearDeadline,
    goBack,
    handleCloseConfirmModal: cancelNavigation,
    isDirty: formHasChanges,
    handleAssigneeChange,
  };
}
