import { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  PostListItem,
  useCategoriesListQuery,
  usePublishPostMutation,
  useUsersListQuery,
} from 'api';
import { useAuth, useCallbackPrompt, useFormAttachments } from 'hooks';
import {
  Attachment,
  CategoriesListType,
  CreatePostFormValues,
  PostDraft,
  SelectOption,
} from 'types';
import { getFullName, objectsDifferences } from 'utils';

import { defaultValuesAdapter, formValuesAdapter } from '../adapters';
import { AllOption } from '../constants';

interface UseCreatePostFormReturn {
  control: Control<CreatePostFormValues>;
  isLoading: boolean;
  attachedImages: Attachment[];
  attachedDocs: Attachment[];
  attachedVideos: Attachment[];
  attachments?: Attachment[];
  isOpen: boolean;
  postTitle: string;
  postDescription: string;
  author: PostListItem['author'] | null;
  usersOptions: SelectOption[];
  categoryMap: CategoriesListType;
  progress: number;
  handleAssigneeChange: (e: ChangeEvent<HTMLInputElement>) => void;
  goBack: () => void;
  handleFormSubmit: () => void;
  handleAddImages: (files: FileList) => Promise<void>;
  handleAddDocuments: (files: FileList) => Promise<void>;
  handleAddVideos: (files: FileList) => Promise<void>;
  handleCloseConfirmModal: () => void;
  handleSubmitConfirmModal: () => void;
  handleDeleteAttachment: (attachmentId: string, type: 'images' | 'docs' | 'videos') => void;
}

interface UseCreatePostFormProps {
  draft: PostDraft;
}

export function useCreatePostForm({ draft }: UseCreatePostFormProps): UseCreatePostFormReturn {
  const defaultValues = defaultValuesAdapter({ draft });
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, isDirty, isSubmitted, errors },
  } = useForm<CreatePostFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const attachedImages = watch('images');
  const attachedDocs = watch('docs');
  const attachedVideos = watch('videos');

  const postTitle = watch('title');
  const postDescription = watch('description');
  const filesChanges = objectsDifferences(
    { images: attachedImages, docs: attachedDocs, videos: attachedVideos },
    {
      images: defaultValues.images,
      docs: defaultValues.docs,
      videos: defaultValues.videos,
    },
  );
  const {
    categoryMap,
    isLoading: isCategoryListLoading,
  }: { categoryMap: CategoriesListType; isLoading: boolean } = useCategoriesListQuery();
  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  const usersOptions = usersList.map((user) => ({
    value: user.id,
    label:
      user.firstName && user.firstName
        ? getFullName({ firstName: user.firstName, lastName: user.lastName })
        : user.email,
  }));

  const navigate = useNavigate();

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    (isDirty || Object.keys(filesChanges).length > 0) && !isSubmitted,
  );

  const { publishPostMutation, isLoading: isPublishPostLoading } = usePublishPostMutation({
    successText: t('toasts.post.successCreate'),
    setIsSuccess,
  });

  const { user } = useAuth();

  const attachments =
    [...attachedDocs, ...attachedImages, ...attachedVideos].length > 0
      ? [...attachedDocs, ...attachedImages, ...attachedVideos]
      : undefined;

  const {
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    isLoadingFileUpload,
    progress,
  } = useFormAttachments({
    setFormValue: setValue,
    attachedDocs,
    attachedImages,
    attachedVideos,
  });

  const handleAssigneeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;

      if (!!value && value.length > 1) {
        if (value?.at(-1) === AllOption.value) {
          setValue('visibility', [AllOption.value]);
        } else if (value.includes(AllOption.value)) {
          setValue(
            'visibility',
            (value as unknown as string[]).filter((value) => value !== AllOption.value),
          );
        }
      }
    },
    [setValue],
  );

  const handleFormSubmit = handleSubmit(async ({ visibility, ...data }) => {
    const attachment_ids = [...attachedImages, ...attachedDocs, ...attachedVideos].map(
      (file) => file.id,
    );
    const visibility_ids =
      visibility?.length === 1 && visibility.includes(AllOption.value) ? undefined : visibility;
    const { title, description } = data;

    await publishPostMutation({
      post: formValuesAdapter({
        title,
        description,
        attachment_ids,
        visibility: visibility_ids,
      }),
      postId: draft.id,
    });
  });

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  async function handleSubmitConfirmModal() {
    confirmNavigation();
  }

  useEffect(() => {
    if (isSubmitted && Object.keys(errors).length === 0 && isSuccess) {
      goBack();
    }
  }, [isSubmitted, goBack, errors, isSuccess]);

  return {
    control,
    isLoading:
      isSubmitting ||
      isPublishPostLoading ||
      isLoadingFileUpload ||
      isLoadingUsersList ||
      isCategoryListLoading,
    attachments,
    attachedImages,
    attachedDocs,
    attachedVideos,
    isOpen: showPrompt,
    postTitle,
    postDescription,
    author: user,
    usersOptions,
    categoryMap,
    progress,
    handleAssigneeChange,
    goBack,
    handleAddImages,
    handleAddVideos,
    handleFormSubmit,
    handleAddDocuments,
    handleDeleteAttachment,
    handleSubmitConfirmModal,
    handleCloseConfirmModal: cancelNavigation,
  };
}
