import i18n from 'i18n';

export const getTabs = (activeIndex: number) => {
  switch (activeIndex) {
    case 0:
    case 1: {
      return [
        {
          label: i18n.t('tasks.statuses.toDo'),
          slideIndex: 0,
        },
        {
          label: i18n.t('tasks.statuses.inProgress'),
          slideIndex: 1,
        },
        {
          label: i18n.t('tasks.statuses.toApprove'),
          slideIndex: 2,
        },
      ];
    }
    case 2:
    case 3: {
      return [
        {
          label: i18n.t('tasks.statuses.inProgress'),
          slideIndex: 1,
        },
        {
          label: i18n.t('tasks.statuses.toApprove'),
          slideIndex: 2,
        },
        {
          label: i18n.t('tasks.statuses.done'),
          slideIndex: 3,
        },
      ];
    }
    default: {
      return [
        {
          label: i18n.t('tasks.statuses.toDo'),
          slideIndex: 0,
        },
        {
          label: i18n.t('tasks.statuses.inProgress'),
          slideIndex: 1,
        },
        {
          label: i18n.t('tasks.statuses.toApprove'),
          slideIndex: 2,
        },
      ];
    }
  }
};
