import { ReactElement, ReactNode } from 'react';

import { ButtonMUI } from './styled';

interface ConnectButtonProps {
  icon: ReactNode;
  text: ReactNode | string;
  onClick: () => void;
}

export function ConnectButton({ icon, text, onClick }: ConnectButtonProps): ReactElement {
  return (
    <ButtonMUI onClick={onClick}>
      {icon}
      <div>{text}</div>
    </ButtonMUI>
  );
}
