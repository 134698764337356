import { styled, Typography } from '@mui/material';

import { theme } from 'styles';

export const CardW = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);

  border-radius: 15px;
  padding: 24px 24px 16px;
  display: flex;
`;

export const MainInfo = styled('div')`
  flex: 1;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    margin-right: 0px;
  }
`;

export const Title = styled('div')`
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 1023px) {
    margin-right: 65px;
    display: flex;
    align-items: center;
  }
`;

type DescriptionProps = {
  isExpandedCard: boolean;
  isMobile: boolean;
};

export const Description = styled('div')<DescriptionProps>(
  ({ isExpandedCard, isMobile, theme }) => ({
    fontWeight: 500,
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.8)',
    marginTop: '12px',
    marginBottom: '12px',
    textOverflow: 'ellipsis',
    maxHeight: isExpandedCard || isMobile ? 'none' : '98px',
    [theme.breakpoints.down('desktop')]: {
      marginBottom: '0px',
      marginTop: '16px',
    },

    '& h1, h2, h3, h4, h5': {
      marginTop: 0,
      fontSize: 'inherit',
    },
  }),
);

export const StyledDate = styled('div')`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: auto;
`;

export const Tag = styled('div')`
  padding: 3px 12px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-right: 8px;
  width: fit-content;
`;

export const ReadMore = styled('span')`
  cursor: pointer;
  color: ${theme.palette.info.main};
`;

export const TagsList = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

export const TagW = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const TagsCounter = styled(Typography)`
  background: #dfe1e6;
  color: ${({ theme }) => theme.palette.primary.p080};
  border-radius: 10px;
  margin-left: 6px;
  padding: 3px 6px;
  font-weight: 400;
  font-size: 12px;
`;

export const TitleAndActionsW = styled('div')`
  display: flex;
  justify-content: space-between;
`;
