import { RegisterOptions } from 'react-hook-form';

import { emailRules, getMaxLengthText, getMinLengthText, required } from 'validations';

export const emailFieldRules: RegisterOptions = {
  ...required,
  ...emailRules,
  minLength: {
    value: 5,
    message: getMinLengthText(5),
  },
  maxLength: {
    value: 254,
    message: getMaxLengthText(254),
  },
};
