export const selectSx = {
  '.MuiSelect-select.MuiSelect-select': {
    paddingLeft: '24px',
  },
};

export const selectMobileSx = {
  minHeight: '60px',

  '.MuiSelect-select.MuiSelect-select': {
    padding: '10px 16px',
    '&.Mui-disabled': {
      height: '100%',
    },
  },
};

export const inputTypeSx = {
  '& .MuiInputBase-input': {
    paddingLeft: '24px',
  },
};

export const optionSx = {
  display: 'inline-block',
  padding: '5px',
  width: '58px',
  height: '58px',
  borderRadius: '15px',
};

export const dropdownContainerSx = (isMobile: boolean) => ({
  maxWidth: '600px',
  paddingLeft: '16px',
  '.MuiList-root': {
    maxHeight: '320px',
    paddingLeft: isMobile ? '8px' : '12px',
  },
});
