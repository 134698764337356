import { AvatarSize } from '..';

export function getAvatarSize(size?: AvatarSize) {
  switch (size) {
    case 'xs':
      return { width: '24px', height: '24px' };
    case 'notification':
      return { width: '32px', height: '32px' };
    case 's':
      return { width: '36px', height: '36px' };
    case 'mobTable':
      return { width: '48px', height: '48px' };

    case 'm':
      return { width: '60px', height: '60px' };
    case 'l':
      return { width: '72px', height: '72px' };
    case 'lg':
      return { width: '96px', height: '96px' };
    case 'xl':
      return { width: '128px', height: '128px' };
    case 'xxl':
      return { width: '200px', height: '200px' };
    case 'xxxl':
      return { width: '235px', height: '235px' };
    default:
      return;
  }
}
