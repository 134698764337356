import { IconButton } from '@mui/material';
import { sv } from 'date-fns/locale';
import { SyntheticEvent } from 'react';

import { ReactComponent as TrashIcon } from 'assets/icons/containerActions/trash.svg';
import { ReactComponent as RequestIcon } from 'assets/icons/request.svg';
import { DEFAULT_DATE_FORMAT } from 'constants/dateFormats';
import { Equipment, EquipmentsTableFields } from 'types/equipments';
import { formattedDate } from 'utils/dateHelpers';

import { categoryOptions } from '../../constants';
import { EquipmentAttachments } from '../../EquipmentAttachements';
import { TitleName, TitleCategory, iconButtonStyle, ActionsWrap } from './styled';

interface RegisteredEquipmentAdapterProps {
  isManager: boolean;
  currentUserId?: string;
  data: Equipment[];
  isSwedish: boolean;
  onConfirmIconClick: ({ id, userId }: Pick<Equipment, 'id' | 'userId'>) => Promise<void>;
  onTrashIconClick: ({ id, name }: Pick<Equipment, 'id' | 'name'>) => void;
  getIsExpanded: (id: string) => boolean;
  setIsExpanded: (id: string) => void;
}

export function requestedEquipmentAdapter({
  isManager,
  currentUserId,
  data,
  isSwedish,
  onConfirmIconClick,
  onTrashIconClick,
  getIsExpanded,
  setIsExpanded,
}: RegisteredEquipmentAdapterProps): EquipmentsTableFields[] {
  if (!data || data.length === 0) {
    return [];
  }

  const locales = isSwedish ? sv : undefined;

  const categorySubTitle = Object.fromEntries(
    categoryOptions.map(({ value, label }) => [value, label]),
  );

  return data.map(
    ({
      id,
      name,
      category,
      userId,
      assignedToId = '',
      expireAt,
      orderedAt,
      attachments,
      attachmentOrder,
      serial,
      isEditable,
      authorId,
    }) => {
      const isCurrentAuthor = currentUserId === authorId;
      const isCurrentAssignee = currentUserId === userId;
      const isEditableByManager =
        isManager &&
        ((isCurrentAuthor && isCurrentAssignee && isEditable) ||
          (isCurrentAuthor && !isCurrentAssignee) ||
          (!isCurrentAuthor && !isCurrentAssignee));
      const isExpanded = getIsExpanded(id);
      const isConfirmDisabled = !isCurrentAssignee && isManager && currentUserId !== assignedToId;

      const handleConfirmIconClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        onConfirmIconClick({ userId, id });
      };

      const handleTrashIconClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        onTrashIconClick({ id, name });
      };

      const setExpanded = () => setIsExpanded(id);

      return {
        name: (
          <>
            <TitleName>{name}</TitleName>
            <TitleCategory>{categorySubTitle[category]}</TitleCategory>
          </>
        ),
        expiryDate: expireAt ? formattedDate(new Date(expireAt), DEFAULT_DATE_FORMAT, locales) : '',
        orderedOn: orderedAt
          ? formattedDate(new Date(orderedAt), DEFAULT_DATE_FORMAT, locales)
          : '',
        attachments: (
          <EquipmentAttachments
            isExpanded={isExpanded}
            setIsExpanded={setExpanded}
            attachments={attachments}
            attachmentOrder={attachmentOrder}
          />
        ),
        serial,
        actions: (
          <ActionsWrap isEditable={!isEditable && !isEditableByManager}>
            {isConfirmDisabled ? (
              <></>
            ) : (
              <IconButton sx={iconButtonStyle} onClick={handleConfirmIconClick}>
                <RequestIcon style={{ opacity: '0.8' }} />
              </IconButton>
            )}

            {(isEditable || isEditableByManager) && (
              <IconButton sx={iconButtonStyle} onClick={handleTrashIconClick}>
                <TrashIcon style={{ opacity: '0.6' }} />
              </IconButton>
            )}
          </ActionsWrap>
        ),
        id,
        isExpanded,
      };
    },
  );
}
