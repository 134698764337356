import { CalendarEvent, CalendarEventsList } from 'types';

export function groupEvents(events: CalendarEventsList) {
  return events.reduce<Record<string, CalendarEvent[]>>((eventsGroups, event) => {
    const date = event.start.split('T')[0];

    if (!eventsGroups[date]) {
      eventsGroups[date] = [];
    }

    eventsGroups[date].push(event);
    return eventsGroups;
  }, {});
}
