import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';
import { requestForToken } from 'api/firebase';
import { useActivateToken } from 'api/queries/useActivateToken';
import { LoginRequestParams, LoginResponse } from 'api/types';
import { useAuth, useSubscription } from 'hooks';

interface UseLoginMutationReturn {
  loginMutation: UseMutateAsyncFunction<
    AxiosResponse<LoginResponse, unknown>,
    unknown,
    LoginRequestParams,
    unknown
  >;
  isLoading: boolean;
}

export function useLoginMutation(): UseLoginMutationReturn {
  const { saveUser } = useAuth();
  const { addNotification, addMockDataBanner } = useSubscription();
  const { refetch } = useActivateToken();

  const { mutateAsync: loginMutation, isLoading } = useMutation(API_HANDLERS.AUTH.LOGIN, {
    onSuccess: async (data) => {
      const { token, user } = data.data;

      localStorage.setItem('accessToken', token.access_token);
      localStorage.setItem('refreshToken', token.refresh_token);
      localStorage.setItem('expiresAt', String(token.expires_at));

      saveUser({ user });
      addNotification();
      addMockDataBanner();
      if (!localStorage.getItem('webPushToken')) {
        const token = await requestForToken();
        localStorage.setItem('webPushToken', token as string);
        refetch();
      }
    },
  });

  return {
    loginMutation,
    isLoading,
  };
}
