import parse from 'html-react-parser';
import { ReactElement } from 'react';

import { ProgressBar } from '../ProgressBar';
import { CardW, ProgressW, Title, Value, DescriptionW } from './styled';

interface AchievementCardProps {
  title: string;
  color: string;
  progress: number;
  description?: string;
  onClick: () => void;
}

export function AchievementCard({
  title,
  progress,
  color,
  description = '',
  onClick,
}: AchievementCardProps): ReactElement {
  const percent = `${progress}%`;

  return (
    <CardW onClick={onClick}>
      <Title>{title}</Title>
      <ProgressW>
        <ProgressBar color={color} value={progress} />
        <Value>{percent}</Value>
      </ProgressW>
      {!!description && <DescriptionW>{parse(description)}</DescriptionW>}
    </CardW>
  );
}
