import { ReactNode } from 'react';

import { useSubscription } from 'hooks';
import i18n from 'i18n';
import { UserExtendedDetails, UserStatus } from 'types';
import { formattedDate, getFullName, toCapitalize } from 'utils';

import { ActivationStatusValueW, StatusButton, StatusText } from './styled';

interface ExtendedDetailsAdapterReturn {
  publicInfo: Record<string, { label: string; value: string }>;
  activationStatus: Record<string, { label: string; value: ReactNode }>;
  privateInformation: Record<string, { label: string; value: string }>;
  emergencyContact: Record<string, { label: string; value: string }>;
}

interface ExtendedDetailsAdapterTypes {
  user: UserExtendedDetails;
  onHandleOpenChangeStatusModal: () => void;
  isManager: boolean;
  isCurrentUser: boolean;
}

export function ExtendedDetailsAdapter({
  user,
  onHandleOpenChangeStatusModal,
  isManager,
  isCurrentUser,
}: ExtendedDetailsAdapterTypes): ExtendedDetailsAdapterReturn {
  const {
    firstName,
    lastName,
    email,
    countryCode,
    phone,
    birthDate,
    department,
    position,
    address1,
    address2,
    city,
    region,
    postcode,
    country,
    status,
    ssn,
    statusComment,
    employedAt,
    employmentNumber,
    education,
    clothesSize,
    allergies,
    comments,
    emergency,
  } = user;

  const { isTrial } = useSubscription();

  const publicInfo = {
    name: {
      label: i18n.t('profile.extended.name'),
      value: getFullName({ firstName, lastName }),
    },
    email: { label: i18n.t('profile.edit.form.publicInfo.placeholders.email'), value: email },
    phone: {
      label: i18n.t('profile.edit.form.publicInfo.placeholders.phone'),
      value: countryCode && phone ? `+${countryCode} ${phone}` : '',
    },
    birthDate: {
      label: i18n.t('profile.edit.form.publicInfo.placeholders.birthDate'),
      value: birthDate ? formattedDate(new Date(birthDate)) : '',
    },

    department: {
      label: i18n.t('profile.edit.form.publicInfo.placeholders.companyDep'),
      value: department ? toCapitalize(department) : '',
    },
    position: {
      label: i18n.t('profile.edit.form.publicInfo.placeholders.companyPos'),
      value: position ? toCapitalize(position) : '',
    },
    address1: {
      label: i18n.t('profile.edit.form.publicInfo.placeholders.address1'),
      value: address1,
    },
    address2: {
      label: i18n.t('profile.edit.form.publicInfo.placeholders.address2'),
      value: address2,
    },
    city: { label: i18n.t('profile.edit.form.publicInfo.placeholders.city'), value: city },
    region: { label: i18n.t('profile.edit.form.publicInfo.placeholders.state'), value: region },
    postCode: {
      label: i18n.t('profile.edit.form.publicInfo.placeholders.postcode'),
      value: postcode,
    },
    country: { label: i18n.t('profile.edit.form.publicInfo.placeholders.country'), value: country },
  };

  const activateButtonText = i18n.t(
    status === UserStatus.Inactive
      ? 'profile.extended.activationStatus.activate'
      : 'profile.extended.activationStatus.offboard',
  );

  const activationStatus = {
    status: {
      label: i18n.t('profile.extended.activationStatus.title'),
      value: (
        <ActivationStatusValueW>
          <StatusText className={status === UserStatus.Inactive ? 'warning' : 'info'}>
            {toCapitalize(status)}
          </StatusText>
          {isManager && !isCurrentUser && !isTrial && (
            <StatusButton
              variant="contained"
              sx={{
                maxWidth: 'none',
                marginTop: '6px',
              }}
              onClick={() => {
                onHandleOpenChangeStatusModal();
              }}
            >
              {activateButtonText}
            </StatusButton>
          )}
        </ActivationStatusValueW>
      ),
    },
    comment: { label: i18n.t('profile.extended.activationStatus.comment'), value: statusComment },
  };

  const privateInformation = {
    ssn: { label: i18n.t('profile.edit.form.privateInfo.placeholders.ssn'), value: ssn },
    employmentDate: {
      label: i18n.t('profile.edit.form.privateInfo.placeholders.employmentDate'),
      value: employedAt ? formattedDate(new Date(employedAt)) : '',
    },
    employmentNumber: {
      label: i18n.t('profile.edit.form.privateInfo.placeholders.employmentNumber'),
      value: employmentNumber,
    },
    education: {
      label: i18n.t('profile.edit.form.privateInfo.placeholders.education'),
      value: education,
    },
    sizeOfClothes: {
      label: i18n.t('profile.edit.form.privateInfo.placeholders.sizeOfClothes'),
      value: clothesSize ? clothesSize.toUpperCase() : '',
    },
    allergies: {
      label: i18n.t('profile.edit.form.privateInfo.placeholders.allergies'),
      value: allergies ? toCapitalize(allergies) : '',
    },
    comment: {
      label: i18n.t('profile.edit.form.privateInfo.placeholders.comment'),
      value: comments,
    },
  };

  const emergencyCountryCode = emergency?.countryCode ? `+${emergency.countryCode} ` : '';
  const emergencyContact = {
    relation: {
      label: i18n.t('profile.edit.form.emergency.placeholders.relation'),
      value: emergency?.relation
        ? toCapitalize(i18n.t(`employee.contact.${emergency.relation}`))
        : '',
    },
    name: {
      label: i18n.t('profile.extended.name'),
      value: getFullName({ firstName: emergency?.firstName, lastName: emergency?.lastName }),
    },
    phone: {
      label: i18n.t('profile.edit.form.publicInfo.placeholders.phone'),
      value: `${emergencyCountryCode}${emergency?.phone || ''}`,
    },
  };

  return {
    publicInfo,
    activationStatus,
    privateInformation,
    emergencyContact,
  };
}
