import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';

import { API_HANDLERS } from 'api/apiHandlers';
import { RegisterCompanyParams } from 'api/types';
import { alert } from 'components';
import { COMPANY_NAME_IS_USED, EMAIL_IS_USED } from 'constants/errorCodes';
import { API_ERRORS_BY_CODES, COMPANY_CREATED_RESEND_ERROR } from 'constants/toasterMessages';
import { ApiErrors } from 'types';

interface UseRegisterCompanyMutationReturn {
  registerCompanyMutation: UseMutateAsyncFunction<
    AxiosResponse,
    unknown,
    RegisterCompanyParams,
    unknown
  >;
  isLoading: boolean;
  errors?: Record<string, ApiErrors['errors']> | null;
}

interface UseRegisterCompanyMutationProps {
  resend?: boolean;
  showPersonalInfoForm?: () => void;
}

export function useRegisterCompanyMutation({
  resend = false,
  showPersonalInfoForm,
}: UseRegisterCompanyMutationProps): UseRegisterCompanyMutationReturn {
  const [validationErrors, setValidationErrors] =
    useState<UseRegisterCompanyMutationReturn['errors']>(null);

  const { mutateAsync: registerCompanyMutation, isLoading } = useMutation(
    API_HANDLERS.AUTH.REGISTER,
    {
      onError: (error) => {
        const errorsItem = error as AxiosError<ApiErrors>;

        if (errorsItem.response?.data.errors) {
          errorsItem.response?.data.errors.forEach((error) => {
            const errorText =
              error.code === COMPANY_NAME_IS_USED && resend
                ? COMPANY_CREATED_RESEND_ERROR
                : API_ERRORS_BY_CODES[error.code];

            alert.error(errorText);
          });
        } else {
          alert.error(errorsItem.message);
        }

        const errors: UseRegisterCompanyMutationReturn['errors'] = error
          ? (error as AxiosError<ApiErrors>)?.response?.data.errors.reduce((prev, curr) => {
              const code = curr.code;
              return { ...prev, [code]: curr };
            }, {})
          : null;

        if (Boolean(errors && errors[EMAIL_IS_USED])) {
          showPersonalInfoForm?.();
        }

        setValidationErrors(errors);
      },
    },
  );

  return {
    registerCompanyMutation,
    isLoading,
    errors: validationErrors,
  };
}
