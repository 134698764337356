import { IconButton, AvatarGroup } from '@mui/material';
import { BoxProps } from '@mui/system';
import parse from 'html-react-parser';
import { ReactElement, SyntheticEvent } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import TruncateMarkup from 'react-truncate-markup';

import { ReactComponent as CommentsIcon } from 'assets/icons/comments.svg';
import { ReactComponent as EmptyCommentsIcon } from 'assets/icons/messageText.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/taskCalendar.svg';
import { Avatar } from 'components';
import { MONTH_DAY_SHORT } from 'constants/index';
import { Links } from 'settings';
import { theme } from 'styles';
import { InViewItemRef, TaskListItem as TaskListItemType } from 'types';
import { formattedDate } from 'utils';

import {
  DateContainer,
  DateW,
  FooterW,
  TaskW,
  TitleW,
  AvatarW,
  DescriptionW,
  IconW,
  CountW,
  HeaderW,
} from './styled';
import { avatarGroupStyles, avatarWrapperStyles, profileListItemStyles } from './styles';
import { getAssigneeInitials } from './utils';

interface TaskListItemProps extends TaskListItemType {
  isPreview?: boolean;
  isProfile?: boolean;
  listItemSx?: BoxProps['sx'];
  lastItemRef?: InViewItemRef;
}

export function TaskListItem({
  title,
  deadline,
  assignee,
  description,
  isPreview = false,
  isProfile = false,
  listItemSx,
  lastItemRef,
  id,
  comments_count,
}: TaskListItemProps): ReactElement {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const isProfilePage = pathname.includes(Links.profile.index);

  const detailsLink = generatePath(
    isProfilePage ? Links.profile.taskManagement.taskDetails : Links.we.taskManagement.taskDetails,
    {
      taskId: id,
    },
  );

  const commentIconStyles = isPreview
    ? { right: '9px', top: '7px' }
    : { right: '16px', top: '16px' };

  function handleOnTaskClick() {
    navigate(detailsLink);
  }

  function handleAvatarClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  return (
    <TaskW
      isPreview={isPreview}
      sx={{ ...listItemSx, ...(isProfile && profileListItemStyles) }}
      ref={lastItemRef}
      onClick={handleOnTaskClick}
    >
      <HeaderW>
        <TitleW isPreview={isPreview}>{title}</TitleW>
        <IconButton sx={{ position: 'absolute', ...commentIconStyles }}>
          {comments_count ? (
            <IconW>
              <CommentsIcon style={{ opacity: '0.4' }} />
              <CountW>{comments_count}</CountW>
            </IconW>
          ) : (
            <EmptyCommentsIcon style={{ opacity: '0.4' }} />
          )}
        </IconButton>
      </HeaderW>

      {description && (
        <TruncateMarkup lines={2} ellipsis={<span>...</span>}>
          <DescriptionW>{parse(description)}</DescriptionW>
        </TruncateMarkup>
      )}

      {(deadline || assignee) && (
        <FooterW isPreview={isPreview}>
          {deadline && (
            <DateContainer>
              <CalendarIcon />
              <DateW>{formattedDate(new Date(deadline), MONTH_DAY_SHORT)}</DateW>
            </DateContainer>
          )}

          {assignee && (
            <AvatarW>
              <AvatarGroup max={3} sx={avatarGroupStyles}>
                {assignee.map((user) => {
                  const assigneeInitials = getAssigneeInitials(user);
                  return (
                    <Avatar
                      key={user.id}
                      size="xs"
                      userId={user.id}
                      filename={user?.avatar?.small}
                      initials={assigneeInitials}
                      sx={{ ...(assigneeInitials && { background: theme.palette.info.main }) }}
                      wrapperSx={avatarWrapperStyles}
                      onClick={handleAvatarClick}
                    />
                  );
                })}
              </AvatarGroup>
            </AvatarW>
          )}
        </FooterW>
      )}
    </TaskW>
  );
}
