import { AvatarGroup, Box, IconButton } from '@mui/material';
import parse from 'html-react-parser';
import { ReactElement, SyntheticEvent, useCallback, useMemo } from 'react';
import { generatePath, useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as CommentsIcon } from 'assets/icons/comments.svg';
import { ReactComponent as EmptyCommentsIcon } from 'assets/icons/messageText.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/taskCalendar.svg';
import { Avatar } from 'components';
import { MONTH_DAY_SHORT } from 'constants/index';
import { getAssigneeInitials } from 'features/career/utils';
import { Links } from 'settings';
import { theme } from 'styles';
import { Goal, User } from 'types';
import { formattedDate } from 'utils';

import { GoalStatusLabel } from '../GoalStatusLabel';
import {
  CardW,
  DateContainer,
  DateW,
  DescriptionW,
  Title,
  FooterW,
  FooterWMax,
  IconW,
  CountW,
  CommentsButtonW,
  FooterInner,
} from './styled';
import { avatarGroupStyles, avatarWrapperStyles } from './styles';

interface GoalCardProps extends Pick<Goal, 'id' | 'title' | 'description' | 'status' | 'deadline'> {
  achievementId: string;
  employeeId: string;
  ownerId: string;
  commentsCount?: number;
  usersList: User[];
  assigneeIds?: string[];
  isAllAssignee: boolean;
}

export function GoalCard({
  id,
  title,
  description,
  status,
  deadline,
  achievementId,
  employeeId,
  commentsCount = 0,
  usersList,
  isAllAssignee,
  assigneeIds = [],
}: GoalCardProps): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const viewGoalLink = generatePath(
    Links.profile.achievements.achievement.achievementId.goal.goalId,
    {
      goalId: id,
      achievementId,
      employeeId,
    },
  );

  const assignee = useMemo(
    () =>
      isAllAssignee ? [...usersList] : [...usersList].filter(({ id }) => assigneeIds.includes(id)),
    [assigneeIds, isAllAssignee, usersList],
  );

  const isAssignee = !!assignee.length;

  const handleClick = useCallback(() => {
    navigate(viewGoalLink, {
      state: {
        prevRoute: location.pathname,
      },
    });
  }, [location, navigate, viewGoalLink]);

  const handleAvatarClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <CardW onClick={handleClick}>
      <Title>{title}</Title>
      {description && <DescriptionW>{parse(description)}</DescriptionW>}

      {isAssignee && deadline && (
        <FooterWMax>
          <FooterInner>
            <DateContainer>
              <CalendarIcon />
              <DateW>{formattedDate(new Date(deadline), MONTH_DAY_SHORT)}</DateW>
            </DateContainer>
            <Box>
              <AvatarGroup max={3} sx={avatarGroupStyles}>
                {assignee.map((user) => {
                  const assigneeInitials = getAssigneeInitials(user);
                  return (
                    <Avatar
                      key={user.id}
                      size="xs"
                      userId={user.id}
                      filename={user?.avatar?.small}
                      initials={assigneeInitials}
                      sx={{ ...(assigneeInitials && { background: theme.palette.info.main }) }}
                      wrapperSx={avatarWrapperStyles}
                      onClick={handleAvatarClick}
                    />
                  );
                })}
              </AvatarGroup>
            </Box>
          </FooterInner>
          <GoalStatusLabel status={status} />
        </FooterWMax>
      )}

      {(!isAssignee || !deadline) && (
        <FooterW isAssignee={isAssignee}>
          <GoalStatusLabel status={status} />
          {deadline && (
            <DateContainer>
              <CalendarIcon />
              <DateW>{formattedDate(new Date(deadline), MONTH_DAY_SHORT)}</DateW>
            </DateContainer>
          )}
          {isAssignee && (
            <AvatarGroup max={3} sx={avatarGroupStyles}>
              {assignee.map((user) => {
                const assigneeInitials = getAssigneeInitials(user);
                return (
                  <Avatar
                    key={user.id}
                    size="xs"
                    userId={user.id}
                    filename={user?.avatar?.small}
                    initials={assigneeInitials}
                    sx={{ ...(assigneeInitials && { background: theme.palette.info.main }) }}
                    wrapperSx={avatarWrapperStyles}
                    onClick={handleAvatarClick}
                  />
                );
              })}
            </AvatarGroup>
          )}
        </FooterW>
      )}

      <CommentsButtonW>
        <IconButton>
          {commentsCount ? (
            <IconW>
              <CommentsIcon style={{ opacity: '0.4' }} />
              <CountW>{commentsCount}</CountW>
            </IconW>
          ) : (
            <EmptyCommentsIcon style={{ opacity: '0.4' }} />
          )}
        </IconButton>
      </CommentsButtonW>
    </CardW>
  );
}
