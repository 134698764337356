import { styled, Typography } from '@mui/material';

export const TitleW = styled(Typography)`
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

export const TextW = styled(Typography)`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 0;
  }
`;
