import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { RegisterEquipmentRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UseReqisterEquipmentMutationReturn {
  registerEquipmentMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    RegisterEquipmentRequestParams,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
}

interface UseReqisterEquipmentMutationProps {
  invalidateQueries?: string[];
}

export function useReqisterEquipmentMutation({
  invalidateQueries,
}: UseReqisterEquipmentMutationProps): UseReqisterEquipmentMutationReturn {
  const {
    mutateAsync: registerEquipmentMutation,
    isLoading,
    isSuccess,
  } = useMutation(API_HANDLERS.EQUIPMENTS.REGISTER, {
    onSuccess: () => {
      invalidateQueries && queryClient.invalidateQueries(invalidateQueries);
    },
  });

  return {
    isLoading,
    isSuccess,
    registerEquipmentMutation,
  };
}
