import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { Description, EmptyStateIconW, EmptyStateW, NavLinkW } from './styled';

interface GoalEmptyStateProps {
  addGoalLink: string;
}

export function GoalEmptyState({ addGoalLink }: GoalEmptyStateProps): ReactElement {
  return (
    <EmptyStateW>
      <EmptyStateIconW />
      <Description>
        <Trans i18nKey="career.goals.emptyState">
          Nothing is here yet.
          <NavLinkW to={addGoalLink}>Create a sub-goal</NavLinkW> to place to place sub-goals in
          progress!
        </Trans>
      </Description>
    </EmptyStateW>
  );
}
