import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/icons/closeNote.svg';
import infoIcon from 'assets/icons/infoBlue.png';
import { useModal, useSubscription } from 'hooks';

import { ClearDataModal } from '../ClearDataModal';
import { FlexW, NotificationBar, ContentW, TextW, ButtonW } from './styled';

export function MockedDataNotification(): ReactElement {
  const { t } = useTranslation();
  const { closeMockDataBanner } = useSubscription();
  const { isOpen, handleOpenModal, handleCloseModal } = useModal();

  return (
    <>
      <NotificationBar>
        <FlexW>
          <div>
            <img src={infoIcon} alt="info icon" />
          </div>
          <ContentW>
            <TextW>{t('mockedData.notification')}</TextW>
            <ButtonW variant="text" onClick={handleOpenModal}>
              {t('mockedData.cleanAll')}
            </ButtonW>
          </ContentW>
        </FlexW>

        <FlexW>
          <IconButton onClick={closeMockDataBanner}>
            <CloseIcon />
          </IconButton>
        </FlexW>
      </NotificationBar>

      <ClearDataModal isOpen={isOpen} handleCloseModal={handleCloseModal} />
    </>
  );
}
