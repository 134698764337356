import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    background: theme.palette.white.main,
    color: theme.palette.primary.p080,

    boxShadow: '0px 34px 34px -18px rgba(0, 76, 189, 0.08)',
    borderRadius: '60px',
  },

  '& .MuiInputBase-input': {
    padding: '0 37px',
    height: '85px',
    fontSize: '32px',
    textOverflow: 'ellipsis',
  },

  input: {
    // to remove arrow from number input
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },

  '.MuiFormHelperText-root.Mui-error': {
    top: '-24px',
    position: 'absolute',
    fontSize: '16px',
    lineHeight: '18px',
  },

  [theme.breakpoints.down('desktop')]: {
    '& .MuiInputBase-input': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px',
      height: 'unset',
      padding: '18px 32px 18px 18px',
    },
    '& .MuiInputBase-root.Mui-error': {
      border: '1px solid #FF9900',
    },
    '.MuiFormHelperText-root.Mui-error': {
      top: '3px',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      left: '20px',
    },
  },

  '& .MuiInputBase-root.Mui-disabled': {
    overflow: 'hidden',
    backdropFilter: 'blur(10px)',

    '& .MuiSelect-select.MuiSelect-select': {
      background: '#00000080',
      opacity: '0.2',
      cursor: 'not-allowed',
    },
  },
}));
