import { UserListItem } from '../../types';

export function filteredUserList(userList: UserListItem[]) {
  return userList.reduce((result, currentValue) => {
    const isUserContained = result.find((item) => item.email === currentValue.email);

    if (isUserContained) return result;
    return [...result, currentValue];
  }, [] as UserListItem[]);
}
