import { SxProps, TableProps as TablePropsMUI } from '@mui/material';
import { ReactElement } from 'react';

import { useWindowSize } from 'hooks';
import { InViewItemRef, TableColumnsConfig } from 'types';

import { TableContainer, StyledTableCell, StyledTableRow, HTableRow, HTableCell } from './styled';

export type TableElement<T> = Record<string, T>;

interface TableProps<T, OnClickT> extends TablePropsMUI {
  columns: TableColumnsConfig<T>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  isLoading: boolean;
  headerItemSx?: SxProps;
  lastListRef?: InViewItemRef;
  onClickRow?: (props: OnClickT) => void;
}

export function Table<T, OnClickT>({
  columns,
  data,
  headerItemSx,
  onClickRow,
  lastListRef,
}: TableProps<T, OnClickT>): ReactElement {
  const { isMobile } = useWindowSize();
  return (
    <TableContainer>
      {!isMobile && (
        <thead>
          <HTableRow>
            {columns.map((column) => (
              <HTableCell
                sx={{ width: column.width, ...headerItemSx, ...column.sx }}
                key={column.key as string}
              >
                {column.label}
              </HTableCell>
            ))}
          </HTableRow>
        </thead>
      )}

      <tbody>
        {data.map((row, index) => {
          return (
            <StyledTableRow
              ref={index === data.length - 1 ? lastListRef : undefined}
              key={row.id}
              onClick={() => (onClickRow && row.isClickable ? onClickRow(row.id) : null)}
              className={`${row.class} row-${index + 1}`}
            >
              {columns.map((column) => {
                return (
                  <StyledTableCell
                    sx={{
                      width: column.width,
                      ...column?.sx,
                    }}
                    key={String(column.key)}
                  >
                    {row[column.key]}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          );
        })}
      </tbody>
    </TableContainer>
  );
}
