import { styled, Typography } from '@mui/material';

import { ReactComponent as FileIcon } from 'assets/icons/document.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/downloadFile.svg';

export const FileW = styled('div')<{ canDownload: boolean }>(({ theme, canDownload }) => ({
  width: '130px',
  height: '100%',
  background: theme.palette.white.p100,
  marginRight: '16px',
  borderRadius: '10px',
  cursor: 'pointer',
  position: 'relative',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .3s',

  ...(canDownload && {
    '@media (hover: hover)': {
      '&:hover': {
        background: theme.palette.info.main,

        p: {
          color: theme.palette.white.main,
        },

        '.download-icon, .download-icon-wrapper': {
          display: 'block',
        },

        '.file-icon': {
          display: 'none',
        },
      },
    },
  }),
}));

export const Wrapper = styled('div')`
  width: 100%;
  height: 100;
  display: none;
`;

export const Badge = styled('span')`
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.info.main};
  color: ${({ theme }) => theme.palette.white.main};
`;

export const FileIconW = styled(FileIcon)`
  width: 43px;
  height: 43px;
  margin: 32px auto 20px;
`;

export const DownloadIconW = styled(DownloadIcon)`
  width: 43px;
  height: 43px;
  margin: 32px auto 20px;
  display: none;

  @media (max-width: 1023px) {
    width: 18px;
    height: 18px;
  }
`;

export const FileName = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const ContainerW = styled('div')`
  width: 130px;
  height: 130px;
  background: ${({ theme }) => theme.palette.white.main};
  margin-right: 16px;
  border-radius: 10px;
  position: relative;
  cursor: not-allowed;

  @media (max-width: 1023px) {
    width: 87px;
    height: 87px;
  }
`;
