import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { CreateAchievementRequestParams } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseCreateAchievementMutationReturn {
  createAchievementMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    CreateAchievementRequestParams,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
}

interface UseCreateAchievementMutationProps {
  successText?: string;
  onSuccessAction?: () => void;
}

export function useCreateAchievementMutation({
  successText,
  onSuccessAction,
}: UseCreateAchievementMutationProps): UseCreateAchievementMutationReturn {
  const {
    mutateAsync: createAchievementMutation,
    isLoading,
    isSuccess,
  } = useMutation(API_HANDLERS.ACHIEVEMENTS.CREATE, {
    onSuccess: () => {
      successText && alert.success(successText);
      onSuccessAction?.();
    },
  });

  return {
    isLoading,
    isSuccess,
    createAchievementMutation,
  };
}
