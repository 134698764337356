import { styled, Typography } from '@mui/material';

export const PostReactions = styled('div')<{ isPostDetails?: boolean }>`
  width: fit-content;
  height: 28px;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  justify-content: ${({ isPostDetails }) => isPostDetails && 'flex-end'};
  margin-right: ${({ isPostDetails }) => isPostDetails && 0};
  margin-left: ${({ isPostDetails }) => isPostDetails && 'auto'};

  &:hover .post-hover-reaction {
    display: flex;
  }
`;

export const Separator = styled('div')<{ isPostDetails?: boolean }>`
  width: ${({ isPostDetails }) => (isPostDetails ? '100%' : '361px')};
  height: 1px;
  background: ${({ theme }) => theme.palette.white.main};
  margin: 8px auto;
`;

export const TitleReactions = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.secondary.light};
`;

export const PostHoverReactions = styled('div')`
  position: absolute;
  width: 72px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  background: ${({ theme }) => theme.palette.white.main};
  column-gap: 12px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  display: none;
`;

export const ReactionsContainer = styled('div')`
  position: absolute;
  margin-top: -48px;
  height: 50px;
  width: 72px;
  display: none;
`;

export const LikesCounterContainer = styled('div')`
  width: fit-content;
  min-width: 16px;

  &:hover .likes-author-view {
    display: flex;
  }
`;

export const LikesCounter = styled('div')<{ isPostDetails?: boolean }>`
  width: 100%;
  color: ${({ theme }) => theme.palette.secondary.light};
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: ${({ isPostDetails }) => isPostDetails && 'right'};

  &:hover {
    text-decoration: underline;
  }
`;

export const LikesAuthorView = styled('div')<{
  likesAmount: number;
  isPostDetails?: boolean;
  isAutoPost?: boolean;
}>`
  background: ${({ theme }) => theme.palette.white.main};
  padding: 8px;
  position: absolute;
  width: 132px;
  max-height: 232px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-top: ${({ likesAmount, isAutoPost }) =>
    likesAmount && `calc(${isAutoPost ? '-30px' : '-18px'} - ${likesAmount - 1}*20px)`};
  display: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  right: ${({ isPostDetails }) => isPostDetails && '0'};
  margin-right: ${({ isPostDetails }) => isPostDetails && '33px'};
  z-index: 1;
`;

export const AuthorName = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Reaction = styled('div')``;

export const Wrap = styled('div')<{ isPostDetails?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isPostDetails }) => (isPostDetails ? 'end' : 'start')};
`;
