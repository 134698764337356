import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeaderBoldTitle, PageWrapper } from 'components';

import { EditPollForm } from '../../components';

export function EditPollPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PageHeaderBoldTitle title={t('poll.create.title')} />
      <EditPollForm />
    </PageWrapper>
  );
}
