import { Box, IconButton, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { AlertProps } from '.';

export const NavLinkW = styled(NavLink)`
  display: block;
  margin-top: 4px;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
`;

export const AlertContentW = styled(Box)`
  max-width: 80%;
`;

export const AlertW = styled(Box)<Pick<AlertProps, 'variant'>>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 76px;
  color: ${({ theme }) => theme.palette.overlay};
  border-radius: 5px;
  width: 400px;
  padding: 20px 25px;

  ${({ variant, theme }) => {
    switch (variant) {
      case 'info':
        return `
          background-color: ${theme.palette.info.p010};
          border: 1px solid ${theme.palette.info.main};
        `;
      case 'success':
        return `
          color: ${theme.palette.overlay};
          background-color: #d1f3d3;
          border: 1px solid ${theme.palette.success.main};
        `;
      case 'danger':
        return `
          background-color: ${theme.palette.danger.p010};
          border: 1px solid ${theme.palette.danger.main};
        `;
    }
  }}

  @media (max-width: 1023px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const AlertContent = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const AlertIconW = styled(Box)`
  display: flex;
  align-items: center;

  svg {
    height: 24px;
  }
`;

export const AlertMessage = styled(Box)`
  font-size: 16px;
  font-weight: 600;
  max-width: 100%;
`;

export const CloseButton = styled(IconButton)`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  padding: 10px;
  display: flex;
  align-items: center;
`;
