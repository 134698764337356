import { EquipmentFormValues, RequestEquipmentRequest, EquipmentCategory } from 'types';
import { removeExtraSpaces } from 'utils';

import { getUTCDate } from '../../utils';

export function formValuesAdapter({
  title,
  category,
  expireAt,
  orderedAt,
  serial,
  comment,
  assignee,
  isEditable,
  customFields = [],
}: EquipmentFormValues): RequestEquipmentRequest {
  return {
    name: removeExtraSpaces(title),
    comment: removeExtraSpaces(comment),
    serial: removeExtraSpaces(serial),
    is_editable: Boolean(isEditable),
    assigned_to_id: assignee ?? '',
    ...(expireAt && { expire_at: getUTCDate(expireAt) }),
    ...(orderedAt && { ordered_at: getUTCDate(orderedAt) }),
    category: category as EquipmentCategory,
    custom_fields: [...customFields].filter(({ key, value }) => !!key && !!value),
  };
}
