import { BoxProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { FullPageProgress } from 'components';
import { AuthPageLink } from 'types';

import { PageHeader } from './PageHeader';
import { Page, PageContentW, MobWrapper } from './styled';

interface AuthPageWrapperProps {
  children: ReactNode;
  link?: AuthPageLink;
  isLoading?: boolean;
  contentSx?: BoxProps['sx'];
}

export function AuthPageWrapper({
  children,
  link,
  isLoading,
  contentSx,
}: AuthPageWrapperProps): ReactElement {
  return (
    <>
      {isLoading && <FullPageProgress />}
      <Page>
        <PageContentW sx={contentSx}>
          <MobWrapper>
            <PageHeader link={link} />
            {children}
          </MobWrapper>
        </PageContentW>
      </Page>
    </>
  );
}

export { AuthPageTitle } from './styled';
