import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { MenuItem } from '@mui/material';
import { ReactElement, RefObject, useEffect, useState } from 'react';

import { useWindowSize } from 'hooks';
import { SelectOption } from 'types';

import { CompanySelectBaseTextField } from '../CompanySelectBaseTextField';
import { defaultStyles, menuItemStyles, defaultMobileStyles } from './styles';

type SelectFieldProps = {
  options: SelectOption[];
  defaultValue?: string;
  name?: string;
  id?: string;
  selectRef?: RefObject<HTMLInputElement>;
  onChange?: (value: string, id?: string) => void;
};

export function CompanySelect({
  id,
  options,
  defaultValue,
  name,
  selectRef,
  onChange,
}: SelectFieldProps): ReactElement {
  const { isMobile } = useWindowSize();

  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <CompanySelectBaseTextField
        id={id}
        inputRef={selectRef}
        name={name}
        onChange={(e) => {
          onChange && onChange(e.target.value, id);
          setValue(options.find((opt) => opt.value === e.target.value)?.label || '');
        }}
        value={value}
        select
        InputProps={{
          sx: {
            ...(isMobile ? defaultMobileStyles : defaultStyles),
          },
        }}
        variant="filled"
        SelectProps={{
          disableUnderline: true,
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              '.MuiList-root': {
                maxHeight: '320px',
              },
            },
          },
          displayEmpty: true,
          IconComponent: ExpandMoreRoundedIcon,
        }}
      >
        {options.map(({ value, label }) => {
          const newLabel = label ? `${label}` : '';
          const displayLabel = newLabel.length > 30 ? `${newLabel.slice(0, 30)}...` : label;
          return (
            <MenuItem
              key={value}
              value={value}
              sx={{
                ...menuItemStyles,
              }}
            >
              {displayLabel}
            </MenuItem>
          );
        })}
      </CompanySelectBaseTextField>
    </>
  );
}
