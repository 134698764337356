import { useNavigate, useLocation, createSearchParams, generatePath } from 'react-router-dom';

import { queryClient, useCommentsListQuery, useDeletePostMutation, usePostDetails } from 'api';
import { POSTS_REQUEST_LIMIT } from 'constants/index';
import { useAuth, useModal } from 'hooks';
import { Links } from 'settings';
import { InstanceType } from 'types';

interface LocationState {
  offset?: string;
}

export function usePostDetailsPage(postId: string) {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { isLoading, post } = usePostDetails({
    postId,
    queryName: 'post-details',
    include_reactions: true,
  });

  const { isOpen, handleCloseModal, handleOpenModal } = useModal();

  const { user, isManager } = useAuth();

  const {
    comments,
    isLoading: isLoadingComments,
    isFetchingNextPage,
    lastItemRef,
  } = useCommentsListQuery({
    limit: 20,
    postId: post?.id || '',
    type: InstanceType.tasks || InstanceType.polls,
  });

  const previousOffset = (state as LocationState)?.offset;

  const offset = previousOffset ? +previousOffset + POSTS_REQUEST_LIMIT : undefined;

  function navigateBack() {
    navigate({
      pathname: Links.we.index,
      search: createSearchParams({
        postId,
        ...(offset && { limit: offset.toString(), offset: '0' }),
      }).toString(),
    });
  }

  const isPoll = post?.type === 'polls';
  const isCurrentAuthor = Boolean(user?.id === post?.author.id);

  const canDeletePost = isManager || isCurrentAuthor;
  const canDeletePoll = isCurrentAuthor;
  const canDelete = isPoll ? canDeletePoll : canDeletePost;
  const canEdit = isPoll && isCurrentAuthor;

  const { deletePostMutation } = useDeletePostMutation(() => {
    queryClient.invalidateQueries({ queryKey: ['posts', 'post-details'] });
    navigate(-1);
  });

  async function handleDeletePost() {
    await deletePostMutation({ postId: post?.id || '' });
    handleCloseModal();
  }

  function handleOpenEditMode() {
    const editLink = generatePath(Links.we.posts.edit, { postId: post?.id ?? '' });
    navigate(editLink);
  }

  return {
    navigateBack,
    isLoading,
    post,
    comments,
    isLoadingComments,
    isFetchingNextPage,
    lastItemRef,
    isPoll,
    canEdit,
    canDelete,
    isOpen,
    handleDeletePost,
    handleOpenModal,
    handleCloseModal,
    handleOpenEditMode,
  };
}
