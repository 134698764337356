import { theme } from 'styles';

export const defaultInputSx = {
  '& .MuiSelect-select.MuiSelect-select': {
    display: 'flex',
    height: '85px',
    width: 'fit-content',
    alignItems: 'center',
    fontSize: '24px',
    padding: '0 24px',

    '&:focus': {
      background: 'none',
    },
  },

  '& .MuiSelect-icon': {
    fontSize: '40px',
    right: '24px',
    color: theme.palette.primary.p030,
  },
};

export const mobileInputSx = {
  '& .MuiSelect-select.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',

    '&:focus': {
      background: 'none',
    },
  },

  '& .MuiSelect-icon': {
    fontSize: '40px',
    right: '24px',
    marginRight: '-8px',
    color: theme.palette.primary.p030,
  },
};

export const textFieldSx = {
  width: '160px',

  '@media (max-width: 1023px)': {
    width: '100%',
  },
};

export const menuSx = {
  '.MuiPaper-root': {
    marginTop: '12px',
    width: '624px',
  },
  '.MuiList-root': {
    width: '100%',
    display: 'flex',
    gap: '10px',
    padding: '16px',
    borderRadius: '6px',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',

    '@media (max-width: 1023px)': {
      flexWrap: 'wrap',
      gap: '16px',
      padding: '16px 8px',
    },
  },
};

export const optionItemSx = (color: string) => ({
  width: '40px',
  minHeight: '40px',
  height: '40px',
  padding: 0,
  borderRadius: '50%',
  background: `#${color}`,

  '&.Mui-selected': {
    background: 'none',
    border: `2px solid #${color}`,

    '&:hover': {
      background: 'none',
      cursor: 'default',
    },
  },

  '& svg': {
    width: '32px',
    height: '32px',
    margin: 'auto',
    padding: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `#${color}`,
    borderRadius: '50%',

    '& path': {
      fill: 'white',
    },
  },

  ':hover': {
    background: `#${color}`,
  },
});
