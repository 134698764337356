import { EquipmentFormValues, PostDraft } from 'types';

interface DefaultValuesAdapterProps {
  isDefaultEditable: boolean;
  draft?: PostDraft;
}

export function defaultValuesAdapter({
  draft,
  isDefaultEditable,
}: DefaultValuesAdapterProps): EquipmentFormValues {
  const imagesList = draft?.attachments?.filter((file) => file.type === 'image') || [];
  const documentsList = draft?.attachments?.filter((file) => file.type === 'document') || [];
  const videosList = draft?.attachments?.filter((file) => file.type === 'video') || [];

  return {
    title: '',
    category: '',
    user: '',
    assignee: '',
    expireAt: '',
    orderedAt: '',
    serial: '',
    comment: '',
    isEditable: isDefaultEditable,
    docs: documentsList,
    images: imagesList,
    videos: videosList,
    customFields: [{ key: '', value: '' }],
  };
}
