import { BoxProps, Skeleton } from '@mui/material';
import { ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { useAttachmentDetails } from 'api';

import { MoreImages, StyledImage, Thumb } from './styled';

interface ImageAttachmentProps {
  attachmentId: string;
  fileName: string;
  fileUrl: string;
  wrapperSx?: BoxProps['sx'] | null;
  moreImages?: number | null;
  hasMoreImagesClick?: () => void;
  onOpenGallery?: () => void;
}

export function ImageAttachment({
  attachmentId,
  fileName,
  fileUrl,
  wrapperSx,
  moreImages,
  hasMoreImagesClick,
  onOpenGallery,
}: ImageAttachmentProps): ReactElement {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const { isLoading, data } = useAttachmentDetails({
    attachmentId,
    fileName,
    fileUrl,
    enabled: inView,
  });

  const hasMoreImages = Boolean(moreImages);

  function handleImageClick(e: SyntheticEvent) {
    e.stopPropagation();
    onOpenGallery?.();
    hasMoreImages && hasMoreImagesClick ? hasMoreImagesClick() : onOpenGallery && onOpenGallery();
  }

  return (
    <Thumb ref={ref} onClick={handleImageClick} sx={wrapperSx}>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        <>
          <StyledImage src={data} />
          {hasMoreImages && (
            <MoreImages>{t('common.moreImages', { count: moreImages as number })}</MoreImages>
          )}
        </>
      )}
    </Thumb>
  );
}
