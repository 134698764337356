import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthPageTitle, AuthPageWrapper } from 'features/auth';
import { LanguageSelector } from 'features/languageSelector';
import { Links } from 'settings';
import { AuthPageLink } from 'types';

import { TextW } from './styled';

type LocationState = {
  expired: boolean;
};

export function ResetLinkExpiredPage(): ReactElement {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const expired = (state as LocationState)?.expired;

  if (!expired) {
    navigate(Links.auth.signIn);
  }

  return (
    <AuthPageWrapper link={AuthPageLink.resetPassword}>
      <AuthPageTitle>{t('restorePassword.expired.title')}</AuthPageTitle>
      <TextW sx={{ marginTop: '25px' }}>{t('restorePassword.expired.text')}</TextW>

      <Box sx={{ marginTop: '204px' }}>
        <LanguageSelector />
      </Box>
    </AuthPageWrapper>
  );
}
