import { ReactElement, ReactNode } from 'react';

import { EmptyW, StyledText } from './styled';

interface EmptySpateProps {
  children: ReactNode;
}

export const EmptyState = ({ children }: EmptySpateProps): ReactElement => {
  return (
    <EmptyW>
      <StyledText>{children}</StyledText>
    </EmptyW>
  );
};
