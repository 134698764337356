import { ReactElement } from 'react';

import { TargetForm } from '../TargetForm';
import { defaultValues } from './constants';
import { useCreateTargetForm } from './useCreateTargetForm';

export function CreateTargetForm(): ReactElement {
  const { handleSubmit, isLoading } = useCreateTargetForm();

  return (
    <TargetForm
      mode="create"
      handleSubmit={handleSubmit}
      defaultValues={defaultValues}
      isSubmitting={isLoading}
    />
  );
}
