import { alert } from 'components';
import { FILED_NAMES } from 'constants/index';
import i18n from 'i18n';
import { BackendError } from 'types';

export function handleInvalidInputError(error: BackendError) {
  const { field } = error;
  const parsedFiledName = field.indexOf('[') > -1 ? field.slice(0, field.indexOf('[')) : field;

  const filedName = FILED_NAMES[parsedFiledName] || parsedFiledName;

  alert.error(i18n.t('toasts.wrongFileFormat', { filedName }));
}
