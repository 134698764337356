import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { ReactElement } from 'react';

import { StyledTextField, StyledFormHelperText, StyledTooltip } from './styled';

type TextFieldProps = {
  tooltipText?: string;
} & MuiTextFieldProps;

export function TextField({
  variant = 'standard',
  size = 'small',
  error,
  label,
  value,
  tooltipText,
  ...otherProps
}: TextFieldProps): ReactElement {
  return (
    <>
      <StyledTooltip
        disableFocusListener
        placement="top"
        disableHoverListener={!tooltipText}
        title={tooltipText || ''}
      >
        <StyledFormHelperText className={error ? 'error' : undefined}>
          {otherProps.helperText}
        </StyledFormHelperText>
      </StyledTooltip>
      <StyledTextField
        fullWidth
        size={size}
        variant={variant}
        error={error}
        value={value}
        label={value && !error ? label : ''}
        {...otherProps}
      />
    </>
  );
}
