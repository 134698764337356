import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, NavLink, useParams } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { Spinner } from 'components';
import { useWindowSize } from 'hooks';
import { Links } from 'settings';
import { Equipment, EquipmentsTableFields } from 'types';

import { useEquipmentFilter } from '../../hooks';
import { CategoryFilter } from '../CategoryFilter';
import { DEFAULT_EQUIPMENTS_FIELDS, MOBILE_EQUIPMENTS_FIELDS } from '../config';
import { initialWindowSizeProps } from '../constants';
import { EmptyState } from '../EmptyState';
import { ContainerW, ActionsW, TitleW, TitleIconW, AddButtonW } from '../styled';
import { Table } from '../Table';
import { useRegisteredEquipmentTable } from './hooks/useRegisteredEquipmentTable';
import { OnRegisteredClickT } from './types';

interface RegisteredEquipmentsContainerProps {
  isSwedish: boolean;
  onTrashIconClick: (equipment: Pick<Equipment, 'id' | 'name'>) => void;
}

export function RegisteredEquipmentsContainer({
  isSwedish,
  onTrashIconClick,
}: RegisteredEquipmentsContainerProps): ReactElement {
  const { employeeId = '' } = useParams<string>();
  const { category, control } = useEquipmentFilter();
  const { isLoading, equipmentList, isFilterShown, handleViewEquipment } =
    useRegisteredEquipmentTable({
      category,
      userId: employeeId,
      onTrashIconClick,
      isSwedish,
    });
  const { isMobile } = useWindowSize(initialWindowSizeProps);
  const { t } = useTranslation();

  const createEquipmentLink = generatePath(Links.profile.equipmentList.createEquipment, {
    employeeId,
  });

  const webColumns = DEFAULT_EQUIPMENTS_FIELDS;
  const mobileColumns = MOBILE_EQUIPMENTS_FIELDS;

  return (
    <>
      <ContainerW
        isListData={!!equipmentList.length}
        style={{ marginTop: '24px', paddingTop: '30px' }}
      >
        <TitleW>{t('equipments.register.title')}</TitleW>

        <ActionsW>
          {!isMobile && isFilterShown && <CategoryFilter control={control} />}

          <AddButtonW>
            <TitleIconW>{t('kpi.add')}</TitleIconW>
            <NavLink to={createEquipmentLink}>
              <IconButton>
                <AddIcon style={{ opacity: '0.6' }} />
              </IconButton>
            </NavLink>
          </AddButtonW>
        </ActionsW>

        {isMobile && isFilterShown && <CategoryFilter control={control} />}

        {isLoading && <Spinner />}

        {!isLoading && !equipmentList.length && <EmptyState />}

        {!isLoading && !!equipmentList.length && (
          <Table<EquipmentsTableFields, OnRegisteredClickT>
            isLoading={isLoading}
            columns={isMobile ? mobileColumns : webColumns}
            data={equipmentList}
            onClickRow={handleViewEquipment}
          />
        )}
      </ContainerW>
    </>
  );
}
