export const textFieldMobileStyles = {
  '.MuiInputBase-input': {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
  },
};

export const textFieldStyles = {
  '.MuiInputBase-input': {
    fontSize: '24px',
  },
};

export const textAreaFieldMobileStyles = {
  '& .MuiInputBase-root': {
    borderRadius: '20px',
    padding: 0,
  },

  '.MuiInputBase-input': {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
  },
};

export const textAreaFieldStyles = {
  '& .MuiInputBase-root': {
    borderRadius: '20px',
    padding: 0,
  },

  '.MuiInputBase-input': {
    fontSize: '24px',
    padding: '24px 37px',
  },
};

export const richEditorStyles = (isMobile: boolean, isCreateGoal?: boolean) => ({
  width: '100%',
  marginBottom: isMobile ? '8px' : '0',
  maxWidth: isCreateGoal ? '550px' : '560px',
});
