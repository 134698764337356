export * from './company';
export * from './auth';
export * from './employee';
export * from './table';
export * from './routes';
export * from './post';
export * from './attachment';
export * from './documents';
export * from './task';
export * from './subscriptions';
export * from './comments';
export * from './languages';
export * from './paymentDetails';
export * from './calendarEvents';
export * from './targets';
export * from './vision';
export * from './quickLInks';
export * from './notifications';
export * from './customNotification';
export * from './equipments';
export * from './poll';
export * from './career';
export * from './category';

export type NestedRecord = {
  [key: string]: NestedRecord | string;
};

export type MapNestedRecord<Type extends NestedRecord> = {
  [Property in keyof Type]: Type[Property] extends NestedRecord
    ? MapNestedRecord<Type[Property]>
    : string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type $FixTypeLater = any;

export interface KeyValuePairs<T = string> {
  [key: string]: T;
}

export interface BackendError {
  code: number;
  field: string;
  description: string;
  extended?: string[];
}

export interface ApiErrors {
  errors: BackendError[];
}

export type InViewItemRef = (node?: Element | null | undefined) => void;

export enum FilesTypes {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export interface ListItem {
  value: string;
  id: string;
}

export enum AccessLevel {
  public = 'public',
  shared = 'shared',
  private = 'private',
}

export enum InstanceType {
  news = 'news',
  document = 'docs',
  tasks = 'tasks',
  polls = 'polls',
}

export enum InstanceLevelType {
  public = 'public',
  shared = 'shared',
  private = 'private',
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface SwitchModal {
  open: boolean;
  type: string;
}
