import { RegisterOptions } from 'react-hook-form';

import i18n from 'i18n';
import { getMaxLengthText, getMinLengthText, required } from 'validations';

import { VALIDATION_RULES } from './constants';

export const titleFieldRules: RegisterOptions = {
  ...required,
  minLength: {
    value: VALIDATION_RULES.title.min,
    message: getMinLengthText(VALIDATION_RULES.title.min),
  },
  maxLength: {
    value: VALIDATION_RULES.title.max,
    message: getMaxLengthText(VALIDATION_RULES.title.max),
  },
};

export const valueFieldRules = (targetValue: string | number): RegisterOptions => ({
  ...required,
  minLength: {
    value: VALIDATION_RULES.title.min,
    message: getMinLengthText(VALIDATION_RULES.title.min),
  },
  maxLength: {
    value: VALIDATION_RULES.title.max,
    message: getMaxLengthText(VALIDATION_RULES.title.max),
  },
  validate: (value) => {
    if (targetValue === '' || value === '') {
      return;
    }

    if (Number(targetValue) < Number(value)) {
      return i18n.t('kpi.targetForm.validation.lessThanValue') as string;
    }
  },
});

export const targetFieldRules = (value: string | number): RegisterOptions => ({
  ...required,
  minLength: {
    value: VALIDATION_RULES.title.min,
    message: getMinLengthText(VALIDATION_RULES.title.min),
  },
  maxLength: {
    value: VALIDATION_RULES.title.max,
    message: getMaxLengthText(VALIDATION_RULES.title.max),
  },
  validate: (target) => {
    if (value === '') {
      return;
    }

    if (Number(value) > Number(target)) {
      return i18n.t('kpi.targetForm.validation.lessThanValue') as string;
    }
  },
});
