import { AxiosInstance } from 'axios';

import { saveTokens } from 'utils';

import { API_PATHS } from '../apiPaths';

export async function refreshToken(httpClient: AxiosInstance) {
  const currentToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  const response = await httpClient.post(
    API_PATHS.AUTH.REFRESH_TOKEN(),
    {},
    {
      headers: {
        'Access-Token': String(currentToken),
        'Refresh-Token': String(refreshToken),
      },
    },
  );

  const { data } = response;
  saveTokens(data.access_token, data.refresh_token, data.expires_at);
  return data;
}
