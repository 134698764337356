import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactElement, useEffect, useRef } from 'react';

import { Spinner } from 'components';

import { useConfirmRegistrationLogic } from './hooks';

export function ConfirmRegistrationPage(): ReactElement {
  const isLoaded = useRef(false);

  const { handleConfirmationFlow } = useConfirmRegistrationLogic();

  useEffect(() => {
    if (!isLoaded.current) {
      handleConfirmationFlow();
      isLoaded.current = true;
    }
  }, [handleConfirmationFlow]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress color="info" />
      <Spinner size="4rem" />
    </Box>
  );
}
