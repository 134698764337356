import { GoalFormValues } from 'api';
import { AllOption } from 'features/tasks/components/TaskForm/constants';
import { Goal, GoalStatus } from 'types';

interface DefaultValuesAdapterProps {
  data?: Goal;
}

export function defaultValuesAdapter({ data }: DefaultValuesAdapterProps): GoalFormValues {
  const images = data?.attachments?.filter((file) => file.type === 'image') ?? [];
  const docs = data?.attachments?.filter((file) => file.type === 'document') ?? [];
  const videos = data?.attachments?.filter((file) => file.type === 'video') ?? [];

  return {
    title: data?.title ?? '',
    description: data?.description ?? '',
    deadline: data?.deadline ?? null,
    status: data?.status ?? GoalStatus.PENDING,
    images,
    docs,
    videos,
    assignee: data?.assigned_type === 'all' ? [AllOption.value] : data?.assigned_to_ids || [],
  };
}
