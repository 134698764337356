import { styled } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'assets/icons/startPage/arrowDownOutlined.svg';

export const Wrapper = styled('div')`
  position: relative;
`;

export const StyledArrowIcon = styled(ArrowIcon)<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')}; ;
`;

export const ActionItem = styled('div')`
  display: flex;
  align-items: center;
  padding: 16px;
  color: ${({ theme }) => theme.palette.primary.p080};
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 25px 12px -20px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(10px);

  svg {
    margin-right: 18px;
    width: 32px;
    height: 32px;
  }

  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 14px 16px;
    svg {
      margin-right: 8px;
      width: 32px;
      height: 32px;
    }
  }
`;
