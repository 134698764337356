import { DialogProps } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { SubmitRoundedButton } from 'components';
import { useWindowSize } from 'hooks';

import { Dialog, DialogActions, DialogContent, ContinueBtn, DialogHeader } from './styled';

export interface UnauthorizedToastProps extends DialogProps {
  onClose?: () => void;
}

export function UnauthorizedToast({
  onClose,
  open,
  ...otherProps
}: UnauthorizedToastProps): ReactElement {
  const { isMobile } = useWindowSize();
  const { i18n, t } = useTranslation();

  const toastTitle =
    i18n.language === 'en'
      ? 'Looks like you need to subscribe to be able to' + ' use our cool features.'
      : 'Verkar som att du måste prenumerera för att kunna använda våra coola funktioner.';

  const toastText =
    i18n.language === 'en'
      ? 'Press continue to check out our price plans that scale with your' +
        ' business. For questions about our price plans please reach out to'
      : 'Tryck på fortsätt för att kolla in våra prisplaner som passar ditt företag. För frågor om våra prisplaner vänligen kontakta';

  return (
    <Dialog open={open} {...otherProps}>
      <DialogContent>
        <DialogHeader>
          <WarningIcon />
          <span>{toastTitle}</span>
        </DialogHeader>
        <span>
          {toastText} <a href="mailto:hello@adligo.io">hello@adligo.io</a>
        </span>
      </DialogContent>
      <DialogActions>
        {isMobile ? (
          <SubmitRoundedButton onClick={onClose} />
        ) : (
          <ContinueBtn color="info" variant="contained" onClick={onClose}>
            {t('subscriptions.modals.startSubscription.submit')}
          </ContinueBtn>
        )}
      </DialogActions>
    </Dialog>
  );
}
