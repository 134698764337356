import { useQuery } from '@tanstack/react-query';

import { User } from 'types';

import { API_HANDLERS } from '../../apiHandlers';
import { UsersListRequestParams } from '../../types';
import { usersListAdapter } from './adapter';

interface UseUsersListQueryReturn {
  usersList: User[];
  isLoading: boolean;
}

export function useUsersListQuery({
  detailed,
  rand,
  status,
  limit,
  offset,
}: UsersListRequestParams): UseUsersListQueryReturn {
  const { data, isLoading } = useQuery(['users', detailed, rand, status, limit, offset], () =>
    API_HANDLERS.USERS.GET({
      ...(detailed && { detailed }),
      ...(rand && { rand }),
      ...(status && { status }),
      ...(limit && { limit }),
      ...(offset && { offset }),
    }),
  );

  return {
    usersList: usersListAdapter(data?.data.data),
    isLoading,
  };
}
