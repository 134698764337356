import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import {
  Backdrop,
  BaseTextFieldController,
  ConfirmDialog,
  Form,
  FormGroup,
  SelectController,
  SubmitRoundedButton,
} from 'components';
import { useAuth, useWindowSize } from 'hooks';
import { textFieldMobileStyles, textFieldStyles } from 'styles';
import { TargetFormValues } from 'types';
import { stripNonDigits } from 'utils';
import { required } from 'validations';

import { TYPE_OPTIONS, VALIDATION_RULES } from './constants';
import { useTargetForm } from './hooks';
import { targetFieldRules, titleFieldRules, valueFieldRules } from './rules';
import {
  FormContainer,
  FormTitle,
  NumberInputW,
  RemoveButtonW,
  SelectW,
  SubmitBtnW,
  TitleW,
} from './styled';
import { selectTypeSx } from './styles';

interface TargetFormProps {
  mode: 'create' | 'edit';
  handleSubmit: (data: TargetFormValues) => void;
  defaultValues: TargetFormValues;
  isSubmitting?: boolean;
  handleOpenModal?: () => void;
}

export function TargetForm({
  handleSubmit,
  mode,
  defaultValues,
  isSubmitting,
  handleOpenModal,
}: TargetFormProps): ReactElement {
  const {
    control,
    isOpen,
    isLoading,
    handleSubmitConfirmModal,
    handleFormSubmit,
    handleCloseConfirmModal,
    isDirty,
    value,
    target,
  } = useTargetForm({
    mode,
    handleSubmit,
    defaultValues,
  });

  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const { isManager } = useAuth();
  const navigate = useNavigate();

  const isEditMode = mode === 'edit';
  const loading = isLoading || isSubmitting;
  const formGroupMarginBottom = isMobile ? 0 : 24;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;

  useEffect(() => {
    if (!isManager) {
      navigate(-1);
    }
  }, [isManager, navigate]);

  return (
    <>
      {loading && <Backdrop />}

      <FormContainer>
        <FormTitle>{t('kpi.targetForm.subtitle')}</FormTitle>
        <Form onSubmit={handleFormSubmit}>
          <FormGroup marginBottom={formGroupMarginBottom} row>
            <TitleW>
              <BaseTextFieldController
                name="title"
                control={control}
                placeholder={t('kpi.targetForm.placeholders.title')}
                sx={fieldStyles}
                maxLength={VALIDATION_RULES.title.max}
                rules={titleFieldRules}
                preventSpaceOnStart
              />
            </TitleW>
            <SelectW>
              <SelectController
                name="type"
                control={control}
                placeholder={t('kpi.targetForm.placeholders.type')}
                options={TYPE_OPTIONS}
                emptyOption={t('kpi.targetForm.options.hint')}
                withDefaultEmptyOption
                sx={selectTypeSx}
                rules={required}
              />
            </SelectW>
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom} row>
            <NumberInputW>
              <BaseTextFieldController
                name="value"
                control={control}
                placeholder={t('kpi.targetForm.placeholders.value')}
                sx={fieldStyles}
                maxLength={VALIDATION_RULES.title.max}
                format={stripNonDigits}
                rules={valueFieldRules(target)}
              />
            </NumberInputW>
            <NumberInputW>
              <BaseTextFieldController
                name="target"
                control={control}
                placeholder={t('kpi.targetForm.placeholders.target')}
                sx={fieldStyles}
                format={stripNonDigits}
                maxLength={VALIDATION_RULES.title.max}
                rules={targetFieldRules(value)}
              />
            </NumberInputW>
          </FormGroup>
          {isManager && (
            <RemoveButtonW onClick={handleOpenModal} endIcon={<TrashIcon />}>
              {t('kpi.targetForm.removeButton')}
            </RemoveButtonW>
          )}
          <SubmitBtnW>
            <SubmitRoundedButton disabled={isEditMode && !isDirty} />
          </SubmitBtnW>
        </Form>
      </FormContainer>

      <ConfirmDialog
        subTitle={t('task.create.modal.exit')}
        title={t('task.create.modal.title')}
        open={isOpen}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
