import { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useUsersCompanyListQuery } from 'api';
import { ReactComponent as AttachmentIcon } from 'assets/icons/customLink/attachment.svg';
import { ConfirmDialog } from 'components';
import { useCompanySwitchMutation } from 'features/companySelector/containers/CompanySelector/hooks';
import { DocumentAttachments } from 'features/documentList/components';
import { useOnEmployeeRedirect } from 'features/notifications/hooks/useOnEmployeeRedirect';
import { useOnPostRedirect } from 'features/notifications/hooks/useOnPostRedirect';
import { Links } from 'settings';
import {
  NotificationType as NotificationTypeEnum,
  Notification as NotificationType,
  Attachment,
  SwitchModal,
} from 'types';
import { getFullName } from 'utils';

import { ContentW, DescriptionW, ListW, MainContentW, StrongW, TitleW } from './styled';

type NotificationDescriptionProps = NotificationType & {
  isPreview?: boolean;
  avatar?: string;
};

export function NotificationDescription({
  isPreview = false,
  type,
  payload,
  company,
}: NotificationDescriptionProps): ReactElement {
  const [id, setId] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [messageType, setMessageType] = useState('');
  const [switchModal, setSwitchModal] = useState<SwitchModal>({ open: false, type: '' });

  const { currentCompany } = useUsersCompanyListQuery();
  const { switchCompanyUserMutation } = useCompanySwitchMutation({ reload: false });
  const [isExpandedCard, setIsExpandedCard] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useOnPostRedirect({ id, type: messageType, clearId: () => setId('') });

  useOnEmployeeRedirect({
    employeeId,
    clearId: () => setEmployeeId(''),
  });

  function handleRedirectClick(link: string, type: string) {
    setId(link);
    setMessageType(type);
  }

  function handleUserClick(id: string) {
    setEmployeeId(id);
  }

  function switchCompany(action: string) {
    switch (type) {
      case NotificationTypeEnum.assigned:
        const employeeId = payload.assigned_by.id;
        if (action === 'employee') {
          handleUserClick(employeeId);
        } else if (action === 'task') {
          handleRedirectClick(payload.task.id, 'task');
        }
        break;
      case NotificationTypeEnum.post_comment:
        const postEmployeeId = payload.posted_by.id;
        if (action === 'employee') {
          handleUserClick(postEmployeeId);
        } else if (action === 'post') {
          handleRedirectClick(payload.task.id, 'post');
        }
        break;
      case NotificationTypeEnum.task_comment:
        const taskCommentEmployeeId = payload.posted_by.id;
        if (action === 'employee') {
          handleUserClick(taskCommentEmployeeId);
        } else if (action === 'task') {
          handleRedirectClick(payload.task.id, 'task');
        }
        break;
      case NotificationTypeEnum.welcome:
        const userWelcomePost = generatePath(Links.we.posts.index, {
          postId: payload.post?.id || '',
        });

        const welcomeEmployeeId = payload.employee.id;
        if (action === 'employee') {
          handleUserClick(welcomeEmployeeId);
        } else if (action === 'welcome') {
          navigate(userWelcomePost);
        }
        break;
      case NotificationTypeEnum.edited:
        const editEmployeeId = payload.edited_by.id;
        if (action === 'employee') {
          handleUserClick(editEmployeeId);
        } else if (action === 'task') {
          handleRedirectClick(payload.task.id, 'task');
        }
        break;

      case NotificationTypeEnum.removed:
        const taskRemoveEmployeeId = payload.deleted_by.id;
        if (action === 'employee') {
          handleUserClick(taskRemoveEmployeeId);
        } else if (action === 'task') {
          handleRedirectClick(payload.task.id, 'task');
        }
        break;
      case NotificationTypeEnum.completed:
        const taskCompletedEmployeeId = payload.completed_by.id;
        if (action === 'employee') {
          handleUserClick(taskCompletedEmployeeId);
        } else if (action === 'task') {
          handleRedirectClick(payload.task.id, 'task');
        }
        break;

      case NotificationTypeEnum.due:
        if (action === 'post') {
          handleRedirectClick(payload.post.id, 'post');
        }
        break;
      case NotificationTypeEnum.birthday:
        const birthdayEmployeeId = payload.user.id;

        if (action === 'employee') {
          handleUserClick(birthdayEmployeeId);
        }
        break;
      case NotificationTypeEnum.birthday_today:
        const birthdayTodayEmployeeId = payload.user.id;
        if (action === 'employee') {
          handleUserClick(birthdayTodayEmployeeId);
        }
        break;

      case NotificationTypeEnum.equipment_completed:
        const equipmentId = payload.equipment.id;
        const equipmentsLink = generatePath(
          `${Links.profile.equipmentList.equipment.index}#${equipmentId}`,
          {
            employeeId: payload.equipment.user_id || '',
          },
        );
        if (action === 'equipment' && equipmentId) {
          navigate(equipmentsLink);
        } else {
          handleRedirectClick(payload.equipment.user_id, 'equipment');
        }
        break;

      case NotificationTypeEnum.equipment_expires_soon:
        const expireEquipmentId = payload.equipment.id;

        const expireEquipmentsLink = generatePath(
          `${Links.profile.equipmentList.equipment.index}#${expireEquipmentId}`,
          {
            employeeId: payload.equipment.user_id || '',
          },
        );
        if (action === 'equipment' && expireEquipmentId) {
          navigate(expireEquipmentsLink);
        } else {
          handleRedirectClick(payload.equipment.user_id, 'equipment');
        }
        break;

      case NotificationTypeEnum.post_new:
        const postNewEmployeeId = payload.posted_by.id;
        if (action === 'employee') {
          handleUserClick(postNewEmployeeId);
        } else if (action === 'post') {
          handleRedirectClick(payload.post?.id || '', 'post');
        }
        break;
      case NotificationTypeEnum.poll_new:
        const postByEmployeeId = payload.posted_by.id;

        if (action === 'employee') {
          handleUserClick(postByEmployeeId);
        } else if (action === 'poll') {
          handleRedirectClick(payload.post?.id || '', 'poll');
        }
        break;
      default:
        return null;
    }
  }

  function formatDescription() {
    switch (type) {
      case NotificationTypeEnum.assigned: {
        const userName = getFullName({
          lastName: payload.assigned_by.last_name,
          firstName: payload.assigned_by.first_name,
        });
        const employeeId = payload.assigned_by.id;

        return (
          <Trans
            i18nKey="notifications.types.taskAssigned"
            values={{
              userName,
              taskTitle: payload.task.title,
            }}
          >
            {' '}
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>
            assigned to you
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleRedirectClick(payload.task.id, 'task');
                  } else {
                    setSwitchModal({ open: true, type: 'task' });
                  }
                }
              }}
            >
              {payload.task.title}
            </StrongW>
            task
          </Trans>
        );
      }
      case NotificationTypeEnum.post_comment: {
        const userName = getFullName({
          lastName: payload.posted_by.last_name,
          firstName: payload.posted_by.first_name,
        });
        const employeeId = payload.posted_by.id;

        return (
          <Trans
            i18nKey="notifications.types.postComment"
            values={{
              userName,
              taskTitle: payload.task.title,
            }}
          >
            {' '}
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>
            comments on post
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleRedirectClick(payload.task.id, 'post');
                  } else {
                    setSwitchModal({ open: true, type: 'post' });
                  }
                }
              }}
            >
              {payload.task.title}
            </StrongW>
          </Trans>
        );
      }
      case NotificationTypeEnum.task_comment: {
        const userName = getFullName({
          lastName: payload.posted_by.last_name,
          firstName: payload.posted_by.first_name,
        });
        const employeeId = payload.posted_by.id;

        return (
          <Trans
            i18nKey="notifications.types.taskComment"
            values={{
              userName,
              taskTitle: payload.task.title,
            }}
          >
            {' '}
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>
            comments on task
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleRedirectClick(payload.task.id, 'task');
                  } else {
                    setSwitchModal({ open: true, type: 'task' });
                  }
                }
              }}
            >
              {payload.task.title}
            </StrongW>
          </Trans>
        );
      }
      case NotificationTypeEnum.welcome: {
        const userName = getFullName({
          lastName: payload.employee.last_name,
          firstName: payload.employee.first_name,
        });

        const userWelcomePost = generatePath(Links.we.posts.index, {
          postId: payload.post?.id || '',
        });

        const employeeId = payload.employee.id;

        return (
          <Trans
            i18nKey="notifications.types.welcomeEmployee"
            values={{
              userName,
            }}
          >
            We welcome{' '}
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>
            to the team! 🥳 Take a chance to
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    navigate(userWelcomePost);
                  } else {
                    setSwitchModal({ open: true, type: 'welcome' });
                  }
                }
              }}
            >
              say hello!
            </StrongW>
          </Trans>
        );
      }
      case NotificationTypeEnum.edited: {
        const userName = getFullName({
          lastName: payload.edited_by.last_name,
          firstName: payload.edited_by.first_name,
        });
        const employeeId = payload.edited_by.id;

        return (
          <Trans
            i18nKey="notifications.types.taskEdited"
            values={{
              userName,
              taskTitle: payload.task.title,
            }}
          >
            {' '}
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>
            edited a
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleRedirectClick(payload.task.id, 'task');
                  } else {
                    setSwitchModal({ open: true, type: 'task' });
                  }
                }
              }}
            >
              {payload.task.title}
            </StrongW>
            task
          </Trans>
        );
      }
      case NotificationTypeEnum.removed: {
        const userName = getFullName({
          lastName: payload.deleted_by.last_name,
          firstName: payload.deleted_by.first_name,
        });
        const employeeId = payload.deleted_by.id;

        return (
          <Trans
            i18nKey="notifications.types.taskRemoved"
            values={{
              userName,
              taskTitle: payload.task.title,
            }}
          >
            {' '}
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>
            removed a
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleRedirectClick(payload.task.id, 'task');
                  } else {
                    setSwitchModal({ open: true, type: 'task' });
                  }
                }
              }}
            >
              {payload.task.title}
            </StrongW>
            task
          </Trans>
        );
      }
      case NotificationTypeEnum.completed: {
        const userName = getFullName({
          lastName: payload.completed_by.last_name,
          firstName: payload.completed_by.first_name,
        });
        const employeeId = payload.completed_by.id;

        return (
          <Trans
            i18nKey="notifications.types.taskCompleted"
            values={{
              userName,
              taskTitle: payload.task.title,
            }}
          >
            {' '}
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>
            moved
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleRedirectClick(payload.task.id, 'task');
                  } else {
                    setSwitchModal({ open: true, type: 'task' });
                  }
                }
              }}
            >
              {payload.task.title}
            </StrongW>
            task to status &quote;Done&quote;
          </Trans>
        );
      }
      case NotificationTypeEnum.due: {
        return (
          <Trans
            i18nKey="notifications.types.taskDue"
            values={{
              taskTitle: payload.post.title,
            }}
          >
            Your task
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleRedirectClick(payload.post.id, 'post');
                  } else {
                    setSwitchModal({ open: true, type: 'post' });
                  }
                }
              }}
            >
              {payload.post.title}
            </StrongW>
            is due shortly. Make sure you manage to finish it
          </Trans>
        );
      }
      case NotificationTypeEnum.birthday: {
        const userName = getFullName({
          lastName: payload.user.last_name,
          firstName: payload.user.first_name,
        });
        const date = new Date(payload.user.birth_date as string);
        const userBirthdate = `${date.getDate()} ${date.toLocaleString('default', {
          month: 'short',
        })}`;
        const employeeId = payload.user.id;

        return (
          <Trans
            i18nKey="notifications.types.birthday"
            values={{
              userName,
              userBirthdate,
            }}
          >
            It&apos;s
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>{' '}
            birthday on {userBirthdate}. Get ready to celebrate! 🎉
          </Trans>
        );
      }
      case NotificationTypeEnum.birthday_today: {
        const userName = getFullName({
          lastName: payload.user.last_name,
          firstName: payload.user.first_name,
        });
        const date = new Date(payload.user.birth_date as string);
        const userBirthdate = `${date.getDate()} ${date.toLocaleString('default', {
          month: 'short',
        })}`;
        const employeeId = payload.user.id;

        return (
          <Trans
            i18nKey="notifications.types.birthdayToday"
            values={{
              userName,
              userBirthdate,
            }}
          >
            It&apos;s
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>{' '}
            birthday today! Congratulations! 🥳
          </Trans>
        );
      }
      case NotificationTypeEnum.custom: {
        const title = payload.title;
        const description = payload.description;
        const attachmentsAmount = (payload.attachments || []).length;
        const isAttachment = attachmentsAmount > 0;
        const isCustom = true;

        return (
          <MainContentW>
            {' '}
            <StrongW>{title}</StrongW>
            <ContentW
              isAttachment={isAttachment}
              isPreview={isPreview}
              dangerouslySetInnerHTML={{ __html: description }}
            ></ContentW>
            {isPreview && attachmentsAmount > 0 && (
              <ListW>
                <AttachmentIcon />
                <TitleW>{attachmentsAmount}</TitleW>
              </ListW>
            )}
            {!isPreview && payload.attachments && payload.attachments.length && (
              <DocumentAttachments
                attachments={payload.attachments as Attachment[]}
                isExpandedCard={isExpandedCard}
                setIsExpandedCard={setIsExpandedCard}
                isCustom={isCustom}
              />
            )}
          </MainContentW>
        );
      }
      case NotificationTypeEnum.equipment_completed: {
        const equipmentName = payload.equipment.name;

        const equipmentId = payload.equipment.id;
        const equipmentsLink = generatePath(
          `${Links.profile.equipmentList.equipment.index}#${equipmentId}`,
          {
            employeeId: payload.equipment.user_id || '',
          },
        );

        return (
          <Trans
            i18nKey="notifications.types.equipmentCompleted"
            values={{
              equipmentName,
            }}
          >
            Your
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    equipmentId
                      ? navigate(equipmentsLink)
                      : handleRedirectClick(payload.equipment.user_id, 'equipment');
                  } else {
                    setSwitchModal({ open: true, type: 'equipment' });
                  }
                }
              }}
            >
              {equipmentName}
            </StrongW>
            request has been approved!
          </Trans>
        );
      }
      case NotificationTypeEnum.equipment_expires_soon: {
        const equipmentName = payload.equipment.name;
        const equipmentId = payload.equipment.id;

        const equipmentsLink = generatePath(
          `${Links.profile.equipmentList.equipment.index}#${equipmentId}`,
          {
            employeeId: payload.equipment.user_id || '',
          },
        );

        return (
          <Trans
            i18nKey="notifications.types.equipmentExpiresSoon"
            values={{
              equipmentName,
            }}
          >
            Your registered equipment
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    equipmentId
                      ? navigate(equipmentsLink)
                      : handleRedirectClick(payload.equipment.user_id, 'equipment');
                  } else {
                    setSwitchModal({ open: true, type: 'equipment' });
                  }
                }
              }}
            >
              {equipmentName}
            </StrongW>
            expires in 10 days!
          </Trans>
        );
      }
      case NotificationTypeEnum.post_new: {
        const userName = getFullName({
          lastName: payload.posted_by.last_name,
          firstName: payload.posted_by.first_name,
        });

        const employeeId = payload.posted_by.id;

        return (
          <Trans
            i18nKey="notifications.types.privatePost"
            values={{
              userName,
            }}
          >
            {' '}
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>
            just published a
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleRedirectClick(payload.post?.id || '', 'post');
                  } else {
                    setSwitchModal({ open: true, type: 'post' });
                  }
                }
              }}
            >
              post
            </StrongW>
            for you!
          </Trans>
        );
      }
      case NotificationTypeEnum.poll_new: {
        const userName = getFullName({
          lastName: payload.posted_by.last_name,
          firstName: payload.posted_by.first_name,
        });

        const employeeId = payload.posted_by.id;

        return (
          <Trans
            i18nKey="notifications.types.publishedPoll"
            values={{
              userName,
            }}
          >
            {' '}
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleUserClick(employeeId);
                  } else {
                    setSwitchModal({ open: true, type: 'employee' });
                  }
                }
              }}
            >
              {userName}
            </StrongW>
            just published a
            <StrongW
              onClick={(e) => {
                e.stopPropagation();
                if (isPreview) {
                  navigate(Links.we.notifications);
                } else {
                  if (currentCompany?.id === company?.id) {
                    handleRedirectClick(payload.post?.id || '', 'poll');
                  } else {
                    setSwitchModal({ open: true, type: 'poll' });
                  }
                }
              }}
            >
              poll
            </StrongW>
            for you to vote!
          </Trans>
        );
      }
      default:
        return null;
    }
  }

  const description = formatDescription();

  return (
    <DescriptionW isPreview={isPreview}>
      {description}
      <ConfirmDialog
        title={t('notifications.switchModal.title', {
          currentOrg: currentCompany?.name,
          targetOrg: company?.name,
        })}
        subTitle={t('notifications.switchModal.exit')}
        open={switchModal?.open}
        onSubmit={() => {
          switchCompanyUserMutation(company?.id);
          setTimeout(() => {
            switchCompany(switchModal?.type);
          }, 2000);
        }}
        onClose={() => {
          setSwitchModal({ open: false, type: '' });
        }}
      />
    </DescriptionW>
  );
}
