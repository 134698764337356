import { Navigate, useRoutes } from 'react-router-dom';

import { AppLayout, AuthRouteWrapper, PrivateRoute } from 'components';
import { NotFound } from 'features/notFound';
import { useAuth } from 'hooks';
import {
  ActionsPage,
  ConfirmRegistrationPage,
  InviteEmployeePage,
  RequestPasswordResetPage,
  RegisterCompanyPage,
  RegisterCompanySuccessPage,
  ResourcesPage,
  SignInPage,
  WePage,
  SignUpPage,
  SignUpExpiredPage,
  EmployeesPage,
  ProfilePage,
  EmployeeDetailPage,
  EmployeeExtendedDetailsPage,
  CreatePostPage,
  CreateDocumentsPage,
  CreateCategoriesPage,
  OffboardingPage,
  PostDetailsPage,
  CreateTaskPage,
  TaskManagementPage,
  TaskDetailsPage,
  SubscriptionsPage,
  DocumentListPage,
  PublicDocumentsPage,
  CompanyDetailsPage,
  CreatePasswordPage,
  ResetLinkExpiredPage,
  KpiPage,
  CreateVisionPage,
  CreateTargetPage,
  EditVisionPage,
  EditTargetPage,
  QuickLinksPage,
  CreateQuickLinkPage,
  EditQuickLinkPage,
  FinishUserActivationPage,
  NotificationsPage,
  EquipmentsPage,
  CreateEquipmentPage,
  EditEquipmentPage,
  CreateNotificationPage,
  CreatePollPage,
  EditPollPage,
  AchievementsPage,
  CreateAchievementPage,
  CreatGoalPage,
  EditAchievementPage,
  EditGoalPage,
} from 'pages';
import { Paths } from 'settings';

function App() {
  const { user, isManager } = useAuth();

  setTimeout(() => {
    Notification.requestPermission();
  }, 3000);

  const element = useRoutes([
    {
      path: Paths.index,
      element: <AppLayout />,
      children: [
        { path: '', element: <Navigate to={Paths.we.index} /> },
        {
          path: Paths.we.index,
          children: [
            {
              path: '',
              element: <PrivateRoute component={WePage} />,
            },
            {
              path: Paths.we.employees.index,
              children: [
                {
                  path: '',
                  element: <PrivateRoute component={EmployeesPage} />,
                },
                {
                  path: Paths.we.employees.invite,
                  element: <PrivateRoute component={InviteEmployeePage} />,
                },
                {
                  path: Paths.we.employees.employee.index,
                  children: [
                    {
                      path: '',
                      element: <PrivateRoute component={EmployeeDetailPage} />,
                    },
                    {
                      path: Paths.we.employees.employee.extended,
                      element: <PrivateRoute component={EmployeeExtendedDetailsPage} />,
                    },
                    {
                      path: Paths.we.employees.employee.createDocument,
                      element: <PrivateRoute component={CreateDocumentsPage} />,
                    },
                    {
                      path: Paths.we.employees.employee.privateDocuments,
                      element: <PrivateRoute component={DocumentListPage} />,
                    },
                  ],
                },
              ],
            },
            {
              path: Paths.we.createPost,
              element: <PrivateRoute component={CreatePostPage} />,
            },
            {
              path: Paths.we.createPoll,
              element: <PrivateRoute component={CreatePollPage} />,
            },
            {
              path: Paths.we.createTask,
              element: <PrivateRoute component={CreateTaskPage} />,
            },
            {
              path: Paths.we.createDocument,
              element: <PrivateRoute component={CreateDocumentsPage} />,
            },
            {
              path: Paths.we.posts.index,
              children: [
                {
                  path: '',
                  element: <PrivateRoute component={PostDetailsPage} />,
                },
                {
                  path: Paths.we.posts.edit,
                  element: <PrivateRoute component={EditPollPage} />,
                },
              ],
            },
            {
              path: Paths.we.taskManagement.index,
              children: [
                {
                  path: '',
                  element: <PrivateRoute component={TaskManagementPage} />,
                },
                {
                  path: Paths.we.taskManagement.taskDetails,
                  element: <PrivateRoute component={TaskDetailsPage} />,
                },
              ],
            },
            {
              path: Paths.we.kpi.index,
              children: [
                {
                  path: '',
                  element: <PrivateRoute component={KpiPage} />,
                },
                {
                  path: Paths.we.kpi.createVision,
                  element: <PrivateRoute component={CreateVisionPage} />,
                },
                {
                  path: Paths.we.kpi.editVision,
                  element: <PrivateRoute component={EditVisionPage} />,
                },
                {
                  path: Paths.we.kpi.createTarget,
                  element: <PrivateRoute component={CreateTargetPage} />,
                },
                {
                  path: Paths.we.kpi.targetDetails,
                  element: <PrivateRoute component={EditTargetPage} />,
                },
              ],
            },
            {
              path: Paths.we.quickLinks.index,
              children: [
                {
                  path: '',
                  element: <PrivateRoute component={QuickLinksPage} />,
                },
                {
                  path: Paths.we.quickLinks.createLink,
                  element: <PrivateRoute component={CreateQuickLinkPage} />,
                },
                {
                  path: Paths.we.quickLinks.linkDetails,
                  element: <PrivateRoute component={EditQuickLinkPage} />,
                },
              ],
            },
            {
              path: Paths.we.publicDocuments,
              element: <PrivateRoute component={PublicDocumentsPage} />,
            },
            {
              path: Paths.we.notifications,
              element: <PrivateRoute component={NotificationsPage} />,
            },
          ],
        },
        {
          path: Paths.actions.index,
          children: [
            {
              path: '',
              element: <PrivateRoute component={ActionsPage} />,
            },
            {
              path: Paths.actions.employeesInvite,
              element: <PrivateRoute component={InviteEmployeePage} />,
            },
            {
              path: Paths.actions.createPost,
              element: <PrivateRoute component={CreatePostPage} />,
            },
            {
              path: Paths.actions.createDocument,
              element: <PrivateRoute component={CreateDocumentsPage} />,
            },
            {
              path: Paths.actions.offboarding,
              element: <PrivateRoute component={OffboardingPage} />,
            },
            {
              path: Paths.actions.createTask,
              element: <PrivateRoute component={CreateTaskPage} />,
            },
            {
              path: Paths.actions.employees.index,
              children: [
                {
                  path: Paths.actions.employees.employee.index,
                  children: [
                    {
                      path: '',
                      element: <PrivateRoute component={EmployeeDetailPage} />,
                    },
                  ],
                },
              ],
            },
            {
              path: Paths.actions.subscriptions,
              element:
                user && isManager ? <PrivateRoute component={SubscriptionsPage} /> : <WePage />,
            },
            {
              path: Paths.actions.createNotification,
              element: isManager && <PrivateRoute component={CreateNotificationPage} />,
            },
            {
              path: Paths.actions.createEquipment,
              element: <PrivateRoute component={CreateEquipmentPage} />,
            },
            {
              path: Paths.actions.createPoll,
              element: <PrivateRoute component={CreatePollPage} />,
            },
          ],
        },
        {
          path: Paths.resources.index,
          children: [
            {
              path: '',
              element: <PrivateRoute component={ResourcesPage} />,
            },
            {
              path: Paths.resources.edit.index,
              children: [
                {
                  path: Paths.resources.edit.categoryId,
                  element: <PrivateRoute component={CreateCategoriesPage} />,
                },
              ],
            },
            {
              path: Paths.resources.employees.index,
              children: [
                {
                  path: '',
                  element: <PrivateRoute component={EmployeesPage} />,
                },
                {
                  path: Paths.resources.employees.invite,
                  element: <PrivateRoute component={InviteEmployeePage} />,
                },
                {
                  path: Paths.resources.employees.employee.index,
                  children: [
                    {
                      path: '',
                      element: <PrivateRoute component={EmployeeDetailPage} />,
                    },
                    {
                      path: Paths.resources.employees.employee.extended,
                      element: <PrivateRoute component={EmployeeExtendedDetailsPage} />,
                    },
                  ],
                },
              ],
            },
            {
              path: Paths.resources.documents.index,
              children: [
                {
                  path: Paths.resources.documents.tag,
                  element: <PrivateRoute component={DocumentListPage} />,
                },
                {
                  path: Paths.resources.documents.edit.index,
                  children: [
                    {
                      path: Paths.resources.documents.edit.documentId,
                      element: <PrivateRoute component={CreateDocumentsPage} />,
                    },
                  ],
                },
              ],
            },
            {
              path: Paths.resources.createDocument,
              element: <PrivateRoute component={CreateDocumentsPage} />,
            },
            {
              path: Paths.resources.createCategory,
              element: <PrivateRoute component={CreateCategoriesPage} />,
            },
            {
              path: Paths.resources.createTaggedDocument.index,
              children: [
                {
                  path: Paths.resources.createTaggedDocument.tagged.tag,
                  children: [
                    {
                      path: Paths.resources.createTaggedDocument.tagged.tag,
                      element: <PrivateRoute component={CreateDocumentsPage} />,
                    },
                  ],
                },
              ],
            },
            {
              path: Paths.resources.createTask,
              element: <PrivateRoute component={CreateTaskPage} />,
            },
            {
              path: Paths.resources.companyInfo,
              element: <PrivateRoute component={CompanyDetailsPage} />,
            },
          ],
        },
        {
          path: Paths.profile.index,
          children: [
            {
              path: '',
              element: <PrivateRoute component={ProfilePage} />,
            },
            {
              path: Paths.profile.createDocument,
              element: <PrivateRoute component={CreateDocumentsPage} />,
            },
            {
              path: Paths.profile.taskManagement.index,
              children: [
                { path: '', element: <PrivateRoute component={TaskManagementPage} /> },
                {
                  path: Paths.profile.taskManagement.taskDetails,
                  element: <PrivateRoute component={TaskDetailsPage} />,
                },
              ],
            },
            {
              path: Paths.profile.createTask,
              element: <PrivateRoute component={CreateTaskPage} />,
            },
            {
              path: Paths.profile.equipmentList.index,
              children: [
                {
                  path: Paths.profile.equipmentList.equipment.index,
                  children: [
                    {
                      path: '',
                      element: <PrivateRoute component={EquipmentsPage} />,
                    },
                    {
                      path: Paths.profile.equipmentList.equipment.equipmentId,
                      element: <PrivateRoute component={EditEquipmentPage} />,
                    },
                  ],
                },
                {
                  path: Paths.profile.equipmentList.createEquipment,
                  element: <PrivateRoute component={CreateEquipmentPage} />,
                },
              ],
            },
            {
              path: Paths.profile.achievements.index,
              children: [
                {
                  path: Paths.profile.achievements.achievement.index,
                  children: [
                    {
                      path: '',
                      element: <PrivateRoute component={AchievementsPage} />,
                    },
                    {
                      path: Paths.profile.achievements.achievement.achievementId.index,
                      children: [
                        { path: '', element: <PrivateRoute component={EditAchievementPage} /> },
                        {
                          path: Paths.profile.achievements.achievement.achievementId.createGoal,
                          element: <PrivateRoute component={CreatGoalPage} />,
                        },
                        {
                          path: Paths.profile.achievements.achievement.achievementId.goal.index,
                          children: [
                            {
                              path: Paths.profile.achievements.achievement.achievementId.goal
                                .goalId,
                              element: <PrivateRoute component={EditGoalPage} />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: Paths.profile.achievements.createAchievement,
                  element: <PrivateRoute component={CreateAchievementPage} />,
                },
              ],
            },
            {
              path: Paths.profile.extended.index,
              children: [
                {
                  path: Paths.profile.extended.extended,
                  element: <PrivateRoute component={EmployeeExtendedDetailsPage} />,
                },
              ],
            },
            {
              path: Paths.profile.privateDocuments.index,
              children: [
                {
                  path: '',
                  element: <PrivateRoute component={DocumentListPage} />,
                },
                {
                  path: Paths.profile.privateDocuments.edit.index,
                  children: [
                    {
                      path: Paths.profile.privateDocuments.edit.documentId,
                      element: <PrivateRoute component={CreateDocumentsPage} />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    {
      path: Paths.auth.index,
      element: <AuthRouteWrapper />,
      children: [
        { path: '', element: <Navigate to={Paths.auth.signIn} /> },
        { path: Paths.auth.signIn, element: <SignInPage /> },

        // Company Registration flow
        {
          path: Paths.auth.registration.index,
          children: [
            { path: '', element: <RegisterCompanyPage /> },
            {
              path: Paths.auth.registration.success,
              element: <RegisterCompanySuccessPage />,
            },
          ],
        },

        // Employee Sign-up flow
        {
          path: Paths.auth.signUp.index,
          children: [
            { path: '', element: <SignUpPage /> },
            {
              path: Paths.auth.signUp.expired,
              element: <SignUpExpiredPage />,
            },
          ],
        },
        { path: Paths.auth.requestPasswordReset, element: <RequestPasswordResetPage /> },
        {
          path: Paths.auth.resetExpired,
          element: <ResetLinkExpiredPage />,
        },
        { path: Paths.auth.createPassword, element: <CreatePasswordPage /> },
        { path: Paths.auth.finishActivation, element: <FinishUserActivationPage /> },
      ],
    },
    {
      path: Paths.confirmAction,
      element: <ConfirmRegistrationPage />,
    },
    { path: '*', element: <NotFound /> },
  ]);

  return <>{element}</>;
}

export default App;
