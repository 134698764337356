import { ReactElement, useLayoutEffect, useState } from 'react';

import { ReactionStats, ReactionTypes } from 'api';
import { useCreatePostReactions } from 'api/mutations/useCreatePostReactions';
import { useDeletePostReaction } from 'api/mutations/useDeletePostReaction';
import { ReactComponent as HeartReaction } from 'assets/icons/postReactions/heart.svg';
import { ReactComponent as LikeReaction } from 'assets/icons/postReactions/like.svg';

import { ReactionInline } from './ReactionInline';
import {
  PostReactions,
  Separator,
  TitleReactions,
  PostHoverReactions,
  ReactionsContainer,
  LikesCounter,
  LikesAuthorView,
  LikesCounterContainer,
  AuthorName,
  Reaction,
  Wrap,
} from './styled';

interface ReactionWrapperProps {
  handleOpenModal: () => void;
  id: string;
  reactions?: ReactionStats;
  isPostDetails?: boolean;
  isPreview?: boolean;
  isAutoPost?: boolean;
}

export function ReactionsWrapper({
  handleOpenModal,
  id,
  reactions,
  isPostDetails,
  isPreview,
  isAutoPost,
}: ReactionWrapperProps): ReactElement {
  const previewLikesAmount = 7;
  const { createPostReaction } = useCreatePostReactions();
  const { deletePostReaction } = useDeletePostReaction();

  const [reaction, setReaction] = useState<ReactionTypes | null>(
    reactions?.active_reaction || null,
  );

  const reactionToDigit = reaction ? 1 : 0;
  const modifier = reactions?.active_reaction ? -1 : 0;

  useLayoutEffect(() => {
    setReaction(reactions?.active_reaction || null);
  }, [reactions?.active_reaction]);

  const reactionCounter = (reactions?.total || 0) + modifier + reactionToDigit;

  return (
    <>
      <Wrap isPostDetails={isPostDetails}>
        {reactions?.total && (
          <LikesCounterContainer>
            <LikesAuthorView
              className="likes-author-view"
              isPostDetails={isPostDetails}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenModal();
              }}
              likesAmount={
                reactions?.total > previewLikesAmount ? previewLikesAmount + 1 : reactions?.total
              }
              isAutoPost={isAutoPost}
            >
              {[...reactions?.preview_names].slice(0, previewLikesAmount).map((name) => {
                return <AuthorName key={name}>{name}</AuthorName>;
              })}
              {reactions?.total > previewLikesAmount && <div>and more</div>}
            </LikesAuthorView>
            <LikesCounter
              onClick={(e) => {
                e.stopPropagation();
                handleOpenModal();
              }}
              isPostDetails={isPostDetails}
            >
              {reactionCounter}
            </LikesCounter>
          </LikesCounterContainer>
        )}

        <Separator isPostDetails={isPostDetails}></Separator>
      </Wrap>

      <PostReactions isPostDetails={isPostDetails}>
        {!isPreview && (
          <ReactionsContainer className="post-hover-reaction">
            <PostHoverReactions className="post-hover-reaction">
              <HeartReaction
                onClick={(e) => {
                  e.stopPropagation();
                  createPostReaction({ postId: id, reaction: ReactionTypes.HEART });
                  setReaction(ReactionTypes.HEART);
                }}
              />
              <LikeReaction
                onClick={(e) => {
                  e.stopPropagation();
                  createPostReaction({ postId: id, reaction: ReactionTypes.LIKE });
                  setReaction(ReactionTypes.LIKE);
                }}
              />
            </PostHoverReactions>
          </ReactionsContainer>
        )}

        <Reaction
          onClick={(e) => {
            e.stopPropagation();
            deletePostReaction({ postId: id });
            setReaction(null);
          }}
        >
          <ReactionInline type={reaction} />
        </Reaction>

        <TitleReactions>Like</TitleReactions>
      </PostReactions>
    </>
  );
}
