import { styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const ContainerW = styled(NavLink)`
  background: ${({ theme }) => theme.palette.white.main};
  border-radius: 15px;
  padding: 24px;
  display: block;
  text-decoration: none;
`;

export const FlexW = styled('div')`
  display: flex;
`;

export const DescriptionW = styled('div')`
  margin-left: 24px;
`;

export const PreviewTitle = styled(NavLink)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  display: block;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;

  @media (max-width: 1400px) {
    display: none;
  }
`;

export const Title = styled(Typography)`
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const SubTitle = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p050};
`;

export const Wrapper = styled('div')`
  width: 376px;
  padding: 24px 16px 16px 16px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  height: 182px;

  @media (max-width: 1400px) {
    width: 100%;
    padding: 0;
    height: unset;
  }
`;
