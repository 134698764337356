import { styled } from '@mui/material';

export const TabsW = styled('div')`
  width: 100%;
  background: #e2e4e6;
  border-radius: 12px;
  padding: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  margin-bottom: 16px;
`;

export const Tab = styled('div')`
  flex: 1;
  text-align: center;
  padding: 6px 8px;
  &.active {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 12px;
  }
`;
