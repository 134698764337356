import { styled } from '@mui/material';

export const HintsW = styled('div')`
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 16px;
  line-height: 18px;

  @media (max-width: 1023px) {
    bottom: -25px;
    left: 0px;
    top: unset;
  }
`;

export const PatternTest = styled('span')<{ isValid: boolean; isSubmitted: boolean }>(
  ({ theme, isSubmitted, isValid }) => ({
    marginLeft: '24px',
    color: isSubmitted ? (isValid ? 'green' : 'orange') : theme.palette.primary.light,
  }),
);
