import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';
import { createLink } from 'utils';

interface UseAttachmentDetailsReturn {
  isLoading: boolean;
  data: string;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<AxiosResponse<Blob, unknown>, unknown>>;
  isRefetching: boolean;
}

interface UseAttachmentDetailsProps {
  postId?: string;
  attachmentId: string;
  fileName: string;
  fileUrl: string;
  enabled?: boolean;
}

export function useAttachmentDetails({
  postId = '',
  attachmentId,
  fileName,
  fileUrl,
  enabled = true,
}: UseAttachmentDetailsProps): UseAttachmentDetailsReturn {
  const apiQuery = () => API_HANDLERS.ATTACHMENTS.DETAILS({ fileUrl });

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['attachment', postId, attachmentId, fileName],
    apiQuery,
    {
      enabled,
    },
  );

  return {
    isLoading,
    data: data?.data ? createLink(data.data) : '',
    refetch,
    isRefetching,
  };
}
