import { styled, Typography } from '@mui/material';

import BackgroundImg from 'assets/images/blurredApp.png';

export const Page = styled('div')`
  min-height: 100vh;
  display: flex;

  /* The image used */
  background-image: url(${BackgroundImg});
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const PageContentW = styled('div')`
  background: ${({ theme }) => theme.palette.white.main};
  width: 50%;
  padding: 85px 125px 0 130px;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 24px 24px 110px;
  }
`;

export const MobWrapper = styled('div')`
  width: 100%;
  @media (max-width: 1023px) {
    max-width: 784px;
    margin: 0px auto;
  }
`;

export const AuthPageTitle = styled(Typography)`
  margin-top: 60px;
  font-weight: 700;
  font-size: 70px;
  line-height: 70px;
  white-space: break-spaces;
  @media (max-width: 1023px) {
    font-size: 36px;
    line-height: 35px;
    margin-top: 36px;
  }
`;
