import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { QUERIES } from 'constants/index';

export function useEditLinkMutation(linkId: string) {
  const { t } = useTranslation();

  const { mutateAsync: editLinkMutation, isLoading } = useMutation(
    API_HANDLERS.QUICK_LINKS.EDIT_LINK,
    {
      onSuccess: () => {
        alert.success(t('toasts.quickLinks.success.edit'));
        Promise.all([
          queryClient.invalidateQueries([QUERIES.QUICK_LINKS_LIST]),
          queryClient.invalidateQueries([QUERIES.QUICK_LINK_DETAILS, linkId]),
        ]);
      },
    },
  );

  return {
    editLinkMutation,
    isLoading,
  };
}
