import { styled, Typography } from '@mui/material';

export const CardW = styled('div')<{ isPreview?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border-radius: 18px;
  box-shadow: 0px 25px 12px -20px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.palette.white.main};
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const DescriptionW = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.p050};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & p {
    display: inline;
    margin: 0;
  }
`;

export const ProgressW = styled('div')`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Value = styled('span')`
  display: inline-block;
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
