import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MaximizeIcon } from 'assets/icons/containerActions/maximize.svg';

import { Calendar, EmptyState } from '../../components';
import { useCalendar } from './hooks';
import { ContainerW, DaysW, CalendarLink, HeaderW, IconButtonW, Title } from './styled';

export function CalendarPreview(): ReactElement {
  const { t } = useTranslation();
  const {
    isFetching,
    events,
    datesRange,
    monthLabel,
    selectedDate,
    setSelectedDate,
    handleGoogleSignIn,
    handleMicrosoftSignIn,
    groupedEvents,
    calendarLink,
  } = useCalendar();

  const datesButtons = datesRange.map((day) => (
    <IconButtonW
      key={day}
      disabled={!events}
      isActive={day === selectedDate}
      onClick={() => setSelectedDate(day)}
    >
      <>{new Date(day).getDate()}</>
    </IconButtonW>
  ));

  return (
    <ContainerW>
      <HeaderW>
        <Title>{t(`months.${monthLabel}`)}</Title>
        <DaysW>{datesButtons}</DaysW>
      </HeaderW>

      {events === null ? (
        <EmptyState
          handleGoogleSignIn={handleGoogleSignIn}
          handleMicrosoftSignIn={handleMicrosoftSignIn}
          isLoading={isFetching}
        />
      ) : (
        <>
          <CalendarLink
            href={!events ? '' : calendarLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MaximizeIcon style={{ opacity: '0.4' }} />
          </CalendarLink>
          <Calendar events={groupedEvents[selectedDate]} selectedDate={selectedDate} />
        </>
      )}
    </ContainerW>
  );
}
