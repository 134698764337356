import { TextareaAutosize } from '@mui/base';
import { styled } from '@mui/material';

export const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  background: theme.palette.white.main,
  color: theme.palette.primary.p080,

  boxShadow: '0px 34px 34px -18px rgba(0, 76, 189, 0.08)',
  borderRadius: '20px',
  width: '100%',
  padding: '30px 20px 30px 40px',
  resize: 'none',
  fontSize: '24px',
  border: 'none',
  fontFamily: theme.typography.fontFamily,
  outline: 'none',
  [theme.breakpoints.down('desktop')]: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    padding: '16px',
  },
}));

export const ErrorText = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
  marginBottom: '10px',
}));
