import { RegisterOptions } from 'react-hook-form';

import { getMaxLengthText, getMinLengthText, REQUIRED, required } from 'validations';

export const companyNameRules: RegisterOptions = {
  ...required,
  minLength: {
    value: 1,
    message: getMinLengthText(1),
  },
  maxLength: {
    value: 40,
    message: getMaxLengthText(40),
  },
  validate: (value) => {
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      return REQUIRED;
    }
  },
};

export const industryRules: RegisterOptions = {
  ...required,
  minLength: {
    value: 1,
    message: getMinLengthText(1),
  },
  maxLength: {
    value: 70,
    message: getMaxLengthText(70),
  },
  validate: (value) => {
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      return REQUIRED;
    }
  },
};

export const companyNumberRules: RegisterOptions = {
  ...required,
  minLength: {
    value: 1,
    message: getMinLengthText(1),
  },
  maxLength: {
    value: 20,
    message: getMaxLengthText(20),
  },
  validate: (value) => {
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      return REQUIRED;
    }
  },
};
