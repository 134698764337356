import { useChangeAvatarMutation } from 'api/mutations/useChangeAvatarMutation';
import { formDataHelper } from 'utils';

export function useProfileCard() {
  const { changeAvatarMutation, isLoading } = useChangeAvatarMutation();

  const handleAvatarFile = (files: File) => {
    const data = formDataHelper(files);
    changeAvatarMutation(data);
  };
  return {
    isLoading,
    handleAvatarFile,
  };
}
