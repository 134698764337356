import { ReactElement } from 'react';

import { QuickLink, QuickLinkFormValues } from 'types';

import { QuickLinkForm } from '../QuickLinkForm';
import { useEditLink } from './useEditLink';

interface EditQuickLinkFormProps {
  defaultValues: QuickLink;
  handleOpenModal: () => void;
}

export function EditQuickLinkForm({
  defaultValues,
  handleOpenModal,
}: EditQuickLinkFormProps): ReactElement {
  const { isLoading, handleSubmit } = useEditLink(defaultValues.id);

  return (
    <QuickLinkForm
      mode="edit"
      handleSubmit={handleSubmit}
      isSubmitting={isLoading}
      defaultValues={defaultValues as QuickLinkFormValues}
      handleOpenModal={handleOpenModal}
    />
  );
}
