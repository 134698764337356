const halfModalWidth = '374px';
const halfPageWidth = '50%';
const marginLeft = '24px';

export const submitBtnStyles = {
  marginLeft: 'auto',
  zIndex: '5',
  top: '182px',
  left: `calc(${halfPageWidth} + ${halfModalWidth} + ${marginLeft})`,
  position: 'sticky',
};

export const submitBtnMobileStyles = {
  marginLeft: 0,
  zIndex: '5',
  bottom: '32px',
  right: '24px',
  position: 'absolute',
};

export const textAreaFieldStyles = {
  '.MuiInputBase-input': {
    fontSize: '24px',
    padding: '24px 37px',
  },
};

export const countryCodeInputStyles = {
  border: 'none',
  fontSize: '24px',
  borderRadius: '60px',
  boxShadow: 'none',
  padding: '28px 14px 28px 70px',
  width: '170px',
  caretColor: 'transparent',
};

export const countryCodeInputMobileStyles = {
  border: 'none',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '24px',
  borderRadius: '60px',
  boxShadow: 'none',
  padding: '21px 14px 18px 60px',
  width: '105px',
  caretColor: 'transparent',
};
