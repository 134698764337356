import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useAuth } from 'hooks';
import { Links } from 'settings';
import { AchievementsTabsValues } from 'types';

import { achievementsTabs } from '../../../constants';

interface LocationI {
  pathname: string;
  state?: { from: string };
}

const TAB_PARAM_KEY = 'tab';

export function useAchievementsTabs() {
  const [activeTab, setActiveTab] = useState<AchievementsTabsValues>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { employeeId } = useParams();
  const { state } = useLocation() as LocationI;
  const { user } = useAuth();
  const navigate = useNavigate();

  const tabParam = searchParams.get(TAB_PARAM_KEY);
  const currentTab = useMemo(
    () => achievementsTabs.find(({ value }) => value === tabParam),
    [tabParam],
  );

  useEffect(() => {
    if (!currentTab) {
      setActiveTab(AchievementsTabsValues.ACTIVE);
    } else if (tabParam && currentTab) {
      setSearchParams(searchParams, { replace: true });
      setActiveTab(currentTab.value);
    } else if (tabParam && !currentTab) {
      searchParams.delete(TAB_PARAM_KEY);
      setSearchParams(searchParams, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const achievementsPath = generatePath(Links.profile.achievements.achievement.index, {
    employeeId: employeeId || user?.id || '',
  });

  const handleChange = useCallback(
    (e: SyntheticEvent, newValue: AchievementsTabsValues) => {
      e.preventDefault();

      const newState = { from: state?.from ?? '' };
      const achievementsTabLink = {
        pathname: achievementsPath,
        search: `?tab=${newValue}`,
      };

      navigate(achievementsTabLink, {
        state: newState,
        replace: true,
      });
      setActiveTab(newValue);
    },
    [navigate, achievementsPath, state?.from],
  );

  return {
    activeTab: activeTab ?? AchievementsTabsValues.ACTIVE,
    handleChange,
  };
}
