import { Notification } from 'types';

interface Dates {
  label: string;
  date: Date;
}

interface GroupByDateReturn {
  groups: Array<Notification[]>;
  labels: string[];
}

export function groupByDate(notifications: Notification[]): GroupByDateReturn {
  const dates: Dates[] = [];
  const groups = notifications.reduce((acc: { [key: string]: Notification[] }, notification) => {
    const date = new Date(notification.created_at);
    const today = new Date();
    const yesterday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1,
    ).toDateString();

    let label;
    if (date.toDateString() === today.toDateString()) {
      label = 'Today';
    } else if (date.toDateString() === yesterday) {
      label = 'Yesterday';
    } else {
      label =
        date.getFullYear() === today.getFullYear()
          ? `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`
          : `${date.getDate()} ${date.toLocaleString('default', {
              month: 'short',
            })} ${date.getFullYear()}`;
    }

    if (!acc[label]) {
      acc[label] = [];
      dates.push({ label, date });
    }
    acc[label].push(notification);
    return acc;
  }, {});
  dates.sort((a, b) => +b.date - +a.date);
  return {
    groups: dates.map((a) => groups[a.label].sort((a, b) => +b.created_at - +a.created_at)),
    labels: dates.map((date) => date.label),
  };
}
