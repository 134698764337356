import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthPageWrapper, AuthPageTitle } from 'features/auth';
import { LanguageSelector } from 'features/languageSelector';

import { CreatePasswordForm } from '../../components';

export function CreatePasswordPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <AuthPageWrapper>
      <AuthPageTitle>{t('createPassword.pageTitle')}</AuthPageTitle>

      <CreatePasswordForm />

      <Box sx={{ marginTop: '24px' }}>
        <LanguageSelector />
      </Box>
    </AuthPageWrapper>
  );
}
