import i18n from 'i18n';
import { TargetValueType } from 'types';

export const VALIDATION_RULES = {
  title: {
    min: 1,
    max: 70,
  },
};

export const TYPE_OPTIONS = [
  { value: TargetValueType.integer, label: i18n.t('kpi.targetForm.options.numbers') },
  { value: TargetValueType.percentage, label: i18n.t('kpi.targetForm.options.percents') },
];
