import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { useCategoriesListQuery, useDocumentsListQuery } from 'api';
import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { GoBackButtonWithTitle, TopMenu } from 'components';
import { DOCUMENTS_PAGE_LIMIT } from 'constants/index';
import { useAuth, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { InstanceType, InstanceLevelType, CategoriesListType } from 'types';

import { DocumentsList } from '../../components';
import { PageW } from '../styled';

type LocationState = {
  tags?: string[];
};

export function PublicDocumentsPage(): ReactElement {
  const params = useParams();
  const { state = {} } = useLocation();
  const tags = (state as LocationState)?.tags;

  const { t } = useTranslation();

  const { isManager, user: currentUser } = useAuth();
  const { isMobile } = useWindowSize();

  const tag = params?.tag || 'private';
  const employeeId = params?.employeeId;
  const isPrivate = tag === 'private';
  const isManagerAndNotCurrentUser = isManager && !!employeeId && currentUser?.id !== employeeId;

  const queryParams = {
    type: InstanceType.document,
    level: [InstanceLevelType.public],
    limit: DOCUMENTS_PAGE_LIMIT,
    tag: tags,
  };

  const { documents, isEmptyList, lastItemRef, isFetchingNextPage, isLoading } =
    useDocumentsListQuery(queryParams);

  const {
    categoryMap,
    isLoading: isCategoryListLoading,
  }: { categoryMap: CategoriesListType; isLoading: boolean } = useCategoriesListQuery();

  return (
    <PageW>
      <TopMenu />
      <GoBackButtonWithTitle
        title={t('publicDocuments.pageTitle')}
        containerSx={{
          marginBottom: isMobile ? '16px' : '24px',
          '.title': {
            fontSize: '24px',
          },
        }}
        Actions={
          <NavLink
            to={Links.we.createDocument}
            state={{ selectPrivate: isPrivate && isManagerAndNotCurrentUser }}
          >
            <IconButton>
              <AddIcon style={{ opacity: '0.4' }} />
            </IconButton>
          </NavLink>
        }
      />
      <DocumentsList
        documents={documents}
        isPrivate={isPrivate}
        isLoading={isLoading || isCategoryListLoading}
        isFetchingNextPage={isFetchingNextPage}
        isEmptyList={isEmptyList}
        lastItemRef={lastItemRef}
        tag={isPrivate ? undefined : tag}
        categoryMap={categoryMap}
      />
    </PageW>
  );
}
