export const avatarGroupStyles = {
  '& .MuiAvatarGroup-avatar': {
    width: '24px',
    height: '24px',
    marginLeft: '-7px',
    fontSize: '12px',
    letterSpacing: '-0.75px',
  },
};

export const avatarWrapperStyles = {
  marginLeft: '-8px',
};
