import { styled } from '@mui/material';

export const WelcomeImage = styled('img')`
  height: 178px;
  width: 100%;
  display: block;
  margin-bottom: 16px;
  @media (max-width: 1023px) {
    height: 140px;
  }
`;

export const WelcomeDescription = styled('div')`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
