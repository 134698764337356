import { styled } from '@mui/material';

export const DescriptionW = styled('div', {
  shouldForwardProp: (propname) => propname !== 'isPreview',
})<{ isPreview: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.primary.p080};
  width: 90%;
`;

export const StrongW = styled('strong')`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
`;

export const MainContentW = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentW = styled('div')<{ isAttachment?: boolean; isPreview: boolean }>`
  p {
    margin: 5px 0px 8px 0;
    word-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    max-width: ${({ isAttachment }) => isAttachment && '400px'};

    overflow: ${({ isPreview }) => isPreview && 'hidden'};
    display: ${({ isPreview }) => isPreview && '-webkit-box'};
    -webkit-line-clamp: ${({ isPreview }) => isPreview && '2'};
    -webkit-box-orient: ${({ isPreview }) => isPreview && 'vertical'};

    @media (max-width: 500px) {
      -webkit-line-clamp: ${({ isPreview }) => isPreview && '4'};
    }
  }
`;

export const ListW = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
`;

export const TitleW = styled('span')`
  margin-left: 9px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;
