import Popover from '@mui/material/Popover';
import { ReactElement, useEffect, useState } from 'react';

import { useNotificationsQuery } from 'api';
import { useNewNotifications } from 'api/queries/useNewNotifications';
import { ReactComponent as BellIcon } from 'assets/icons/notificationBell.svg';
import { Spinner } from 'components';
import { NOTIFICATIONS_LIMIT } from 'constants/index';
import { useNotificationBadge } from 'features/notifications/hooks/useNotificationBadge';
import { Notification } from 'types';

import { PreviewEmptyState, PreviewNotificationList } from '../../components';
import { BadgeW, StyledBtn, Wrapper } from './styled';
import { popoverSx } from './styles';

export function NotificationPreview(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    isEmptyList,
    isLoading,
    notifications: oldNotifications,
    lastItemRef,
    lastItemId,
    refetch,
  } = useNotificationsQuery({
    limit: NOTIFICATIONS_LIMIT,
  });

  const notifications: Notification[] = [...oldNotifications];
  const { amount, handleNewNotification, markAsRead } = useNotificationBadge();

  useNewNotifications({ onNewNotification: handleNewNotification });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    refetch();
  }, [amount, refetch]);

  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;

  return (
    <Wrapper>
      <StyledBtn onClick={handleClick}>
        <BadgeW badgeContent=" " invisible={!amount} overlap="circular">
          <BellIcon />
        </BadgeW>
      </StyledBtn>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={popoverSx}
      >
        {isLoading && <Spinner />}
        {isEmptyList ? (
          <PreviewEmptyState isPreview />
        ) : (
          <PreviewNotificationList
            notifications={notifications}
            lastItemRef={lastItemRef}
            lastItemId={lastItemId}
            markAsRead={markAsRead}
          />
        )}
      </Popover>
    </Wrapper>
  );
}
