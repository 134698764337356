import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { useDocumentsListQuery } from 'api';
import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { ReactComponent as MaximizeIcon } from 'assets/icons/containerActions/maximize.svg';
import {
  BlockWrapper,
  ImageGallery,
  ListItemDocumentAttachment,
  ListItemImageAttachment,
  VideoAttachment,
  VideoGallery,
} from 'components';
import { DOCUMENTS_PAGE_LIMIT } from 'constants/index';
import { useAuth, useModal, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { InstanceLevelType, InstanceType } from 'types';
import { getCoreRoute } from 'utils';

import { EmptyStateTitle, AttachmentsWrapper } from './styled';
import {
  imagesWrapperStyles,
  BlockWrapperStyles,
  BlockWrapperMobileStyles,
  imagesWrapperMobileStyles,
} from './styles';
import { getLastAttachments } from './utils';

interface PrivateDocumentsProps {
  employeeId: string;
}

export const PrivateDocuments = ({ employeeId }: PrivateDocumentsProps) => {
  const { isManager, user: currentUser } = useAuth();
  const { isOpen, handleOpenModal, handleCloseModal } = useModal();
  const {
    isOpen: isVideoOpen,
    handleOpenModal: handleOpenVideoModal,
    handleCloseModal: handleCloseVideoModal,
  } = useModal();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const isManagerAndNotCurrentUser = isManager && currentUser?.id !== employeeId;
  const { documents } = useDocumentsListQuery({
    type: InstanceType.document,
    level: isManagerAndNotCurrentUser
      ? [InstanceLevelType.shared]
      : [InstanceLevelType.shared, InstanceLevelType.private],
    assigned: isManagerAndNotCurrentUser ? employeeId : '',
    limit: DOCUMENTS_PAGE_LIMIT,
  });
  const coreRoute = getCoreRoute(location);

  const onNavigateToCreateDocument = () => {
    navigate(
      isManagerAndNotCurrentUser
        ? generatePath(Links.we.employees.employee.createDocument, {
            employeeId,
          })
        : Links.profile.createDocument,
      {
        state: {
          selectPrivate: isManagerAndNotCurrentUser,
          assignedToId: isManagerAndNotCurrentUser ? employeeId : '',
        },
      },
    );
  };

  const onNavigateToPrivateDocumentList = () => {
    navigate(
      generatePath(
        coreRoute === 'profile'
          ? Links.profile.privateDocuments.index
          : Links.we.employees.employee.privateDocuments,
        {
          employeeId,
        },
      ),
      {
        state: {
          tag: 'private',
          employeeId: employeeId,
        },
      },
    );
  };
  const isShowAddButton = (isManager || employeeId === currentUser?.id) && !isMobile;
  const lastAttachments = getLastAttachments(documents, isMobile);
  const lastAttachmentsImages =
    lastAttachments.filter((attachment) => attachment.type === 'image') || [];
  const lastAttachmentsVideos =
    lastAttachments.filter((attachment) => attachment.type === 'video') || [];
  return (
    <BlockWrapper
      wrapperSx={isMobile ? BlockWrapperMobileStyles : BlockWrapperStyles}
      title={t('profile.privateDocuments.title')}
      Actions={
        <>
          {isShowAddButton && (
            <IconButton onClick={onNavigateToCreateDocument}>
              <AddIcon style={{ opacity: '0.4' }} />
            </IconButton>
          )}

          <IconButton onClick={onNavigateToPrivateDocumentList}>
            <MaximizeIcon style={{ opacity: '0.4' }} />
          </IconButton>
        </>
      }
    >
      {lastAttachments.length ? (
        <AttachmentsWrapper>
          {lastAttachments.map((att) =>
            att.type === 'image' ? (
              <ListItemImageAttachment
                key={att.id}
                attachmentId={att.id}
                fileName={att.file.small}
                fileUrl={att.file_url?.small}
                postId={att.postId}
                wrapperSx={isMobile ? imagesWrapperMobileStyles : imagesWrapperStyles}
                onOpenGallery={handleOpenModal}
              />
            ) : att.type === 'video' ? (
              <VideoAttachment
                attachmentId={att.file.video_uid}
                wrapperSx={{ height: '140px', width: '140px' }}
                onOpenGallery={handleOpenVideoModal}
              />
            ) : (
              <ListItemDocumentAttachment
                key={att.id}
                postId={att.postId}
                documentId={att.id}
                fileName={att.file.original}
                fileUrl={att.file_url?.original}
                wrapperSx={isMobile ? imagesWrapperMobileStyles : imagesWrapperStyles}
              />
            ),
          )}
        </AttachmentsWrapper>
      ) : (
        <EmptyStateTitle>{t('profile.privateDocuments.emptyState')}</EmptyStateTitle>
      )}
      <ImageGallery open={isOpen} onClose={handleCloseModal} imageList={lastAttachmentsImages} />
      <VideoGallery
        open={isVideoOpen}
        onClose={handleCloseVideoModal}
        videoList={lastAttachmentsVideos}
      />
    </BlockWrapper>
  );
};
