import { ReactComponent as AddSquareActiveIcon } from 'assets/icons/navbar/addSquare.svg';
import { ReactComponent as ResourcesActiveIcon } from 'assets/icons/navbar/briefcase.svg';
import { ReactComponent as PeopleActiveIcon } from 'assets/icons/navbar/people.svg';
import { Avatar } from 'components';
import i18n from 'i18n';
import { Links } from 'settings';

export const MENU_ITEMS = (userId: string, filename: string) => {
  return {
    ACTIONS: {
      path: Links.actions.index,
      label: i18n.t('sidebar.actions'),
      icon: <AddSquareActiveIcon />,
    },
    WE: {
      path: Links.we.index,
      label: i18n.t('sidebar.we'),
      icon: <PeopleActiveIcon />,
    },
    ME: {
      path: Links.profile.index,
      label: i18n.t('sidebar.me'),
      icon: <Avatar userId={userId} filename={filename} size="xs" withNavigation={false} />,
    },
    RESOURCES: {
      path: Links.resources.index,
      label: i18n.t('sidebar.resources'),
      icon: <ResourcesActiveIcon />,
    },
  };
};
