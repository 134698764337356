import axios from 'axios';
import { useState } from 'react';

import { useUploadAttachment } from 'api/mutations/useUploadAttachment';
import { useVideoUploadAttachment } from 'api/mutations/useVideoUploadAttachment';
import { Attachment, FormDocumentsKeyValue } from 'types';
import { prepareFilesBeforeUpload } from 'utils';
interface UseFormAttachmentsProps {
  attachedImages: Attachment[];
  attachedDocs: Attachment[];
  attachedVideos: Attachment[];
  newVideos?: File[];
  setFormValue: (key: FormDocumentsKeyValue['key'], value: FormDocumentsKeyValue['value']) => void;
}

export function useFormAttachments({
  attachedImages,
  attachedDocs,
  attachedVideos,
  setFormValue,
  newVideos,
}: UseFormAttachmentsProps) {
  const { uploadAttachmentMutation, isLoading: isLoadingFileUpload } = useUploadAttachment();
  const { uploadVideoAttachmentMutation, isLoading: isLoadingVideoUpload } =
    useVideoUploadAttachment();

  const attachmentsCount = attachedImages.length + attachedDocs.length + attachedVideos.length;
  const [isLoadingVideoGetURL, setIsLoadingVideoGetURL] = useState(false);
  const [progress, setProgress] = useState(0);

  const postData = {
    maxDurationSeconds: 3600,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEventListener('fetch', (event: any) => {
    event.respondWith(handleRequest(event.request));
  });

  async function handleRequest(request: Request): Promise<Response> {
    const url = 'https://api.cloudflare.com' + request.url;
    const response = await fetch(url, request);
    return response;
  }

  async function handleVideoUploadURLFetch() {
    try {
      const getURL = await fetch(process.env.REACT_APP_CLOUDFLARE_URL as RequestInfo, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CLOUDFLARE_TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (!getURL.ok) {
        return new Response('Failed to retrieve upload URL', { status: 500 });
      }

      // Extract the upload URL from the response
      const { result } = await getURL.json();
      const videoUploadURL = result.uploadURL;

      return videoUploadURL;
    } catch (error) {
      return;
    }
  }

  async function handleUploadMutation(file: File) {
    const data = new FormData();
    data.append('file', file);
    const response = await uploadAttachmentMutation({ file: data });
    return response.data;
  }

  async function handleVideoUploadMutation({
    videoId,
    fileExtension,
  }: {
    videoId: string;
    fileExtension: string;
  }) {
    const response = await uploadVideoAttachmentMutation({
      video_id: videoId,
      file_extension: fileExtension,
    });
    return response.data;
  }

  async function handleAddImages(files: FileList) {
    const filesToAdd = prepareFilesBeforeUpload(attachmentsCount, files);

    if (filesToAdd) {
      filesToAdd.forEach(async (file) => {
        const newImage = await handleUploadMutation(file);

        if (newImage) {
          attachedImages.push(newImage);

          setFormValue('images', attachedImages);
        }
      });
    }
  }

  async function handleAddVideos(files: FileList) {
    const filesToAdd = prepareFilesBeforeUpload(attachmentsCount, files);

    if (filesToAdd) {
      filesToAdd.forEach(async (file) => {
        newVideos?.push(file);
        setIsLoadingVideoGetURL(true);
        const videoUploadURL: string = await handleVideoUploadURLFetch();

        const uploadedVideoURLAarray = videoUploadURL?.split('/');
        const videoId = uploadedVideoURLAarray[uploadedVideoURLAarray.length - 1];

        const data = new FormData();
        data.append('file', file);

        await axios
          .post(videoUploadURL, data, {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const precentage = Math.floor((loaded * 100) / total);
              setProgress(precentage);
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => setProgress(0));

        // const uploadResponse = await fetch(videoUploadURL, {
        //   method: 'POST',
        //   body: data,
        // });

        const newVideo = await handleVideoUploadMutation({
          videoId: videoId,
          fileExtension: file.type,
        });
        setIsLoadingVideoGetURL(false);

        if (newVideo) {
          attachedVideos.push(newVideo);

          setFormValue('videos', attachedVideos);
        }
      });
    }
  }

  async function handleAddDocuments(files: FileList) {
    const filesToAdd = prepareFilesBeforeUpload(attachmentsCount, files);

    if (filesToAdd) {
      filesToAdd.forEach(async (file) => {
        const newDoc = await handleUploadMutation(file);

        if (newDoc) {
          attachedDocs.push(newDoc);
          setFormValue('docs', attachedDocs);
        }
      });
    }
  }

  function handleDeleteAttachment(
    attachmentId: string,
    type: 'images' | 'docs' | 'videos' | 'videos',
  ) {
    if (type === 'images') {
      const newImagesList = attachedImages.filter((image) => image.id !== attachmentId);
      setFormValue('images', newImagesList);
    }

    if (type === 'docs') {
      const newDocsList = attachedDocs.filter((document) => document.id !== attachmentId);
      setFormValue('docs', newDocsList);
    }

    if (type === 'videos') {
      const newVideoList = attachedVideos.filter((video) => video.id !== attachmentId);
      setFormValue('videos', newVideoList);
    }
  }

  return {
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    progress,
    isLoadingFileUpload: isLoadingFileUpload || isLoadingVideoGetURL || isLoadingVideoUpload,
  };
}
