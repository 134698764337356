import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';

import { CreateCategoryRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import * as ERROR_CODES from 'constants/errorCodes';
import { ApiErrors } from 'types';

interface UseCreateCategoryMutationReturn {
  createCategoryMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    CreateCategoryRequestParams,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
}

interface UseCreateCategoryMutationProps {
  successText: string;
  setIsSuccess?: Dispatch<SetStateAction<boolean>>;
  invalidateQueries?: string[];
}

export function useCreateCategoryMutation({
  successText,
  setIsSuccess,
  invalidateQueries,
}: UseCreateCategoryMutationProps): UseCreateCategoryMutationReturn {
  const {
    mutateAsync: createCategoryMutation,
    isLoading,
    isSuccess,
  } = useMutation(API_HANDLERS.CATEGORY.CREATE, {
    onSuccess: () => {
      setIsSuccess && setIsSuccess(true);
      alert.success(successText);

      if (invalidateQueries) {
        queryClient.invalidateQueries(invalidateQueries);
      }
    },
    onError: (error: AxiosError<ApiErrors>) => {
      if (error.response?.data.errors[0].code === ERROR_CODES.INVALID_REQUEST) {
        alert.error(error.response?.data.errors[0].description);
      }
    },
  });

  return {
    createCategoryMutation,
    isLoading,
    isSuccess,
  };
}
