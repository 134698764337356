import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageHeaderBoldTitle, PageWrapper } from 'components';
import { useAuth } from 'hooks';

import { CreateTargetForm } from '../../components';

export function CreateTargetPage(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isManager } = useAuth();

  useEffect(() => {
    if (!isManager) {
      navigate(-1);
    }
  }, [isManager, navigate]);

  return (
    <PageWrapper>
      <PageHeaderBoldTitle title={t('kpi.targetForm.createTitle')} />
      <CreateTargetForm />
    </PageWrapper>
  );
}
