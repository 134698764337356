import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { COMPANY_NAME_IS_USED } from 'constants/errorCodes';
import { ApiErrors, CompanyInfoFormValues } from 'types';

interface UseCompanyProfileFormProps {
  defaultValues: CompanyInfoFormValues;
  storeValues: (values: CompanyInfoFormValues) => void;
  handleRegisterSubmit: () => void;
  errors?: Record<string, ApiErrors['errors']> | null;
}

export function useCompanyProfileForm({
  defaultValues,
  storeValues,
  handleRegisterSubmit,
  errors,
}: UseCompanyProfileFormProps) {
  const { handleSubmit, control, watch, setError } = useForm<CompanyInfoFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const companyName = watch('companyName');
  const industry = watch('industry');
  const companySize = watch('companySize');
  const number = watch('number');

  useEffect(() => {
    storeValues({
      companyName,
      industry,
      companySize,
      number,
    });
  }, [companyName, companySize, industry, storeValues, number]);

  useEffect(() => {
    if (errors && errors[COMPANY_NAME_IS_USED]) {
      setError('companyName', { message: '' });
    }
  }, [errors, setError]);

  const handleFormSubmit = handleSubmit(() => {
    handleRegisterSubmit();
  });

  return { handleFormSubmit, control };
}
