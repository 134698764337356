import { useQuery } from '@tanstack/react-query';
import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { API_HANDLERS } from 'api/apiHandlers';
import { SWEDEN_COUNTRY_CODE } from 'constants/index';
import { useAuth } from 'hooks';
import { StripeProduct, SubscriptionsPeriod } from 'types';

import { defaultValues } from './constants';
import { formatProducts } from './utils';

interface UseCalculationProps {
  handleClose: () => void;
}

export function useCalculation({ handleClose }: UseCalculationProps) {
  const [products, setProducts] = useState<null | Record<string, StripeProduct>>(null);

  const { control, reset, watch, trigger } = useForm({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { location } = useAuth();
  const { t } = useTranslation();

  const productName = watch('productName');
  const employeeCount = watch('employeeCount');
  const frequency = watch('frequency');

  useQuery(
    ['calculator-products', frequency, productName],
    () =>
      API_HANDLERS.PRODUCTS.GET_LIST({
        period: frequency as SubscriptionsPeriod,
        currency: !location || location === SWEDEN_COUNTRY_CODE ? 'sek' : 'eur',
      }),
    {
      enabled: !!frequency && !!location && !!productName,
      onSuccess: (data) => {
        const groupedProducts = formatProducts(frequency, data.data);
        setProducts(groupedProducts);
      },
    },
  );

  const currency = !location || location === SWEDEN_COUNTRY_CODE ? 'sek' : 'eur';

  const totalPrice = useMemo(() => {
    if (products && productName && employeeCount && frequency) {
      const MAIN_PRICE = products[productName].price.price;
      const EXTRA_PRICE_ITEM = products[`${productName}-EXTRA`].price.price;
      const extraEmployeeCount = +employeeCount > 20 ? +employeeCount - 20 : 0;
      const totalExtraPrice = extraEmployeeCount > 0 ? EXTRA_PRICE_ITEM * extraEmployeeCount : 0;
      const total = MAIN_PRICE + totalExtraPrice;

      return `${currency.toUpperCase()} ${total}/${t(`subscriptions.${frequency}`)}`;
    }

    return '0';
  }, [currency, employeeCount, frequency, productName, products, t]);

  function handlerFormClose() {
    reset();
    handleClose();
  }

  return {
    control,
    handlerFormClose,
    totalPrice,
    trigger,
  };
}
