import { TabsW, Tab } from './styled';

type Props = {
  slideTo: (index: number) => void;
  activeIndex?: number;
  tabs: {
    label: string;
    slideIndex: number;
  }[];
};

export const TasksTabs = ({ slideTo, activeIndex, tabs }: Props) => {
  return (
    <TabsW>
      {tabs.map((tab) => (
        <Tab
          key={tab.slideIndex}
          className={tab.slideIndex === activeIndex ? 'active' : ''}
          onClick={() => slideTo(tab.slideIndex)}
        >
          {tab.label}
        </Tab>
      ))}
    </TabsW>
  );
};
