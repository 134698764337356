import { ReactElement, ReactNode } from 'react';

import { alert } from 'components/Alerts';
import { MAX_AVATAR_SIZE } from 'features/employeeDetails/components/ProfileCard/constants';
import { fileSizeConverter } from 'utils';
import { toLargeFile } from 'validations';

interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  acceptFormats: string;
  multiple: boolean;
  children: ReactNode;
  handleFile: (file: FileList) => void;
}

export const UploadFile = ({
  multiple,
  acceptFormats,
  children,
  handleFile,
  style,
  id = 'file-upload',
  ...otherProps
}: Props): ReactElement => {
  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const sizeInMB = fileSizeConverter(e.currentTarget.files[0].size);

      if (sizeInMB > MAX_AVATAR_SIZE) {
        alert.error(toLargeFile(15));
        e.currentTarget.value = '';
        return;
      }
      handleFile(e.currentTarget.files);
    }
  };

  function onInputClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    (event.target as HTMLInputElement).value = '';
  }

  return (
    <>
      <label htmlFor={id} style={{ cursor: 'pointer', position: 'relative', ...style }}>
        {children}
      </label>
      <input
        multiple={multiple}
        id={id}
        type="file"
        hidden
        accept={acceptFormats}
        onChange={onChangeFile}
        onClick={onInputClick}
        {...otherProps}
      />
    </>
  );
};
