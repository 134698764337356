import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { DialogProps } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BaseTextFieldController,
  CountryCodeSelect,
  DatePicker,
  Form,
  FormGroup,
  SubmitRoundedButton,
  SelectController,
  ConfirmDialog,
  FullPageProgress,
} from 'components';
import { countries, FIELDS_VALUE_LENGTH } from 'constants/index';
import { useWindowSize } from 'hooks';
import { textFieldMobileStyles, textFieldStyles } from 'styles';
import { UserExtendedDetails } from 'types';
import { stripMultipleSpaces, stripNonDigits } from 'utils';
import { required } from 'validations';

import { CLOTHES_SIZE_OPTIONS, EDIT_FORM_FIELDS_LENGTH, RELATION_OPTIONS } from './constants';
import { useEditEmployeeForm } from './hooks';
import { phoneRules, relationPhoneRules, ssnRules } from './rules';
import { DialogTitle, GroupTitle, ModalContent, ModalOverlay, PhoneInputW } from './styled';
import {
  countryCodeInputStyles,
  submitBtnStyles,
  textAreaFieldStyles,
  submitBtnMobileStyles,
  countryCodeInputMobileStyles,
} from './stylesConfig';

interface EditEmployeeFormProps extends DialogProps {
  onClose: () => void;
  employee: UserExtendedDetails;
  refetchUserDetails?: () => void;
  formTitle: string;
  mode?: 'edit' | 'activate';
}

export function EditEmployeeForm({
  onClose,
  employee,
  refetchUserDetails,
  mode = 'edit',
  formTitle,
}: EditEmployeeFormProps): ReactElement {
  const { t } = useTranslation();
  const {
    control,
    handleFormSubmit,
    isLoading,
    isOpenConfirmModal,
    handleTitleClick,
    handleCloseForm,
    handleCloseConfirmModal,
  } = useEditEmployeeForm({ employee, onClose, refetchUserDetails, mode });

  const { isMobile } = useWindowSize();
  const formGroupMarginBottom = isMobile ? 8 : 24;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;

  return (
    <>
      <ModalOverlay>
        <SubmitRoundedButton
          style={{
            ...(isMobile ? submitBtnMobileStyles : submitBtnStyles),
            position: isMobile ? 'fixed' : 'sticky',
          }}
          onClick={handleFormSubmit}
          disabled={isLoading}
        />
        {isMobile && (
          <DialogTitle onClick={handleTitleClick}>
            <KeyboardBackspaceRoundedIcon fontSize="large" />
            {formTitle}
          </DialogTitle>
        )}
        <ModalContent>
          {!isMobile && (
            <DialogTitle onClick={handleTitleClick}>
              <KeyboardBackspaceRoundedIcon fontSize="large" />
              {formTitle}
            </DialogTitle>
          )}

          <Form onSubmit={handleFormSubmit}>
            <GroupTitle>{t('profile.edit.form.publicInfo.title')}</GroupTitle>
            <FormGroup
              marginBottom={formGroupMarginBottom}
              row
              sx={{ flexDirection: isMobile ? 'column' : 'row' }}
            >
              <BaseTextFieldController
                name="firstName"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.firstName')}
                sx={{
                  width: isMobile ? '100%' : '49%',
                  ...fieldStyles,
                  marginBottom: isMobile ? '8px' : 0,
                }}
                maxLength={FIELDS_VALUE_LENGTH.firstName.max}
                format={stripMultipleSpaces}
                preventSpaceOnStart
                rules={required}
              />
              <BaseTextFieldController
                name="lastName"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.lastName')}
                sx={{ width: isMobile ? '100%' : '49%', ...fieldStyles }}
                maxLength={FIELDS_VALUE_LENGTH.lastName.max}
                format={stripMultipleSpaces}
                preventSpaceOnStart
                rules={required}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom} row>
              <BaseTextFieldController
                name="email"
                control={control}
                disabled
                placeholder={t('profile.edit.form.publicInfo.placeholders.email')}
                sx={{ ...fieldStyles, opacity: '0.5' }}
                maxLength={FIELDS_VALUE_LENGTH.email.max}
                rules={required}
              />
            </FormGroup>
            <FormGroup
              marginBottom={isMobile ? 12 : 24}
              row
              sx={{ columnGap: isMobile ? '8px' : '20px' }}
            >
              <CountryCodeSelect
                control={control}
                name="countryCode"
                containerStyle={{ width: isMobile ? '105px' : '170px' }}
                inputStyle={isMobile ? countryCodeInputMobileStyles : countryCodeInputStyles}
                buttonStyle={{
                  left: '10px',
                }}
              />
              <PhoneInputW>
                <BaseTextFieldController
                  name="phone"
                  control={control}
                  placeholder={t('profile.edit.form.publicInfo.placeholders.phone')}
                  hint={t('common.phoneHint')}
                  sx={fieldStyles}
                  format={stripNonDigits}
                  maxLength={FIELDS_VALUE_LENGTH.phone.max}
                  rules={phoneRules}
                />
              </PhoneInputW>
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <DatePicker
                name="birthDate"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.dateFormat')}
                sx={fieldStyles}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="department"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.companyDep')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.companyDepartment.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="position"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.companyPos')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.position.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="address1"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.address1')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.address1.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="address2"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.address2')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.address2.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="city"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.city')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.city.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="region"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.state')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.province.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="postCode"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.postcode')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.postCode.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="country"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.country')}
                options={countries}
                withDefaultEmptyOption
              />
            </FormGroup>

            <GroupTitle sx={{ marginTop: isMobile ? '8px' : 0 }}>
              {t('profile.edit.form.privateInfo.title')}
            </GroupTitle>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="ssn"
                control={control}
                placeholder={t('profile.edit.form.privateInfo.placeholders.ssn')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.ssn.max}
                rules={ssnRules}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <DatePicker
                name="employmentDate"
                control={control}
                placeholder={t('profile.edit.form.privateInfo.placeholders.employmentDate')}
                sx={fieldStyles}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="employmentNumber"
                control={control}
                placeholder={t('profile.edit.form.privateInfo.placeholders.employmentNumber')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.employmentNumber.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="education"
                control={control}
                placeholder={t('profile.edit.form.privateInfo.placeholders.education')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.education.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="clothesSize"
                control={control}
                placeholder={t('profile.edit.form.privateInfo.placeholders.sizeOfClothes')}
                options={CLOTHES_SIZE_OPTIONS}
                withDefaultEmptyOption
              />
            </FormGroup>

            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="allergies"
                control={control}
                placeholder={t('profile.edit.form.privateInfo.placeholders.allergies')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.allergies.max}
              />
            </FormGroup>

            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="comments"
                control={control}
                placeholder={t('profile.edit.form.privateInfo.placeholders.comment')}
                sx={isMobile ? textFieldMobileStyles : textAreaFieldStyles}
                multiline
                maxLength={EDIT_FORM_FIELDS_LENGTH.comments.max}
              />
            </FormGroup>

            <GroupTitle sx={{ marginTop: isMobile ? '8px' : 0 }}>
              {t('profile.edit.form.emergency.title')}
            </GroupTitle>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="relation"
                control={control}
                placeholder={t('profile.edit.form.emergency.placeholders.relation')}
                options={RELATION_OPTIONS}
                withDefaultEmptyOption
              />
            </FormGroup>
            <FormGroup
              marginBottom={formGroupMarginBottom}
              row
              sx={{ flexDirection: isMobile ? 'column' : 'row' }}
            >
              <BaseTextFieldController
                name="relationContactFirstName"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.firstName')}
                sx={{
                  width: isMobile ? '100%' : '49%',
                  ...fieldStyles,
                  marginBottom: isMobile ? '8px' : 0,
                }}
                maxLength={FIELDS_VALUE_LENGTH.firstName.max}
                format={stripMultipleSpaces}
                preventSpaceOnStart
              />
              <BaseTextFieldController
                name="relationContactLastName"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.lastName')}
                sx={{ width: isMobile ? '100%' : '49%', ...fieldStyles }}
                maxLength={FIELDS_VALUE_LENGTH.lastName.max}
                format={stripMultipleSpaces}
                preventSpaceOnStart
              />
            </FormGroup>
            <FormGroup
              marginBottom={formGroupMarginBottom}
              row
              sx={{ columnGap: isMobile ? '8px' : '20px' }}
            >
              <CountryCodeSelect
                control={control}
                name="relationCountryCode"
                containerStyle={{ width: isMobile ? '105px' : '170px' }}
                inputStyle={isMobile ? countryCodeInputMobileStyles : countryCodeInputStyles}
                buttonStyle={{
                  left: '10px',
                }}
              />
              <BaseTextFieldController
                name="relationContactPhone"
                control={control}
                placeholder={t('profile.edit.form.publicInfo.placeholders.phone')}
                sx={{ flex: 1, ...fieldStyles }}
                format={stripNonDigits}
                maxLength={FIELDS_VALUE_LENGTH.phone.max}
                rules={relationPhoneRules}
              />
            </FormGroup>
          </Form>
        </ModalContent>
      </ModalOverlay>

      <ConfirmDialog
        title={t('profile.edit.modal.title')}
        subTitle={t('profile.edit.modal.exit')}
        open={isOpenConfirmModal}
        onSubmit={handleCloseForm}
        onClose={handleCloseConfirmModal}
      />

      {isLoading && <FullPageProgress />}
    </>
  );
}
