import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { Box, DialogProps } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseTextFieldController, FormGroup, SelectController } from 'components';
import { useWindowSize } from 'hooks';
import { textFieldMobileStyles, textFieldStyles } from 'styles';
import { stripNonDigits } from 'utils';
import { required } from 'validations';

import { FREQUENCY_OPTIONS, PLANS_OPTIONS } from './constants';
import { CloseButton, Dialog, TotalCountW } from './styled';
import { useCalculation } from './useCalculation';

interface CalculationDialogProps extends DialogProps {
  handleClose: () => void;
}

export function CalculationDialog({ handleClose, open }: CalculationDialogProps): ReactElement {
  const { t } = useTranslation();

  const { control, handlerFormClose, totalPrice, trigger } = useCalculation({
    handleClose,
  });

  const { isMobile } = useWindowSize();

  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;
  const formGroupMarginBottom = isMobile ? 8 : 24;

  return (
    <Dialog open={open}>
      <div>
        <CloseButton
          variant="text"
          startIcon={<KeyboardBackspaceRoundedIcon fontSize="large" />}
          onClick={handlerFormClose}
        >
          {t('calculator.modal.title')}
        </CloseButton>
      </div>

      <Box sx={{ marginLeft: isMobile ? '0px' : '24px' }}>
        <FormGroup
          marginBottom={formGroupMarginBottom}
          row
          sx={{ columnGap: isMobile ? '8px' : '20px' }}
        >
          <SelectController
            control={control}
            name="productName"
            options={PLANS_OPTIONS}
            placeholder={t('calculator.modal.fields.plan.placeholder')}
            sx={fieldStyles}
          />
        </FormGroup>

        <FormGroup
          marginBottom={formGroupMarginBottom}
          row
          sx={{ columnGap: isMobile ? '8px' : '20px' }}
        >
          <SelectController
            control={control}
            name="frequency"
            options={FREQUENCY_OPTIONS}
            placeholder={t('calculator.modal.fields.frequency.placeholder')}
            sx={fieldStyles}
          />
        </FormGroup>

        <BaseTextFieldController
          name="employeeCount"
          control={control}
          placeholder={t('calculator.modal.fields.employeeCount.placeholder')}
          sx={{ flex: 1, ...fieldStyles }}
          format={stripNonDigits}
          maxLength={6}
          rules={required}
          onChange={() => trigger()}
        />

        <TotalCountW>
          {t('calculator.modal.total')}: {totalPrice}
        </TotalCountW>
      </Box>
    </Dialog>
  );
}
