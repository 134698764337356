import { ReactElement } from 'react';

import { useCurrentSubscriptionQuery } from 'api';
import { FreeTrialNotification, MockedDataNotification } from 'features/freeTrial';
import { useSubscription } from 'hooks';

import { BannersW } from './styled';

export function Banners(): ReactElement {
  const { isVisibleTrialNotification, isVisibleMockDataBanner, isTrial } = useSubscription();
  const { currentSubscriptions } = useCurrentSubscriptionQuery();

  return (
    <>
      {isTrial && (isVisibleTrialNotification || isVisibleMockDataBanner) && (
        <BannersW>
          {isVisibleTrialNotification && (
            <FreeTrialNotification expiresAt={currentSubscriptions?.expires_at} />
          )}
          {isVisibleMockDataBanner && <MockedDataNotification />}
        </BannersW>
      )}
    </>
  );
}
