import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useEditEquipmentMutation, useEquipmentDetailsQuery } from 'api';
import { EquipmentFormValues } from 'types';

import { formValuesAdapter } from '../../RegisterEquipmentForm/adapter';
import { defaultValuesAdapter } from '../adapter';

export function useEditEquipmentForm() {
  const { t } = useTranslation();
  const { equipmentId, employeeId } = useParams();

  const {
    data,
    isLoading: isDetailsLoading,
    isSuccess: isDetailsLoaded,
  } = useEquipmentDetailsQuery({
    userId: employeeId ?? '',
    equipmentId: equipmentId ?? '',
  });

  const { editEquipmentMutation, isLoading: isEditEquipmentLoading } = useEditEquipmentMutation({
    invalidateQueries: ['equipment'],
    successText: t('toasts.equipments.success.edit'),
  });

  const handleSubmit = useCallback(
    async (data: EquipmentFormValues, attachmentIds: string[]) => {
      await editEquipmentMutation({
        data: {
          ...formValuesAdapter(data),
          ...(!!attachmentIds.length && { attachment_ids: attachmentIds }),
        },
        userId: employeeId ?? '',
        equipmentId: equipmentId ?? '',
      });
    },
    [editEquipmentMutation, employeeId, equipmentId],
  );

  const defaultValues = (isDefaultEditable: boolean) =>
    defaultValuesAdapter({ data, isDefaultEditable });

  return {
    isDetailsLoading,
    isDetailsLoaded,
    isEditEquipmentLoading,
    defaultValues,
    handleSubmit,
  };
}
