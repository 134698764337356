import { ReactElement, ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { CUSTOM_LINK_ICONS } from 'constants/index';
import { useAuth } from 'hooks';
import { Links } from 'settings';
import { QuickLink as IQuickLink, QuickLinkAccess } from 'types';

import { LINKS_ICONS } from '../../constants';
import { CardW, EditIconW, IconW, RowW, SubtitleW, TitleW } from './styled';

interface QuickLinkProps extends IQuickLink {
  children?: ReactNode;
  isEditable?: boolean;
  isDragging?: boolean;
}

export function QuickLink({
  title,
  icon,
  children,
  url,
  id,
  isEditable = false,
  isDragging,
  access,
  author_id,
}: QuickLinkProps): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isManager, user: currentUser } = useAuth();

  const isPublicLink = access === QuickLinkAccess.public;
  const isPublicEditable = isPublicLink && isManager && author_id === currentUser?.id && isEditable;
  const editable = isEditable && (isPublicEditable || !isPublicLink);

  const ICONS = { ...LINKS_ICONS, ...CUSTOM_LINK_ICONS };
  const detailsLink = generatePath(Links.we.quickLinks.linkDetails, {
    linkId: id,
  });

  function handleClick() {
    if (isDragging) {
      return;
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  function handleEditBtnClick(e: SyntheticEvent) {
    e.stopPropagation();
    navigate(detailsLink);
  }

  return (
    <CardW onClick={handleClick}>
      {editable && <EditIconW onClick={handleEditBtnClick} />}
      <RowW>
        <IconW>{ICONS[icon]}</IconW>
        <div>
          <TitleW>{title}</TitleW>
          {isPublicLink && isEditable && <SubtitleW>{t('quickLinks.publicLink')}</SubtitleW>}
        </div>
      </RowW>
      {children}
    </CardW>
  );
}
