import { useMutation } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

import { queryClient } from '../queryClient';

interface UseReInviteMutationProps {
  onSuccessFunc?: () => void;
  invalidateQuery: string;
  successText: string;
}

export function useReInviteUserMutation({
  onSuccessFunc,
  invalidateQuery,
  successText,
}: UseReInviteMutationProps) {
  const { mutateAsync: reInviteUserMutation, isLoading } = useMutation(
    API_HANDLERS.USER.RE_INVITE,
    {
      onSuccess: () => {
        alert.success(successText);
        onSuccessFunc?.();
        queryClient.invalidateQueries([invalidateQuery]);
      },
    },
  );

  return {
    reInviteUserMutation,
    isLoading,
  };
}
