import { useUsersListQuery } from 'api';
import { EMPLOYEES_PREVIEW_LIMIT_WE_PAGE } from 'constants/index';
import { User, UserStatus } from 'types';

interface UseWePageLogicReturn {
  isUsersListLoading: boolean;
  usersList: User[];
}

export function useWePageLogic(): UseWePageLogicReturn {
  const { isLoading, usersList } = useUsersListQuery({
    limit: EMPLOYEES_PREVIEW_LIMIT_WE_PAGE,
    status: [UserStatus.Active, UserStatus.Registered],
    rand: true,
  });

  return {
    isUsersListLoading: isLoading,
    usersList,
  };
}
