import { isPast, fromUnixTime } from 'date-fns';
import { createContext, ReactElement, ReactNode } from 'react';

import { useSessionState } from 'hooks';
import {
  Subscription,
  SubscriptionContextValues,
  SubscriptionStatus,
  SubscriptionType,
} from 'types';
import { getUnixTimestamp } from 'utils';

const initialAuthContext = {
  subscription: null,
  isTrial: false,
  isVisibleTrialNotification: true,
  isVisibleMockDataBanner: true,
  saveSubscription: () => {},
  closeNotification: () => {},
  addNotification: () => {},
  closeMockDataBanner: () => {},
  addMockDataBanner: () => {},
  isExpired: false,
  isCanceled: false,
};

export const SubscriptionContext = createContext<SubscriptionContextValues>(initialAuthContext);

interface SubscriptionProviderProps {
  children: ReactNode;
}

export function SubscriptionProvider({ children }: SubscriptionProviderProps): ReactElement | null {
  const [subscription, setSubscription] = useSessionState<null | Subscription>(
    'subscription',
    null,
  );

  const [isVisibleTrialNotification, setIsVisible] = useSessionState('notificationIsVisible', true);
  const [isVisibleMockDataBanner, setIsVisibleMockDataBanner] = useSessionState(
    'mockDataBanner',
    true,
  );

  const unixTimestamp = getUnixTimestamp(subscription?.expires_at || '');
  const dateFromUnix = fromUnixTime(unixTimestamp);
  const isExpired = isPast(dateFromUnix);

  function saveSubscription(data: Subscription) {
    setSubscription(data);
  }

  function closeNotification() {
    setIsVisible(false);
  }

  function addNotification() {
    setIsVisible(true);
  }

  function closeMockDataBanner() {
    setIsVisibleMockDataBanner(false);
  }

  function addMockDataBanner() {
    setIsVisibleMockDataBanner(true);
  }

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        saveSubscription,
        isTrial: subscription?.type === SubscriptionType.trial,
        isCanceled:
          subscription?.status === SubscriptionStatus.canceled ||
          Boolean(subscription?.canceled_at),
        isVisibleTrialNotification,
        isVisibleMockDataBanner,
        closeNotification,
        addNotification,
        isExpired,
        closeMockDataBanner,
        addMockDataBanner,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}
