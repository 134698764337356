import { styled, Typography } from '@mui/material';

export const ContainerW = styled('div')`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 15px;
  margin-bottom: 42px;
  padding: 23px 24px 10px 24px;
  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }
`;

export const Title = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p020};
  padding-bottom: 6px;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Row = styled('div')`
  display: flex;
  color: ${({ theme }) => theme.palette.primary.p080};
  padding: 24px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.white.main}`};

  &:last-of-type {
    border-bottom: none;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 16px 0;
  }
`;

export const LabelW = styled('div')`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  width: 50%;
  min-width: 50%;
  white-space: break-spaces;

  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.4);
  }
`;

export const Label = styled('div')`
  width: 170px;
  overflow-wrap: break-word;
  white-space: break-spaces;
`;

export const Value = styled('div')`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  word-break: break-word;
  white-space: break-spaces;

  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 16px;
  }
`;
