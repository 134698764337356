import { PollFormValues } from 'types';

export function defaultValuesAdapter(): PollFormValues {
  return {
    title: '',
    description: '',
    visibility: null,
    deadline: null,
    docs: [],
    images: [],
    videos: [],
    options: [{ value: '' }, { value: '' }],
  };
}
