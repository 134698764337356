import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { BoxProps, MenuItem, MenuItemProps } from '@mui/material';
import { ChangeEvent, ReactElement, ReactNode } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'hooks';

import { BaseTextField } from '../BaseTextField';
import { BaseTextFieldControllerProps } from '../BaseTextFieldController';
import { CustomSelectedValue } from '../CustomSelectedValue';
import { Placeholder, MultipleSelectWrap } from './styled';
import { defaultStyles, menuItemStyles, defaultMobileStyles } from './styles';

type SelectControllerProps<T extends FieldValues> = BaseTextFieldControllerProps<T> & {
  options: { value: string; label: ReactNode; icon?: string; disabled?: boolean }[];
  defaultOptionText?: string;
  multipleSelect?: boolean;
  CustomSelectLabel?: ReactElement;
  withDefaultEmptyOption?: boolean;
  emptyOption?: string;
  optionSx?: MenuItemProps['sx'];
  dropdownContainerSx?: BoxProps['sx'];
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
};

export function SelectController<T extends FieldValues>({
  emptyOption,
  options,
  name,
  control,
  rules,
  defaultOptionText,
  sx,
  placeholder,
  multipleSelect = false,
  CustomSelectLabel,
  withDefaultEmptyOption,
  optionSx,
  dropdownContainerSx,
  handleChange,
  type,
  ...otherProps
}: SelectControllerProps<T>): ReactElement {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const emptyOptionText = emptyOption || t('common.noneOption');

  return (
    <BaseTextField
      onChange={(e) => {
        onChange(e);
        handleChange?.(e as ChangeEvent<HTMLInputElement>);
      }}
      value={value}
      error={Boolean(error)}
      helperText={error && error.message}
      select
      InputProps={{
        // disableUnderline: true,
        sx: {
          ...(isMobile ? defaultMobileStyles : defaultStyles),
          ...sx,
        },
      }}
      SelectProps={{
        multiple: multipleSelect,
        disableUnderline: true,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '.MuiList-root': {
              maxHeight: '320px',
            },
            ...dropdownContainerSx,
          },
        },
        displayEmpty: true,
        renderValue: (values) => {
          if (!(values && (values as string[]).length)) {
            return <Placeholder>{placeholder}</Placeholder>;
          }

          return multipleSelect ? (
            <MultipleSelectWrap>
              {(values as string[]).map((value) => {
                const option = options.find((el) => el.value === value);
                return (
                  <CustomSelectedValue
                    key={value}
                    icon={option?.icon}
                    label={option?.label as string}
                    identify={name}
                    type={type}
                  />
                );
              })}
            </MultipleSelectWrap>
          ) : (
            <CustomSelectedValue
              key={value}
              icon={options.find((el) => el.value === value)?.icon}
              label={options.find((el) => el.value === value)?.label as string}
              identify={name}
              type={type}
            />
          );
        },
        IconComponent: ExpandMoreRoundedIcon,
        value: value || [],
      }}
      {...otherProps}
    >
      {withDefaultEmptyOption && (
        <MenuItem value="">
          <em>{emptyOptionText}</em>
        </MenuItem>
      )}
      {options.map(({ value, label, disabled }) => (
        <MenuItem
          key={value}
          value={value}
          sx={{
            ...menuItemStyles,
            display: disabled && value === '' ? 'none' : 'block',
            ...optionSx,
          }}
          disabled={disabled}
        >
          {label}
        </MenuItem>
      ))}
    </BaseTextField>
  );
}
