import { styled } from '@mui/material';

export const TextW = styled('div')`
  width: 467px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.primary.p080};
  white-space: break-spaces;
  @media (max-width: 1023px) {
    font-size: 18px;
    width: 100%;
    margin-top: 16px;
  }
`;
