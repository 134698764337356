import { theme } from 'styles';

export const autocompleteStyles = {
  '& .MuiFormControl-root': {
    '.MuiAutocomplete-inputRoot': {
      paddingBottom: 0,
    },
  },

  '& .MuiInputBase-root': {
    '.MuiInputBase-input': {
      padding: '0 43px 0 37px',
      fontSize: '24px',
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.p030,
    },

    '.MuiAutocomplete-endAdornment': {
      top: 'unset',
      right: '24px',
    },
  },
};

export const autocompleteMobileStyles = {
  '& .MuiFormControl-root': {
    '.MuiAutocomplete-inputRoot': {
      paddingBottom: 0,
    },
  },

  '& .MuiInputBase-root': {
    '.MuiInputBase-input': {
      padding: '18px 32px 18px 16px',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px',
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.p030,
    },

    '.MuiAutocomplete-endAdornment': {
      top: 'unset',
      right: '24px',
    },
  },
};

export const menuItemStyles = { fontSize: '18px', padding: '8px 24px' };
