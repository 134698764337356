import { Language } from 'types';
import { getCookie } from 'utils';

export function getUserLanguage() {
  const langFromStorage = localStorage.getItem('i18nextLng');
  const langFromCookies = getCookie('i18next');

  if (langFromStorage) {
    return langFromStorage;
  }

  if (langFromCookies) {
    return langFromCookies;
  }

  // default lang SV if i18next could't determine
  return Language.swedish;
}
