import { LinearProgress } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog, Form, RichEditorController } from 'components';
import { useCallbackPrompt } from 'hooks';

import { commentFieldRules, COMMENT_MAX_LENGTH } from './constants';
import { useAddGoalCommentForm } from './hooks';
import { SubmitBtn } from './styled';
import { formStyles, loaderStyles, commentRichEditorStyles } from './styles';

interface AddGoalCommentFormProps {
  userId: string;
  achievementId: string;
  goalId: string;
}

export function AddGoalCommentForm({
  userId,
  achievementId,
  goalId,
}: AddGoalCommentFormProps): ReactElement {
  const { t } = useTranslation();

  const { isLoading, isMobile, isResetField, isDirty, isSubmitted, control, handleFormSubmit } =
    useAddGoalCommentForm({
      userId,
      achievementId,
      goalId,
    });

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isDirty && !isSubmitted,
  );

  return (
    <>
      <Form sx={formStyles} onSubmit={handleFormSubmit}>
        {isLoading && <LinearProgress color="info" sx={loaderStyles} />}

        <RichEditorController
          name="content"
          control={control}
          placeholder={t('comments.post.placeholder')}
          maxLength={COMMENT_MAX_LENGTH}
          rules={commentFieldRules}
          sx={commentRichEditorStyles(isMobile)}
          isResetField={isResetField}
          withRightPadding
        />

        <SubmitBtn color="info" type="submit" disabled={isLoading}>
          {t('comments.postBtn')}
        </SubmitBtn>
      </Form>

      <ConfirmDialog
        title={t('comments.modal.title')}
        subTitle={t('comments.modal.exit')}
        open={showPrompt}
        onClose={cancelNavigation}
        onSubmit={confirmNavigation}
      />
    </>
  );
}
