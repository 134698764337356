import { styled, Typography } from '@mui/material';

export const TaskW = styled('div')<{ isPreview: boolean }>(({ theme, isPreview }) => ({
  width: '100%',
  padding: isPreview ? '16px' : '24px',
  background: theme.palette.white.main,
  boxShadow: '0px 8px 12px -20px rgba(0, 0, 0, 0.08)',
  borderRadius: '18px',
  marginTop: '16px',
  position: 'relative',

  [theme.breakpoints.down('desktop')]: {
    width: 'auto',
  },
}));

export const TitleW = styled(Typography, {
  shouldForwardProp: (propname) => propname !== 'isPreview',
})<{ isPreview: boolean }>(({ theme, isPreview }) => ({
  fontSize: isPreview ? '14px' : '18px',
  lineHeight: isPreview ? '20px' : '24px',
  color: theme.palette.primary.p080,
  maxWidth: '180px',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}));

export const DescriptionW = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.primary.p050};
  margin-top: 16px;
  overflow: hidden;

  & p {
    margin: 0;

    &:has(code) {
      margin: 4px 0;
    }
  }

  & a {
    color: ${({ theme }) => theme.palette.info.main};
    z-index: 9999;
  }

  & h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    font-size: inherit;
  }
`;

export const FooterW = styled('div')<{ isPreview: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ isPreview }) => (isPreview ? '14px' : '16px')};
`;

export const DateContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateW = styled(Typography)`
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: 10px;
`;

export const AvatarW = styled('div')`
  margin-left: auto;
`;

export const IconW = styled('div')`
  position: relative;
  height: 20px;
`;

export const CountW = styled('span')`
  position: absolute;
  font-size: 11px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
`;

export const HeaderW = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
