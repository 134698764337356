import { useNavigate, useParams } from 'react-router-dom';

import { usePostDetails, useDeletePostMutation, PostListItem, queryClient } from 'api';
import { useAuth, useModal } from 'hooks';

interface UseTaskDetailsPageReturn {
  task?: PostListItem;
  isOpen: boolean;
  canDelete: boolean;
  isLoading: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  handleDeleteTask: () => void;
}

export function useTaskDetailsPage(): UseTaskDetailsPageReturn {
  const { taskId = '' } = useParams<string>();

  const navigate = useNavigate();

  const { handleOpenModal, handleCloseModal, isOpen } = useModal();

  const { user } = useAuth();

  const {
    isLoading,
    post: task,
    isError,
  } = usePostDetails({
    postId: taskId,
    queryName: 'task-details',
  });

  if (isError) {
    navigate(-1);
  }

  const canDelete = Boolean(user?.id === task?.author.id);

  const { deletePostMutation } = useDeletePostMutation(() => {
    queryClient.invalidateQueries({ queryKey: ['tasks', 'task-details'] });
    navigate(-1);
  });

  async function handleDeleteTask() {
    await deletePostMutation({ postId: taskId });
    handleCloseModal();
  }

  return {
    task,
    isOpen,
    isLoading,
    handleOpenModal,
    handleCloseModal,
    handleDeleteTask,
    canDelete,
  };
}
