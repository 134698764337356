import { Box, styled, Typography } from '@mui/material';

export const InputW = styled(Box)`
  width: 47%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const PhoneInputW = styled(Box)`
  &:first-of-type {
    max-width: 132px;

    @media (max-width: 1023px) {
      max-width: 76px;
    }
  }

  &:nth-of-type(2) {
    width: 100%;
    max-width: 65%;
  }

  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }
`;

export const PasswordInputW = styled(Box)`
  width: 100%;
  max-width: 405px;
`;

export const WranDescription = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgb(0, 155, 242);
`;
