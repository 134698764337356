import { handleLogout } from 'utils';

import { LOGOUT_MESSAGE } from './constant';

let bc: null | BroadcastChannel = null;

try {
  if (window.BroadcastChannel) {
    bc = new BroadcastChannel('bc_channel');

    bc.onmessage = function (event) {
      if (event.data === LOGOUT_MESSAGE) {
        handleLogout();
        bc?.close();
      }
    };
  }
} catch (error) {
  console.log(error);
}

export { bc };
