import { Button, IconButton, styled, Typography } from '@mui/material';

export const FromContainer = styled('div')`
  width: 671px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 36px 25px;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
    padding-bottom: 80px;
    margin-right: 0px;
  }
`;

export const RichEditorW = styled('div')`
  @media (max-width: 1023px) {
    margin-bottom: 8px;
  }
`;

export const CloseButtonW = styled(IconButton)`
  margin-left: auto;
  position: fixed;
  top: 79px;
  right: 88px;
`;

export const FormAndPreviewW = styled('div')`
  display: flex;
  align-items: flex-start;
  position: relative;
`;

export const PreviewTitle = styled(Typography)`
  font-size: 14px;
  line-height: 10px;
  color: ${({ theme }) => theme.palette.primary.p030};
  position: absolute;
  top: -32px;
`;

export const ButtonsW = styled('div')`
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    flex-direction: row;
    column-gap: 16px;
  }
`;

export const AuthorName = styled(Typography)`
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  bottom: -18px;
  left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 500px) {
    width: 200px;
  }

  @media (max-width: 400px) {
    width: 120px;
  }

  @media (max-width: 1023px) {
    bottom: -25px;
  }
`;

export const PublishedDateW = styled(Typography)`
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  bottom: -18px;
  right: 16px;
  @media (max-width: 1023px) {
    bottom: -25px;
  }
`;

export const ActionsW = styled('div')`
  margin-top: 32px;
  position: absolute;
  bottom: 16px;
  right: 4px;
  left: 4px;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const RemoveButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
