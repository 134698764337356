import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import Swiper, { Pagination } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';

import { AddAndExtendActions } from 'components';
import { useAuth, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { TaskStatus } from 'types';

import { TasksList, TasksTabs } from '../../components';
import { ContainerW, ListsW } from './styled';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.module.css';

const PROFILE_PAGE_TASKS_LIST_LIMIT = 2;

export function ProfileTasks(): ReactElement {
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiperRef, setSwiperRef] = useState<Swiper | null>(null);

  const { pathname } = useLocation();
  const { employeeId = '' } = useParams<string>();

  const { isMobile } = useWindowSize();
  const { user, isManager } = useAuth();
  const { t } = useTranslation();

  const slideTo = (index: number) => {
    swiperRef?.slideTo(index);
  };

  const isProfilePage = pathname.includes(Links.profile.index);
  const isUserViewingHisDetails = employeeId === user?.id;
  const isManagerViewingOthersProfile = isManager && employeeId !== user?.id;
  const withAssigneeParam =
    (!isProfilePage && !isUserViewingHisDetails) || isManagerViewingOthersProfile;

  const tabs = [
    {
      label: t('tasks.statuses.toDo'),
      slideIndex: 0,
    },
    {
      label: t('tasks.statuses.inProgress'),
      slideIndex: 1,
    },
    {
      label: t('tasks.statuses.toApprove'),
      slideIndex: 2,
    },
  ];

  const assigneeIdState = isManagerViewingOthersProfile ? { assigneeId: employeeId } : {};
  const addTaskLink = isProfilePage ? Links.profile.createTask : Links.resources.createTask;

  return (
    <ContainerW>
      <AddAndExtendActions
        addIconLink={addTaskLink}
        maximizeIconLink={Links.profile.taskManagement.index}
        addLinkState={assigneeIdState}
        maximizeLinkState={assigneeIdState}
      />
      {isMobile ? (
        <>
          <TasksTabs slideTo={slideTo} activeIndex={activeIndex} tabs={tabs} />
          <ListsW>
            <SwiperComponent
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
              onSwiper={(swiper) => setSwiperRef(swiper)}
              pagination={true}
              modules={[Pagination]}
            >
              <SwiperSlide style={{ height: swiperRef?.height || '100%' }}>
                <TasksList
                  status={TaskStatus.to_do}
                  title={t('tasks.statuses.toDo')}
                  limit={PROFILE_PAGE_TASKS_LIST_LIMIT}
                  {...(withAssigneeParam && { assigned: employeeId })}
                  isProfile
                  newTaskAssigneeId={isManagerViewingOthersProfile ? employeeId : undefined}
                />
              </SwiperSlide>
              <SwiperSlide style={{ height: swiperRef?.height || '100%' }}>
                <TasksList
                  status={TaskStatus.in_progress}
                  title={t('tasks.statuses.inProgress')}
                  limit={PROFILE_PAGE_TASKS_LIST_LIMIT}
                  {...(withAssigneeParam && { assigned: employeeId })}
                  isProfile
                  newTaskAssigneeId={isManagerViewingOthersProfile ? employeeId : undefined}
                />
              </SwiperSlide>
              <SwiperSlide style={{ height: swiperRef?.height || '100%' }}>
                <TasksList
                  status={TaskStatus.to_approve}
                  title={t('tasks.statuses.toApprove')}
                  limit={PROFILE_PAGE_TASKS_LIST_LIMIT}
                  {...(withAssigneeParam && { assigned: employeeId })}
                  isProfile
                  newTaskAssigneeId={isManagerViewingOthersProfile ? employeeId : undefined}
                />
              </SwiperSlide>
            </SwiperComponent>
          </ListsW>
        </>
      ) : (
        <ListsW>
          <TasksList
            status={TaskStatus.to_do}
            title={t('tasks.statuses.toDo')}
            limit={PROFILE_PAGE_TASKS_LIST_LIMIT}
            {...(withAssigneeParam && { assigned: employeeId })}
            isProfile
            newTaskAssigneeId={isManagerViewingOthersProfile ? employeeId : undefined}
          />
          <TasksList
            status={TaskStatus.in_progress}
            title={t('tasks.statuses.inProgress')}
            limit={PROFILE_PAGE_TASKS_LIST_LIMIT}
            {...(withAssigneeParam && { assigned: employeeId })}
            isProfile
            newTaskAssigneeId={isManagerViewingOthersProfile ? employeeId : undefined}
          />
          <TasksList
            status={TaskStatus.to_approve}
            title={t('tasks.statuses.toApprove')}
            limit={PROFILE_PAGE_TASKS_LIST_LIMIT}
            {...(withAssigneeParam && { assigned: employeeId })}
            isProfile
            newTaskAssigneeId={isManagerViewingOthersProfile ? employeeId : undefined}
          />
        </ListsW>
      )}
    </ContainerW>
  );
}
