import { styled } from '@mui/material';

export const InputW = styled('div')`
  max-width: 75%;
  display: flex;
  align-items: flex-end;

  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;
