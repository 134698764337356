import axios from 'axios';

import { API_HOST } from 'settings';
import { $FixTypeLater } from 'types';
import { handleLogout } from 'utils';

import { API_PATHS } from './apiPaths';
import { refreshToken } from './utils';

const httpClient = axios.create({
  baseURL: API_HOST,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;

httpClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalRequest = error.config;

    // Access Token was expired
    if (
      error.response.status === 401 &&
      error.config &&
      !originalRequest._retry &&
      originalRequest.url !== '/calendar/google/events' &&
      originalRequest.url !== API_PATHS.AUTH.LOGIN()
    ) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          await refreshToken(httpClient);
          isRefreshing = false;
          return httpClient.request(originalRequest);
        } catch (_error: $FixTypeLater) {
          handleLogout();
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error);
  },
);

export { httpClient };
