import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState.svg';

export const NavLinkW = styled(NavLink)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
`;

export const EmptyStateIconW = styled(EmptyStateIcon)`
  margin-bottom: 17px;
  opacity: 0.4;
`;
