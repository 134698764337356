import { PostListItem } from 'api';
import { PollFormValues, User } from 'types';

import { AllOption } from '../PollForm/constants';

const compareIds = (arr1: string[], arr2: string[]): boolean => {
  const sortedArr1 = arr1.sort();
  const sortedArr2 = arr2.sort();

  return (
    sortedArr1.length === sortedArr2.length &&
    sortedArr2.every((value, index) => value === sortedArr1[index])
  );
};

export function defaultValuesAdapter(usersList: User[], data?: PostListItem): PollFormValues {
  const images = data?.attachments?.filter((file) => file.type === 'image') || [];
  const docs = data?.attachments?.filter((file) => file.type === 'document') || [];
  const videos = data?.attachments?.filter((file) => file.type === 'video') || [];

  const options =
    data?.poll?.options?.map(({ text }) => ({
      value: text,
    })) ?? [];

  const usersIds = usersList.map(({ id }) => id) ?? [];
  const assigneeOptions: string[] = data?.assigned_to?.map(({ id }) => id) ?? [];
  const isAllAssignee = !data?.assigned_to || compareIds(usersIds, assigneeOptions);

  return {
    title: data?.title ?? '',
    description: data?.description ?? '',
    deadline: data?.poll?.deadline ?? null,
    visibility: isAllAssignee ? [AllOption.value] : assigneeOptions,
    docs,
    images,
    videos,
    options,
  };
}
