import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { GoBackButtonWithTitle, TopMenu } from 'components';
import { useSubscription } from 'hooks';
import { Links } from 'settings';

import { HeaderW } from './styled';

interface EmployeesTableHeaderProps {
  count: number;
}

export function EmployeesTableHeader({ count }: EmployeesTableHeaderProps): ReactElement {
  const { t } = useTranslation();
  const { isTrial } = useSubscription();
  const tableTitle = t(
    count > 1 ? 'employee.table.header.fewAndMoreEmployee' : 'employee.table.header.oneEmployee',
    { count },
  );

  const { pathname } = useLocation();

  const inviteEmployeeRoute = pathname.includes('resources')
    ? Links.resources.employees.invite
    : Links.we.employees.invite;

  return (
    <HeaderW>
      <TopMenu />
      <GoBackButtonWithTitle title={tableTitle} />

      {!isTrial ? (
        <NavLink to={inviteEmployeeRoute}>
          <IconButton sx={{ paddingRight: '0px' }}>
            <AddIcon style={{ opacity: '0.4' }} />
          </IconButton>
        </NavLink>
      ) : null}
    </HeaderW>
  );
}
