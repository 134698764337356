export const popoverStyles = {
  padding: '16px',
  borderRadius: '16px',
  background: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(10px)',
  width: '409px',

  '& .MuiList-root': {
    padding: 0,
    display: 'grid',
    gap: '16px',
  },

  '@media (max-width: 1023px)': {
    width: 'calc(100% - 16px)',
    maxWidth: 'calc(100% - 16px)',
    padding: '16px 8px',
    left: '8px !important',
  },
};

export const buttonStyles = {
  borderRadius: '6px',
  background: '#CCEBFC',
  width: '100%',
  padding: '4px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '28px',
  textTransform: 'capitalize',
};
