import { API_SOCKET } from 'settings';
import { Notification } from 'types';

function connect() {
  const socket = new WebSocket(API_SOCKET);
  const currentToken = localStorage.getItem('accessToken');

  socket.addEventListener('open', () => {
    socket.send(
      JSON.stringify({
        type: 'auth',
        payload: {
          token: currentToken,
        },
      }),
    );
  });

  socket.addEventListener('message', (event) => {
    const data = JSON.parse(event.data);
    Socket.on(data.type, data.payload);
  });

  socket.addEventListener('close', () => {
    console.warn('Websocket connection closed. Try to reconnect in 1 second.');
    setTimeout(connect, 1000);
  });

  socket.addEventListener('error', (err) => {
    console.error('Socket error:', err);
    socket.close();
  });
}

connect();

type Callback = (data: Notification) => void;
type EventType = string;

export const Events = {
  authOk: 'auth-ok',
  authFailed: 'auth-failed',
  newNotification: 'new-notification',
};

export const Socket: {
  listeners: { [key: string]: Callback[] };
  subscribe: (eventType: EventType, callback: Callback) => void;
  unsubscribe: (eventType: EventType, callback: Callback) => void;
  notify: (eventType: EventType, payload: Notification) => void;
  on: (eventType: EventType, payload: Notification) => void;
} = {
  listeners: {
    [Events.newNotification]: [],
  },
  subscribe: function (eventType, callback) {
    this.listeners[eventType].push(callback);
  },
  unsubscribe: function (eventType, callback) {
    this.listeners[eventType].filter((c) => c !== callback);
  },
  notify: function (eventType, payload) {
    this.listeners[eventType].forEach((callback) => callback(payload));
  },
  on: function (eventType, payload) {
    switch (eventType) {
      case Events.authOk:
        break;
      case Events.authFailed:
        break;
      case Events.newNotification:
        this.notify(eventType, payload);
        break;
    }
  },
};
