import { Tooltip } from '@mui/material';
import { sv } from 'date-fns/locale';

import { Avatar } from 'components';
import { DEFAULT_DATE_FORMAT } from 'constants/dateFormats';
import ActionsMenu from 'features/employees/components/ActionsMenu';
import { useAuth } from 'hooks';
import i18n from 'i18n';
import { EmployeesTableFields, RemoveEmployeeArgument, User, UserRole, UserStatus } from 'types';
import { formattedDate } from 'utils';

// TODO: refactor adapter
// - move out some components, reuse code
import {
  AdressW,
  Email,
  NameCellW,
  NameW,
  OffboardButton,
  PositionDepartmentText,
  SpanIn,
} from './styled';

interface EmployeeTableAdapterProps {
  data: User[];
  removeButtonAction: (arg: RemoveEmployeeArgument) => void;
  activateButtonClick: (id: string) => void;
  reInviteButtonClick: (id: string) => void;
  isManager: boolean;
  isMobile: boolean;
  isLoadingUser: boolean;
  isSwedish: boolean;
}

export function employeeTableAdapter({
  data,
  removeButtonAction,
  activateButtonClick,
  reInviteButtonClick,
  isManager,
  isMobile,
  isSwedish,
}: EmployeeTableAdapterProps): EmployeesTableFields[] {
  if (data.length === 0) {
    return [];
  }

  const removeButtonText = i18n.t('employee.table.actions.remove');
  const offboardButtonText = i18n.t('employee.table.actions.offboard');
  const pendingText = i18n.t('employee.table.actions.pending');
  const managerRoleText = i18n.t('inviteEmployee.form.options.manager');
  const inText = i18n.t('employee.table.description.in');
  const locales = isSwedish ? sv : undefined;

  return data.map(
    ({
      firstName,
      lastName,
      id,
      email,
      phone,
      countryCode,
      avatar,
      status,
      role,
      birthDate,
      position,
      department,
      city,
      region,
      country,
      address1,
      address2,
      postcode,
      invitedById,
    }) => {
      const userName = `${firstName || ''} ${lastName || ''}`.trim();
      const { user: currentUser } = useAuth();
      const addressValue = [address1, address2, city, region, postcode, country]
        .filter((value) => value)
        .join(', ');
      const avatarSize = isMobile ? 'mobTable' : 'l';
      const isManagerRoleRow = role === UserRole.Manager;
      const invitedByCurrentUser = invitedById === currentUser?.id;

      const positionDepartmentText = () => {
        if (position && department) {
          return (
            <PositionDepartmentText>
              {position} <SpanIn>{inText}</SpanIn> {department}
            </PositionDepartmentText>
          );
        }

        return <PositionDepartmentText>{position || department}</PositionDepartmentText>;
      };

      if (status === UserStatus.Invited || status === UserStatus.Registered) {
        return {
          name:
            status === UserStatus.Invited ? (
              <NameCellW>
                <NameW>
                  {pendingText}
                  {isMobile && <Email>{email}</Email>}
                </NameW>
              </NameCellW>
            ) : (
              <NameCellW>
                <Tooltip title={userName} placement="top-start">
                  <NameW>{userName}</NameW>
                </Tooltip>
                {isMobile && <Email>{email}</Email>}
                {role === UserRole.Manager ? <span>{managerRoleText}</span> : null}
                {positionDepartmentText()}
              </NameCellW>
            ),
          id,
          email: email || '',
          avatar: <Avatar size={avatarSize} userId={id} filename={avatar?.small || ''} />,
          phone: phone ? `+${countryCode} ${phone}` : '',
          birthDate: birthDate
            ? formattedDate(new Date(birthDate), DEFAULT_DATE_FORMAT, locales)
            : '',
          address: <AdressW>{addressValue || ''}</AdressW>,
          actions:
            isManager || invitedByCurrentUser ? (
              <ActionsMenu
                isRegister={status === UserStatus.Registered}
                isManager={isManager}
                onRemove={() => removeButtonAction({ id, status, userName })}
                onActivate={() => activateButtonClick(id)}
                onReInvite={() => reInviteButtonClick(id)}
              />
            ) : (
              <></>
            ),
          offboarding: (
            <OffboardButton
              variant="outlined"
              color="info"
              sx={{
                maxWidth: 'none',
                background: 'white',
              }}
              onClick={(e) => {
                e.stopPropagation();
                removeButtonAction({ id, status, userName });
              }}
            >
              {removeButtonText}
            </OffboardButton>
          ),
          position: '',
          isClickable: false,
          class: 'not-hovered',
        };
      }

      if (status === UserStatus.Inactive) {
        return {
          name: (
            <NameCellW>
              <Tooltip title={userName} placement="top-start">
                <NameW>{userName}</NameW>
              </Tooltip>
              {isMobile && <Email>{email}</Email>}
              {isManagerRoleRow ? <span>{managerRoleText}</span> : null}
              {positionDepartmentText()}
            </NameCellW>
          ),
          id,
          email: email || '',
          avatar: <Avatar size={avatarSize} userId={id} filename={avatar?.small || ''} />,
          phone: phone ? `+${countryCode} ${phone}` : '',
          birthDate: birthDate
            ? formattedDate(new Date(birthDate), DEFAULT_DATE_FORMAT, locales)
            : '',
          address: <AdressW>{addressValue || ''}</AdressW>,
          actions: <></>,
          position: '',
          isClickable: true,
          class: 'inactive',
          offboarding: (
            <OffboardButton
              variant="contained"
              color="warning"
              sx={{
                maxWidth: 'none',
                '&:hover': {
                  boxShadow: 'none',
                },
              }}
            >
              {offboardButtonText}
            </OffboardButton>
          ),
        };
      }

      return {
        name: (
          <NameCellW>
            <Tooltip title={userName} placement="top-start">
              <NameW>{userName}</NameW>
            </Tooltip>
            {isMobile && <Email>{email}</Email>}
            {isManagerRoleRow ? <span>{managerRoleText}</span> : null}
            {positionDepartmentText()}
          </NameCellW>
        ),
        id,
        email: email || '',
        avatar: (
          <Avatar
            size={avatarSize}
            userId={id}
            filename={avatar?.small || ''}
            withNavigation={false}
          />
        ),
        phone: phone ? `+${countryCode} ${phone}` : '',
        birthDate: birthDate
          ? formattedDate(new Date(birthDate), DEFAULT_DATE_FORMAT, locales)
          : '',
        address: <AdressW>{!isMobile && addressValue ? addressValue : ''}</AdressW>,
        actions: <></>,
        isClickable: status === UserStatus.Active || status === UserStatus.Registered,
        offboarding: (
          <>
            {currentUser?.id !== id ? (
              <OffboardButton
                variant="contained"
                color="warning"
                sx={{
                  maxWidth: 'none',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeButtonAction({ id, status, userName });
                }}
              >
                {offboardButtonText}
              </OffboardButton>
            ) : null}
          </>
        ),
      };
    },
  );
}
