import { IconButton, styled, Typography } from '@mui/material';

export const ContainerW = styled('div')`
  margin-right: 24px;
  padding: 24px 16px;
  width: 258px;
  height: 216px;
  border-radius: 15px;
  position: relative;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const ListW = styled('div')``;

export const EmptyStateTitle = styled(Typography)`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.p040};
  margin-top: 70px;
  white-space: break-spaces;
`;

export const IconButtonW = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const ListItem = styled('div')`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p080};
  padding: 22px 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 25px 12px -20px rgba(0, 0, 0, 0.08);
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
