import { styled } from '@mui/material';

export const ProgressW = styled('div')`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const RichEditorW = styled('div')`
  padding-top: 20px;
`;
