import { styled, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  position: relative;
  width: 427px;
  height: 297px;
  border-radius: 16px;
  background: #f5f5f5;
  backdrop-filter: blur(10px);
  border-radius: 16px;

  @media (max-width: 1023px) {
    width: 300px;
  }
`;

export const TitleW = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 16px;
  background: #f5f5f5;
`;

export const CenteredW = styled(Typography, {
  shouldForwardProp: (propname) => propname !== 'isPreview',
})<{ isPreview: boolean }>`
  color: ${({ theme }) => theme.palette.primary.p080};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  position: absolute;
  left: ${({ isPreview }) => (isPreview ? '50%' : '55%')};
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    left: 50%;
  }
`;
