export const accomplishedDialogSx = {
  '@media (max-width: 1023px)': {
    '& .MuiPaper-root': {
      width: '100%',
      marginRight: '16px',
      marginLeft: '16px',
      padding: '16px',

      '& .MuiDialogTitle-root': {
        marginTop: '0',
      },

      '& .MuiDialogActions-root': {
        marginTop: '8px',
      },
    },
  },
};
