import { ReactElement, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useNotificationsQuery } from 'api';
import { GoBackButtonWithTitle, PageWrapper, Spinner } from 'components';
import { NOTIFICATIONS_LIMIT } from 'constants/index';
import { groupByDate } from 'features/notifications/components/Notification/utils/groupByDate';
import { useNotificationBadge } from 'features/notifications/hooks/useNotificationBadge';
import { useListScrollTo } from 'hooks/useListScrollTo';
import { Links } from 'settings';
import { Notification as NotificationInterface } from 'types';

import { Notification, PreviewEmptyState } from '../../components';
import { ContentW, TitleW } from './styled';

interface LocationI {
  hash: string;
  state?: { from: string };
}

export function NotificationsPage(): ReactElement {
  const { t } = useTranslation();
  const { isEmptyList, isLoading, notifications, lastItemRef, lastItemId } = useNotificationsQuery({
    limit: NOTIFICATIONS_LIMIT,
  });

  const { groups, labels } = groupByDate(notifications);

  const { state } = useLocation() as LocationI;
  const navigate = useNavigate();

  const { refs, activeItemId } = useListScrollTo(notifications);

  const { markAsRead } = useNotificationBadge();

  const handleGoBack = useCallback(() => {
    const prevPath = state?.from ?? Links.we.index;
    navigate(prevPath);
  }, [navigate, state?.from]);

  return (
    <PageWrapper>
      <>
        <GoBackButtonWithTitle title={t('notifications.title')} onClick={handleGoBack} />
        {isLoading && <Spinner />}
        {isEmptyList ? (
          <PreviewEmptyState isPreview={false} />
        ) : (
          <>
            {groups.map((group: NotificationInterface[], index: number) => (
              <ContentW key={labels[index]}>
                <TitleW>{labels[index]}</TitleW>
                {group.map((notification) => (
                  <div
                    key={notification.id}
                    ref={refs[notification.id] as RefObject<HTMLDivElement>}
                  >
                    <Notification
                      isPreview={false}
                      lastItemRef={notification.id === lastItemId ? lastItemRef : undefined}
                      isActive={activeItemId === notification.id.toString()}
                      addToIsRead={(id: string) => markAsRead(id)}
                      {...notification}
                    />
                  </div>
                ))}
              </ContentW>
            ))}
          </>
        )}
      </>
    </PageWrapper>
  );
}
