import { useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  usePublishPostMutation,
  PostListItem,
  useEditPostMutation,
  CategoryListItemExt,
} from 'api';
import { useCategoriesListQuery } from 'api/queries/useCategoriesListQuery';
import { QUERIES } from 'constants/index';
import { useCallbackPrompt, useFormAttachments } from 'hooks';
import { Attachment, CreatePostFormValues, DocumentsType, PostDraft, SelectOption } from 'types';
import { objectsDifferences } from 'utils';

import { defaultValuesAdapter, formValuesAdapter } from '../adapters';

interface UseCreatePostFormReturn {
  handleFormSubmit: () => void;
  control: Control<CreatePostFormValues>;
  isLoading: boolean;
  handleAddImages: (files: FileList) => Promise<void>;
  handleAddDocuments: (files: FileList) => Promise<void>;
  handleAddVideos: (files: FileList) => Promise<void>;
  attachedImages: Attachment[];
  attachedVideos: Attachment[];
  attachedDocs: Attachment[];
  handleDeleteAttachment: (attachmentId: string, type: 'images' | 'docs' | 'videos') => void;
  handleCloseButton: () => void;
  handleCloseModal: () => void;
  handleSubmitConfirmModal: () => void;
  categoriesOptionsList: SelectOption[];
  isOpen: boolean;
  isDisableTags: boolean;
  isDirty: boolean;
  progress: number;
  isNotDisableButton: boolean;
  isBlockedRemoveAttachments: boolean;
  isPublicDocument: boolean;
}

interface UseCreatePostFormProps {
  draft?: PostDraft;
  selectPrivate?: boolean;
  assignedToId?: string;
  document?: PostListItem;
  isEdit?: boolean;
  tag: string;
}

export function useCreateDocumentForm({
  draft,
  selectPrivate,
  assignedToId,
  document,
  isEdit,
  tag,
}: UseCreatePostFormProps): UseCreatePostFormReturn {
  const postId = (draft?.id || document?.id) as string;
  const [isSuccess, setIsSuccess] = useState(false);
  const defaultValues = defaultValuesAdapter({ draft, document });
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, isDirty, isSubmitted, errors },
  } = useForm<CreatePostFormValues>({
    defaultValues,
    mode: 'onBlur',
  });

  const navigate = useNavigate();

  const attachedImages = watch('images');
  const attachedDocs = watch('docs');
  const attachedVideos = watch('videos');
  const documentType = watch('level');
  const filesChanges = objectsDifferences(
    { images: attachedImages, docs: attachedDocs, videos: attachedVideos },
    {
      images: defaultValues.images,
      docs: defaultValues.docs,
      videos: defaultValues.videos,
    },
  );
  const countOfAttachments = attachedImages.length + attachedDocs.length + attachedVideos.length;
  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    ((isDirty || Object.keys(filesChanges).length > 0) && !isSubmitted) ||
      Object.keys(errors).length > 0,
  );

  const {
    categoriesList,
    isLoading: isCategoryListLoading,
  }: { categoriesList: CategoryListItemExt[]; isLoading: boolean } = useCategoriesListQuery();

  const categoriesOptionsList = categoriesList
    .filter((category) => category.tag && category.tag !== 'untagged')
    .map((category) => {
      return {
        value: category?.tag || '',
        label: category.title,
        icon: category.icon,
        tag: category.tag,
      };
    });

  const { publishPostMutation, isLoading: isPublishPostLoading } = usePublishPostMutation({
    successText: t('toasts.document.success.create'),
    setIsSuccess,
    invalidateQueries: [QUERIES.LAST_UPDATED_DOCS, QUERIES.DOCUMENTS_LIST],
  });

  const { editPostMutation, isLoading: isEditPostLoading } = useEditPostMutation({
    successText: t('toasts.document.success.edit'),
    setIsSuccess,
    invalidateQueries: [QUERIES.LAST_UPDATED_DOCS, QUERIES.DOCUMENTS_LIST],
  });

  const isDisableTags =
    watch('level') === DocumentsType.PRIVATE || watch('level') === DocumentsType.SHARED;

  const {
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    isLoadingFileUpload,
    progress,
  } = useFormAttachments({
    setFormValue: setValue,
    attachedDocs,
    attachedImages,
    attachedVideos,
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    const attachment_ids = [...attachedImages, ...attachedDocs, ...attachedVideos].map(
      (file) => file.id,
    );
    const { title, description, level, tags } = data;

    const adaptedData = {
      post: formValuesAdapter({
        title,
        description,
        attachment_ids,
        level: level || '',
        ...(tags?.length && { tags: tags }),
        ...(assignedToId && { assigned_to: [assignedToId] }),
      }),
      postId: postId,
    };
    if (isEdit) {
      await editPostMutation(adaptedData);
      return;
    }
    await publishPostMutation(adaptedData);
  });

  function handleCloseButton() {
    navigate(-1);
  }

  async function handleSubmitConfirmModal() {
    if (isEdit) {
      confirmNavigation();
      navigate(-1);
      return;
    }
    confirmNavigation();
    navigate(-1);
  }

  useEffect(() => {
    if (isDisableTags) {
      setValue('tags', undefined);
    }
  }, [isDisableTags, setValue]);

  useEffect(() => {
    if (tag && !document?.tags) {
      setValue('tags', [tag]);
    }
  }, [tag, setValue, document?.tags]);

  useEffect(() => {
    if (selectPrivate) {
      setValue('level', DocumentsType.SHARED);
    }
  }, [selectPrivate, setValue]);

  useEffect(() => {
    if (document) {
      setValue('level', document.level);
      setValue('tags', document.tags);
    }
  }, [document, setValue]);

  useEffect(() => {
    if (isSubmitted && Object.keys(errors).length === 0 && isSuccess) {
      navigate(-1);
    }
  }, [isSubmitted, navigate, errors, attachedImages, attachedDocs, attachedVideos, isSuccess]);

  return {
    control,
    handleFormSubmit,
    isLoading:
      isSubmitting ||
      isPublishPostLoading ||
      isEditPostLoading ||
      isLoadingFileUpload ||
      isCategoryListLoading,
    handleAddDocuments,
    handleAddVideos,
    handleAddImages,
    attachedImages,
    attachedVideos,
    attachedDocs,
    handleDeleteAttachment,
    handleCloseButton,
    handleCloseModal: cancelNavigation,
    isOpen: showPrompt,
    handleSubmitConfirmModal,
    isDisableTags,
    isDirty,
    categoriesOptionsList,
    progress,
    isNotDisableButton: isDirty || Object.keys(filesChanges).length > 0,
    isBlockedRemoveAttachments: Boolean(countOfAttachments === 1 && isEdit),
    isPublicDocument: documentType === DocumentsType.PUBLIC,
  };
}
