import { useContext } from 'react';

import { AuthContext } from 'context';
import { AuthContextValues } from 'types';

type UseAuthReturn = AuthContextValues;

export function useAuth(): UseAuthReturn {
  return useContext(AuthContext);
}
