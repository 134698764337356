import { Slider, styled } from '@mui/material';

const boxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#000',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 26,
    width: 26,
    backgroundColor: '#000',
    boxShadow: boxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: boxShadow,
      },
    },
  },

  '& .MuiSlider-valueLabel': {
    fontSize: 36,
    lineHeight: '36px',
    fontWeight: 'normal',
    top: 0,
    backgroundColor: 'unset',

    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  '& .MuiSlider-rail': {
    opacity: 1,
  },

  [theme.breakpoints.down('desktop')]: {
    '& .MuiSlider-valueLabel': {
      fontSize: 18,
    },
  },
}));
