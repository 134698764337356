import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useCreateAchievementMutation } from 'api';
import { Links } from 'settings';
import { AchievementFormValues } from 'types';

import { formValuesAdapter } from '../adapter';

export function useCreateAchievementForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { employeeId = '' } = useParams();

  const achievementsLink = generatePath(Links.profile.achievements.achievement.index, {
    employeeId,
  });

  const navigateBack = useCallback(() => {
    if (state) {
      navigate(achievementsLink);
    } else {
      navigate(-1);
    }
  }, [achievementsLink, navigate, state]);

  const { createAchievementMutation, isLoading } = useCreateAchievementMutation({
    successText: t('toasts.achievement.success.create'),
    onSuccessAction: navigateBack,
  });

  const handleSubmit = useCallback(
    async (data: AchievementFormValues) => {
      await createAchievementMutation({ userId: employeeId, data: formValuesAdapter(data) });
    },
    [createAchievementMutation, employeeId],
  );

  return {
    handleSubmit,
    isLoading,
  };
}
