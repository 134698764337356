import { ChangeEvent, useCallback } from 'react';
import { Control, UseFormGetValues, useFieldArray } from 'react-hook-form';

import { PollFormValues } from 'types';

import { MAX_OPTIONS_NUMBER, MIN_OPTIONS_NUMBER } from '../constants';
import { getEmptyField } from './utils';

interface UseOptionsFieldsProps {
  control: Control<PollFormValues>;
  getValues: UseFormGetValues<PollFormValues>;
}

export function useOptionsFields({ control, getValues }: UseOptionsFieldsProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = getValues('options') ?? [];

  const handleRemoveField = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const handleAddField = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const otherFieldsFilled = [...options]
        .filter((_, i) => i !== index)
        .every(({ value }) => !!value);
      const allFieldsFilled = options.every(({ value }) => !!value);

      const { isEmptyField, emptyFieldIndex } = getEmptyField(options);

      const shouldRemoveEmpty =
        !e.target.value &&
        isEmptyField &&
        options.length > MIN_OPTIONS_NUMBER &&
        emptyFieldIndex !== index;

      const shouldRemoveCurrent =
        options.length > MIN_OPTIONS_NUMBER && !e.target.value && !otherFieldsFilled;

      allFieldsFilled && options.length < MAX_OPTIONS_NUMBER && handleAddField();

      shouldRemoveEmpty
        ? handleRemoveField(emptyFieldIndex)
        : shouldRemoveCurrent
        ? handleRemoveField(index)
        : null;
    },
    [options, handleAddField, handleRemoveField],
  );

  return {
    fields,
    handleChange,
  };
}
