import { Button, styled } from '@mui/material';

export const SubmitBtn = styled(Button)<{ isPostComment?: boolean }>`
  position: absolute;
  bottom: 18px;
  right: ${({ isPostComment }) => (isPostComment ? '156px' : '132px')};
  text-transform: none;
  @media (max-width: 1023px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    bottom: 18px;
    right: 6px;
    width: auto;
  }
`;
