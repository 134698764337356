import { ReactElement } from 'react';

import { useUsersCompanyListQuery } from 'api';
import { CompanySelect } from 'components';
import { SelectOption } from 'types';

import { useCompanySwitchMutation } from './hooks';
import { Wrapper } from './styled';

export function CompanySelector(): ReactElement {
  const { companyList = [], currentCompany } = useUsersCompanyListQuery();

  let selectedTeam: SelectOption = { value: '', label: '' };
  if (currentCompany?.id && companyList.length) {
    selectedTeam = companyList?.find(
      (company: SelectOption) => company.value === currentCompany.id,
    ) || { value: '', label: '' };
  }

  const { switchCompanyUserMutation } = useCompanySwitchMutation({ reload: true });

  const handleCompanyChange = (selectedCompanyId: string) => {
    switchCompanyUserMutation(selectedCompanyId);
  };

  return (
    <Wrapper>
      {companyList?.length && selectedTeam.label ? (
        <CompanySelect
          key="company"
          id="id"
          options={companyList}
          defaultValue={selectedTeam.label}
          name="company"
          onChange={handleCompanyChange}
        />
      ) : (
        <></>
      )}
    </Wrapper>
  );
}
