import { styled, Typography } from '@mui/material';

import { ReactComponent as FileIcon } from 'assets/icons/document.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/downloadFile.svg';

export const FileW = styled('div')<{ canDownload: boolean }>(({ theme, canDownload }) => ({
  width: '248px',
  height: '56px',
  background: theme.palette.white.main,
  marginBottom: '8px',
  borderRadius: '10px',
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  transition: 'all .3s',

  ...(canDownload && {
    '&:hover': {
      background: theme.palette.info.main,

      p: {
        color: theme.palette.white.main,
      },

      '.download-icon, .download-icon-wrapper': {
        display: 'flex',
      },

      '.file-icon, .file-name': {
        display: 'none',
      },
    },
  }),
}));

export const Wrapper = styled('div')`
  display: none;
  width: 248px;
  height: 100%;
  align-items: center;
`;

export const Badge = styled('span')`
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.info.main};
  color: ${({ theme }) => theme.palette.white.main};
`;

export const FileIconW = styled(FileIcon)`
  width: 21px;
  height: 21px;
  margin: 0 17px;
`;

export const DownloadIconW = styled(DownloadIcon)`
  width: 21px;
  height: 21px;
  margin: 0 17px;
  display: none;
`;

export const FileName = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const ContainerW = styled('div')`
  width: 248px;
  height: 56px;
  background: ${({ theme }) => theme.palette.white.main};
  border-radius: 10px;
  position: relative;
  cursor: not-allowed;
`;
