export interface CalendarEventApi {
  description: string;
  summary?: string;
  location: string;
  status: string;
  event_type: string;
  created: string;
  start: string;
  end: string;
}

export interface CalendarEvent extends CalendarEventApi {
  title: string;
  startTime: Date;
  endTime: Date;
}

export type CalendarEventsList = CalendarEvent[];

export enum CalendarTypes {
  google = 'google',
  ms = 'ms',
}
