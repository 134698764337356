import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { MenuItem } from '@mui/material';
import { ReactElement } from 'react';
import { Control, useController } from 'react-hook-form';

import { ReactComponent as CheckIcon } from 'assets/icons/checkMark.svg';
import { BaseTextField } from 'components';
import { useWindowSize } from 'hooks';
import { AchievementFormValues } from 'types';
import { required } from 'validations';

import { achievementsColors } from '../../constants';
import { defaultInputSx, menuSx, mobileInputSx, optionItemSx, textFieldSx } from './styles';

interface ColorsSelectProps {
  control: Control<AchievementFormValues>;
}

export function ColorsSelect({ control }: ColorsSelectProps): ReactElement {
  const { isMobile } = useWindowSize();

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: 'color',
    control,
    rules: required,
  });

  const inputSx = isMobile ? mobileInputSx : defaultInputSx;

  const options = achievementsColors.map((value) => ({ value }));

  return (
    <BaseTextField
      onChange={(e) => {
        onChange(e);
      }}
      value={value}
      error={Boolean(error)}
      helperText={error && error.message}
      select
      sx={textFieldSx}
      InputProps={{
        sx: inputSx,
      }}
      SelectProps={{
        multiple: false,
        disableUnderline: true,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 586,
          },
          sx: menuSx,
        },
        displayEmpty: true,
        renderValue: () => {
          return <MenuItem value={value} sx={optionItemSx(value)} />;
        },
        IconComponent: ExpandMoreRoundedIcon,
        value,
      }}
    >
      {options.map(({ value: optionValue }) => (
        <MenuItem key={optionValue} value={optionValue} sx={optionItemSx(optionValue)}>
          {optionValue === value && <CheckIcon />}
        </MenuItem>
      ))}
    </BaseTextField>
  );
}
