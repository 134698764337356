import { useQuery } from '@tanstack/react-query';

import { CategoryListItemExt } from 'api/types';
import { CategoriesListType, DocumentCategory } from 'types';

import { API_HANDLERS } from '../../apiHandlers';

interface UseCategoryListQueryReturn {
  categoryMap: CategoriesListType;
  categoriesList: CategoryListItemExt[];
  isLoading: boolean;
}

export function useCategoriesListQuery(): UseCategoryListQueryReturn {
  const { data, isLoading } = useQuery(['document_categories'], () =>
    API_HANDLERS.CATEGORY.GET_LIST(),
  );

  const categoriesList =
    data?.data.data.map((cat: { title: string }) => {
      return { ...cat, tag: cat.title.toLowerCase() };
    }) || [];

  const categoryMap: CategoriesListType = categoriesList.reduce(
    (acc: CategoriesListType, category: DocumentCategory) => {
      acc[`${category.title.toLowerCase()}`] = {
        ...category,
        label: category.title,
        tag: category.title.toLowerCase(),
      };
      return acc;
    },
    {},
  );

  return {
    categoryMap,
    categoriesList,
    isLoading,
  };
}
