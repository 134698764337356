import { IconButton, styled } from '@mui/material';

export const UploadButtons = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  @media (max-width: 1023px) {
    flex-direction: row;
    column-gap: 16px;
    margin-top: 8px;
    &.notMarginTop {
      margin-top: 0px;
    }
  }
`;

export const IconButtonW = styled(IconButton)`
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 17px 8px -12px rgba(0, 71, 255, 0.12);
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const ButtonW = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonLabel = styled('span')`
  font-size: 12px;
  line-height: 10px;
  color: ${({ theme }) => theme.palette.primary.p030};
  margin-top: 8px;
`;
