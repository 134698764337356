import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import {
  useDeactivateUserMutation,
  useInfiniteUsersListQuery,
  useReInviteUserMutation,
  useUserDetailsQuery,
} from 'api';
import { EMPLOYEE_REQUEST_LIMIT, EMPTY_SPACES_REGEX } from 'constants/index';
import { useAuth, useModal, useSessionState, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { Language, RemoveEmployeeArgument, UserStatus } from 'types';
import { stripHtml } from 'utils';
import { REQUIRED } from 'validations';

import { employeeTableAdapter } from '../adapters';

export function useEmployeeTable(isOffboarding: boolean) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [deleteEmployee, setDeleteEmployee] = useState<RemoveEmployeeArgument | null>(null);
  const [deletedComment, setDeletedComment] = useState<string>('');
  const [deletedCommentError, setDeletedCommentError] = useState<string>('');
  const [isFocusedDeletedComment, setIsFocusedDeletedComment] = useState<boolean>(false);
  const [employeeId, setEmployeeId] = useState('');

  const { ref, inView } = useInView();
  const { isManager } = useAuth();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const [state] = useSessionState('i18nextLng');
  const isSwedish = state === Language.swedish;

  const { isOpen, handleOpenModal, handleCloseModal } = useModal();
  const {
    isOpen: isOpenActivateModal,
    handleOpenModal: handleOpenActivateModal,
    handleCloseModal: handleCloseActivateModal,
  } = useModal();

  const { isLoading: isLoadingUser, user } = useUserDetailsQuery({ employeeId });

  const { deactivateUserMutation } = useDeactivateUserMutation({
    onSuccessFunc: handleCloseModal,
    invalidateQuery: 'employees',
  });

  const { reInviteUserMutation } = useReInviteUserMutation({
    invalidateQuery: 'employees',
    successText: t('toasts.employee.success.reinvite'),
  });

  const { isLoading, usersList, isFetchingNextPage, countOfActiveUsers } =
    useInfiniteUsersListQuery({
      limit: EMPLOYEE_REQUEST_LIMIT,
      offset: 0,
      inView,
      status: isManager
        ? [UserStatus.Active, UserStatus.Inactive, UserStatus.Invited, UserStatus.Registered]
        : [UserStatus.Active, UserStatus.Invited],
      detailed: true,
    });

  const isEmptyComment = !stripHtml(deletedComment).replace(EMPTY_SPACES_REGEX, '');

  const onHandleOpenDeleteModal = (arg: RemoveEmployeeArgument) => {
    setDeleteEmployee(arg);
    handleOpenModal();
  };

  const onHandleCloseModal = () => {
    setDeletedCommentError('');
    handleCloseModal();
  };

  const onDeactivateEmployee = async () => {
    setDeletedCommentError('');
    if (!deleteEmployee) {
      return;
    }

    if (
      isEmptyComment &&
      isOffboarding &&
      (deleteEmployee.status === UserStatus.Active ||
        deleteEmployee.status === UserStatus.Registered)
    ) {
      setDeletedCommentError(REQUIRED);
      return;
    }

    deactivateUserMutation({ userId: deleteEmployee?.id, comment: deletedComment });
  };

  const onHandleFocusRichEditor = () => {
    setDeletedCommentError('');
    if (isFocusedDeletedComment && isEmptyComment) {
      setDeletedCommentError(REQUIRED);
    }
    setIsFocusedDeletedComment(!isFocusedDeletedComment);
  };

  const detailsLink = isOffboarding
    ? Links.actions.employees.employee.index
    : pathname.includes('resources')
    ? Links.resources.employees.employee.index
    : Links.we.employees.employee.index;

  const handleRowClick = (id: string) => {
    navigate(
      generatePath(detailsLink, {
        employeeId: id,
      }),
    );
  };

  async function handleActivateClick(id: string) {
    setEmployeeId(id);
    handleOpenActivateModal();
  }

  const handleReInviteClick = (id: string) => {
    reInviteUserMutation({ userId: id });
  };

  return {
    isUsersListLoading: isLoading,
    usersList: employeeTableAdapter({
      data: usersList,
      removeButtonAction: onHandleOpenDeleteModal,
      activateButtonClick: handleActivateClick,
      reInviteButtonClick: handleReInviteClick,
      isSwedish,
      isManager,
      isMobile,
      isLoadingUser,
    }),
    lastListRef: ref,
    isOpenModal: isOpen,
    handleCloseModal: onHandleCloseModal,
    onDeactivateEmployee,
    isFetchingNextPage,
    countOfActiveUsers,
    setDeletedComment,
    deleteEmployee,
    deletedComment,
    deletedCommentError,
    onHandleFocusRichEditor,
    handleRowClick,
    isOpenActivateModal,
    handleOpenActivateModal,
    handleCloseActivateModal,
    user,
    isLoadingUser,
    isManager,
  };
}
