import { NewPostPayload } from 'api';
import { removeExtraSpaces } from 'utils';

interface FormValuesAdapterProps {
  title: string;
  description: string;
  attachment_ids: string[];
  level: string;
  tags?: string[];
}

export function formValuesAdapter({
  title,
  description,
  attachment_ids,
  ...otherProps
}: FormValuesAdapterProps): NewPostPayload {
  return {
    title: removeExtraSpaces(title),
    description: removeExtraSpaces(description),
    type: 'docs',
    ...(!!attachment_ids.length && { attachment_ids }),
    ...otherProps,
  };
}
