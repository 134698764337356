import { forwardRef, ReactNode } from 'react';

import { ContainerW, Label, LabelW, Row, Title, Value } from './styled';

interface DetailsContainerProps {
  title: string;
  data: Record<string, { label: string; value: string | ReactNode }>;
}

const DetailsContainer = forwardRef<HTMLDivElement, DetailsContainerProps>(
  ({ title, data }, ref) => {
    return (
      <ContainerW ref={ref}>
        <Title>{title}</Title>

        {Object.keys(data).map((key) => (
          <Row key={key}>
            <LabelW>
              <Label>{data[key].label}</Label>
            </LabelW>
            <Value>{data[key].value}</Value>
          </Row>
        ))}
      </ContainerW>
    );
  },
);

DetailsContainer.displayName = 'CompanyDetails';

export { DetailsContainer as CompanyDetails };
