import { ComponentType, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks';
import { Links } from 'settings';

interface PrivateRouteProps<T> {
  component: ComponentType<Partial<T>>;
  componentProps?: T;
}

export function PrivateRoute<PropsTypes>({
  component: Component,
  componentProps,
}: PrivateRouteProps<PropsTypes>): ReactElement | null {
  const { user } = useAuth();
  const { pathname } = useLocation();

  if (!Component) {
    return null;
  }

  if (user) {
    return <Component {...componentProps} />;
  }

  sessionStorage.setItem('beforeLoginUrl', pathname);

  return (
    <Navigate
      to={{
        pathname: Links.auth.signIn,
      }}
    />
  );
}
