import { useNavigate } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';

import { HeaderW, PageTitle, CloseButtonW } from './styled';

interface PageHeaderProps {
  pageTitle: string;
}

export function PageHeader({ pageTitle }: PageHeaderProps) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <HeaderW>
      <PageTitle variant="h1">{pageTitle}</PageTitle>
      <CloseButtonW onClick={goBack}>
        <CloseIcon />
      </CloseButtonW>
    </HeaderW>
  );
}
