import { SxProps } from '@mui/material';
import { ReactElement } from 'react';

import { Spinner } from 'components';
import { Comment, InViewItemRef } from 'types';

import { CommentListItem } from '../CommentListItem';
import { ListW } from './styled';

interface CommentsListProps {
  postId?: string;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  comments: Comment[];
  lastItemRef?: InViewItemRef;
  listWrapSx?: SxProps;
}

export function CommentsList({
  comments,
  isLoading,
  lastItemRef,
  isFetchingNextPage,
  listWrapSx,
}: CommentsListProps): ReactElement {
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ListW sx={listWrapSx}>
      {comments.map((comment, idx) => (
        <CommentListItem
          key={comment.id}
          comment={comment}
          lastItemRef={idx === comments.length - 1 ? lastItemRef : undefined}
        />
      ))}

      {isFetchingNextPage && <Spinner wrapperSx={{ bottom: '0', top: 'unset' }} />}
    </ListW>
  );
}
