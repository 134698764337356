import { ReactElement, ReactNode } from 'react';

import { PageW } from './styled';

interface PageWrapperProps {
  children: ReactNode;
}

export function PageWrapper({ children }: PageWrapperProps): ReactElement {
  return <PageW>{children}</PageW>;
}
