import { SliderProps } from '@mui/material/Slider';
import { ReactElement } from 'react';

import { StyledSlider } from './styled';

export function Slider({ value, onChange, ...otherProps }: SliderProps): ReactElement {
  return (
    <StyledSlider
      size="small"
      value={value}
      onChange={onChange}
      valueLabelDisplay="on"
      {...otherProps}
    />
  );
}
