import { styled } from '@mui/material';

export const PageW = styled('div')`
  padding-left: 88px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 96px;
  }
`;

export const ToggleW = styled('div')`
  display: flex;
  justify-content: flex-end;
  max-width: 1106px;
  width: 100%;
  position: relative;
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;
