import { IconButton, styled } from '@mui/material';

import { ReactComponent as ArrowIconComponent } from 'assets/icons/arrow.svg';

export const IconButtonW = styled(IconButton)`
  position: relative;
  left: -8px;
`;

export const ArrowIcon = styled(ArrowIconComponent)`
  color: ${({ theme }) => theme.palette.primary.p080};
`;
