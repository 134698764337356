import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { EquipmentPayload } from 'types';

interface UseEquipmentDetailsQueryReturn {
  isLoading: boolean;
  isSuccess: boolean;
  data?: EquipmentPayload;
}

interface UseEquipmentDetailsQueryProps {
  userId: string;
  equipmentId: string;
}

export function useEquipmentDetailsQuery({
  userId,
  equipmentId,
}: UseEquipmentDetailsQueryProps): UseEquipmentDetailsQueryReturn {
  const { isLoading, isSuccess, data } = useQuery(
    ['equipment', equipmentId],
    () => API_HANDLERS.EQUIPMENTS.DETAILS({ userId, equipmentId }),
    {
      enabled: !!userId && !!equipmentId,
    },
  );

  return {
    isLoading,
    isSuccess,
    data: data?.data,
  };
}
