import { theme } from 'styles';

export const defaultStyles = {
  '& .MuiSelect-select.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',

    '&:focus': {
      background: 'none',
    },
  },

  '& .MuiSelect-icon': {
    fontSize: '30px',
    right: '10px',
    color: theme.palette.primary.p030,
  },
};

export const defaultMobileStyles = {
  '& .MuiSelect-select.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',

    '&:focus': {
      background: 'none',
    },
  },

  '& .MuiSelect-icon': {
    fontSize: '24px',
    right: '10px',
    color: theme.palette.primary.p030,
  },
};

export const menuItemStyles = {
  fontSize: '18px',
  padding: '0px 24px',
  width: '300px',
};
