import { IconButton, styled } from '@mui/material';

export const StyledIconBtn = styled(IconButton)(({ theme }) => ({
  background: theme.palette.info.main,
  boxShadow: '0px 34px 34px -18px rgba(0, 76, 189, 0.08)',
  width: '85px',
  height: '85px',
  color: theme.palette.white.main,

  '&:hover': {
    background: theme.palette.info.dark,
  },

  '&.Mui-disabled': {
    background: theme.palette.info.p030,
    color: theme.palette.white.main,
    cursor: 'not-allowed',
    pointerEvents: 'all',
  },

  svg: {
    width: '1.5em',
    height: '1.5em',
  },
  [theme.breakpoints.down('desktop')]: {
    width: '65px',
    height: '65px',
    svg: {
      width: '1em',
      height: '1em',
    },
    '&.fixed-bottom': {
      margin: 0,
      position: 'fixed',
      bottom: '24px',
      right: '24px',
    },
  },
}));
