import { ReactElement } from 'react';

import { EquipmentFormValues, PostDraft, User } from 'types';

import { EquipmentForm } from '../EquipmentForm';
import { useRegisterEquipmentForm } from './hooks';

interface RegisterEquipmentFormProps {
  draft: PostDraft | null;
  usersList: User[];
  defaultValues: (isDefaultEditable: boolean) => EquipmentFormValues;
}

export function RegisterEquipmentForm({
  draft,
  usersList,
  defaultValues,
}: RegisterEquipmentFormProps): ReactElement {
  const { handleSubmit, isLoading } = useRegisterEquipmentForm();

  return (
    <EquipmentForm
      draft={draft}
      isLoading={isLoading}
      usersList={usersList}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    />
  );
}
