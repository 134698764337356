import * as ERROR_CODES from 'constants/errorCodes';
import { KeyValuePairs } from 'types';

import i18n from '../i18n';

export const API_ERRORS_BY_CODES: KeyValuePairs = {
  [ERROR_CODES.EMAIL_IS_USED]: i18n.t('toasts.codes.emailIsUsed'),
  [ERROR_CODES.COMPANY_NAME_IS_USED]: i18n.t('toasts.codes.companyNameIsUsed'),
  [ERROR_CODES.INVALID_CREDENTIALS]: i18n.t('toasts.codes.invalidCredentials'),
  [ERROR_CODES.INVALID_OTT]: i18n.t('toasts.codes.invalidOtt'),
  [ERROR_CODES.UNAUTHORIZED]: i18n.t('toasts.codes.unauthorized'),
  [ERROR_CODES.NOT_CONFIRMED_ACCOUNT]: i18n.t('toasts.codes.notConfirmedAccount'),
  [ERROR_CODES.ATTACHMENT_FILE_TYPE_ERROR]: i18n.t('toasts.codes.attachmentFileTypeError'),
  [ERROR_CODES.ATTACHMENT_COUNT_LIMIT_REACHED]: i18n.t('toasts.codes.attachmentCountLimitReached'),
  [ERROR_CODES.LOGIN_DEACTIVATED_USER]: i18n.t(
    'toasts.codes.loginOrRestorePasswordDeactivatedUser',
  ),
  [ERROR_CODES.TOO_LARGE_FILE]: i18n.t('toasts.codes.tooLargeFile'),
  [ERROR_CODES.SUBSCRIPTION_EXPIRED]: i18n.t('toasts.codes.subscriptionExpired'),
  [ERROR_CODES.SUBSCRIPTION_EXPIRED_EMPLOYEE]: i18n.t('toasts.codes.subscriptionExpiredEmployee'),
  [ERROR_CODES.UPDATE_NOT_ALLOWED]: i18n.t('toasts.codes.updateIsNotAllowed'),
  [ERROR_CODES.PENDING_USER_RESET_PASSWORD]: i18n.t('toasts.codes.pendingUserResetPassword'),
  [ERROR_CODES.VIEW_OPERATION_IS_NOT_ALLOWED]: i18n.t('toasts.codes.viewOperationIsNotAllowed'),
};

export const INVITE_FORM_EMAILS_LIMIT = i18n.t('toasts.codes.inviteFormEmailsLimit');
export const COMPANY_CREATED_RESEND_ERROR = i18n.t('toasts.codes.companyCreatedResendError');
