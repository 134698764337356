import i18n from 'i18n';
import { Links } from 'settings';

export const pageLinks = {
  signIn: {
    text: i18n.t('login.signInBtn'),
    link: String(Links.auth.signIn),
  },
  signUp: {
    text: i18n.t('signUp.signUpBtn'),
    link: String(Links.auth.registration.index),
  },
  resetPassword: {
    text: i18n.t('restorePassword.expired.link'),
    link: String(Links.auth.requestPasswordReset),
  },
};
