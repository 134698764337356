import { Link, styled, Typography } from '@mui/material';

export const FooterText = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p050};
  white-space: break-spaces;
  max-width: 370px;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 16px;
  }
`;

export const LinkW = styled(Link)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
  white-space: nowrap;
`;
