import { styled, Typography } from '@mui/material';

export const PageTitle = styled(Typography)`
  font-weight: 700;
  font-size: 70px;
  line-height: 70px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 42px;
  white-space: break-spaces;
  @media (max-width: 1023px) {
    padding: 0px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }
`;

export const PageW = styled('div')<{ isManager: boolean }>`
  width: 100%;
  padding-left: 88px;
  @media (max-width: 1023px) {
    padding: 0px;
    padding-bottom: ${({ isManager }) => (isManager ? '96px' : '16px')};
  }
`;

export const TopMenuW = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: top;
  width: 850px;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;
