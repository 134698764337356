import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeaderBoldTitle, PageWrapper } from 'components';

import { CreateAchievementForm } from '../../components';
import { defaultValues } from './defaultValues';
import { ContentW, FormW } from './styled';

export function CreateAchievementPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PageHeaderBoldTitle title={t('career.achievements.create.title')} />
      <ContentW>
        <FormW>
          <CreateAchievementForm defaultValues={defaultValues} />
        </FormW>
      </ContentW>
    </PageWrapper>
  );
}
