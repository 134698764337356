import { Box } from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { RegisterCompanyParams, useRegisterCompanyMutation } from 'api';
import { alert } from 'components';
import { AuthPageWrapper, AuthPageTitle } from 'features/auth';
import { LanguageSelector } from 'features/languageSelector';
import { useWindowSize } from 'hooks';
import { Links } from 'settings';
import { AuthPageLink } from 'types';

import { EmailW, ResendButton, TextW } from './styled';

interface LocationState {
  requestPayload: RegisterCompanyParams;
}

export function RegisterCompanySuccessPage(): ReactElement {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const requestPayload = (state as LocationState)?.requestPayload;

  const { registerCompanyMutation, isLoading } = useRegisterCompanyMutation({ resend: true });

  async function handleResend() {
    await registerCompanyMutation(requestPayload);
    alert.success(t('toasts.emailSent'));
  }

  useEffect(() => {
    if (!requestPayload) {
      navigate(Links.auth.registration.index);
    }
  }, [requestPayload, navigate]);

  return (
    <AuthPageWrapper link={AuthPageLink.signIn} isLoading={isLoading}>
      <AuthPageTitle>{t('registerSuccess.title')}</AuthPageTitle>
      <TextW sx={{ marginTop: isMobile ? '10px' : '25px' }}>
        {t('registerSuccess.text.weHaveSent')}
        {!isMobile && <br />}
        <EmailW>{requestPayload.email}</EmailW>. {t('registerSuccess.text.pleaseSelect')}
      </TextW>

      <TextW sx={{ marginTop: isMobile ? '24px' : '42px' }}>
        {t('registerSuccess.question.heventReceived')}{' '}
        <ResendButton onClick={handleResend}>{t('common.clickHere')}</ResendButton>{' '}
        {t('registerSuccess.question.sentAgain')}
      </TextW>

      <Box sx={{ marginTop: '24px' }}>
        <LanguageSelector />
      </Box>
    </AuthPageWrapper>
  );
}
