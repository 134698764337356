import { styled } from '@mui/material';

export const Thumb = styled('div')`
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  &:hover {
    .remove-btn {
      display: flex;
    }
  }
  @media (max-width: 1023px) {
    .remove-btn {
      display: flex;
    }
  }
`;

export const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const RemoveCircle = styled('div')`
  display: none;
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const SpinnerW = styled('div')`
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 16px;
  cursor: not-allowed;
  background-color: ${({ theme }) => theme.palette.info.p030};
  position: relative;
`;

export const SpinnerBackdrop = styled('span')`
  background: rgba(255, 255, 255, 0.5);
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: not-allowed;
`;
