import {
  Dialog as DialogMUI,
  DialogActions as DialogActionsMUI,
  DialogContent as DialogContentMUI,
  styled,
  Button,
  Typography,
  Link,
} from '@mui/material';

export const DialogActions = styled(DialogActionsMUI)(({ theme }) => ({
  padding: 0,
  marginTop: '48px',
  paddingLeft: '48px',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    paddingLeft: '0px',
    marginTop: '20px',
  },
}));

export const DialogContent = styled(DialogContentMUI)`
  padding: 0;
  margin-top: 36px;
  height: 538px;

  overflow-y: auto;
  @media (max-width: 1023px) {
    height: unset;
    margin-top: 20px;
    overflow-y: hidden;
  }
`;

export const Dialog = styled(DialogMUI)(({ theme }) => ({
  '.MuiDialog-paper': {
    maxWidth: '900px',
    width: '900px',
    maxHeight: '650px',
    padding: '24px 16px 48px 24px',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(20px)',
    borderRadius: '15px',
    margin: 0,
  },

  '.MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
  },
  [theme.breakpoints.down('desktop')]: {
    '.MuiDialog-paper': {
      padding: '16px 16px 32px',
      margin: '32px 8px',
      maxHeight: 'unset',
      maxWidth: 'calc(100% - 16px)',
    },
  },
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  textTransform: 'none',
  fontSize: '24px',
  lineHeight: '24px',
  color: theme.palette.primary.main,

  '.MuiButton-startIcon': {
    fontSize: '2rem',
    marginRight: '24px',
  },

  '.MuiSvgIcon-root': {
    width: '1.5em',
    height: '1.5em',
  },
  [theme.breakpoints.down('desktop')]: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 500,
  },
}));

export const ResendButton = styled('span')`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.info.main};
  margin-left: 6px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-transform: none;
  margin-bottom: 7px;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const ContentW = styled('div')`
  font-weight: 500;
  font-size: 24px;
  max-width: 756px;
  line-height: 36px;
  padding-left: 48px;
  color: ${({ theme }) => theme.palette.primary.p080};
  white-space: break-spaces;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    padding-left: 0px;
  }
`;

export const FooterNote = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p050};
  width: 300px;
  @media (max-width: 1023px) {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ContinueBtn = styled(Button)`
  margin-right: 48px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  border-radius: 12px;
  width: 265px;
  height: 64px;
  text-transform: none;
  box-shadow: none;
  @media (max-width: 1023px) {
    width: 100%;
    margin-right: 0px;
    margin-top: 12px;
  }
`;

export const LinkW = styled(Link)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
`;
