import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-named-as-default
import Swiper, { Pagination } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';

import { useQuickLinksPresetQuery } from 'api';
import { Spinner } from 'components';
import { getArrayChunks } from 'utils';

import { QuickLink } from '../QuickLink';
import { ButtonW, ContentW, ListW, MobileListW, MobileW, TitleW } from './styled';
import { useAddLinkMutation } from './useAddLinkMutation';

interface RecommendedLinksProps {
  setIsLoading: (isLoading: boolean) => void;
}

export function RecommendedLinks({ setIsLoading }: RecommendedLinksProps): ReactElement {
  const { t } = useTranslation();
  const { links, isLoading } = useQuickLinksPresetQuery();
  const { isLoading: isAddLoading, addLinkMutation } = useAddLinkMutation();
  const mobileWidth = 520;

  const [_activeIndex, setActiveIndex] = useState(0);
  const [swiperRef, setSwiperRef] = useState<Swiper | null>(null);

  function getItemsCountInChunk(width: number) {
    return mobileWidth > width ? 3 : 6;
  }

  const [itemsCount, setItemsCount] = useState(getItemsCountInChunk(window.innerWidth));

  useEffect(() => {
    function handleWindowResize() {
      setItemsCount(getItemsCountInChunk(window.innerWidth));
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(isAddLoading);
  }, [isAddLoading, setIsLoading]);

  async function handleAddLink(id: string) {
    await addLinkMutation(id);
  }

  const chunks = getArrayChunks(links, itemsCount);

  function handleClickAddBtn(e: SyntheticEvent, id: string) {
    e.stopPropagation();
    handleAddLink(id);
  }

  return (
    <ContentW>
      <TitleW>{t('quickLinks.recommended')}</TitleW>
      {isLoading && <Spinner />}

      {/* Desktop view */}
      <ListW>
        {links.map((link) => (
          <QuickLink {...link} key={link.id}>
            <ButtonW
              variant="outlined"
              color="info"
              disabled={isAddLoading}
              onClick={(e) => handleClickAddBtn(e, link.id)}
            >
              {t('quickLinks.add')}
            </ButtonW>
          </QuickLink>
        ))}
      </ListW>

      {/* Mobile view with Swiper */}
      <MobileW>
        <SwiperComponent
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          onSwiper={(swiper) => setSwiperRef(swiper)}
          pagination={true}
          modules={[Pagination]}
          style={{ maxHeight: '438px' }}
        >
          {Object.keys(chunks).map((key) => (
            <SwiperSlide
              key={key}
              style={{ height: swiperRef?.height || '438px', maxHeight: '438px' }}
            >
              <MobileListW>
                {chunks[key].map((link) => (
                  <QuickLink {...link} key={link.id}>
                    <ButtonW
                      variant="outlined"
                      color="info"
                      disabled={isAddLoading}
                      onClick={(e) => handleClickAddBtn(e, link.id)}
                    >
                      {t('quickLinks.add')}
                    </ButtonW>
                  </QuickLink>
                ))}
              </MobileListW>
            </SwiperSlide>
          ))}
        </SwiperComponent>
      </MobileW>
    </ContentW>
  );
}
