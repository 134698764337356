import { ReactElement } from 'react';

import { Goal, GoalStatus, User } from 'types';

import { GoalCard } from '../GoalCard';
import { MAX_PREVIEW_GOALS_AMOUNT } from './constants';
import { ListW } from './styled';

interface GoalsListProps {
  data: Goal[];
  achievementId: string;
  employeeId: string;
  usersList: User[];
  isProfilePreview?: boolean;
}

export function GoalsList({
  data,
  achievementId,
  employeeId,
  usersList,
  isProfilePreview,
}: GoalsListProps): ReactElement {
  return (
    <ListW>
      {data
        .slice(0, isProfilePreview ? MAX_PREVIEW_GOALS_AMOUNT : data.length)
        .map(
          ({
            id,
            title,
            description,
            status,
            deadline,
            comments_count,
            assigned_to_ids,
            owner_id,
            assigned_type,
          }) =>
            (status !== GoalStatus.ACCOMPLISHED || !isProfilePreview) && (
              <GoalCard
                key={id}
                id={id}
                title={title}
                description={description}
                status={status}
                deadline={deadline}
                achievementId={achievementId}
                employeeId={employeeId}
                commentsCount={comments_count}
                assigneeIds={assigned_to_ids}
                usersList={usersList}
                ownerId={owner_id}
                isAllAssignee={assigned_type === 'all'}
              />
            ),
        )}
    </ListW>
  );
}
