import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ReceiptIcon } from 'assets/icons/receipt.svg';
import { Links } from 'settings';

import { ContainerW, DescriptionW, FlexW, PreviewTitle, SubTitle, Title, Wrapper } from './styled';

export function OtherResources(): ReactElement {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <PreviewTitle to={Links.resources.companyInfo}>
        {t('resources.otherResources.previewTitle')}
      </PreviewTitle>
      <ContainerW to={Links.resources.companyInfo}>
        <FlexW>
          <Box sx={{ width: '32px', height: '32px' }}>
            <ReceiptIcon />
          </Box>
          <DescriptionW>
            <Title>{t('resources.otherResources.title')}</Title>
            <SubTitle>{t('resources.otherResources.subTitle')}</SubTitle>
          </DescriptionW>
        </FlexW>
      </ContainerW>
    </Wrapper>
  );
}
