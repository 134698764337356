import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageHeaderBoldTitle } from 'components';
import { EmployeesTable } from 'features/employees/components';
import { Links } from 'settings';

import { PageW } from './styled';

export const OffboardingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onNavigateBack = () => {
    navigate(Links.actions.index);
  };
  return (
    <PageW>
      <PageHeaderBoldTitle
        title={t('offboarding.title')}
        onClickCloseIcon={onNavigateBack}
        buttonPosition="static"
      />
      <EmployeesTable isOffboarding={true} />
    </PageW>
  );
};
