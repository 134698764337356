import { styled } from '@mui/material';

export const PageW = styled('div')`
  padding-left: 88px;
  position: relative;
  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 16px;
  }
`;

export const ListsW = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-top: 36px;
  @media (max-width: 1023px) {
    margin-top: 0px;
  }
`;

export const MobileContainerW = styled('div')`
  width: 100%;
  position: relative;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  padding: 16px;
  margin-top: 16px;
`;
