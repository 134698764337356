import { Button, styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const NotificationBar = styled('div')`
  background: #e5f5fe;
  padding: 16px;
  border-radius: 3px;
  max-width: 1105px;
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1023px) {
    padding: 16px;
    align-items: flex-start;
    margin-bottom: 16px;
  }
`;

export const FlexW = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const LinkW = styled(NavLink)`
  color: ${({ theme }) => theme.palette.info.main};
  margin-right: 16px;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;

  @media (max-width: 1023px) {
    font-weight: 500;
  }
`;

export const TextW = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: break-spaces;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const ContentW = styled('div')`
  margin-left: 16px;
  @media (max-width: 1023px) {
    margin-left: 16px;
  }
`;

export const ButtonW = styled(Button)`
  color: ${({ theme }) => theme.palette.info.main};
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 14px;
  position: relative;
  right: 8px;
  /* padding: 0; */
`;
