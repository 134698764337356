import { styled } from '@mui/material';

export const BackdropW = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1200;
`;

export const SpinnerWrapper = styled('div')`
  position: 'relative';
`;

export const BackdropPercentage = styled('div')`
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;
