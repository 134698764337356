import { useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { useAchievementsQuery, useUsersListQuery } from 'api';
import { Links } from 'settings';

export function useAchievementsPage() {
  const { employeeId = '' } = useParams();
  const { data: achievements, isLoading } = useAchievementsQuery({ userId: employeeId });
  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  const addAchievementLink = generatePath(Links.profile.achievements.createAchievement, {
    employeeId,
  });

  const activeAchievements = useMemo(
    () => achievements.filter((achievement) => achievement.progress !== 100),
    [achievements],
  );

  const accomplishedAchievements = useMemo(
    () => achievements.filter((achievement) => achievement.progress === 100),
    [achievements],
  );

  const isAchievementsList = !!achievements.length;
  const isActiveAchievementsList = !!activeAchievements.length;
  const isAccomplishedAchievementsList = !!accomplishedAchievements.length;

  return {
    isLoading: isLoading || isLoadingUsersList,
    isAchievementsList,
    isActiveAchievementsList,
    isAccomplishedAchievementsList,
    employeeId,
    addAchievementLink,
    activeAchievements,
    accomplishedAchievements,
    usersList,
  };
}
