import { ReactElement } from 'react';

import { ReactionTypes } from 'api';
import { ReactComponent as EmptyStateReactions } from 'assets/icons/postReactions/emptyState.svg';
import { ReactComponent as HeartReaction } from 'assets/icons/postReactions/heart.svg';
import { ReactComponent as LikeReaction } from 'assets/icons/postReactions/like.svg';

interface ReactionInlineProps {
  type: ReactionTypes | null;
}

export function ReactionInline({ type }: ReactionInlineProps): ReactElement {
  switch (type) {
    case ReactionTypes.HEART:
      return <HeartReaction />;
    case ReactionTypes.LIKE:
      return <LikeReaction />;
    default:
      return <EmptyStateReactions />;
  }
}
