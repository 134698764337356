export const avatarWrapperStyles = { position: 'absolute', bottom: '-18px', left: '-11px' };
export const avatarWrapperMobileStyles = { position: 'absolute', bottom: '-30px', left: '0px' };

export const multiSelectStyles = (isMobile: boolean) => ({
  height: 'auto',
  minHeight: isMobile ? '64px' : '85px',
  opacity: 1,
  '.MuiSelect-select.MuiSelect-select': {
    height: 'auto',
    padding: isMobile ? '12px 40px 12px 18px' : '20px 60px 20px 37px',
    '& > div': {
      margin: 0,
    },
  },
  '.MuiSvgIcon-root.MuiSelect-icon': {
    top: isMobile ? '12px' : '22px',
  },
});

export const descriptionWrapSx = {
  flexWrap: 'nowrap',
  gap: '24px',
};

export const descriptionMobileWrapSx = {
  flexWrap: 'wrap',
  flexDirection: 'column',
  gap: '0',
};
