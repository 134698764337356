import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { RegisterRequestedEquipmentRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseRegisterRequestedEquipmentMutationReturn {
  registerRequestedEquipmentMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    RegisterRequestedEquipmentRequestParams,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
}

interface UseRegisterRequestedEquipmentMutationProps {
  successText?: string;
  invalidateQueries?: string[];
}

export function useRegisterRequestedEquipmentMutation({
  successText,
  invalidateQueries,
}: UseRegisterRequestedEquipmentMutationProps): UseRegisterRequestedEquipmentMutationReturn {
  const {
    mutateAsync: registerRequestedEquipmentMutation,
    isLoading,
    isSuccess,
  } = useMutation(API_HANDLERS.EQUIPMENTS.REGISTER_REQUESTED, {
    onSuccess: () => {
      successText && alert.success(successText);
      invalidateQueries && queryClient.invalidateQueries(invalidateQueries);
    },
  });

  return {
    isLoading,
    isSuccess,
    registerRequestedEquipmentMutation,
  };
}
