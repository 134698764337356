import { useMutation } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { queryClient } from 'api/queryClient';

export function useAnswerPostMutation() {
  const { mutateAsync: answerPostMutation, isLoading } = useMutation(API_HANDLERS.POST.ANSWER, {
    onSuccess: () => {
      queryClient.invalidateQueries(['posts']);
      queryClient.invalidateQueries(['post-details']);
      queryClient.invalidateQueries(['reactions']);
    },
  });

  return {
    answerPostMutation,
    isLoading,
  };
}
