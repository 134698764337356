import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';
import { queryClient } from 'api/queryClient';
import { QUERIES } from 'constants/index';

import { EditUserRequestParams, UserDetailsResponse } from '../types';

interface UseEditUserMutationReturn {
  editUserMutation: UseMutateAsyncFunction<
    AxiosResponse<UserDetailsResponse, unknown>,
    unknown,
    EditUserRequestParams,
    unknown
  >;
  isLoading: boolean;
}

export function useEditUserMutation(employeeId?: string): UseEditUserMutationReturn {
  const { mutateAsync: editUserMutation, isLoading } = useMutation(API_HANDLERS.USER.EDIT, {
    onSuccess: () => {
      if (employeeId) {
        queryClient.invalidateQueries([QUERIES.USER_DETAILS, employeeId]);
      }
    },
  });

  return {
    editUserMutation,
    isLoading,
  };
}
