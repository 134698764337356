import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { Links } from 'settings';
import { CreatePasswordFormValues } from 'types';

import { defaultValues } from './config';

interface UseCreatePasswordFormReturn {
  isLoading: boolean;
  handleFormSubmit: () => void;
  control: Control<CreatePasswordFormValues>;
  validatePasswords: () => void;
}

interface LocationState {
  ott: string;
}

export function useCreatePasswordForm(): UseCreatePasswordFormReturn {
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<CreatePasswordFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { t } = useTranslation();

  const pass1 = watch('password');
  const pass2 = watch('repeatPassword');

  const { ott } = state as LocationState;

  useEffect(() => {
    // do not validate if main password field is empty
    if (pass1.trim() === '') {
      clearErrors('repeatPassword');
      return;
    }
  }, [clearErrors, pass1, pass2, setError]);

  function validatePasswords() {
    // do not validate if main password field is empty
    if (pass1.trim() === '') {
      clearErrors('repeatPassword');
      return;
    }

    if (pass1 === pass2) {
      clearErrors('repeatPassword');
      return;
    }

    setError('repeatPassword', { message: t('createPassword.notMatchError') });
  }

  const { mutateAsync: createPasswordResetMutation, isLoading } = useMutation(
    API_HANDLERS.AUTH.RESTORE_PASSWORD,
    {
      onSuccess: () => {
        alert.success(t('createPassword.passwordUpdatedAlert'));
        navigate(Links.auth.signIn);
      },
    },
  );

  const handleFormSubmit = handleSubmit(async ({ password, repeatPassword }) => {
    if (password !== repeatPassword) {
      return;
    }

    await createPasswordResetMutation({
      password,
      ott,
    });
  });

  return {
    isLoading: isSubmitting || isLoading,
    control,
    handleFormSubmit,
    validatePasswords,
  };
}
