import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES } from 'constants/index';

interface UseGetKpiDetailsQueryProps {
  limit?: number;
  kpi_status?: string;
}

export function useGetKpiDetailsQuery({ kpi_status, limit }: UseGetKpiDetailsQueryProps) {
  const { data: kpiData, isLoading } = useQuery(
    [QUERIES.VISION_AND_TARGETS],
    () => API_HANDLERS.KPI.GET_LIST({ limit, kpi_status }),
    {
      cacheTime: 0,
    },
  );

  return {
    isLoading,
    vision: kpiData?.data.vision || '',
    targets: kpiData?.data.targets || [],
  };
}
