import { styled, Typography } from '@mui/material';

export const PageW = styled('div')`
  padding-left: 88px;
  @media (max-width: 1023px) {
    padding: 0px;
    padding-bottom: 16px;
  }
`;

export const PostSubmissionNote = styled(Typography)`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.primary.p080};
  white-space: break-spaces;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
`;
