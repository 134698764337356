import { useGetKpiDetailsQuery } from '../../hooks';
import { sortTargetByStatus } from './utils';

export function useKpiFullPageDetails() {
  const { isLoading, vision, targets } = useGetKpiDetailsQuery({});

  const { activeTargets, completedTargets } = sortTargetByStatus(targets);

  return { isLoading, vision, activeTargets, completedTargets };
}
