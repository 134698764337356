import { Dialog as DialogMUI, styled, Button, css } from '@mui/material';

export const Dialog = styled(DialogMUI)(({ theme }) => ({
  '.MuiDialog-paper': {
    maxWidth: '514px',
    maxHeight: '574px',
    padding: '32px',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(20px)',
    borderRadius: '15px',
  },

  '.MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
  },
  [theme.breakpoints.down('desktop')]: {
    '.MuiDialog-paper': {
      padding: '16px 16px 32px',
      marginBottom: 'auto',
    },
  },
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  textTransform: 'none',
  fontSize: '24px',
  lineHeight: '24px',
  color: theme.palette.primary.main,

  '.MuiButton-startIcon': {
    fontSize: '2rem',
    marginRight: '24px',
  },

  '.MuiSvgIcon-root': {
    width: '1.5em',
    height: '1.5em',
  },
  [theme.breakpoints.down('desktop')]: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
  },
}));

export const DialogContainer = styled('div')`
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const DialogHeader = styled('div')`
  width: 450px;
  height: 52px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0px -1px 0px #e1e3e5;
`;

export const DialogHeaderAll = styled('div')`
  width: 59px;
  padding: 0px 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
`;

export const DialogHeaderLike = styled('div')<{ disabled: boolean }>`
  width: 76px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0 0 0;
  gap: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.secondary.light};
  flex-wrap: wrap;
  ${({ disabled }) => css`
    opacity: ${disabled && '0.4'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;

export const DialogHeaderHeart = styled(DialogHeaderLike)``;

export const DialogItem = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 16px;
`;

export const AuthorName = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Line = styled('div')<{ isReaction?: boolean; isActive: boolean }>`
  background: ${({ theme, isActive }) => (isActive ? theme.palette.info.main : 'none')};
  border-radius: 4px 4px 0px 0px;
  height: 3px;
  width: 100%;
  margin-top: ${({ isReaction }) => (isReaction ? '4px' : '-8px')};
`;
