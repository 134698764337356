import { Reorder } from 'framer-motion';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useChangeTargetsOrderMutation } from 'api';
import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { Spinner } from 'components';
import { useAuth } from 'hooks';
import { Links } from 'settings';
import { Target } from 'types';

import { EmptyStateKpi, TargetItem } from '../../components';
import { AddButtonW, ContentW, HeaderW, ListItemW, ListW, Title } from './styled';

interface TargetsListProps {
  list: Target[];
  title: string;
  isActiveList?: boolean;
}

export function TargetsList({ list, title, isActiveList }: TargetsListProps): ReactElement {
  const { isManager } = useAuth();
  const { t } = useTranslation();
  const [targetsList, setTargetsList] = useState(list);
  const [isDragging, setIsDragging] = useState(false);

  const { changeTargetsOrdersMutation, isLoading } = useChangeTargetsOrderMutation();

  async function handleReorder() {
    const targetsIds = targetsList.map((target) => target.id);
    await changeTargetsOrdersMutation({ targetsIds });
  }

  const listNode = (
    <ListW>
      {isActiveList && isManager ? (
        <>
          {targetsList.map((target) => (
            <ListItemW key={target.id}>
              <Reorder.Item
                value={target}
                as="div"
                drag
                onDragStart={() => {
                  setIsDragging(true);
                }}
                onDragEnd={() => {
                  setTimeout(() => {
                    setIsDragging(false);
                  }, 150);
                  handleReorder();
                }}
              >
                <TargetItem {...target} isDragging={isDragging} />
              </Reorder.Item>
            </ListItemW>
          ))}
        </>
      ) : (
        <>
          {targetsList.map((target) => (
            <ListItemW key={target.id}>
              <TargetItem {...target} />
            </ListItemW>
          ))}
        </>
      )}
    </ListW>
  );

  return (
    <ContentW>
      <HeaderW>
        <Title>{title}</Title>

        {isManager && isActiveList && (
          <NavLink to={Links.we.kpi.createTarget} style={{ textDecoration: 'none' }}>
            <AddButtonW endIcon={<AddIcon style={{ opacity: '0.4' }} />}>{t('kpi.add')}</AddButtonW>
          </NavLink>
        )}
      </HeaderW>
      <Reorder.Group as="div" style={{ width: '100%' }} values={list} onReorder={setTargetsList}>
        {isLoading && <Spinner />}
        {list.length === 0 ? <EmptyStateKpi type="targets" /> : listNode}
      </Reorder.Group>
    </ContentW>
  );
}
