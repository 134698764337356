import { useForm, Control } from 'react-hook-form';

import { useActivatePendingUserMutation, useEditUserMutation } from 'api';
import { QUERIES } from 'constants/index';
import { useAuth, useModal } from 'hooks';
import { EditEmployeeFormValues, UserExtendedDetails } from 'types';
import { objectsDifferences } from 'utils';

import { defaultValuesAdapter, formValuesAdapter } from '../adapters';

interface FormKeyValue {
  key: keyof EditEmployeeFormValues;
  value: string[];
}

interface UseEditEmployeeFormProps {
  employee: UserExtendedDetails;
  onClose: () => void;
  refetchUserDetails?: () => void;
  mode: 'edit' | 'activate';
}

interface UseEditEmployeeFormReturn {
  isLoading: boolean;
  isDirty: boolean;
  handleFormSubmit: () => void;
  control: Control<EditEmployeeFormValues>;
  setFormValue: (key: FormKeyValue['key']) => (value: FormKeyValue['value']) => void;
  isOpenConfirmModal: boolean;
  handleTitleClick: () => void;
  handleCloseForm: () => void;
  handleCloseConfirmModal: () => void;
}

export function useEditEmployeeForm({
  employee,
  onClose,
  mode,
  refetchUserDetails,
}: UseEditEmployeeFormProps): UseEditEmployeeFormReturn {
  const defaultValues = defaultValuesAdapter(employee);

  const { user, saveUser } = useAuth();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useForm<EditEmployeeFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { isLoading, editUserMutation } = useEditUserMutation(employee.id);
  const { activateUserMutation } = useActivatePendingUserMutation({
    invalidateQuery: QUERIES.EMPLOYEES_LIST,
  });

  const {
    isOpen: isOpenConfirmModal,
    handleOpenModal: handleOpenConfirmModal,
    handleCloseModal: handleCloseConfirmModal,
  } = useModal();

  const handleFormSubmit = handleSubmit(async (data) => {
    let editInput;
    if (typeof data.birthDate === 'string') {
      editInput = objectsDifferences(defaultValues, data);
    } else {
      const birthDate = data.birthDate as unknown as Date;
      const utcBirthDate = new Date(
        Date.UTC(birthDate?.getFullYear(), birthDate?.getMonth(), birthDate?.getDate()),
      );
      editInput = objectsDifferences(defaultValues, {
        ...data,
        birthDate: utcBirthDate.toISOString(),
      });
    }
    const countryCode = !employee.countryCode && data.countryCode;

    const formValues = formValuesAdapter({ ...editInput, ...(countryCode && { countryCode }) });

    if (mode === 'edit') {
      await editUserMutation({ user: formValues, id: employee.id });
    } else {
      await activateUserMutation({ user: formValues, userId: employee.id });
    }
    handleCloseForm();

    const { first_name, last_name } = formValues;

    // if current users changes its name - update localStorage
    if (user?.id === employee.id && (first_name || last_name)) {
      const updatedUser = {
        ...user,
        ...(first_name && { first_name }),
        ...(last_name && { last_name }),
      };

      saveUser({ user: updatedUser });
      await refetchUserDetails?.();
    }
  });

  function setFormValue(key: FormKeyValue['key']) {
    return (value: FormKeyValue['value']) => setValue(key, value);
  }

  function handleTitleClick() {
    if (isDirty) {
      handleOpenConfirmModal();
      return;
    }
    onClose();
  }

  function handleCloseForm() {
    onClose();
    handleCloseConfirmModal();
  }

  return {
    control,
    handleFormSubmit,
    setFormValue,
    isLoading: isLoading || isSubmitting,
    isDirty,
    isOpenConfirmModal,
    handleTitleClick,
    handleCloseForm,
    handleCloseConfirmModal,
  };
}
