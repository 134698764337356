import { RegisterOptions } from 'react-hook-form';

export const FIELDS_VALUE_LENGTH = {
  firstName: {
    min: 1,
    max: 40,
  },
  lastName: {
    min: 1,
    max: 40,
  },
  phone: {
    min: 3,
    max: 13,
  },
  password: {
    min: 8,
    max: 60,
  },
  email: {
    min: 5,
    max: 254,
  },
};

import { getMinLengthText, getMaxLengthText, required, REQUIRED } from 'validations';

export const firstNameRules: RegisterOptions = {
  ...required,
  minLength: {
    value: FIELDS_VALUE_LENGTH.firstName.min,
    message: getMinLengthText(FIELDS_VALUE_LENGTH.firstName.max),
  },
  maxLength: {
    value: FIELDS_VALUE_LENGTH.firstName.max,
    message: getMaxLengthText(FIELDS_VALUE_LENGTH.firstName.max),
  },
  validate: (value) => {
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      return REQUIRED;
    }
  },
};

export const lastNameRules: RegisterOptions = {
  ...required,
  minLength: {
    value: FIELDS_VALUE_LENGTH.lastName.min,
    message: getMinLengthText(FIELDS_VALUE_LENGTH.lastName.min),
  },
  maxLength: {
    value: FIELDS_VALUE_LENGTH.lastName.max,
    message: getMaxLengthText(FIELDS_VALUE_LENGTH.lastName.max),
  },
  validate: (value) => {
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      return REQUIRED;
    }
  },
};

export const phoneNumberRules: RegisterOptions = {
  ...required,
  minLength: {
    value: FIELDS_VALUE_LENGTH.phone.min,
    message: getMinLengthText(FIELDS_VALUE_LENGTH.phone.min),
  },
  maxLength: {
    value: FIELDS_VALUE_LENGTH.phone.max,
    message: getMaxLengthText(FIELDS_VALUE_LENGTH.phone.max),
  },
};
