import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConfirmDialog, PageHeaderBoldTitle } from 'components';
import { useCallbackPrompt } from 'hooks';

interface InviteEmployeesPageHeaderProps {
  formHasContent: boolean;
}

type LocationState = {
  submitted: boolean;
};

export function InviteEmployeesPageHeader({
  formHasContent,
}: InviteEmployeesPageHeaderProps): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = window.history;
  const isSubmitted = (state as LocationState)?.submitted;

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(formHasContent);

  function handleCloseIconClick() {
    if (isSubmitted) {
      navigate(-2);
      return;
    }
    navigate(-1);
  }

  function handleSubmitConfirm() {
    confirmNavigation();
    navigate(-1);
  }

  const pageTitle = t(isSubmitted ? 'inviteEmployee.title.sent' : 'inviteEmployee.title.new');
  return (
    <>
      <PageHeaderBoldTitle
        onClickCloseIcon={handleCloseIconClick}
        title={pageTitle}
        classNames="invitation"
      />

      <ConfirmDialog
        title={t('inviteEmployee.modal.title')}
        subTitle={t('inviteEmployee.modal.exit')}
        open={showPrompt}
        onClose={cancelNavigation}
        onSubmit={handleSubmitConfirm}
      />
    </>
  );
}
