import { Typography, styled } from '@mui/material';

export const ContainerW = styled('div')`
  position: relative;
  width: 100%;
  min-height: 325px;
  padding: 24px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);

  @media (max-width: 1023px) {
    min-height: 258px;
    padding: 16px;
  }
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
