import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';

interface UseReadNotificationsReturn {
  isLoading: boolean;
  amount?: number;
}

interface UseReadNotificationsProps {
  ids: string[];
}

export function useReadNotifications({
  ids,
}: UseReadNotificationsProps): UseReadNotificationsReturn {
  const { data, isLoading, fetchStatus } = useQuery(
    [ids],
    () => API_HANDLERS.NOTIFICATIONS.READ_NOTIFICATIONS(ids),
    {
      enabled: !!ids.length,
    },
  );
  return {
    isLoading: isLoading && fetchStatus !== 'idle',
    amount: data?.data.amount,
  };
}
