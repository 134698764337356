import { styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { ReactComponent as EmptyStateIcon } from 'assets/icons/folder.svg';

export const EmptyStateW = styled('div')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '18px',
  textAlign: 'center',
  width: '100%',
  color: theme.palette.primary.p040,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  [theme.breakpoints.down('desktop')]: {
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 25px 12px -20px rgba(0, 0, 0, 0.08)',
    borderRadius: '18px',
    width: 'auto',
    padding: '24px',
  },
}));

export const NavLinkW = styled(NavLink)`
  color: ${({ theme }) => theme.palette.info.main};
`;

export const EmptyStateIconW = styled(EmptyStateIcon)`
  margin-bottom: 17px;
`;

export const Description = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
`;
