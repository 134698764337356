export const headerContainerSx = (isMobile: boolean) => ({
  marginBottom: isMobile ? '16px' : '24px',
});

export const headerInnerSx = {
  maxWidth: '100%',
};

export const headerTitleSx = {
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
