import { styled, Dialog as DialogMUI, Button, Typography } from '@mui/material';

export const Dialog = styled(DialogMUI)(({ theme }) => ({
  '.MuiDialog-paper': {
    width: '900px',
    height: '560px',
    position: 'relative',
    backdropFilter: 'blur(10px)',
    borderRadius: '15px',
    display: 'flex',
    background: 'rgba(255, 255, 255, 0.7)',
  },

  '.MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
  },

  [theme.breakpoints.down('desktop')]: {
    '.MuiDialog-paper': {
      marginBottom: 'auto',
      padding: '16px 16px 24px',
      margin: '32px 8px auto',
      maxHeight: 'unset',
      height: 'fit-content',
    },
  },
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  textTransform: 'none',
  fontSize: '24px',
  lineHeight: '24px',
  color: theme.palette.primary.p080,
  margin: '24px 0 0 24px',

  '.MuiButton-startIcon': {
    fontSize: '2rem',
  },

  '.MuiSvgIcon-root': {
    width: '1.5em',
    height: '1.5em',
  },
  [theme.breakpoints.down('desktop')]: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 500,
    margin: 0,
  },
}));

export const Wrapper = styled('div')`
  position: relative;
  height: 100%;
`;

export const ContentW = styled('div')`
  padding-bottom: 32px;
  padding: 24px 72px;
  @media (max-width: 1023px) {
    padding: 0px;
  }
`;

export const NoteW = styled(Typography)`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 40px;
  @media (max-width: 1023px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const CloseBtn = styled(Button)`
  margin-left: auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  border-radius: 12px;
  width: 265px;
  height: 64px;
  text-transform: none;
  box-shadow: none;
  @media (max-width: 1023px) {
    width: 100%;
    height: 48px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
`;

export const SubmitBtn = styled(CloseBtn)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.info.main};
  margin-left: 28px;
  @media (max-width: 1023px) {
    margin-left: 0px;
    width: 100%;
    height: 48px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
`;

export const FooterW = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  @media (max-width: 1023px) {
    flex-direction: column;
    row-gap: 8px;
  }
`;
