import { ReactElement } from 'react';

import { PollFormValues, PostDraft } from 'types';

import { PollForm } from '../PollForm';
import { useCreatePollForm } from './hooks';

interface CreatePollFormProps {
  draft: PostDraft | null;
  defaultValues: (draft?: PostDraft) => PollFormValues;
}

export function CreatePollForm({ draft, defaultValues }: CreatePollFormProps): ReactElement {
  const { handleSubmit, isLoading } = useCreatePollForm({ draft });

  return (
    <PollForm
      draft={draft}
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    />
  );
}
