import { PostListItem } from 'api';
import { TaskFormValues, User } from 'types';

import { AllOption } from '../TaskForm/constants';

const compareIds = (arr1: string[], arr2: string[]): boolean => {
  const sortedArr1 = arr1.sort();
  const sortedArr2 = arr2.sort();

  return (
    sortedArr1.length === sortedArr2.length &&
    sortedArr2.every((value, index) => value === sortedArr1[index])
  );
};

export function defaultValuesAdapter(task: PostListItem, usersList: User[]): TaskFormValues {
  const imagesList = task?.attachments?.filter((file) => file.type === 'image') || [];
  const documentsList = task?.attachments?.filter((file) => file.type === 'document') || [];
  const videosList = task?.attachments?.filter((file) => file.type === 'video') || [];

  const usersIds = usersList.map(({ id }) => id) ?? [];
  const assigneeOptions: string[] = task?.assigned_to?.map(({ id }) => id) ?? [];
  const isAllAssignee = compareIds(usersIds, assigneeOptions);

  return {
    title: task?.title || '',
    description: task?.description || '',
    images: imagesList,
    docs: documentsList,
    videos: videosList,
    status: task?.task.state || '',
    assignee: isAllAssignee ? [AllOption.value] : assigneeOptions,
    deadline: task?.task.deadline || null,
  };
}
