import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'assets/icons/containerActions/edit.svg';
import { ReactComponent as MaximizeIcon } from 'assets/icons/containerActions/maximize.svg';
import { useWindowSize } from 'hooks';
import { Links } from 'settings';

import { ActionsW, ButtonW } from './styled';

interface ProfileCardActionsProps {
  employeeId: string;
  handleOnEditClick: () => void;
}

export function ProfileCardActions({
  employeeId,
  handleOnEditClick,
}: ProfileCardActionsProps): ReactElement {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isMobile } = useWindowSize();

  const extendedDetailsLink = !params?.employeeId
    ? Links.profile.extended.extended
    : pathname.includes('resources')
    ? Links.resources.employees.employee.extended
    : Links.we.employees.employee.extended;

  function goToExtendedDetails() {
    navigate(
      generatePath(extendedDetailsLink, {
        employeeId,
      }),
    );
  }

  return (
    <ActionsW>
      {!isMobile && (
        <ButtonW onClick={handleOnEditClick} endIcon={<EditIcon style={{ opacity: '0.4' }} />}>
          {t('profile.edit.text')}
        </ButtonW>
      )}

      <IconButton onClick={goToExtendedDetails}>
        <MaximizeIcon style={{ opacity: '0.4' }} />
      </IconButton>
    </ActionsW>
  );
}
