import { Button, styled } from '@mui/material';

import { ReactComponent as CameraIcon } from 'assets/icons/blueCamera.svg';
import AdligoLogo from 'assets/icons/logos/logoWhite165.svg';

export const CardW = styled('div')<{ privateView: boolean }>(({ privateView, theme }) => ({
  marginRight: '24px',
  padding: privateView ? '31px 36px 17px 36px' : '31px 36px 31px 36px',
  width: privateView ? '541px' : '855px',
  position: 'relative',
  borderRadius: '15px',
  display: 'flex',

  background: 'rgba(255, 255, 255, 0.7)',
  height: privateView ? '264px' : 'auto',
  backdropFilter: 'blur(20px)',

  ...(!privateView && {
    backgroundImage: `url(${AdligoLogo})`,
    backgroundPosition: '95% 70%',
    backgroundRepeat: 'no-repeat',
  }),
  overflow: 'hidden',
  [theme.breakpoints.down('desktop')]: {
    width: '100%',
    height: 'unset',
    padding: '24px 16px 24px 24px',
    flexWrap: 'wrap',
    ...(!privateView && {
      height: '100%',
      backgroundPosition: '97% 95%',
    }),
  },
}));

export const ActionsW = styled('div')`
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  margin-top: 6px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  @media (max-width: 1023px) {
    justify-content: flex-start;
  }
`;

export const AvatarSectionW = styled('div')<{ privateView: boolean }>(({ privateView, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: privateView ? '43px' : '60px',
  [theme.breakpoints.down('desktop')]: {
    marginRight: '24px',
    ...(!privateView && {
      flexBasis: '100%',
      marginRight: 0,
    }),
  },
}));

export const UserDetailsW = styled('div')<{ privateView: boolean }>(({ privateView, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('desktop')]: {
    ...(!privateView && {
      flexBasis: '100%',
    }),
  },
}));

export const UserName = styled('div')<{ privateView: boolean }>(({ theme, privateView }) => ({
  width: privateView ? '185px' : '450px',
  marginBottom: '11px',
  fontWeight: 500,
  fontSize: '36px',
  lineHeight: '44px',
  color: theme.palette.primary.p080,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('desktop')]: {
    width: 'unset',
    fontSize: '24px',
    lineHeight: '24px',
    marginTop: '12px',
    marginBottom: '0px',
    ...(!privateView && {
      textAlign: 'center',
      fontSize: '36px',
      lineHeight: '44px',
      whiteSpace: 'break-spaces',
      marginTop: '16px',
    }),
  },

  '@media (max-width: 500px)': {
    maxWidth: '320px',
  },

  '@media (max-width: 400px)': {
    maxWidth: '270px',
  },

  '@media (max-width: 350px)': {
    maxWidth: '230px',
  },
}));

export const NameValue = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

export const MobileProfileCardW = styled('div')`
  flex-basis: 100%;
  margin-top: 16px;
`;

export const MobileCameraIcon = styled(CameraIcon)`
  position: absolute;
  z-index: 1;
  right: 0px;
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`;
