import { Stream } from '@cloudflare/stream-react';
import { DialogProps } from '@mui/material/Dialog';
import { useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { Spinner } from 'components/Spinner';
import { PrivateDocumentsAttachments } from 'features/employeeDetails/components/PrivateDocuments/types';
import { Attachment } from 'types';

import SVG from './Arrows';
import {
  Dialog,
  DialogContent,
  DialogActions,
  ButtonW,
  VideoContainer,
  VideoWrapper,
  SpinnerW,
} from './styled';

import 'react-image-gallery/styles/css/image-gallery.css';
import './styles.css';

type VideoGallery = DialogProps & {
  onClose: () => void;
  videoList: Attachment[] | PrivateDocumentsAttachments[];
  postId?: string;
};
export const VideoGallery = ({ open, onClose, videoList }: VideoGallery) => {
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SampleNextArrow = (props: any) => {
    const { onClick, currentSlide, slideCount } = props;
    const disabled = currentSlide + 1 >= slideCount;
    console.log(disabled, 'disabled');
    return (
      <button
        type="button"
        className="image-gallery-icon image-gallery-right-nav"
        onClick={onClick}
        aria-label="Next Slide"
      >
        <SVG icon="right" viewBox="6 0 12 24" />
      </button>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SamplePrevArrow = (props: any) => {
    const { onClick, currentSlide } = props;
    const disabled = currentSlide === 0;
    console.log(disabled, 'disabled');

    return (
      <button
        type="button"
        className="image-gallery-icon image-gallery-left-nav"
        onClick={onClick}
        aria-label="Next Slide"
      >
        <SVG icon="left" viewBox="6 0 12 24" />
      </button>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleVideoReady = () => {
    setIsLoading(false);
  };

  return (
    <>
      <Dialog open={open} fullScreen={true} onClick={(e) => e.stopPropagation()}>
        <DialogActions>
          <ButtonW
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <CloseIcon />
          </ButtonW>
        </DialogActions>
        <DialogContent>
          {isLoading && (
            <SpinnerW>
              <Spinner size="1.5rem" wrapperSx={{ height: '24px' }} />
            </SpinnerW>
          )}
          {videoList.length > 0 && (
            <VideoContainer>
              <VideoWrapper>
                {videoList.length > 1 ? (
                  <Slider {...settings}>
                    {videoList.map((video, index) => {
                      return (
                        <Stream
                          key={index}
                          controls
                          autoplay
                          poster={`https://customer-tr04cbm6ks3rgpwn.cloudflarestream.com/${video.file.video_uid}/thumbnails/thumbnail.jpg`}
                          src={video.file.video_uid}
                          onLoadedData={handleVideoReady}
                          height="720px"
                          width="100%"
                          responsive={false}
                        />
                      );
                    })}
                  </Slider>
                ) : (
                  <Stream
                    controls
                    src={videoList[0].file.video_uid}
                    poster={`https://customer-tr04cbm6ks3rgpwn.cloudflarestream.com/${videoList[0].file.video_uid}/thumbnails/thumbnail.jpg`}
                    height="720px"
                    onLoadedData={handleVideoReady}
                    width="100%"
                    responsive={false}
                    autoplay
                  />
                )}
              </VideoWrapper>
            </VideoContainer>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
