import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { GetAchievementsListResponse } from 'api/types';

interface UseAchievementsQueryReturn {
  isLoading: boolean;
  data: GetAchievementsListResponse;
}

interface UseAchievementsQueryProps {
  userId: string;
  limit?: number;
  exclude_accomplished?: boolean;
}

export function useAchievementsQuery({
  userId,
  limit,
  exclude_accomplished,
}: UseAchievementsQueryProps): UseAchievementsQueryReturn {
  const { data, isLoading } = useQuery(
    ['achievement', userId],
    () => API_HANDLERS.ACHIEVEMENTS.GET_LIST({ userId, limit, exclude_accomplished }),
    {
      enabled: !!userId,
    },
  );

  return {
    isLoading,
    data: data?.data || [],
  };
}
