import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryListItem } from 'api';
import {
  Backdrop,
  BaseTextFieldController,
  ConfirmDialog,
  Form,
  FormGroup,
  SubmitRoundedButton,
  SelectController,
  RichEditorController,
} from 'components';
import { DocumentCategoryIcons } from 'constants/DocumentCategoryIcons';
import { useWindowSize } from 'hooks';
import { textFieldMobileStyles, textFieldStyles, richEditorStyles } from 'styles';
import { required } from 'validations';

import { AllOption, MAX_ARTICLE_LENGTH, MAX_TITLE_LENGTH } from './constants';
import { useCreateCategoryForm } from './hooks';
import { descriptionFieldRules, titleFieldRules } from './rules';
import { Footer, FromContainer } from './styled';
import {
  dropdownContainerSx,
  multiSelectStyles,
  optionSx,
  selectMobileSx,
  selectSx,
} from './styles';

interface CreatePostFormProps {
  category?: CategoryListItem;
  isEdit: boolean;
}

export function CreateDocumentCategoriesForm({
  category,
  isEdit,
}: CreatePostFormProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const {
    control,
    handleFormSubmit,
    handleAssigneeChange,
    isLoading,
    isOpen,
    handleCloseModal,
    isNotDisableButton,
    handleSubmitConfirmModal,
    usersOptions,
  } = useCreateCategoryForm({
    category,
    isEdit,
  });
  const formGroupMarginBottom = isMobile ? 8 : 24;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;

  const iconsOptions = Object.keys(DocumentCategoryIcons)
    .filter((name) => name !== 'untagged')
    .map((name) => ({
      value: name,
      label: DocumentCategoryIcons[name],
    }));

  return (
    <>
      {isLoading && <Backdrop />}

      <FromContainer>
        <Form onSubmit={handleFormSubmit}>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <BaseTextFieldController
              name="title"
              control={control}
              placeholder={t('resources.documentCategories.create.form.placeholders.title')}
              sx={fieldStyles}
              maxLength={MAX_TITLE_LENGTH}
              rules={titleFieldRules}
            />
          </FormGroup>

          <FormGroup marginBottom={formGroupMarginBottom}>
            <SelectController
              name="assigned_to"
              control={control}
              placeholder={t('resources.documentCategories.create.form.placeholders.visibility')}
              options={[AllOption, ...usersOptions]}
              multipleSelect
              sx={multiSelectStyles(isMobile)}
              handleChange={handleAssigneeChange}
              type="poll"
              rules={required}
            />
          </FormGroup>

          <FormGroup marginBottom={isMobile ? 0 : 24} row>
            <RichEditorController
              name="description"
              control={control}
              placeholder={t('resources.documentCategories.create.form.placeholders.description')}
              maxLength={MAX_ARTICLE_LENGTH}
              rules={descriptionFieldRules}
              sx={richEditorStyles(isMobile)}
            />
          </FormGroup>

          <FormGroup marginBottom={formGroupMarginBottom} row>
            <SelectController
              name="icon"
              control={control}
              placeholder={t('resources.documentCategories.create.form.placeholders.icon')}
              options={iconsOptions}
              sx={isMobile ? selectMobileSx : selectSx}
              rules={required}
              optionSx={optionSx}
              dropdownContainerSx={dropdownContainerSx(isMobile)}
            />
          </FormGroup>

          {!isMobile && (
            <Footer>
              <ConfirmDialog
                subTitle={t('resources.documentCategories.create.modal.exit')}
                title={t('resources.documentCategories.create.modal.title')}
                open={isOpen}
                onClose={handleCloseModal}
                onSubmit={handleSubmitConfirmModal}
              />

              <SubmitRoundedButton
                disabled={isEdit && !isNotDisableButton}
                sx={{ marginLeft: 'auto' }}
              />
            </Footer>
          )}
        </Form>
      </FromContainer>
      {isMobile && (
        <SubmitRoundedButton
          disabled={isEdit && !isNotDisableButton}
          sx={{ marginLeft: 'auto' }}
          className="fixed-bottom"
          onClick={handleFormSubmit}
        />
      )}

      <ConfirmDialog
        subTitle={t('resources.documentCategories.create.modal.exit')}
        title={t('resources.documentCategories.create.modal.title')}
        open={isOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
