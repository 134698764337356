import { ReactNode } from 'react';

import i18n from 'i18n';
import { Links } from 'settings';
import {
  Company,
  GroupedProduct,
  PaymentDetails,
  ProductType,
  Subscription,
  SubscriptionsPeriod,
} from 'types';
import { formattedDate, toCapitalize } from 'utils';

import { NavLinkW } from './styled';

interface DetailsAdapterReturn {
  generalInfo: Record<string, { label: string; value: string }>;
  cardDetails: Record<string, { label: string; value: string }>;
  contactInfo: Record<string, { label: string; value: string }>;
  subscriptionInfo: Record<string, { label: string; value: ReactNode }>;
}

interface DetailsAdapterProps {
  company?: Company;
  subscription?: Subscription;
  paymentDetails?: PaymentDetails;
  activeProducts: Record<ProductType, GroupedProduct> | null;
}

export function detailsAdapter({
  company,
  subscription,
  activeProducts,
  paymentDetails,
}: DetailsAdapterProps): DetailsAdapterReturn {
  const cardYear = paymentDetails?.exp_year ? String(paymentDetails?.exp_year).slice(2) : '';
  const cardExpiration =
    paymentDetails?.exp_month && cardYear ? `${paymentDetails?.exp_month}/${cardYear}` : '';

  const filteredAddressValues = [
    company?.address_1,
    company?.address_2,
    company?.city,
    company?.region,
    company?.postcode,
    company?.country,
  ].filter((value) => value);
  const addressStringValue = filteredAddressValues.join(', ');

  const productName = subscription ? toCapitalize(subscription.type) : '';
  const goToSubscriptionsLinkLabel = i18n.t('company.manageSubscriptions');

  const startDate = subscription?.created_at || subscription?.started_at;
  const expDate = subscription?.expires_at || '';
  const formattedStartDate = startDate ? formattedDate(new Date(startDate)) : '';
  const formattedExpDate = expDate ? formattedDate(new Date(expDate)) : '';

  const period =
    subscription?.period === SubscriptionsPeriod.month
      ? i18n.t(`subscriptions.month`)
      : i18n.t(`subscriptions.year`);
  const mainPrice =
    activeProducts && subscription
      ? `${activeProducts.main.price.currency.toUpperCase()} ${
          activeProducts.main.price.price
        }/${period}`
      : '';
  const extraPrice = activeProducts && subscription ? `${activeProducts.extra.price.price}` : '';
  const perExtraEmployeeText = i18n.t('company.forExtraEmployee');
  const duration = activeProducts
    ? i18n.t(`company.duration.${activeProducts.main.name.toLowerCase()}`)
    : '';

  const generalInfo = {
    name: {
      label: i18n.t('company.edit.form.general.placeholders.name'),
      value: company?.name || '',
    },
    industry: {
      label: i18n.t('company.edit.form.general.placeholders.industry'),
      value: company?.industry || '',
    },
    vatNumber: {
      label: i18n.t('company.edit.form.general.placeholders.vatNumber'),
      value: company?.vat || '',
    },
    companyNumber: {
      label: i18n.t('company.edit.form.general.placeholders.companyNumber'),
      value: company?.company_number || '',
    },
  };

  const cardDetails = {
    cardNumber: {
      label: i18n.t('company.edit.form.cardDetails.placeholders.cardNumber'),
      value: paymentDetails?.last_4 ? `XXXX XXXX XXXX ${paymentDetails?.last_4}` : '',
    },
    expirationDate: {
      label: i18n.t('company.edit.form.cardDetails.placeholders.expirationDate'),
      value: cardExpiration,
    },
  };

  const contactInfo = {
    address: {
      label: i18n.t('company.edit.form.contactInfo.placeholders.address'),
      value: addressStringValue || '',
    },
    companyEmail: {
      label: i18n.t('company.edit.form.contactInfo.placeholders.companyEmail'),
      value: company?.email || '',
    },
    supportEmail: {
      label: i18n.t('company.edit.form.contactInfo.placeholders.supportEmail'),
      value: company?.support_email || '',
    },
    phoneNumber: {
      label: i18n.t('company.edit.form.contactInfo.placeholders.phone'),
      value: `${company?.country_code || ''} ${company?.phone || ''}`,
    },
    website: {
      label: i18n.t('company.edit.form.contactInfo.placeholders.website'),
      value: company?.website || '',
    },
  };

  const subscriptionInfo = {
    currentSubscription: {
      label: i18n.t('company.edit.form.subscriptionDetails.placeholders.currentSubscription'),
      value: (
        <div>
          <div>{productName}</div>
          <NavLinkW to={Links.actions.subscriptions}>{goToSubscriptionsLinkLabel}</NavLinkW>
        </div>
      ),
    },
    date: {
      label: i18n.t('company.edit.form.subscriptionDetails.placeholders.date'),
      value:
        formattedStartDate && formattedExpDate ? `${formattedStartDate}/${formattedExpDate}` : '',
    },
    currentPricePlan: {
      label: i18n.t('company.edit.form.subscriptionDetails.placeholders.currentPricePlan'),
      value: mainPrice && extraPrice && (
        <div>
          <div>
            {mainPrice} {duration}
          </div>
          <div>
            + {extraPrice} {perExtraEmployeeText}
          </div>
        </div>
      ),
    },
    numberOfActiveEmployees: {
      label: i18n.t('company.edit.form.subscriptionDetails.placeholders.numberOfActiveEmployees'),
      value: subscription?.users_count ? String(subscription?.users_count) : '',
    },
  };

  return {
    generalInfo,
    cardDetails,
    contactInfo,
    subscriptionInfo,
  };
}
