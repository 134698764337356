import { SWEDISH_DIAL_CODE } from 'constants/index';
import { EditEmployeeFormValues, UserExtendedDetails } from 'types';

export function defaultValuesAdapter(user: UserExtendedDetails | null): EditEmployeeFormValues {
  return {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    countryCode: user?.countryCode ? String(user?.countryCode) : SWEDISH_DIAL_CODE,
    phone: user?.phone ?? '',
    birthDate: user?.birthDate || '',
    department: user?.department || '',
    position: user?.position || '',
    address1: user?.address1 || '',
    address2: user?.address2 || '',
    city: user?.city || '',
    region: user?.region || '',
    postCode: user?.postcode || '',
    country: user?.country || '',

    // privateInfo
    ssn: user?.ssn || '',
    employmentDate: user?.employedAt || null,
    employmentNumber: user?.employmentNumber || '',
    education: user?.education || '',
    clothesSize: user?.clothesSize || '',
    allergies: user?.allergies || '',
    comments: user?.comments || '',

    // Equipment
    equipment: user?.equipment || [],

    // Licenses
    licenses: user?.licenses || [],

    // Emergency contact
    relation: user?.emergency?.relation || '',
    relationContactFirstName: user?.emergency?.firstName || '',
    relationContactLastName: user?.emergency?.lastName || '',
    relationCountryCode: user?.emergency?.countryCode
      ? String(user.emergency.countryCode)
      : SWEDISH_DIAL_CODE,
    relationContactPhone: user?.emergency?.phone || '',
  };
}
