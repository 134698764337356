export const oneImageStyles = { width: '300px', height: '150px' };

export const oneImageMobileStyles = { width: '100%', height: '146px' };

export const twoImageStyles = { width: '130px', height: '130px', marginRight: '16px' };

export const twoImageMobileStyles = {
  width: '112px',
  height: '110px',
  marginRight: '16px',
};
