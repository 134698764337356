import { BoxProps } from '@mui/material';
import { ReactElement, SyntheticEvent } from 'react';

import { useAttachmentDetails } from 'api';
import { ReactComponent as FileIcon } from 'assets/icons/document.svg';
import { handleFileDownload, hideDocNameMidPart } from 'utils';

import { FileName, FileW, FileIconW, DownloadIconW, Wrapper } from './styled';

interface DocumentAttachmentProps {
  fileName: string;
  fileUrl: string;
  documentId: string;
  wrapperSx?: BoxProps['sx'];
}

const lengthLimit = 8;

export function DocumentAttachment({
  fileName,
  fileUrl,
  wrapperSx,
  documentId,
}: DocumentAttachmentProps): ReactElement {
  const { refetch } = useAttachmentDetails({
    attachmentId: documentId,
    fileName,
    fileUrl,
    enabled: false,
  });

  const lastDotIndex = fileName.lastIndexOf('.');
  const nameWithoutExtension = fileName.slice(0, lastDotIndex);

  const title =
    nameWithoutExtension.length > lengthLimit
      ? hideDocNameMidPart({ docName: fileName, limit: lengthLimit })
      : fileName;

  async function handleDownloadClick(e: SyntheticEvent) {
    e.stopPropagation();

    const { data } = await refetch();

    if (data?.data) {
      await handleFileDownload({ blobFile: data.data, fileName });
    }
  }

  return (
    <FileW canDownload={true} sx={wrapperSx} onClick={handleDownloadClick}>
      <Wrapper className="download-icon-wrapper">
        <DownloadIconW className="download-icon" />
      </Wrapper>
      <FileIconW className="file-icon" onClick={handleDownloadClick}>
        <FileIcon className="file-icon" />
      </FileIconW>
      <FileName>{title}</FileName>
    </FileW>
  );
}
