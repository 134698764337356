import { useMutation } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { requestForToken } from 'api/firebase';
import { useActivateToken } from 'api/queries/useActivateToken';
import { useAuth, useSubscription } from 'hooks';

interface UseCompanySwitchMutation {
  reload?: boolean;
}

export function useCompanySwitchMutation({ reload }: UseCompanySwitchMutation) {
  const { saveUser } = useAuth();
  const { addNotification, addMockDataBanner } = useSubscription();
  const { refetch } = useActivateToken();

  const { mutateAsync: switchCompanyUserMutation, isLoading } = useMutation(
    API_HANDLERS.AUTH.SWITCH_COMPANY,
    {
      onSuccess: async (data) => {
        const { token, user } = data.data;

        localStorage.setItem('accessToken', token.access_token);
        localStorage.setItem('refreshToken', token.refresh_token);
        localStorage.setItem('expiresAt', String(token.expires_at));

        saveUser({ user });
        addNotification();
        addMockDataBanner();
        if (!localStorage.getItem('webPushToken')) {
          const token = await requestForToken();
          localStorage.setItem('webPushToken', token as string);
          refetch();
        }
        if (reload) {
          window.location.reload();
        }
      },
    },
  );

  return {
    switchCompanyUserMutation,
    isLoading,
  };
}
