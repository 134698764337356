import { Backdrop as BackdropMUI } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactElement } from 'react';

import { ContainerBackdrop } from './ContainerBackdrop';
import { BackdropPercentage, SpinnerWrapper } from './styled';

interface BackdropProps {
  progress?: number;
}

export function Backdrop({ progress = 0 }: BackdropProps): ReactElement {
  return (
    <BackdropMUI
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      }}
      open={true}
    >
      <SpinnerWrapper>
        <CircularProgress color="inherit" size={80} />
        {progress ? <BackdropPercentage>{`${progress}%`}</BackdropPercentage> : null}
      </SpinnerWrapper>
    </BackdropMUI>
  );
}

export { ContainerBackdrop };
