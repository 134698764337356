import { Typography, styled } from '@mui/material';

export const Container = styled('div')`
  width: 100%;
  height: 46px;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 17px 8px -12px rgba(0, 71, 255, 0.12);
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  &.active {
    outline: 1px solid ${({ theme }) => theme.palette.info.main};
  }

  &.shadow {
    opacity: 0.6;
  }

  &.topMargin {
    margin-top: 16px;
  }
`;

export const TitleMore = styled(Typography)`
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.info.main};
`;

export const OptionBackground = styled('div')<{
  isCommonStyle: boolean;
  percentNumber: string | undefined;
}>`
  display: ${({ isCommonStyle }) => (isCommonStyle ? 'flex' : 'none')};
  box-shadow: 0px 17px 8px -12px rgba(0, 71, 255, 0.12);
  width: 100%;
  height: 46px;
  border-radius: 9px;
  position: relative;
  z-index: 0;
`;

export const Progress = styled('div')<{
  isCommonStyle: boolean;
  percentNumber: string | undefined;
}>`
  display: ${({ isCommonStyle }) => (isCommonStyle ? 'flex' : 'none')};
  background: ${({ percentNumber }) => !!percentNumber && '#e5f5fe'};
  height: 46px;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${({ percentNumber }) => `calc(100% - ${percentNumber})`};
  border-radius: 9px;
  position: absolute;
  z-index: 0;
`;

export const OptionTitle = styled(Typography)<{ isCommonStyle: boolean; isDetails: boolean }>`
  display: ${({ isCommonStyle }) => !isCommonStyle && 'none'};
  max-width: calc(100% - 8px);
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding: 10px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 5;
`;

export const ActiveOptionTitle = styled('div')<{
  isCommonStyle: boolean;
  isDetails: boolean;
  isSelected: boolean;
}>`
  padding: 10px 16px;
  display: block;
  max-width: ${({ isDetails, isCommonStyle, isSelected }) =>
    isDetails && isCommonStyle && isSelected
      ? 'calc(100% - 68px)'
      : isDetails && isCommonStyle && !isSelected
      ? 'calc(100% - 32px)'
      : isSelected
      ? 'calc(100% - 64px)'
      : 'calc(100% - 36px)'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 5;

  @media (max-width: 1023px) {
    max-width: ${({ isDetails, isCommonStyle, isSelected }) =>
      isDetails && isCommonStyle && isSelected
        ? 'calc(100% - 68px)'
        : isDetails && isCommonStyle && !isSelected
        ? 'calc(100% - 36px)'
        : isSelected
        ? 'calc(100% - 64px)'
        : 'calc(100% - 36px)'};
  }
`;

export const PercentAmount = styled('div')<{ isCommonStyle: boolean; isDetails: boolean }>`
  display: ${({ isCommonStyle }) => (isCommonStyle ? 'inline-block' : 'none')};
  color: #9ca3af;
  font-size: 14px;
  line-height: 22px;
  padding: 15px 0 13px 0;
  position: absolute;
  width: 32px;
  right: ${({ isDetails }) => (isDetails ? '36px' : '100px')};
  width: ${({ isDetails }) => isDetails && '32px'};
  text-align: ${({ isDetails }) => isDetails && 'left'};
  z-index: 2;
  z-index: 5;

  @media (max-width: 1023px) {
    right: ${({ isDetails }) => (isDetails ? '36px' : '64px')};
  }
`;

export const DateContainer = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 22px;
`;

export const DateW = styled(Typography)<{ isMobile: boolean }>`
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: ${({ isMobile }) => (isMobile ? '8px' : '10px')};
`;

export const RequestIconW = styled('div')`
  z-index: 5;
`;
