import { styled } from '@mui/material';

export const PageW = styled('div')`
  padding-left: 88px;
  max-width: 1113px;
  position: relative;
  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 16px;
  }
`;
