import i18n from 'i18n';
import { ProductName, SubscriptionsPeriod } from 'types';

export const defaultValues = {
  productName: '',
  frequency: '',
  employeeCount: '',
};

export const PLANS_OPTIONS = [
  { value: '', label: '', disabled: true },
  {
    value: ProductName.PROBATION,
    label: i18n.t('calculator.modal.fields.plan.options.onProbation'),
  },
  { value: ProductName.PARTNER, label: i18n.t('calculator.modal.fields.plan.options.partner') },
  { value: ProductName.FRIEND, label: i18n.t('calculator.modal.fields.plan.options.friend') },
];

export const FREQUENCY_OPTIONS = [
  {
    value: '',
    label: '',
    disabled: true,
  },
  {
    value: SubscriptionsPeriod.month,
    label: i18n.t('calculator.modal.fields.frequency.options.monthly'),
  },
  {
    value: SubscriptionsPeriod.year,
    label: i18n.t('calculator.modal.fields.frequency.options.yearly'),
  },
];
