export function getTimeLeftText(expiresAt: string) {
  const today = new Date();
  const expireDate = new Date(expiresAt);
  today.setHours(0, 0, 0, 0);
  expireDate.setHours(0, 0, 0, 0);

  const diffInTime = expireDate.getTime() - today.getTime();
  const diffInDays = ~~(diffInTime / (1000 * 3600 * 24)) - 1;

  const isItToday = diffInDays === 0 || diffInDays === -1;
  const isTomorrow = diffInDays === 1;

  if (isItToday) {
    return { text: 'freeTrial.expiresIn.today' };
  }

  if (isTomorrow) {
    return { text: 'freeTrial.expiresIn.tomorrow' };
  }

  return { text: 'freeTrial.expiresIn.days', days: diffInDays };
}
