import i18n from 'i18n';
import { TableColumnsConfig } from 'types';
import { EquipmentsMobileTableFields, EquipmentsTableFields } from 'types/equipments';

export const DEFAULT_EQUIPMENTS_FIELDS: TableColumnsConfig<EquipmentsTableFields>[] = [
  {
    key: 'name',
    label: i18n.t('equipments.table.tHead.name'),
    width: '15%',
  },
  {
    key: 'expiryDate',
    label: i18n.t('equipments.table.tHead.expiryDate'),
    width: '17%',
  },
  {
    key: 'orderedOn',
    label: i18n.t('equipments.table.tHead.orderedOn'),
    width: '17%',
  },
  {
    key: 'serial',
    label: i18n.t('equipments.table.tHead.serialNumber'),
    width: '17%',
  },
  {
    key: 'attachments',
    label: i18n.t('equipments.table.tHead.attachment'),
    width: '22%',
  },
  {
    key: 'actions',
    label: '',
    width: '6%',
  },
];

export const MOBILE_EQUIPMENTS_FIELDS: TableColumnsConfig<EquipmentsMobileTableFields>[] = [
  {
    key: 'name',
    label: i18n.t('equipments.table.tHead.name'),
    width: '60%',
  },
  {
    key: 'expiryDate',
    label: i18n.t('equipments.table.tHead.expiryDate'),
    width: '50%',
  },
  {
    key: 'orderedOn',
    label: i18n.t('equipments.table.tHead.orderedOn'),
    width: '50%',
  },
  {
    key: 'serial',
    label: i18n.t('equipments.table.tHead.serialNumber'),
    width: '100%',
  },
  {
    key: 'attachments',
    label: i18n.t('equipments.table.tHead.attachment'),
    width: '80%',
  },
  {
    key: 'actions',
    label: '',
    width: '30%',
  },
];
