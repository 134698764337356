import { Reorder } from 'framer-motion';
import { ReactElement, useEffect, useState } from 'react';

import { useChangeLinksOrderMutation } from 'api';
import { Spinner } from 'components';
import { QuickLink as IQuickLink } from 'types';

import { LinksEmptyState } from '../LinksEmptyState';
import { QuickLink } from '../QuickLink';
import { ContentW, CenteredW, ListW } from './styled';

interface QuickLinksListProps {
  isLoading: boolean;
  links: IQuickLink[];
}

export function QuickLinksList({ isLoading, links }: QuickLinksListProps): ReactElement {
  const [linksList, setLinksList] = useState(links);
  const [isDragging, setIsDragging] = useState(false);

  const { changeLinksOrdersMutation, isLoading: isLoadingReorder } = useChangeLinksOrderMutation();

  async function handleReorder() {
    const linksIds = linksList.map((link) => link.id);
    await changeLinksOrdersMutation({ linksIds });
  }

  useEffect(() => {
    setLinksList(links);
  }, [links]);

  const listNode = (
    <ListW>
      {linksList.map((link) => (
        <Reorder.Item
          key={link.id}
          value={link}
          as="div"
          drag
          onDragStart={() => {
            setIsDragging(true);
          }}
          onDragEnd={() => {
            setTimeout(() => {
              setIsDragging(false);
            }, 150);
            handleReorder();
          }}
        >
          <QuickLink {...link} isEditable isDragging={isDragging} />
        </Reorder.Item>
      ))}
    </ListW>
  );

  return (
    <ContentW>
      <Reorder.Group as="div" style={{ width: '100%' }} values={links} onReorder={setLinksList}>
        {(isLoading || isLoadingReorder) && <Spinner />}
        {links.length === 0 && !isLoading ? (
          <CenteredW>
            <LinksEmptyState />
          </CenteredW>
        ) : (
          listNode
        )}
      </Reorder.Group>
    </ContentW>
  );
}
