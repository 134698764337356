import { styled } from '@mui/material';

export const ListW = styled('div')`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 16px;
  @media (max-width: 1023px) {
    row-gap: 8px;
  }
`;
