import { useTranslation } from 'react-i18next';

import { VisionFormPayload } from 'types';

import { useCreateVisionMutation } from '../../hooks';

export function useEditVisionForm() {
  const { t } = useTranslation();

  const { createVisionMutation: editVisionMutation, isLoading } = useCreateVisionMutation({
    successText: t('toasts.vision.success.edit'),
  });

  async function handleSubmit(data: VisionFormPayload) {
    await editVisionMutation(data);
  }

  return {
    handleSubmit,
    isLoading,
  };
}
