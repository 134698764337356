import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'assets/icons/containerActions/edit.svg';
import { useAuth } from 'hooks';
import { Links } from 'settings';

import { EmptyStateKpi } from '../EmptyStateKpi';
import { ContentW, VisionTitle, VisionDescription, HeaderW, EditButtonW } from './styled';

interface VisionProps {
  vision: string | null;
}

export function Vision({ vision }: VisionProps): ReactElement {
  const { t } = useTranslation();
  const { isManager } = useAuth();

  return (
    <ContentW>
      <HeaderW>
        <VisionTitle>{t('kpi.fullPage.ourVision')}</VisionTitle>
        {isManager && vision && (
          <NavLink to={Links.we.kpi.editVision} style={{ textDecoration: 'none' }}>
            <EditButtonW endIcon={<EditIcon style={{ opacity: '0.4' }} />}>
              {t('company.edit.text')}
            </EditButtonW>
          </NavLink>
        )}
      </HeaderW>
      {vision ? (
        <VisionDescription dangerouslySetInnerHTML={{ __html: vision }} />
      ) : (
        <EmptyStateKpi type="vision" />
      )}
    </ContentW>
  );
}
