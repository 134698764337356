import { styled, Typography, Divider } from '@mui/material';

import { ReactComponent as EmptyAvatarIcon } from 'assets/icons/avatarEmpty.svg';

export const NavBarW = styled('div')<{ disabled: boolean }>(({ disabled }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',

  width: '88px',
  height: '758px',
  padding: '34px 16px',
  margin: '66px 0 66px 88px',

  background: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(20px)',
  borderRadius: '15px',
  opacity: disabled ? '0.5' : 'unset',

  '@media (max-width: 1023px)': {
    width: '100%',
    height: '57px',
    padding: 0,
    margin: '0px 0px 16px',
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    minHeight: '57px',
    position: 'fixed',
    bottom: '0',
    left: '50%',
    zIndex: '100',
    transform: 'translateX(-50%)',
  },
}));

export const ListItems = styled('div')`
  @media (max-width: 1023px) {
    display: flex;
    height: 100%;
  }
`;

export const DividerW = styled(Divider)`
  margin-top: 24px;
  border-color: ${({ theme }) => theme.palette.primary.p010};
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const ListItemsMainW = styled('div')`
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
  }
`;

export const LogoW = styled('div')`
  display: flex;
  flex-direction: column;
  svg {
    width: 40px;
    height: 36px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const AdligoText = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.primary.p040};
  margin-top: 8px;
`;

export const DefaultAvatar = styled(EmptyAvatarIcon)`
  opacity: 0.8;
  width: 24px;
  height: 24px;
`;

export const Logo = styled('img')`
  height: 36px;
  width: 36px;
`;
