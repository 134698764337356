import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { DeleteGoalRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UseDeleteGoalMutationProps {
  invalidateQueries?: string[];
  onSuccessAction?: () => void;
}

interface UseDeleteGoalMutationReturn {
  deleteGoalMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    DeleteGoalRequestParams,
    unknown
  >;
  isLoading: boolean;
}

export function useDeleteGoalMutation({
  invalidateQueries,
  onSuccessAction,
}: UseDeleteGoalMutationProps): UseDeleteGoalMutationReturn {
  const { mutateAsync: deleteGoalMutation, isLoading } = useMutation(
    API_HANDLERS.ACHIEVEMENTS.DELETE_GOAL,
    {
      onSuccess: () => {
        invalidateQueries && queryClient.invalidateQueries(invalidateQueries);
        onSuccessAction?.();
      },
    },
  );

  return {
    deleteGoalMutation,
    isLoading,
  };
}
