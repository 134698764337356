import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FileUploadIcon } from 'assets/icons/filesUpload/fileUpload.svg';
import { ReactComponent as PhotoUploadIcon } from 'assets/icons/filesUpload/photoUpload.svg';
import { ReactComponent as VideoUploadIcon } from 'assets/icons/filesUpload/videoUpload.svg';
import { UploadFile } from 'components';
import { useWindowSize } from 'hooks';

import { ALLOWED_FILE_TYPES, ALLOWED_IMAGE_TYPES, ALLOWED_VIDEO_TYPES } from './constants';
import { UploadButtons, ButtonW, IconButtonW, ButtonLabel } from './styled';
import { labelStyles } from './styles';

interface FileUploadButtonsProps {
  handleAddImages: (files: FileList) => Promise<void>;
  handleAddDocuments: (files: FileList) => Promise<void>;
  handleAddVideos: (files: FileList) => Promise<void>;
  classNames?: string;
}

export function FileUploadButtons({
  handleAddImages,
  handleAddDocuments,
  handleAddVideos,
  classNames,
}: FileUploadButtonsProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  return (
    <UploadButtons className={classNames}>
      <ButtonW>
        <IconButtonW>
          <UploadFile
            id="imagesUpload"
            multiple={true}
            acceptFormats={ALLOWED_IMAGE_TYPES}
            handleFile={handleAddImages}
            style={labelStyles}
          >
            <PhotoUploadIcon />
          </UploadFile>
        </IconButtonW>
        {!isMobile && <ButtonLabel>{t('common.image')}</ButtonLabel>}
      </ButtonW>

      <ButtonW>
        <IconButtonW>
          <UploadFile
            id="docsUpload"
            multiple={true}
            acceptFormats={ALLOWED_FILE_TYPES}
            handleFile={handleAddDocuments}
            style={labelStyles}
          >
            <FileUploadIcon />
          </UploadFile>
        </IconButtonW>
        {!isMobile && <ButtonLabel>{t('common.file')}</ButtonLabel>}
      </ButtonW>

      <ButtonW>
        <IconButtonW>
          <UploadFile
            id="videoUpload"
            multiple={false}
            acceptFormats={ALLOWED_VIDEO_TYPES}
            handleFile={handleAddVideos}
            style={labelStyles}
          >
            <VideoUploadIcon />
          </UploadFile>
        </IconButtonW>
        {!isMobile && <ButtonLabel>{t('common.video')}</ButtonLabel>}
      </ButtonW>
    </UploadButtons>
  );
}
