import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { alert } from 'components';
import { PasswordResetFormValues } from 'types';

import { ResendButton, SuccessNote } from './styled';

interface AfterSubmitNoteProps {
  email: string;
  isLoading: boolean;
  requestPasswordResetMutation: (data: PasswordResetFormValues) => void;
}

export function AfterSubmitNote({
  email,
  requestPasswordResetMutation,
}: AfterSubmitNoteProps): ReactElement {
  const { t } = useTranslation();

  async function resendEmail() {
    await requestPasswordResetMutation({ email });
    alert.success(t('toasts.emailSent'));
  }

  return (
    <SuccessNote>
      <Trans i18nKey="restorePassword.submitted.successNoteP1" values={{ email }}>
        If any account with email address <strong>{email}</strong> is registered in Adligo, you will
        receive an email with password reset instructions.
      </Trans>

      <Box sx={{ marginTop: '48px' }}>
        <Trans i18nKey="restorePassword.submitted.successNoteP2">
          Haven&lsquo;t received an email? Check the email specified or
          <ResendButton onClick={resendEmail}>click here</ResendButton> to send it again.
        </Trans>
      </Box>
    </SuccessNote>
  );
}
