import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Poll } from 'api';
import { useAnswerPostMutation } from 'api/mutations/useAnswerPostMutation';
import { ReactComponent as RequestIcon } from 'assets/icons/request.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/taskCalendar.svg';
import { alert } from 'components';
import { MONTH_DAY_SHORT } from 'constants/index';
import { formattedDate } from 'utils';

import { MAX_OPTIONS_LENGTH } from '../constants';
import {
  Container,
  OptionBackground,
  OptionTitle,
  PercentAmount,
  TitleMore,
  DateContainer,
  DateW,
  ActiveOptionTitle,
  Progress,
  RequestIconW,
} from './styled';

type PollOptionProps = Poll & {
  postId: string;
  isDetails?: boolean;
  hasMarginTop?: boolean;
  goToPollDetails?: () => void;
};

export function PollOptions({
  deadline,
  options,
  postId,
  isDetails = false,
  hasMarginTop = false,
  goToPollDetails,
}: PollOptionProps): ReactElement {
  const { t } = useTranslation();

  const isAfterDeadline =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !!deadline && new Date((new Date() as any) - 3600 * 1000 * 24) >= new Date(deadline);

  const { answerPostMutation } = useAnswerPostMutation();

  const beOptionIndex = options.findIndex((option) => option.is_chosen);

  const answeredOptionIndex = options.findIndex((option) => option.is_chosen);

  function handleClick(optionKey: number) {
    const getOption = () => {
      if (answeredOptionIndex === -1) {
        return optionKey;
      }
      if (answeredOptionIndex === optionKey) {
        return -1;
      }
      return answeredOptionIndex;
    };

    const clickedOption = getOption();

    if (beOptionIndex !== clickedOption) {
      answerPostMutation({ postId, option_index: clickedOption });
      options?.length > MAX_OPTIONS_LENGTH && goToPollDetails?.();
    }

    return clickedOption;
  }

  function showToast() {
    alert.error(t('toasts.codes.pollHasExpired'));
  }

  function calcPercent(key: number) {
    const optionsSum = options
      .map((option) => option.votes)
      .reduce((acc: number, num: number) => acc + num, 0);

    const optionPercent = Math.round((100 / optionsSum) * options[key].votes);

    return optionPercent ? optionPercent + '%' : undefined;
  }

  const isCommonStyle = options.findIndex((option) => option.is_chosen) >= 0;

  const optionsItems = isDetails ? [...options] : [...options.slice(0, MAX_OPTIONS_LENGTH)];

  return (
    <>
      {optionsItems.map((option, key) => (
        <Container
          key={option.text}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            isAfterDeadline ? showToast() : handleClick(key);
          }}
          className={`${isCommonStyle && beOptionIndex === key && 'active'} ${
            isAfterDeadline && 'shadow'
          } ${hasMarginTop && key === 0 && 'topMargin'}`}
        >
          <OptionBackground isCommonStyle={isCommonStyle} percentNumber={calcPercent(key)}>
            {isCommonStyle && beOptionIndex === key && (
              <RequestIconW>
                <RequestIcon style={{ margin: '11px -8px 11px 16px' }} />
              </RequestIconW>
            )}
            <Progress isCommonStyle={isCommonStyle} percentNumber={calcPercent(key)} />
            <ActiveOptionTitle
              isDetails={isDetails}
              isCommonStyle={isCommonStyle}
              isSelected={beOptionIndex === key}
            >
              {option.text}
            </ActiveOptionTitle>
          </OptionBackground>
          <PercentAmount isCommonStyle={isCommonStyle} isDetails={isDetails}>
            {calcPercent(key)}
          </PercentAmount>

          <OptionTitle isCommonStyle={!isCommonStyle} isDetails={isDetails}>
            {option.text}
          </OptionTitle>
        </Container>
      ))}

      {!isDetails && options.length > MAX_OPTIONS_LENGTH && (
        <TitleMore>{t('reactions.showMore')}</TitleMore>
      )}

      {deadline && (
        <DateContainer>
          <CalendarIcon />
          <DateW isMobile>{formattedDate(new Date(deadline), MONTH_DAY_SHORT)}</DateW>
        </DateContainer>
      )}
    </>
  );
}
