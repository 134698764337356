import { AvatarSize } from '..';

export function getUserNameWidth(size?: AvatarSize) {
  switch (size) {
    case 'xs':
      return { width: '24px' };
    case 's':
      return { width: '36px' };
    case 'm':
      return { width: '60px' };
    case 'l':
      return { width: '128px' };
    case 'xl':
      return { width: '235px' };
    default:
      return { width: 'auto' };
  }
}
