import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useQuickLinksQuery } from 'api';
import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { Backdrop, GoBackButtonWithTitle, PageWrapper, TopMenu } from 'components';
import { Links } from 'settings';

import { QuickLinksList, RecommendedLinks } from '../../components';
import { AddButtonW, ContentW } from './styled';

export function QuickLinksPage(): ReactElement {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { links, isLoading: isLoadingLinks } = useQuickLinksQuery();

  return (
    <PageWrapper>
      <ContentW>
        <GoBackButtonWithTitle
          title={t('quickLinks.pageTitle')}
          Actions={
            <NavLink to={Links.we.quickLinks.createLink} style={{ textDecoration: 'none' }}>
              <AddButtonW endIcon={<AddIcon style={{ opacity: '0.4' }} />}>
                {t('quickLinks.add')}
              </AddButtonW>
            </NavLink>
          }
        />
        <TopMenu />
        <QuickLinksList isLoading={isLoadingLinks} links={links} />
        <RecommendedLinks setIsLoading={setIsLoading} />
        {isLoading && <Backdrop />}
      </ContentW>
    </PageWrapper>
  );
}
