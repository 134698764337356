import { add } from 'date-fns';
import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Backdrop,
  BaseTextFieldController,
  DatePicker,
  FileUploadButtons,
  Form,
  FormAttachments,
  FormGroup,
  RichEditorController,
  SelectController,
  SubmitRoundedButton,
} from 'components';
import { EQUIPMENT_FORM_FIELDS_LENGTH } from 'constants/fieldsLength';
import { useWindowSize } from 'hooks';
import { textFieldStyles, textFieldMobileStyles, richEditorStyles } from 'styles';
import { EquipmentFormValues, PostDraft, User } from 'types';
import { required } from 'validations';

import { categoryOptions, initialWindowSizeProps } from '../constants';
import { CustomFields } from '../CustomFields';
import { useEquipmentForm } from './hooks';
import { CheckboxInput, CheckboxW, LabelW } from './styled';
import { submitButtonSx, userSelectSx } from './styles';
import {
  commentFieldRules,
  titleFieldRules,
  onlyFutureDates,
  onlyPastAndCurrentDates,
  invalidDate,
} from './validation';

interface EquipmentFormProps {
  draft?: PostDraft | null;
  isLoading: boolean;
  usersList: User[];
  defaultValues: (isDefaultEditable: boolean, draft?: PostDraft) => EquipmentFormValues;
  onSubmit: (data: EquipmentFormValues, attachmentIds: string[]) => void;
  mode?: 'create' | 'edit';
  isRequestForm?: boolean;
}

export function EquipmentForm({
  draft,
  isLoading: isEquipmentMutationLoading,
  usersList,
  defaultValues,
  onSubmit,
  mode = 'create',
  isRequestForm = false,
}: EquipmentFormProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize(initialWindowSizeProps);
  const isEditMode = mode === 'edit';

  const {
    isLoading,
    isSubmitting,
    isCurrentUserProfile,
    isSelectedCurrentUser,
    isCreateFromProfile,
    isManager,
    isSubmitted,
    isDirty,
    control,
    usersOptions,
    assigneeOptions,
    attachedImages,
    attachedDocs,
    attachedVideos,
    progress,
    getValues,
    handleFormSubmit,
    register,
    setError,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
  } = useEquipmentForm({
    draft,
    isEquipmentMutationLoading,
    isEditMode,
    usersList,
    defaultValues,
    onSubmit,
  });

  const isSubmitButtonDisabled =
    (isEditMode && !isDirty) || isSubmitting || isEquipmentMutationLoading;
  const formGroupMarginBottom = isMobile ? 16 : 24;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;
  const todayDate = new Date();
  const tomorrowDate = add(todayDate, {
    days: 1,
  });

  return (
    <>
      {(isLoading || isSubmitting) && <Backdrop progress={progress} />}

      <>
        <Form onSubmit={handleFormSubmit}>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <BaseTextFieldController
              name="title"
              control={control}
              placeholder={t('equipments.create.form.placeholders.title')}
              sx={fieldStyles}
              maxLength={EQUIPMENT_FORM_FIELDS_LENGTH.title.max}
              rules={titleFieldRules}
            />
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <SelectController
              name="category"
              control={control}
              placeholder={t('equipments.create.form.placeholders.category')}
              options={categoryOptions}
              rules={required}
            />
          </FormGroup>
          {isManager && (
            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="user"
                control={control}
                placeholder={t('equipments.create.form.placeholders.user')}
                options={usersOptions}
                rules={required}
                disabled={isCurrentUserProfile || isCreateFromProfile || isEditMode}
                sx={userSelectSx}
              />
            </FormGroup>
          )}
          {isRequestForm && (
            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="assignee"
                control={control}
                placeholder={t('equipments.create.form.placeholders.assignee')}
                options={assigneeOptions}
                rules={required}
                sx={userSelectSx}
              />
            </FormGroup>
          )}
          <FormGroup marginBottom={formGroupMarginBottom}>
            <DatePicker
              {...register('expireAt', {
                validate: {
                  invalidDate,
                  onlyFutureDates,
                },
              })}
              control={control}
              placeholder={t('equipments.create.form.placeholders.expirationDate')}
              sx={fieldStyles}
              minDate={tomorrowDate}
            />
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <DatePicker
              {...register('orderedAt', {
                validate: {
                  invalidDate,
                  onlyPastAndCurrentDates,
                },
              })}
              control={control}
              placeholder={t('equipments.create.form.placeholders.orderedOn')}
              sx={fieldStyles}
              maxDate={todayDate}
            />
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <BaseTextFieldController
              name="serial"
              control={control}
              placeholder={t('equipments.create.form.placeholders.serialNumber')}
              sx={fieldStyles}
              maxLength={EQUIPMENT_FORM_FIELDS_LENGTH.serial.max}
            />
          </FormGroup>

          <FormGroup marginBottom={formGroupMarginBottom} row>
            {!isMobile && (
              <FormAttachments
                attachedImages={attachedImages}
                attachedDocs={attachedDocs}
                attachedVideos={attachedVideos}
                handleDeleteAttachment={handleDeleteAttachment}
                hasDownloadDoc={isEditMode}
                draftId={draft?.id}
              />
            )}

            {isMobile && (
              <FormAttachments
                attachedImages={attachedImages}
                attachedDocs={attachedDocs}
                attachedVideos={attachedVideos}
                handleDeleteAttachment={handleDeleteAttachment}
                hasDownloadDoc={isEditMode}
                draftId={draft?.id}
              />
            )}

            <RichEditorController
              name="comment"
              control={control}
              placeholder={t('equipments.create.form.placeholders.comment')}
              maxLength={EQUIPMENT_FORM_FIELDS_LENGTH.comment.max}
              rules={commentFieldRules}
              sx={richEditorStyles(isMobile)}
            />

            <FileUploadButtons
              handleAddImages={handleAddImages}
              handleAddDocuments={handleAddDocuments}
              handleAddVideos={handleAddVideos}
              classNames="notMarginTop"
            />
          </FormGroup>

          <CustomFields
            isSubmitted={isSubmitted}
            setError={setError}
            control={control}
            getValues={getValues}
          />

          {isManager && (
            <Controller
              control={control}
              name="isEditable"
              render={({ field: { value, onChange } }) => {
                return (
                  <CheckboxW>
                    <CheckboxInput
                      disabled={isCurrentUserProfile || isSelectedCurrentUser}
                      checked={value}
                      value={value}
                      onChange={onChange}
                    />
                    <LabelW>{t('equipments.create.form.placeholders.editDelete')}</LabelW>
                  </CheckboxW>
                );
              }}
            />
          )}

          {!isMobile && (
            <SubmitRoundedButton disabled={isSubmitButtonDisabled} sx={submitButtonSx} />
          )}
        </Form>

        {isMobile && (
          <SubmitRoundedButton
            disabled={isSubmitButtonDisabled}
            sx={submitButtonSx}
            className="fixed-bottom"
            onClick={handleFormSubmit}
          />
        )}
      </>
    </>
  );
}
