import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useUsersListQuery } from 'api';
import { PageHeaderBoldTitle, PageWrapper, Spinner } from 'components';

import { EditEquipmentForm } from '../../components';

export function EditEquipmentPage(): ReactElement {
  const { t } = useTranslation();
  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  if (isLoadingUsersList) {
    return <Spinner />;
  }

  return (
    <PageWrapper>
      <PageHeaderBoldTitle title={t('equipments.create.title')} />
      <EditEquipmentForm usersList={usersList} />
    </PageWrapper>
  );
}
