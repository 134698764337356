import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { EditAchievementRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseEditAchievementMutationReturn {
  editAchievementMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    EditAchievementRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseEditAchievementMutationProps {
  successText?: string;
  invalidateQueries?: string[];
}

export function useEditAchievementMutation({
  successText,
  invalidateQueries,
}: UseEditAchievementMutationProps): UseEditAchievementMutationReturn {
  const { mutateAsync: editAchievementMutation, isLoading } = useMutation(
    API_HANDLERS.ACHIEVEMENTS.EDIT_ACHIEVEMENT,
    {
      onSuccess: () => {
        successText && alert.success(successText);
        invalidateQueries && queryClient.invalidateQueries(invalidateQueries);
      },
    },
  );

  return {
    editAchievementMutation,
    isLoading,
  };
}
