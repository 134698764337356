import i18n from '../i18n';

export const REQUIRED = i18n.t('messages.required');
export const SAFE_CHARACTERS = i18n.t('messages.wrongCharacters');
export const WRONG_EMAIL_FORMAT = i18n.t('messages.wrongEmailFormat');
export const WRONG_PASSWORD_FORMAT = i18n.t('messages.wrongPasswordFormat');
export const WRONG_PASSWORD_FORMAT_FULL = i18n.t('messages.wrongPasswordFullFormat');
export const PAST_DATES_ONLY = i18n.t('messages.pastDatesOnly');
export const FUTURE_DATES_ONLY = i18n.t('messages.futureDatesOnly');
export const INVALID_DATE = i18n.t('messages.invalidDate');
export const getMinLengthText = (length: number): string =>
  length > 1
    ? i18n.t('messages.minLengthTextWithOne', { length })
    : i18n.t('messages.minLengthText', { length });
export const getMaxLengthText = (length: number): string =>
  i18n.t('messages.maxLengthText', { length });
export const toLargeFile = (size: number) => i18n.t('messages.toLargeFile', { size });
