import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { IconButtonProps } from '@mui/material';
import { ReactElement } from 'react';

import { StyledIconBtn } from './styled';

interface SubmitRoundedButtonProps extends IconButtonProps {
  icon?: 'arrow' | 'check';
}

const buttonIcons = {
  arrow: <ArrowForwardRoundedIcon fontSize="large" />,
  check: <CheckRoundedIcon fontSize="large" />,
};

export function SubmitRoundedButton({
  icon = 'check',
  type = 'submit',
  ...otherProps
}: SubmitRoundedButtonProps): ReactElement {
  return (
    <StyledIconBtn type={type} {...otherProps}>
      {buttonIcons[icon]}
    </StyledIconBtn>
  );
}
