import { stringify } from 'qs';

import {
  Achievement,
  CreateCustomNotificationRequestParams,
  EquipmentPayload,
  Goal,
  TargetFormValues,
} from '../types';
import { API_PATHS } from './apiPaths';
import { deleteData, getData, patchData, postData, putData } from './crudHandlers';
import {
  AxiosResponseSuccess,
  ConfirmRegistrationParams,
  InviteEmployeeParams,
  LoginRequestParams,
  LoginResponse,
  RegisterCompanyParams,
  UsersListRequestParams,
  UsersResponseSuccess,
  ValidateOttParams,
  ValidateOttResponse,
  DeactivateUserRequestParams,
  UserDetailsResponse,
  EditUserRequestParams,
  UsersInfiniteListResponseSuccess,
  UserGetAvatarResponseSuccess,
  UpdateAvatarResponseSuccess,
  PublishPostRequestParams,
  CreatePostDraftResponse,
  GetAttachmentDetailsRequestParams,
  GetCategoryDetailsParams,
  CreateCategoryRequestParams,
  GetPostsListParams,
  PostsListsResponseSuccess,
  GetPostDetailsParams,
  PostsDetailsResponseSuccess,
  DeletePostRequestParams,
  GetProductsListParams,
  GetProductDetailsParams,
  ProductsListSuccessResponse,
  GetSubscriptionDetailsResponse,
  CountryIpResponse,
  CreateCommentParams,
  GetCommentsListParams,
  CreateCommentSuccessResponse,
  GetProductDetailsResponseSuccess,
  CreateSubscriptionParams,
  CreateSubscriptionResponse,
  GetCommentsListResponse,
  UpdateSubscriptionParams,
  UpdateSubscriptionResponse,
  CancelSubscriptionParams,
  CompanyDetailsResponse,
  PaymentDetailsResponse,
  EditCompanyParams,
  EditCompanyResponse,
  GetCalendarEventsParams,
  GetCalendarEventsResponse,
  CalendarSignInProps,
  ForgotPasswordRequestParams,
  RestorePasswordRequestParams,
  KpiDetailsResponse,
  DeleteTargetRequestParams,
  AddTargetRequestParams,
  AddVisionParams,
  TargetDetailsResponse,
  GetKpiListParams,
  ChangeTargetsOrderRequestParams,
  UserQuickLinksResponse,
  QuickLinksPresetResponse,
  CreateQuickLinkRequestParams,
  CreateQuickLinkResponse,
  AddLinkFromPresetResponse,
  EditQuickLinkRequestParams,
  EditQuickLinkResponse,
  QuickLinkDetailsResponse,
  ChangeLinksOrderRequestParams,
  ActivateUserRequestParams,
  EmailValidationParams,
  FinishActivationRequestParams,
  GetNotificationsListParams,
  GetNotificationsListResponse,
  GetNotificationsCounterResponse,
  UnreadNotificationAmountResponse,
  GetUserEquipmentsListResponse,
  ActivateNotificationParams,
  ReInviteUserRequestParams,
  RegisterEquipmentRequestParams,
  DeleteEquipmentRequestParams,
  GetEquipmentRequestParams,
  EditEquipmentRequestParams,
  RequestEquipmentRequestParams,
  RegisterRequestedEquipmentRequestParams,
  SubscibeWebpushNotificationsRequestParams,
  AddAttachmentRequestParams,
  AddAttachmentResponse,
  AnswerPostRequestParams,
  CreateReactionRequestParams,
  DeleteReactionRequestParams,
  GetReactionRequestParams,
  GetReactionListResponse,
  CreateAchievementRequestParams,
  GetAchievementsRequestParams,
  GetAchievementsListResponse,
  CreateGoalRequestParams,
  GetAchievementRequestParams,
  EditAchievementRequestParams,
  GetGoalRequestParams,
  EditGoalRequestParams,
  CommentGoalRequestParams,
  DeleteAchievementRequestParams,
  DeleteGoalRequestParams,
  CategoryDetailsResponseSuccess,
  DeleteCategoryRequestParams,
  CategoryListSuccessResponse,
  AddVideoAttachmentRequestParams,
  UserCompanyListSuccessResponse,
} from './types';

export const API_HANDLERS = {
  AUTH: {
    REGISTER(data: RegisterCompanyParams): AxiosResponseSuccess<unknown> {
      const { password, ...rest } = data;
      const filteredData = !password || password.length < 8 || password.length > 60 ? rest : data;
      return postData<unknown>(API_PATHS.AUTH.REGISTER(), {
        data: filteredData,
        authorizeRequest: false,
      });
    },
    CONFIRM_REGISTER(data: ConfirmRegistrationParams): AxiosResponseSuccess<unknown> {
      const { password, ...rest } = data?.user || { password: '' };
      const filteredData =
        !password || password.length < 8 || password.length > 60 ? rest : data?.user;
      const modifiedData = data?.user ? { ott: data?.ott, user: filteredData } : data;
      return postData<unknown>(API_PATHS.AUTH.CONFIRM_REGISTRATION(), {
        data: modifiedData,
        authorizeRequest: false,
      });
    },
    VALIDATE_OTT(data: ValidateOttParams): AxiosResponseSuccess<ValidateOttResponse> {
      return postData<ValidateOttResponse>(API_PATHS.AUTH.VALIDATE_OTT(), {
        data,
        authorizeRequest: false,
      });
    },
    LOGIN(data: LoginRequestParams): AxiosResponseSuccess<LoginResponse> {
      return postData<LoginResponse>(API_PATHS.AUTH.LOGIN(), {
        data,
        authorizeRequest: false,
      });
    },
    LOGOUT(): AxiosResponseSuccess<unknown> {
      return postData(API_PATHS.AUTH.LOGOUT());
    },
    REFRESH_TOKEN(): AxiosResponseSuccess<unknown> {
      return postData(API_PATHS.AUTH.REFRESH_TOKEN());
    },
    FORGOT_PASSWORD(data: ForgotPasswordRequestParams): AxiosResponseSuccess<unknown> {
      return postData(API_PATHS.AUTH.FORGOT_PASSWORD(), { data });
    },
    RESTORE_PASSWORD(data: RestorePasswordRequestParams): AxiosResponseSuccess<unknown> {
      return postData(API_PATHS.AUTH.RESTORE_PASSWORD(), { data });
    },
    FINISH_ACTIVATION(data: FinishActivationRequestParams): AxiosResponseSuccess<unknown> {
      return postData(API_PATHS.AUTH.FINISH_ACTIVATION(), { data });
    },
    SWITCH_COMPANY(companyId: string): AxiosResponseSuccess<LoginResponse> {
      return postData(API_PATHS.AUTH.SWITCH_COMPANY(companyId));
    },
  },
  USER: {
    DETAILS(userId: string): AxiosResponseSuccess<UserDetailsResponse> {
      return getData<UserDetailsResponse>(API_PATHS.USER.DETAILS(userId));
    },
    INVITE(data: InviteEmployeeParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.USER.INVITE(), {
        data,
      });
    },
    DEACTIVATE({ userId, comment }: DeactivateUserRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.USER.DEACTIVATE(userId), {
        data: {
          comment,
        },
      });
    },
    EDIT({ user, id }: EditUserRequestParams): AxiosResponseSuccess<UserDetailsResponse> {
      return patchData<UserDetailsResponse>(API_PATHS.USER.EDIT(id), {
        data: user,
      });
    },
    CHANGE_AVATAR(data: FormData): AxiosResponseSuccess<UpdateAvatarResponseSuccess> {
      return putData<UpdateAvatarResponseSuccess>(API_PATHS.USER.CHANGE_AVATAR(), {
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    GET_AVATAR(
      userId: string,
      filename: string,
    ): AxiosResponseSuccess<UserGetAvatarResponseSuccess> {
      return getData<UserGetAvatarResponseSuccess>(API_PATHS.USER.GET_AVATAR(userId, filename), {
        responseType: 'blob',
      });
    },
    ACTIVATE({ userId, user }: ActivateUserRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.USER.ACTIVATE(userId), {
        data: user,
      });
    },
    VALIDATE_EMAIL({ email }: EmailValidationParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.AUTH.EMAILVALIDATE(), {
        data: { email },
      });
    },
    ACTIVATE_PENDING({ userId, user }: ActivateUserRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.USER.ACTIVATE_PENDING(userId), {
        data: user,
      });
    },
    RE_INVITE({ userId }: ReInviteUserRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.USER.RE_INVITE(userId));
    },
    EQUIPMENTS(userId: string): AxiosResponseSuccess<GetUserEquipmentsListResponse> {
      return getData<GetUserEquipmentsListResponse>(API_PATHS.USER.EQUIPMENTS(userId));
    },
    GET_COMPANIES(): AxiosResponseSuccess<UserCompanyListSuccessResponse> {
      return getData<UserCompanyListSuccessResponse>(API_PATHS.USER.GET_COMPANIES());
    },
  },
  USERS: {
    GET(params: UsersListRequestParams): AxiosResponseSuccess<UsersResponseSuccess> {
      return getData<UsersResponseSuccess>(API_PATHS.USERS._(), {
        params,
        paramsSerializer: (params) => {
          return stringify(params, { arrayFormat: 'repeat' });
        },
      });
    },
    GET_INFINITE(
      params: UsersListRequestParams,
    ): AxiosResponseSuccess<UsersInfiniteListResponseSuccess> {
      return getData<UsersInfiniteListResponseSuccess>(API_PATHS.USERS._(), {
        params,
        paramsSerializer: (params) => {
          return stringify(params, { arrayFormat: 'repeat' });
        },
      });
    },
  },
  CATEGORY: {
    GET_LIST(): AxiosResponseSuccess<CategoryListSuccessResponse> {
      return getData<CategoryListSuccessResponse>(API_PATHS.CATEGORY.LIST());
    },
    GET_DETAILS({
      categoryId,
    }: GetCategoryDetailsParams): AxiosResponseSuccess<CategoryDetailsResponseSuccess> {
      return getData<CategoryDetailsResponseSuccess>(API_PATHS.CATEGORY.DETAILS(categoryId));
    },
    CREATE({ category }: CreateCategoryRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.CATEGORY.CREATE(), {
        data: category,
      });
    },
    EDIT({ categoryId, category }: CreateCategoryRequestParams): AxiosResponseSuccess<unknown> {
      return patchData<unknown>(API_PATHS.CATEGORY.DETAILS(categoryId), {
        data: category,
      });
    },
    DELETE({ categoryId }: DeleteCategoryRequestParams): AxiosResponseSuccess<unknown> {
      return deleteData<unknown>(API_PATHS.CATEGORY.DELETE(categoryId));
    },
  },
  POST: {
    CREATE_DRAFT(): AxiosResponseSuccess<CreatePostDraftResponse> {
      return postData<CreatePostDraftResponse>(API_PATHS.POST.CREATE_DRAFT());
    },
    PUBLISH({ postId, post }: PublishPostRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.POST.PUBLISH(postId), {
        data: post,
      });
    },
    GET_LIST(params: GetPostsListParams): AxiosResponseSuccess<PostsListsResponseSuccess> {
      return getData<PostsListsResponseSuccess>(API_PATHS.POST.LIST(), {
        params,
        paramsSerializer: (params) => {
          return stringify(
            {
              ...params,
              state:
                params.state === '!done' ? ['to_do', 'in_progress', 'to_approve'] : params.state,
            },
            { arrayFormat: 'repeat' },
          );
        },
      });
    },
    GET_DETAILS({
      postId,
      include_reactions,
    }: GetPostDetailsParams): AxiosResponseSuccess<PostsDetailsResponseSuccess> {
      return getData<PostsDetailsResponseSuccess>(API_PATHS.POST.DETAILS(postId), {
        params: { include_reactions },
        paramsSerializer: (params) => {
          return stringify(params, { arrayFormat: 'repeat' });
        },
      });
    },
    EDIT({ postId, post }: PublishPostRequestParams): AxiosResponseSuccess<unknown> {
      return putData<unknown>(API_PATHS.POST.DETAILS(postId), {
        data: post,
      });
    },
    DELETE({ postId }: DeletePostRequestParams): AxiosResponseSuccess<unknown> {
      return deleteData<unknown>(API_PATHS.POST.DELETE(postId));
    },
    ANSWER({ postId, option_index }: AnswerPostRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.POST.ANSWER(postId), {
        data: { option_index },
      });
    },
  },
  PRODUCTS: {
    GET_LIST(params: GetProductsListParams): AxiosResponseSuccess<ProductsListSuccessResponse> {
      return getData<ProductsListSuccessResponse>(API_PATHS.PRODUCTS.LIST(), {
        params,
      });
    },
    GET_DETAILS({
      productId,
    }: GetProductDetailsParams): AxiosResponseSuccess<GetProductDetailsResponseSuccess> {
      return getData<GetProductDetailsResponseSuccess>(API_PATHS.PRODUCTS.DETAILS(productId));
    },
  },
  SUBSCRIPTION: {
    DETAILS(): AxiosResponseSuccess<GetSubscriptionDetailsResponse> {
      return getData<GetSubscriptionDetailsResponse>(API_PATHS.SUBSCRIPTION._());
    },
    CREATE(data: CreateSubscriptionParams): AxiosResponseSuccess<CreateSubscriptionResponse> {
      return postData<CreateSubscriptionResponse>(API_PATHS.SUBSCRIPTION._(), {
        data,
      });
    },
    UPDATE(data: UpdateSubscriptionParams): AxiosResponseSuccess<UpdateSubscriptionResponse> {
      return putData<UpdateSubscriptionResponse>(API_PATHS.SUBSCRIPTION._(), {
        data,
      });
    },
    CANCEL(data: CancelSubscriptionParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.SUBSCRIPTION.CANCEL(), {
        data,
      });
    },
  },
  LOCATION: {
    GET_LOCATION(): AxiosResponseSuccess<CountryIpResponse> {
      return getData<CountryIpResponse>(API_PATHS.LOCATION(), { baseURL: '' });
    },
  },
  COMMENTS: {
    CREATE({
      postId,
      content,
    }: CreateCommentParams): AxiosResponseSuccess<CreateCommentSuccessResponse> {
      return postData<CreateCommentSuccessResponse>(API_PATHS.COMMENTS.CREATE(postId), {
        data: { content },
      });
    },
    GET_LIST({
      post_id,
      ...otherParams
    }: GetCommentsListParams): AxiosResponseSuccess<GetCommentsListResponse> {
      return getData<GetCommentsListResponse>(API_PATHS.COMMENTS.LIST(post_id), {
        params: { ...otherParams },
      });
    },
  },
  COMPANY: {
    DETAILS(): AxiosResponseSuccess<CompanyDetailsResponse> {
      return getData<CompanyDetailsResponse>(API_PATHS.COMPANY.DETAILS());
    },
    EDIT(params: EditCompanyParams): AxiosResponseSuccess<EditCompanyResponse> {
      return patchData<EditCompanyResponse>(API_PATHS.COMPANY.EDIT(), {
        data: params,
      });
    },
  },
  PAYMENT: {
    _(payment_method_id: string): AxiosResponseSuccess<PaymentDetailsResponse> {
      return getData<PaymentDetailsResponse>(API_PATHS.PAYMENT.DETAILS(payment_method_id));
    },
  },
  CALENDAR: {
    SIGN_IN({ type }: CalendarSignInProps): AxiosResponseSuccess<{ auth_url: string }> {
      return postData<{ auth_url: string }>(API_PATHS.CALENDAR_AUTH.SIGN_IN(type));
    },
    GET_EVENTS({
      provider,
      start,
      end,
    }: GetCalendarEventsParams): AxiosResponseSuccess<GetCalendarEventsResponse> {
      return getData<GetCalendarEventsResponse>(API_PATHS.CALENDAR_AUTH.GET_EVENTS(provider), {
        params: {
          start,
          end,
        },
      });
    },
  },
  KPI: {
    GET_LIST(params: GetKpiListParams): AxiosResponseSuccess<KpiDetailsResponse> {
      return getData<KpiDetailsResponse>(API_PATHS.KPI.DETAILS(), { params });
    },
    TARGET_DETAILS(targetId: string): AxiosResponseSuccess<TargetDetailsResponse> {
      return getData<TargetDetailsResponse>(API_PATHS.KPI.TARGET_DETAILS(targetId));
    },
    EDIT_TARGET({
      targetId = '',
      ...otherParams
    }: TargetFormValues): AxiosResponseSuccess<KpiDetailsResponse> {
      return putData<KpiDetailsResponse>(API_PATHS.KPI.EDIT_TARGET(targetId), {
        data: otherParams,
      });
    },
    ADD_VISION(data: AddVisionParams): AxiosResponseSuccess<KpiDetailsResponse> {
      return putData<KpiDetailsResponse>(API_PATHS.KPI.ADD_VISION(), {
        data,
      });
    },
    DELETE({ targetId }: DeleteTargetRequestParams): AxiosResponseSuccess<unknown> {
      return deleteData<unknown>(API_PATHS.KPI.DELETE(targetId));
    },
    ADD_TARGET(data: AddTargetRequestParams): AxiosResponseSuccess<KpiDetailsResponse> {
      return postData<KpiDetailsResponse>(API_PATHS.KPI.ADD_TARGET(), { data });
    },
    CHANGE_TARGETS_ORDER({
      targetsIds,
    }: ChangeTargetsOrderRequestParams): AxiosResponseSuccess<unknown> {
      return putData<unknown>(API_PATHS.KPI.CHANGE_TARGETS_ORDER(), { data: targetsIds });
    },
  },
  QUICK_LINKS: {
    GET_USER_LINKS(): AxiosResponseSuccess<UserQuickLinksResponse> {
      return getData<UserQuickLinksResponse>(API_PATHS.QUICK_LINKS.USER_LINKS());
    },
    GET_LINKS_PRESET(): AxiosResponseSuccess<QuickLinksPresetResponse> {
      return getData<QuickLinksPresetResponse>(API_PATHS.QUICK_LINKS.LINKS_PRESET());
    },
    CREATE_LINK(data: CreateQuickLinkRequestParams): AxiosResponseSuccess<CreateQuickLinkResponse> {
      return postData<CreateQuickLinkResponse>(API_PATHS.QUICK_LINKS.CREATE_LINK(), { data });
    },
    ADD_LINK_FROM_PRESET(linkId: string): AxiosResponseSuccess<AddLinkFromPresetResponse> {
      return postData<AddLinkFromPresetResponse>(
        API_PATHS.QUICK_LINKS.ADD_LINK_FROM_PRESET(linkId),
      );
    },
    DELETE_LINK(linkId: string): AxiosResponseSuccess<unknown> {
      return deleteData<unknown>(API_PATHS.QUICK_LINKS.DELETE_LINK(linkId));
    },
    EDIT_LINK(data: EditQuickLinkRequestParams): AxiosResponseSuccess<EditQuickLinkResponse> {
      return putData<EditQuickLinkResponse>(API_PATHS.QUICK_LINKS.EDIT_LINK(data.linkId), {
        data,
      });
    },
    GET_DETAILS(linkId: string): AxiosResponseSuccess<QuickLinkDetailsResponse> {
      return getData<QuickLinkDetailsResponse>(API_PATHS.QUICK_LINKS.LINK_DETAILS(linkId));
    },
    CHANGE_LINKS_ORDER({ linksIds }: ChangeLinksOrderRequestParams): AxiosResponseSuccess<unknown> {
      return putData<unknown>(API_PATHS.QUICK_LINKS.CHANGE_LINKS_ORDER(), { data: linksIds });
    },
  },
  DEMO: {
    CLEAR(): AxiosResponseSuccess<unknown> {
      return deleteData<unknown>(API_PATHS.DEMO.CLEAR());
    },
  },
  NOTIFICATIONS: {
    GET_LIST(
      params: GetNotificationsListParams,
    ): AxiosResponseSuccess<GetNotificationsListResponse> {
      return getData<GetNotificationsListResponse>(API_PATHS.NOTIFICATIONS.LIST(), {
        params,
      });
    },
    COUNTER(): AxiosResponseSuccess<GetNotificationsCounterResponse> {
      return getData<GetNotificationsCounterResponse>(API_PATHS.NOTIFICATIONS.COUNTER());
    },
    READ_NOTIFICATIONS(ids: string[]): AxiosResponseSuccess<UnreadNotificationAmountResponse> {
      return postData<UnreadNotificationAmountResponse>(API_PATHS.NOTIFICATIONS.READ(), {
        data: {
          ids,
        },
      });
    },
    CREATE({ notification }: CreateCustomNotificationRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.NOTIFICATIONS.CREATE(), {
        data: notification,
      });
    },
    ACTIVATE({ id, ...otherParams }: ActivateNotificationParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.NOTIFICATIONS.ACTIVATE(id), {
        params: { ...otherParams },
      });
    },
  },
  EQUIPMENTS: {
    REGISTER({ userId, data }: RegisterEquipmentRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.USER.EQUIPMENTS(userId), {
        data,
      });
    },
    DETAILS({
      userId,
      equipmentId,
    }: GetEquipmentRequestParams): AxiosResponseSuccess<EquipmentPayload> {
      return getData<EquipmentPayload>(API_PATHS.USER.EQUIPMENT({ userId, equipmentId }));
    },
    DELETE({ userId, equipmentId }: DeleteEquipmentRequestParams): AxiosResponseSuccess<unknown> {
      return deleteData(API_PATHS.USER.EQUIPMENT({ userId, equipmentId }));
    },
    EDIT({ userId, equipmentId, data }: EditEquipmentRequestParams): AxiosResponseSuccess<unknown> {
      return putData(API_PATHS.USER.EQUIPMENT({ userId, equipmentId }), { data });
    },
    REQUEST({ userId, data }: RequestEquipmentRequestParams): AxiosResponseSuccess<unknown> {
      return postData(API_PATHS.USER.REQUEST_EQUIPMENT(userId), {
        data,
      });
    },
    REGISTER_REQUESTED({
      userId,
      equipmentId,
    }: RegisterRequestedEquipmentRequestParams): AxiosResponseSuccess<unknown> {
      return postData(API_PATHS.USER.REGISTER_REQUESTED_EQUIPMENT({ userId, equipmentId }));
    },
  },
  ATTACHMENTS: {
    UPLOAD({ file }: AddAttachmentRequestParams): AxiosResponseSuccess<AddAttachmentResponse> {
      return postData<AddAttachmentResponse>(API_PATHS.ATTACHMENTS.UPLOAD(), {
        data: file,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    VIDEO_UPLOAD({
      video_id,
      file_extension,
    }: AddVideoAttachmentRequestParams): AxiosResponseSuccess<AddAttachmentResponse> {
      return postData<AddAttachmentResponse>(API_PATHS.ATTACHMENTS.VIDEO_UPLOAD(), {
        data: { video_id, file_extension },
        headers: { 'Content-Type': 'application/json' },
      });
    },
    DETAILS({
      fileUrl,
    }: GetAttachmentDetailsRequestParams): AxiosResponseSuccess<UserGetAvatarResponseSuccess> {
      return getData<UserGetAvatarResponseSuccess>(fileUrl, {
        responseType: 'blob',
      });
    },
  },
  WEBPUSH: {
    SUBSCRIBE({ token }: SubscibeWebpushNotificationsRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.WEBPUSH.SUBSCRIBE(), {
        data: { token },
      });
    },
  },
  REACTIONS: {
    GET_LIST({ postId }: GetReactionRequestParams): AxiosResponseSuccess<GetReactionListResponse> {
      return getData<GetReactionListResponse>(API_PATHS.REACTIONS.GET_LIST(postId));
    },
    CREATE({ postId, reaction }: CreateReactionRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.REACTIONS.CREATE(postId), { data: { emoji: reaction } });
    },
    DELETE({ postId }: DeleteReactionRequestParams): AxiosResponseSuccess<unknown> {
      return deleteData<unknown>(API_PATHS.REACTIONS.DELETE(postId));
    },
  },
  ACHIEVEMENTS: {
    GET_LIST({
      userId,
      limit,
      exclude_accomplished,
    }: GetAchievementsRequestParams): AxiosResponseSuccess<GetAchievementsListResponse> {
      return getData<GetAchievementsListResponse>(API_PATHS.ACHIEVEMENTS.GET_LIST(userId), {
        params: {
          limit,
          exclude_accomplished,
        },
      });
    },
    CREATE({ userId, data }: CreateAchievementRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.ACHIEVEMENTS.CREATE(userId), { data });
    },
    CREATE_GOAL({
      userId,
      achievementId,
      data,
    }: CreateGoalRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(API_PATHS.ACHIEVEMENTS.CREATE_GOAL(userId, achievementId), { data });
    },
    ACHIEVEMENT_DETAILS({
      userId,
      achievementId,
    }: GetAchievementRequestParams): AxiosResponseSuccess<Achievement> {
      return getData<Achievement>(
        API_PATHS.ACHIEVEMENTS.ACHIEVEMENT_DETAILS({ userId, achievementId }),
      );
    },
    EDIT_ACHIEVEMENT({
      userId,
      achievementId,
      data,
    }: EditAchievementRequestParams): AxiosResponseSuccess<unknown> {
      return putData<unknown>(API_PATHS.ACHIEVEMENTS.EDIT_ACHIEVEMENT({ userId, achievementId }), {
        data,
      });
    },
    DELETE_ACHIEVEMENT({
      userId,
      achievementId,
    }: DeleteAchievementRequestParams): AxiosResponseSuccess<unknown> {
      return deleteData<unknown>(
        API_PATHS.ACHIEVEMENTS.DELETE_ACHIEVEMENT({ userId, achievementId }),
      );
    },
    GOAL_DETAILS({
      userId,
      achievementId,
      goalId,
    }: GetGoalRequestParams): AxiosResponseSuccess<Goal> {
      return getData<Goal>(API_PATHS.ACHIEVEMENTS.GOAL_DETAILS({ userId, achievementId, goalId }));
    },
    EDIT_GOAL({
      userId,
      achievementId,
      goalId,
      data,
    }: EditGoalRequestParams): AxiosResponseSuccess<Goal> {
      return putData<Goal>(API_PATHS.ACHIEVEMENTS.EDIT_GOAL({ userId, achievementId, goalId }), {
        data,
      });
    },
    COMMENT_GOAL({
      userId,
      achievementId,
      goalId,
      data,
    }: CommentGoalRequestParams): AxiosResponseSuccess<unknown> {
      return postData<unknown>(
        API_PATHS.ACHIEVEMENTS.COMMENT_GOAL({ userId, achievementId, goalId }),
        {
          data,
        },
      );
    },
    DELETE_GOAL({
      userId,
      achievementId,
      goalId,
    }: DeleteGoalRequestParams): AxiosResponseSuccess<unknown> {
      return deleteData<unknown>(
        API_PATHS.ACHIEVEMENTS.DELETE_GOAL({ userId, achievementId, goalId }),
      );
    },
  },
};
