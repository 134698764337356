import { styled } from '@mui/material';

export const Thumb = styled('div')`
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  &:hover {
    .remove-btn {
      display: flex;
    }
  }
`;

export const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const MoreImages = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.white.main};
`;
