import { PollFormValues, CreatePollRequest } from 'types';
import { removeExtraSpaces } from 'utils';

import { getUTCDate } from '../../utils';

export function formValuesAdapter({
  title,
  description,
  visibility,
  deadline,
  options,
}: PollFormValues): CreatePollRequest {
  const formattedOptions = options
    .map(({ value }) => removeExtraSpaces(value))
    .filter((value) => !!value);

  return {
    title: removeExtraSpaces(title),
    description: removeExtraSpaces(description),
    type: 'polls',
    ...(visibility && { assigned_to: visibility }),
    poll: {
      options: formattedOptions,
      ...(deadline && { deadline: getUTCDate(deadline) }),
    },
  };
}
