import { Button, styled, Typography, Radio } from '@mui/material';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

export const MainContentW = styled('div')`
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
  border-radius: 20px;
  width: 336px;
  padding: 24px 0;
  position: relative;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 16px 20px;
    &.checked {
      border: 1px solid ${({ theme }) => theme.palette.info.main};
    }
  }
`;

export const ContentW = styled('div')`
  padding: 0 24px;
  @media (max-width: 1023px) {
    padding: 0px;
  }
`;

export const ProductName = styled('div')`
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.palette.primary.p080};
  justify-content: center;
  display: flex;
  align-items: baseline;
  @media (max-width: 1023px) {
    justify-content: flex-start;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    align-items: center;
  }
`;

export const Row = styled('div')`
  padding: 16px 0;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  @media (max-width: 1023px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.primary.p080};
    text-align: start;
    border-bottom: none;
    padding: 0px;
    margin-top: 8px;
  }
`;

export const ImageW = styled('div')`
  height: 271px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const ButtonW = styled(Button, {
  shouldForwardProp: (propname) => propname !== 'isBlocked',
})<{ isBlocked: boolean }>(({ theme, isBlocked }) => ({
  width: '100%',
  textTransform: 'none',
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '30px',
  borderRadius: '12px',
  boxShadow: 'none',

  '&.Mui-disabled': {
    background: isBlocked ? theme.palette.primary.p020 : theme.palette.info.p030,
    color: theme.palette.white.main,
    cursor: 'not-allowed',
    pointerEvents: 'all',
  },
  [theme.breakpoints.down('desktop')]: {
    marginTop: '8px',
  },
}));

export const PriceBeforeDiscount = styled(Typography)`
  padding: 16px 0;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-decoration-line: line-through;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 0px;
    margin-top: 8px;
    text-align: start;
  }
`;

export const MainPrice = styled(Typography)`
  padding: 16px 0;
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    padding: 0px;
    margin-top: 8px;
    text-align: start;
  }
`;

export const InfoIconW = styled(InfoIcon)`
  margin-left: 8px;
  cursor: pointer;
`;

export const ActiveProductLabel = styled('div')`
  position: absolute;
  padding: 4px 13px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.white.main};
  background: ${({ theme }) => theme.palette.error.main};
  border-radius: 10px;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 1023px) {
    position: static;
    transform: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 12px;
    width: fit-content;
    margin-bottom: 8px;
  }
`;

export const MobileDuration = styled('div')`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid ${({ theme }) => theme.palette.info.main};
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.info.main};
  width: fit-content;
  padding: 4px;
  margin-left: auto;
`;

export const StyledRadioButton = styled(Radio)`
  &.MuiRadio-root {
    padding: 0px;
    margin-left: auto;
    color: ${({ theme }) => theme.palette.primary.p040};
  }
  &.Mui-checked {
    color: ${({ theme }) => theme.palette.info.main};
  }
  &.MuiSvgIcon {
    width: 13px;
    height: 13px;
  }
`;

export const CanceledPlanNote = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.primary.p040};
`;

export const CanceledSubscriptionNoteW = styled('div')`
  padding: 0 4px;
  height: 60px;
  margin-bottom: 16px;
`;

export const NoteW = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const BlockedProductNote = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.primary.p080};
  white-space: break-spaces;
  margin-left: 8px;
`;

export const Footer = styled('div')`
  padding: 0 16px;
`;

export const CancelNotAllowed = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 0 8px;
  color: ${({ theme }) => theme.palette.primary.p040};
`;
