import { UsersResponseSuccess } from 'api/types';
import { User } from 'types';

export function usersListAdapter(list?: UsersResponseSuccess['data']): User[] {
  if (!list) {
    return [];
  }

  return list.map(
    ({ first_name, last_name, country_code, birth_date, address_1, address_2, ...otherProps }) => ({
      firstName: first_name,
      lastName: last_name,
      countryCode: country_code,
      birthDate: birth_date,
      address1: address_1,
      address2: address_2,
      ...otherProps,
    }),
  );
}
