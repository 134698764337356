import { ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { useUserDetailsQuery } from 'api';
import { ReactComponent as EditIcon } from 'assets/icons/containerActions/edit.svg';
import { ConfirmDialog, GoBackButtonWithTitle, Spinner, TextArea, TopMenu } from 'components';
import { DEACTIVATE_USER_COMMENT_MAX_LENGTH } from 'constants/fieldsLength';
import { useModal, useAuth } from 'hooks';
import { UserStatus } from 'types';
import { getFullName } from 'utils';

import { EditEmployeeForm, ExtendedDetailsInfoBlock } from '../../components';
import { ExtendedDetailsAdapter } from './adapter';
import { useEmployeeExtendedDetails } from './hooks';
import { ButtonW, Column, ColumnsW, PageHeader, PageW } from './styled';

interface LocationState {
  showLicenses?: boolean;
  showEquipment?: boolean;
}

export function EmployeeExtendedDetailsPage(): ReactElement {
  const { employeeId = '' } = useParams<string>();

  const { state = {} } = useLocation();

  const licensesRef = useRef<HTMLDivElement>(null);
  const equipmentRef = useRef<HTMLDivElement>(null);

  const { isManager, user: currentUser } = useAuth();
  const { t } = useTranslation();
  const { isLoading, user, refetch } = useUserDetailsQuery({ employeeId });

  const { handleCloseModal: handleCloseEditModal, isOpen, handleOpenModal } = useModal();

  const {
    isOpenModal,
    setDeletedComment,
    handleCloseModal,
    deletedComment,
    deletedCommentError,
    onChangeEmployeeStatus,
    onHandleOpenChangeStatusModal,
    onHandleFocusTextArea,
  } = useEmployeeExtendedDetails(user?.id || '', user?.status);

  const showLicenses = (state as LocationState)?.showLicenses;
  const showEquipment = (state as LocationState)?.showEquipment;

  useEffect(() => {
    if (licensesRef.current && showLicenses) {
      licensesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [showLicenses]);

  useEffect(() => {
    if (equipmentRef.current && showEquipment) {
      equipmentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [showEquipment]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!user) {
    return <></>;
  }

  const isCurrentUser = currentUser?.id === user?.id;

  const { activationStatus, emergencyContact, privateInformation, publicInfo } =
    ExtendedDetailsAdapter({ user, onHandleOpenChangeStatusModal, isManager, isCurrentUser });

  const userFullName = getFullName({ firstName: user.firstName, lastName: user.lastName });

  const confirmDialogTitle = t(
    user.status === UserStatus.Active
      ? 'profile.extended.modal.offboard'
      : 'profile.extended.modal.activate',
    { userFullName },
  );

  return (
    <PageW>
      <TopMenu />
      {!isOpen && (
        <>
          <PageHeader>
            <GoBackButtonWithTitle title={t('profile.edit.form.title')} />
            <ButtonW onClick={handleOpenModal} endIcon={<EditIcon style={{ opacity: '0.4' }} />}>
              {t('profile.edit.text')}
            </ButtonW>
          </PageHeader>

          <ColumnsW>
            <Column>
              <ExtendedDetailsInfoBlock
                title={t('profile.edit.form.publicInfo.title')}
                data={publicInfo}
              />
              {isCurrentUser && isManager ? null : (
                <ExtendedDetailsInfoBlock
                  title={t('profile.extended.activationStatus.title')}
                  data={activationStatus}
                />
              )}
            </Column>

            <Column>
              <ExtendedDetailsInfoBlock
                title={t('profile.edit.form.privateInfo.title')}
                data={privateInformation}
              />
              <ExtendedDetailsInfoBlock
                title={t('profile.edit.form.emergency.title')}
                data={emergencyContact}
              />
            </Column>
          </ColumnsW>
        </>
      )}

      {isOpen && (
        <EditEmployeeForm
          open={isOpen}
          onClose={handleCloseEditModal}
          employee={user}
          refetchUserDetails={refetch}
          formTitle={t('profile.edit.form.title')}
        />
      )}
      <ConfirmDialog
        title={confirmDialogTitle}
        subTitle={t('profile.extended.modal.exit')}
        open={isOpenModal}
        onClose={handleCloseModal}
        onSubmit={onChangeEmployeeStatus}
        isLoading={false}
      >
        <TextArea
          placeholder={t('profile.extended.modal.placeholder')}
          minRows={4}
          onChange={(e) => setDeletedComment(e.target.value)}
          value={deletedComment}
          errorText={deletedCommentError}
          maxLength={DEACTIVATE_USER_COMMENT_MAX_LENGTH}
          onFocus={onHandleFocusTextArea}
          onBlur={onHandleFocusTextArea}
        />
      </ConfirmDialog>
    </PageW>
  );
}
