import { AxiosError } from 'axios';

import { ApiErrors } from 'types';

export function canRefetch(error: AxiosError<ApiErrors>) {
  // if ms has empty events list - set empty[]
  if (error.response?.status === 404) {
    return false;
  }

  // if user is not authorized with calendar stop retrying
  return (error as AxiosError).response?.status !== 401;
}
