import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useCurrentSubscriptionQuery } from 'api';
import { ReactComponent as EditIcon } from 'assets/icons/containerActions/edit.svg';
import { GoBackButtonWithTitle, Spinner, TopMenu } from 'components';
import { useAuth, useWindowSize } from 'hooks';

import { EditCompanyForm, CompanyDetails } from '../../components';
import { useCompanyDetails } from './hooks';
import { ButtonW, Column, ColumnsW, PageHeader, PageW, Footer, LinkW } from './styled';

export function CompanyDetailsPage(): ReactElement {
  const { t } = useTranslation();
  const { isManager } = useAuth();
  const { isMobile } = useWindowSize();

  useCurrentSubscriptionQuery();

  const { isLoading, companyDetails, handleOpenModal, handleCloseModal, isOpen, company } =
    useCompanyDetails();

  if (isLoading) {
    return <Spinner />;
  }

  const { generalInfo, cardDetails, contactInfo, subscriptionInfo } = companyDetails;

  return (
    <PageW>
      <TopMenu />
      {!isOpen && (
        <>
          <PageHeader>
            <GoBackButtonWithTitle title={t('company.edit.form.title')} />
            {isManager && (
              <ButtonW onClick={handleOpenModal} endIcon={<EditIcon style={{ opacity: '0.4' }} />}>
                {t('company.edit.text')}
              </ButtonW>
            )}
          </PageHeader>

          <ColumnsW>
            <Column>
              <CompanyDetails title={t('company.edit.form.general.title')} data={generalInfo} />
              {isManager && !isMobile && (
                <CompanyDetails
                  title={t('company.edit.form.cardDetails.title')}
                  data={cardDetails}
                />
              )}
            </Column>

            <Column>
              <CompanyDetails title={t('company.edit.form.contactInfo.title')} data={contactInfo} />
              {isManager && isMobile && (
                <CompanyDetails
                  title={t('company.edit.form.cardDetails.title')}
                  data={cardDetails}
                />
              )}
              {isManager && (
                <CompanyDetails
                  title={t('company.edit.form.subscriptionDetails.title')}
                  data={subscriptionInfo}
                />
              )}
            </Column>
          </ColumnsW>
          {isManager && (
            <Footer>
              <Trans i18nKey="company.contactText">
                Contact our support at <LinkW underline="none">hello@adligo.io</LinkW> to deactivate
                your company account.
              </Trans>
            </Footer>
          )}
        </>
      )}

      {isOpen && company && (
        <EditCompanyForm open={isOpen} onClose={handleCloseModal} company={company} />
      )}
    </PageW>
  );
}
