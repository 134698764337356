import { IconButton } from '@mui/material';
import { sv } from 'date-fns/locale';
import { SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'assets/icons/containerActions/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/containerActions/trash.svg';
import { DEFAULT_DATE_FORMAT } from 'constants/index';
import { Equipment, EquipmentsTableFields } from 'types/equipments';
import { formattedDate } from 'utils/dateHelpers';

import { categoryOptions } from '../../constants';
import { EquipmentAttachments } from '../../EquipmentAttachements';
import { iconButtonStyle, TitleCategory, TitleName } from './styled';

interface RegisteredEquipmentAdapterProps {
  isManager: boolean;
  currentUserId?: string;
  data: Equipment[];
  isSwedish: boolean;
  onTrashIconClick: ({ id, name }: Pick<Equipment, 'id' | 'name'>) => void;
  onEditIconClick: ({ id, userId }: Pick<Equipment, 'id' | 'userId'>) => void;
  getIsExpanded: (id: string) => boolean;
  setIsExpanded: (id: string) => void;
}

export function registeredEquipmentAdapter({
  isManager,
  currentUserId = '',
  data,
  isSwedish,
  onTrashIconClick,
  onEditIconClick,
  getIsExpanded,
  setIsExpanded,
}: RegisteredEquipmentAdapterProps): EquipmentsTableFields[] {
  if (!data || data.length === 0) {
    return [];
  }
  const locales = isSwedish ? sv : undefined;

  const categorySubTitle = Object.fromEntries(
    categoryOptions.map(({ value, label }) => [value, label]),
  );

  return data.map(
    ({
      id,
      name,
      category,
      expireAt,
      orderedAt,
      attachments,
      attachmentOrder,
      serial,
      isEditable,
      userId,
      authorId,
    }) => {
      const isCurrentAuthor = currentUserId === authorId;
      const isCurrentAssignee = currentUserId === userId;
      const isEditableByManager =
        isManager &&
        ((isCurrentAuthor && isCurrentAssignee && isEditable) ||
          (isCurrentAuthor && !isCurrentAssignee) ||
          (!isCurrentAuthor && !isCurrentAssignee));
      const isExpanded = getIsExpanded(id);

      const handleTrashIconClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        onTrashIconClick({ id, name });
      };

      const handleEditIconClick = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onEditIconClick({ id, userId });
      };

      const setExpanded = () => setIsExpanded(id);

      return {
        name: (
          <>
            <TitleName>{name}</TitleName>
            <TitleCategory>{categorySubTitle[category]}</TitleCategory>
          </>
        ),
        expiryDate: expireAt ? formattedDate(new Date(expireAt), DEFAULT_DATE_FORMAT, locales) : '',
        orderedOn: orderedAt
          ? formattedDate(new Date(orderedAt), DEFAULT_DATE_FORMAT, locales)
          : '',
        attachments: (
          <EquipmentAttachments
            isExpanded={isExpanded}
            setIsExpanded={setExpanded}
            attachments={attachments}
            attachmentOrder={attachmentOrder}
          />
        ),
        serial,
        actions: (
          <>
            {(isEditableByManager || isEditable) && (
              <>
                <NavLink to="">
                  <IconButton sx={iconButtonStyle} onClick={handleEditIconClick}>
                    <EditIcon style={{ opacity: '0.6' }} />
                  </IconButton>
                </NavLink>
                <IconButton sx={iconButtonStyle} onClick={handleTrashIconClick}>
                  <TrashIcon style={{ opacity: '0.6' }} />
                </IconButton>
              </>
            )}
          </>
        ),
        isClickable: isEditable || isEditableByManager,
        userId,
        id,
        isExpanded,
      };
    },
  );
}
