import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useSubscription } from 'hooks';

import { InviteEmployeesForm, InviteEmployeesPageHeader } from '../../components';
import { PageW, PostSubmissionNote } from './styled';

type LocationState = {
  submitted: boolean;
};

export function InviteEmployeePage(): ReactElement {
  const [formHasContent, setFormHasContent] = useState(false);

  const { t } = useTranslation();
  const { isTrial } = useSubscription();

  const { state } = window.history;
  const isSubmitted = (state as LocationState)?.submitted;
  const pageContent = isSubmitted ? (
    <PostSubmissionNote>{t('inviteEmployee.postSubmissionNote')}</PostSubmissionNote>
  ) : (
    <InviteEmployeesForm setFormHasContent={setFormHasContent} />
  );

  if (isTrial) {
    return (
      <Navigate
        to={{
          pathname: 'not-found',
        }}
      />
    );
  }

  return (
    <PageW>
      <InviteEmployeesPageHeader formHasContent={formHasContent} />
      {pageContent}
    </PageW>
  );
}
