import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRegisterRequestedEquipmentMutation } from 'api/mutations/useRegisterRequestedEquipmentMutation';
import { useEquipmentsQuery } from 'api/queries/useEquipmentsQuery';
import { useAuth } from 'hooks';
import { Equipment, EquipmentCategory, EquipmentStatus } from 'types';

import { requestedEquipmentAdapter } from '../adapters/requestedEquipmentAdapter';

interface UseRegisteredEquipmentTableProps {
  userId: string;
  category: EquipmentCategory | null;
  isSwedish: boolean;
  onTrashIconClick: ({ id, name }: Pick<Equipment, 'id' | 'name'>) => void;
}

export function useRequestedEquipmentTable({
  userId,
  category,
  isSwedish,
  onTrashIconClick,
}: UseRegisteredEquipmentTableProps) {
  const { isManager, user } = useAuth();
  const { t } = useTranslation();
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const getIsExpanded = useCallback((id: string) => expandedItems.includes(id), [expandedItems]);

  const setIsExpanded = useCallback((id: string) => {
    setExpandedItems((prev: string[]) => {
      return prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id];
    });
  }, []);

  const { equipments, isLoading: isEquipmentsListLoading } = useEquipmentsQuery({ userId });
  const { registerRequestedEquipmentMutation, isLoading: isRegisterRequestedLoading } =
    useRegisterRequestedEquipmentMutation({
      invalidateQueries: ['equipments'],
      successText: t('toasts.equipments.success.register'),
    });

  const handleRegisterRequestedEquipment = useCallback(
    async ({ id, userId }: Pick<Equipment, 'id' | 'userId'>) => {
      await registerRequestedEquipmentMutation({ userId, equipmentId: id });
    },
    [registerRequestedEquipmentMutation],
  );

  const requestedEquipment = useMemo(
    () => equipments.filter((equipment) => equipment.status === EquipmentStatus.REQUESTED),
    [equipments],
  );

  const data = useMemo(
    () =>
      requestedEquipment.filter((equipment) => (category ? equipment.category === category : true)),
    [category, requestedEquipment],
  );

  return {
    isLoading: isEquipmentsListLoading || isRegisterRequestedLoading,
    equipmentList: requestedEquipmentAdapter({
      isManager,
      currentUserId: user?.id,
      data,
      isSwedish,
      onTrashIconClick,
      onConfirmIconClick: handleRegisterRequestedEquipment,
      getIsExpanded,
      setIsExpanded,
    }),
    isFilterShown: !!requestedEquipment.length,
  };
}
