import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSignature } from 'api';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import {
  Avatar,
  Backdrop,
  BaseTextFieldController,
  Form,
  FormGroup,
  RichEditorController,
  SubmitRoundedButton,
} from 'components';
import { ACHIEVEMENT_FORM_FIELDS_LENGTH } from 'constants/fieldsLength';
import { useWindowSize } from 'hooks';
import { textFieldStyles, textFieldMobileStyles, richEditorStyles } from 'styles';
import { AchievementFormValues } from 'types';
import { getFullName } from 'utils';

import { ColorsSelect } from '../ColorsSelect';
import { initialWindowSizeProps } from '../constants';
import { useAchievementForm } from './hooks';
import { ActionsW, AuthorName, PublishedDateW, RemoveButtonW } from './styled';
import { avatarWrapperMobileSx, avatarWrapperSx, submitButtonSx, titleFormGroupSx } from './styles';
import { descriptionFieldRules, titleFieldRules } from './validation';

interface AchievementFormProps {
  isLoading: boolean;
  defaultValues: AchievementFormValues;
  onSubmit: (data: AchievementFormValues) => void;
  handleRemoveClick?: () => void;
  mode?: 'create' | 'edit';
  author?: UserSignature;
  createdAt?: string;
}

export function AchievementForm({
  isLoading: isAchievementMutationLoading,
  defaultValues,
  onSubmit,
  mode = 'create',
  author,
  createdAt,
  handleRemoveClick,
}: AchievementFormProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize(initialWindowSizeProps);

  const isEditMode = mode === 'edit';

  const { isSubmitting, isDirty, control, handleFormSubmit } = useAchievementForm({
    isEditMode,
    defaultValues,
    onSubmit,
  });

  const isSubmitButtonDisabled =
    (isEditMode && !isDirty) || isSubmitting || isAchievementMutationLoading;
  const formGroupMarginBottom = isMobile ? 16 : 24;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;

  return (
    <>
      {(isAchievementMutationLoading || isSubmitting) && <Backdrop />}

      <>
        <Form onSubmit={handleFormSubmit}>
          <FormGroup marginBottom={formGroupMarginBottom} sx={titleFormGroupSx(isMobile)}>
            <BaseTextFieldController
              name="title"
              control={control}
              placeholder={t('career.achievements.create.form.placeholders.title')}
              sx={fieldStyles}
              maxLength={ACHIEVEMENT_FORM_FIELDS_LENGTH.title.max}
              rules={titleFieldRules}
            />

            <ColorsSelect control={control} />
          </FormGroup>

          <FormGroup marginBottom={formGroupMarginBottom} row>
            <RichEditorController
              name="description"
              control={control}
              placeholder={t('career.achievements.create.form.placeholders.description')}
              maxLength={ACHIEVEMENT_FORM_FIELDS_LENGTH.description.max}
              rules={descriptionFieldRules}
              sx={richEditorStyles(isMobile)}
            />
          </FormGroup>

          {!isMobile && (
            <SubmitRoundedButton disabled={isSubmitButtonDisabled} sx={submitButtonSx} />
          )}

          {isEditMode && author && (
            <>
              <Avatar
                size={isMobile ? 'xs' : 's'}
                userId={author?.id}
                filename={author?.avatar?.small || ''}
                wrapperSx={isMobile ? avatarWrapperMobileSx : avatarWrapperSx}
              />
              <AuthorName>
                {getFullName({ firstName: author?.first_name, lastName: author?.last_name })}
              </AuthorName>
            </>
          )}
          {isEditMode && createdAt && <PublishedDateW>{createdAt}</PublishedDateW>}

          {isEditMode && isMobile && (
            <ActionsW>
              <RemoveButtonW onClick={handleRemoveClick} endIcon={<TrashIcon />}>
                {t('tasks.details.removeButton')}
              </RemoveButtonW>
            </ActionsW>
          )}
        </Form>

        {isMobile && (
          <SubmitRoundedButton
            disabled={isSubmitButtonDisabled}
            sx={submitButtonSx}
            className="fixed-bottom"
            onClick={handleFormSubmit}
          />
        )}
      </>
    </>
  );
}
