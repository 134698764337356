import { EquipmentFormValues, EquipmentPayload } from 'types';

interface DefaultValuesAdapterProps {
  isDefaultEditable: boolean;
  data?: EquipmentPayload;
}

export function defaultValuesAdapter({
  isDefaultEditable,
  data,
}: DefaultValuesAdapterProps): EquipmentFormValues {
  const customFields = data?.custom_fields ?? [];

  const images = data?.attachments?.filter((file) => file.type === 'image') || [];
  const docs = data?.attachments?.filter((file) => file.type === 'document') || [];
  const videos = data?.attachments?.filter((file) => file.type === 'video') || [];

  return {
    title: data?.name ?? '',
    category: data?.category ?? '',
    user: data?.user_id ?? '',
    expireAt: data?.expire_at ?? '',
    orderedAt: data?.ordered_at ?? '',
    serial: data?.serial ?? '',
    comment: data?.comment ?? '',
    isEditable: isDefaultEditable || Boolean(data?.is_editable),
    docs,
    images,
    videos,
    customFields: [...customFields, { key: '', value: '' }],
  };
}
