import { UserSignature } from 'api';
import { Comment } from 'types';

import { Attachment } from './attachment';

export enum GoalStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  BLOCKED = 'blocked',
  ACCOMPLISHED = 'accomplished',
}

export type AchievementGoalAssignedType = 'all' | 'users';

export interface Goal {
  id: string;
  title: string;
  description: string;
  status: GoalStatus;
  deadline?: string | null;
  attachments?: Attachment[];
  owner_id: string;
  author?: UserSignature;
  created_at?: string;
  comments?: Comment[];
  comments_count?: number;
  editable?: boolean;
  assigned_type?: AchievementGoalAssignedType;
  assigned_to_ids?: string[];
}

export interface Achievement {
  id: string;
  title: string;
  color: string;
  progress: number;
  owner_id: string;
  description?: string;
  goals?: Goal[];
  author?: UserSignature;
  created_at?: string;
}

export interface AchievementFormValues {
  title: string;
  description: string;
  color: string;
}

export enum AchievementsTabsValues {
  ACTIVE = 'active',
  ACCOMPLISHED = 'accomplished',
}

export interface AchievementsTab {
  label: string;
  value: AchievementsTabsValues;
}
