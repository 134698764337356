import { ReactElement, RefObject } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactionStats } from 'api';
import { ReactComponent as ProcessIcon } from 'assets/icons/process-automation.svg';
import { ReactComponent as WelcomeIcon } from 'assets/icons/welcomePostIcon.svg';
import welcomePost from 'assets/images/welcomePost.png';
import { ReactionsWrapper } from 'components/ReactionsWrapper';
import { useModal } from 'hooks';
import { InViewItemRef } from 'types';

import { PostItemActions } from '../../PostItemActions';
import { ReactionConfirmDialog } from '../../ReactionConfirmDialog';
import { Container, IconW, LeftSide, LinkW, PostTitle, PostW, RightSide } from '../styled';
import { WelcomeDescription, WelcomeImage } from './styled';

interface WelcomePostProps {
  authorName: string;
  onNavigateToNewUser: () => void;
  postRef: RefObject<HTMLDivElement>;
  lastItemRef?: InViewItemRef;
  handlePostClick?: () => void;
  comments_count?: number;
  id: string;
  reactions?: ReactionStats;
}

export function WelcomePost({
  authorName,
  onNavigateToNewUser,
  postRef,
  lastItemRef,
  handlePostClick,
  comments_count,
  id,
  reactions,
}: WelcomePostProps): ReactElement {
  const { t } = useTranslation();
  const { handleOpenModal, handleCloseModal, isOpen } = useModal();

  return (
    <Container ref={postRef}>
      <IconW>
        <WelcomeIcon />
        <ProcessIcon />
      </IconW>

      <PostW ref={lastItemRef} onClick={handlePostClick} isAutomatedPost>
        <LeftSide>
          <WelcomeImage src={welcomePost} alt="welcome post image" />
          <PostTitle>
            {t('post.listItem.welcomePost.title', {
              user: authorName,
            })}
          </PostTitle>
          <WelcomeDescription>
            <Trans
              i18nKey="post.listItem.welcomePost.description"
              values={{
                linkLabel: authorName,
              }}
            >
              We have some great news to share. Our family grows! Take a chance to say hello to{' '}
              <LinkW
                onClick={(e) => {
                  e.stopPropagation();
                  onNavigateToNewUser();
                }}
              >
                {authorName}
              </LinkW>
            </Trans>
          </WelcomeDescription>
          <ReactionsWrapper
            reactions={reactions}
            handleOpenModal={handleOpenModal}
            id={id}
            isAutoPost
          />
        </LeftSide>

        <RightSide>
          <PostItemActions isPreview={false} commentsCount={comments_count} />
        </RightSide>
      </PostW>

      <ReactionConfirmDialog
        title={t('reactions.title')}
        onClose={handleCloseModal}
        postId={id}
        open={isOpen}
      />
    </Container>
  );
}
