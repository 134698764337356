import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES } from 'constants/index';
import { QuickLink } from 'types';

interface UsePostDetailsReturn {
  isLoading: boolean;
  links: QuickLink[];
}

export function useQuickLinksQuery(): UsePostDetailsReturn {
  const { data, isLoading } = useQuery(
    [QUERIES.QUICK_LINKS_LIST],
    () => API_HANDLERS.QUICK_LINKS.GET_USER_LINKS(),
    {
      cacheTime: 0,
    },
  );
  return {
    isLoading: isLoading,
    links: data?.data || [],
  };
}
