import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';
import { queryClient } from 'api/queryClient';
import { QUERIES } from 'constants/index';

import { ChangeLinksOrderRequestParams } from '../types';

interface UseChangeLinksOrderMutationReturn {
  isLoading: boolean;
  changeLinksOrdersMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    ChangeLinksOrderRequestParams,
    unknown
  >;
}

export function useChangeLinksOrderMutation(): UseChangeLinksOrderMutationReturn {
  const { mutateAsync: changeLinksOrdersMutation, isLoading } = useMutation(
    API_HANDLERS.QUICK_LINKS.CHANGE_LINKS_ORDER,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.QUICK_LINKS_LIST]);
      },
    },
  );

  return {
    changeLinksOrdersMutation,
    isLoading,
  };
}
