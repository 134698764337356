import { styled, Typography } from '@mui/material';

import { AvatarSize } from '.';
import { getAvatarSize, getUserNameWidth } from './utils';

export const AvatarW = styled('div')<{ size?: AvatarSize }>(({ size }) => ({
  textAlign: 'center',
  position: 'relative',

  '.MuiAvatar-root': {
    ...getAvatarSize(size),
  },
  '.MuiAvatar-img': {
    ...getAvatarSize(size),
  },

  img: {
    width: '80%',
    height: '80%',
  },

  '.camera-icon': {
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'none',
  },

  '&:hover': {
    cursor: 'pointer',

    '.hovered-wrap': {
      display: 'block',
    },
    '.camera-icon': {
      display: 'block',
    },
  },
}));

export const UserName = styled(Typography)<{ size?: AvatarSize }>(({ theme, size }) => ({
  fontSize: '12px',
  lineHeight: '20px',
  textAlign: 'center',
  marginTop: '4px',
  color: theme.palette.primary.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ...getUserNameWidth(size),
}));

export const HoverW = styled('div')`
  background: rgba(0, 155, 242, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  border-radius: 50%;
  display: none;
`;

export const DefaultAvatar = styled('img')`
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const AssigneeInitials = styled('div')`
  font-size: 14px;
`;
