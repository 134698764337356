import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentSubscriptionQuery } from 'api';
import { CompanySelector } from 'features/companySelector';
import { NotificationPreview } from 'features/notifications';
import { useAuth } from 'hooks';

import { ActionOptionsList } from '../../components';
import { PageTitle, PageW, TopMenuW } from './styled';

export function ActionsPage(): ReactElement {
  const { t } = useTranslation();
  const { isManager } = useAuth();

  useCurrentSubscriptionQuery();

  const pageTitle = isManager ? 'actions.manager.title' : 'actions.employee.title';

  return (
    <PageW isManager={isManager}>
      <TopMenuW>
        <div style={{ flexGrow: 1 }}>
          <PageTitle variant="h1">{t(pageTitle)}</PageTitle>
        </div>
        <CompanySelector />
        <NotificationPreview />
      </TopMenuW>
      <ActionOptionsList />
    </PageW>
  );
}
