import { styled } from '@mui/material';

export const PollDescription = styled('div')`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const Container = styled('div')`
  position: relative;
  margin-bottom: 16px;
`;
export const OptionTitle = styled('div')`
  color: #1f2937;
  font-size: 14px;
  font-weight: 400;
  max-width: 280px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
`;
export const PercentAmount = styled(OptionTitle)``;

export const OptionDetails = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OptionLine = styled('div')`
  width: 100%;
  height: 8px;
  border-radius: 8px;
  margin-top: 6px;
  background: ${({ theme }) => theme.palette.white.main};
`;

export const OptionBackground = styled('div')<{ percentNumber: string; isMaxVotesAmount: boolean }>`
  display: flex;
  background: ${({ isMaxVotesAmount, theme }) =>
    isMaxVotesAmount ? theme.palette.info.main : '#99d7fa'};
  height: 8px;
  width: ${({ percentNumber }) => percentNumber};
  border-radius: 8px;
  position: relative;
  z-index: 1;
`;
