import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAchievementDetailsQuery } from 'api';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ConfirmDialog, GoBackButtonWithTitle, PageWrapper } from 'components';
import { useAuth } from 'hooks';

import { EditAchievementForm } from '../../components';
import { useDeleteAchievement } from './hooks';
import { ActionsW, ButtonW, ContentW, FormW } from './styled';

export function EditAchievementPage(): ReactElement {
  const { t } = useTranslation();
  const { achievementId = '', employeeId = '' } = useParams();
  const { user: currentUser, isManager } = useAuth();
  const currentUserId = currentUser?.id ?? '';

  const {
    isConfirmDeleteLoading,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    handleDeleteAchievement,
    handleRemoveClick,
  } = useDeleteAchievement();

  const {
    data,
    isLoading: isDetailsLoading,
    isSuccess: isDetailsLoaded,
  } = useAchievementDetailsQuery({
    userId: employeeId,
    achievementId,
  });

  const achievementOwnerId = data?.owner_id ?? '';

  const removeModalTitle = !!data?.goals?.length
    ? t('career.achievements.remove.withGoalsTitle')
    : t('career.achievements.remove.title');

  const isRemoveAvailable =
    (achievementOwnerId === currentUserId && employeeId === currentUserId) ||
    (employeeId === achievementOwnerId && currentUserId === achievementOwnerId) ||
    (isManager && currentUserId !== achievementOwnerId && currentUserId !== employeeId);

  return (
    <>
      <PageWrapper>
        <GoBackButtonWithTitle title={t('career.achievements.edit.title')} />
        <ContentW>
          <FormW>
            <EditAchievementForm
              data={data}
              isDetailsLoaded={isDetailsLoaded}
              isDetailsLoading={isDetailsLoading}
              handleRemoveClick={handleRemoveClick}
            />
          </FormW>

          <ActionsW>
            {isRemoveAvailable && (
              <ButtonW onClick={handleRemoveClick} endIcon={<TrashIcon />}>
                {t('post.details.removeButton')}
              </ButtonW>
            )}
          </ActionsW>
        </ContentW>
      </PageWrapper>

      <ConfirmDialog
        title={removeModalTitle}
        subTitle={t('career.achievements.edit.title')}
        open={isDeleteModalOpen}
        onSubmit={handleDeleteAchievement}
        onClose={handleCloseDeleteModal}
        isLoading={isConfirmDeleteLoading}
      />
    </>
  );
}
