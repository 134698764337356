import { ReactElement } from 'react';

import { TargetFormValues } from 'types';

import { TargetForm } from '../TargetForm';
import { useEditTargetForm } from './useEditTargetForm';

interface EditTargetFormProps extends TargetFormValues {
  handleOpenModal: () => void;
}

export function EditTargetForm({
  targetId = '',
  handleOpenModal,
  ...defaultValues
}: EditTargetFormProps): ReactElement {
  const { handleSubmit, isLoading } = useEditTargetForm(targetId);

  return (
    <TargetForm
      mode="edit"
      handleSubmit={handleSubmit}
      defaultValues={defaultValues}
      isSubmitting={isLoading}
      handleOpenModal={handleOpenModal}
    />
  );
}
