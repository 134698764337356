import { theme } from 'styles';

export const defaultStyles = {
  '& .MuiSelect-select.MuiSelect-select': {
    display: 'flex',
    height: '85px',
    alignItems: 'center',
    fontSize: '24px',

    '&:focus': {
      background: 'none',
    },
  },

  '& .MuiSelect-icon': {
    fontSize: '40px',
    right: '24px',
    color: theme.palette.primary.p030,
  },
};

export const defaultMobileStyles = {
  '& .MuiSelect-select.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',

    '&:focus': {
      background: 'none',
    },
  },

  '& .MuiSelect-icon': {
    fontSize: '40px',
    right: '24px',
    color: theme.palette.primary.p030,
  },
};

export const menuItemStyles = { fontSize: '18px', padding: '8px 24px' };
