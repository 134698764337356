import { useForm, Control } from 'react-hook-form';

import { PasswordResetFormValues } from 'types';

import { defaultValues } from './config';

interface UseRestorePasswordFormReturn {
  isSubmitting: boolean;
  handleFormSubmit: () => void;
  control: Control<PasswordResetFormValues>;
}

interface UseRestorePasswordFormProps {
  setEmail: (email: string) => void;
  requestPasswordResetMutation: (data: PasswordResetFormValues) => void;
}

export function useRestorePasswordForm({
  setEmail,
  requestPasswordResetMutation,
}: UseRestorePasswordFormProps): UseRestorePasswordFormReturn {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<PasswordResetFormValues>({
    defaultValues,
    mode: 'onBlur',
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    await requestPasswordResetMutation(data);
    setEmail(data.email);
  });

  return {
    control,
    handleFormSubmit,
    isSubmitting,
  };
}
