import { useEffect } from 'react';

import { Events, Socket } from 'api/sockets';

interface UseNewNotificationsProps {
  onNewNotification: () => void;
}

export function useNewNotifications({ onNewNotification }: UseNewNotificationsProps) {
  useEffect(() => {
    const handleNewNotification = () => {
      onNewNotification();
    };

    Socket.subscribe(Events.newNotification, handleNewNotification);

    return function () {
      Socket.unsubscribe(Events.newNotification, handleNewNotification);
    };
  }, [onNewNotification]);
}
