import { styled } from '@mui/material';

export const EmptyW = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: break-spaces;
`;

export const StyledText = styled('div')`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  white-space: break-spaces;
`;
