import { ReactElement } from 'react';
import { Pagination } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';

import { Achievement, User } from 'types';

import { AchievementSection } from '../AchievementSection';
import { ListW, MobileListW } from './styled';

interface AchievementsListProps {
  data: Achievement[];
  isMobile: boolean;
  employeeId: string;
  usersList: User[];
  isFullView?: boolean;
  isProfilePreview?: boolean;
}

export function AchievementsList({
  data,
  isMobile,
  employeeId,
  usersList,
  isProfilePreview,
  isFullView = false,
}: AchievementsListProps): ReactElement {
  return (
    <>
      {isMobile ? (
        <MobileListW isBackground={isFullView}>
          <SwiperComponent pagination modules={[Pagination]}>
            {data.map(({ id, title, description, color, goals, progress, owner_id }) => (
              <SwiperSlide key={id}>
                <AchievementSection
                  id={id}
                  title={title}
                  color={color}
                  progress={progress}
                  description={description}
                  goals={goals}
                  employeeId={employeeId}
                  isFullView={isFullView}
                  isProfilePreview={isProfilePreview}
                  ownerId={owner_id}
                  usersList={usersList}
                />
              </SwiperSlide>
            ))}
          </SwiperComponent>
        </MobileListW>
      ) : (
        <ListW isFullView={isFullView}>
          {data.map(({ id, title, description, color, goals, progress, owner_id }) => (
            <AchievementSection
              key={id}
              id={id}
              title={title}
              color={color}
              progress={progress}
              description={description}
              goals={goals}
              employeeId={employeeId}
              isFullView={isFullView}
              isProfilePreview={isProfilePreview}
              ownerId={owner_id}
              usersList={usersList}
            />
          ))}
        </ListW>
      )}
    </>
  );
}
