import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactElement, ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { queryClient } from 'api';
import { AlertsContainer } from 'components';
import { theme } from 'styles';

import { AuthProvider } from './AuthContext';
import { SubscriptionProvider } from './SubscriptionContext';

interface ContextProvidersProps {
  children: ReactNode;
}

export function ContextProviders({ children }: ContextProvidersProps): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === 'development' && (
            <ReactQueryDevtools
              position="bottom-right"
              initialIsOpen={false}
              toggleButtonProps={{ style: { opacity: '0.2' } }}
            />
          )}
          <CssBaseline />
          <AlertsContainer />
          <SubscriptionProvider>
            <AuthProvider>{children}</AuthProvider>
          </SubscriptionProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
