import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { FooterText, LinkW } from './styled';

export function TermsAndConditions(): ReactElement {
  return (
    <FooterText>
      <Trans i18nKey="terms">
        By submitting the form you confirm that you have read through and accepted our
        <LinkW
          href={process.env.PUBLIC_URL + '/Adligo_Terms&Conditions.pdf'}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
        >
          Terms & Conditions
        </LinkW>
        and
        <LinkW
          href={process.env.PUBLIC_URL + '/Adligo_Privacy_Notice.pdf'}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
        >
          Privacy Policy
        </LinkW>
      </Trans>
    </FooterText>
  );
}
