import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { DialogProps } from '@mui/material';
import { ReactElement, useState } from 'react';

import { useGetPostReactionsQuery } from 'api/queries/useGetPostReactionsQuery';
import { ReactComponent as HeartReaction } from 'assets/icons/postReactions/heart.svg';
import { ReactComponent as LikeReaction } from 'assets/icons/postReactions/like.svg';
import { Avatar } from 'components';

import {
  Dialog,
  CloseButton,
  DialogHeader,
  DialogHeaderAll,
  DialogHeaderLike,
  DialogHeaderHeart,
  DialogItem,
  DialogContainer,
  AuthorName,
  Line,
} from './styled';

enum ReactionTabType {
  all = 'all',
  like = '👍',
  heart = '❤️',
}

interface ReactionConfirmDialogProps extends DialogProps {
  onClose?: () => void;
  postId: string;
}

export function ReactionConfirmDialog({
  title,
  onClose,
  open,
  postId,
  ...otherProps
}: ReactionConfirmDialogProps): ReactElement {
  const { reactions: data } = useGetPostReactionsQuery({ postId });

  const reactions = [...data].sort(function (a, b) {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  const likesAmount = reactions.filter(
    ({ emoji }) => (emoji as unknown as ReactionTabType) === ReactionTabType.like,
  ).length;

  const heartAmount = reactions.filter(
    ({ emoji }) => (emoji as unknown as ReactionTabType) === ReactionTabType.heart,
  ).length;

  const [activeTab, setActiveTab] = useState<ReactionTabType>(ReactionTabType.all);

  function handleUserClick(reaction: ReactionTabType) {
    setActiveTab(reaction);
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          overflow: 'hidden',
        },
      }}
      {...otherProps}
    >
      <CloseButton
        variant="text"
        startIcon={<KeyboardBackspaceRoundedIcon fontSize="large" />}
        onClick={onClose}
      >
        {title}
      </CloseButton>

      <DialogHeader>
        <DialogHeaderAll
          onClick={(e) => {
            e.stopPropagation();
            handleUserClick(ReactionTabType.all);
          }}
        >
          <p>All</p>
          <Line isActive={activeTab === ReactionTabType.all} />
        </DialogHeaderAll>

        <DialogHeaderLike
          disabled={!likesAmount}
          onClick={(e) => {
            if (!likesAmount) return;
            e.stopPropagation();
            handleUserClick(ReactionTabType.like);
          }}
        >
          <LikeReaction />
          {likesAmount}
          <Line isReaction isActive={activeTab === ReactionTabType.like} />
        </DialogHeaderLike>

        <DialogHeaderHeart
          disabled={!heartAmount}
          onClick={(e) => {
            if (!heartAmount) return;
            e.stopPropagation();
            handleUserClick(ReactionTabType.heart);
          }}
        >
          <HeartReaction />
          {heartAmount}
          <Line isReaction isActive={activeTab === ReactionTabType.heart} />
        </DialogHeaderHeart>
      </DialogHeader>

      <DialogContainer>
        {reactions
          .filter(
            ({ emoji }) =>
              activeTab === ReactionTabType.all ||
              (emoji as unknown as ReactionTabType) === activeTab,
          )
          .map(({ user }) => (
            <DialogItem key={user.id}>
              <Avatar
                userId={user.id}
                filename={user.avatar?.small || ''}
                size="xs"
                onClick={(e) => e.stopPropagation()}
              />
              <AuthorName>
                {user.first_name} {user.last_name}
              </AuthorName>
            </DialogItem>
          ))}
      </DialogContainer>
    </Dialog>
  );
}
