import { ReactElement } from 'react';

import { OptionsPoll } from 'api/types';

import { Container } from '../styled';
import { OptionBackground, OptionDetails, OptionLine, OptionTitle, PercentAmount } from './styled';

interface PollResultsProps {
  options: OptionsPoll[];
}

export function PollResults({ options }: PollResultsProps): ReactElement {
  function calcPercent(key: number) {
    const optionsSum = options
      .map((option) => option.votes)
      .reduce((acc: number, num: number) => acc + num, 0);

    let optionPercent = 0;
    if (optionsSum > 0) {
      optionPercent = Math.round((100 / optionsSum) * options[key].votes);
    }

    return optionPercent + '%';
  }

  const maxVotesAmount = Math.max(...options.map((option) => option.votes));

  return (
    <>
      {options.map((option, key) => (
        <Container key={key}>
          <OptionDetails>
            <OptionTitle>{option.text}</OptionTitle>
            <PercentAmount>{calcPercent(key)}</PercentAmount>
          </OptionDetails>

          <OptionLine>
            <OptionBackground
              percentNumber={calcPercent(key)}
              isMaxVotesAmount={maxVotesAmount === option.votes}
            ></OptionBackground>
          </OptionLine>
        </Container>
      ))}
    </>
  );
}
