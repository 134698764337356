import { ReactElement } from 'react';

import { ReactComponent as Activity } from 'assets/icons/customLink/activity.svg';
import { ReactComponent as Airplane } from 'assets/icons/customLink/airplane.svg';
import { ReactComponent as ArchiveBook } from 'assets/icons/customLink/archiveBook.svg';
import { ReactComponent as Book } from 'assets/icons/customLink/book.svg';
import { ReactComponent as Book2 } from 'assets/icons/customLink/book2.svg';
import { ReactComponent as BookSquare } from 'assets/icons/customLink/bookSquare.svg';
import { ReactComponent as Box } from 'assets/icons/customLink/box.svg';
import { ReactComponent as BrushSquare } from 'assets/icons/customLink/brushSquare.svg';
import { ReactComponent as BucketSquare } from 'assets/icons/customLink/bucketSquare.svg';
import { ReactComponent as Calculator } from 'assets/icons/customLink/calculator.svg';
import { ReactComponent as Calendar } from 'assets/icons/customLink/calendar.svg';
import { ReactComponent as CalendarCircle } from 'assets/icons/customLink/calendarCircle.svg';
import { ReactComponent as CalendarDate } from 'assets/icons/customLink/calendarDate.svg';
import { ReactComponent as Call } from 'assets/icons/customLink/call.svg';
import { ReactComponent as Chart } from 'assets/icons/customLink/chart.svg';
import { ReactComponent as Chart2 } from 'assets/icons/customLink/chart2.svg';
import { ReactComponent as ClipboardText } from 'assets/icons/customLink/clipboardText.svg';
import { ReactComponent as Clock } from 'assets/icons/customLink/clock.svg';
import { ReactComponent as Cloud } from 'assets/icons/customLink/cloud.svg';
import { ReactComponent as CloudConnection } from 'assets/icons/customLink/cloudConnection.svg';
import { ReactComponent as Code } from 'assets/icons/customLink/code.svg';
import { ReactComponent as ColorsSquare } from 'assets/icons/customLink/colorsSquare.svg';
import { ReactComponent as ColorSwatch } from 'assets/icons/customLink/colorSwatch.svg';
import { ReactComponent as Computing } from 'assets/icons/customLink/computing.svg';
import { ReactComponent as Cup } from 'assets/icons/customLink/cup.svg';
import { ReactComponent as DirectNormal } from 'assets/icons/customLink/directNormal.svg';
import { ReactComponent as Document } from 'assets/icons/customLink/document.svg';
import { ReactComponent as DocumentCode } from 'assets/icons/customLink/documentCode.svg';
import { ReactComponent as DocumentFilter } from 'assets/icons/customLink/documentFilter.svg';
import { ReactComponent as DocumentSquare } from 'assets/icons/customLink/documentSquare.svg';
import { ReactComponent as Dollar } from 'assets/icons/customLink/dollar.svg';
import { ReactComponent as EmojiHappy } from 'assets/icons/customLink/emojiHappy.svg';
import { ReactComponent as FilterSquare } from 'assets/icons/customLink/filterSquare.svg';
import { ReactComponent as Folder } from 'assets/icons/customLink/folder.svg';
import { ReactComponent as FolderCloud } from 'assets/icons/customLink/folderCloud.svg';
import { ReactComponent as Gallery } from 'assets/icons/customLink/gallery.svg';
import { ReactComponent as Graph } from 'assets/icons/customLink/graph.svg';
import { ReactComponent as Hashtag } from 'assets/icons/customLink/hashtag.svg';
import { ReactComponent as Key } from 'assets/icons/customLink/key.svg';
import { ReactComponent as Map } from 'assets/icons/customLink/map.svg';
import { ReactComponent as Math } from 'assets/icons/customLink/math.svg';
import { ReactComponent as Maximize } from 'assets/icons/customLink/maximize.svg';
import { ReactComponent as Maximize2 } from 'assets/icons/customLink/maximize2.svg';
import { ReactComponent as Menu } from 'assets/icons/customLink/menu.svg';
import { ReactComponent as MenuBoard } from 'assets/icons/customLink/menuBoard.svg';
import { ReactComponent as MessageCode } from 'assets/icons/customLink/messageCode.svg';
import { ReactComponent as MessageEdit } from 'assets/icons/customLink/messageEdit.svg';
import { ReactComponent as Messages3 } from 'assets/icons/customLink/messages3.svg';
import { ReactComponent as MessageSquare } from 'assets/icons/customLink/messageSquare.svg';
import { ReactComponent as Moon } from 'assets/icons/customLink/moon.svg';
import { ReactComponent as More2 } from 'assets/icons/customLink/more2.svg';
import { ReactComponent as MusicPlay } from 'assets/icons/customLink/musicPlay.svg';
import { ReactComponent as MusicSquare } from 'assets/icons/customLink/musicSquare.svg';
import { ReactComponent as NotificationFavorite } from 'assets/icons/customLink/notificationFavorite.svg';
import { ReactComponent as NotificationStatus } from 'assets/icons/customLink/notificationStatus.svg';
import { ReactComponent as Paperclip } from 'assets/icons/customLink/paperclip.svg';
import { ReactComponent as PathSquare } from 'assets/icons/customLink/pathSquare.svg';
import { ReactComponent as Percentage } from 'assets/icons/customLink/percentage.svg';
import { ReactComponent as Personalcard } from 'assets/icons/customLink/personalcard.svg';
import { ReactComponent as Printer } from 'assets/icons/customLink/printer.svg';
import { ReactComponent as QuoteDown } from 'assets/icons/customLink/quoteDown.svg';
import { ReactComponent as QuoteUp } from 'assets/icons/customLink/quoteUp.svg';
import { ReactComponent as ReceiptSquare } from 'assets/icons/customLink/receiptSquare.svg';
import { ReactComponent as Route } from 'assets/icons/customLink/route.svg';
import { ReactComponent as ScanBarcode } from 'assets/icons/customLink/scanBarcode.svg';
import { ReactComponent as Scanning } from 'assets/icons/customLink/scanning.svg';
import { ReactComponent as Scissors } from 'assets/icons/customLink/scissors.svg';
import { ReactComponent as SearchStatus } from 'assets/icons/customLink/searchStatus.svg';
import { ReactComponent as Setting } from 'assets/icons/customLink/setting.svg';
import { ReactComponent as Setting3 } from 'assets/icons/customLink/setting3.svg';
import { ReactComponent as Shield } from 'assets/icons/customLink/shield.svg';
import { ReactComponent as Shop } from 'assets/icons/customLink/shop.svg';
import { ReactComponent as Smallcaps } from 'assets/icons/customLink/smallcaps.svg';
import { ReactComponent as SmartHome } from 'assets/icons/customLink/smartHome.svg';
import { ReactComponent as Sms } from 'assets/icons/customLink/sms.svg';
import { ReactComponent as Square3 } from 'assets/icons/customLink/square3.svg';
import { ReactComponent as Square3d } from 'assets/icons/customLink/square3d.svg';
import { ReactComponent as StatusUp } from 'assets/icons/customLink/statusUp.svg';
import { ReactComponent as Sticker } from 'assets/icons/customLink/sticker.svg';
import { ReactComponent as StrongBox } from 'assets/icons/customLink/strongbox.svg';
import { ReactComponent as Sun } from 'assets/icons/customLink/sun.svg';
import { ReactComponent as Tag2 } from 'assets/icons/customLink/tag2.svg';
import { ReactComponent as Task } from 'assets/icons/customLink/task.svg';
import { ReactComponent as Timer } from 'assets/icons/customLink/timer.svg';
import { ReactComponent as TrashSquare } from 'assets/icons/customLink/trashSquare.svg';
import { ReactComponent as TrendDown } from 'assets/icons/customLink/trendDown.svg';
import { ReactComponent as TrendUp } from 'assets/icons/customLink/trendUp.svg';
import { ReactComponent as UserSquare } from 'assets/icons/customLink/userSquare.svg';
import { ReactComponent as VideoHorizontal } from 'assets/icons/customLink/videoHorizontal.svg';
import { ReactComponent as VideoOctagon } from 'assets/icons/customLink/videoOctagon.svg';
import { ReactComponent as Voice } from 'assets/icons/customLink/voice.svg';
import { ReactComponent as Wallet } from 'assets/icons/customLink/wallet.svg';

export const CUSTOM_LINK_ICONS: Record<string, ReactElement> = {
  menu: <Menu />,
  sticker: <Sticker />,
  computing: <Computing />,
  smartHome: <SmartHome />,
  emojiHappy: <EmojiHappy />,
  filterSquare: <FilterSquare />,
  cup: <Cup />,
  trashSquare: <TrashSquare />,
  scissors: <Scissors />,
  code: <Code />,
  wallet: <Wallet />,
  clipboardText: <ClipboardText />,
  voice: <Voice />,
  videoOctagon: <VideoOctagon />,
  hashtag: <Hashtag />,
  strongbox: <StrongBox />,
  tag2: <Tag2 />,
  documentSquare: <DocumentSquare />,
  dollar: <Dollar />,
  math: <Math />,
  maximize: <Maximize />,
  quoteUp: <QuoteUp />,
  scanning: <Scanning />,
  setting: <Setting />,
  setting3: <Setting3 />,
  more2: <More2 />,
  receiptSquare: <ReceiptSquare />,
  documentFilter: <DocumentFilter />,
  chart2: <Chart2 />,
  graph: <Graph />,
  activity: <Activity />,
  trendUp: <TrendUp />,
  trendDown: <TrendDown />,
  route: <Route />,
  shop: <Shop />,
  searchStatus: <SearchStatus />,
  box: <Box />,
  square3d: <Square3d />,
  notificationFavorite: <NotificationFavorite />,
  notificationStatus: <NotificationStatus />,
  menuBoard: <MenuBoard />,
  folder: <Folder />,
  folderCloud: <FolderCloud />,
  airplane: <Airplane />,
  videoHorizontal: <VideoHorizontal />,
  calendar: <Calendar />,
  calendarCircle: <CalendarCircle />,
  calendarDate: <CalendarDate />,
  userSquare: <UserSquare />,
  book: <Book />,
  book2: <Book2 />,
  calculator: <Calculator />,
  timer: <Timer />,
  gallery: <Gallery />,
  directNormal: <DirectNormal />,
  musicPlay: <MusicPlay />,
  documentCode: <DocumentCode />,
  musicSquare: <MusicSquare />,
  sms: <Sms />,
  messageSquare: <MessageSquare />,
  messageEdit: <MessageEdit />,
  colorSwatch: <ColorSwatch />,
  pathSquare: <PathSquare />,
  colorsSquare: <ColorsSquare />,
  square3: <Square3 />,
  maximize2: <Maximize2 />,
  percentage: <Percentage />,
  chart: <Chart />,
  paperclip: <Paperclip />,
  smallcaps: <Smallcaps />,
  document: <Document />,
  quoteDown: <QuoteDown />,
  task: <Task />,
  shield: <Shield />,
  key: <Key />,
  scanBarcode: <ScanBarcode />,
  personalcard: <Personalcard />,
  statusUp: <StatusUp />,
  bookSquare: <BookSquare />,
  archiveBook: <ArchiveBook />,
  map: <Map />,
  call: <Call />,
  moon: <Moon />,
  sun: <Sun />,
  cloud: <Cloud />,
  clock: <Clock />,
  messages3: <Messages3 />,
  brushSquare: <BrushSquare />,
  bucketSquare: <BucketSquare />,
  printer: <Printer />,
  cloudConnection: <CloudConnection />,
  messageCode: <MessageCode />,
};
