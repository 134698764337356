import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useForm, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { COMPANY_NAME_IS_USED } from 'constants/errorCodes';
import { QUERIES } from 'constants/index';
import { API_ERRORS_BY_CODES } from 'constants/toasterMessages';
import { useModal } from 'hooks';
import { ApiErrors, Company, EditCompanyFormValues } from 'types';
import { objectsDifferences } from 'utils';

import { defaultValuesAdapter, formValuesAdapter } from '../adapters';

interface UseEditCompanyFormProps {
  onClose: () => void;
  company: Company;
}
interface UseEditCompanyFormReturn {
  isLoading: boolean;
  isDirty: boolean;
  handleFormSubmit: () => void;
  control: Control<EditCompanyFormValues>;
  isOpenConfirmModal: boolean;
  handleTitleClick: () => void;
  handleCloseForm: () => void;
  handleCloseConfirmModal: () => void;
}

export function useEditCompanyForm({
  onClose,
  company,
}: UseEditCompanyFormProps): UseEditCompanyFormReturn {
  const { t } = useTranslation();

  const defaultValues = defaultValuesAdapter(company);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isDirty, isSubmitting },
  } = useForm<EditCompanyFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { mutateAsync: editCompanyMutation, isLoading } = useMutation(API_HANDLERS.COMPANY.EDIT, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERIES.COMPANY_DETAILS]);
    },
    onError: (error) => {
      const errorsItem = error as AxiosError<ApiErrors>;

      if (errorsItem.response?.data.errors) {
        errorsItem.response?.data.errors.forEach((error) => {
          const errorText = API_ERRORS_BY_CODES[error.code];

          if (error.code === COMPANY_NAME_IS_USED) {
            setError('name', { message: t('company.edit.nameIsUsedError') });
          }

          alert.error(errorText);
        });
      }
    },
  });

  const {
    isOpen: isOpenConfirmModal,
    handleOpenModal: handleOpenConfirmModal,
    handleCloseModal: handleCloseConfirmModal,
  } = useModal();

  const handleFormSubmit = handleSubmit(async (data) => {
    const editInput = objectsDifferences(
      { ...defaultValues, country_code: String(company.country_code) },
      data,
    );

    if (Object.keys(editInput).length === 0) {
      handleCloseForm();
      return;
    }

    const formValues = formValuesAdapter(editInput);
    await editCompanyMutation(formValues);
    handleCloseForm();
  });

  function handleTitleClick() {
    if (isDirty) {
      handleOpenConfirmModal();
      return;
    }
    onClose();
  }

  function handleCloseForm() {
    onClose();
    handleCloseConfirmModal();
  }

  return {
    control,
    handleFormSubmit,
    isLoading: isLoading || isSubmitting,
    isDirty,
    isOpenConfirmModal,
    handleTitleClick,
    handleCloseForm,
    handleCloseConfirmModal,
  };
}
