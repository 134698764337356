import { forwardRef, ReactNode } from 'react';

import { ContainerW, Label, LabelW, Row, Title, Value } from './styled';

interface ExtendedDetailsInfoBlockProps {
  title: string;
  data: Record<string, { label: string; value: string | ReactNode }>;
}

const DetailsContainer = forwardRef<HTMLDivElement, ExtendedDetailsInfoBlockProps>(
  ({ title, data }, ref) => {
    return (
      <ContainerW ref={ref}>
        <Title>{title}</Title>

        {Object.keys(data).map((key) => (
          <Row key={key}>
            <LabelW>
              <Label>{data[key].label}</Label>
            </LabelW>
            {key === 'comment' ? (
              <Value dangerouslySetInnerHTML={{ __html: String(data[key].value) }}></Value>
            ) : (
              <Value>{data[key].value}</Value>
            )}
          </Row>
        ))}
      </ContainerW>
    );
  },
);

DetailsContainer.displayName = 'ExtendedDetailsInfoBlock';

export { DetailsContainer as ExtendedDetailsInfoBlock };
