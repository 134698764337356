import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useUsersListQuery } from 'api';
import { Spinner, PageHeaderBoldTitle } from 'components';
import { CreateGoalForm } from 'features/career/components';
import { useCreateDraft } from 'hooks';

import { PageW } from './styled';

export function CreatGoalPage(): ReactElement {
  const { t } = useTranslation();
  const { draft, isLoading } = useCreateDraft();
  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  if (isLoading || isLoadingUsersList) {
    return <Spinner />;
  }

  return (
    <PageW>
      <PageHeaderBoldTitle title={t('career.goals.create.title')} />
      {draft && <CreateGoalForm draft={draft} usersList={usersList} />}
    </PageW>
  );
}
