import { RegisterOptions } from 'react-hook-form';

import { emailRules, getMaxLengthText, getMinLengthText, required } from 'validations';

export const phoneRules: RegisterOptions = {
  ...required,
  minLength: {
    value: 3,
    message: getMinLengthText(3),
  },
  maxLength: {
    value: 13,
    message: getMaxLengthText(13),
  },
};

export const emailFieldRules: RegisterOptions = {
  ...emailRules,
  minLength: {
    value: 5,
    message: getMinLengthText(5),
  },
  maxLength: {
    value: 254,
    message: getMaxLengthText(254),
  },
};
