import { styled } from '@mui/material';

export const Thumb = styled('div')`
  width: 160px;
  height: 120px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  &:hover {
    .remove-btn {
      display: flex;
    }
  }
  @media (max-width: 1023px) {
    .remove-btn {
      display: flex;
    }
  }
`;

export const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const VideoW = styled('div')`
  width: 100%;
  height: 100%;
`;

export const RemoveCircle = styled('div')`
  display: none;
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const PlayButtonContainer = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;
