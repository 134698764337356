import { styled, Typography } from '@mui/material';

export const ContentW = styled('div')`
  width: 100%;
  margin-bottom: 35px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 15px;
  padding: 24px 16px;
  position: relative;

  @media (max-width: 1023px) {
    padding: 20px 16px 16px;
  }
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-transform: none;
`;

export const HeaderW = styled('div')``;

export const ActionsW = styled('div')`
  position: absolute;
  right: 12px;
  top: 12px;
`;
