import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useTasksListQuery } from 'api';
import { PreviewContainer, Spinner } from 'components';
import { useWindowSize } from 'hooks';
import { Links } from 'settings';
import { InstanceType } from 'types';

import { EmptyState, TaskListItem } from '../../components';

const PREVIEW_SECTION_LIMIT = 3;

export function TasksPreview(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isMobile } = useWindowSize();

  const { isLoading, activeTasks, total } = useTasksListQuery({
    type: InstanceType.tasks,
    limit: PREVIEW_SECTION_LIMIT,
    state: '!done',
  });

  return (
    <PreviewContainer
      title={t('we.taskPreview.title')}
      handleTitleClick={total > 0 ? () => navigate(Links.we.taskManagement.index) : undefined}
      addIconLink={Links.we.createTask}
      maximizeIconLink={total > 0 ? Links.we.taskManagement.index : ''}
      headerSx={{ marginBottom: activeTasks.length === 0 ? '90px' : 0 }}
      containerSx={
        isMobile
          ? { minHeight: '387px', marginBottom: '16px' }
          : { width: '48%', minHeight: '387px' }
      }
    >
      {isLoading && <Spinner />}

      {!isLoading && activeTasks.length === 0 && <EmptyState />}

      {activeTasks.length > 0 &&
        activeTasks.map(({ title, assigned_to, task, id, comments_count }) => (
          <TaskListItem
            key={id}
            id={id}
            title={title}
            assignee={assigned_to}
            deadline={task?.deadline}
            isPreview
            comments_count={comments_count}
          />
        ))}
    </PreviewContainer>
  );
}
