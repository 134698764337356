export function getArrayChunks<T>(links: T[], limit: number) {
  let chunk = 1;
  const map: Record<string, T[]> = {};

  for (let i = 0; i < links.length; i++) {
    const array = map[chunk];
    const link = links[i];

    if (array) {
      if (array.length < limit) {
        array.push(link);
        map[chunk] = array;
      } else {
        chunk += 1;
        map[chunk] = [link];
        continue;
      }
    } else {
      map[chunk] = [link];
    }
  }

  return map;
}
