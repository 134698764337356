import { SWEDISH_DIAL_CODE } from 'constants/index';
import { EditCompanyFormValues } from 'types';

export function defaultValuesAdapter(company: EditCompanyFormValues): EditCompanyFormValues {
  return {
    name: company?.name || '',
    industry: company?.industry || '',
    vat: company?.vat || '',
    company_number: company?.company_number || '',
    address_1: company?.address_1 || '',
    address_2: company?.address_2 || '',
    city: company?.city || '',
    region: company?.region || '',
    postcode: company?.postcode || '',
    country: company?.country || '',
    email: company?.email || '',
    support_email: company?.support_email || '',
    website: company?.website || '',
    country_code: company?.country_code ? String(company?.country_code) : SWEDISH_DIAL_CODE,
    phone: company?.phone || '',
  };
}
