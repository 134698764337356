import { styled } from '@mui/material';

export const InputHint = styled('span')`
  position: absolute;
  top: 85px;
  left: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.p050};

  @media (max-width: 1023px) {
    top: 59px;
    left: 0;
    font-size: 12px;
  }
`;
