import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { RequestEquipmentRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UseRequestEquipmentMutationReturn {
  requestEquipmentMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    RequestEquipmentRequestParams,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
}

interface UseRequestEquipmentMutationProps {
  invalidateQueries?: string[];
}

export function useRequestEquipmentMutation({
  invalidateQueries,
}: UseRequestEquipmentMutationProps): UseRequestEquipmentMutationReturn {
  const {
    mutateAsync: requestEquipmentMutation,
    isLoading,
    isSuccess,
  } = useMutation(API_HANDLERS.EQUIPMENTS.REQUEST, {
    onSuccess: () => {
      invalidateQueries && queryClient.invalidateQueries(invalidateQueries);
    },
  });

  return {
    isLoading,
    isSuccess,
    requestEquipmentMutation,
  };
}
