import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES } from 'constants/index';
import { ApiErrors, CalendarEvent, CalendarEventApi, CalendarTypes } from 'types';

import { canRefetch, formatEvents, groupEvents } from '../utils';

interface UseGoogleEventsQueryProps {
  timeMin: string;
  timeMax: string;
  selectedDate: string;
  eventsProvider: CalendarTypes | null;
  setEvents: (events: CalendarEventApi[] | null) => void;
  setEventsProvider: (value: CalendarTypes | null) => void;
  setGroupedEvents: (events: Record<string, CalendarEvent[]>) => void;
}

export function useEventsQuery({
  timeMin,
  timeMax,
  selectedDate,
  setEvents,
  eventsProvider,
  setGroupedEvents,
}: UseGoogleEventsQueryProps) {
  const { isFetching } = useQuery(
    [QUERIES.CALENDAR_EVENTS, selectedDate, eventsProvider],
    () =>
      API_HANDLERS.CALENDAR.GET_EVENTS({
        start: timeMin,
        end: timeMax,
        provider: eventsProvider as CalendarTypes,
      }),
    {
      enabled: Boolean(eventsProvider),
      refetchOnWindowFocus: true,
      retry: (_count, error) => canRefetch(error as AxiosError<ApiErrors>),
      onSuccess: (data) => {
        if (data?.data?.length) {
          const formattedEvents = formatEvents(data.data);
          const groupedEventsByDay = groupEvents(formattedEvents);

          setEvents(formattedEvents);
          setGroupedEvents(groupedEventsByDay);
        }
      },
      // remove saved provider if user is not authorized
      onError: (error) => {
        if ((error as AxiosError).response?.status === 401) {
          setEvents(null);
        }

        // if ms has empty events list - set empty[]
        if ((error as AxiosError).response?.status === 404) {
          setEvents([]);
        }
      },
    },
  );

  return {
    isFetching,
  };
}
