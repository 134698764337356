import { styled } from '@mui/material';

export const Actions = styled('div')`
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;
