import { Button, styled, Typography } from '@mui/material';

export const FormContainer = styled('div')`
  width: 100%;
  max-width: 742px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 32px 24px;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
    margin-bottom: 110px;
    &.editMode {
      margin-bottom: 0px;
    }
  }
`;

export const FormFooter = styled('div')`
  display: flex;
`;

export const FormTitle = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
`;

export const TitleW = styled('div')`
  width: 65%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const SelectW = styled('div')`
  width: 30%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 16px;
  }
`;

export const NumberInputW = styled('div')`
  width: 49.3%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 16px;
  }
`;

export const SubmitBtnW = styled('div')`
  margin-left: auto;

  @media (max-width: 1023px) {
    margin-top: 32px;
  }
`;

export const RemoveButtonW = styled(Button)`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    text-transform: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.palette.primary.p080};
    position: absolute;
    bottom: 28px;
    left: 0;
    display: flex;
  }
`;
