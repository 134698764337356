import { styled } from '@mui/material';

import { theme } from 'styles';

export const PageW = styled('div')`
  padding: 0px 50px 20px 75px;
  display: flex;
  align-items: center;
  height: 100vh;
  @media (max-width: 1023px) {
    padding: 0px 16px 55px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
  }
`;

export const LeftBlock = styled('div')`
  width: fit-content;
  z-index: 2;
  margin-right: 20px;
  @media (max-width: 1023px) {
    width: 100%;
    margin-right: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Text = styled('div')`
  font-weight: 700;
  font-size: 130px;
  line-height: 105px;
  white-space: break-spaces;
  span {
    color: ${theme.palette.info.main};
    display: inline;
  }
  @media (max-width: 1023px) {
    font-weight: 700;
    font-size: 48px;
    line-height: 40px;
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
`;

export const Button = styled('div')`
  background: ${theme.palette.info.main};
  border-radius: 12px;
  color: ${theme.palette.white.main};
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  padding: 14px 56px;
  width: fit-content;
  margin-top: 40px;
  text-align: center;
  @media (max-width: 1023px) {
    width: 100%;
    margin-top: auto;
    padding: 9px 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
  }
`;

export const RightBlock = styled('div')`
  flex: 55%;
  height: 100%;
  display: flex;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Image = styled('img')`
  width: 100%;
  display: block;
  margin-top: auto;
  max-height: 865px;
  max-width: 820px;
  margin-left: auto;
  margin-top: 195px;
`;
