import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import {
  Backdrop,
  BaseTextFieldController,
  ConfirmDialog,
  Form,
  FormGroup,
  SubmitRoundedButton,
  FileUploadButtons,
  FormAttachments,
  RichEditorController,
  SelectController,
  DatePickerWithIcon,
  Avatar,
} from 'components';
import { GOAL_FIELDS_LENGTH } from 'constants/fieldsLength';
import { useWindowSize } from 'hooks';
import { textFieldStyles, textFieldMobileStyles, richEditorStyles } from 'styles';
import { Goal, User } from 'types';
import { getFullName } from 'utils';
import { required } from 'validations';

import { RefetchGoalDetails } from '../../types';
import { useEditGoalForm } from './hooks';
import { descriptionFieldRules, titleFieldRules } from './rules';
import {
  ActionsW,
  AuthorName,
  ButtonsW,
  FormAndPreviewW,
  FromContainer,
  PublishedDateW,
  RemoveButtonW,
} from './styled';
import { avatarWrapperMobileSx, avatarWrapperSx, multiSelectStyles, selectSx } from './styles';

interface EditGoalFormProps {
  isDetailsLoaded: boolean;
  data?: Goal;
  usersList: User[];
  handleRemoveClick: () => void;
  refetchGoal: RefetchGoalDetails;
}

export function EditGoalForm({
  data,
  usersList,
  isDetailsLoaded,
  handleRemoveClick,
  refetchGoal,
}: EditGoalFormProps): ReactElement {
  const { t } = useTranslation();

  const {
    control,
    attachedImages,
    attachedDocs,
    attachedVideos,
    statusOptions,
    assigneeOptions,
    isLoading,
    isDirty,
    isOpen,
    author,
    createdAt,
    isAssigneeFieldDisabled,
    progress,
    handleFormSubmit,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    handleSubmitConfirmModal,
    handleCloseConfirmModal,
    clearDeadline,
    handleAssigneeChange,
  } = useEditGoalForm({ data, usersList, refetchGoal });

  const { isMobile } = useWindowSize();

  const formGroupMarginBottom = isMobile ? 8 : 36;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;
  const isCreateGoal = true;

  return (
    <>
      {isLoading && <Backdrop progress={progress} />}

      {isDetailsLoaded && (
        <>
          <FormAndPreviewW>
            <FromContainer>
              <Form onSubmit={handleFormSubmit}>
                <FormGroup marginBottom={formGroupMarginBottom}>
                  <BaseTextFieldController
                    name="title"
                    control={control}
                    placeholder={t('career.goals.form.placeholders.title')}
                    sx={fieldStyles}
                    maxLength={GOAL_FIELDS_LENGTH.title.max}
                    rules={titleFieldRules}
                  />
                </FormGroup>
                <FormGroup marginBottom={formGroupMarginBottom}>
                  <SelectController
                    name="status"
                    control={control}
                    placeholder={t('career.goals.form.placeholders.status')}
                    options={statusOptions}
                    rules={required}
                    disabled={!data?.editable}
                    sx={selectSx}
                  />
                </FormGroup>
                <FormGroup marginBottom={formGroupMarginBottom}>
                  <SelectController
                    name="assignee"
                    control={control}
                    placeholder={t('equipments.create.form.placeholders.assignee')}
                    options={assigneeOptions}
                    multipleSelect
                    sx={multiSelectStyles(isMobile)}
                    handleChange={handleAssigneeChange}
                    type="poll"
                    disabled={isAssigneeFieldDisabled}
                  />
                </FormGroup>
                <FormGroup
                  marginBottom={formGroupMarginBottom}
                  row
                  sx={{ flexWrap: isMobile ? 'wrap' : 'nowrap', columnGap: '24px' }}
                >
                  <RichEditorController
                    name="description"
                    control={control}
                    placeholder={t('career.goals.form.placeholders.description')}
                    maxLength={GOAL_FIELDS_LENGTH.description.max}
                    rules={descriptionFieldRules}
                    sx={richEditorStyles(isMobile, isCreateGoal)}
                  />

                  {isMobile && (
                    <FormAttachments
                      attachedImages={attachedImages}
                      attachedDocs={attachedDocs}
                      attachedVideos={attachedVideos}
                      handleDeleteAttachment={handleDeleteAttachment}
                    />
                  )}
                  <ButtonsW>
                    <DatePickerWithIcon
                      control={control}
                      name="deadline"
                      label={t('career.goals.deadline')}
                      clearValue={clearDeadline}
                      sx={{ marginTop: '0px' }}
                      isCreateGoal
                    />
                    <FileUploadButtons
                      handleAddImages={handleAddImages}
                      handleAddDocuments={handleAddDocuments}
                      handleAddVideos={handleAddVideos}
                      classNames="notMarginTop"
                    />
                  </ButtonsW>
                </FormGroup>

                {!isMobile && (
                  <FormAttachments
                    attachedImages={attachedImages}
                    attachedDocs={attachedDocs}
                    attachedVideos={attachedVideos}
                    handleDeleteAttachment={handleDeleteAttachment}
                  />
                )}
                {!isMobile && (
                  <SubmitRoundedButton
                    disabled={!isDirty}
                    sx={{ marginLeft: 'auto', marginTop: '32px' }}
                  />
                )}
              </Form>
              {author && (
                <>
                  <Avatar
                    size={isMobile ? 'xs' : 's'}
                    userId={author?.id}
                    filename={author?.avatar?.small || ''}
                    wrapperSx={isMobile ? avatarWrapperMobileSx : avatarWrapperSx}
                  />
                  <AuthorName>
                    {getFullName({ firstName: author?.first_name, lastName: author?.last_name })}
                  </AuthorName>
                </>
              )}
              {createdAt && <PublishedDateW>{createdAt}</PublishedDateW>}
            </FromContainer>

            {isMobile && (
              <ActionsW>
                <RemoveButtonW onClick={handleRemoveClick} endIcon={<TrashIcon />}>
                  {t('tasks.details.removeButton')}
                </RemoveButtonW>

                <SubmitRoundedButton disabled={!isDirty} onClick={handleFormSubmit} />
              </ActionsW>
            )}
          </FormAndPreviewW>
        </>
      )}

      <ConfirmDialog
        subTitle={t('career.goals.create.modal.exit')}
        title={t('career.goals.create.modal.title')}
        open={isOpen}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
