import { styled } from '@mui/material';
import FormGroup, { FormGroupProps as MuiFormGroupProps } from '@mui/material/FormGroup';

interface FormGroupProps extends MuiFormGroupProps {
  marginBottom?: number;
}

const FormGroupW = styled(FormGroup, {
  shouldForwardProp: (propname) => propname !== 'marginBottom',
})<FormGroupProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '0px')};
`;

export { FormGroupW as FormGroup };
