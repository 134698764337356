import { ReactElement, SyntheticEvent } from 'react';

import { useAttachmentDetails } from 'api';
import { Spinner } from 'components';
import { handleFileDownload, hideDocNameMidPart } from 'utils';

import { FileName, FileW, FileIconW, DownloadIconW, Badge, ContainerW, Wrapper } from './styled';

interface DocumentAttachmentProps {
  fileName: string;
  fileUrl: string;
  documentId: string;
  postId: string;
  moreFiles?: number | null;
  hasMoreFilesClick: () => void;
}

const lengthLimit = 16;

export function DocumentAttachment({
  fileName,
  fileUrl,
  documentId,
  postId,
  moreFiles,
  hasMoreFilesClick,
}: DocumentAttachmentProps): ReactElement {
  const { isRefetching, refetch } = useAttachmentDetails({
    postId,
    attachmentId: documentId,
    fileName,
    fileUrl,
    enabled: false,
  });

  const hasMoreFiles = Boolean(moreFiles);
  const lastDotIndex = fileName.lastIndexOf('.');
  const nameWithoutExtension = fileName.slice(0, lastDotIndex);

  const canDownload = !hasMoreFiles;

  const title =
    nameWithoutExtension.length > lengthLimit
      ? hideDocNameMidPart({ docName: fileName, limit: lengthLimit })
      : fileName;

  async function handleDownloadClick() {
    const { data } = await refetch();

    if (data?.data) {
      await handleFileDownload({ blobFile: data.data, fileName });
    }
  }

  if (isRefetching) {
    return (
      <ContainerW>
        <Spinner size="2.5rem" />
      </ContainerW>
    );
  }

  function handleClick(e: SyntheticEvent) {
    e.stopPropagation();
    if (hasMoreFiles && hasMoreFilesClick) {
      hasMoreFilesClick();
    }
  }

  return (
    <FileW canDownload={canDownload} onClick={handleClick}>
      <Wrapper
        className="download-icon-wrapper"
        onClick={canDownload ? handleDownloadClick : undefined}
      >
        <DownloadIconW className="download-icon" sx={{ height: '21px', width: '21px' }} />
        <FileName>{title}</FileName>
      </Wrapper>

      <FileIconW className="file-icon" />
      <FileName className="file-name">{title}</FileName>

      {moreFiles && <Badge>+{moreFiles}</Badge>}
    </FileW>
  );
}
