import { GroupedProduct, ProductType, Subscription, SubscriptionPlan } from 'types';

interface CombineProductByTypeProps {
  products: SubscriptionPlan[];
  subscription: Subscription;
}

export function combineProductByType({ products, subscription }: CombineProductByTypeProps) {
  return products.reduce<Record<ProductType, GroupedProduct>>((products, { name, prices, id }) => {
    const priceId = Object.keys(prices[subscription.period])[0];
    const priceDesc = Object.values(prices[subscription.period])[0][0];

    const productType = name.includes('-EXTRA') ? 'extra' : 'main';

    products[productType] = {
      id,
      name,
      price: { ...priceDesc, id: priceId },
    };

    return products;
  }, {} as Record<ProductType, GroupedProduct>);
}
