import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Backdrop,
  ConfirmDialog,
  Form,
  FormGroup,
  RichEditorController,
  SubmitRoundedButton,
} from 'components';
import { useAuth, useWindowSize } from 'hooks';
import { richEditorStyles } from 'styles';
import { VisionFormValues } from 'types';

import { useVisionForm } from './hooks';
import { visionFieldRules, VISION_MAX_LENGTH } from './rules';
import { FormContainer, FormTitle } from './styled';

interface VisionFormProps {
  mode: 'create' | 'edit';
  handleSubmit: (data: VisionFormValues) => void;
  defaultValues: VisionFormValues;
  isSubmitting?: boolean;
  handleOpenModal?: () => void;
}

export function VisionForm({
  handleSubmit,
  mode,
  defaultValues,
  isSubmitting,
}: VisionFormProps): ReactElement {
  const {
    control,
    isOpen,
    isLoading,
    handleSubmitConfirmModal,
    handleFormSubmit,
    handleCloseConfirmModal,
    isDirty,
  } = useVisionForm({
    mode,
    handleSubmit,
    defaultValues,
  });

  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isManager } = useAuth();

  const isEditMode = mode === 'edit';
  const loading = isLoading || isSubmitting;
  const formGroupMarginBottom = isMobile ? 0 : 24;

  // TODO: can be moved to custom hook
  // - navigate back if not a Manager role
  useEffect(() => {
    if (!isManager) {
      navigate(-1);
    }
  }, [isManager, navigate]);

  return (
    <>
      {loading && <Backdrop />}

      <FormContainer>
        <FormTitle>{t('kpi.visionForm.subtitle')}</FormTitle>
        <Form onSubmit={handleFormSubmit}>
          <FormGroup marginBottom={formGroupMarginBottom} row>
            <RichEditorController
              name="vision"
              control={control}
              placeholder={t('task.create.form.placeholders.description')}
              maxLength={VISION_MAX_LENGTH}
              rules={visionFieldRules}
              sx={richEditorStyles(isMobile)}
            />
          </FormGroup>
          <SubmitRoundedButton
            disabled={isEditMode && !isDirty}
            sx={{ marginLeft: 'auto', marginTop: '32px' }}
          />
        </Form>
      </FormContainer>

      <ConfirmDialog
        subTitle={t('task.create.modal.exit')}
        title={t('task.create.modal.title')}
        open={isOpen}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
