import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components';
import { useCreateDraft } from 'hooks';

import { CreatePostForm, PageHeader } from '../../components';
import { PageW } from './styled';

export function CreatePostPage(): ReactElement {
  const { draft, isLoading } = useCreateDraft();
  const { t } = useTranslation();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageW>
      <PageHeader pageTitle={t('post.create.title')} />
      {draft && <CreatePostForm draft={draft} />}
    </PageW>
  );
}
