import { Box } from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthPageTitle, AuthPageWrapper } from 'features/auth';
import { LanguageSelector } from 'features/languageSelector';
import { Links } from 'settings';

import { SignUpForm } from '../../components';

export function SignUpPage(): ReactElement | null {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!state) {
      navigate(Links.auth.signIn);
    }
  }, [navigate, state]);

  if (!state) {
    return null;
  }

  return (
    <AuthPageWrapper>
      <AuthPageTitle>{t('signUp.title')}</AuthPageTitle>

      <SignUpForm />
      <Box sx={{ marginTop: '24px' }}>
        <LanguageSelector />
      </Box>
    </AuthPageWrapper>
  );
}
