import { styled } from '@mui/material';

import { ReactComponent as ArrowDown } from 'assets/icons/arrowRight.svg';

export const ListW = styled('div')`
  display: flex;
  padding-top: 12px;
  height: 111px;
`;

export const ArrowRight = styled(ArrowDown)`
  cursor: pointer;
  margin-left: 17px;
  margin-top: 40px;
`;

export const ArrowLeft = styled(ArrowDown)`
  transform: rotate(-180deg);
  cursor: pointer;
  margin-right: 17px;
  margin-top: 40px;
`;

export const PreviewW = styled('div')`
  position: relative;
  display: flex;
`;

export const ArrowW = styled('div')`
  margin-left: auto;
`;

export const Wrapper = styled('div')<{ showRightArrow: boolean; showLeftArrow: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ showRightArrow, showLeftArrow }) =>
    showRightArrow && showLeftArrow
      ? 'center'
      : !showRightArrow && showLeftArrow
      ? 'flex-start'
      : ''};
`;
