import { RegisterOptions } from 'react-hook-form';

import { getMaxLengthText, getMinLengthText, required } from 'validations';

const VISION_MIN_LENGTH = 1;
export const VISION_MAX_LENGTH = 1000;

export const visionFieldRules: RegisterOptions = {
  ...required,
  minLength: {
    value: VISION_MIN_LENGTH,
    message: getMinLengthText(VISION_MIN_LENGTH),
  },
  maxLength: {
    value: VISION_MAX_LENGTH,
    message: getMaxLengthText(VISION_MAX_LENGTH),
  },
};
