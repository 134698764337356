import { ReactElement } from 'react';

import { ContainerW, Filler } from './styled';

interface ProgressBarProps {
  value: number;
  color: string;
}

export function ProgressBar({ value, color }: ProgressBarProps): ReactElement {
  return (
    <ContainerW>
      <Filler value={value} color={`#${color}`} />
    </ContainerW>
  );
}
