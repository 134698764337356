import { ReactElement } from 'react';

import { Attachment as AttachmentType } from 'types';

import { DocumentAttachment } from '../DocumentAttachment';
import { OnlyDocumentsWrap } from './styled';

interface OnlyDocumentsLayoutProps {
  documents: AttachmentType[];
  documentId: string;
  hasMoreFilesClick: () => void;
}

export function OnlyDocumentsLayout({
  documents,
  documentId,
  hasMoreFilesClick,
}: OnlyDocumentsLayoutProps): ReactElement {
  const twoDocs = documents.slice(0, 2);

  return (
    <OnlyDocumentsWrap>
      {twoDocs.map(({ id, file, file_url }, idx) => {
        const moreFiles = documents.length > 2 ? documents.length - 2 : null;

        return (
          <DocumentAttachment
            key={id}
            documentId={id}
            fileName={file.original}
            fileUrl={file_url?.original}
            postId={documentId}
            hasMoreFilesClick={hasMoreFilesClick}
            {...(moreFiles && idx === 1 && { moreFiles })}
          />
        );
      })}
    </OnlyDocumentsWrap>
  );
}
