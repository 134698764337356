import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useDeleteGoalMutation } from 'api';
import { alert } from 'components';
import { useModal } from 'hooks';

export function useDeleteGoal() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { employeeId = '', achievementId = '', goalId = '' } = useParams();

  const {
    isOpen: isDeleteModalOpen,
    handleOpenModal: openDeleteModal,
    handleCloseModal: closeDeleteModal,
  } = useModal();

  const handleCloseDeleteModal = useCallback(() => {
    closeDeleteModal();
  }, [closeDeleteModal]);

  const { deleteGoalMutation, isLoading: isConfirmDeleteLoading } = useDeleteGoalMutation({
    invalidateQueries: ['achievement'],
    onSuccessAction: () => {
      handleCloseDeleteModal();
      alert.success(t('career.goals.remove.success'));
      navigate(-1);
    },
  });

  const handleRemoveClick = useCallback(() => {
    openDeleteModal();
  }, [openDeleteModal]);

  const handleDeleteGoal = useCallback(() => {
    deleteGoalMutation({
      userId: employeeId,
      achievementId,
      goalId,
    });
  }, [achievementId, deleteGoalMutation, employeeId, goalId]);

  return {
    isConfirmDeleteLoading,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    handleDeleteGoal,
    handleRemoveClick,
  };
}
