import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { GetReactionListResponse } from 'api/types';

interface UseGetPostReactionsQueryReturn {
  reactions: GetReactionListResponse;
  isLoading?: boolean;
}

interface UseGetPostReactionsQueryProps {
  postId: string;
}

export function useGetPostReactionsQuery({
  postId,
}: UseGetPostReactionsQueryProps): UseGetPostReactionsQueryReturn {
  const { data, isLoading } = useQuery(['reactions', postId], () =>
    API_HANDLERS.REACTIONS.GET_LIST({ postId }),
  );

  return {
    isLoading,
    reactions: data?.data || [],
  };
}
