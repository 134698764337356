import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PostListItem } from 'api';
import { DocumentCategoryColors } from 'constants/DocumentCategoryColors';
import { useWindowSize } from 'hooks';
import { CategoriesListType, DocumentCategory, DocumentsType } from 'types';

import { DocumentActions } from '../DocumentActions';
import { DocumentMobileFooter } from '../DocumentMobileFooter';
import { MAX_DESCRIPTION_LENGTH } from './constants';
import {
  MainInfo,
  Title,
  Description,
  StyledDate,
  Tag,
  ReadMore,
  TagsList,
  TagW,
  TagsCounter,
  TitleAndActionsW,
} from './styled';
import { useDocumentMainInfo } from './useDocumentMainInfo';

interface DocumentMainInfoProps {
  title: string;
  isExpandedCard: boolean;
  description: string;
  created_at: string;
  tag?: DocumentCategory | null;
  tags?: string;
  documentTags: DocumentCategory[];
  setIsExpandedCard: React.Dispatch<React.SetStateAction<boolean>>;
  attachmentsLength: number;
  date: string;
  author: PostListItem['author'];
  id: string;
  level?: DocumentsType;
  categoryMap: CategoriesListType;
}

export const DocumentMainInfo = ({
  title,
  isExpandedCard,
  description,
  tag,
  setIsExpandedCard,
  attachmentsLength,
  date,
  documentTags,
  author,
  level,
  id,
  tags,
  categoryMap,
}: DocumentMainInfoProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const {
    hasShowExpandIcon,
    isReadMore,
    isPublicDocumentsPage,
    IconElement,
    otherTagsCount,
    categoryTag,
  } = useDocumentMainInfo({
    isExpandedCard,
    description,
    documentTags,
    isMobile,
    attachmentsLength,
    categoryMap,
  });

  const fullListOfTags =
    documentTags.length > 0
      ? documentTags.map((tag) => (
          <Tag key={tag.tag} sx={{ backgroundColor: DocumentCategoryColors[tag.icon] }}>
            {tag.label}
          </Tag>
        ))
      : null;

  const firstTagOnly =
    documentTags.length > 0 ? (
      <TagW>
        <Tag sx={{ backgroundColor: DocumentCategoryColors[categoryTag?.icon], margin: 0 }}>
          {categoryTag.label}
        </Tag>
        {Boolean(otherTagsCount) && <TagsCounter>+{otherTagsCount}</TagsCounter>}
      </TagW>
    ) : null;

  const footer = isMobile ? (
    <>
      <DocumentMobileFooter
        author={author}
        date={date}
        tag={tag}
        attachmentsCounts={attachmentsLength}
      />
      {isPublicDocumentsPage && (
        <TagsList>{isExpandedCard ? fullListOfTags : firstTagOnly}</TagsList>
      )}
    </>
  ) : (
    <>
      <StyledDate>{date}</StyledDate>
      {tag && <Tag sx={{ backgroundColor: DocumentCategoryColors[tag.icon] }}>{tag.label}</Tag>}
      {isPublicDocumentsPage && (
        <TagsList>{isExpandedCard ? fullListOfTags : firstTagOnly}</TagsList>
      )}
    </>
  );
  function textFormater(text: string) {
    if (!text) {
      return text;
    }
    if ((text.match(/<br>|<\/p>/g) || []).length >= 3) {
      return text.split(/<br>|<\/p>/g, 2).join('<br>');
    }
    return text.slice(0, MAX_DESCRIPTION_LENGTH) + '...';
  }

  return (
    <MainInfo>
      <TitleAndActionsW>
        <Title>
          {title}
          {hasShowExpandIcon && (
            <IconButton onClick={() => setIsExpandedCard(!isExpandedCard)}>
              <IconElement />
            </IconButton>
          )}
        </Title>
        {isMobile && (
          <DocumentActions author={author} documentId={id} tag={tags} level={level} title={title} />
        )}
      </TitleAndActionsW>

      {description && (
        <Description isExpandedCard={isExpandedCard} isMobile={isMobile}>
          <span
            dangerouslySetInnerHTML={{
              __html: isReadMore ? textFormater(description) : description,
            }}
          />
          {isReadMore && (
            <ReadMore onClick={() => setIsExpandedCard(true)}>
              {t('document.list.readMore')}
            </ReadMore>
          )}
        </Description>
      )}

      {footer}
    </MainInfo>
  );
};
