import { RefObject, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { alert } from 'components';
import { useAuth } from 'hooks';
import { UserRole } from 'types';
import { getUserLanguage, splitStringByCommaOrSpace } from 'utils';

import { UserListItem } from '../../types';
import { filteredUserList, handleRemoveExtraItems, setIdToEmailItems } from '../utils';
import { MAX_INPUT_FIELDS } from './constants';
import { useInviteEmployeeMutation } from './useInviteEmployeeMutation';

interface UseInviteEmployeesFormProps {
  setFormHasContent: (value: boolean) => void;
}

interface UseInviteEmployeesFormReturn {
  handleSubmit: (e: SyntheticEvent) => void;
  handleRemoveItem: (userID: string) => void;
  usersList: UserListItem[];
  inputRef: RefObject<HTMLInputElement>;
  isLoading: boolean;
  handleUserItemOnChange: (userID: string, value: string, role: string) => void;
  handleUserSetRole: (value: string, userID?: string) => void;
  isManager: boolean;
}

export function useInviteEmployeesForm({
  setFormHasContent,
}: UseInviteEmployeesFormProps): UseInviteEmployeesFormReturn {
  const { isManager } = useAuth();

  const emptyUser = { id: '', email: '', role: UserRole.Employee };
  const [value, setValue] = useState('');
  const [usersList, setUsersList] = useState<UserListItem[]>([]);

  const { t } = useTranslation();

  const { isLoading, inviteEmployeeMutation } = useInviteEmployeeMutation();

  const inputRef = useRef<HTMLInputElement>(null);

  // TODO: need to refactor watching form state part
  useEffect(() => {
    usersList && usersList?.length > 0 ? setFormHasContent(true) : setFormHasContent(false);

    return () => setFormHasContent(false);
  }, [usersList, usersList?.length, setFormHasContent]);

  const handleMultipleUsers = useCallback(() => {
    const trimmedValue = value.trim();

    if (!value || trimmedValue === '') {
      return;
    }

    const textItems = splitStringByCommaOrSpace(trimmedValue);
    const emailsWithId = setIdToEmailItems(textItems);

    const strippedList = handleRemoveExtraItems([...usersList, ...emailsWithId], MAX_INPUT_FIELDS);
    const filteredList = filteredUserList(strippedList);

    setUsersList(filteredList);
    setValue('');
  }, [usersList, value]);

  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();

    if (usersList === null || usersList.length === 0) {
      alert.error(t('toasts.employee.add.error'));
      return;
    }

    const dataToSend = filteredUserList(
      usersList.filter(({ id, email }) => id !== '' && email !== ''),
    ).map(({ email, role }) => ({ email, role }));

    await inviteEmployeeMutation(
      { users: dataToSend, lang: getUserLanguage() },
      {
        onSuccess: () => {
          setUsersList([]);
          window.history.pushState({ submitted: true }, '');
        },
      },
    );
  }

  function handleRemoveItem(userID: string) {
    setUsersList((usersList) => usersList.filter(({ id }) => id !== userID));
  }

  function handleUserItemOnChange(userID: string, value: string, role: string) {
    setValue(value);

    const isUserContained = usersList.find((item) => item.id === userID);
    const newUser = { id: uuid(), email: value, role: role as UserRole };

    if (value.includes(' ') && inputRef.current) {
      inputRef.current.focus();
      return;
    }

    if (!value) {
      setUsersList((usersList) => usersList.filter(({ id }) => id !== userID));
      return;
    }

    if (!isUserContained) {
      setUsersList((usersList) => [...usersList, newUser]);
      return;
    }

    if (isUserContained) {
      setUsersList((usersList) =>
        usersList.map((item) => {
          if (userID === item.id) {
            return { id: userID, email: value, role: item.role };
          }
          return item;
        }),
      );
      return;
    }
  }

  function handleUserSetRole(value: string, userID?: string) {
    if (!userID) {
      const newUser = { id: uuid(), email: '', role: value as UserRole };
      setUsersList((usersList) => [...usersList, newUser]);
    }

    setUsersList((usersList) =>
      usersList.map((item) => {
        if (item.id === userID) {
          return { id: item.id, email: item.email, role: value as UserRole };
        }
        return item;
      }),
    );
  }

  useEffect(() => {
    if (value.split(' ').length > 1) {
      handleMultipleUsers();
    }
  }, [value, handleMultipleUsers]);

  return {
    handleSubmit,
    handleRemoveItem,
    inputRef,
    usersList: usersList.length < MAX_INPUT_FIELDS ? [...usersList, emptyUser] : usersList,
    isManager,
    isLoading,
    handleUserItemOnChange,
    handleUserSetRole,
  };
}
