import { BoxProps } from '@mui/system';
import { ReactElement } from 'react';

import { CompanySelector } from 'features/companySelector';
import { NotificationPreview } from 'features/notifications';

import { Wrapper } from './styled';

interface TopMenuProps {
  wrapperSx?: BoxProps['sx'];
}

export function TopMenu({ wrapperSx }: TopMenuProps): ReactElement {
  return (
    <Wrapper sx={wrapperSx}>
      {window?.location?.pathname?.length < 30 && (
        <>
          <CompanySelector />
          <NotificationPreview />
        </>
      )}
    </Wrapper>
  );
}
