export const FIELDS_VALUE_LENGTH = {
  firstName: {
    min: 1,
    max: 40,
  },
  lastName: {
    min: 1,
    max: 40,
  },
  phone: {
    min: 3,
    max: 13,
  },
  password: {
    min: 8,
    max: 60,
  },
  email: {
    min: 5,
    max: 254,
  },
  ssn: {
    min: 9,
    max: 15,
  },
};

export const POST_FIELDS_LENGTH = {
  title: {
    min: 1,
    max: 70,
  },
  description: {
    min: 1,
    max: 1000,
  },
};

export const NOTIFICATION_FIELDS_LENGTH = {
  title: {
    min: 1,
    max: 70,
  },
  description: {
    min: 1,
    max: 500,
  },
};

export const DEACTIVATE_USER_COMMENT_MAX_LENGTH = 500;

export const EQUIPMENT_FORM_FIELDS_LENGTH = {
  title: {
    min: 1,
    max: 70,
  },
  serial: {
    min: 0,
    max: 100,
  },
  comment: {
    min: 0,
    max: 100,
  },
  customFieldName: {
    min: 0,
    max: 70,
  },
  customFieldValue: {
    min: 0,
    max: 100,
  },
};

export const POLL_FORM_FIELDS_LENGTH = {
  title: {
    min: 1,
    max: 70,
  },
  description: {
    min: 1,
    max: 500,
  },
  option: {
    min: 1,
    max: 70,
  },
};

export const ACHIEVEMENT_FORM_FIELDS_LENGTH = {
  title: {
    min: 1,
    max: 70,
  },
  description: {
    min: 1,
    max: 500,
  },
};

export const GOAL_FIELDS_LENGTH = {
  title: {
    min: 1,
    max: 70,
  },
  description: {
    min: 1,
    max: 500,
  },
};
