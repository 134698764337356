import { ReactElement, useState } from 'react';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm-icon.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';
import { useWindowSize } from 'hooks';
import i18n from 'i18n';

import { MenuItemW, MenuW, MoreButton } from './styled';

type ClickType = React.MouseEvent<HTMLElement>;

export interface ActionsMenuProps {
  isRegister: boolean;
  isManager: boolean;
  onRemove: () => void;
  onActivate: () => void;
  onReInvite?: () => void;
}

export default function ActionsMenu({
  onRemove,
  onActivate,
  onReInvite,
  isRegister,
  isManager,
}: ActionsMenuProps): ReactElement {
  const { isMobile } = useWindowSize();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (e: ClickType) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: ClickType) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleReInvite = (e: ClickType) => {
    handleClose(e);
    onReInvite && onReInvite();
  };

  const handleActivate = (e: ClickType) => {
    handleClose(e);
    onActivate();
  };

  const handleRemove = (e: ClickType) => {
    handleClose(e);
    onRemove();
  };

  const reInviteText = i18n.t('employee.table.actions.reInvite');
  const activateText = i18n.t('employee.table.actions.activate');
  const removeText = i18n.t('employee.table.actions.remove');

  return (
    <div>
      <MoreButton onClick={handleClick} />
      <MenuW
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'right' : 'left',
        }}
      >
        <MenuItemW onClick={handleReInvite}>
          <RefreshIcon />
          {reInviteText}
        </MenuItemW>
        {!isRegister && isManager ? (
          <>
            <MenuItemW onClick={handleActivate}>
              <ConfirmIcon />
              {activateText}
            </MenuItemW>
            <MenuItemW onClick={handleRemove}>
              <TrashIcon />
              {removeText}
            </MenuItemW>
          </>
        ) : null}
      </MenuW>
    </div>
  );
}
