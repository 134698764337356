import { Button, styled, Typography } from '@mui/material';

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const ContentW = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
  position: relative;

  @media (max-width: 1023px) {
    padding: 16px 8px;
  }
`;

export const ListW = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const CompletedListW = styled('div')`
  flex-wrap: no-wrap;
`;

export const ListItemW = styled('div')`
  width: 49.3%;
  margin-top: 24px;

  &:nth-of-type(1) {
    margin-top: 0;
  }
  &:nth-of-type(2) {
    margin-top: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 16px;

    &:nth-of-type(2) {
      margin-top: 16px;
    }
  }
`;

export const HeaderW = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    margin-bottom: 16px;
  }
`;

export const AddButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
