import { styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const ContainerW = styled('div')<{ isListLength: boolean }>`
  padding: 24px 16px;
  width: 542px;
  height: 264px;
  border-radius: 15px;
  position: relative;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 24px;
  }

  @media (max-width: 520px) {
    height: ${({ isListLength }) => (isListLength ? '264px' : '182px')};
  }
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 16px;
`;

export const EmptyStateTitle = styled(Typography)`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.p040};
  white-space: break-spaces;
  margin-top: 24px;
`;

export const EmptyStateImage = styled('img')`
  margin-top: 10px;

  @media (max-width: 520px) {
    margin-top: 0;
  }
`;

export const ListContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 8px;
  column-gap: 12px;

  @media (max-width: 520px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ListItem = styled('div')`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p080};
  padding: 15px 20px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 25px 12px -20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EmptyStateContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NavLinkW = styled(NavLink)`
  color: ${({ theme }) => theme.palette.info.main};
`;
