import { generatePath, useNavigate } from 'react-router-dom';

import { useUserDetailsQuery } from 'api';
import { Links } from 'settings';

interface UseOnEmployeeRedirectProps {
  employeeId: string;
  clearId: () => void;
}

export function useOnEmployeeRedirect({ employeeId, clearId }: UseOnEmployeeRedirectProps) {
  const navigate = useNavigate();
  const { isSuccess, isError } = useUserDetailsQuery({ employeeId });

  const userLink = generatePath(Links.we.employees.employee.index, {
    employeeId,
  });

  isSuccess ? navigate(userLink) : isError && clearId();
}
