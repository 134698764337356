import { css, styled } from '@mui/material';

export const ListW = styled('div')<{ isFullView: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, minmax(32%, 1fr));
  grid-template-rows: 1fr;
  gap: 16px;

  ${({ isFullView }) =>
    isFullView &&
    css`
      display: flex;
      gap: 24px;
    `};
`;

export const MobileListW = styled('div')<{ isBackground: boolean }>`
  width: 100%;
  margin-top: 8px;
  position: relative;

  ${({ isBackground }) =>
    isBackground &&
    css`
      @media (max-width: 1023px) {
        padding-bottom: 16px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
      }
    `}
`;
