export const submitButtonSx = {
  marginLeft: 'auto',
  marginTop: '32px',
};

export const userSelectSx = {
  '&.MuiInputBase-root.Mui-disabled .MuiSelect-select.MuiSelect-select': {
    background: '#F9FAFB',
    color: '#9CA3AF',
    opacity: 0.5,
  },
};
