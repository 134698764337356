import { ReactElement, ReactNode } from 'react';

import { AchievementsTabsValues } from 'types';

interface TabPanelProps {
  path: AchievementsTabsValues;
  value: AchievementsTabsValues;
  children?: ReactNode;
}

export function TabPanel({ children, value, path }: TabPanelProps): ReactElement {
  return <div hidden={value !== path}>{value === path && <>{children}</>}</div>;
}
