import { ReactElement } from 'react';

import { Spinner } from '../Spinner';
import { BackdropW } from './styled';

export function ContainerBackdrop(): ReactElement {
  return (
    <BackdropW>
      <Spinner />
    </BackdropW>
  );
}
