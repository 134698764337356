import { CreateAchievementRequest } from 'api';
import { AchievementFormValues } from 'types';
import { removeExtraSpaces } from 'utils';

export function formValuesAdapter({
  title,
  description,
  color,
}: AchievementFormValues): CreateAchievementRequest {
  return {
    title: removeExtraSpaces(title),
    description: removeExtraSpaces(description),
    color,
  };
}
