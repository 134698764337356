import { Button, styled } from '@mui/material';

export const ContentW = styled('div')`
  width: 100%;
  max-width: 1106px;
  position: relative;

  @media (max-width: 1023px) {
    width: 95%;
    margin: 0 auto;
    padding: 0 10px;
  }
`;

export const AddButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
