import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeaderBoldTitle, PageWrapper, Spinner } from 'components';
import { useCreateDraft } from 'hooks';

import { CreatePollForm } from '../../components';
import { defaultValuesAdapter } from './adapter';
import { ContentW, FormW } from './styled';

export function CreatePollPage(): ReactElement {
  const { t } = useTranslation();
  const { draft, isLoading: isDraftLoading } = useCreateDraft();

  if (isDraftLoading) {
    return <Spinner />;
  }
  return (
    <PageWrapper>
      <PageHeaderBoldTitle title={t('poll.create.title')} />
      <ContentW>
        <FormW>
          <CreatePollForm draft={draft} defaultValues={defaultValuesAdapter} />
        </FormW>
      </ContentW>
    </PageWrapper>
  );
}
