import { Button, styled, Typography } from '@mui/material';

export const PageTitle = styled(Typography)`
  font-weight: 700;
  font-size: 70px;
  line-height: 70px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 42px;
  white-space: break-spaces;
`;

export const PageW = styled('div')`
  width: 100%;
  padding-left: 88px;
  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 16px;
  }
`;

export const PageHeaderW = styled('div')`
  max-width: 671px;
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1023px) {
    margin-bottom: 16px;
    max-width: unset;
  }
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-left: 328px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const FormW = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const CommentsCountW = styled('div')`
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.info.main};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.info.main}`};
  display: inline-block;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Count = styled('a')``;

export const CommentsW = styled('div')`
  margin-top: 51px;
  max-width: 671px;
`;
