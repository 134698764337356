import { NewPostPayload } from 'api';
import { removeExtraSpaces } from 'utils';

interface FormValuesAdapterProps {
  title: string;
  description: string;
  attachment_ids: string[];
  visibility?: string[];
}

export function formValuesAdapter({
  title,
  description,
  attachment_ids,
  visibility,
  ...otherProps
}: FormValuesAdapterProps): NewPostPayload {
  return {
    title: removeExtraSpaces(title),
    description: removeExtraSpaces(description),
    type: 'news',
    ...(!!attachment_ids.length && { attachment_ids }),
    ...(visibility && { assigned_to: visibility }),
    ...otherProps,
  };
}
