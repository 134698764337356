import { useTranslation } from 'react-i18next';

import { usePublishPostMutation, TaskFormPayload } from 'api';

export function useCreateTaskForm() {
  const { t } = useTranslation();

  const { publishPostMutation, isLoading } = usePublishPostMutation({
    successText: t('toasts.task.success.create'),
  });

  async function handleSubmit(data: TaskFormPayload, postId: string) {
    await publishPostMutation({ post: data, postId });
  }

  return {
    handleSubmit,
    isLoading,
  };
}
