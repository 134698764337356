import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NotFoundImage from 'assets/images/404.png';
import { Links } from 'settings';

import { PageW, LeftBlock, Text, Button, Image, RightBlock } from './styled';

export const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToHome = () => {
    navigate(Links.we.index);
  };
  return (
    <PageW>
      <LeftBlock>
        <Text>
          <div>
            <Trans i18nKey="notFound.text" components={[<span key={1} />]}>
              <span>Ooo</span>ps!!Page not found
            </Trans>
          </div>
        </Text>
        <Button onClick={navigateToHome}>{t('notFound.buttonText')}</Button>
      </LeftBlock>
      <RightBlock>
        <Image src={NotFoundImage} />
      </RightBlock>
    </PageW>
  );
};
