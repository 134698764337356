import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { useCurrentSubscriptionQuery } from 'api';
import { useAuth } from 'hooks';

import { EmployeeFullDetails, EmployeeSimplifiedDetails } from '../';
import { PageW } from './styled';

export function EmployeeDetailPage(): ReactElement {
  useCurrentSubscriptionQuery();

  const { employeeId = '' } = useParams<string>();

  const { isManager, user: currentUser } = useAuth();

  const isExtendedView = isManager || employeeId === currentUser?.id;

  return (
    <PageW>
      {isExtendedView ? (
        <EmployeeFullDetails employeeId={employeeId} />
      ) : (
        <EmployeeSimplifiedDetails />
      )}
    </PageW>
  );
}
