import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useCategoriesListQuery } from 'api';
import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { ReactComponent as ExpandedIcon } from 'assets/icons/expanded.svg';
import { ReactComponent as NotExpandedIcon } from 'assets/icons/notExpanded.svg';
import { BlockWrapper, Spinner } from 'components';
import { TooltipW } from 'features/actions/components/ActionOptionsList/styled';
import { SpinnerContainer } from 'features/postsList/components/PostsList/styled';
import { useAuth, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { CategoriesListType } from 'types';

import { DocumentCategoryItem } from '../DocumentCategoryItem';
import { CardsW } from './styled';

export const DocumentCategories = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { isManager } = useAuth();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const { categoryMap, isLoading }: { categoryMap: CategoriesListType; isLoading: boolean } =
    useCategoriesListQuery();
  const fourDocuments = Object.values(categoryMap).slice(0, 4);

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <BlockWrapper
      wrapperSx={{
        marginBottom: isMobile ? '16px' : '24px',
        width: '100%',
        padding: isMobile ? '16px 8px' : '16px',
      }}
      title={t('resources.documentCategories.title')}
      Actions={
        <>
          {isMobile && (
            <IconButton onClick={toggleExpand}>
              {isExpanded ? (
                <NotExpandedIcon style={{ opacity: '0.4' }} />
              ) : (
                <ExpandedIcon style={{ opacity: '0.4' }} />
              )}
            </IconButton>
          )}
          {isManager && (
            <NavLink to={Links.resources.createCategory}>
              <TooltipW title={t('resources.documentCategories.create.title')}>
                <IconButton>
                  <AddIcon style={{ opacity: '0.4' }} />
                </IconButton>
              </TooltipW>
            </NavLink>
          )}
        </>
      }
    >
      <CardsW className={isExpanded ? 'expanded' : ''}>
        {isExpanded || !isMobile ? (
          <>
            {Object.values(categoryMap).map((category) => (
              <DocumentCategoryItem
                key={category.id}
                {...category}
                isExpanded={isExpanded}
                toggleExpand={toggleExpand}
              />
            ))}
          </>
        ) : (
          <>
            {fourDocuments.map((category) => (
              <DocumentCategoryItem
                toggleExpand={toggleExpand}
                key={category.id}
                {...category}
                isExpanded={isExpanded}
              />
            ))}
          </>
        )}
      </CardsW>
    </BlockWrapper>
  );
};
