import { sv } from 'date-fns/locale';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { OverflowTip } from 'components';
import { DEFAULT_DATE_FORMAT } from 'constants/dateFormats';
import { useSessionState } from 'hooks';
import { Language, UserExtendedDetails } from 'types';
import { formattedDate } from 'utils';

import { ICONS } from './config';
import { DetailsItem, Row } from './styled';

interface ProfileCardMetaProps
  extends Pick<
    UserExtendedDetails,
    | 'countryCode'
    | 'phone'
    | 'email'
    | 'position'
    | 'city'
    | 'department'
    | 'postcode'
    | 'address1'
    | 'birthDate'
    | 'address2'
    | 'region'
    | 'country'
  > {
  privateView: boolean;
}

export function ProfileCardMeta({
  privateView,
  countryCode,
  postcode,
  phone,
  email,
  position,
  city,
  address1,
  region,
  country,
  birthDate,
  department,
  address2,
}: ProfileCardMetaProps): ReactElement {
  const phoneNumber = countryCode ? `+${countryCode} ${phone}` : phone;
  const filteredAddressValues = [address1, address2, city, region, postcode, country].filter(
    (value) => value,
  );
  const addressStringValue = filteredAddressValues.join(', ');
  const positionTip =
    position && department ? `${position} in ${department}` : `${position} ${department}`.trim();
  const { t } = useTranslation();
  const [state] = useSessionState('i18nextLng');
  const isSwedish = state === Language.swedish;
  const locales = isSwedish ? sv : undefined;

  return (
    <>
      {phoneNumber && (
        <Row privateView={privateView}>
          {ICONS.phone}
          <DetailsItem privateView={privateView} isLink>
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </DetailsItem>
        </Row>
      )}

      {email && (
        <Row privateView={privateView}>
          {ICONS.email}
          <DetailsItem
            onClick={() => window.open(`mailto:${email}`)}
            isLink
            privateView={privateView}
          >
            <OverflowTip tip={email}>{email}</OverflowTip>
          </DetailsItem>
        </Row>
      )}

      {(department || position) && (
        <Row privateView={privateView}>
          {ICONS.position}
          <DetailsItem privateView={privateView}>
            <OverflowTip tip={positionTip}>
              {position}
              {position && department && <span className="span-in">{t('profile.in')}</span>}
              {department}
            </OverflowTip>
          </DetailsItem>
        </Row>
      )}

      {privateView && addressStringValue && (
        <Row privateView={privateView}>
          {ICONS.address}
          <DetailsItem privateView={privateView}>
            <OverflowTip tip={addressStringValue}>{addressStringValue}</OverflowTip>
          </DetailsItem>
        </Row>
      )}

      {privateView && birthDate && (
        <Row privateView={privateView}>
          {ICONS.date}
          <DetailsItem privateView={privateView}>
            {formattedDate(new Date(birthDate), DEFAULT_DATE_FORMAT, locales)}
          </DetailsItem>
        </Row>
      )}
    </>
  );
}
