import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemVideoAttachment as Attachments } from 'components';
import { useWindowSize } from 'hooks';
import { Attachment } from 'types';

import { VIDEOS_COUNT } from './constants';
import { MoreVideos } from './styled';
import { oneVideoStyles, oneVideoMobileStyles } from './styles';

interface OnlyVideosLayoutProps {
  videos: Attachment[];
  onOpenGallery: () => void;
  goToPostDetails: () => void;
}

export function OnlyVideosLayout({ videos, onOpenGallery }: OnlyVideosLayoutProps): ReactElement {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const moreVideos = videos.length > VIDEOS_COUNT ? videos.length - VIDEOS_COUNT : null;

  if (videos.length === 1) {
    return (
      <Attachments
        video={videos[0]}
        wrapperSx={isMobile ? oneVideoMobileStyles : oneVideoStyles}
        onOpenGallery={onOpenGallery}
      />
    );
  }

  return (
    <>
      <Attachments
        video={videos[0]}
        wrapperSx={isMobile ? oneVideoMobileStyles : oneVideoStyles}
        onOpenGallery={onOpenGallery}
      />
      {moreVideos && (
        <MoreVideos>{t('common.moreImages', { count: moreVideos as number })}</MoreVideos>
      )}
    </>
  );
}
