import { styled, Typography } from '@mui/material';

export const PreviewSection = styled('div')`
  margin-bottom: 35px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 15px;
  padding: 24px 16px;
  position: relative;
  min-height: 242px;

  @media (max-width: 1023px) {
    padding: 16px 11px 23px;
  }
`;

export const ContentW = styled('div')`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Subtitle = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const Section = styled('div')`
  width: 49%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const VisionW = styled('div')<{ isManager: boolean }>`
  background: ${({ theme }) => theme.palette.white.main};
  color: ${({ theme }) => theme.palette.primary.p080};
  box-shadow: 0px 25px 12px -20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 181px;
  word-break: break-word;
  overflow: hidden;
  cursor: ${({ isManager }) => (isManager ? 'pointer' : 'default')};

  & p {
    margin: 12px 0;

    &:has(code) {
      margin: 4px 0;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  & h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    font-size: inherit;
  }

  & a {
    color: ${({ theme }) => theme.palette.info.main};
  }

  @media (max-width: 1023px) {
    height: auto;
  }
`;
