import { styled } from '@mui/material';

export const HeaderW = styled('div')`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 36px;
  @media (max-width: 1023px) {
    align-items: flex-start;
    margin-bottom: 16px;
  }
`;
