import { ListItem } from '@mui/material';
import { forwardRef, ReactElement, ReactNode, useMemo } from 'react';
import { NavLink as RouterLink, NavLinkProps } from 'react-router-dom';

import { IconW, LabelW, listItemStyles } from './styled';

interface LinkProps extends NavLinkProps {
  to: string;
  icon: ReactNode;
  disabled?: boolean;
  label: string;
}

export function NavLink({ to, icon, disabled, label }: LinkProps): ReactElement {
  const RenderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} />;
      }),
    [to],
  );

  return (
    <ListItem
      sx={{ ...listItemStyles, opacity: '0.4' }}
      button
      component={RenderLink}
      disabled={disabled}
    >
      <IconW>{icon}</IconW>
      <LabelW>{label}</LabelW>
    </ListItem>
  );
}
