import { CustomNotification } from 'types';
import { removeExtraSpaces } from 'utils';

type FormValuesAdapterProps = CustomNotification;

export function formValuesAdapter({
  title,
  description,
  ...otherProps
}: FormValuesAdapterProps): CustomNotification {
  return {
    title: removeExtraSpaces(title),
    description: removeExtraSpaces(description),
    ...otherProps,
  };
}
