import { intervalToDuration } from 'date-fns';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ConfirmDialog } from 'components';
import { useSubscription, useWindowSize } from 'hooks';
import { Product, SubscriptionsPeriod } from 'types';
import { formattedDate } from 'utils';

import { CancelPlanDialog } from '../CancelPlanDialog';
import { StartSubscriptionDialog } from '../StartSubscriptionDialog';
import { UpgradePlanDialog } from '../UpgradePlanDialog';
import {
  ActiveProductLabel,
  BlockedProductNote,
  ButtonW,
  CanceledPlanNote,
  CanceledSubscriptionNoteW,
  ContentW,
  Footer,
  ImageW,
  InfoIconW,
  MainContentW,
  MainPrice,
  NoteW,
  PriceBeforeDiscount,
  ProductName,
  Row,
  MobileDuration,
  CancelNotAllowed,
} from './styled';
import { upgradeConfirmModalSx, upgradeConfirmModalTitleSx } from './styles';
import { useSubscriptionDetails } from './useSubscriptionDetails';
import { checkIsCancelable, getSubmitButtonConfig } from './utils';

interface SubscriptionProps {
  product: Product;
  extraProduct: Product;
  image: string;
  period: SubscriptionsPeriod;
}

export function Subscription({
  product,
  extraProduct,
  image,
  period,
}: SubscriptionProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const {
    isOpen,
    handleCloseModal,
    handleOpenModal,
    isLoadingStartSubscription,
    handleStartSubscription,
    isOpenUpgrade,
    handleCloseUpgradeModal,
    handleOpenUpgradeModal,
    subscriptionDetails,
    handleUpdateSubscription,
    isOpenInfo,
    handleCloseInfoModal,
    handleOpenInfoModal,
    isOpenUpgradeConfirm,
    handleCloseModalUpgradeConfirm,
    isLoadingUpdateSubscription,
    isOpenCancel,
    handleCloseCancelModal,
    handleOpenCancelModal,
  } = useSubscriptionDetails({
    product,
    extraProduct,
    period,
  });

  const {
    subscription: currentSubscription,
    isTrial,
    isCanceled: isCanceledCurrentSubscription,
  } = useSubscription();

  const { isActive, disabled, isCanceled: isCanceledProduct, isBlocked } = product;

  const { variant, text, onClick } = getSubmitButtonConfig({
    isActive,
    disabled,
    cancel: handleOpenCancelModal,
    subscribe: handleOpenModal,
    upgrade: handleOpenUpgradeModal,
    isTrial,
    isBlocked,
  });

  const { name, duration, extraPrice, teamSize, priceBefore, mainPrice } = subscriptionDetails;
  const expiresAt = currentSubscription?.expires_at
    ? formattedDate(new Date(currentSubscription.expires_at))
    : '';

  // TODO: update function to get days count
  const interval = intervalToDuration({
    start: new Date(),
    end: new Date(expiresAt),
  });

  const canBeCanceled = checkIsCancelable(interval);
  const activeAndCantBeCanceled = isActive && !canBeCanceled;

  const mobileDuration = duration.split(' ').slice(0, 2).join(' ');
  return (
    <>
      <MainContentW sx={{ opacity: disabled ? 'unset' : 'unset' }}>
        {(isActive || isCanceledProduct) && (
          <ActiveProductLabel>{t('subscriptions.usingNow')}</ActiveProductLabel>
        )}
        <ContentW>
          <ProductName>
            <span>{name}</span>
            <InfoIconW onClick={handleOpenInfoModal} />
            {isMobile && <MobileDuration>{mobileDuration}</MobileDuration>}
          </ProductName>

          <ImageW>
            <img src={image} alt="subscription" />
          </ImageW>

          <Row sx={{ paddingTop: 0 }}>{teamSize}</Row>
          {!isMobile && <Row>{duration}</Row>}

          <Row sx={period === SubscriptionsPeriod.month ? { border: 'none' } : {}}>
            {extraPrice}
          </Row>
          {period === SubscriptionsPeriod.year && (
            <PriceBeforeDiscount>{priceBefore}</PriceBeforeDiscount>
          )}
          <MainPrice>{mainPrice}</MainPrice>

          {isCanceledCurrentSubscription && (
            <CanceledSubscriptionNoteW>
              {!isCanceledProduct && isActive && (
                <NoteW>
                  <InfoIcon />
                  <BlockedProductNote>
                    {t('subscriptions.blockedProduct', { date: expiresAt })}
                  </BlockedProductNote>
                </NoteW>
              )}
            </CanceledSubscriptionNoteW>
          )}
        </ContentW>

        <Footer>
          {isCanceledProduct && (
            <CanceledPlanNote>
              {t('subscriptions.canceled.note', { date: expiresAt })}
            </CanceledPlanNote>
          )}

          {activeAndCantBeCanceled && (
            <CancelNotAllowed>
              {t('subscriptions.cancelNotAllowed', { date: expiresAt })}
            </CancelNotAllowed>
          )}

          {!isCanceledProduct && !activeAndCantBeCanceled && (
            <ButtonW
              variant={variant}
              color="info"
              onClick={onClick}
              disabled={disabled || isBlocked}
              isBlocked={Boolean(isBlocked)}
            >
              {t(text)}
            </ButtonW>
          )}
        </Footer>
      </MainContentW>

      {isOpen && (
        <StartSubscriptionDialog
          open={isOpen}
          onSubmit={handleStartSubscription}
          onClose={handleCloseModal}
          isLoading={isLoadingStartSubscription}
          scroll={isMobile ? 'body' : 'paper'}
        />
      )}

      {isOpenInfo && (
        <StartSubscriptionDialog
          open={isOpenInfo}
          onSubmit={() => {}}
          onClose={handleCloseInfoModal}
          withConfirmBtn={false}
          scroll={isMobile ? 'body' : 'paper'}
        />
      )}

      {currentSubscription && isOpenUpgrade && (
        <UpgradePlanDialog
          open={isOpenUpgrade}
          onSubmit={handleUpdateSubscription}
          onClose={handleCloseUpgradeModal}
          newPlan={subscriptionDetails}
          currentSubscription={currentSubscription}
          isLoading={isLoadingUpdateSubscription}
        />
      )}

      {isOpenUpgradeConfirm && (
        <ConfirmDialog
          title={t('subscriptions.modals.planUpgraded.title')}
          onSubmit={handleCloseModalUpgradeConfirm}
          open={isOpenUpgradeConfirm}
          dialogSx={upgradeConfirmModalSx}
          titleSx={upgradeConfirmModalTitleSx}
        />
      )}

      {currentSubscription && isOpenCancel && (
        <CancelPlanDialog
          open={isOpenCancel}
          onClose={handleCloseCancelModal}
          currentSubscription={currentSubscription}
        />
      )}
    </>
  );
}
