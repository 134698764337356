import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeaderBoldTitle, PageWrapper } from 'components';

import { CreateVisionForm } from '../../components';

export function CreateVisionPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PageHeaderBoldTitle title={t('kpi.visionForm.createTitle')} />
      <CreateVisionForm />
    </PageWrapper>
  );
}
