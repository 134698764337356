import { styled } from '@mui/material';

export const ListW = styled('div')`
  margin-top: 30px;
  position: relative;
  @media (max-width: 1023px) {
    margin-top: 24px;
  }
`;

export const SpinnerContainer = styled('div')`
  height: calc(100vh - 178px);
  position: relative;
`;

export const ListTitle = styled('h3')`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-top: 0px;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    line-height: 28px;
    margin-bottom: 16px;
  }
`;
