import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useDeleteAchievementMutation } from 'api';
import { alert } from 'components';
import { useModal } from 'hooks';

export function useDeleteAchievement() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { employeeId = '', achievementId = '' } = useParams();

  const {
    isOpen: isDeleteModalOpen,
    handleOpenModal: openDeleteModal,
    handleCloseModal: closeDeleteModal,
  } = useModal();

  const handleCloseDeleteModal = useCallback(() => {
    closeDeleteModal();
  }, [closeDeleteModal]);

  const { deleteAchievementMutation, isLoading: isConfirmDeleteLoading } =
    useDeleteAchievementMutation({
      invalidateQueries: ['achievement'],
      onSuccessAction: () => {
        handleCloseDeleteModal();
        alert.success(t('career.achievements.remove.success'));
        navigate(-1);
      },
    });

  const handleRemoveClick = useCallback(() => {
    openDeleteModal();
  }, [openDeleteModal]);

  const handleDeleteAchievement = useCallback(() => {
    deleteAchievementMutation({
      userId: employeeId,
      achievementId,
    });
  }, [achievementId, deleteAchievementMutation, employeeId]);

  return {
    isConfirmDeleteLoading,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    handleDeleteAchievement,
    handleRemoveClick,
  };
}
