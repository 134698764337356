import { IconButton } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { BoxProps } from '@mui/system';
import { useState } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeSlashIcon } from 'assets/icons/eyeSlash.svg';
import { useWindowSize } from 'hooks';
import { WRONG_PASSWORD_FORMAT, WRONG_PASSWORD_FORMAT_FULL } from 'validations';

import { TextField } from '../TextField';
import { InputAdornmentW, InputW } from './styled';
import { adornmentSx } from './styles';
import { ValidationHints } from './ValidationHints';

type PasswordControllerProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> & {
    maxLength?: number;
    hintsSx?: BoxProps['sx'];
    withAdornment?: boolean;
  };

export function PasswordController<T extends FieldValues>({
  control,
  name,
  rules,
  maxLength = 60,
  className,
  hintsSx,
  withAdornment = false,
  ...otherProps
}: PasswordControllerProps<T>) {
  const [showPassword, setShowPassword] = useState(false);
  const { isMobile } = useWindowSize();

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  function toggleType() {
    setShowPassword((show) => !show);
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <InputW className={className}>
      {value && !isMobile && <ValidationHints value={String(value)} />}
      <TextField
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        inputRef={ref}
        error={Boolean(error)}
        helperText={error && error.message}
        inputProps={{ maxLength }}
        tooltipText={
          error && error.message === WRONG_PASSWORD_FORMAT ? WRONG_PASSWORD_FORMAT_FULL : undefined
        }
        sx={withAdornment ? adornmentSx : {}}
        {...otherProps}
      />
      {withAdornment && (
        <InputAdornmentW position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={toggleType}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
          </IconButton>
        </InputAdornmentW>
      )}
      {value && isMobile && <ValidationHints hintsSx={hintsSx} value={String(value)} />}
    </InputW>
  );
}
