import { ButtonProps, IconButton } from '@mui/material';
import { ReactElement } from 'react';

import { ReactComponent as MaximizeIcon } from 'assets/icons/containerActions/maximize.svg';

interface MaximizeButtonProps {
  onClick?: ButtonProps['onClick'];
  disabled?: ButtonProps['disabled'];
}

export function MaximizeButton({ onClick, disabled }: MaximizeButtonProps): ReactElement {
  return (
    <IconButton disabled={disabled} onClick={onClick}>
      <MaximizeIcon style={{ opacity: '0.4' }} />
    </IconButton>
  );
}
