import { styled } from '@mui/material';

export const ListW = styled('div')`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1023px) {
    flex-direction: column;
    row-gap: 8px;
  }
`;

export const ContainerW = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 1106px;
  width: 100%;
  @media (max-width: 1023px) {
    padding: 8px 8px 20px;
  }
`;
