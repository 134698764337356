import { styled, Typography } from '@mui/material';

import { ReactComponent as EditIcon } from 'assets/icons/containerActions/edit.svg';

export const CardW = styled('div')`
  width: 254px;
  background: #ffffff;
  box-shadow: 0px 15px 8px -15px rgba(0, 155, 242, 0.3);
  border-radius: 10px;
  padding: 8px;
  position: relative;

  @media (max-width: 1440px) {
    width: auto;
  }
`;

export const TitleW = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-left: 16px;
`;

export const SubtitleW = styled(Typography)`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  width: 63px;
  max-width: 63px;
  white-space: nowrap;
  margin-left: 16px;
  color: ${({ theme }) => theme.palette.secondary.light};

  @media (max-width: 1023px) {
    width: 48px;
  }
`;

export const RowW = styled('div')`
  display: flex;
  align-items: center;
  height: 64px;
`;

export const EditIconW = styled(EditIcon)`
  opacity: 0.4;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const IconW = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
`;
