import { ReactElement } from 'react';
import { Toaster, toast, ToastOptions, DefaultToastOptions } from 'react-hot-toast';

import { AlertProps, Alert } from '../Alert';
import { AlertsContainerW, MessageW } from './styled';

const options: DefaultToastOptions = {
  className: 'alert',
  style: {
    maxWidth: '800px',
    padding: 0,
    color: 'inherit',
    background: 'transparent',
    borderRadius: 0,
    boxShadow: 'none',
    zIndex: 1500,
  },
  success: {
    className: 'alert-success',
  },
  error: {
    className: 'alert-error',
  },
  position: 'top-center',
  duration: 5000,
};

function renderAlert(message: string, toastID: string, alertProps?: Pick<AlertProps, 'variant'>) {
  function handleClose() {
    toast.remove(toastID);
  }

  return (
    <Alert {...alertProps} close={handleClose}>
      <MessageW>{message}</MessageW>
    </Alert>
  );
}

export const alert = {
  info: (message: string, opts?: ToastOptions): string =>
    toast((t) => renderAlert(message, t.id), { ...opts, id: message }),
  success: (message: string, opts?: ToastOptions): string =>
    toast.success((t) => renderAlert(message, t.id, { variant: 'success' }), {
      ...opts,
      id: message,
    }),
  error: (message: string, opts?: ToastOptions): string =>
    toast.error((t) => renderAlert(message, t.id, { variant: 'danger' }), {
      ...opts,
      id: message,
    }),
};

export function AlertsContainer(): ReactElement {
  return (
    <AlertsContainerW>
      <Toaster toastOptions={options} />
    </AlertsContainerW>
  );
}
