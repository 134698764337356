import { TextFieldProps } from '@mui/material/TextField';
import { ReactElement } from 'react';

import { StyledTextField } from './styled';

export function BaseTextField({
  name,
  size,
  variant = 'standard',
  error,
  value,
  label,
  onBlur,
  ...otherProps
}: TextFieldProps): ReactElement {
  return (
    <StyledTextField
      fullWidth
      size={size}
      variant={variant}
      error={error}
      value={value}
      label={value && !error ? label : ''}
      autoComplete="off"
      onBlur={onBlur}
      {...otherProps}
    />
  );
}
