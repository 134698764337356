import { RegisterOptions } from 'react-hook-form';

import { FIELDS_VALUE_LENGTH } from 'constants/';
import { getMinLengthText, getMaxLengthText, emailRules, required, REQUIRED } from 'validations';

export const firstNameRules: RegisterOptions = {
  ...required,
  minLength: {
    value: FIELDS_VALUE_LENGTH.firstName.min,
    message: getMinLengthText(FIELDS_VALUE_LENGTH.firstName.max),
  },
  maxLength: {
    value: FIELDS_VALUE_LENGTH.firstName.max,
    message: getMaxLengthText(FIELDS_VALUE_LENGTH.firstName.max),
  },
  validate: (value) => {
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      return REQUIRED;
    }
  },
};

export const lastNameRules: RegisterOptions = {
  ...required,
  minLength: {
    value: FIELDS_VALUE_LENGTH.lastName.min,
    message: getMinLengthText(FIELDS_VALUE_LENGTH.lastName.min),
  },
  maxLength: {
    value: FIELDS_VALUE_LENGTH.lastName.max,
    message: getMaxLengthText(FIELDS_VALUE_LENGTH.lastName.max),
  },
  validate: (value) => {
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      return REQUIRED;
    }
  },
};

export const emailFieldRules: RegisterOptions = {
  ...required,
  ...emailRules,
  minLength: {
    value: 5,
    message: getMinLengthText(5),
  },
  maxLength: {
    value: 254,
    message: getMaxLengthText(254),
  },
};
