export const titleMobileSx = {
  position: 'relative',
  left: 0,

  'div:first-of-type': {
    width: '70%',
  },

  '.title': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const titleDesktopSx = {
  position: 'relative',
  left: '-8px',
};
