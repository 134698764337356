import { styled, Button } from '@mui/material';

export const ContentW = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1100px;
  margin-top: 48px;

  @media (max-width: 1023px) {
    margin-top: 16px;
    flex-direction: column;
  }
`;

export const FormW = styled('div')`
  width: 672px;
  position: relative;
  border-radius: 15px;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
  }
`;

export const PageHeaderW = styled('div')`
  max-width: 671px;
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1023px) {
    margin-bottom: 16px;
    max-width: unset;
    gap: 16px;
    padding-right: 8px;
  }
`;

export const CommentsCountW = styled('div')`
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.info.main};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.info.main}`};
  display: inline-block;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Count = styled('a')`
  white-space: nowrap;
`;

export const CommentsW = styled('div')`
  position: relative;
`;

export const ActionsW = styled('div')`
  display: grid;
  gap: 16px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
