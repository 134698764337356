import { styled, Box } from '@mui/material';

export const AlertsContainerW = styled(Box)`
  .alert-error,
  .alert-success {
    & > div {
      :first-of-type {
        display: none;
      }
    }
  }
`;

export const MessageW = styled('div')(({ theme }) => ({
  color: theme.palette.primary.p080,
  overflowWrap: 'break-word',
}));
