import {
  Dialog as DialogMUI,
  DialogActions as DialogActionsMUI,
  DialogContent as DialogContentMUI,
  styled,
  Button,
} from '@mui/material';

export const DialogActions = styled(DialogActionsMUI)`
  padding: 0;

  @media (max-width: 1023px) {
    margin-top: 28px;

    & button {
      height: 56px;
      width: 56px;
    }
  }
`;

export const DialogContent = styled(DialogContentMUI)`
  padding: 0;
`;

export const Dialog = styled(DialogMUI)(({ theme }) => ({
  '.MuiDialog-paper': {
    maxWidth: '746px',
    padding: '32px',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(20px)',
    borderRadius: '15px',
    fontSize: '24px',
  },

  '.MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
  },
  '& a': {
    color: '#009BF2',
  },
  [theme.breakpoints.down('desktop')]: {
    '.MuiDialog-paper': {
      padding: '24px 16px',
      marginBottom: 'auto',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

export const DialogHeader = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 18px;
  margin-bottom: 32px;

  & span {
    font-size: 32px;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  & svg {
    align-self: flex-start;
    height: 66px;
  }

  @media (max-width: 1023px) {
    gap: 9px;

    & svg {
      height: 20px;
      transform: translateY(3px);
    }

    & span {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
    }
  }
`;

export const ContinueBtn = styled(Button)`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  border-radius: 12px;
  width: 131px;
  height: 52px;
  text-transform: none;
  box-shadow: none;
  @media (max-width: 1023px) {
  }
`;
