import { Button, styled } from '@mui/material';

export const ContentW = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1100px;
  margin-top: 48px;

  @media (max-width: 1023px) {
    margin-top: 16px;
    flex-direction: column;
  }
`;

export const FormW = styled('div')`
  width: 672px;
  position: relative;
  border-radius: 15px;
  padding: 32px 24px 40px;

  &:before {
    content: '';
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 15px;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
  }
`;

export const ActionsW = styled('div')`
  display: grid;
  gap: 16px;
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};

  @media (max-width: 1023px) {
    display: none;
  }
`;
