import { useMutation, UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { AddAttachmentRequestParams, AddAttachmentResponse } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UseUploadAttachmentReturn {
  isLoading: boolean;
  uploadAttachmentMutation: UseMutateAsyncFunction<
    AxiosResponse<AddAttachmentResponse, unknown>,
    unknown,
    AddAttachmentRequestParams,
    unknown
  >;
}

export function useUploadAttachment(): UseUploadAttachmentReturn {
  const { mutateAsync: uploadAttachmentMutation, isLoading } = useMutation(
    API_HANDLERS.ATTACHMENTS.UPLOAD,
  );

  return { uploadAttachmentMutation, isLoading };
}
