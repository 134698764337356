import { styled } from '@mui/material';

export const ActionsW = styled('div')`
  position: relative;
  top: -8px;
  width: min-content;
`;

export const IconW = styled('div')`
  position: relative;
  height: 20px;
`;

export const CountW = styled('span')`
  position: absolute;
  font-size: 11px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
`;
