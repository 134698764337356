import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CenteredW, TitleW, Wrapper } from './styled';

export function PreviewEmptyState({ isPreview = true }: { isPreview: boolean }): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      {isPreview && (
        <Wrapper>
          <TitleW>{t('notifications.title')}</TitleW>
          <CenteredW isPreview={isPreview}>{t('notifications.emptyState')}</CenteredW>
        </Wrapper>
      )}
      <CenteredW isPreview={isPreview}>{t('notifications.emptyState')}</CenteredW>
    </>
  );
}
