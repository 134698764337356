import { addDays, formatRFC3339 } from 'date-fns';

import { CALENDAR_DATE_KEY_FORMAT, EVERYTHING_AFTER_PLUS } from 'constants/index';
import { formattedDate } from 'utils';

interface UseCalendarDatesReturn {
  datesRange: string[];
  timeMin: string;
  timeMax: string;
}

export function useCalendarDates(): UseCalendarDatesReturn {
  const today = new Date();
  const lastDay = addDays(today, 4);

  const timeMin = formatRFC3339(today.setHours(0, 0, 0, 0)).replace(EVERYTHING_AFTER_PLUS, 'Z');
  const timeMax = formatRFC3339(lastDay.setHours(23, 59, 59, 59)).replace(
    EVERYTHING_AFTER_PLUS,
    'Z',
  );

  const date = new Date(today.getTime());
  const datesRange = [];

  while (date <= lastDay) {
    datesRange.push(formattedDate(new Date(date), CALENDAR_DATE_KEY_FORMAT));
    date.setDate(date.getDate() + 1);
  }

  return { datesRange, timeMin, timeMax };
}
