import { styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const DocumentW = styled('div')`
  width: 280px;
  height: 260px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;

  background: linear-gradient(338.17deg, rgba(0, 0, 0, 0.15) -53.57%, rgba(0, 0, 0, 0) 85.58%),
    linear-gradient(176.57deg, #ffffff 0%, rgba(255, 255, 255, 0) 32.85%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const TitleW = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.primary.p080};
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NavLinkW = styled(NavLink)`
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const FooterW = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const ImageW = styled('div')`
  width: 248px;
  height: 56px;
  position: relative;
  margin-bottom: 8px;
`;

export const MoreImages = styled('div')`
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.white.main};
`;

export const FooterBlockLeft = styled('div')``;

export const DateW = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  color: ${({ theme }) => theme.palette.primary.p040};
`;

export const ContainerW = styled('div')`
  margin-top: 20px;
  height: 128px;
`;

export const TagW = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const TagsCounter = styled(Typography)`
  background: #dfe1e6;
  color: ${({ theme }) => theme.palette.primary.p080};
  border-radius: 10px;
  margin-left: 6px;
  padding: 3px 6px;
  font-weight: 400;
  font-size: 12px;
`;
