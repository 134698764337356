import { styled, Typography } from '@mui/material';

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const ListW = styled('div')`
  width: 336px;
  position: relative;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;
