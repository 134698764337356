import { ReactElement } from 'react';

import { GoalStatus } from 'types';

import { StatusDisplayValue } from '../../constants';
import { LabelW } from './styled';
import { styles } from './styles';

interface GoalStatusLabelProps {
  status: GoalStatus;
}

export function GoalStatusLabel({ status }: GoalStatusLabelProps): ReactElement {
  return <LabelW sx={{ ...styles[status] }}>{StatusDisplayValue[status]}</LabelW>;
}
