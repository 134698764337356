import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { Achievement } from 'types';

interface UseAchievementDetailsQueryReturn {
  isLoading: boolean;
  isSuccess: boolean;
  data?: Achievement;
}

interface UseAchievementDetailsQueryProps {
  userId: string;
  achievementId: string;
}

export function useAchievementDetailsQuery({
  userId,
  achievementId,
}: UseAchievementDetailsQueryProps): UseAchievementDetailsQueryReturn {
  const { isLoading, isSuccess, data } = useQuery(
    ['achievement-details', achievementId],
    () => API_HANDLERS.ACHIEVEMENTS.ACHIEVEMENT_DETAILS({ userId, achievementId }),
    {
      enabled: !!userId && !!achievementId,
    },
  );

  return {
    isLoading,
    isSuccess,
    data: data?.data,
  };
}
