import { BoxProps } from '@mui/material';
import { ReactNode } from 'react';

import { ContainerW, Title, HeaderW, ActionsW } from './styled';

interface BlockWrapperPropsType {
  title: string;
  Actions?: ReactNode;
  children: ReactNode;
  wrapperSx?: BoxProps['sx'];
  onClickTitle?: () => void;
}

export const BlockWrapper = ({
  title,
  Actions,
  children,
  wrapperSx,
  onClickTitle,
}: BlockWrapperPropsType) => {
  return (
    <ContainerW sx={wrapperSx}>
      <HeaderW>
        <Title sx={{ cursor: onClickTitle ? 'pointer' : '' }} onClick={onClickTitle || undefined}>
          {title}
        </Title>
        {Actions && <ActionsW>{Actions}</ActionsW>}
      </HeaderW>
      {children}
    </ContainerW>
  );
};
