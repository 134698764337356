import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const HeaderW = styled('div')`
  display: flex;
  width: 100%;
  align-items: flex-start;
  @media (max-width: 1023px) {
    align-items: center;
  }
`;

export const SignInLink = styled(NavLink)`
  margin-left: auto;
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
  text-decoration: none;
  line-height: 24px;

  @media (max-width: 1023px) {
    font-size: 18px;
  }
`;
