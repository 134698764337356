import { styled } from '@mui/material';

export const Placeholder = styled('span')`
  font-size: 24px;
  color: ${({ theme }) => theme.palette.primary.p040};
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const MultipleSelectWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  margin: 30px 40px 30px 10px;
  width: 100%;
  @media (max-width: 1023px) {
    margin: 0px;
  }
`;
