import { BoxProps } from '@mui/material';
import { ReactElement } from 'react';

import { useTasksListQuery } from 'api';
import { Spinner } from 'components';
import { InstanceType, TaskStatus } from 'types';

import { EmptyState } from '../EmptyState';
import { TaskListItem } from '../TaskListItem';
import { ListW, Title } from './styled';

interface TasksListProps {
  title: string;
  status: TaskStatus;
  limit: number;
  assigned?: string;
  author?: string;
  isProfile?: boolean;
  listWrapperSx?: BoxProps['sx'];
  newTaskAssigneeId?: string;
}

export function TasksList({
  title,
  status,
  limit,
  assigned,
  author,
  listWrapperSx,
  isProfile = false,
  newTaskAssigneeId,
}: TasksListProps): ReactElement {
  const { isLoading, tasks, total, lastItemRef, isFetchingNextPage } = useTasksListQuery({
    type: InstanceType.tasks,
    state: status,
    limit,
    ...(assigned && { assigned }),
    ...(author && { author }),
  });

  return (
    <ListW sx={listWrapperSx}>
      <Title>{title}</Title>

      {isLoading && <Spinner />}

      {!isLoading && total === 0 && <EmptyState status={status} assigneeId={newTaskAssigneeId} />}

      {tasks.length > 0 &&
        tasks.map(({ title, assigned_to, task, description, id, comments_count }, idx) => (
          <TaskListItem
            key={id}
            id={id}
            title={title}
            assignee={assigned_to}
            deadline={task.deadline}
            description={description}
            isProfile={isProfile}
            lastItemRef={idx === tasks.length - 1 ? lastItemRef : undefined}
            comments_count={comments_count}
          />
        ))}

      {isFetchingNextPage && <Spinner wrapperSx={{ bottom: '0', top: 'unset' }} />}
    </ListW>
  );
}
