import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EmptyW, StyledText, UploadButton } from './styled';

type EmptySpateProps = {
  linkToCreateDocument: string;
  selectPrivate?: boolean;
};

export const EmptyState = ({ linkToCreateDocument, selectPrivate }: EmptySpateProps) => {
  const navigate = useNavigate();

  return (
    <EmptyW>
      <StyledText>
        <Trans i18nKey="document.list.emptyState">
          No documents yet. Be the first one to
          <UploadButton
            onClick={() => navigate(linkToCreateDocument, { state: { selectPrivate } })}
          >
            upload
          </UploadButton>
          !
        </Trans>
      </StyledText>
    </EmptyW>
  );
};
