import { Popover } from '@mui/material';
import { ReactElement, useState } from 'react';

import { useEditUserMutation } from 'api';
import { useAuth, useSessionState, useWindowSize } from 'hooks';
import i18n from 'i18n';
import { Language } from 'types';

import { ENGLISH, SWEDISH } from './constants';
import { OptionBtn, ToggleBtn, Triangle } from './styled';

export function LanguageSelector({
  spinnerSwitcher,
}: {
  spinnerSwitcher?: () => void;
}): ReactElement {
  const [language, setLanguage] = useSessionState('i18nextLng');
  const { isMobile } = useWindowSize();

  const { user } = useAuth();
  const { editUserMutation } = useEditUserMutation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const userId = user?.id || '';
  const LANG_CONFIG = language && language === Language.english ? ENGLISH : SWEDISH;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function changeLanguage(lang: string) {
    i18n.changeLanguage(lang);
  }

  const handleOptionClick = async () => {
    if (spinnerSwitcher) spinnerSwitcher();
    if (userId) {
      await editUserMutation({ id: userId, user: { lang: LANG_CONFIG.oppositeValue } });
    }
    changeLanguage(LANG_CONFIG.oppositeValue);
    setLanguage(LANG_CONFIG.oppositeValue);
    setAnchorEl(null);
    window.location.reload();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'language-popover' : undefined;

  return (
    <div>
      <ToggleBtn aria-describedby={id} variant="contained" color="secondary" onClick={handleClick}>
        {LANG_CONFIG.icon}
        {!isMobile && <span>{LANG_CONFIG.label}</span>}
        <Triangle />
      </ToggleBtn>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <OptionBtn onClick={handleOptionClick}>
          {LANG_CONFIG.optionIcon}
          {!isMobile && <span style={{ marginLeft: '8px' }}>{LANG_CONFIG.optionLabel}</span>}
        </OptionBtn>
      </Popover>
    </div>
  );
}
