import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { DialogProps } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert, ContainerBackdrop, Form, RichEditorController } from 'components';
import { Subscription } from 'types';
import { formattedDate } from 'utils';
import { required } from 'validations';

import { COMMENT_MAX_LENGTH } from './constants';
import {
  CloseBtn,
  CloseButton,
  ContentW,
  Dialog,
  FooterW,
  NoteW,
  SubmitBtn,
  Wrapper,
} from './styled';

interface CancelPlanDialogProps extends DialogProps {
  onClose: () => void;
  currentSubscription: Subscription;
}

export function CancelPlanDialog({
  onClose,
  open,
  currentSubscription,
}: CancelPlanDialogProps): ReactElement {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: { comment: '' },
    mode: 'onSubmit',
  });

  const { mutateAsync: cancelSubscriptionMutation, isLoading } = useMutation(
    API_HANDLERS.SUBSCRIPTION.CANCEL,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['subscription-details']);
        alert.success(t('subscriptions.modals.cancelSubscription.afterCancelToast'));
      },
    },
  );

  const handleFormSubmit = handleSubmit(async ({ comment }) => {
    await cancelSubscriptionMutation({ comment });
    onClose();
  });

  const expiresAt = formattedDate(new Date(currentSubscription.expires_at));

  return (
    <Dialog open={open} disableEnforceFocus>
      <Wrapper>
        <div>
          <CloseButton
            variant="text"
            startIcon={<KeyboardBackspaceRoundedIcon fontSize="large" />}
            onClick={onClose}
          >
            {t('subscriptions.modals.cancelSubscription.title')}
          </CloseButton>
        </div>

        {isLoading && <ContainerBackdrop />}

        <ContentW>
          <NoteW>{t('subscriptions.modals.cancelSubscription.note', { date: expiresAt })}</NoteW>
          <RichEditorController
            name="comment"
            control={control}
            placeholder={t('subscriptions.modals.cancelSubscription.placeholder')}
            maxLength={COMMENT_MAX_LENGTH}
            rules={required}
            sx={{ width: '100%' }}
          />
          <Form onSubmit={handleFormSubmit}>
            <FooterW>
              <CloseBtn color="info" variant="contained" onClick={onClose}>
                {t('subscriptions.modals.cancelSubscription.closeBtn')}
              </CloseBtn>
              <SubmitBtn color="info" type="submit" disabled={isLoading}>
                {t('subscriptions.modals.cancelSubscription.submitBtn')}
              </SubmitBtn>
            </FooterW>
          </Form>
        </ContentW>
      </Wrapper>
    </Dialog>
  );
}
