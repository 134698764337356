import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';

//TODO: hide firebaseConfig values (.env)
const firebaseConfig = {
  apiKey: 'AIzaSyAVUCmRyCge00Gx95UQ3Qs7M-ShOxZdZTc',
  authDomain: 'adligo-369614.firebaseapp.com',
  projectId: 'adligo-369614',
  storageBucket: 'adligo-369614.appspot.com',
  messagingSenderId: '368569595621',
  appId: '1:368569595621:web:1ef2ee5b41fab124a9060c',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const deleteCurrentToken = () => {
  return deleteToken(messaging);
};

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BHqL7qyx8Ie0hz2cnJM2PLnBu5AKcikuFa2KjZ4zIOnEIsl01lpyEgpJnxMublHTY6oDl2ApoHrYLDLqQRCNilc',
  })
    .then((currentToken) => {
      return currentToken;
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      return undefined;
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

onMessage(messaging, (payload) => {
  const notificationTitle = payload?.data?.title ?? '';
  const notificationOptions = {
    body: payload?.data?.body,
  };

  new Notification(notificationTitle, notificationOptions);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// onMessageListener().then((payload: any) => {
//   new Notification(payload.data.title, {
//     body: payload.data.body,
//   });
// });
