import { useLocation } from 'react-router-dom';

import { ReactComponent as ExpandedIcon } from 'assets/icons/expanded.svg';
import { ReactComponent as NotExpandedIcon } from 'assets/icons/notExpanded.svg';
import { Links } from 'settings';
import { CategoriesListType, DocumentCategory } from 'types';
import { stripHtml } from 'utils';

import { COUNT_OF_ATTACHMENTS_WITHOUT_EXPAND, MAX_DESCRIPTION_LENGTH } from './constants';

interface UseDocumentMainInfoProps {
  description: string;
  documentTags: DocumentCategory[];
  isMobile: boolean;
  isExpandedCard: boolean;
  attachmentsLength: number;
  categoryMap: CategoriesListType;
}

export function useDocumentMainInfo({
  description,
  documentTags,
  isMobile,
  isExpandedCard,
  attachmentsLength,
  categoryMap,
}: UseDocumentMainInfoProps) {
  const { pathname } = useLocation();

  const hasShowExpandIcon =
    (description && description.length > MAX_DESCRIPTION_LENGTH) ||
    attachmentsLength > COUNT_OF_ATTACHMENTS_WITHOUT_EXPAND ||
    documentTags.length > 1 ||
    isMobile ||
    (description && (description.match(/<br>|<\/p>/g) || []).length >= 3);
  const isReadMore =
    (description && stripHtml(description).length > MAX_DESCRIPTION_LENGTH && !isExpandedCard) ||
    (description && (description.match(/<br>|<\/p>/g) || []).length >= 3 && !isExpandedCard);
  const isPublicDocumentsPage = pathname === Links.we.publicDocuments;
  const IconElement = isExpandedCard ? ExpandedIcon : NotExpandedIcon;

  const firstTag = documentTags[0];
  const otherTagsCount = documentTags.length - 1;
  const tagName = firstTag ? firstTag.tag.toLowerCase() : '';
  const categoryTag = categoryMap[tagName];

  return {
    hasShowExpandIcon,
    isReadMore,
    isPublicDocumentsPage,
    IconElement,
    firstTag,
    otherTagsCount,
    tagName,
    categoryTag,
  };
}
