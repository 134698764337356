import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useEditPostMutation, usePostDetails, useUsersListQuery } from 'api';
import { PollFormValues } from 'types';

import { formValuesAdapter } from '../../CreatePollForm/adapter';
import { defaultValuesAdapter } from '../adapter';

export function useEditPollForm() {
  const { t } = useTranslation();
  const { postId: pollId } = useParams();

  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  const {
    post: data,
    isLoading: isDetailsLoading,
    isSuccess: isDetailsLoaded,
  } = usePostDetails({
    postId: pollId ?? '',
    queryName: 'poll-details',
  });

  const { editPostMutation: editPollMutation, isLoading: isEditPollLoading } = useEditPostMutation({
    successText: t('toasts.poll.success.edit'),
    invalidateQueries: ['poll-details'],
  });

  const handleSubmit = useCallback(
    async (poll: PollFormValues, attachmentIds: string[]) => {
      const data = {
        ...formValuesAdapter(poll),
        ...(!!attachmentIds.length && { attachment_ids: attachmentIds }),
      };

      await editPollMutation({ post: data, postId: pollId ?? '' });
    },
    [editPollMutation, pollId],
  );

  const defaultValues = () => defaultValuesAdapter(usersList, data);

  return {
    isDetailsLoading,
    isDetailsLoaded,
    isEditPollLoading,
    isLoadingUsersList,
    defaultValues,
    handleSubmit,
  };
}
