import { IconButton } from '@mui/material';

import { PostListItem as Post } from 'api';
import { ReactComponent as PaperClipComponent } from 'assets/icons/paperclip.svg';
import { Avatar } from 'components';
import { DocumentCategoryColors } from 'constants/DocumentCategoryColors';
import { DocumentCategory } from 'types';

import { FooterW, CountOfAttachments, Wrapper, Tag, StyledDate } from './styled';

type DocumentMobileFooterTypes = Pick<Post, 'author'> & {
  attachmentsCounts: number;
  tag?: DocumentCategory | null;
  date: string;
};

export const DocumentMobileFooter = ({
  attachmentsCounts,
  tag,
  date,
  author,
}: DocumentMobileFooterTypes) => {
  const { avatar, id } = author;
  return (
    <Wrapper>
      {tag && <Tag sx={{ backgroundColor: DocumentCategoryColors[tag.icon] }}>{tag.label}</Tag>}
      <FooterW>
        <CountOfAttachments>
          <IconButton>
            <PaperClipComponent />
          </IconButton>
          {attachmentsCounts}
        </CountOfAttachments>
        <StyledDate>{date}</StyledDate>
        <Avatar
          wrapperSx={{ marginLeft: 'auto', cursor: 'pointer' }}
          userId={id}
          filename={avatar?.small || ''}
          size="xs"
        />
      </FooterW>
    </Wrapper>
  );
};
