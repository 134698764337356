import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useAttachmentDetails } from 'api';
import { ReactComponent as RemoveIcon } from 'assets/icons/closeRounded.svg';
import { Spinner, alert } from 'components';

import { RemoveCircle, SpinnerW, StyledImage, Thumb } from './styled';

interface ImageAttachmentProps {
  postId?: string;
  attachmentId: string;
  fileName: string;
  fileUrl: string;
  handleDelete?: () => void;
  blockDelete?: boolean;
}

export function ImageAttachment({
  postId,
  attachmentId,
  fileName,
  fileUrl,
  handleDelete,
  blockDelete,
}: ImageAttachmentProps): ReactElement {
  const { isLoading, data } = useAttachmentDetails({ postId, attachmentId, fileName, fileUrl });
  const { t } = useTranslation();

  async function handleAttachmentRemove() {
    if (blockDelete) {
      alert.error(t('toasts.document.blockDeleteAttachment'));
      return;
    }

    handleDelete?.();
  }

  if (isLoading) {
    return (
      <SpinnerW>
        <Spinner size="1.5rem" wrapperSx={{ height: '24px' }} />
      </SpinnerW>
    );
  }

  function handleOnLoad() {
    window.URL.revokeObjectURL(data);
  }

  return (
    <Thumb onClick={handleAttachmentRemove}>
      <StyledImage
        alt={fileName}
        src={data}
        // Revoke data uri after image is loaded
        onLoad={handleOnLoad}
      />

      <RemoveCircle className="remove-btn">
        <RemoveIcon style={{ opacity: 'unset' }} />
      </RemoveCircle>
    </Thumb>
  );
}
