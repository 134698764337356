import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Form,
  FormGroup,
  FullPageProgress,
  SubmitRoundedButton,
  TextFieldController,
} from 'components';
import { FIELDS_VALUE_LENGTH } from 'constants/fieldsLength';
import { LanguageSelector } from 'features/languageSelector';
import { useWindowSize } from 'hooks';
import { Links } from 'settings';
import { required } from 'validations';

import { GetStartedButton } from '../../components';
import { useSignInForm } from './hooks';
import { emailFieldRules } from './rules';
import { LinkW, RecoverPasswordHint } from './styled';
import { mobileButtonStyles } from './styles';

interface SignInFormProps {
  firstTimeSignIn: boolean;
}

export function SignInForm({ firstTimeSignIn }: SignInFormProps): ReactElement {
  const { control, handleFormSubmit, isLoading } = useSignInForm();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  return (
    <>
      {isLoading && <FullPageProgress />}

      <Form sx={{ marginTop: isMobile ? '32px' : '56px' }} onSubmit={handleFormSubmit}>
        <FormGroup marginBottom={isMobile ? 24 : 56}>
          <TextFieldController
            name="email"
            id="email"
            control={control}
            type="email"
            label={t('login.form.email')}
            placeholder={t('login.form.email')}
            rules={emailFieldRules}
            maxLength={FIELDS_VALUE_LENGTH.email.max}
          />
        </FormGroup>
        <FormGroup marginBottom={isMobile ? 16 : 35}>
          <TextFieldController
            name="password"
            control={control}
            label={t('login.form.password')}
            placeholder={t('login.form.password')}
            type="password"
            rules={required}
            maxLength={FIELDS_VALUE_LENGTH.password.max}
          />
        </FormGroup>
        <FormGroup
          sx={{
            justifyContent: 'space-between',
            flexDirection: isMobile && firstTimeSignIn ? 'column' : 'row',
          }}
        >
          <RecoverPasswordHint>
            <Trans i18nKey="login.recovery" values={{ linkLabel: t('common.clickHere') }}>
              Forgot your password?
              <LinkW to={Links.auth.requestPasswordReset}>{t('common.clickHere')}</LinkW>
              to recover it
            </Trans>
          </RecoverPasswordHint>
          {firstTimeSignIn ? (
            <GetStartedButton />
          ) : (
            <SubmitRoundedButton sx={isMobile ? mobileButtonStyles : {}} />
          )}
        </FormGroup>
      </Form>

      <Box sx={{ marginTop: '24px' }}>
        <LanguageSelector />
      </Box>
    </>
  );
}
