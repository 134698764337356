import { AxiosError } from 'axios';

import { handleLogout } from '../../utils';
import { API_PATHS } from '../apiPaths';

export function retryHandler(failureCount: number, error: AxiosError): boolean {
  // allow refetch only when refreshing token
  if (error.response?.status === 401) {
    if (error.config.url === API_PATHS.AUTH.LOGIN()) {
      return false;
    }

    if (failureCount === 3) {
      handleLogout();
      return false;
    }
    return true;
  }

  return false;
}
