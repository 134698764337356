import { Box } from '@mui/material';
import { ReactElement } from 'react';

import {
  DocumentAttachment,
  FormGroup,
  ImageGallery,
  ListItemImageAttachment,
  ListItemVideoAttachment,
  VideoGallery,
} from 'components';
import {
  filesFormGroupMobileStyles,
  filesFormGroupStyles,
} from 'components/FormAttachments/styles';
import { PREVIEW_FILE_NAME_LENGTH_LIMIT } from 'constants/index';
import { useModal, useWindowSize } from 'hooks';
import { Attachment } from 'types';
import { hideDocNameMidPart } from 'utils';

import { OnlyImagesLayout } from '../OnlyImagesLayout';
import { twoImageMobileStyles, twoImageStyles } from '../OnlyImagesLayout/styles';
import { OnlyVideosLayout } from '../OnlyVideosLayout';
import { ContainerW } from './styled';

interface PostAttachmentsProps {
  attachments: Attachment[];
  postId: string;
  isPreview: boolean;
  goToPostDetails: () => void;
}

export function PostAttachments({
  attachments,
  postId,
  isPreview,
  goToPostDetails,
}: PostAttachmentsProps): ReactElement {
  const { isOpen, handleOpenModal, handleCloseModal } = useModal();
  const {
    isOpen: isOpenVideoGallery,
    handleOpenModal: handleOpenModalVideoGallery,
    handleCloseModal: handleCloseModalVideoGallery,
  } = useModal();
  const { isMobile } = useWindowSize();

  const onlyImages = attachments.every((attachment) => attachment.type === 'image');
  const onlyVideos = attachments.every((attachment) => attachment.type === 'video');

  const image = attachments.find((attachment) => attachment.type === 'image');
  const document = attachments.find((attachment) => attachment.type === 'document');
  const video = attachments.find((attachment) => attachment.type === 'video');

  const image_doc = image && document && !video;
  const image_video = image && video && !document;
  const doc_video = document && video && !image;
  const allAttachment = image && document && video;
  const imagesList = attachments.filter((attachment) => attachment.type === 'image');
  const moreImages = imagesList.length > 1 ? imagesList.length - 1 : null;

  const documentsList = attachments.filter((attachment) => attachment.type === 'document');

  const videosList = attachments.filter((attachment) => attachment.type === 'video');
  const moreVideos = videosList.length > 1 ? videosList.length - 1 : null;

  return (
    <ContainerW>
      {(image_doc || onlyImages) && (
        <div style={{ marginBottom: '10px' }}>
          <OnlyImagesLayout
            images={imagesList}
            postId={postId}
            onOpenGallery={handleOpenModal}
            goToPostDetails={goToPostDetails}
          />
        </div>
      )}
      {(doc_video || onlyVideos) && (
        <OnlyVideosLayout
          videos={videosList}
          onOpenGallery={handleOpenModalVideoGallery}
          goToPostDetails={goToPostDetails}
        />
      )}
      {image_video && (
        <Box display="flex">
          <ListItemImageAttachment
            key={image.id}
            attachmentId={image.id}
            fileName={image.file.small}
            fileUrl={image.file_url.small}
            postId={postId}
            wrapperSx={isMobile ? twoImageMobileStyles : twoImageStyles}
            onOpenGallery={handleOpenModal}
            moreImages={moreImages}
          />
          <ListItemVideoAttachment
            key={video.id}
            video={video}
            wrapperSx={isMobile ? twoImageMobileStyles : twoImageStyles}
            onOpenGallery={handleOpenModalVideoGallery}
            moreVideos={moreVideos}
          />
        </Box>
      )}
      {allAttachment && (
        <Box display="flex">
          <ListItemImageAttachment
            key={image.id}
            attachmentId={image.id}
            fileName={image.file.small}
            fileUrl={image.file_url.small}
            postId={postId}
            wrapperSx={isMobile ? twoImageMobileStyles : twoImageStyles}
            onOpenGallery={handleOpenModal}
            moreImages={moreImages}
          />
          <ListItemVideoAttachment
            key={video.id}
            video={video}
            wrapperSx={isMobile ? twoImageMobileStyles : twoImageStyles}
            onOpenGallery={handleOpenModalVideoGallery}
            moreVideos={moreVideos}
          />
        </Box>
      )}
      {document && (
        <div style={{ marginTop: '10px' }}>
          <FormGroup sx={isMobile ? filesFormGroupMobileStyles : filesFormGroupStyles}>
            {documentsList.map(({ file, id, file_url }) => {
              const fileName =
                file.original.length > PREVIEW_FILE_NAME_LENGTH_LIMIT
                  ? hideDocNameMidPart({
                      docName: file.original,
                      limit: PREVIEW_FILE_NAME_LENGTH_LIMIT,
                    })
                  : file.original;

              return (
                <DocumentAttachment
                  key={id}
                  postId="null"
                  attachmentId={id}
                  fileName={fileName}
                  handleDelete={() => null}
                  hasDownload
                  originalFileName={file.original}
                  originalFileUrl={file_url?.original}
                  blockDelete
                  isPreview={isPreview}
                />
              );
            })}
          </FormGroup>
        </div>
      )}
      {isOpen && (
        <ImageGallery
          open={isOpen}
          onClose={handleCloseModal}
          postId={postId}
          imageList={imagesList}
        />
      )}
      {isOpenVideoGallery && (
        <VideoGallery
          open={isOpenVideoGallery}
          onClose={handleCloseModalVideoGallery}
          videoList={videosList}
        />
      )}
    </ContainerW>
  );
}
