import { styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const NotificationBar = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  padding: 12px 18px;
  border-radius: 15px;
  max-width: 1105px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1023px) {
    padding: 16px;
    align-items: flex-start;
    margin-bottom: 16px;
  }
`;

export const ExpiresInW = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.p080};
  font-size: 14px;
  line-height: 16px;
`;

export const FlexW = styled('div')`
  display: flex;
  align-items: center;
  @media (max-width: 1023px) {
    &.positionTop {
      align-items: flex-start;
    }
  }
`;

export const LinkW = styled(NavLink)`
  color: ${({ theme }) => theme.palette.info.main};
  margin-right: 16px;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;

  @media (max-width: 1023px) {
    font-weight: 500;
  }
`;

export const ExpiresInMainW = styled('div')`
  margin-left: 20px;
  @media (max-width: 1023px) {
    margin-left: 16px;
  }
`;
