import { Button, styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const RecoverPasswordHint = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.p050};
  line-height: 18px;
`;

export const LinkW = styled(NavLink)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
  text-decoration: none;
`;

export const ButtonW = styled(Button)`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-transform: none;
  width: 265px;
  padding: 14px 16px;
`;
