import { styled, Typography } from '@mui/material';

export const CommentW = styled('div')<{ isCommentOwner: boolean }>`
  position: relative;
  padding: 24px;
  width: 380px;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.primary.p040};
  margin-bottom: 40px;
  margin-left: ${({ isCommentOwner }) => (isCommentOwner ? 'auto' : 'unset')};
  @media (max-width: 1023px) {
    padding: 8px;
    margin-left: ${({ isCommentOwner }) => (isCommentOwner ? 'auto' : '32px')};
    margin-right: ${({ isCommentOwner }) => (isCommentOwner ? '32px' : '0px')};
    width: calc(100% - 32px);
    max-width: 380px;
  }
`;

export const CommentText = styled(Typography)`
  white-space: pre-line;

  & p {
    margin: 12px 0;

    &:has(code) {
      margin: 4px 0;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  & a {
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

export const DateTime = styled(Typography, {
  shouldForwardProp: (propname) => propname !== 'isCommentOwner',
})<{ isCommentOwner: boolean }>(({ isCommentOwner, theme }) => ({
  color: theme.palette.primary.p080,
  fontSize: '12px',
  lineHeight: '14px',
  cursor: 'default',
  position: 'absolute',
  right: isCommentOwner ? 'unset' : '11px',
  left: isCommentOwner ? '11px' : 'unset',
  bottom: '-20px',
}));

export const UserName = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  left: 20px;
  bottom: -20px;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 1023px) {
    position: static;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.palette.primary.p080};
  }
`;
