import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { IconButton } from '@mui/material';
import { ReactElement, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseTextField } from 'components';
import { useWindowSize } from 'hooks';
import { UserRole } from 'types';
import { EMAIL_REGEX, WRONG_EMAIL_FORMAT } from 'validations';

import { fieldStyles } from './styles';

interface EmailListItemProps {
  email: string;
  id: string;
  handleRemove: (id: string) => void;
  handleOnChange: (id: string, value: string, role: string) => void;
  inputRef?: RefObject<HTMLInputElement> | null;
  role: UserRole;
}

export function EmailListItem({
  email,
  id,
  handleRemove,
  handleOnChange,
  inputRef,
  role,
}: EmailListItemProps): ReactElement {
  const [value, setValue] = useState(email);
  const [isValidation, setValidation] = useState(false);

  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const isError = isValidation && value !== '' && !EMAIL_REGEX.test(email);
  const isHelperText =
    isValidation && value !== '' && !EMAIL_REGEX.test(email) && WRONG_EMAIL_FORMAT;

  const handleChange = (value: string) => {
    handleOnChange(id, value, role);

    if (value.includes(' ')) {
      setValue(value.trim());
      return;
    }

    setValue(value);
  };

  useEffect(() => {
    setValue(email);
  }, [email]);

  return (
    <>
      <BaseTextField
        inputRef={inputRef}
        InputProps={{
          disableUnderline: true,
          endAdornment: email && (
            <IconButton
              onClick={() => {
                handleRemove(id);
                setValue('');
              }}
              sx={{ right: '15px' }}
            >
              <CancelRoundedIcon />
            </IconButton>
          ),
        }}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={() => setValidation(true)}
        sx={{
          ...fieldStyles,
          ...(!EMAIL_REGEX.test(email) && isMobile ? { marginTop: '16px' } : {}),
        }}
        error={isError}
        helperText={isHelperText}
        placeholder={t('inviteEmployee.form.placeholders.email')}
      />
    </>
  );
}
