import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';

import { ChangeTargetsOrderRequestParams } from '../types';

interface UseChangeTargetsOrderMutationReturn {
  isLoading: boolean;
  changeTargetsOrdersMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    ChangeTargetsOrderRequestParams,
    unknown
  >;
}

export function useChangeTargetsOrderMutation(): UseChangeTargetsOrderMutationReturn {
  const { mutateAsync: changeTargetsOrdersMutation, isLoading } = useMutation(
    API_HANDLERS.KPI.CHANGE_TARGETS_ORDER,
  );

  return {
    changeTargetsOrdersMutation,
    isLoading,
  };
}
