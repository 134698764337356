import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { AddVisionParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { QUERIES } from 'constants/index';

interface UseCreateVisionMutationReturn {
  createVisionMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    AddVisionParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseEditKpiMutationProps {
  successText: string;
}

export function useCreateVisionMutation({
  successText,
}: UseEditKpiMutationProps): UseCreateVisionMutationReturn {
  const { mutateAsync: createVisionMutation, isLoading } = useMutation(
    API_HANDLERS.KPI.ADD_VISION,
    {
      onSuccess: () => {
        alert.success(successText);
        queryClient.invalidateQueries([QUERIES.VISION_AND_TARGETS]);
      },
    },
  );

  return {
    createVisionMutation,
    isLoading,
  };
}
