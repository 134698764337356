import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { AddTargetRequestParams } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseCreateTargetMutationReturn {
  createTargetMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    AddTargetRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseCreateTargetMutationProps {
  successText: string;
}

export function useCreateTargetMutation({
  successText,
}: UseCreateTargetMutationProps): UseCreateTargetMutationReturn {
  const { mutateAsync: createTargetMutation, isLoading } = useMutation(
    API_HANDLERS.KPI.ADD_TARGET,
    {
      onSuccess: () => {
        alert.success(successText);
      },
    },
  );

  return {
    createTargetMutation,
    isLoading,
  };
}
