import { styled } from '@mui/material';

import BoldActiveIcon from 'assets/icons/richEditor/bold-active.svg';
import BoldIcon from 'assets/icons/richEditor/bold.svg';
import BulletedListActiveIcon from 'assets/icons/richEditor/bulletedList-active.svg';
import BulletedListIcon from 'assets/icons/richEditor/bulletedList.svg';
import CloseIcon from 'assets/icons/richEditor/close-icon.svg';
import CodeActiveIcon from 'assets/icons/richEditor/code-active.svg';
import CodeIcon from 'assets/icons/richEditor/code.svg';
import EmotionActiveIcon from 'assets/icons/richEditor/emotion-active.svg';
import EmotionIcon from 'assets/icons/richEditor/emotion.svg';
import InsertLinkActiveIcon from 'assets/icons/richEditor/insertLink-active.svg';
import InsertLinkIcon from 'assets/icons/richEditor/insertLink.svg';
import ItalicActiveIcon from 'assets/icons/richEditor/italic-active.svg';
import ItalicIcon from 'assets/icons/richEditor/italic.svg';
import NumberedListActiveIcon from 'assets/icons/richEditor/numberedList-active.svg';
import NumberedListIcon from 'assets/icons/richEditor/numberedList.svg';
import OpenIcon from 'assets/icons/richEditor/open-icon.svg';
import ThroughActiveIcon from 'assets/icons/richEditor/through-active.svg';
import ThroughIcon from 'assets/icons/richEditor/through.svg';
import UnderlineActiveIcon from 'assets/icons/richEditor/underline-active.svg';
import UnderlineIcon from 'assets/icons/richEditor/underline.svg';

const icons = [
  {
    default: BoldIcon,
    active: BoldActiveIcon,
  },
  {
    default: ItalicIcon,
    active: ItalicActiveIcon,
  },
  {
    default: UnderlineIcon,
    active: UnderlineActiveIcon,
  },
  {
    default: ThroughIcon,
    active: ThroughActiveIcon,
  },
  {
    default: NumberedListIcon,
    active: NumberedListActiveIcon,
  },
  {
    default: BulletedListIcon,
    active: BulletedListActiveIcon,
  },
  {
    default: InsertLinkIcon,
    active: InsertLinkActiveIcon,
  },
  {
    default: CodeIcon,
    active: CodeActiveIcon,
  },
  {
    default: EmotionIcon,
    active: EmotionActiveIcon,
  },
];
const buttonStyles = (): string => {
  return icons
    .map((item, index) => {
      return `
      &:nth-of-type(${index + 1}) button {
        background-image: url(${item.default});

        &.active {
          background-image: url(${item.active});
        }
      }`;
    })
    .join(' ');
};

export const RichEditorC = styled('div')`
  position: relative;
  width: 100%;

  & * {
    font-family: inherit !important;
  }
`;

export const RichEditorW = styled('div')<{ isError: boolean; isModal?: boolean }>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
  border-radius: 20px;

  & .w-e-panel-content-emotion {
    width: 280px;

    li {
      padding: 6px;
      font-style: normal !important;
    }
  }
  @media (max-width: 1023px) {
    flex-direction: column-reverse;

    ${({ isError }) =>
      isError &&
      `
        border: 1px solid #ff9900;
    `}

    & .w-e-drop-panel {
      left: -34px !important;
    }

    & .w-e-panel-content-emotion {
      width: ${({ isModal }) => (isModal ? '200px' : '264px')};

      li {
        padding: 2px;
      }
    }
  }
`;

export const ToolbarW = styled('div')<{ isOpen: boolean }>`
  position: relative;
  margin-bottom: 16px;

  & > div {
    padding: 12px;
  }

  & .w-e-toolbar {
    padding: 0;
  }

  & .w-e-bar-item {
    ${buttonStyles()}

    & button {
      border-radius: 8px;
      background-repeat: no-repeat;
      background-position: center;
      width: 32px;
      height: 32px;

      & svg {
        display: none;
      }

      &.active {
        background-color: #ccebfc;
      }

      &.disabled {
        opacity: 0.4;

        &:after,
        &:before {
          opacity: 0;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    flex-wrap: nowrap;
    margin-bottom: 0px;

    padding: 16px 8px 16px 34px;

    & > div {
      padding: 0;
    }

    & .w-e-bar-item {
      padding: 4px 3px;
      height: 30px;
      display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

      &:last-of-type {
        display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
      }
    }

    & .w-e-bar-item button {
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background-size: 9px 9px;
    }
  }
`;

export const EditorW = styled('div')<{ withRightPadding?: boolean }>`
  & .w-e-scroll {
    padding: ${({ withRightPadding }) => (withRightPadding ? '0px 50px 0px 6px' : '0 6px')};
    border-radius: 20px;
    background: #fff;
  }

  & .w-e-text-container {
    border-radius: 20px;
    padding: 0 0 14px 0;
    font-family: inherit !important;

    & * {
      font-size: 18px !important;
      line-height: 28px;
    }

    & code {
      font-family: 'Outfit', sans-serif !important;
      color: #ff9900;
      background: rgba(239, 239, 239, 0.7);
      border: 1px solid #e3e3e3;
      border-radius: 6px;
      margin-bottom: 2px;
    }

    & a {
      color: #009bf2;
      background: none;
    }

    & a,
    span,
    p {
      background: none;
    }

    & h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 0;
    }

    & [data-slate-editor] p {
      line-height: 1.1;
      margin-top: 0;
      margin-bottom: 12px;
    }

    & .w-e-text-placeholder {
      font-style: normal;
      left: 15px;
      top: 0;
      font-size: 18px;
      color: #a8a8a8;
    }

    & .w-e-max-length-info {
      display: none;
    }

    & .w-e-bar {
      padding: 8px;
    }
  }

  @media (max-width: 1023px) {
    & .w-e-scroll {
      padding: 0 8px;
    }

    & .w-e-text-container {
      padding: 16px 0 0;

      & .w-e-text-placeholder {
        left: 18px;
        top: 16px;
      }

      & .w-e-bar {
        padding: 2px;
      }
    }
  }
`;

export const ToolbarShowButton = styled('div')<{ isOpen: boolean }>`
  position: absolute;
  top: 20px;
  left: 8px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-size: 9px 9px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ isOpen }) => `url(${isOpen ? CloseIcon : OpenIcon})`};
`;

export const EditorHelperText = styled('p')`
  position: absolute;
  top: -22px;
  font-size: 16px;
  line-height: 18px;
  color: #ff9900;
  margin: 0;

  @media (max-width: 1023px) {
    top: 2px;
    left: 20px;
    z-index: 1;
    font-size: 12px;
    font-weight: 500;
  }
`;
