import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { VIEW_OPERATION_IS_NOT_ALLOWED } from 'constants/errorCodes';
import { QUERIES } from 'constants/index';
import { API_ERRORS_BY_CODES } from 'constants/toasterMessages';
import { ApiErrors, UserExtendedDetails } from 'types';

import { userDetailsAdapter } from './adapter';

interface UseUserDetailsQueryReturn {
  user: UserExtendedDetails | null;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  refetch: () => void;
}

interface UseUserDetailsQueryProps {
  employeeId?: string;
}

export function useUserDetailsQuery({
  employeeId = '',
}: UseUserDetailsQueryProps): UseUserDetailsQueryReturn {
  const [userDetails, setUserDetails] = useState<UserExtendedDetails | null>(null);

  const { isLoading, isSuccess, isError, refetch, fetchStatus } = useQuery(
    [QUERIES.USER_DETAILS, employeeId],
    () => API_HANDLERS.USER.DETAILS(employeeId),
    {
      enabled: !!employeeId,
      onSuccess: (data) => {
        setUserDetails(userDetailsAdapter(data.data));
      },
      onError: (error) => {
        const errorsItem = error as AxiosError<ApiErrors>;
        const errorText = API_ERRORS_BY_CODES[VIEW_OPERATION_IS_NOT_ALLOWED];
        const isViewNotAllowed = errorsItem.response?.data.errors.some(
          (error) => error.code === VIEW_OPERATION_IS_NOT_ALLOWED,
        );
        isViewNotAllowed && alert.error(errorText);
      },
    },
  );

  return {
    user: userDetails,
    isLoading: isLoading && fetchStatus !== 'idle',
    isSuccess,
    isError,
    refetch,
  };
}
