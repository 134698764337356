import { styled, Typography, Link } from '@mui/material';

export const PostW = styled('div')<{ isAutomatedPost?: boolean }>`
  display: flex;
  flex-wrap: ${({ isAutomatedPost }) => (isAutomatedPost ? 'nowrap' : 'wrap')};
  width: 409px;
  max-height: unset;
  padding: 24px 24px 20px 24px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  margin-bottom: 24px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1023px) {
    margin-bottom: 16px;
    width: 100%;
    max-width: 409px;
    padding: 16px 10px 16px 16px;
  }
`;

export const PostTitle = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-bottom: 8px;
  overflow: hidden;
  white-space: break-spaces;
`;

export const StrongW = styled('strong')`
  cursor: pointer;
  font-weight: 900 !important;
  width: 100%;
`;

export const PostDescription = styled('div', {
  shouldForwardProp: (propname) => propname !== 'hasAttachments',
})<{ hasAttachments?: boolean; isPoll?: boolean }>(({ theme, isPoll }) => ({
  fontSize: isPoll ? '16px' : '12px',
  lineHeight: isPoll ? '24px' : '16px',
  color: theme.palette.primary.p080,
  maxHeight: isPoll ? 'fit-content' : '300px',

  '& p': {
    margin: isPoll ? '8px 0 16px 0' : '12px 0',

    '&:has(code)': {
      margin: '4px 0',
    },
  },

  '& a': {
    color: theme.palette.info.main,
  },

  '& h1, h2, h3, h4, h5': {
    marginTop: 0,
    fontSize: 'inherit',
  },
}));

export const LeftSide = styled('div')`
  width: 300px;
  margin-right: 30px;
  @media (max-width: 1023px) {
    margin-right: 10px;
    width: calc(100% - 40px);
  }
`;

export const RightSide = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 1023px) {
    align-items: flex-start;
  }
`;

export const ActionsW = styled('div')`
  position: relative;
  top: -8px;
  width: min-content;
`;

export const IconW = styled('div')`
  position: absolute;
  top: 24px;
  left: -22px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  z-index: 2;

  @media (max-width: 1023px) {
    position: static;
    flex-direction: row;
    margin-bottom: 12px;
    column-gap: 8px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

export const Container = styled('div')`
  position: relative;
`;

export const TagW = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const TagsCounter = styled(Typography)`
  background: #dfe1e6;
  color: ${({ theme }) => theme.palette.primary.p080};
  border-radius: 10px;
  margin-left: 6px;
  padding: 3px 6px;
  font-weight: 400;
  font-size: 12px;
`;

export const LinkW = styled(Link)`
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
  text-decoration-color: ${({ theme }) => theme.palette.info.main};
`;
