import { BoxProps } from '@mui/material';
import { ReactElement } from 'react';

import {
  PASSWORD_LENGTH_REGEX,
  NUMERIC_VALUES_REGEX,
  LOWERCASE_VALUES_REGEX,
  UPPERCASE_VALUES_REGEX,
  SPECIAL_SYMBOLS_REGEX,
} from 'validations';

import { HintsW, PatternTest } from './styled';

interface ValidationHintsProps {
  value: string;
  isSubmitted?: boolean;
  hintsSx?: BoxProps['sx'];
}

export function ValidationHints({
  value,
  isSubmitted = true,
  hintsSx,
}: ValidationHintsProps): ReactElement {
  const hasValidLength = PASSWORD_LENGTH_REGEX.test(value);
  const containsNumbers = NUMERIC_VALUES_REGEX.test(value);
  const containsLowercase = LOWERCASE_VALUES_REGEX.test(value);
  const containsUpperCase = UPPERCASE_VALUES_REGEX.test(value);
  const containsSymbols = SPECIAL_SYMBOLS_REGEX.test(value);

  return (
    <HintsW sx={hintsSx}>
      <PatternTest isSubmitted={isSubmitted} isValid={containsNumbers}>
        1
      </PatternTest>
      <PatternTest isSubmitted={isSubmitted} isValid={containsLowercase && containsUpperCase}>
        Aa
      </PatternTest>
      <PatternTest isSubmitted={isSubmitted} isValid={containsSymbols}>
        &
      </PatternTest>
      <PatternTest isSubmitted={isSubmitted} isValid={hasValidLength}>
        8+
      </PatternTest>
    </HintsW>
  );
}
