import { SWEDISH_DIAL_CODE } from 'constants/index';

export const defaultValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  password: '',
  countryCode: SWEDISH_DIAL_CODE,
};
