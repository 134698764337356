import { ReactElement } from 'react';

import { ReactComponent as AchivementIcon } from 'assets/icons/tags/achivement.svg';
import { ReactComponent as AirplaneIcon } from 'assets/icons/tags/airplane.svg';
import { ReactComponent as AnnouncementIcon } from 'assets/icons/tags/announcement.svg';
import { ReactComponent as BookIcon } from 'assets/icons/tags/book-square.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/tags/calendar-edit.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/tags/camera.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/tags/chart.svg';
import { ReactComponent as CloudIcon } from 'assets/icons/tags/cloud.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/tags/company.svg';
import { ReactComponent as ComputingIcon } from 'assets/icons/tags/computing.svg';
import { ReactComponent as ConnectivityIcon } from 'assets/icons/tags/connectivity.svg';
import { ReactComponent as ContractIcon } from 'assets/icons/tags/contract.svg';
import { ReactComponent as CreditCardIcon } from 'assets/icons/tags/credit-card.svg';
import { ReactComponent as DevicesIcon } from 'assets/icons/tags/devices.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/tags/document-text.svg';
import { ReactComponent as EditIcon } from 'assets/icons/tags/edit.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/tags/folder.svg';
import { ReactComponent as FoodIcon } from 'assets/icons/tags/food.svg';
import { ReactComponent as GalleryIcon } from 'assets/icons/tags/gallery.svg';
import { ReactComponent as GiftsIcon } from 'assets/icons/tags/gifts.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/tags/heart.svg';
import { ReactComponent as IdeaIcon } from 'assets/icons/tags/ideas.svg';
import { ReactComponent as LawIcon } from 'assets/icons/tags/law.svg';
import { ReactComponent as LettersIcon } from 'assets/icons/tags/letters.svg';
import { ReactComponent as MoneyIcon } from 'assets/icons/tags/moneys.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/tags/print.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/tags/profile-2user.svg';
import { ReactComponent as SecureIcon } from 'assets/icons/tags/secure.svg';
import { ReactComponent as TargetIcon } from 'assets/icons/tags/target.svg';
import { ReactComponent as TeacherIcon } from 'assets/icons/tags/teacher.svg';
import { ReactComponent as UntageedIcon } from 'assets/icons/tags/untagged.svg';

export const DocumentCategoryIcons: Record<string, ReactElement> = {
  airplane: <AirplaneIcon />,
  book: <BookIcon />,
  calendar: <CalendarIcon />,
  chart: <ChartIcon />,
  computing: <ComputingIcon />,
  devices: <DevicesIcon />,
  document: <DocumentIcon />,
  edit: <EditIcon />,
  heart: <HeartIcon />,
  money: <MoneyIcon />,
  people: <PeopleIcon />,
  teacher: <TeacherIcon />,
  creditCard: <CreditCardIcon />,
  achivement: <AchivementIcon />,
  law: <LawIcon />,
  print: <PrintIcon />,
  connectivity: <ConnectivityIcon />,
  gifts: <GiftsIcon />,
  target: <TargetIcon />,
  gallery: <GalleryIcon />,
  camera: <CameraIcon />,
  secure: <SecureIcon />,
  cloud: <CloudIcon />,
  food: <FoodIcon />,
  folder: <FolderIcon />,
  contract: <ContractIcon />,
  letters: <LettersIcon />,
  announcement: <AnnouncementIcon />,
  company: <CompanyIcon />,
  idea: <IdeaIcon />,
  untagged: <UntageedIcon />,
};
