import { LinearProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { ConfirmDialog, Form, alert, RichEditorController } from 'components';
import { useCallbackPrompt, useWindowSize } from 'hooks';
import { richEditorStyles } from 'styles';
import { removeExtraSpaces } from 'utils';

import { commentFieldRules, COMMENT_MAX_LENGTH } from './constants';
import { SubmitBtn } from './styled';
import { formStyles, loaderStyles } from './styles';

interface AddCommentFormProps {
  postId: string;
  isPostComment?: boolean;
}

export function AddCommentForm({ postId, isPostComment }: AddCommentFormProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const { mutateAsync: addNewCommentMutation, isLoading } = useMutation(
    API_HANDLERS.COMMENTS.CREATE,
    {
      onSuccess: () => {
        alert.success(t('comments.alerts.created'));
        queryClient.invalidateQueries(['comments-list']);
      },
    },
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitted },
  } = useForm<{ content: string }>({
    defaultValues: {
      content: '',
    },
  });

  const [isResetField, setResetField] = useState(false);

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isDirty && !isSubmitted,
  );

  const handleFormSubmit = handleSubmit(async ({ content }) => {
    if (content.trim() === '') {
      return;
    }
    const formValue = removeExtraSpaces(content);
    await addNewCommentMutation({ content: formValue, postId });

    setResetField((prev) => !prev);
    reset();
  });

  return (
    <div>
      <Form sx={formStyles} onSubmit={handleFormSubmit}>
        {isLoading && <LinearProgress color="info" sx={loaderStyles} />}

        <RichEditorController
          name="content"
          control={control}
          placeholder={t('comments.post.placeholder')}
          maxLength={COMMENT_MAX_LENGTH}
          rules={commentFieldRules}
          sx={richEditorStyles(isMobile)}
          isResetField={isResetField}
          withRightPadding
        />

        <SubmitBtn color="info" type="submit" disabled={isLoading} isPostComment={isPostComment}>
          {t('comments.postBtn')}
        </SubmitBtn>
      </Form>

      <ConfirmDialog
        title={t('comments.modal.title')}
        subTitle={t('comments.modal.exit')}
        open={showPrompt}
        onClose={cancelNavigation}
        onSubmit={confirmNavigation}
      />
    </div>
  );
}
