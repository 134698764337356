import { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';

import EquipmentEmptyImage from 'assets/images/equipment.png';
import { Links } from 'settings';
import { EquipmentTabsValues } from 'types';

import {
  EmptyStateContainer,
  EmptyStateImage,
  EmptyStateTitle,
  EmptyStateField,
  NavLinkW,
} from './styled';

interface EmptyStateProps {
  isRequestedState?: boolean;
}

export function EmptyState({ isRequestedState = false }: EmptyStateProps): ReactElement {
  const { employeeId } = useParams();

  const registerEquipmentLink = generatePath(Links.profile.equipmentList.createEquipment, {
    employeeId,
  });

  const requestEquipmentPath = generatePath(Links.profile.equipmentList.createEquipment, {
    employeeId,
  });

  const requestEquipmentLink = {
    pathname: requestEquipmentPath,
    search: `?tab=${EquipmentTabsValues.REQUEST}`,
  };

  return (
    <EmptyStateContainer>
      <EmptyStateField>
        <EmptyStateImage src={EquipmentEmptyImage} alt="equipment empty state" />
        <EmptyStateTitle>
          <Trans i18nKey="equipments.emptyState">
            Nothing is here yet.
            <NavLinkW to={isRequestedState ? requestEquipmentLink : registerEquipmentLink}>
              Add Equipment
            </NavLinkW>
            !
          </Trans>
        </EmptyStateTitle>
      </EmptyStateField>
    </EmptyStateContainer>
  );
}
