// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationSE from './locales/se.json';
import { Language } from './types';

const resources = {
  en: {
    translation: translationEN,
  },
  sv: {
    translation: translationSE,
  },
};

const detectionOptions = {
  order: ['localStorage', 'cookie', 'navigator'],
  caches: ['localStorage', 'cookie'],
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    supportedLngs: [Language.english, Language.swedish],
    debug: true,
    resources,
    interpolation: {
      escapeValue: false,
    },
    detection: detectionOptions,
  });

export default i18n;
