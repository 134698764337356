import { Box, styled, Typography } from '@mui/material';

export const InputW = styled(Box)`
  width: 47%;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;
export const WranDescription = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgb(0, 155, 242);
`;
