import { styled } from '@mui/material';

export const PageW = styled('div')`
  display: flex;
  padding-left: 88px;
  flex-direction: column;
  position: relative;
  /* max-height to position TopNav */
  max-width: 1180px;

  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 80px;
  }
`;

export const PageContent = styled('div')`
  display: flex;
`;

export const LeftColumn = styled('div')`
  width: 409px;
  margin-right: 89px;

  @media (max-width: 1023px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const RightColumn = styled('div')`
  width: 610px;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const FlexW = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const DesktopView = styled('div')`
  display: block;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MobileView = styled('div')`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`;
