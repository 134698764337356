import { EditCompanyFormValues } from 'types';
import { removeExtraSpaces } from 'utils';

export function formValuesAdapter({
  name,
  country_code,
  address_1,
  address_2,
  industry,
  vat,
  company_number,
  city,
  region,
  postcode,
  country,
  email,
  support_email,
  website,
  phone,
}: EditCompanyFormValues): EditCompanyFormValues {
  return {
    ...(typeof name === 'string' && { name: removeExtraSpaces(name) }),
    ...(typeof country_code === 'string' && { country_code: Number(country_code) }),
    ...(typeof address_1 === 'string' && { address_1: removeExtraSpaces(address_1) }),
    ...(typeof address_2 === 'string' && { address_2: removeExtraSpaces(address_2) }),
    ...(typeof industry === 'string' && { industry: removeExtraSpaces(industry) }),
    ...(typeof vat === 'string' && { vat: removeExtraSpaces(vat) }),
    ...(typeof company_number === 'string' && {
      company_number: removeExtraSpaces(company_number),
    }),
    ...(typeof city === 'string' && { city: removeExtraSpaces(city) }),
    ...(typeof region === 'string' && { region: removeExtraSpaces(region) }),
    ...(typeof postcode === 'string' && { postcode: removeExtraSpaces(postcode) }),

    ...(typeof country === 'string' && { country }),
    ...(typeof email === 'string' && { email }),
    ...(typeof support_email === 'string' && { support_email }),
    ...(typeof website === 'string' && { website: removeExtraSpaces(website) }),
    ...(typeof phone === 'string' && { phone }),
  };
}
