import { styled } from '@mui/material';

import { Button } from 'components';

export const PageW = styled('div')`
  padding-left: 88px;
  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 16px;
  }
`;

export const FlexW = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-top: 35px;
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  background-color: transparent;
  margin-left: 328px;
  box-shadow: none;
  padding: 6px 8px 6px 8px;
  @media (max-width: 1023px) {
    display: none;
  }
`;
