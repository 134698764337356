import { styled, FormGroup as MuiFormGroup } from '@mui/material';

export const FormGroup = styled(MuiFormGroup)`
  top: 0;
  width: 22%;
  position: absolute;
  right: 8%;

  @media (max-width: 1023px) {
    position: relative;
    top: unset;
    right: unset;
    width: calc(100% - 32px);
    margin: 0 16px;
  }
`;
