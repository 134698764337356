import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { DialogProps } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BaseTextFieldController,
  CountryCodeSelect,
  Form,
  FormGroup,
  SubmitRoundedButton,
  SelectController,
  ConfirmDialog,
  FullPageProgress,
} from 'components';
import { countries, FIELDS_VALUE_LENGTH } from 'constants/index';
import { useWindowSize } from 'hooks';
import { textFieldMobileStyles, textFieldStyles } from 'styles';
import { Company } from 'types';
import { stripNonDigits } from 'utils';
import { required } from 'validations';

import { EDIT_FORM_FIELDS_LENGTH } from './constants';
import { useEditCompanyForm } from './hooks';
import { emailFieldRules, phoneRules } from './rules';
import { DialogTitle, GroupTitle, ModalContent, ModalOverlay } from './styled';
import {
  countryCodeInputStyles,
  submitBtnStyles,
  submitBtnMobileStyles,
  countryCodeInputMobileStyles,
} from './stylesConfig';

interface EditCompanyFormProps extends DialogProps {
  onClose: () => void;
  company: Company;
}

export function EditCompanyForm({ onClose, company }: EditCompanyFormProps): ReactElement {
  const { t } = useTranslation();

  const {
    control,
    handleFormSubmit,
    isLoading,
    isOpenConfirmModal,
    handleTitleClick,
    handleCloseForm,
    handleCloseConfirmModal,
  } = useEditCompanyForm({ onClose, company });

  const { isMobile } = useWindowSize();
  const formGroupMarginBottom = isMobile ? 8 : 24;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;

  return (
    <>
      <ModalOverlay>
        <SubmitRoundedButton
          style={{
            ...(isMobile ? submitBtnMobileStyles : submitBtnStyles),
            position: isMobile ? 'fixed' : 'sticky',
          }}
          onClick={handleFormSubmit}
          disabled={isLoading}
        />
        {isMobile && (
          <DialogTitle onClick={handleTitleClick}>
            <KeyboardBackspaceRoundedIcon fontSize="large" />
            {t('company.edit.form.title')}
          </DialogTitle>
        )}
        <ModalContent>
          {!isMobile && (
            <DialogTitle onClick={handleTitleClick}>
              <KeyboardBackspaceRoundedIcon fontSize="large" />
              {t('company.edit.form.title')}
            </DialogTitle>
          )}

          <Form onSubmit={handleFormSubmit}>
            <GroupTitle>{t('company.edit.form.general.title')}</GroupTitle>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="name"
                control={control}
                placeholder={t('company.edit.form.general.placeholders.name')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.name.max}
                rules={required}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="industry"
                control={control}
                placeholder={t('company.edit.form.general.placeholders.industry')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.industry.max}
                rules={required}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="vat"
                control={control}
                placeholder={t('company.edit.form.general.placeholders.vatNumber')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.vatNumber.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="company_number"
                control={control}
                placeholder={t('company.edit.form.general.placeholders.companyNumber')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.companyNumber.max}
                rules={required}
              />
            </FormGroup>

            <GroupTitle sx={{ marginTop: isMobile ? '8px' : 0 }}>
              {t('company.edit.form.contactInfo.title')}
            </GroupTitle>

            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="address_1"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.address1')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.address1.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="address_2"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.address2')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.address2.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="city"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.city')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.city.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="region"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.state')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.province.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="postcode"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.postcode')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.postCode.max}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="country"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.country')}
                options={countries}
                withDefaultEmptyOption
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="email"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.companyEmail')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.companyEmail.max}
                rules={emailFieldRules}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="support_email"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.supportEmail')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.supportEmail.max}
                rules={emailFieldRules}
              />
            </FormGroup>
            <FormGroup
              marginBottom={formGroupMarginBottom}
              row
              sx={{ columnGap: isMobile ? '8px' : '20px' }}
            >
              <CountryCodeSelect
                control={control}
                name="country_code"
                containerStyle={{ width: isMobile ? '105px' : '170px' }}
                inputStyle={isMobile ? countryCodeInputMobileStyles : countryCodeInputStyles}
                buttonStyle={{
                  left: '10px',
                }}
              />
              <BaseTextFieldController
                name="phone"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.phone')}
                sx={{ flex: 1, ...fieldStyles }}
                format={stripNonDigits}
                maxLength={FIELDS_VALUE_LENGTH.phone.max}
                rules={phoneRules}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="website"
                control={control}
                placeholder={t('company.edit.form.contactInfo.placeholders.website')}
                sx={fieldStyles}
                maxLength={EDIT_FORM_FIELDS_LENGTH.website.max}
              />
            </FormGroup>
          </Form>
        </ModalContent>
      </ModalOverlay>

      <ConfirmDialog
        title={t('company.edit.modal.title')}
        subTitle={t('company.edit.modal.exit')}
        open={isOpenConfirmModal}
        onSubmit={handleCloseForm}
        onClose={handleCloseConfirmModal}
      />

      {isLoading && <FullPageProgress />}
    </>
  );
}
