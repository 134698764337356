import i18n from 'i18n';
import { EmployeesTableFields, TableColumnsConfig, EmployeesMobileTableFields } from 'types';

export const DEFAULT_EMPLOYEES_FIELDS = (
  isManager: boolean,
): TableColumnsConfig<EmployeesTableFields>[] => {
  if (isManager) {
    return [
      {
        key: 'avatar',
        label: '',
        width: '8%',
      },
      {
        key: 'name',
        label: i18n.t('employee.table.tHead.name'),
        width: '20%',
        sx: {
          maxWidth: '20%',
          minWidth: '20%',
        },
      },
      {
        key: 'email',
        label: i18n.t('employee.table.tHead.email'),
        width: '22%',
      },
      {
        key: 'phone',
        label: i18n.t('employee.table.tHead.phone'),
        width: '16%',
      },
      {
        key: 'birthDate',
        label: i18n.t('employee.table.tHead.birthDate'),
        width: '13%',
        sx: {
          wordBreak: 'break-word',
          maxWidth: '13%',
          minWidth: '118px',
        },
      },
      {
        key: 'address',
        label: i18n.t('employee.table.tHead.address'),
        width: '18%',
      },
      {
        key: 'actions',
        label: 'Actions',
        width: '12%',
      },
    ];
  }

  return [
    {
      key: 'avatar',
      label: '',
      width: '10%',
    },
    {
      key: 'name',
      label: i18n.t('employee.table.tHead.name'),
      width: '30%',
    },
    {
      key: 'email',
      label: i18n.t('employee.table.tHead.email'),
      width: '25%',
    },
    {
      key: 'phone',
      label: i18n.t('employee.table.tHead.phone'),
      width: '23%',
    },
    {
      key: 'actions',
      label: 'Actions',
      width: '12%',
    },
  ];
};

export const MOBILE_EMPLOYEES_FIELDS = (
  isManager: boolean,
): TableColumnsConfig<EmployeesMobileTableFields>[] => {
  if (isManager) {
    return [
      {
        key: 'avatar',
        label: '',
        width: '16%',
      },
      {
        key: 'name',
        label: i18n.t('employee.table.tHead.name'),
        width: '72%',
      },
      {
        key: 'actions',
        label: 'Actions',
        width: '12%',
      },
    ];
  }

  return [
    {
      key: 'avatar',
      label: '',
      width: '16%',
    },
    {
      key: 'name',
      label: i18n.t('employee.table.tHead.name'),
      width: '72%',
    },
    {
      key: 'actions',
      label: 'Actions',
      width: '12%',
    },
  ];
};

export const OFFBOARDING_EMPLOYEES_FIELDS: TableColumnsConfig<EmployeesTableFields>[] = [
  {
    key: 'avatar',
    label: '',
    width: '10%',
  },
  {
    key: 'name',
    label: i18n.t('employee.table.tHead.name'),
    width: '18%',
  },
  {
    key: 'email',
    label: i18n.t('employee.table.tHead.email'),
    width: '23%',
  },
  {
    key: 'phone',
    label: i18n.t('employee.table.tHead.phone'),
    width: '13%',
  },
  {
    key: 'birthDate',
    label: i18n.t('employee.table.tHead.birthDate'),
    width: '13%',
  },
  {
    key: 'offboarding',
    label: i18n.t('employee.table.tHead.offboarding'),
    width: '23%',
  },
];

export const MOBILE_OFFBOARDING_EMPLOYEES_FIELDS: TableColumnsConfig<EmployeesMobileTableFields>[] =
  [
    {
      key: 'avatar',
      label: '',
      width: '16%',
    },
    {
      key: 'name',
      label: i18n.t('employee.table.tHead.name'),
      width: '59%',
    },
    {
      key: 'offboarding',
      label: i18n.t('employee.table.tHead.offboarding'),
      width: '25%',
    },
  ];
