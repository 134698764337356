import { styled } from '@mui/material';

export const ListW = styled('div')``;

export const FieldW = styled('div')(() => ({
  marginTop: '24px',

  '&:nth-of-type(1)': {
    marginTop: '0',
  },
}));
