import { styled } from '@mui/material';

export const Row = styled('div')<{ privateView: boolean }>(({ privateView }) => ({
  display: 'flex',
  alignItems: 'center',

  svg: {
    width: privateView ? 'initial' : '24px',
    height: privateView ? 'initial' : '24px',
    minWidth: '16px',
  },
}));

export const DetailsItem = styled('div', {
  shouldForwardProp: (propname) => propname !== 'isLink' && propname !== 'privateView',
})<{ isLink?: boolean; privateView: boolean }>(({ isLink, privateView, theme }) => ({
  fontSize: privateView ? '14px' : '20px',
  lineHeight: privateView ? '19px' : '40px',
  color: isLink ? theme.palette.info.main : theme.palette.primary.p080,
  cursor: isLink ? 'pointer' : 'default',
  marginLeft: '12px',
  '.span-in': {
    color: theme.palette.primary.p040,
    margin: '0 8px',
  },
  maxWidth: privateView ? '280px' : '460px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',

  '& a': {
    font: 'inherit',
    color: 'inherit',
    textDecoration: 'none',
    maxWidth: privateView ? '280px' : '460px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  },

  [theme.breakpoints.down('desktop')]: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    maxWidth: '50%',
    overflowX: 'hidden',
  },

  '@media (max-width: 700px)': {
    maxWidth: '40%',
  },

  '@media (max-width: 600px)': {
    maxWidth: '35%',
  },

  '@media (max-width: 500px)': {
    maxWidth: '60%',
  },

  '@media (max-width: 400px)': {
    maxWidth: '55%',
  },

  '@media (max-width: 320px)': {
    maxWidth: '45%',
  },
}));
