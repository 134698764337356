import { BoxProps } from '@mui/material';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { Links } from 'settings';
import { TaskStatus } from 'types';

import { statusOptions } from '../TaskForm/constants';
import { Description, EmptyStateIconW, EmptyStateW, NavLinkW } from './styled';

interface EmptyStateProps {
  status?: TaskStatus;
  wrapperSx?: BoxProps['sx'];
  assigneeId?: string;
}

export function EmptyState({ status, wrapperSx, assigneeId }: EmptyStateProps): ReactElement {
  const link = Links.we.createTask;

  const taskStatus = status
    ? Object.fromEntries(statusOptions.map(({ value, label }) => [value, label.toLowerCase()]))[
        status
      ]
    : null;

  return (
    <EmptyStateW status={status} sx={{ ...wrapperSx }}>
      {status !== TaskStatus.done && <EmptyStateIconW style={{ opacity: '0.4' }} />}

      <Description variant="body2">
        {status && status === TaskStatus.done && (
          <>
            <Trans i18nKey="tasks.emptyState.statusDone">
              Nothing is here yet, but we’re sure your <br /> accomplishments will be great
            </Trans>
            ✨
          </>
        )}

        {(status || assigneeId) && status !== TaskStatus.done && (
          <Trans i18nKey="tasks.emptyState.default">
            Nothing is here yet.
            <NavLinkW to={link} state={{ status, assigneeId }}>
              Create a task
            </NavLinkW>
            to place <br />
            it in {{ taskStatus }}!
          </Trans>
        )}

        {!status && (
          <Trans i18nKey="tasks.emptyState.tasksPreview">
            Nothing is here yet. <NavLinkW to={link}>Create a task</NavLinkW>
            <br /> to add a new one!
          </Trans>
        )}
      </Description>
    </EmptyStateW>
  );
}
