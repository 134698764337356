import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDeleteLinkMutation, useLinkDetailsQuery } from 'api';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ConfirmDialog, GoBackButtonWithTitle, PageWrapper, Spinner } from 'components';
import { useModal } from 'hooks';

import { EditQuickLinkForm } from '../../components';
import { ButtonW, Wrapper } from './styled';

export function EditQuickLinkPage(): ReactElement {
  const { t } = useTranslation();
  const { linkId = '' } = useParams<string>();

  const { isLoading, link } = useLinkDetailsQuery(linkId);
  const { handleOpenModal, handleCloseModal, isOpen } = useModal();
  const { deleteLinkMutation } = useDeleteLinkMutation();

  async function handleDeleteTarget() {
    await deleteLinkMutation(linkId);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageWrapper>
      <GoBackButtonWithTitle title={t('quickLinks.editLinkPageTitle')} />
      <Wrapper>
        {link && (
          <>
            <EditQuickLinkForm defaultValues={link} handleOpenModal={handleOpenModal} />
            <ButtonW onClick={handleOpenModal} endIcon={<TrashIcon />}>
              {t('kpi.targetForm.removeButton')}
            </ButtonW>
          </>
        )}
      </Wrapper>

      <ConfirmDialog
        title={t('quickLinks.removeModal.title', { title: link?.title })}
        subTitle={t('quickLinks.removeModal.subtitle')}
        open={isOpen}
        onSubmit={handleDeleteTarget}
        onClose={handleCloseModal}
      />
    </PageWrapper>
  );
}
