import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { useUserDetailsQuery } from 'api';
import { GoBackButtonWithTitle, Spinner } from 'components';
import { useWindowSize } from 'hooks';
import { getFullName } from 'utils';

import { ProfileCard } from '../../components';
import { ContainerW, PageW } from './styled';
import { headerContainerSx, headerInnerSx, headerTitleSx } from './styles';

export function EmployeeSimplifiedDetails(): ReactElement {
  const { employeeId = '' } = useParams<string>();
  const { isMobile } = useWindowSize();
  const { isLoading, user } = useUserDetailsQuery({ employeeId });

  const fullName = getFullName({ firstName: user?.firstName, lastName: user?.lastName });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageW>
      {user && (
        <ContainerW>
          <GoBackButtonWithTitle
            title={fullName}
            containerSx={headerContainerSx(isMobile)}
            headerSx={headerInnerSx}
            titleSx={headerTitleSx}
          />

          <ProfileCard privateView={false} user={user} handleOpenModal={() => {}} />
        </ContainerW>
      )}
    </PageW>
  );
}
