import { Box } from '@mui/material';
import { ReactElement, useState } from 'react';

import { TopMenu } from 'components';
import { CalendarPreview } from 'features/calendar';
import { EmployeeListPreview } from 'features/employeesPreview';
import { KpiPreview } from 'features/kpi';
import { LastUpdatedDocuments } from 'features/lastUpdatedDocuments';
import { PostsList } from 'features/postsList';
import { QuickLinksPreview } from 'features/quickLinks';
import { TasksPreview } from 'features/tasks';
import { MobileTabsValues } from 'features/we/components/types';
import { useWindowSize } from 'hooks';
import { Links } from 'settings';

import { Banners, CreatePostActionBar, MobileTabs } from '../../components';
import { useWePageLogic } from './hooks';
import {
  DesktopView,
  FlexW,
  LeftColumn,
  MobileView,
  PageContent,
  PageW,
  RightColumn,
} from './styled';

export function WePage(): ReactElement {
  const [activeTab, setActiveTab] = useState<MobileTabsValues>(MobileTabsValues.NEWSFEED);
  const { isUsersListLoading, usersList } = useWePageLogic();
  const { isMobile } = useWindowSize();

  return (
    <PageW>
      <TopMenu />
      <DesktopView>
        <Banners />
        <PageContent>
          <LeftColumn>
            <CreatePostActionBar />
            <PostsList />
          </LeftColumn>

          <RightColumn>
            <FlexW>
              <TasksPreview />
              <CalendarPreview />
            </FlexW>

            <EmployeeListPreview
              isUsersListLoading={isUsersListLoading}
              usersList={usersList}
              linkInviteEmployee={Links.we.employees.invite}
              linkOfListEmployees={Links.we.employees.index}
            />

            <LastUpdatedDocuments />
            <QuickLinksPreview />
            <KpiPreview />
          </RightColumn>
        </PageContent>
      </DesktopView>

      <MobileView>
        <MobileTabs activeTab={activeTab} onChangeActiveTab={setActiveTab} />
        <Banners />

        {activeTab === MobileTabsValues.NEWSFEED ? (
          <LeftColumn>
            <CreatePostActionBar />
            <PostsList />
          </LeftColumn>
        ) : (
          <Box width={isMobile ? '100%' : '610px'}>
            <TasksPreview />
            <CalendarPreview />
            <EmployeeListPreview
              isUsersListLoading={isUsersListLoading}
              usersList={usersList}
              linkInviteEmployee={Links.we.employees.invite}
              linkOfListEmployees={Links.we.employees.index}
            />
            <LastUpdatedDocuments />
            <QuickLinksPreview />
            <KpiPreview />
          </Box>
        )}
      </MobileView>
    </PageW>
  );
}
