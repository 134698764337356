import { CompanySize } from 'types';

export const personalDataDefaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

export const companyProfileFormValues = {
  companyName: '',
  industry: '',
  companySize: Object.values(CompanySize).indexOf(CompanySize.ElevenToFifteen),
  number: '',
};
