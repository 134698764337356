import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CreatePostDraftResponse } from 'api';
import {
  Backdrop,
  BaseTextFieldController,
  ConfirmDialog,
  Form,
  FormGroup,
  SubmitRoundedButton,
  FileUploadButtons,
  FormAttachments,
  RichEditorController,
  SelectController,
  DatePickerWithIcon,
} from 'components';
import { GOAL_FIELDS_LENGTH } from 'constants/fieldsLength';
import { useWindowSize } from 'hooks';
import { textFieldStyles, textFieldMobileStyles, richEditorStyles } from 'styles';
import { User } from 'types';
import { required } from 'validations';

import { useCreateGoalForm } from './hooks';
import { descriptionFieldRules, titleFieldRules } from './rules';
import { ButtonsW, FormAndPreviewW, FromContainer } from './styled';
import { multiSelectStyles } from './styles';

interface CreateGoalFormProps {
  draft: CreatePostDraftResponse;
  usersList: User[];
}

export function CreateGoalForm({ usersList }: CreateGoalFormProps): ReactElement {
  const { t } = useTranslation();

  const {
    control,
    handleFormSubmit,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    attachedImages,
    attachedDocs,
    attachedVideos,
    handleDeleteAttachment,
    statusOptions,
    assigneeOptions,
    isLoading,
    isOpen,
    progress,
    handleSubmitConfirmModal,
    handleCloseConfirmModal,
    clearDeadline,
    handleAssigneeChange,
  } = useCreateGoalForm({ usersList });

  const { isMobile } = useWindowSize();

  const formGroupMarginBottom = isMobile ? 8 : 36;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;
  const isCreateGoal = true;

  return (
    <>
      {isLoading && <Backdrop progress={progress} />}

      <FormAndPreviewW>
        <FromContainer>
          <Form onSubmit={handleFormSubmit}>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="title"
                control={control}
                placeholder={t('career.goals.form.placeholders.title')}
                sx={fieldStyles}
                maxLength={GOAL_FIELDS_LENGTH.title.max}
                rules={titleFieldRules}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="status"
                control={control}
                placeholder={t('career.goals.form.placeholders.status')}
                options={statusOptions}
                rules={required}
              />
            </FormGroup>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="assignee"
                control={control}
                placeholder={t('equipments.create.form.placeholders.assignee')}
                options={assigneeOptions}
                multipleSelect
                sx={multiSelectStyles(isMobile)}
                handleChange={handleAssigneeChange}
                type="poll"
              />
            </FormGroup>
            <FormGroup
              marginBottom={formGroupMarginBottom}
              row
              sx={{ flexWrap: isMobile ? 'wrap' : 'nowrap', columnGap: '24px' }}
            >
              <RichEditorController
                name="description"
                control={control}
                placeholder={t('career.goals.form.placeholders.description')}
                maxLength={GOAL_FIELDS_LENGTH.description.max}
                rules={descriptionFieldRules}
                sx={richEditorStyles(isMobile, isCreateGoal)}
              />

              {isMobile && (
                <FormAttachments
                  attachedImages={attachedImages}
                  attachedDocs={attachedDocs}
                  attachedVideos={attachedVideos}
                  handleDeleteAttachment={handleDeleteAttachment}
                />
              )}
              <ButtonsW>
                <DatePickerWithIcon
                  control={control}
                  name="deadline"
                  label={t('career.goals.deadline')}
                  clearValue={clearDeadline}
                  sx={{ marginTop: '0px' }}
                  isCreateGoal
                />
                <FileUploadButtons
                  handleAddImages={handleAddImages}
                  handleAddDocuments={handleAddDocuments}
                  handleAddVideos={handleAddVideos}
                  classNames="notMarginTop"
                />
              </ButtonsW>
            </FormGroup>

            {!isMobile && (
              <FormAttachments
                attachedImages={attachedImages}
                attachedDocs={attachedDocs}
                attachedVideos={attachedVideos}
                handleDeleteAttachment={handleDeleteAttachment}
              />
            )}
            {!isMobile && <SubmitRoundedButton sx={{ marginLeft: 'auto', marginTop: '32px' }} />}
          </Form>
        </FromContainer>

        {isMobile && (
          <SubmitRoundedButton
            sx={{ marginLeft: 'auto', marginTop: '32px' }}
            className="fixed-bottom"
            onClick={handleFormSubmit}
          />
        )}
      </FormAndPreviewW>

      <ConfirmDialog
        subTitle={t('career.goals.create.modal.exit')}
        title={t('career.goals.create.modal.title')}
        open={isOpen}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
