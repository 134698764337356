import i18n from 'i18n';
import { AchievementsTab, AchievementsTabsValues, GoalStatus } from 'types';

export const StatusDisplayValue: Record<GoalStatus, string> = {
  [GoalStatus.PENDING]: i18n.t('career.goals.status.pending'),
  [GoalStatus.IN_PROGRESS]: i18n.t('career.goals.status.inProgress'),
  [GoalStatus.BLOCKED]: i18n.t('career.goals.status.blocked'),
  [GoalStatus.ACCOMPLISHED]: i18n.t('career.goals.status.accomplished'),
};

export const achievementsColors = [
  '34966D',
  '0DB6B8',
  '00DED1',
  '00FFB2',
  '009BF2',
  '5E88E5',
  '99D7FA',
  '9747FF',
  'FF6767',
  'FE9899',
  'FF9900',
  'FFD600',
];

export const AllOption = {
  value: 'all',
  label: i18n.t('poll.create.form.placeholders.all'),
};

export const achievementsTabs: AchievementsTab[] = [
  {
    label: i18n.t('career.achievements.tabs.active'),
    value: AchievementsTabsValues.ACTIVE,
  },
  {
    label: i18n.t('career.achievements.tabs.accomplished'),
    value: AchievementsTabsValues.ACCOMPLISHED,
  },
];
