import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useNotificationUnreadQuery } from 'api';
import { useReadNotifications } from 'api/queries/useReadNotifications';

export function useNotificationBadge() {
  const [unreadAmount, setUnreadAmount] = useState<number>();

  function handleNewNotification() {
    setUnreadAmount((a) => (a ? a + 1 : 1));
  }

  const { amount: startAmount } = useNotificationUnreadQuery();

  useEffect(() => {
    if (startAmount !== undefined) {
      setUnreadAmount(startAmount);
    }
  }, [startAmount]);

  const [isReadArray, setIsReadArray] = useState<string[]>([]);
  const [ids] = useDebounce<string[]>(isReadArray, 1000);

  const { amount } = useReadNotifications({ ids });

  useEffect(() => {
    if (amount !== undefined) {
      setUnreadAmount(amount as number);
    }
  }, [amount]);

  function markAsRead(id: string) {
    setIsReadArray((isReadArray) =>
      isReadArray.includes(id) ? isReadArray : [id, ...isReadArray],
    );
  }

  return {
    amount: unreadAmount,
    handleNewNotification,
    changeUnreadAmount: setUnreadAmount,
    markAsRead,
  };
}
