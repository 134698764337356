import { CircularProgress } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Backdrop, RichEditor, Spinner, Table } from 'components';
import { ConfirmDialog } from 'components/Dialogs';
import { DEACTIVATE_USER_COMMENT_MAX_LENGTH } from 'constants/fieldsLength';
import { EditEmployeeForm } from 'features/employeeDetails';
import { useWindowSize } from 'hooks';
import { EmployeesTableFields, UserStatus } from 'types';

import { EmployeesTableHeader } from '../EmployeesTableHeader';
import {
  DEFAULT_EMPLOYEES_FIELDS,
  MOBILE_EMPLOYEES_FIELDS,
  MOBILE_OFFBOARDING_EMPLOYEES_FIELDS,
  OFFBOARDING_EMPLOYEES_FIELDS,
} from './config';
import { useEmployeeTable } from './hooks';
import { ProgressW, RichEditorW } from './styled';
import { headerItemSx } from './styles';

interface EmployeesTableProps {
  isOffboarding?: boolean;
}

export function EmployeesTable({ isOffboarding }: EmployeesTableProps): ReactElement | null {
  const {
    isUsersListLoading,
    usersList,
    lastListRef,
    isOpenModal,
    handleCloseModal,
    onDeactivateEmployee,
    isFetchingNextPage,
    deleteEmployee,
    setDeletedComment,
    deletedCommentError,
    countOfActiveUsers,
    onHandleFocusRichEditor,
    handleRowClick,
    isOpenActivateModal,
    handleCloseActivateModal,
    user,
    isLoadingUser,
    isManager,
  } = useEmployeeTable(Boolean(isOffboarding));
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const confirmDialogTitle =
    isOffboarding && deleteEmployee?.status == UserStatus.Active
      ? t('employee.table.modal.title.offboard', { userName: deleteEmployee?.userName || '' })
      : t('employee.table.modal.title.remove');

  const confirmDialogCloseText = isOffboarding
    ? t('employee.table.modal.subtitle.offboard')
    : t('employee.table.modal.subtitle.remove');

  if (usersList.length === 0 && isUsersListLoading) {
    return <Spinner />;
  }

  const webColumns = isOffboarding
    ? OFFBOARDING_EMPLOYEES_FIELDS
    : DEFAULT_EMPLOYEES_FIELDS(isManager);
  const mobileColumns = isOffboarding
    ? MOBILE_OFFBOARDING_EMPLOYEES_FIELDS
    : MOBILE_EMPLOYEES_FIELDS(isManager);

  return (
    <>
      {!isOpenActivateModal && (
        <>
          {isLoadingUser && <Backdrop />}
          {!isOffboarding && <EmployeesTableHeader count={countOfActiveUsers} />}

          {usersList.length > 0 && (
            <Table<EmployeesTableFields, string>
              isLoading={isUsersListLoading}
              columns={isMobile ? mobileColumns : webColumns}
              data={usersList}
              headerItemSx={headerItemSx}
              lastListRef={lastListRef}
              onClickRow={handleRowClick}
            />
          )}

          {usersList.length && isFetchingNextPage && (
            <ProgressW>
              <CircularProgress color="info" size={60} />
            </ProgressW>
          )}
        </>
      )}

      <ConfirmDialog
        sx={{ '.MuiDialog-paper': { paddingBottom: '56px' } }}
        title={confirmDialogTitle}
        subTitle={confirmDialogCloseText}
        open={isOpenModal}
        onClose={handleCloseModal}
        onSubmit={onDeactivateEmployee}
        isLoading={false}
        isContentOverflow
        disableEnforceFocus
      >
        {isOffboarding &&
          (deleteEmployee?.status == UserStatus.Active ||
            deleteEmployee?.status == UserStatus.Registered) && (
            <RichEditorW>
              <RichEditor
                placeholder={t('employee.table.modal.stateReason')}
                maxLength={DEACTIVATE_USER_COMMENT_MAX_LENGTH}
                errorText={deletedCommentError}
                onChange={setDeletedComment}
                onFocus={onHandleFocusRichEditor}
                onBlur={onHandleFocusRichEditor}
                isModal
              />
            </RichEditorW>
          )}
      </ConfirmDialog>

      {user && isOpenActivateModal && (
        <EditEmployeeForm
          open={!!user && isOpenActivateModal}
          onClose={handleCloseActivateModal}
          employee={user}
          key={user.id}
          formTitle={t('activateEmployee.formTitle')}
          mode="activate"
        />
      )}
    </>
  );
}
