import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES } from 'constants/index';

export function useNotificationUnreadQuery() {
  const { data, isLoading } = useQuery([QUERIES.NOTIFICATIONS_UNREAD], () =>
    API_HANDLERS.NOTIFICATIONS.COUNTER(),
  );
  return {
    isLoading,
    amount: data?.data ? data.data.amount : 0,
  };
}
