import { TextFieldProps } from '@mui/material/TextField';
import { ReactElement, useState } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';

import { BaseTextField } from '../BaseTextField';
import { InputHint } from './styled';

export type BaseTextFieldControllerProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> & {
    maxLength?: number;
    format?: (value: string) => unknown;
    preventSpaceOnStart?: boolean;
    hint?: string;
  };

export function BaseTextFieldController<T extends FieldValues>({
  control,
  disabled,
  name,
  rules,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  format,
  maxLength,
  preventSpaceOnStart = false,
  hint,
  ...otherProps
}: BaseTextFieldControllerProps<T>): ReactElement {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const [isFocused, setIsFocused] = useState(false);

  function setIsActive() {
    setIsFocused(true);
  }

  return (
    <>
      <BaseTextField
        fullWidth
        disabled={disabled}
        onChange={(...args) => {
          if (preventSpaceOnStart && value === '' && args[0].target.value === ' ') {
            return;
          }
          onChange(format ? format(args[0].target.value) : args[0].target.value);
          externalOnChange?.(...args);
        }}
        onBlur={(...args) => {
          onBlur();
          externalOnBlur?.(...args);
          setIsFocused(false);
        }}
        onFocus={setIsActive}
        value={value}
        name={name}
        inputRef={ref}
        error={Boolean(error)}
        helperText={error && error.message}
        autoComplete="off"
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{
          maxLength,
        }}
        {...otherProps}
      />
      {hint && isFocused && <InputHint>{hint}</InputHint>}
    </>
  );
}
