import { ChangeEvent, ReactElement } from 'react';
import { Control, Controller, UseFormGetValues, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseTextField, FormGroup } from 'components';
import { EQUIPMENT_FORM_FIELDS_LENGTH } from 'constants/index';
import { useWindowSize } from 'hooks';
import { textFieldMobileStyles, textFieldStyles } from 'styles';
import { EquipmentFormValues } from 'types';
import { REQUIRED } from 'validations';

import { initialWindowSizeProps } from '../constants';
import { customFieldNameRules, customFieldValueRules } from '../EquipmentForm/validation';
import { useCustomFields } from './hooks';
import { FieldsW } from './styled';
import { inputStyles } from './styles';

interface CustomFieldsProps {
  isSubmitted: boolean;
  control: Control<EquipmentFormValues>;
  getValues: UseFormGetValues<EquipmentFormValues>;
  setError: UseFormSetError<EquipmentFormValues>;
}

export function CustomFields({
  isSubmitted,
  control,
  getValues,
  setError,
}: CustomFieldsProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize(initialWindowSizeProps);
  const { fields, customFields, handleFieldChange } = useCustomFields({
    isSubmitted,
    control,
    getValues,
    setError,
  });

  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;
  return (
    <FormGroup marginBottom={isMobile ? 16 : 24}>
      {fields.map(({ key, value, id }, index) => {
        return (
          <FieldsW key={id}>
            <Controller
              render={({ field: { onChange, ...field }, fieldState: { error } }) => {
                const isRequired = !!customFields[index].value && !field.value;
                const isError = isRequired || !!error;

                return (
                  <BaseTextField
                    placeholder={t('equipments.create.form.placeholders.fieldName')}
                    sx={fieldStyles}
                    InputProps={{
                      disableUnderline: true,
                      sx: inputStyles,
                    }}
                    inputProps={{
                      maxLength: EQUIPMENT_FORM_FIELDS_LENGTH.customFieldName.max,
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onChange(e);
                      handleFieldChange(e, index, 'key');
                    }}
                    error={isError}
                    helperText={isRequired && REQUIRED}
                    required={isRequired}
                    {...field}
                  />
                );
              }}
              name={`customFields.${index}.key`}
              control={control}
              defaultValue={key}
              rules={customFieldNameRules}
            />
            <Controller
              render={({ field: { onChange, ...field }, fieldState: { error } }) => {
                const isRequired = !!customFields[index].key && !field.value;
                const isError = isRequired || !!error;

                return (
                  <BaseTextField
                    placeholder={t('equipments.create.form.placeholders.fieldValue')}
                    sx={fieldStyles}
                    InputProps={{
                      disableUnderline: true,
                      sx: inputStyles,
                    }}
                    inputProps={{
                      maxLength: EQUIPMENT_FORM_FIELDS_LENGTH.customFieldValue.max,
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onChange(e);
                      handleFieldChange(e, index, 'value');
                    }}
                    error={isError}
                    helperText={isRequired && REQUIRED}
                    required={isRequired}
                    {...field}
                  />
                );
              }}
              name={`customFields.${index}.value`}
              control={control}
              defaultValue={value}
              rules={customFieldValueRules}
            />
          </FieldsW>
        );
      })}
    </FormGroup>
  );
}
