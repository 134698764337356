import { styled } from '@mui/material';

export const FormW = styled('div')<{ isManager: boolean }>`
  display: flex;
  flex-direction: column;

  width: ${({ isManager }) => (isManager ? '739px' : '671px')};
  margin-right: 24px;
  padding: 32px 24px;
  /* backdrop-filter: blur(20px); */
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-image-source: linear-gradient(176.57deg, #ffffff 0%, rgba(255, 255, 255, 0) 32.85%),
    linear-gradient(338.17deg, rgba(0, 0, 0, 0.15) -53.57%, rgba(0, 0, 0, 0) 85.58%);
  @media (max-width: 1023px) {
    padding: 16px 8px;
    width: 100%;
    max-width: 671px;
    margin-right: 0px;
  }
`;

export const ListW = styled('div')`
  padding-right: 10px;
  @media (max-width: 1023px) {
    padding-right: 0px;
  }
`;

export const FieldsW = styled('div')<{ isManager: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ isManager }) => (isManager ? '451px 1fr' : '1fr')};

  gap: 8px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;
