import { AuthPageWrapper, AuthPageTitle } from 'features/auth';
import { AuthPageLink, RegisterCompanyFormSteps } from 'types';

import { FormStepper, PersonalDataForm, CompanyProfileForm } from '../../components';
import { useRegistrationPageLogic } from './hooks';

export function RegisterCompanyPage() {
  const {
    step,
    setStep,
    pageTitle,
    personalDataValues,
    companyProfileValues,
    setPersonalDataValues,
    setCompanyProfileValues,
    handleRegisterSubmit,
    isLoading,
    errors,
  } = useRegistrationPageLogic();

  return (
    <AuthPageWrapper link={AuthPageLink.signIn} isLoading={isLoading}>
      <AuthPageTitle>{pageTitle}</AuthPageTitle>
      <FormStepper step={step} setStep={setStep} />
      {step === RegisterCompanyFormSteps.PERSONAL_DATA ? (
        <PersonalDataForm
          setStep={setStep}
          defaultValues={personalDataValues}
          storeValues={setPersonalDataValues}
          errors={errors}
        />
      ) : (
        <CompanyProfileForm
          defaultValues={companyProfileValues}
          storeValues={setCompanyProfileValues}
          handleRegisterSubmit={handleRegisterSubmit}
          errors={errors}
        />
      )}
    </AuthPageWrapper>
  );
}
