import { UserDetailsResponse } from 'api/types';
import { NotificationType as NotificationTypeEnum, Notification } from 'types';

export function getUserFromNotification(notification: Notification): UserDetailsResponse | null {
  switch (notification.type) {
    case NotificationTypeEnum.assigned:
      return notification.payload.assigned_by;
    case NotificationTypeEnum.post_comment:
      return notification.payload.posted_by;
    case NotificationTypeEnum.task_comment:
      return notification.payload.posted_by;
    case NotificationTypeEnum.welcome:
      return null;
    case NotificationTypeEnum.edited:
      return notification.payload.edited_by;
    case NotificationTypeEnum.removed:
      return notification.payload.deleted_by;
    case NotificationTypeEnum.completed:
      return notification.payload.completed_by;
    case NotificationTypeEnum.birthday:
      return notification.payload.user;
    case NotificationTypeEnum.birthday_today:
      return notification.payload.user;
    case NotificationTypeEnum.due:
      return null;
    case NotificationTypeEnum.custom:
      return null;
    case NotificationTypeEnum.equipment_completed:
      return null;
    case NotificationTypeEnum.equipment_expires_soon:
      return null;
    case NotificationTypeEnum.post_new:
      return notification.payload.posted_by;
    case NotificationTypeEnum.poll_new:
      return notification.payload.posted_by;
    default:
      throw new Error('Unknown notification type');
  }
}
