import { CustomFieldItem } from 'types';

export const getEmptyField = (fields: CustomFieldItem[]) => {
  const emptyField = fields.find(({ key, value }) => !key && !value);
  const emptyFieldIndex = (emptyField && fields.indexOf(emptyField)) ?? -1;
  const isEmptyField =
    emptyFieldIndex >= 0 && !fields[emptyFieldIndex].key && !fields[emptyFieldIndex].value;

  return { isEmptyField, emptyFieldIndex };
};
