import { IconButton, styled, Typography } from '@mui/material';

export const HeaderW = styled('div')`
  margin-bottom: 48px;
  display: flex;
  align-items: flex-start;
  padding-top: 25px;

  @media (max-width: 1023px) {
    padding-top: 0px;
    margin-bottom: 16px;
  }
`;

export const PageTitle = styled(Typography)`
  font-weight: 700;
  font-size: 70px;
  line-height: 70px;
  color: ${({ theme }) => theme.palette.primary.p080};
  white-space: pre-wrap;

  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    &.invitation {
      font-weight: 700;
      font-size: 36px;
      line-height: 35px;
      margin-bottom: 8px;
    }
  }
`;

interface CloseButtonWProps {
  buttonPosition: 'fixed' | 'static' | 'relative' | 'absolute';
}

export const CloseButtonW = styled(IconButton, {
  shouldForwardProp: (propname) => propname !== 'buttonPosition',
})<CloseButtonWProps>(({ buttonPosition, theme }) => ({
  marginLeft: 'auto',
  position: buttonPosition,
  top: '79px',
  right: '88px',
  [theme.breakpoints.down('desktop')]: {
    position: 'static',
    paddingTop: '0px',
    svg: {
      width: '24px',
      height: '24px',
      marginRight: '8px',
    },
  },
}));
