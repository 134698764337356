import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';

import { PublishPostRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import * as ERROR_CODES from 'constants/errorCodes';
import { ApiErrors } from 'types';

interface UseEditPostMutationReturn {
  editPostMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    PublishPostRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseEditPostMutationProps {
  successText: string;
  invalidateQueries?: string[];
  setShouldReset?: (value: boolean) => void;
  setIsSuccess?: Dispatch<SetStateAction<boolean>>;
}

export function useEditPostMutation({
  successText,
  invalidateQueries,
  setShouldReset,
  setIsSuccess,
}: UseEditPostMutationProps): UseEditPostMutationReturn {
  const { mutateAsync: editPostMutation, isLoading } = useMutation(API_HANDLERS.POST.EDIT, {
    onSuccess: () => {
      setIsSuccess && setIsSuccess(true);
      alert.success(successText);

      if (invalidateQueries) {
        queryClient.invalidateQueries(invalidateQueries);
      }
    },

    onError: (error: AxiosError<ApiErrors>) => {
      if (error.response?.data.errors[0].code === ERROR_CODES.UPDATE_NOT_ALLOWED) {
        setShouldReset?.(true);
      }
    },
  });

  return {
    editPostMutation,
    isLoading,
  };
}
