import { ReactElement } from 'react';

import { PostListItem } from 'api';
import { Spinner } from 'components';
import { Links } from 'settings';
import { CategoriesListType, InViewItemRef } from 'types';

import { DocumentItem } from '../DocumentItem';
import { EmptyState } from '../EmptyState';
import { ListW } from './styled';
import { spinnerSx } from './styles';

interface DocumentsListProps {
  isEmptyList: boolean;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  documents: PostListItem[];
  lastItemRef: InViewItemRef;
  isPrivate: boolean;
  tag?: string;
  categoryMap: CategoriesListType;
}

export function DocumentsList({
  documents,
  isLoading,
  isEmptyList,
  isFetchingNextPage,
  lastItemRef,
  isPrivate,
  tag,
  categoryMap,
}: DocumentsListProps): ReactElement {
  if (isLoading) {
    return <Spinner />;
  }

  if (!isLoading && isEmptyList) {
    return <EmptyState linkToCreateDocument={Links.we.createDocument} />;
  }

  return (
    <ListW>
      {documents.map((document, idx) => (
        <DocumentItem
          key={document.id}
          {...document}
          tag={isPrivate ? undefined : tag}
          lastItemRef={idx === documents.length - 1 ? lastItemRef : undefined}
          categoryMap={categoryMap}
        />
      ))}
      {isFetchingNextPage && <Spinner wrapperSx={spinnerSx} />}
    </ListW>
  );
}
