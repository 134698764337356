export enum QuickLinkAccess {
  public = 'public',
  private = 'private',
}

export interface QuickLink {
  id: string;
  title: string;
  icon: string;
  url: string;
  created_at: string;
  author_id: string;
  access?: QuickLinkAccess;
}

export interface QuickLinkFormValues {
  title: string;
  icon: string;
  url: string;
  access: QuickLinkAccess | boolean;
}
