import { styled } from '@mui/material';

export const MoreVideos = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  margin: 24px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 300px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.white.main};
  @media (max-width: 1023px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
  }
`;
