import { httpClient } from '../httpClient';
import { AxiosResponseSuccess, ClientConfig } from '../types';

export async function makeHttpRequest<SuccessPayload>(
  config: ClientConfig,
): AxiosResponseSuccess<SuccessPayload> {
  const { headers, authorizeRequest = true } = config;
  const headersToSend = { ...headers };

  if (authorizeRequest) {
    const currentToken = localStorage.getItem('accessToken');
    headersToSend['Access-Token'] = `${currentToken}`;
  }

  return httpClient.request<SuccessPayload>({
    ...config,
    headers: {
      ...headersToSend,
    },
  });
}
