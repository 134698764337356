import { css, styled } from '@mui/material';

export const SectionW = styled('div')<{ isFullView: boolean }>`
  width: 100%;
  height: fit-content;

  ${({ isFullView }) =>
    isFullView &&
    css`
      width: 308px;
      padding: 24px;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
      flex-shrink: 0;

      @media (max-width: 1023px) {
        width: 100%;
        box-sizing: border-box;
        padding: 16px;
        background-color: transparent;
        backdrop-filter: unset;
      }
    `}
`;

export const AddButton = styled('div')<{ disabled?: boolean }>`
  width: 100%;
  height: 24px;
  padding: 0px 24px;
  gap: 8px;
  display: flex;
  align-items: center;
  margin: 16px 0;
  cursor: pointer;

  ${({ disabled }) => css`
    opacity: ${disabled && '0.4'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;

export const Title = styled('p')`
  color: ${({ theme }) => theme.palette.info.main};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const EmptyStateW = styled('div')`
  margin-top: 24px;

  @media (max-width: 1023px) {
    margin-top: 16px;
  }
`;

export const AccomplishedLabel = styled('p')`
  color: #6b7280;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin: 16px 0;
`;
