import { styled, IconButton } from '@mui/material';

import { ReactComponent as DownloadIcon } from 'assets/icons/downloadFile.svg';

export const DocumentW = styled('div')<{ canDownload: boolean }>(({ theme, canDownload }) => ({
  display: 'flex',
  height: '48px',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: '13px',
  borderRadius: '60px',
  width: '90%',
  marginRight: '5px',

  ...(canDownload && {
    '&:hover': {
      background: theme.palette.info.main,

      p: {
        color: theme.palette.white.main,
      },

      '.download-icon, .download-icon-wrapper': {
        display: 'flex',
        height: '48px',
      },

      '.file-icon, .file-name': {
        display: 'none',
      },
    },
  }),
}));

export const Wrapper = styled('div')`
  display: none;
  overflow: hidden;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
`;
export const OuterW = styled('div')`
  display: flex;
`;

export const DownloadIconW = styled(DownloadIcon)`
  width: 21px;
  height: 21px;
  display: none;
  margin-left: 10px;
`;

export const DocumentNameW = styled('p')`
  flex: 1;
  height: 48px;
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
  border-radius: 60px;
  padding: 10px 10px;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
  @media (max-width: 1023px) {
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    padding: 15px 20px;
    line-height: 24px;
    height: 48px;
  }
`;

export const DocumentNameWIn = styled('p')`
  display: flex;
  height: 48px;
  box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
  border-radius: 60px;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 18px 20px 18px 16px;
    height: unset;
  }
`;

export const RemoveDocBtn = styled(IconButton)`
  width: 40px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.white.main};
  svg {
    color: ${({ theme }) => theme.palette.info.main};
    width: 23px;
    height: 23px;
    opacity: 'unset';
  }
  &.download-button {
    svg {
      width: 35px;
      height: 35px;
    }
  }
  @media (max-width: 1023px) {
    height: unset;
    width: unset;
    background-color: ${({ theme }) => theme.palette.transparency};
    padding: 0px 8px;
    svg {
      color: ${({ theme }) => theme.palette.info.main};
      width: 16px;
      height: 16px;
    }
    &.download-button {
      svg {
        width: 30px;
        height: 24px;
      }
    }
  }
`;
