import { styled } from '@mui/material';

export const CardsW = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  @media (max-width: 1023px) {
    &.expanded {
      grid-template-columns: repeat(1, 1fr);
    }
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
  }
`;
