import { RegisterOptions } from 'react-hook-form';

import { getMaxLengthText, getMinLengthText } from 'validations';

export const COMMENT_MIN_LENGTH = 1;
export const COMMENT_MAX_LENGTH = 200;

export const commentFieldRules: RegisterOptions = {
  minLength: {
    value: COMMENT_MIN_LENGTH,
    message: getMinLengthText(COMMENT_MIN_LENGTH),
  },
  maxLength: {
    value: COMMENT_MAX_LENGTH,
    message: getMaxLengthText(COMMENT_MAX_LENGTH),
  },
};
