import { ReactElement } from 'react';

import { DocumentCategoryIcons } from 'constants/DocumentCategoryIcons';

import { IconLabelW, LabelW, StyledTagLabel } from './styled';

interface TagLabelProps {
  icon?: string;
  identify: string;
  label?: string;
  type?: string;
}

export const CustomSelectedValue = ({
  icon,
  identify,
  label,
  type,
}: TagLabelProps): ReactElement => {
  switch (identify) {
    case 'tags': {
      const Icon = icon ? DocumentCategoryIcons[icon.toLowerCase()] : null;
      return (
        <StyledTagLabel>
          <span>{Icon}</span>
          {label}
        </StyledTagLabel>
      );
    }
    case 'icon': {
      return <IconLabelW>{label}</IconLabelW>;
    }
    default: {
      return <LabelW type={type}>{label}</LabelW>;
    }
  }
};
