import { useForm, Control } from 'react-hook-form';

import { SignInFormValues } from 'types';

import { defaultValues } from './config';
import { useLoginMutation } from './useLoginMutation';

interface UseSignInFormReturn {
  handleFormSubmit: () => void;
  control: Control<SignInFormValues>;
  isLoading: boolean;
}

export function useSignInForm(): UseSignInFormReturn {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignInFormValues>({
    defaultValues,
    mode: 'onBlur',
  });

  const { loginMutation, isLoading } = useLoginMutation();

  const handleFormSubmit = handleSubmit(async (data) => {
    await loginMutation(data);
  });

  return { control, handleFormSubmit, isLoading: isSubmitting || isLoading };
}
