import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router-dom';

import { usePostDetails } from 'api/queries/usePostDetails';
import { alert } from 'components';
import { Links } from 'settings';

interface UseOnPostRedirectProps {
  id: string;
  type: string;
  clearId: () => void;
}

export function useOnPostRedirect({ id, type, clearId }: UseOnPostRedirectProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isError, isFetched } = usePostDetails({ postId: id, queryName: 'post-in-notifications' });

  if (isFetched) {
    if (!isError) {
      if (type === 'task') {
        navigate(
          generatePath(Links.we.taskManagement.taskDetails, {
            taskId: id,
          }),
        );
      }
      if (type === 'post' || type === 'poll') {
        navigate(
          generatePath(Links.we.posts.index, {
            postId: id,
          }),
        );
      }
    } else {
      if (type === 'task') {
        alert.error(t('toasts.codes.taskWasDeleted'));
      }
      if (type === 'post') {
        alert.error(t('toasts.codes.postWasDeleted'));
      }
      if (type === 'equipment') {
        alert.error(t('toasts.codes.equipmentWasDeleted'));
      }
      if (type === 'poll') {
        alert.error(t('toasts.codes.pollWasDeleted'));
      }

      clearId();
    }
  }
}
