import { styled } from '@mui/material';

export const FieldsW = styled('div')`
  position: relative;

  &:not(last-of-type) {
    margin-bottom: 24px;
  }

  @media (max-width: 1023px) {
    &:not(last-of-type) {
      margin-bottom: 16px;
    }
  }
`;
