import { Button, styled } from '@mui/material';

export const NameCellW = styled('span')`
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    margin: 0 8px;
  }
`;

export const NameW = styled('span')`
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  word-break: initial;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    word-break: initial;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ActionBtn = styled(Button)`
  text-transform: none;
  background: ${({ theme }) => theme.palette.white.main};
  border: ${({ theme }) => `1px solid ${theme.palette.info.main}`};
  color: ${({ theme }) => theme.palette.info.main};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  box-shadow: none;
  padding: 7px 16px;
  min-width: 100px;

  & .span-in {
    color: ${({ theme }) => theme.palette.primary.p040};
    margin: 0 8px;
  }

  @media (max-width: 1023px) {
    margin-left: 8px;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 10px;
    margin: 0;
    min-width: unset;
    min-height: unset;
    margin-left: auto;
  }
`;

export const PositionDepartmentText = styled('span')`
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1023px) {
    margin-top: 2px;
  }
`;

export const SpanIn = styled('span')`
  color: ${({ theme }) => theme.palette.primary.p040};
`;

export const RemoveButton = styled(ActionBtn)`
  margin-right: 8px;
  &:hover {
    background: ${({ theme }) => theme.palette.white.main};
  }

  @media (max-width: 1512px) {
    margin-bottom: 8px;
    margin-right: 0;
  }
`;

export const ButtonsW = styled('span')`
  display: flex;
  align-items: center;

  @media (max-width: 1512px) {
    flex-direction: column;
  }
`;

export const ActivateButton = styled(ActionBtn)`
  background: ${({ theme }) => theme.palette.info.main};
  color: ${({ theme }) => theme.palette.white.main};
  position: relative;
  min-height: 40px;
`;

export const Email = styled('div')`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OffboardButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  box-shadow: none;
  padding: 8px 22px;
  white-space: nowrap;

  @media (max-width: 1023px) {
    font-size: 12px;
    line-height: 14px;
    padding: 3px 7px;
  }
`;

export const AdressW = styled('span')`
  word-break: initial;
`;
