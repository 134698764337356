import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useCategoriesListQuery, usePostsListQuery } from 'api';
import { Spinner } from 'components';
import { POSTS_REQUEST_LIMIT } from 'constants/index';
import { CategoriesListType, InstanceType } from 'types';

import { EmptyState } from '../EmptyState';
import { PostListItem } from '../PostListItem';
import { ListTitle, ListW, SpinnerContainer } from './styled';

export function PostsList(): ReactElement {
  const { t } = useTranslation();
  const { posts, isLoading, lastItemRef, isFetchingNextPage, isEmptyList } = usePostsListQuery({
    type: [InstanceType.news, InstanceType.document, InstanceType.polls],
    limit: POSTS_REQUEST_LIMIT,
    include_reactions: true,
  });

  const {
    categoryMap,
    isLoading: isCategoryListLoading,
  }: { categoryMap: CategoriesListType; isLoading: boolean } = useCategoriesListQuery();

  if (isLoading && isCategoryListLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <ListW>
      <ListTitle>{t('we.posts.list.title')}</ListTitle>
      {!isLoading && posts.length === 0 && <EmptyState />}

      {!isEmptyList &&
        posts.map((post, idx) => (
          <PostListItem
            key={post.id}
            lastItemRef={idx === posts.length - 1 ? lastItemRef : undefined}
            categoryMap={categoryMap}
            {...post}
          />
        ))}

      {isFetchingNextPage && <Spinner wrapperSx={{ bottom: '0', top: 'unset' }} />}
    </ListW>
  );
}
