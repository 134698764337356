import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { Description, EmptyStateIconW, EmptyStateW, NavLinkW } from './styled';

interface AchievementEmptyStateProps {
  addAchievementLink: string;
}

export function AchievementEmptyState({
  addAchievementLink,
}: AchievementEmptyStateProps): ReactElement {
  return (
    <EmptyStateW>
      <EmptyStateIconW />
      <Description>
        <Trans i18nKey="career.achievements.emptyState">
          Nothing is here yet.
          <NavLinkW to={addAchievementLink}>Create a goal</NavLinkW> to place sub-goals in progress!
        </Trans>
      </Description>
    </EmptyStateW>
  );
}
