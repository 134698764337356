import { TextareaAutosizeProps } from '@mui/base';

import { StyledTextArea, ErrorText } from './styled';

interface TextAreaPros extends TextareaAutosizeProps {
  errorText?: string;
}

export const TextArea = ({ errorText, ...props }: TextAreaPros) => {
  return (
    <>
      {errorText && <ErrorText>{errorText}</ErrorText>}
      <StyledTextArea {...props} />
    </>
  );
};
