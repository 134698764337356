import { useTranslation } from 'react-i18next';

import { TargetFormValues } from 'types';
import { removeExtraSpaces } from 'utils';

import { useCreateTargetMutation } from './useCreateTargetMutation';

export function useCreateTargetForm() {
  const { t } = useTranslation();

  const { createTargetMutation, isLoading } = useCreateTargetMutation({
    successText: t('toasts.target.success.create'),
  });

  async function handleSubmit(data: TargetFormValues) {
    const { value, target, title } = data;
    const titleValue = removeExtraSpaces(title);
    await createTargetMutation({
      ...data,
      value: Number(value),
      target: Number(target),
      title: titleValue,
    });
  }

  return {
    handleSubmit,
    isLoading,
  };
}
