import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { DialogProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  CloseButton,
  DialogContent,
  ContentW,
  FooterNote,
  ContinueBtn,
  LinkW,
} from './styled';

interface StartSubscriptionDialogProps extends DialogProps {
  onClose: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  children?: ReactNode;
  withConfirmBtn?: boolean;
}

export function StartSubscriptionDialog({
  title,
  onClose,
  onSubmit,
  open,
  isLoading,
  children,
  withConfirmBtn = true,
  ...otherProps
}: StartSubscriptionDialogProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Dialog open={open} {...otherProps}>
      <div>
        <CloseButton
          variant="text"
          startIcon={<KeyboardBackspaceRoundedIcon fontSize="large" />}
          onClick={onClose}
        >
          {t('subscriptions.modals.startSubscription.title')}
        </CloseButton>
      </div>

      <DialogContent>
        <ContentW>{t('subscriptions.modals.startSubscription.details')}</ContentW>

        {withConfirmBtn && (
          <DialogActions>
            <FooterNote>
              <Trans i18nKey="subscriptions.modals.startSubscription.footerNote">
                I have carefully read the rules above & the
                <LinkW
                  href={process.env.PUBLIC_URL + '/Adligo_Terms&Conditions.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                >
                  Terms & Conditions
                </LinkW>{' '}
                and accept them
              </Trans>
            </FooterNote>
            <ContinueBtn color="info" variant="contained" onClick={onSubmit} disabled={isLoading}>
              {t('subscriptions.modals.startSubscription.submit')}
            </ContinueBtn>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
}
