import { Target } from 'types';

export function sortTargetByStatus(targets: Target[]) {
  const activeTargets: Target[] = [];
  const completedTargets: Target[] = [];

  if (targets.length === 0) {
    return { activeTargets, completedTargets };
  }

  for (let i = 0; i < targets.length; i++) {
    if (targets[i].completed_at) {
      completedTargets.push(targets[i]);
    } else {
      activeTargets.push(targets[i]);
    }
  }

  return { activeTargets, completedTargets };
}
