import { styled, DialogTitle as DialogTitleMUI, Typography } from '@mui/material';

export const DialogTitle = styled(DialogTitleMUI)(({ theme }) => ({
  color: theme.palette.primary.p080,
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '24px',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: '32px',

  svg: {
    marginRight: '24px',
  },
  [theme.breakpoints.down('desktop')]: {
    margin: '16px 0 16px 10px',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
  },
}));

export const GroupTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '24px',
  color: theme.palette.primary.p080,
  marginBottom: '24px',
  [theme.breakpoints.down('desktop')]: {
    marginBottom: '8px',
  },
}));

export const ModalOverlay = styled('div')`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);

  @media (max-width: 1023px) {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: none;
  }
`;

export const ModalContent = styled('div')`
  width: 746px;
  height: auto;
  padding: 32px 24px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin: 97px auto;

  @media (max-width: 1023px) {
    margin: 0 10px 16px 10px;
    width: auto;
    padding: 16px 8px 90px;
  }
`;
