import { styled } from '@mui/material';

import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';

export const PageW = styled('div')`
  padding-left: 88px;

  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 16px;
  }
`;

export const EmptyStateW = styled('div')`
  position: relative;
  width: 100%;
  min-height: 325px;
  padding: 24px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    min-height: 258px;
    padding: 16px;
  }
`;

export const ContentW = styled('div')`
  margin-top: 32px;
  width: fit-content;
  min-width: 100%;

  @media (max-width: 1023px) {
    margin-top: 16px;
    width: 100%;
  }
`;

export const Header = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
`;

export const StyledAddIcon = styled(AddIcon)`
  opacity: 0.4;
`;
