import { Button, styled } from '@mui/material';

export const FlexW = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-top: 35px;
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-left: 328px;
  @media (max-width: 1023px) {
    display: none;
  }
`;
