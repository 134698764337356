export interface CompanyPersonalDataFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface CompanyInfoFormValues {
  companyName: string;
  industry: string;
  companySize: number;
  number: string;
}

export enum RegisterCompanyFormSteps {
  PERSONAL_DATA = 'personalData',
  COMPANY_PROFILE = 'companyProfile',
}

export enum CompanySize {
  One = '1',
  TwoToFive = '2-5',
  SixToTen = '6-10',
  ElevenToFifteen = '11-15',
  SixteenToTwenty = '16-20',
  TwentyOneToTwentyFive = '21-25',
  TwentySixToTwentyFive = '26-30',
  ThirtyOneTo = '31-35',
  ThirtySizToForty = '36-40',
  FortyOneToFortyFive = '41-45',
  FortySixToFifty = '46-50',
  FiftyPlus = '50+',
}

export enum CompanyStatus {
  active = 'active',
  blocked = 'blocked',
}

export interface Company {
  id: string;
  name: string;
  industry: string;
  size: number;
  vat: string;
  company_number: string;
  address_1: string;
  address_2: string;
  city: string;
  region: string;
  postcode: string;
  country: string;
  email: string;
  support_email: string;
  website: string;
  country_code: string | number;
  phone: string;
  status: CompanyStatus;
  created_at: string;
  updated_at: string;
}

export interface CompanyDetails {
  id: string;
  created_at: string;
  updated_at: string;
  status: string;
  name: string;
  industry: string;
  size: number;
  company_number: string;
  have_active_manager: boolean;
}

export type EditCompanyFormValues = Partial<Company>;
