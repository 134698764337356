import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRequestEquipmentMutation } from 'api';
import { EquipmentFormValues } from 'types';

import { formValuesAdapter } from '../adapter';

export function useRequestEquipmentForm() {
  const navigate = useNavigate();
  const { requestEquipmentMutation, isLoading, isSuccess } = useRequestEquipmentMutation({});

  const handleSubmit = useCallback(
    async (data: EquipmentFormValues, attachmentIds: string[]) => {
      await requestEquipmentMutation({
        data: {
          ...formValuesAdapter(data),
          ...(!!attachmentIds.length && { attachment_ids: attachmentIds }),
        },
        userId: data.user,
      });
    },
    [requestEquipmentMutation],
  );

  useEffect(() => {
    isSuccess && navigate(-1);
  }, [isSuccess, navigate]);

  return {
    handleSubmit,
    isLoading,
  };
}
