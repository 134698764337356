import {
  DialogTitle as DialogTitleMUI,
  Dialog as DialogMUI,
  DialogActions as DialogActionsMUI,
  DialogContent as DialogContentMUI,
  styled,
  Button,
} from '@mui/material';

export const DialogTitle = styled(DialogTitleMUI)(({ theme }) => ({
  padding: '0px 10px 0px 48px',
  fontWeight: '700',
  fontSize: '48px',
  lineHeight: '64px',
  marginBottom: '33px',
  wordBreak: 'break-word',
  whiteSpace: 'break-spaces',
  marginTop: '26px',

  [theme.breakpoints.down('desktop')]: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    padding: 0,
    marginTop: '24px',
    marginBottom: '16px',
  },
}));

export const DialogActions = styled(DialogActionsMUI)(({ theme }) => ({
  padding: 0,
  'button:not(:first-of-type)': {
    marginLeft: '48px',
  },
  [theme.breakpoints.down('desktop')]: {
    marginTop: '126px',
    'button:not(:first-of-type)': {
      marginLeft: '32px',
    },
  },
}));

export const DialogContent = styled(DialogContentMUI)<{ isContentOverflow?: boolean }>`
  padding-left: 48px;
  padding-right: 0px;
  overflow-y: ${({ isContentOverflow }) => (isContentOverflow ? 'visible' : 'auto')};
  @media (max-width: 1023px) {
    padding-left: 0px;
  }
`;

export const Dialog = styled(DialogMUI)(({ theme }) => ({
  '.MuiDialog-paper': {
    maxWidth: '746px',
    padding: '24px 72px 72px 24px',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(20px)',
    borderRadius: '15px',
  },

  '.MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
  },
  [theme.breakpoints.down('desktop')]: {
    '.MuiDialog-paper': {
      padding: '16px 16px 32px',
      marginBottom: 'auto',
    },
  },
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  textTransform: 'none',
  fontSize: '24px',
  lineHeight: '24px',
  color: theme.palette.primary.main,

  '.MuiButton-startIcon': {
    fontSize: '2rem',
    marginRight: '24px',
  },

  '.MuiSvgIcon-root': {
    width: '1.5em',
    height: '1.5em',
  },
  [theme.breakpoints.down('desktop')]: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
  },
}));
