import { Box, BoxProps, CircularProgress, CircularProgressProps } from '@mui/material';
import { ReactElement } from 'react';

interface SpinnerProps extends CircularProgressProps {
  wrapperSx?: BoxProps['sx'];
}

export function Spinner({ size = '5rem', wrapperSx, color = 'info' }: SpinnerProps): ReactElement {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        ...wrapperSx,
      }}
    >
      <CircularProgress color={color} size={size} />
    </Box>
  );
}
