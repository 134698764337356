import { useUsersListQuery } from 'api';
import { EMPLOYEES_PREVIEW_LIMIT_RESOURCES_PAGE } from 'constants/index';
import { User, UserStatus } from 'types';

interface UseResourcesPageLogicReturn {
  isUsersListLoading: boolean;
  usersList: User[];
}

export function useResourcesPageLogic(): UseResourcesPageLogicReturn {
  const { isLoading, usersList } = useUsersListQuery({
    limit: EMPLOYEES_PREVIEW_LIMIT_RESOURCES_PAGE,
    status: [UserStatus.Active, UserStatus.Registered],
    rand: true,
  });

  return {
    isUsersListLoading: isLoading,
    usersList,
  };
}
