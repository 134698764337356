import i18n from 'i18n';
import { EquipmentCategory, EquipmentTab, EquipmentTabsValues } from 'types';

export const categoryOptions = [
  { value: EquipmentCategory.HARDWARE, label: i18n.t('equipments.categories.hardware') },
  { value: EquipmentCategory.SOFTWARE, label: i18n.t('equipments.categories.software') },
  { value: EquipmentCategory.LICENSE, label: i18n.t('equipments.categories.license') },
  { value: EquipmentCategory.SUBSCRIPTION, label: i18n.t('equipments.categories.subscription') },
  { value: EquipmentCategory.PERMISSION, label: i18n.t('equipments.categories.permission') },
  { value: EquipmentCategory.CERTIFICATION, label: i18n.t('equipments.categories.certification') },
  { value: EquipmentCategory.COMPETENCE, label: i18n.t('equipments.categories.competence') },
];

export const AllOption = {
  label: i18n.t('notifications.create.form.allOption'),
  value: 'all',
};

export const createEquipmentsTabs: EquipmentTab[] = [
  {
    label: i18n.t('equipments.tabs.register'),
    value: EquipmentTabsValues.REGISTER,
  },
  {
    label: i18n.t('equipments.tabs.request'),
    value: EquipmentTabsValues.REQUEST,
  },
];

export const initialWindowSizeProps = {
  initialWidth: window.innerWidth,
  initialHeight: window.innerHeight,
};
