import { RegisterOptions } from 'react-hook-form';

import { POST_FIELDS_LENGTH } from 'constants/index';
import { getMaxLengthText, getMinLengthText, required } from 'validations';

export const titleFieldRules: RegisterOptions = {
  ...required,
  minLength: {
    value: POST_FIELDS_LENGTH.title.min,
    message: getMinLengthText(POST_FIELDS_LENGTH.title.min),
  },
  maxLength: {
    value: POST_FIELDS_LENGTH.title.max,
    message: getMaxLengthText(POST_FIELDS_LENGTH.title.max),
  },
};

export const descriptionFieldRules: RegisterOptions = {
  minLength: {
    value: POST_FIELDS_LENGTH.description.min,
    message: getMinLengthText(POST_FIELDS_LENGTH.description.min),
  },
  maxLength: {
    value: POST_FIELDS_LENGTH.description.max,
    message: getMaxLengthText(POST_FIELDS_LENGTH.description.max),
  },
};
