import { styled, Typography } from '@mui/material';

import { ReactComponent as AdligoStarLogo } from 'assets/icons/logos/starLogo.svg';
import { Button } from 'components';

export const ContentW = styled('div', {
  shouldForwardProp: (propname) => propname !== 'isPreview',
})<{ isPreview: boolean }>`
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 1px 0px #e4e8ee;
  border-radius: 10px;
  padding: 16px 16px 8px 16px;
  width: ${({ isPreview }) => (isPreview ? '440px' : '100%')};
  min-height: ${({ isPreview }) => (isPreview ? '100px' : 'unset')};
  margin: 0 auto 8px;
  display: flex;
  flex-direction: ${({ isPreview }) => (isPreview ? 'column' : 'row')};
  justify-content: space-between;
  position: relative;

  &.activeClass {
    animation-name: example;
    animation-duration: 7s;

    @keyframes example {
      from {
        box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 1);
      }
      to {
        box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FlexW = styled('div', {
  shouldForwardProp: (propname) => propname !== 'isPreview',
})<{ isPreview: boolean }>`
  display: flex;
  flex-grow: ${({ isPreview }) => (isPreview ? 'unset' : '1')};

  .default-avatar-icon {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    text-align: center;
    position: relative;
    margin-right: 16px;
  }

  @media (max-width: 520px) {
    margin: 0 0 8px 0;
  }
`;

export const BlueCircle = styled('div')`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: 8px;
  top: 8px;
  background: ${({ theme }) => theme.palette.info.p020};
  border: ${({ theme }) => `1px solid ${theme.palette.info.main}`};
`;

export const DateW = styled(Typography, {
  shouldForwardProp: (propname) => propname !== 'isPreview',
})<{ isPreview: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;

  @media (max-width: 520px) {
    width: auto;
    font-size: 12px;
    line-height: 16px;
  }
  align-self: ${({ isPreview }) => (isPreview ? 'unset' : 'flex-end')};

  @media (max-width: 500px) {
    align-self: flex-start;
  }
`;

const ActionBtn = styled(Button)`
  text-transform: none;
  background: ${({ theme }) => theme.palette.white.main};
  color: ${({ theme }) => theme.palette.info.main};
  font-weight: 400;
  line-height: 24px;
  border-radius: 6px;
  box-shadow: none;
  padding: 7px 16px;

  @media (max-width: 520px) {
    width: auto;
    margin: 15px 0;
  }
`;
export const ActivateButtonW = styled(ActionBtn)<{ disabled: boolean }>`
  background: ${({ theme }) => theme.palette.info.main};
  color: ${({ theme }) => theme.palette.white.main};
  border: ${({ theme, disabled }) =>
    disabled ? `1px solid ${theme.palette.grey[500]}` : `1px solid ${theme.palette.info.main}`};
  position: relative;
  height: 30px;
  margin-right: 20px;
  min-width: 130px;
`;

export const AdligoStarLogoW = styled(AdligoStarLogo)`
  margin-right: 16px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`;

export const NotificationW = styled('div')`
  display: flex,
  flex-direction: row;
  margin-right: 16px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`;

export const NotificationPreviewW = styled('div')<{ isPreview: boolean }>`
  margin-right: 16px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`;

export const StrongW = styled(Typography)`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const CompanyWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
