import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { MenuItem } from '@mui/material';
import { ReactElement, ReactNode, RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'hooks';

import { BaseTextField } from '../BaseTextField';
import { defaultStyles, menuItemStyles, defaultMobileStyles } from './styles';

type SelectFieldProps = {
  options: { value: string; label: ReactNode; disabled?: boolean }[];
  defaultOptionText?: string;
  multipleSelect?: boolean;
  CustomSelectLabel?: ReactElement;
  withDefaultEmptyOption?: boolean;
  emptyOption?: string;
  defaultValue?: string;
  name?: string;
  id?: string;
  selectRef?: RefObject<HTMLInputElement>;
  onChange?: (value: string, id?: string) => void;
};

export function SelectField({
  id,
  emptyOption,
  options,
  multipleSelect = false,
  withDefaultEmptyOption,
  defaultValue,
  name,
  selectRef,
  onChange,
}: SelectFieldProps): ReactElement {
  const [value, setValue] = useState(defaultValue || '');

  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const emptyOptionText = emptyOption || t('common.noneOption');

  return (
    <BaseTextField
      id={id}
      inputRef={selectRef}
      name={name}
      onChange={(e) => {
        onChange && onChange(e.target.value, id);
        setValue(e.target.value);
      }}
      value={value}
      select
      InputProps={{
        sx: {
          ...(isMobile ? defaultMobileStyles : defaultStyles),
        },
      }}
      SelectProps={{
        multiple: multipleSelect,
        disableUnderline: true,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '.MuiList-root': {
              maxHeight: '320px',
            },
          },
        },
        displayEmpty: true,
        IconComponent: ExpandMoreRoundedIcon,
      }}
    >
      {withDefaultEmptyOption && (
        <MenuItem value="">
          <em>{emptyOptionText}</em>
        </MenuItem>
      )}
      {options.map(({ value, label, disabled }) => (
        <MenuItem
          key={value}
          value={value}
          sx={{
            ...menuItemStyles,
            display: disabled && value === '' ? 'none' : 'block',
          }}
          disabled={disabled}
        >
          {label}
        </MenuItem>
      ))}
    </BaseTextField>
  );
}
