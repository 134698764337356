import { styled, Typography } from '@mui/material';

export const ContainerW = styled('div')`
  height: calc(100% - 27px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoteW = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.p040};
  white-space: break-spaces;
  margin: 20px 0 16px;
`;

export const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GoogleButtonWrapper = styled('div')`
  margin-bottom: 8px;
  width: 229px;
`;

export const GoogleSignInText = styled('span')`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.54);
`;
