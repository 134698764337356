export * from './fieldsLength';
export * from './files';
export * from './regex';
export * from './dateFormats';
export * from './fieldNames';
export * from './limits';
export * from './customLinkIcons';
export { QUERIES } from './queries';
export { countries } from './countries';
export { RICH_EDITOR_EMPTY } from './richEditor';

export const SWEDISH_DIAL_CODE = '46';
export const SWEDEN_COUNTRY_CODE = 'SE';

export const BREAKPOINTS = {
  web: 1023,
};
