export const submitBtnSx = {
  marginLeft: '31px',
  top: '15px',
};

export const submitBtnMobileSx = {
  position: 'absolute',
  top: '100px',
  right: 0,
};

export const countryCodeSelectSx = {
  border: 'none',
  borderBottom: '1px solid #000',
  borderRadius: '0',
  boxShadow: 'none',
  padding: '5px 14px 0px 58px',
  caretColor: 'transparent',
  fontSize: '36px',
  width: '155px',
};

export const countryCodeMobileSx = {
  fontSize: '18px',
  width: '76px',
  padding: '13px 0px 13px 36px',
};
