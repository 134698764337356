import { createContext, ReactElement, ReactNode } from 'react';

import { SWEDEN_COUNTRY_CODE } from 'constants/index';
import { useSessionState } from 'hooks';
import { AuthContextValues, UserAuthData, UserRole } from 'types';
import { removeTokens } from 'utils';

const initialAuthContext = {
  user: null,
  removeCurrentUser: () => {},
  saveUser: () => {},
  saveLocation: () => {},
  isManager: false,
  location: SWEDEN_COUNTRY_CODE,
};

export const AuthContext = createContext<AuthContextValues>(initialAuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps): ReactElement | null {
  const [user, setUser] = useSessionState<null | UserAuthData['user']>('user', null);
  const [location, setLocation] = useSessionState('location', SWEDEN_COUNTRY_CODE);

  function saveUser(data: UserAuthData) {
    const { user } = data;
    setUser(user);
  }

  function saveLocation(location: string) {
    setLocation(location);
  }

  function removeCurrentUser() {
    setUser(null);
    removeTokens();
  }

  const isManager = user?.role?.includes(UserRole.Manager);

  return (
    <AuthContext.Provider
      value={{
        user,
        isManager: Boolean(isManager),
        removeCurrentUser,
        saveUser,
        location,
        saveLocation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
