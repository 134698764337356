import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useEditAchievementMutation } from 'api';
import { TIME_DATE_MONTH_WITH_COMMA, TIME_DATE_FORMAT_WITH_COMMA } from 'constants/dateFormats';
import { Achievement, AchievementFormValues } from 'types';
import { formattedDate } from 'utils';

import { formValuesAdapter } from '../../CreateAchievementForm/adapter';
import { defaultValuesAdapter } from '../adapter';

interface UseEditAchievementFormProps {
  data?: Achievement;
}

export function useEditAchievementForm({ data }: UseEditAchievementFormProps) {
  const { t } = useTranslation();
  const { achievementId = '', employeeId = '' } = useParams();

  const { editAchievementMutation, isLoading: isEditAchievementLoading } =
    useEditAchievementMutation({
      invalidateQueries: ['achievement-details'],
      successText: t('toasts.achievement.success.edit'),
    });

  const handleSubmit = useCallback(
    async (data: AchievementFormValues) => {
      await editAchievementMutation({
        data: {
          ...formValuesAdapter(data),
        },
        userId: employeeId,
        achievementId,
      });
    },
    [achievementId, editAchievementMutation, employeeId],
  );

  const defaultValues = defaultValuesAdapter({ data });

  const createdDate = data?.created_at ? new Date(data?.created_at) : '';
  const isCurrentYear = createdDate && createdDate.getFullYear() === new Date().getFullYear();
  const dateFormat = isCurrentYear ? TIME_DATE_MONTH_WITH_COMMA : TIME_DATE_FORMAT_WITH_COMMA;
  const createdAt = !!createdDate ? formattedDate(createdDate, dateFormat) : '';

  return {
    isEditAchievementLoading,
    defaultValues,
    author: data?.author,
    createdAt,
    handleSubmit,
  };
}
