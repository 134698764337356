import { IconButton } from '@mui/material';
import { ReactElement } from 'react';

import { ReactComponent as CommentsIcon } from 'assets/icons/comments.svg';
import { ReactComponent as EmptyCommentsIcon } from 'assets/icons/messageText.svg';
import { MaximizeButton } from 'components';

import { ActionsW, CountW, IconW } from './styled';

interface PostItemActionsProps {
  isPreview?: boolean;
  commentsCount?: number;
}

export function PostItemActions({
  isPreview = false,
  commentsCount,
}: PostItemActionsProps): ReactElement {
  return (
    <ActionsW>
      <MaximizeButton disabled={isPreview} />
      <IconButton disabled={isPreview}>
        {commentsCount ? (
          <IconW>
            <CommentsIcon style={{ opacity: '0.4' }} />
            <CountW>{commentsCount}</CountW>
          </IconW>
        ) : (
          <EmptyCommentsIcon style={{ opacity: '0.4' }} />
        )}
      </IconButton>
    </ActionsW>
  );
}
