import { Control, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { useConfirmRegistrationMutation } from 'api';
import { Links } from 'settings';
import { SignUpFormValues } from 'types';

import { confirmSignUpRequestAdapter } from '../adapters';
import { defaultValues } from './config';

interface UseSignUpFormReturn {
  handleFormSubmit: () => void;
  control: Control<SignUpFormValues>;
  isLoading: boolean;
  email_exist: boolean;
}

type LocationState = {
  signUp: boolean;
  email: string;
  ott: string;
  company_id: string;
  is_email_exist: string;
};

export function useSignUpForm(): UseSignUpFormReturn {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { confirmRegistrationMutation, isLoading } = useConfirmRegistrationMutation();

  const { email, ott, company_id = '', is_email_exist } = state as LocationState;

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<SignUpFormValues>({
    defaultValues: {
      ...defaultValues,
      email,
    },
    mode: 'onBlur',
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    const userData = confirmSignUpRequestAdapter(data) || {
      first_name: '',
      last_name: '',
      password: '',
      country_code: 46,
      phone: '',
    };
    await confirmRegistrationMutation(
      {
        ott,
        user: { company_id: company_id || '', ...userData },
      },
      { onSuccess: () => navigate(Links.auth.signIn, { state: { userSignUp: true } }) },
    );
  });

  return {
    handleFormSubmit,
    control,
    isLoading: isSubmitting || isLoading,
    email_exist: is_email_exist === 'true' ? true : false,
  };
}
