import { styled } from '@mui/material';

export const SuccessNote = styled('div')`
  margin-top: 24px;
  max-width: 467px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  word-break: break-word;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const ResendButton = styled('span')`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.info.main};
`;
