import { ReactElement, useState, useEffect, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import Swiper, { Pagination } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';

import EquipmentEmptyImage from 'assets/images/equipment.png';
import { AddAndExtendActions } from 'components';
import { useAuth } from 'hooks';
import { Links } from 'settings';
import { EquipmentPayload } from 'types/equipments';
import { getArrayChunks } from 'utils/getArrayChunks';

import {
  ContainerW,
  EmptyStateTitle,
  ListItem,
  ListContainer,
  Title,
  EmptyStateContainer,
  EmptyStateImage,
  NavLinkW,
} from './styled';

interface EquipmentListProps {
  list: EquipmentPayload[];
  employeeId: string;
}

export function EquipmentList({ list, employeeId }: EquipmentListProps): ReactElement {
  const { isManager, user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const linkState = useMemo(() => ({ from: pathname }), [pathname]);
  const mobileWidth = 520;

  const [_activeIndex, setActiveIndex] = useState(0);
  const [swiperRef, setSwiperRef] = useState<Swiper | null>(null);

  function getItemsCountInChunk(width: number) {
    return mobileWidth > width ? 3 : 6;
  }

  const [itemsCount, setItemsCount] = useState(getItemsCountInChunk(window.innerWidth));

  useEffect(() => {
    function handleWindowResize() {
      setItemsCount(getItemsCountInChunk(window.innerWidth));
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const chunks = getArrayChunks<EquipmentPayload>(list, itemsCount);
  const isListLength = list.length > 0;

  const createEquipmentLink = generatePath(Links.profile.equipmentList.createEquipment, {
    employeeId,
  });
  const viewEquipmentsLink = generatePath(Links.profile.equipmentList.equipment.index, {
    employeeId,
  });

  const handleItemClick = useCallback(
    ({ id, user_id, author_id, is_editable }: EquipmentPayload) => {
      const isCurrentAuthor = user?.id === author_id;
      const isCurrentAssignee = user?.id === user_id;
      const isEditableByManager =
        isManager &&
        ((isCurrentAuthor && isCurrentAssignee && is_editable) ||
          (isCurrentAuthor && !isCurrentAssignee) ||
          (!isCurrentAuthor && !isCurrentAssignee));

      const equipmentLink = generatePath(Links.profile.equipmentList.equipment.equipmentId, {
        employeeId: user_id,
        equipmentId: id,
      });

      (isEditableByManager || is_editable) && navigate(equipmentLink, { state: linkState });
    },
    [isManager, linkState, navigate, user?.id],
  );

  return (
    <ContainerW isListLength={isListLength}>
      <Title>{t('profile.equipments.preview.title')}</Title>
      {isListLength ? (
        <SwiperComponent
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          onSwiper={(swiper) => setSwiperRef(swiper)}
          pagination={true}
          modules={[Pagination]}
          style={{ maxHeight: '438px' }}
        >
          {Object.keys(chunks).map((key) => (
            <SwiperSlide
              key={key}
              style={{ height: swiperRef?.height || '438px', maxHeight: '438px' }}
            >
              <ListContainer>
                {chunks[key].map((listItem) => {
                  return (
                    <ListItem key={listItem.id} onClick={() => handleItemClick(listItem)}>
                      {listItem.name}
                    </ListItem>
                  );
                })}
              </ListContainer>
            </SwiperSlide>
          ))}
        </SwiperComponent>
      ) : (
        <EmptyStateContainer>
          <EmptyStateImage src={EquipmentEmptyImage} alt="equipment empty state" />
          <EmptyStateTitle>
            <Trans i18nKey="profile.equipments.preview.emptyState">
              Nothing is here yet.
              <NavLinkW to={createEquipmentLink} state={linkState}>
                Add Equipments
              </NavLinkW>
              !
            </Trans>
          </EmptyStateTitle>
        </EmptyStateContainer>
      )}

      <AddAndExtendActions
        addIconLink={createEquipmentLink}
        maximizeIconLink={viewEquipmentsLink}
        addLinkState={linkState}
      />
    </ContainerW>
  );
}
