import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';
import { ActivateUserRequestParams } from 'api/types';

import { queryClient } from './../queryClient';

interface UseActivateUserMutationReturn {
  activateUserMutation: UseMutateAsyncFunction<
    AxiosResponse,
    unknown,
    ActivateUserRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseDeactivateProps {
  onSuccessFunc?: () => void;
  invalidateQuery: string;
}

export function useActivateUserMutation({
  onSuccessFunc,
  invalidateQuery,
}: UseDeactivateProps): UseActivateUserMutationReturn {
  const { mutateAsync: activateUserMutation, isLoading } = useMutation(API_HANDLERS.USER.ACTIVATE, {
    onSuccess: () => {
      onSuccessFunc?.();
      queryClient.invalidateQueries([invalidateQuery]);
    },
  });

  return {
    activateUserMutation,
    isLoading,
  };
}
