import { Box, BoxProps } from '@mui/system';
import { ReactElement } from 'react';

export function Form({ children, ...otherProps }: BoxProps): ReactElement {
  return (
    <Box
      noValidate
      autoComplete="off"
      component="form"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      {...otherProps}
    >
      {children}
    </Box>
  );
}
