import { styled } from '@mui/material';

export const FieldsW = styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  &:not(last-of-type) {
    margin-bottom: 24px;
  }

  @media (max-width: 1023px) {
    &:not(last-of-type) {
      margin-bottom: 16px;
    }
  }
`;
