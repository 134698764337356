import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { API_HANDLERS } from 'api/apiHandlers';
import { Subscription } from 'types';
import { combineProductByType } from 'utils';

interface UseUpgradePlanDialogProps {
  currentSubscription: Subscription;
  isOpen: boolean;
}

export function useUpgradePlanDialog({ currentSubscription, isOpen }: UseUpgradePlanDialogProps) {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    ['products', currentSubscription.period],
    () =>
      API_HANDLERS.PRODUCTS.GET_LIST({
        period: currentSubscription.period,
        currency: currentSubscription.currency,
      }),
    {
      enabled: isOpen,
    },
  );

  const filteredProducts =
    data?.data.filter((product) => product.name.toLowerCase().includes(currentSubscription.type)) ||
    [];

  const productsByName = combineProductByType({
    products: filteredProducts,
    subscription: currentSubscription,
  });

  const teamSize = t(`subscriptions.types.${currentSubscription.type}.teamSize`);
  const duration = t(`subscriptions.types.${currentSubscription.type}.duration`);
  const name = t(`subscriptions.types.${currentSubscription.type}.name`);
  const currentSubscriptionPeriod = t(`subscriptions.${currentSubscription.period}`);

  const { main, extra } = productsByName;

  const currentProductDetails = {
    name,
    duration,
    teamSize,
    extraPrice: `${extra.price.currency.toUpperCase()} ${extra.price.price} ${t(
      'subscriptions.extra',
    )}`,
    mainPrice: `${main.price.currency.toUpperCase()} ${
      main.price.price
    }/${currentSubscriptionPeriod}`,
    currency: currentSubscription.currency,
  };

  return { currentProductDetails, isLoading };
}
