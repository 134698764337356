import { styled, FormHelperText, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  // show only focused label
  '.MuiFormLabel-root': {
    display: 'none',
  },
  '.MuiInputLabel-shrink.Mui-focused': {
    color: theme.palette.primary.p050,
    top: '-20px',
    display: 'block',
    fontSize: '16px',
    lineHeight: '18px',
    transform: 'scale(1)',
  },

  '& .MuiInputBase-root': {
    marginTop: 0,

    '.Mui-disabled': {
      cursor: 'not-allowed',
      WebkitTextFillColor: theme.palette.primary.p050,
      border: 'none',
    },
  },

  '& .MuiInputBase-input': {
    padding: '0 0 10px 0',
    height: '38px',
    fontSize: '36px',
    borderBottomStyle: 'solid',
    borderBottom: '1px solid #000000',
  },

  '.MuiInputBase-root.MuiInput-root.Mui-disabled::before': {
    borderBottomStyle: 'solid',
  },

  // changes underline border color to black
  '& .MuiInput-underline::before': {
    borderBottomStyle: 'solid',
  },

  [theme.breakpoints.down('desktop')]: {
    '& .MuiInputBase-input': {
      fontSize: '24px',
    },
  },

  input: {
    textOverflow: 'ellipsis',

    // removes blue bg on autocomplete
    ':-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },

    // to remove arrow from number input
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '.Mui-disabled': {
      cursor: 'not-allowed',
      'input:disabled': {
        cursor: 'not-allowed',
        // borderColor: theme.palette.primary.p050,
      },
    },
  },

  '.MuiFormHelperText-root.Mui-error': {
    display: 'none',
  },
}));

export const StyledFormHelperText = styled(FormHelperText)`
  font-size: 16px;
  line-height: 18px;
  top: -24px;
  position: absolute;
  &.error {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const StyledTooltip = styled(Tooltip)``;
