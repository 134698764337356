import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCategoriesListQuery } from 'api';
import { useDeleteCategoryMutation } from 'api/mutations/useDeleteCategoryMutation';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ConfirmDialog, GoBackButtonWithTitle, Spinner } from 'components';
import { useAuth, useModal, useWindowSize } from 'hooks';

import { CreateDocumentCategoriesForm, PageHeader } from '../../components';
import { ButtonW, FlexW, PageW } from './styled';

export function CreateCategoriesPage(): ReactElement {
  const params = useParams();
  const { isMobile } = useWindowSize();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { categoriesList, isLoading }: { categoriesList: any[]; isLoading: boolean } =
    useCategoriesListQuery();

  const category = categoriesList.find((cat) => cat.id === params.categoryId);

  const { deleteCategoryMutation } = useDeleteCategoryMutation();
  const { isManager } = useAuth();

  const { t } = useTranslation();

  const isEdit = !!params?.categoryId;

  const { handleOpenModal, handleCloseModal, isOpen } = useModal();

  async function handleDeleteTarget() {
    if (params?.categoryId) {
      await deleteCategoryMutation({ categoryId: params.categoryId });
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageW>
      {isEdit ? (
        <GoBackButtonWithTitle
          containerSx={{ marginBottom: isMobile ? '16px' : '36px' }}
          title={t('resources.documentCategories.create.editTitle')}
        />
      ) : (
        <PageHeader pageTitle={t('resources.documentCategories.create.title')} />
      )}
      <FlexW>
        <CreateDocumentCategoriesForm isEdit={isEdit} category={category ?? undefined} />
        {isManager && isEdit && (
          <ButtonW onClick={handleOpenModal} endIcon={<TrashIcon />}>
            {t('resources.documentCategories.removeButton')}
          </ButtonW>
        )}
      </FlexW>
      <ConfirmDialog
        title={t('resources.documentCategories.removeModal.title', {
          categoryTitle: category?.title,
        })}
        subTitle={t('resources.documentCategories.removeModal.subtitle')}
        open={isOpen}
        onSubmit={handleDeleteTarget}
        onClose={handleCloseModal}
      />
    </PageW>
  );
}
