import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useUsersListQuery } from 'api';
import { Spinner } from 'components';
import { useCreateDraft } from 'hooks';

import { CreateNotificationForm, PageHeader } from '../../components';
import { PageW } from './styled';

export function CreateNotificationPage(): ReactElement {
  const { draft, isLoading } = useCreateDraft();
  const { t } = useTranslation();

  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  if (isLoading || isLoadingUsersList) {
    return <Spinner />;
  }

  return (
    <PageW>
      <PageHeader pageTitle={t('notifications.create.title')} />
      {draft && <CreateNotificationForm draft={draft} usersList={usersList} />}
    </PageW>
  );
}
