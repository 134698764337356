import { styled } from '@mui/material';

export const ShowMore = styled('div')`
  position: absolute;
  background: rgba(55, 65, 81, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  z-index: 10;
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.white.main};
`;

export const ShowLess = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.palette.info.main};
`;

export const EquipmentAttachmentContainer = styled('div')<{ showMore: boolean }>`
  display: flex;
  gap: 8px;
  flex-direction: ${({ showMore }) => (showMore ? 'column' : 'row')};

  @media (max-width: 1023px) {
    flex-direction: column;
  },
`;
