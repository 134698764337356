import { ReactElement } from 'react';

import { Backdrop } from 'components';

import { PollForm } from '../PollForm';
import { useEditPollForm } from './hooks';
import { ContentW, FormW } from './styled';

export function EditPollForm(): ReactElement {
  const {
    isLoadingUsersList,
    isDetailsLoading,
    isDetailsLoaded,
    isEditPollLoading,
    defaultValues,
    handleSubmit,
  } = useEditPollForm();

  return (
    <>
      {(isDetailsLoading || isLoadingUsersList) && <Backdrop />}

      {isDetailsLoaded && (
        <ContentW>
          <FormW>
            <PollForm
              mode="edit"
              isLoading={isEditPollLoading}
              defaultValues={defaultValues}
              onSubmit={handleSubmit}
            />
          </FormW>
        </ContentW>
      )}
    </>
  );
}
