import { Box, IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PostSubTypes, usePostDetails } from 'api';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ConfirmDialog, Spinner, TopMenu } from 'components';
import { GoBackButtonWithTitle } from 'components/Button';
import { AddCommentForm, CommentsList } from 'features/comments';
import { useWindowSize } from 'hooks';
import { getFullName } from 'utils';

import { PostDetails } from '../../components';
import {
  ActionsW,
  ButtonW,
  ContentW,
  ContentWithRemoveW,
  MobileActionsW,
  PageW,
  RemoveIconW,
} from './styled';
import { titleDesktopSx, titleMobileSx } from './styles';
import { usePostDetailsPage } from './usePostDetailsPage';

// TODO: remove comment after edit poll implementation
// import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

export function PostDetailsPage(): ReactElement {
  const { postId = '' } = useParams<string>();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const {
    isLoading,
    navigateBack,
    post,
    comments,
    isLoadingComments,
    isFetchingNextPage,
    lastItemRef,
    isPoll,
    canDelete,
    handleOpenModal,
    isOpen,
    handleDeletePost,
    handleCloseModal,

    // TODO: remove comment after edit poll implementation
    // canEdit,
    // handleOpenEditMode,
  } = usePostDetailsPage(postId);

  const { post: poll } = usePostDetails({
    postId: post?.meta?.poll_id || '',
    queryName: `post-details, ${post?.meta?.poll_id || ''}`,
    include_reactions: true,
  });

  const isFinishedPoll = post?.meta?.sub_type == 'poll_finished';
  const showDeleteButton = !isFinishedPoll && canDelete;

  if (isLoading) {
    return <Spinner />;
  }

  if (!post) {
    return <PageW />;
  }

  const { title, author, id, meta } = post;
  const isWelcomePost = post.meta?.sub_type === PostSubTypes.WELCOME;
  const isBirthdayPost = post.meta?.sub_type === PostSubTypes.BIRTHDAY;
  const isPollPost = post.meta?.sub_type === PostSubTypes.POLL;
  const isKpiTargetPost = Boolean(meta?.kpi_target_id && meta.sub_type === PostSubTypes.KPI_TARGET);

  const removeDialogTitle = isPoll
    ? t('poll.removeModal.title')
    : t('post.details.removeModal.title', { postTitle: title ? `“${title}”` : '' });
  const removeDialogSubTitle = isPoll
    ? t('poll.removeModal.subtitle')
    : t('post.details.removeModal.subtitle');

  const TITLE = isWelcomePost
    ? t('post.listItem.welcomePost.title', {
        user: getFullName({ firstName: author.first_name, lastName: author.last_name }),
      })
    : isKpiTargetPost
    ? t('completedKpiTargetPost.title')
    : isBirthdayPost
    ? t('post.listItem.birthdayPost.title')
    : isPollPost
    ? t('post.listItem.pollPost.title', {
        title: poll?.title,
      })
    : title;

  return (
    <PageW>
      <TopMenu />
      <GoBackButtonWithTitle
        title={TITLE}
        containerSx={isMobile ? titleMobileSx : titleDesktopSx}
        onClick={navigateBack}
        Actions={
          <MobileActionsW>
            {showDeleteButton && (
              <IconButton onClick={handleOpenModal}>
                <RemoveIconW />
              </IconButton>
            )}
            {/* TODO: show after edit poll implementation */}
            {/* {canEdit && (
              <IconButton onClick={handleOpenEditMode}>
                <EditIconW />
              </IconButton>
            )} */}
          </MobileActionsW>
        }
      />

      <ContentWithRemoveW>
        <ContentW isPollPost={isPoll}>
          <PostDetails
            post={post}
            isWelcomePost={isWelcomePost}
            isBirthdayPost={isBirthdayPost}
            isPollPost={isPollPost}
            isKpiTargetPost={isKpiTargetPost}
            targetId={meta?.kpi_target_id}
          />

          <Box sx={{ marginTop: '32px' }}>
            <AddCommentForm postId={id} isPostComment />
          </Box>
          <CommentsList
            postId={id}
            comments={comments}
            isLoading={isLoadingComments}
            isFetchingNextPage={isFetchingNextPage}
            lastItemRef={lastItemRef}
          />
        </ContentW>

        <ActionsW>
          {/* TODO: show after edit poll implementation */}
          {/* {canEdit && (
            <ButtonW onClick={handleOpenEditMode} endIcon={<EditIcon />}>
              {t('post.details.editButton')}
            </ButtonW>
          )} */}
          {showDeleteButton && (
            <ButtonW onClick={handleOpenModal} endIcon={<TrashIcon />}>
              {t('post.details.removeButton')}
            </ButtonW>
          )}
        </ActionsW>
      </ContentWithRemoveW>

      <ConfirmDialog
        title={removeDialogTitle}
        subTitle={removeDialogSubTitle}
        open={isOpen}
        onSubmit={handleDeletePost}
        onClose={handleCloseModal}
      />
    </PageW>
  );
}
