import {
  isValid,
  isToday,
  daysInWeek,
  daysInYear,
  differenceInYears,
  differenceInWeeks,
} from 'date-fns';

export function formatDateTime(date: Date): string {
  if (!isValid(date)) {
    return 'Invalid date';
  }

  const currentDate = new Date();
  const msPassed = currentDate.getTime() - date.getTime();
  const ONE_DAY_MS = 86400000;
  const daysCount = Math.floor(msPassed / ONE_DAY_MS);

  if (msPassed > 3_600_000 && daysCount < 1) {
    const hoursPassed = Math.floor(msPassed / 3_600_000);
    return `${hoursPassed}h`;
  }

  if (msPassed > 60_000 && daysCount < 1) {
    const minsPassed = Math.floor(msPassed / 60_000);
    return `${minsPassed}m`;
  }

  if (!isToday(date)) {
    // years ago
    if (daysCount > daysInYear) {
      const years = differenceInYears(currentDate, date);
      return `${years}y`;
    }

    // this week
    if (daysCount < daysInWeek) {
      return `${daysCount}d`;
    }

    // weeks ago
    const weeksCount = differenceInWeeks(currentDate, date);
    return `${weeksCount}w`;
  }

  return `now`;
}
