import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface PaletteColor {
    main: string;
    p080?: string;
    p050?: string;
    p040?: string;
    p030?: string;
    p020?: string;
    p010?: string;
    p100?: string;
  }

  // TODO: update Theme
  interface PaletteColorOptions {
    main: string;
    dark?: string;
    light?: string;
    p080?: string;
    p050?: string;
    p030?: string;
    p020?: string;
    p010?: string;
    p005?: string;
  }

  interface Palette {
    primary: PaletteColor;
    secondary: PaletteColor;
    white: PaletteColor;
    success: PaletteColor;
    error: PaletteColor;
    danger: PaletteColor;
    info: PaletteColor;
    transparency: string;
    overlay: string;
  }

  interface PaletteOptions {
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    white?: PaletteColorOptions;
    success?: PaletteColorOptions;
    error?: PaletteColorOptions;
    danger?: PaletteColorOptions;
    info?: PaletteColorOptions;
    overlay: string;
    transparency: string;
  }
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    desktop: true;
  }
}

export const customPalette = {
  primary: {
    main: '#000000',
    light: '#D9D9D9',
    p010: '#0000001A',
    p020: '#00000033',
    p030: '#0000004D',
    p040: '#00000066',
    p050: '#00000080',
    p080: '#000000CC',
  },
  white: {
    main: '#FFFFFF',
    light: '#FFFFFFCC',
    p050: '#FFFFFF99',
    p030: '#FFFFFF66',
    p020: '#FFFFFF33',
    p010: '#FFFFFF1A',
    p005: '#FFFFFF0D',
    p100: '#F3F4F6',
  },
  error: {
    main: '#FF9900',
  },
  danger: {
    main: '#F20000',
    dark: '#CA0101',
    light: '#DF7778',
    p050: '#E89E9E',
    p030: '#F1C5C5',
    p020: '#F6D8D8',
    p010: '#FAECEC',
    p005: '#FDF5F5',
  },
  info: {
    main: '#009BF2',
    dark: '#0086D2',
    light: '#72A5DF',
    p030: '#009BF24D',
    p020: '#B2E1FB',
  },
  warning: {
    main: '#F20000',
  },
  secondary: {
    main: '#ffffffb3',
    light: '#6B7280',
  },
  overlay: '#191C1E99',
  transparency: 'rgba(0, 0, 0, 0)',
};

const theme = createTheme({
  palette: customPalette,
  typography: {
    fontFamily: "'Outfit', sans-serif",
  },
  breakpoints: {
    values: {
      desktop: 1023,
    },
  },
});

export default theme;
