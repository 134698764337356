import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, NavLink, useLocation, useParams } from 'react-router-dom';

import { useCategoriesListQuery, useDocumentsListQuery } from 'api';
import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { GoBackButtonWithTitle, TopMenu } from 'components';
import { DOCUMENTS_PAGE_LIMIT } from 'constants/index';
import { useAuth, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { CategoriesListType, InstanceLevelType, InstanceType } from 'types';
import { getCoreRoute, toCapitalize } from 'utils';

import { DocumentsList } from '../../components';
import { PageW } from '../styled';
import { formatTag } from './formatTag';

export const DocumentListPage = () => {
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  const { isManager, user: currentUser } = useAuth();
  const { isMobile } = useWindowSize();

  const tag = params?.tag || 'private';
  const employeeId = params?.employeeId;
  const isPrivate = tag === 'private';
  const isManagerAndNotCurrentUser = isManager && !!employeeId && currentUser?.id !== employeeId;

  const {
    categoryMap,
    isLoading: isCategoryListLoading,
  }: { categoryMap: CategoriesListType; isLoading: boolean } = useCategoriesListQuery();

  const tagTitle = isPrivate
    ? t('profile.privateDocuments.title')
    : categoryMap[formatTag(tag)]?.label;
  const coreRoute = getCoreRoute(location);

  const postListQueryParams = {
    type: InstanceType.document,
    level: [InstanceLevelType.public],
    tag: tag as string,
    limit: DOCUMENTS_PAGE_LIMIT,
  };

  const privatePostListQuery = {
    type: InstanceType.document,
    level: isManagerAndNotCurrentUser
      ? [InstanceLevelType.shared]
      : [InstanceLevelType.shared, InstanceLevelType.private],
    assigned: isManagerAndNotCurrentUser ? employeeId : '',
    limit: DOCUMENTS_PAGE_LIMIT,
  };
  const { documents, isEmptyList, lastItemRef, isFetchingNextPage, isLoading } =
    useDocumentsListQuery(isPrivate ? privatePostListQuery : postListQueryParams);

  const linkToCreateDocument = isPrivate
    ? coreRoute === 'we'
      ? generatePath(Links.we.employees.employee.createDocument, { employeeId })
      : Links.profile.createDocument
    : generatePath(Links.resources.createTaggedDocument.tagged.tag, { tag });

  return (
    <PageW>
      <TopMenu />
      <GoBackButtonWithTitle
        title={toCapitalize(tagTitle || 'Document Category')}
        containerSx={{
          marginBottom: isMobile ? '16px' : '24px',
          '.title': {
            fontSize: '24px',
          },
        }}
        Actions={
          <NavLink
            to={linkToCreateDocument}
            state={{ selectPrivate: isPrivate && isManagerAndNotCurrentUser }}
          >
            <IconButton>
              <AddIcon style={{ opacity: '0.4' }} />
            </IconButton>
          </NavLink>
        }
      />
      <DocumentsList
        documents={documents}
        isPrivate={isPrivate}
        isLoading={isLoading || isCategoryListLoading}
        isFetchingNextPage={isFetchingNextPage}
        isEmptyList={isEmptyList}
        lastItemRef={lastItemRef}
        tag={isPrivate ? undefined : tag}
        categoryMap={categoryMap}
      />
    </PageW>
  );
};
