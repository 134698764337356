import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { API_HANDLERS } from 'api/apiHandlers';
import { AuthPageWrapper, AuthPageTitle } from 'features/auth';
import { LanguageSelector } from 'features/languageSelector';
import { AuthPageLink } from 'types';

import { AfterSubmitNote, PasswordResetForm } from '../../components';

export function RequestPasswordResetPage(): ReactElement {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const { mutateAsync: requestPasswordResetMutation, isLoading } = useMutation(
    API_HANDLERS.AUTH.FORGOT_PASSWORD,
  );

  const pageTitle = email
    ? t('restorePassword.submitted.pageTitle')
    : t('restorePassword.pageTitle');
  const headerLink = email ? AuthPageLink.signIn : AuthPageLink.signUp;
  const marginTop = email ? '24px' : '127px';

  return (
    <AuthPageWrapper link={headerLink}>
      <AuthPageTitle>{pageTitle}</AuthPageTitle>

      {email ? (
        <AfterSubmitNote
          email={email}
          isLoading={isLoading}
          requestPasswordResetMutation={requestPasswordResetMutation}
        />
      ) : (
        <PasswordResetForm
          setEmail={setEmail}
          isLoading={isLoading}
          requestPasswordResetMutation={requestPasswordResetMutation}
        />
      )}

      <Box sx={{ marginTop }}>
        <LanguageSelector />
      </Box>
    </AuthPageWrapper>
  );
}
