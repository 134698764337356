import { RegisterOptions } from 'react-hook-form';

import { FIELDS_VALUE_LENGTH } from 'constants/index';
import { getMaxLengthText, getMinLengthText, required } from 'validations';

export const phoneRules: RegisterOptions = {
  ...required,
  minLength: {
    value: 3,
    message: getMinLengthText(3),
  },
  maxLength: {
    value: 13,
    message: getMaxLengthText(13),
  },
};

export const relationPhoneRules: RegisterOptions = {
  minLength: {
    value: 3,
    message: getMinLengthText(3),
  },
  maxLength: {
    value: 13,
    message: getMaxLengthText(13),
  },
};

export const ssnRules: RegisterOptions = {
  minLength: {
    value: FIELDS_VALUE_LENGTH.ssn.min,
    message: getMinLengthText(FIELDS_VALUE_LENGTH.ssn.min),
  },
  maxLength: {
    value: FIELDS_VALUE_LENGTH.ssn.max,
    message: getMaxLengthText(FIELDS_VALUE_LENGTH.ssn.max),
  },
};
