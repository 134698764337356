import { Typography, styled } from '@mui/material';

export const TitleName = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
`;

export const TitleCategory = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
`;

export const iconButtonStyle = {
  height: 'fit-content',
};
