import { Link, styled, Typography } from '@mui/material';

export const Container = styled('div')`
  position: relative;
`;

export const PostDescription = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
  white-space: break-spaces;

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const LinkW = styled(Link)`
  color: ${({ theme }) => theme.palette.info.main};
  text-decoration-color: ${({ theme }) => theme.palette.info.main};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  display: inline-block;
`;

export const RightSide = styled('div')`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 1023px) {
    align-items: flex-start;
  }
`;

export const TargetW = styled('div')`
  margin: 24px 0 16px;
`;
