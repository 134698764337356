import { styled } from '@mui/material';

export const ContainerW = styled('div')`
  color: ${({ theme }) => theme.palette.primary.p040};
  margin-top: 32px;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
  }
`;

export const LinkW = styled('span')`
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
`;
