import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { CreatePostDraftResponse, PostListItem } from 'api';
import {
  Backdrop,
  BaseTextFieldController,
  ConfirmDialog,
  Form,
  FormGroup,
  SubmitRoundedButton,
  FileUploadButtons,
  SelectController,
  FormAttachments,
  RichEditorController,
} from 'components';
import { useWindowSize } from 'hooks';
import { textFieldMobileStyles, textFieldStyles, richEditorStyles } from 'styles';
import { REQUIRED, required } from 'validations';

import { DOCUMENTS_TYPE, MAX_ARTICLE_LENGTH, MAX_TITLE_LENGTH } from './constants';
import { useCreateDocumentForm } from './hooks';
import { descriptionFieldRules, titleFieldRules } from './rules';
import { Footer, FromContainer, PublicDocumentNote } from './styled';
import { tagsFieldMobileStyles, tagsFieldStyles } from './styles';

interface CreatePostFormProps {
  draft?: CreatePostDraftResponse;
  document?: PostListItem;
  isEdit: boolean;
}

interface CreatePostFormLocationTypes {
  selectPrivate?: boolean;
  assignedToId?: string;
}
interface Params {
  tag: string;
  employeeId: string;
}

export function CreateDocumentForm({ draft, document, isEdit }: CreatePostFormProps): ReactElement {
  const params: Params | Readonly<Params> = useParams() as Params | Readonly<Params>;
  const { t } = useTranslation();
  const { state } = useLocation();
  const selectPrivate = (state as CreatePostFormLocationTypes)?.selectPrivate;
  const assignedToId = (state as CreatePostFormLocationTypes)?.assignedToId || params.employeeId;
  const { isMobile } = useWindowSize();

  const {
    control,
    handleFormSubmit,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    attachedImages,
    attachedDocs,
    attachedVideos,
    handleDeleteAttachment,
    isLoading,
    isOpen,
    handleCloseModal,
    isDisableTags,
    isPublicDocument,
    isNotDisableButton,
    categoriesOptionsList,
    progress,
    handleSubmitConfirmModal,
  } = useCreateDocumentForm({
    draft,
    selectPrivate,
    assignedToId,
    document,
    isEdit,
    tag: params.tag,
  });
  const formGroupMarginBottom = isMobile ? 8 : 24;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;
  const disableTagsField = Boolean(isDisableTags || selectPrivate);

  return (
    <>
      {isLoading && <Backdrop progress={progress} />}

      <FromContainer>
        <Form onSubmit={handleFormSubmit}>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <BaseTextFieldController
              name="title"
              control={control}
              placeholder={t('document.create.form.placeholders.title')}
              sx={fieldStyles}
              maxLength={MAX_TITLE_LENGTH}
              rules={titleFieldRules}
            />
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <SelectController
              name="level"
              control={control}
              placeholder={t('document.create.form.placeholders.documentType')}
              options={DOCUMENTS_TYPE}
              disabled={selectPrivate}
              rules={required}
              sx={{
                opacity: selectPrivate ? '0.5' : 1,
              }}
            />
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <SelectController
              name="tags"
              control={control}
              placeholder={disableTagsField ? '' : t('document.create.form.placeholders.tag')}
              options={categoriesOptionsList}
              multipleSelect={true}
              disabled={disableTagsField}
              sx={
                isMobile
                  ? tagsFieldMobileStyles(disableTagsField)
                  : tagsFieldStyles(disableTagsField)
              }
              rules={{
                required: {
                  message: REQUIRED,
                  value: !isDisableTags,
                },
              }}
            />
          </FormGroup>

          <FormAttachments
            attachedImages={attachedImages}
            attachedDocs={attachedDocs}
            attachedVideos={attachedVideos}
            handleDeleteAttachment={handleDeleteAttachment}
            draftId={draft?.id || document?.id || ''}
            hasDownloadDoc={isEdit}
          />

          <FormGroup marginBottom={isMobile ? 0 : 24} row>
            <RichEditorController
              name="description"
              control={control}
              placeholder={t('document.create.form.placeholders.description')}
              maxLength={MAX_ARTICLE_LENGTH}
              rules={descriptionFieldRules}
              sx={richEditorStyles(isMobile)}
            />
            <FileUploadButtons
              handleAddImages={handleAddImages}
              handleAddDocuments={handleAddDocuments}
              handleAddVideos={handleAddVideos}
            />
          </FormGroup>

          {isMobile && !isEdit && (
            <PublicDocumentNote>
              {isPublicDocument && t('document.create.form.autogeneratedPostNote')}
            </PublicDocumentNote>
          )}
          {!isMobile && (
            <Footer>
              <PublicDocumentNote>
                {isPublicDocument && !isEdit && t('document.create.form.autogeneratedPostNote')}
              </PublicDocumentNote>
              <ConfirmDialog
                subTitle={t('document.create.modal.exit')}
                title={t('document.create.modal.title')}
                open={isOpen}
                onClose={handleCloseModal}
                onSubmit={handleSubmitConfirmModal}
              />

              <SubmitRoundedButton
                disabled={isEdit && !isNotDisableButton}
                sx={{ marginLeft: 'auto' }}
              />
            </Footer>
          )}
        </Form>
      </FromContainer>
      {isMobile && (
        <SubmitRoundedButton
          disabled={isEdit && !isNotDisableButton}
          sx={{ marginLeft: 'auto' }}
          className="fixed-bottom"
          onClick={handleFormSubmit}
        />
      )}

      <ConfirmDialog
        subTitle={t('document.create.modal.exit')}
        title={t('document.create.modal.title')}
        open={isOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
