import { getLinks } from 'utils';

export const Paths = {
  index: '/',
  teamSelect: 'team-select',
  we: {
    index: 'we',
    employees: {
      index: 'employees',
      invite: 'invite',
      employee: {
        index: ':employeeId',
        extended: 'extended',
        createDocument: 'add-document',
        privateDocuments: 'private-documents',
      },
    },
    createPost: 'add-post',
    createTask: 'add-task',
    createPoll: 'add-poll',
    createDocument: 'add-document',
    taskManagement: {
      index: 'tasks',
      taskDetails: ':taskId',
    },
    posts: {
      index: ':postId',
      edit: 'edit',
    },
    publicDocuments: 'public-documents',
    kpi: {
      index: 'kpi',
      createTarget: 'add-target',
      createVision: 'add-vision',
      editVision: 'edit-vision',
      targetDetails: 'targets/:targetId',
    },
    quickLinks: {
      index: 'quick-links',
      createLink: 'add-link',
      linkDetails: ':linkId',
      editVision: 'edit-link',
    },
    notifications: 'notifications',
  },
  profile: {
    index: 'profile',
    createDocument: 'add-document',
    taskManagement: {
      index: 'tasks',
      taskDetails: ':taskId',
    },
    extended: {
      index: ':employeeId',
      extended: 'extended',
    },
    privateDocuments: {
      index: 'private-documents',
      edit: {
        index: 'edit',
        documentId: ':documentId',
      },
    },
    equipmentList: {
      index: ':employeeId',
      equipment: {
        index: 'equipment',
        equipmentId: ':equipmentId',
      },
      createEquipment: 'add-equipment',
    },
    createTask: 'add-task',
    achievements: {
      index: ':employeeId',
      achievement: {
        index: 'achievements',
        achievementId: {
          index: ':achievementId',
          createGoal: 'add-goal',
          goal: {
            index: 'goals',
            goalId: ':goalId',
          },
        },
      },
      createAchievement: 'add-achievement',
    },
  },
  resources: {
    index: 'resources',
    edit: {
      index: 'edit',
      categoryId: ':categoryId',
    },
    employees: {
      index: 'employees',
      invite: 'invite',
      employee: {
        index: ':employeeId',
        extended: 'extended',
      },
    },
    documents: {
      index: 'documents',
      tag: ':tag',
      edit: {
        index: 'edit',
        documentId: ':documentId',
      },
    },
    createDocument: 'add-document',
    createTaggedDocument: {
      index: 'add-document',
      tagged: {
        index: 'tag',
        tag: ':tag',
      },
    },
    createCategory: 'add-category',
    createTask: 'add-task',
    companyInfo: 'company-info',
  },
  actions: {
    index: 'actions',
    employeesInvite: 'employees-invite',
    offboarding: 'employees-offboarding',
    createPost: 'add-post',
    createDocument: 'add-document',
    createNotification: 'add-notification',
    employees: {
      index: 'employees',
      employee: {
        index: ':employeeId',
      },
    },
    subscriptions: 'subscriptions',
    createTask: 'add-task',
    createEquipment: 'add-equipment',
    createPoll: 'add-poll',
  },
  auth: {
    index: 'auth',
    signIn: 'sign-in',
    teamSelect: 'team-select',
    registration: {
      index: 'registration',
      success: 'success',
    },
    signUp: {
      index: 'sign-up',
      expired: 'expired',
    },
    requestPasswordReset: 'request-password-reset',
    createPassword: 'create-password',
    resetExpired: 'reset-expired',
    finishActivation: 'finish-activation',
  },
  confirmAction: 'confirm-action',
} as const;

export type PathsType = typeof Paths;

export const Links = getLinks(Paths);
