export const accomplishedDialogSx = {
  '@media (max-width: 1023px)': {
    '& .MuiPaper-root': {
      width: '100%',
      marginRight: '16px',
      marginLeft: '16px',
      padding: '16px',

      '& .MuiDialogTitle-root': {
        marginTop: '0',
      },

      '& .MuiDialogActions-root': {
        marginTop: '8px',
      },
    },
  },
};

export const headerContainerSx = {
  maxWidth: '1106px',
};

export const headerInnerSx = {
  maxWidth: '100%',
};

export const headerTitleSx = {
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
