import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from 'hooks';

export function AuthRouteWrapper() {
  const { user } = useAuth();

  const prevUrl = sessionStorage.getItem('beforeLoginUrl') || '/';

  return user ? <Navigate to={prevUrl} replace /> : <Outlet />;
}
