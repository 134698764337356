import { ReactElement, SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { PostListItem, useTargetDetailsQuery } from 'api';
import birthdayPost from 'assets/images/birthdayPost.png';
import welcomePost from 'assets/images/welcomePost.png';
import {
  Avatar,
  VideoGallery,
  ListItemDocumentAttachment,
  ListItemImageAttachment,
  Spinner,
  VideoAttachment,
  ImageGallery,
} from 'components';
import { ReactionsWrapper } from 'components/ReactionsWrapper';
import { TIME_DATE_FORMAT_WITH_COMMA, TIME_DATE_MONTH_WITH_COMMA } from 'constants/index';
import { TargetItem } from 'features/kpi';
import { usePostDetailsPage } from 'features/postDetails/containers/PostDetailsPage/usePostDetailsPage';
import { PollOptions } from 'features/postsList/components/PostListItem/PollOptions';
import { PollResults } from 'features/postsList/components/PostListItem/PollResults';
import { ReactionConfirmDialog } from 'features/postsList/components/ReactionConfirmDialog';
import { useModal, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { formattedDate, getFullName } from 'utils';

import {
  AttachmentsW,
  AuthorName,
  BirthdayImage,
  ContainerW,
  DateTime,
  DescriptionW,
  DetailsCard,
  FooterW,
  LinkW,
  PollDescription,
  TargetW,
  WelcomeImage,
} from './styled';
import {
  attachmentStyles,
  avatarWrapperStyles,
  avatarWrapperMobileStyles,
  attachmentMobileStyles,
} from './styles';

interface PostDetailsProps {
  post: PostListItem;
  isWelcomePost: boolean;
  isBirthdayPost: boolean;
  isPollPost: boolean;
  isKpiTargetPost: boolean;
  targetId?: string;
}

export function PostDetails({
  post,
  isWelcomePost,
  isKpiTargetPost,
  isBirthdayPost,
  isPollPost,
  targetId = '',
}: PostDetailsProps): ReactElement {
  const {
    isOpen: isOpenGallery,
    handleOpenModal: handleOpenModalGallery,
    handleCloseModal: handleCloseModalGallery,
  } = useModal();

  const {
    isOpen: isOpenVideoGallery,
    handleOpenModal: handleOpenModalVideoGallery,
    handleCloseModal: handleCloseModalVideoGallery,
  } = useModal();

  const {
    isOpen: isOpenReaction,
    handleOpenModal: handleOpenModalReaction,
    handleCloseModal: handleCloseModalReaction,
  } = useModal();

  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, data } = useTargetDetailsQuery(targetId);

  const { description, attachments, id, author, published_at = '', poll, reactions_stats } = post;
  const { first_name, last_name, id: authorId, avatar } = author;
  const imagesList = attachments?.filter((attachment) => attachment.type === 'image') || [];
  const videosList = attachments?.filter((attachment) => attachment.type === 'video') || [];
  const dateFormat =
    isMobile && !isKpiTargetPost ? TIME_DATE_MONTH_WITH_COMMA : TIME_DATE_FORMAT_WITH_COMMA;
  const attachStyles = isMobile ? attachmentMobileStyles : attachmentStyles;
  const authorName = getFullName({
    firstName: author.first_name,
    lastName: author.last_name,
  });

  const { post: pollFromPost } = usePostDetailsPage(post?.meta?.poll_id || '');

  const onNavigateToNewUser = () => {
    navigate(
      generatePath(Links.we.employees.employee.index, {
        employeeId: authorId,
      }),
    );
  };

  function handleLinkClick(e: SyntheticEvent) {
    e.stopPropagation();
    navigate(Links.we.kpi.index);
  }

  if (isLoading && targetId) {
    return <Spinner />;
  }

  // TODO: refactoring: Move blocks to its components
  // - kpi post details
  // isWelcome post details
  return (
    <ContainerW>
      <DetailsCard>
        <DescriptionW>
          {isWelcomePost && (
            <Trans
              i18nKey="post.listItem.welcomePost.description"
              values={{ linkLabel: authorName }}
            >
              We have some great news to share. Our family grows! Take a chance to say hello to{' '}
              <LinkW
                onClick={(e) => {
                  e.stopPropagation();
                  onNavigateToNewUser();
                }}
              >
                {authorName}
              </LinkW>
            </Trans>
          )}
          {isBirthdayPost && (
            <Trans i18nKey="post.listItem.birthdayPost.description" values={{ authorName }}>
              {' '}
              It&quote;s
              <LinkW
                onClick={(e) => {
                  e.stopPropagation();
                  onNavigateToNewUser();
                }}
              >
                {authorName}
              </LinkW>
              birthday today! Congratulations! 🥳
            </Trans>
          )}

          {isPollPost && pollFromPost && (
            <PollDescription>{t('post.listItem.pollPost.description')}</PollDescription>
          )}
          {isKpiTargetPost && (
            <>
              <span>
                {t('completedKpiTargetPost.description1')}{' '}
                {t('completedKpiTargetPost.description2')}
              </span>
              <TargetW>
                {data?.data && !isLoading && <TargetItem {...data?.data} isFromPost />}
              </TargetW>
              <div>
                <LinkW onClick={handleLinkClick}>{t('completedKpiTargetPost.link')}</LinkW>
              </div>
            </>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: (!isWelcomePost && !isKpiTargetPost && description) || '',
            }}
          />
        </DescriptionW>

        {isWelcomePost && <WelcomeImage src={welcomePost} alt="welcome post image" />}
        {isBirthdayPost && <BirthdayImage src={birthdayPost} alt="birthday post image" />}
        {attachments && (
          <AttachmentsW>
            {attachments.map(({ type, id: attachmentId, file, file_url }) =>
              type === 'image' ? (
                <ListItemImageAttachment
                  postId={id}
                  key={attachmentId}
                  attachmentId={attachmentId}
                  fileName={file.small}
                  fileUrl={file_url?.small}
                  wrapperSx={attachStyles}
                  onOpenGallery={handleOpenModalGallery}
                />
              ) : type === 'video' ? (
                <VideoAttachment
                  onOpenGallery={handleOpenModalVideoGallery}
                  postId={id}
                  attachmentId={file.video_uid}
                  wrapperSx={attachStyles}
                />
              ) : (
                <ListItemDocumentAttachment
                  postId={id}
                  key={attachmentId}
                  documentId={attachmentId}
                  fileName={file.original}
                  fileUrl={file_url?.original}
                  wrapperSx={attachStyles}
                />
              ),
            )}
          </AttachmentsW>
        )}

        {poll && (
          <PollOptions {...poll} postId={id} isDetails hasMarginTop={!!attachments?.length} />
        )}

        {isPollPost && pollFromPost?.poll?.options && (
          <PollResults options={pollFromPost.poll?.options} />
        )}

        {!isWelcomePost && !isKpiTargetPost && !isBirthdayPost && !isPollPost && (
          <Avatar
            size={isMobile ? 'xs' : 's'}
            userId={authorId}
            filename={avatar?.small || ''}
            wrapperSx={isMobile ? avatarWrapperMobileStyles : avatarWrapperStyles}
          />
        )}
        <ReactionsWrapper
          reactions={reactions_stats}
          handleOpenModal={handleOpenModalReaction}
          id={id}
          isPostDetails
        />
      </DetailsCard>

      <FooterW>
        {!isWelcomePost && !isKpiTargetPost && !isBirthdayPost && !isPollPost && (
          <AuthorName>{getFullName({ firstName: first_name, lastName: last_name })}</AuthorName>
        )}
        <DateTime>{formattedDate(new Date(published_at), dateFormat)}</DateTime>
      </FooterW>
      {isOpenGallery && (
        <ImageGallery
          open={isOpenGallery}
          onClose={handleCloseModalGallery}
          postId={id}
          imageList={imagesList}
        />
      )}
      {isOpenVideoGallery && (
        <VideoGallery
          open={isOpenVideoGallery}
          onClose={handleCloseModalVideoGallery}
          postId={id}
          videoList={videosList}
        />
      )}

      <ReactionConfirmDialog
        title={t('reactions.title')}
        onClose={handleCloseModalReaction}
        postId={id}
        open={isOpenReaction}
      />
    </ContainerW>
  );
}
