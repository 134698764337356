import { ReactElement, ReactNode } from 'react';

import { EquipmentPayload } from './equipments';

export interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export type EmployeesTableFields = {
  id: string;
  avatar: ReactElement;
  name: ReactElement;
  email: string;
  phone: string;
  birthDate?: string;
  actions?: ReactNode;
  address?: ReactNode;
  offboarding?: ReactNode;
};

export type EmployeesMobileTableFields = {
  id: string;
  avatar: ReactElement;
  name: ReactElement;
  email: string;
  address?: ReactNode;
  actions?: ReactNode;
  offboarding?: ReactNode;
};

export interface EditEmployeeFormValues {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phone: string;
  birthDate: string | null; // or date or null
  department: string;
  position: string;
  address1: string;
  address2: string;
  city: string;
  region: string;
  postCode: string;
  country: string;

  // privateInfo
  ssn: string;
  employmentDate: string | null; // or date or null
  employmentNumber: string;
  education: string;
  clothesSize: ClothesSize | string;
  allergies: string;
  comments: string;

  // Equipment
  equipment: EquipmentPayload[];

  // Licenses
  licenses: string[];

  // Emergency contact
  relation?: UserEmergencyRelation | string;
  relationContactFirstName: string;
  relationContactLastName: string;
  relationCountryCode: string;
  relationContactPhone: string;
}

// export enum UserStatus {
//   Active = 'active',
//   Inactive = 'inactive',
//   Invited = 'invited',
// }

export enum UserStatus {
  Active = 'active',
  Inactive = 'disabled',
  Invited = 'pending',
  Registered = 'registered',
}

export enum UserRole {
  Employee = 'employee',
  Manager = 'manager',
}

export enum UserEmergencyRelation {
  Parent = 'parent',
  Child = 'child',
  Sibling = 'sibling',
  Friend = 'friend',
  Partner = 'partner',
  Other = 'other',
}

export enum ClothesSize {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = 'xxxl',
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  countryCode: number;
  phone: string;
  avatar?: {
    small: string;
    medium: string;
  };
  position: string;
  department?: string;
  birthDate?: string;
  address1?: string;
  address2?: string;
  postcode?: string;
  city?: string;
  region?: string;
  country?: string;
  status: UserStatus;
  role: UserRole[] | UserRole;
  invitedById?: string;
}

export interface UserExtendedDetails {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  countryCode: number;
  phone: string;
  avatar?: {
    small: string;
    medium: string;
  };
  position: string;
  birthDate?: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  region: string;
  country: string;
  department: string;
  ssn: string;
  employmentNumber: string;
  employedAt: string;
  education: string;
  equipment?: EquipmentPayload[];
  licenses?: string[];
  clothesSize?: ClothesSize | string;
  allergies: string;
  comments: string;
  emergency?: {
    firstName?: string;
    lastName?: string;
    countryCode?: number | string;
    phone?: string;
    relation?: UserEmergencyRelation | string;
  };
  status: UserStatus;
  statusComment: string;
  role: UserRole[];
  createdAt: string;
  updatedAt: string;
}

export interface RemoveEmployeeArgument {
  id: string;
  status: UserStatus;
  userName: string;
}
