import { theme } from 'styles';

export const fieldStyles = {
  marginBottom: '24px',

  '.MuiInputBase-input': {
    fontSize: 24,
  },

  '.MuiInputBase-root.Mui-error': {
    border: `2px solid ${theme.palette.error.main}`,

    '.MuiInputBase-input': {
      paddingTop: '16px',
      boxSizing: 'border-box',
    },
  },

  '.MuiFormHelperText-root.Mui-error': {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.error.main,
    position: 'absolute',
    top: '15px',
    left: '37px',
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: '8px',
    '.MuiFormHelperText-root.Mui-error': {
      top: '-18px',
      left: '0px',
    },
    '.MuiInputBase-input': {
      fontSize: 18,
    },
  },
};
