export const DocumentCategoryColors: Record<string, string> = {
  airplane: '#99D7FA',
  book: '#FE9899',
  calendar: '#00FFB2',
  chart: '#FF9900',
  computing: '#00DED1',
  devices: '#FFE299',
  document: '#0044C7',
  edit: '#DB00FF',
  heart: '#FE9899',
  money: '#0DB6B8',
  people: '#FFE299',
  teacher: '#009BF2',
  creditCard: '#B4CCB9',
  achivement: '#FAB353',
  law: '#AB5D4A',
  print: '#90CAF9',
  gifts: '#4D902E',
  connectivity: '#38B9FF',
  gallery: '#2CB8F4',
  camera: '#8667C4',
  secure: '#D171C7',
  cloud: '#34A853',
  food: '#EA9860',
  folder: '#B08A77',
  contract: '#EFF2FA',
  letters: '#E5A864',
  announcement: '#F38E7A',
  company: '#C2CDDC',
  idea: '#FFECBA',
  target: '#F86C6C',
};
