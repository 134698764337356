import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useModal } from 'hooks';

import { CalculationDialog } from '../CalculationDialog';
import { ContainerW, LinkW } from './styled';

export function CalculationBanner(): ReactElement {
  const { t } = useTranslation();

  const { isOpen, handleCloseModal, handleOpenModal } = useModal();

  return (
    <>
      <ContainerW>
        <Trans i18nKey="calculator.bannerNote" values={{ linkLabel: t('common.clickHere') }}>
          Please note that the final price includes the corresponding plan fee for 1 to 20 employees
          and extra employee rate for each employee over 20.
          <LinkW onClick={handleOpenModal}>{t('common.clickHere')}</LinkW> to calculate the final
          price.
        </Trans>
      </ContainerW>
      <CalculationDialog open={isOpen} handleClose={handleCloseModal} />
    </>
  );
}
