import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { RegisterCompanyFormSteps } from 'types';

import { FirstStep, Step, StepsW } from './styled';

interface FormStepperProps {
  step: RegisterCompanyFormSteps;
  setStep: (step: RegisterCompanyFormSteps) => void;
}

export function FormStepper({ step, setStep }: FormStepperProps): ReactElement {
  const { t } = useTranslation();
  function handleGoBack() {
    if (step !== RegisterCompanyFormSteps.COMPANY_PROFILE) {
      return;
    }

    setStep(RegisterCompanyFormSteps.PERSONAL_DATA);
  }

  return (
    <StepsW>
      <FirstStep
        isCurrentStep={step === RegisterCompanyFormSteps.PERSONAL_DATA}
        onClick={handleGoBack}
      >
        {t('companyRegistration.steps.personal')}
      </FirstStep>
      <Step isCurrentStep={step === RegisterCompanyFormSteps.COMPANY_PROFILE}>
        {t('companyRegistration.steps.company')}
      </Step>
    </StepsW>
  );
}
