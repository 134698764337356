import { isValid } from 'date-fns';
import { RegisterOptions } from 'react-hook-form';

import { EQUIPMENT_FORM_FIELDS_LENGTH } from 'constants/index';
import {
  FUTURE_DATES_ONLY,
  INVALID_DATE,
  PAST_DATES_ONLY,
  getMaxLengthText,
  getMinLengthText,
  required,
} from 'validations';

export const titleFieldRules: RegisterOptions = {
  ...required,
  minLength: {
    value: EQUIPMENT_FORM_FIELDS_LENGTH.title.min,
    message: getMinLengthText(EQUIPMENT_FORM_FIELDS_LENGTH.title.min),
  },
  maxLength: {
    value: EQUIPMENT_FORM_FIELDS_LENGTH.title.max,
    message: getMaxLengthText(EQUIPMENT_FORM_FIELDS_LENGTH.title.max),
  },
};

export const commentFieldRules: RegisterOptions = {
  minLength: {
    value: EQUIPMENT_FORM_FIELDS_LENGTH.comment.min,
    message: getMinLengthText(EQUIPMENT_FORM_FIELDS_LENGTH.comment.min),
  },
  maxLength: {
    value: EQUIPMENT_FORM_FIELDS_LENGTH.comment.max,
    message: getMaxLengthText(EQUIPMENT_FORM_FIELDS_LENGTH.comment.max),
  },
};

export const onlyFutureDates = (value: string) => {
  if (!value) return true;

  const todayDate = new Date();
  const selectedDate = new Date(value);
  return selectedDate > todayDate || FUTURE_DATES_ONLY;
};

export const onlyPastAndCurrentDates = (value: string) => {
  if (!value) return true;

  const todayDate = new Date();
  const selectedDate = new Date(value);

  return selectedDate <= todayDate || PAST_DATES_ONLY;
};

export const invalidDate = (value: string) => {
  if (!value) return true;

  const selectedDate = new Date(value);

  return isValid(selectedDate) || INVALID_DATE;
};

export const customFieldNameRules: RegisterOptions = {
  minLength: {
    value: EQUIPMENT_FORM_FIELDS_LENGTH.customFieldName.min,
    message: getMinLengthText(EQUIPMENT_FORM_FIELDS_LENGTH.customFieldName.min),
  },
  maxLength: {
    value: EQUIPMENT_FORM_FIELDS_LENGTH.customFieldName.max,
    message: getMaxLengthText(EQUIPMENT_FORM_FIELDS_LENGTH.customFieldName.max),
  },
};

export const customFieldValueRules: RegisterOptions = {
  minLength: {
    value: EQUIPMENT_FORM_FIELDS_LENGTH.customFieldValue.min,
    message: getMinLengthText(EQUIPMENT_FORM_FIELDS_LENGTH.customFieldValue.min),
  },
  maxLength: {
    value: EQUIPMENT_FORM_FIELDS_LENGTH.customFieldValue.max,
    message: getMaxLengthText(EQUIPMENT_FORM_FIELDS_LENGTH.customFieldValue.max),
  },
};
