import { DialogProps } from '@mui/material/Dialog';
import { default as ImageGalleryComponent, ReactImageGalleryItem } from 'react-image-gallery';

import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/imageDownload.svg';
import { PrivateDocumentsAttachments } from 'features/employeeDetails/components/PrivateDocuments/types';
import { Attachment } from 'types';

import { Spinner } from '../Spinner';
import { useImageGallery } from './hooks';
import { Dialog, DialogContent, DialogActions, ButtonW } from './styled';

import 'react-image-gallery/styles/css/image-gallery.css';
import './styles.css';

type ImageGallery = DialogProps & {
  onClose: () => void;
  imageList: Attachment[] | PrivateDocumentsAttachments[];
  postId?: string;
};

export const ImageGallery = ({ open, onClose, imageList, postId }: ImageGallery) => {
  const { images, setActiveIndex, handleDownloadClick, isLoading } = useImageGallery({
    onClose,
    imageList,
    postId,
    open,
  });

  return (
    <>
      <Dialog open={open} fullScreen={true} onClick={(e) => e.stopPropagation()}>
        <DialogActions>
          <ButtonW
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadClick();
            }}
          >
            <DownloadIcon />
          </ButtonW>
          <ButtonW
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <CloseIcon />
          </ButtonW>
        </DialogActions>
        <DialogContent>
          {isLoading && !images ? (
            <Spinner />
          ) : (
            <ImageGalleryComponent
              items={(images as ReactImageGalleryItem[]) || []}
              showThumbnails={true}
              showPlayButton={false}
              showFullscreenButton={false}
              onSlide={(activeIndex) => setActiveIndex(activeIndex)}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
