import { CalendarTypes } from '../types';

export const API_PATHS = {
  AUTH: {
    REGISTER: (): string => '/auth/register',
    CONFIRM_REGISTRATION: (): string => '/auth/confirm-registration',
    VALIDATE_OTT: (): string => 'auth/validate-ott',
    LOGIN: (): string => '/auth/login',
    LOGOUT: (): string => '/auth/logout',
    REFRESH_TOKEN: (): string => '/auth/refresh-token',
    FORGOT_PASSWORD: (): string => '/auth/forgot-password',
    RESTORE_PASSWORD: (): string => '/auth/restore-password',
    FINISH_ACTIVATION: (): string => '/auth/finish-activation',
    EMAILVALIDATE: (): string => '/auth/validate-email',
    SWITCH_COMPANY: (companyId: string): string => `/auth/switch-company/${companyId}`,
  },
  USER: {
    DETAILS: (userId: string): string => `/users/${userId}`,
    DEACTIVATE: (userId: string): string => `/users/${userId}/deactivate`,
    INVITE: (): string => '/users/invite',
    EDIT: (userId: string): string => `/users/${userId}`,
    CHANGE_AVATAR: (): string => 'users/avatar',
    GET_AVATAR: (userId: string, filename: string): string => `/users/${userId}/avatar/${filename}`,
    ACTIVATE: (userId: string): string => `/users/${userId}/activate`,
    ACTIVATE_PENDING: (userId: string): string => `/users/${userId}/register`,
    RE_INVITE: (userId: string): string => `/users/${userId}/re-invite`,
    EQUIPMENTS: (userId: string): string => `/users/${userId}/equipment`,
    EQUIPMENT: ({ userId, equipmentId }: { userId: string; equipmentId: string }): string =>
      `/users/${userId}/equipment/${equipmentId}`,
    REQUEST_EQUIPMENT: (userId: string): string => `/users/${userId}/equipment/request`,
    GET_COMPANIES: () => `/users/companies`,
    REGISTER_REQUESTED_EQUIPMENT: ({
      userId,
      equipmentId,
    }: {
      userId: string;
      equipmentId: string;
    }): string => `/users/${userId}/equipment/${equipmentId}/register`,
  },
  USERS: {
    _: (): string => '/users',
  },
  CATEGORY: {
    CREATE: (): string => '/categories',
    LIST: (): string => '/categories',
    DETAILS: (categoryId: string): string => `/categories/${categoryId}`,
    DELETE: (categoryId: string): string => `/categories/${categoryId}`,
  },
  POST: {
    CREATE_DRAFT: (): string => '/posts',
    PUBLISH: (postId: string): string => `/posts/${postId}/publish`,
    LIST: (): string => '/posts',
    DETAILS: (postId: string): string => `/posts/${postId}`,
    DELETE: (postId: string): string => `/posts/${postId}`,
    ANSWER: (postId: string): string => `/posts/${postId}/answer`,
  },
  PRODUCTS: {
    LIST: (): string => '/products',
    DETAILS: (productId: string): string => `/products/${productId}`,
  },
  SUBSCRIPTION: {
    _: (): string => '/subscriptions',
    CANCEL: (): string => '/subscriptions/cancel',
  },
  LOCATION: (): string => 'https://api.country.is/',
  COMMENTS: {
    CREATE: (postId: string): string => `/posts/${postId}/comments`,
    LIST: (postId: string): string => `/posts/${postId}/comments`,
  },
  COMPANY: {
    DETAILS: (): string => 'company',
    EDIT: (): string => 'company',
  },
  PAYMENT: {
    DETAILS: (payment_method_id: string): string => `/payments/methods/${payment_method_id}`,
  },
  CALENDAR_AUTH: {
    SIGN_IN: (provider: CalendarTypes): string => `/calendar/${provider}/auth`,
    GET_EVENTS: (provider: CalendarTypes): string => `/calendar/${provider}/events`,
  },
  KPI: {
    DETAILS: (): string => '/company/kpi',
    TARGET_DETAILS: (targetId: string): string => `/company/kpi/target/${targetId}`,
    ADD_VISION: (): string => '/company/kpi/vision',
    ADD_TARGET: (): string => '/company/kpi/target',
    EDIT_TARGET: (targetId: string): string => `/company/kpi/target/${targetId}`,
    DELETE: (targetId: string): string => `/company/kpi/target/${targetId}`,
    CHANGE_TARGETS_ORDER: (): string => `/company/kpi/target/order`,
  },
  QUICK_LINKS: {
    USER_LINKS: (): string => '/users/quick-links',
    LINKS_PRESET: (): string => '/users/quick-links/preset',
    ADD_LINK_FROM_PRESET: (linkId: string): string => `/users/quick-links/preset/${linkId}`,
    CREATE_LINK: (): string => '/users/quick-links',
    LINK_DETAILS: (linkId: string): string => `/users/quick-links/${linkId}`,
    DELETE_LINK: (linkId: string): string => `/users/quick-links/${linkId}`,
    EDIT_LINK: (linkId: string): string => `/users/quick-links/${linkId}`,
    CHANGE_LINKS_ORDER: (): string => `/users/quick-links/order`,
  },
  DEMO: {
    CLEAR: (): string => '/company/demo',
  },
  NOTIFICATIONS: {
    LIST: () => '/notifications',
    COUNTER: () => '/notifications/unread',
    READ: () => '/notifications/read',
    CREATE: () => '/notifications',
    ACTIVATE: (id: string) => `/notifications/${id}/action`,
  },
  ATTACHMENTS: {
    UPLOAD: (): string => `/attachments`,
    VIDEO_UPLOAD: (): string => `/attachments/video`,
  },
  WEBPUSH: {
    SUBSCRIBE: () => '/notifications/web-push/subscribe',
  },
  REACTIONS: {
    GET_LIST: (postId: string): string => `/posts/${postId}/reactions`,
    CREATE: (postId: string): string => `/posts/${postId}/reactions`,
    DELETE: (postId: string): string => `/posts/${postId}/reactions`,
  },
  ACHIEVEMENTS: {
    GET_LIST: (userId: string): string => `/users/${userId}/achievements`,
    CREATE: (userId: string): string => `/users/${userId}/achievements`,
    CREATE_GOAL: (userId: string, achievementId: string): string =>
      `/users/${userId}/achievements/${achievementId}/goal`,
    ACHIEVEMENT_DETAILS: ({
      userId,
      achievementId,
    }: {
      userId: string;
      achievementId: string;
    }): string => `/users/${userId}/achievements/${achievementId}`,
    EDIT_ACHIEVEMENT: ({
      userId,
      achievementId,
    }: {
      userId: string;
      achievementId: string;
    }): string => `/users/${userId}/achievements/${achievementId}`,
    DELETE_ACHIEVEMENT: ({
      userId,
      achievementId,
    }: {
      userId: string;
      achievementId: string;
    }): string => `/users/${userId}/achievements/${achievementId}`,
    GOAL_DETAILS: ({
      userId,
      achievementId,
      goalId,
    }: {
      userId: string;
      achievementId: string;
      goalId: string;
    }): string => `/users/${userId}/achievements/${achievementId}/goal/${goalId}`,
    EDIT_GOAL: ({
      userId,
      achievementId,
      goalId,
    }: {
      userId: string;
      achievementId: string;
      goalId: string;
    }): string => `/users/${userId}/achievements/${achievementId}/goal/${goalId}`,
    DELETE_GOAL: ({
      userId,
      achievementId,
      goalId,
    }: {
      userId: string;
      achievementId: string;
      goalId: string;
    }): string => `/users/${userId}/achievements/${achievementId}/goal/${goalId}`,
    COMMENT_GOAL: ({
      userId,
      achievementId,
      goalId,
    }: {
      userId: string;
      achievementId: string;
      goalId: string;
    }): string => `/users/${userId}/achievements/${achievementId}/goal/${goalId}/comment`,
  },
};
