import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { CreateCustomNotificationRequestParams } from 'types';

interface UseCreateCustomNotificationMutationReturn {
  createCustomNotification: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    CreateCustomNotificationRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseCreateCustomNotificationMutationProps {
  successText: string;
  setIsSuccess?: Dispatch<SetStateAction<boolean>>;
  invalidateQueries?: string[];
}

export function useCreateCustomNotificationMutation({
  successText,
  setIsSuccess,
  invalidateQueries,
}: UseCreateCustomNotificationMutationProps): UseCreateCustomNotificationMutationReturn {
  const { mutateAsync: createCustomNotification, isLoading } = useMutation(
    API_HANDLERS.NOTIFICATIONS.CREATE,
    {
      onSuccess: () => {
        setIsSuccess && setIsSuccess(true);
        alert.success(successText);

        if (invalidateQueries) {
          queryClient.invalidateQueries(invalidateQueries);
        }
      },
    },
  );

  return {
    createCustomNotification,
    isLoading,
  };
}
