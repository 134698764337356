import { IconButton, styled, Typography } from '@mui/material';

export const ContainerW = styled('div')`
  width: 48%;
  margin-bottom: 35px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 15px;
  padding: 24px 16px;
  position: relative;

  @media (max-width: 1023px) {
    padding: 20px 16px 16px;
    width: 100%;
  }
`;

export const DaysW = styled('div')``;

export const HeaderW = styled('div')`
  display: flex;
  align-items: center;
`;

export const IconButtonW = styled(IconButton, {
  shouldForwardProp: (propname) => propname !== 'isActive',
})<{ isActive: boolean }>(({ isActive, theme }) => ({
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '16px',
  width: '26px',
  height: '26px',
  color: isActive ? theme.palette.primary.p080 : theme.palette.primary.p040,
}));

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-transform: none;
  margin-right: 8px;
`;

export const CalendarLink = styled('a')`
  position: absolute;
  top: 17px;
  right: 17px;
`;
