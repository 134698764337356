import { Button, styled } from '@mui/material';

export const SubmitBtnW = styled('div')`
  margin-left: auto;

  @media (max-width: 1023px) {
    margin-top: 32px;
  }
`;

export const FormContainer = styled('div')`
  width: 671px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 36px 25px;
  position: relative;
  z-index: 999;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
    margin-bottom: 110px;
  }
`;

export const RemoveButtonW = styled(Button)`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    text-transform: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.palette.primary.p080};
    position: absolute;
    bottom: 14px;
    left: 0;
    display: flex;
  }
`;

export const LabelW = styled('label')`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  width: 56px;
  max-width: 56px;
  white-space: nowrap;
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.primary.main};

  @media (max-width: 1023px) {
    width: 48px;
  }
`;

export const InputW = styled('input')`
  width: 20px;
  height: 20px;
`;

export const CheckboxContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const SubmitBlock = styled('div')`
  display: flex;
  flex-direction: row;
`;
