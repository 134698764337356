import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useUsersListQuery } from 'api';
import { PageHeaderBoldTitle, PageWrapper, Spinner } from 'components';
import { useCreateDraft } from 'hooks';
import { EquipmentTabsValues, PostDraft } from 'types';

import { RegisterEquipmentForm, RequestEquipmentForm, TabPanel, Tabs } from '../../components';
import { createEquipmentsTabs } from '../../components/constants';
import { defaultValuesAdapter } from './adapter';
import { useEquipmentTabs } from './hooks';
import { ContentW, FormW } from './styled';

export function CreateEquipmentPage(): ReactElement {
  const { t } = useTranslation();
  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});
  const { activeTab, handleChange } = useEquipmentTabs();
  const { draft, isLoading: isDraftLoading } = useCreateDraft();

  const defaultValues = (isDefaultEditable: boolean, draft?: PostDraft) =>
    defaultValuesAdapter({ isDefaultEditable, draft });

  if (isLoadingUsersList || isDraftLoading) {
    return <Spinner />;
  }

  return (
    <PageWrapper>
      <PageHeaderBoldTitle title={t('equipments.create.title')} />
      <ContentW>
        <FormW>
          <Tabs activeTab={activeTab} tabs={createEquipmentsTabs} handleChange={handleChange} />
          <TabPanel path={EquipmentTabsValues.REGISTER} value={activeTab}>
            <RegisterEquipmentForm
              draft={draft}
              usersList={usersList}
              defaultValues={defaultValues}
            />
          </TabPanel>
          <TabPanel path={EquipmentTabsValues.REQUEST} value={activeTab}>
            <RequestEquipmentForm
              draft={draft}
              usersList={usersList}
              defaultValues={defaultValues}
            />
          </TabPanel>
        </FormW>
      </ContentW>
    </PageWrapper>
  );
}
