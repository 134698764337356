import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';

import { PublishPostRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UsePublishPostMutationReturn {
  publishPostMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    PublishPostRequestParams,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
}

interface UsePublishPostMutationProps {
  successText: string;
  setIsSuccess?: Dispatch<SetStateAction<boolean>>;
  invalidateQueries?: string[];
}

export function usePublishPostMutation({
  successText,
  setIsSuccess,
  invalidateQueries,
}: UsePublishPostMutationProps): UsePublishPostMutationReturn {
  const {
    mutateAsync: publishPostMutation,
    isLoading,
    isSuccess,
  } = useMutation(API_HANDLERS.POST.PUBLISH, {
    onSuccess: () => {
      setIsSuccess && setIsSuccess(true);
      alert.success(successText);

      if (invalidateQueries) {
        queryClient.invalidateQueries(invalidateQueries);
      }
    },
  });

  return {
    publishPostMutation,
    isLoading,
    isSuccess,
  };
}
