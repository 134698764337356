import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { Links } from 'settings';

import { ContentW, LinkW } from './styled';

export function EmptyState(): ReactElement {
  return (
    <ContentW>
      <Trans i18nKey="we.posts.list.emptyState">
        No updates yet. Be the first one to <LinkW to={Links.we.createPost}>post</LinkW>!
      </Trans>
    </ContentW>
  );
}
