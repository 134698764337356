import { ReactElement } from 'react';

import { TaskFormValues, User } from 'types';

import { TaskForm } from '../TaskForm';
import { useCreateTaskForm } from './hooks';

interface CreateTaskFormProps {
  draftId: string;
  defaultValues: TaskFormValues;
  usersList: User[];
}

export function CreateTaskForm({
  draftId,
  defaultValues,
  usersList,
}: CreateTaskFormProps): ReactElement {
  const { handleSubmit, isLoading } = useCreateTaskForm();

  return (
    <TaskForm
      mode="create"
      handleSubmit={handleSubmit}
      draftId={draftId}
      defaultValues={defaultValues}
      isSubmitting={isLoading}
      usersList={usersList}
    />
  );
}
