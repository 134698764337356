import { ReactElement } from 'react';

import { EmployeesTable } from '../../components';
import { PageW, TableW } from './styled';

export function EmployeesPage(): ReactElement {
  return (
    <PageW>
      <TableW>
        <EmployeesTable />
      </TableW>
    </PageW>
  );
}
