import { QuickLinkFormValues } from 'types';
import { removeExtraSpaces } from 'utils';

import { useCreateQuickLinkMutation } from './useCreateQuickLinkMutation';

export function useCreateLink() {
  const { createLinkMutation, isLoading } = useCreateQuickLinkMutation();

  async function handleSubmit(data: QuickLinkFormValues) {
    const { title, ...otherProps } = data;
    const titleValue = removeExtraSpaces(title);

    await createLinkMutation({
      title: titleValue,
      ...otherProps,
    });
  }

  return { handleSubmit, isLoading };
}
