import { useTranslation } from 'react-i18next';

import { Product, ProductDetails, SubscriptionsPeriod } from 'types';

interface UseProductDetailsProps {
  mainProduct: Product;
  extraProduct: Product;
  period: SubscriptionsPeriod;
  productDetails?: ProductDetails;
}

export function useProductDetails({
  mainProduct,
  productDetails,
  extraProduct,
  period,
}: UseProductDetailsProps) {
  const { t } = useTranslation();

  const teamSize = t(`subscriptions.types.${mainProduct.name.toLocaleLowerCase()}.teamSize`);
  const duration = t(`subscriptions.types.${mainProduct.name.toLocaleLowerCase()}.duration`);
  const productName = t(`subscriptions.types.${mainProduct.name.toLocaleLowerCase()}.name`);
  const periodTranslation = t(`subscriptions.${period}`);

  const monthPriceId = productDetails ? Object.keys(productDetails.prices.month)[0] : '';

  const pricePerMonth = productDetails?.prices.month[monthPriceId].reduce<
    Record<string, { currency: string; price: number }>
  >((prices, { currency, price }) => {
    prices[currency] = { currency, price };
    return prices;
  }, {});

  const { price: mainPrice } = mainProduct;
  const { price: extraPrice } = extraProduct;

  const priceWithoutDiscount = pricePerMonth
    ? String(pricePerMonth[mainPrice.currency].price * 12)
    : '';

  return {
    name: productName,
    duration,
    extraPrice: `${extraPrice.currency.toUpperCase()} ${extraPrice.price} ${t(
      'subscriptions.extra',
    )}`,
    mainPrice: `${mainPrice.currency.toUpperCase()} ${mainPrice.price}/${periodTranslation}`,
    currency: extraPrice.currency,
    teamSize,
    priceBefore: `${extraPrice.currency.toUpperCase()} ${priceWithoutDiscount}/${periodTranslation}`,
  };
}
