import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { CommentGoalRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseCommentGoalMutationReturn {
  commentGoalMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    CommentGoalRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseCommentGoalMutationProps {
  successText?: string;
  invalidateQueries?: string[];
}

export function useCommentGoalMutation({
  successText,
  invalidateQueries,
}: UseCommentGoalMutationProps): UseCommentGoalMutationReturn {
  const { mutateAsync: commentGoalMutation, isLoading } = useMutation(
    API_HANDLERS.ACHIEVEMENTS.COMMENT_GOAL,
    {
      onSuccess: () => {
        successText && alert.success(successText);
        invalidateQueries && queryClient.invalidateQueries(invalidateQueries);
      },
    },
  );

  return {
    commentGoalMutation,
    isLoading,
  };
}
