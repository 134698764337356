import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { PostListItem } from 'api';
import {
  Avatar,
  ImageGallery,
  ListItemImageAttachment,
  ListItemVideoAttachment,
  VideoAttachment,
  VideoGallery,
} from 'components';
import { DocumentCategoryColors } from 'constants/DocumentCategoryColors';
import { Tag } from 'features/documentList/components/DocumentMainInfo/styled';
import { useModal } from 'hooks';
import { Attachment, CategoriesListType } from 'types';

import { DocumentAttachment } from '../DocumentAttachment';
import { OnlyDocumentsLayout } from '../OnlyDocumentsLayout';
import { OnlyImagesLayout } from '../OnlyImagesLayout';
import { wrapperStyles } from '../styles';
import { getDateValue } from './getDateValue';
import {
  ContainerW,
  DateW,
  DocumentW,
  FooterBlockLeft,
  FooterW,
  TagsCounter,
  TagW,
  TitleW,
} from './styled';
import { wrapperStylesOnlyVideo } from './styles';

interface DocumentListItemProps {
  document: PostListItem;
  categoryMap: CategoriesListType;
  handleViewMoreClick: (tags: string[]) => void;
}

export function DocumentListItem({
  document,
  handleViewMoreClick,
  categoryMap,
}: DocumentListItemProps): ReactElement {
  const { t } = useTranslation();

  const { isOpen, handleOpenModal, handleCloseModal } = useModal();
  const {
    isOpen: isOpenVideoGallery,
    handleOpenModal: handleOpenModalVideoGallery,
    handleCloseModal: handleCloseModalVideoGallery,
  } = useModal();
  const { title, author, attachments = [], tags = [] } = document;
  const firstTag = tags[0];
  const otherTagsCount = tags.length - 1;
  const tagName = firstTag.replace('-and-', ' & ');
  const categoryTag = categoryMap[tagName];

  const imagesList: Attachment[] = [];
  const videosList: Attachment[] = [];
  const documentsList: Attachment[] = [];

  attachments.forEach((file) => {
    if (file.type === 'document') {
      documentsList.push(file);
    }

    if (file.type === 'image') {
      imagesList.push(file);
    }

    if (file.type === 'video') {
      videosList.push(file);
    }
  });

  const onlyImages = attachments.length === imagesList.length;
  const onlyDocs = attachments.length === documentsList.length;
  const onlyVideos = attachments.length === videosList.length;

  const moreImages = imagesList.length > 1 ? imagesList.length - 1 : null;
  const moreFiles = documentsList.length > 1 ? documentsList.length - 1 : null;
  const moreVideos = videosList.length > 1 ? videosList.length - 1 : null;

  const image = attachments.find((attachment) => attachment.type === 'image');
  const documentFile = attachments.find((attachment) => attachment.type === 'document');
  const videoFile = attachments.find((attachment) => attachment.type === 'video');

  const isUpdated = Boolean(document.updated_by);
  const date = getDateValue({
    updated_at: document.updated_at,
    published_at: document.published_at || '',
    isUpdated,
  });

  function handleClick() {
    handleViewMoreClick(tags);
  }

  const dateDisplayValue = isUpdated
    ? t('document.list.updatedOn', {
        date,
      })
    : date;

  return (
    <DocumentW>
      <TitleW>{title}</TitleW>

      <ContainerW>
        {onlyImages && (
          <OnlyImagesLayout
            images={attachments}
            documentId={document.id}
            onOpenGallery={handleOpenModal}
            hasMoreImagesClick={handleClick}
          />
        )}
        {onlyDocs && (
          <OnlyDocumentsLayout
            documents={attachments}
            documentId={document.id}
            hasMoreFilesClick={handleClick}
          />
        )}
        {onlyVideos && videoFile && (
          <ListItemVideoAttachment
            video={videoFile}
            wrapperSx={wrapperStylesOnlyVideo}
            onOpenGallery={handleOpenModalVideoGallery}
            moreVideos={moreVideos}
          />
        )}
        {image && videoFile && (
          <>
            <VideoAttachment
              attachmentId={videoFile.file.video_uid}
              wrapperSx={wrapperStyles}
              onOpenGallery={handleOpenModalVideoGallery}
            />
            <ListItemImageAttachment
              attachmentId={image.id}
              fileName={image.file.small}
              fileUrl={image.file_url?.small}
              postId={document.id}
              onOpenGallery={handleOpenModal}
              hasMoreImagesClick={handleClick}
              moreImages={moreImages}
              wrapperSx={wrapperStyles}
            />
          </>
        )}
        {documentFile && videoFile && !image && (
          <>
            <ListItemVideoAttachment
              video={videoFile}
              wrapperSx={wrapperStyles}
              onOpenGallery={handleOpenModalVideoGallery}
              moreVideos={moreVideos}
            />
            <DocumentAttachment
              postId={document.id}
              documentId={documentFile.id}
              fileName={documentFile.file.original}
              fileUrl={documentFile.file_url?.original}
              moreFiles={moreFiles}
              hasMoreFilesClick={handleClick}
            />
          </>
        )}
        {image && documentFile && !videoFile && (
          <>
            <ListItemImageAttachment
              attachmentId={image.id}
              fileName={image.file.small}
              fileUrl={image.file_url?.small}
              postId={document.id}
              onOpenGallery={handleOpenModal}
              hasMoreImagesClick={handleClick}
              moreImages={moreImages}
              wrapperSx={wrapperStyles}
            />

            <DocumentAttachment
              postId={document.id}
              documentId={documentFile.id}
              fileName={documentFile.file.original}
              fileUrl={documentFile.file_url?.original}
              moreFiles={moreFiles}
              hasMoreFilesClick={handleClick}
            />
          </>
        )}
      </ContainerW>

      <FooterW>
        <FooterBlockLeft>
          <DateW>{dateDisplayValue}</DateW>
          {categoryTag && (
            <TagW>
              <Tag sx={{ backgroundColor: DocumentCategoryColors[categoryTag.icon], margin: 0 }}>
                {categoryTag.label}
              </Tag>
              {Boolean(otherTagsCount) && <TagsCounter>+{otherTagsCount}</TagsCounter>}
            </TagW>
          )}
        </FooterBlockLeft>
        <Avatar
          size="s"
          alt={author.first_name}
          userId={author.id}
          filename={author.avatar?.small || ''}
        />
      </FooterW>

      {isOpen && (
        <ImageGallery
          open={isOpen}
          onClose={handleCloseModal}
          postId={document.id}
          imageList={imagesList}
        />
      )}
      {isOpenVideoGallery && (
        <VideoGallery
          open={isOpenVideoGallery}
          onClose={handleCloseModalVideoGallery}
          videoList={videosList}
        />
      )}
    </DocumentW>
  );
}
