export const tabsStyles = {
  overflow: 'visible',
  '.MuiTabs-indicator': {
    width: '93px',
    height: '3px',
    bottom: 0,
    marginLeft: '4px',
    background: '#009BF2',
    borderRadius: '4px 4px 0px 0px',
  },
  '& .Mui-selected': {
    color: '#111827',
  },
};

export const tabItemStyles = {
  '&.Mui-disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'auto',
    opacity: 0.4,
  },
};
