import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';

interface UseActivateNotificationReturn {
  isLoading: boolean;
}

interface UseActivateNotificationProps {
  notificationId: string;
}

export function useActivateNotification({
  notificationId,
}: UseActivateNotificationProps): UseActivateNotificationReturn {
  const { isLoading } = useQuery(
    ['activate-notification', notificationId],
    () => API_HANDLERS.NOTIFICATIONS.ACTIVATE({ id: notificationId, action: 'birthday_post' }),
    {
      enabled: !!notificationId,
      cacheTime: 0,
    },
  );

  return {
    isLoading: isLoading,
  };
}
