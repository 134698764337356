export const avatarWrapperSx = {
  position: 'absolute',
  bottom: '-18px',
  left: '-11px',
};

export const avatarWrapperMobileSx = {
  position: 'absolute',
  bottom: '-30px',
  left: '0px',
};

export const selectSx = {
  '&.MuiInputBase-root.Mui-disabled .MuiSelect-select.MuiSelect-select': {
    background: 'none',
  },
};

export const multiSelectStyles = (isMobile: boolean) => ({
  height: 'auto',
  minHeight: isMobile ? '64px' : '85px',
  opacity: 1,
  '.MuiSelect-select.MuiSelect-select': {
    height: 'auto',
    padding: isMobile ? '12px 40px 12px 18px' : '20px 60px 20px 37px',
    '& > div': {
      margin: 0,
    },
  },
  '.MuiSvgIcon-root.MuiSelect-icon': {
    top: isMobile ? '12px' : '22px',
  },
  '&.MuiInputBase-root.Mui-disabled .MuiSelect-select.MuiSelect-select': {
    minHeight: isMobile ? '40px' : '45px',
  },
});
