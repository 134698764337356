import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AddAndExtendActions } from 'components';
import { useWindowSize } from 'hooks';
import { User } from 'types';

import { AchievementEmptyState, AchievementsList } from '../../components';
import { useProfileAchievements } from './hooks';
import { ContainerW, Header, Title } from './styled';

interface ProfileAchievementsProps {
  employeeId: string;
  usersList: User[];
}

export function ProfileAchievements({
  employeeId,
  usersList,
}: ProfileAchievementsProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const { achievements, isAchievementsList, addAchievementLink, viewAchieventsLink } =
    useProfileAchievements({ employeeId });

  return (
    <ContainerW>
      <Header>
        <Title>{t('career.achievements.title')}</Title>
        <AddAndExtendActions
          addIconLink={addAchievementLink}
          maximizeIconLink={viewAchieventsLink}
        />
      </Header>

      {isAchievementsList ? (
        <AchievementsList
          isMobile={isMobile}
          data={achievements}
          employeeId={employeeId}
          usersList={usersList}
          isProfilePreview
        />
      ) : (
        <AchievementEmptyState addAchievementLink={addAchievementLink} />
      )}
    </ContainerW>
  );
}
