import { PostListItem } from 'api';

import { PrivateDocumentsAttachments } from './types';

export const getLastAttachments = (
  posts: PostListItem[],
  isMobile: boolean,
): PrivateDocumentsAttachments[] => {
  const maxCount = isMobile ? 2 : 3;
  const threeAttachments: PrivateDocumentsAttachments[] = [];
  posts.forEach((post) => {
    if (!post?.attachments || threeAttachments.length >= maxCount) {
      return;
    }
    post.attachments.forEach((att) => {
      if (threeAttachments.length >= maxCount) {
        return;
      }
      threeAttachments.push({ ...att, postId: post.id });
    });
  });
  return threeAttachments;
};
