import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserDetailsQuery, useUsersListQuery } from 'api';
import { ConfirmDialog, GoBackButtonWithTitle, Spinner } from 'components';
import { ProfileAchievements } from 'features/career';
import { LanguageSelector } from 'features/languageSelector';
import { ProfileTasks } from 'features/tasks';
import { useAuth, useModal, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { EquipmentStatus } from 'types';
import { getFullName } from 'utils';

import { EditEmployeeForm, EquipmentList, PrivateDocuments, ProfileCard } from '../../components';
import { FirstRow, Row } from './styled';
import { accomplishedDialogSx, headerContainerSx, headerInnerSx, headerTitleSx } from './styles';

interface LocationI extends Omit<Location, 'state'> {
  state?: {
    accomplishedAchievement?: string;
  };
}

interface EmployeeFullDetailsProps {
  employeeId: string;
}

export function EmployeeFullDetails({ employeeId }: EmployeeFullDetailsProps): ReactElement {
  const location = useLocation() as unknown as LocationI;
  const navigate = useNavigate();
  const { handleCloseModal, isOpen, handleOpenModal } = useModal();
  const { handleCloseModal: handleCloseAchievementModal } = useModal();
  const { isMobile } = useWindowSize();
  const { isLoading, user, refetch } = useUserDetailsQuery({ employeeId });
  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();
  const pathname = location.pathname;
  const isWatchingHisProfile = currentUser?.id === employeeId;
  const fullName = getFullName({ firstName: user?.firstName, lastName: user?.lastName });
  const isProfilePage = pathname.includes(Links.profile.index);
  const equipmentCopy =
    user?.equipment?.filter(({ status }) => status === EquipmentStatus.ACTIVE) || [];
  const achievementDialogName = location?.state?.accomplishedAchievement ?? '';

  const handleCloseConfirmModal = useCallback(() => {
    handleCloseAchievementModal();
    navigate(location, {
      replace: true,
    });
  }, [handleCloseAchievementModal, location, navigate]);

  if (isLoading || isLoadingUsersList) {
    return <Spinner />;
  }

  return (
    <>
      {!isProfilePage && (
        <GoBackButtonWithTitle
          title={fullName}
          Actions={isWatchingHisProfile ? <LanguageSelector /> : <></>}
          containerSx={headerContainerSx}
          headerSx={headerInnerSx}
          titleSx={headerTitleSx}
        />
      )}

      {user && (
        <>
          <FirstRow className={isMobile ? 'no-margin-top' : ''}>
            <ProfileCard handleOpenModal={handleOpenModal} user={user} />
            <EquipmentList list={equipmentCopy} employeeId={user.id} />
          </FirstRow>

          <Row width={isMobile ? '100%' : '1106px'}>
            <ProfileAchievements employeeId={user.id} usersList={usersList} />
          </Row>

          <Row width={isMobile ? '100%' : '1106px'}>
            <ProfileTasks />
          </Row>

          <Row>
            <PrivateDocuments employeeId={user.id} />
          </Row>
        </>
      )}

      {user && isOpen && (
        <EditEmployeeForm
          open={isOpen}
          onClose={handleCloseModal}
          employee={user}
          refetchUserDetails={refetch}
          formTitle={t('profile.edit.form.title')}
        />
      )}

      {!!achievementDialogName && (
        <ConfirmDialog
          title={t('career.achievements.onAchievementAccomplished', {
            achievementName: achievementDialogName,
          })}
          open={!!achievementDialogName}
          onSubmit={handleCloseConfirmModal}
          dialogSx={accomplishedDialogSx}
        />
      )}
    </>
  );
}
