import { RegisterOptions } from 'react-hook-form';

import { getMaxLengthText, getMinLengthText, required } from 'validations';

import { TASK_FIELDS_LENGTH } from './constants';

export const titleFieldRules: RegisterOptions = {
  ...required,
  minLength: {
    value: TASK_FIELDS_LENGTH.title.min,
    message: getMinLengthText(TASK_FIELDS_LENGTH.title.min),
  },
  maxLength: {
    value: TASK_FIELDS_LENGTH.title.max,
    message: getMaxLengthText(TASK_FIELDS_LENGTH.title.max),
  },
};

export const descriptionFieldRules: RegisterOptions = {
  minLength: {
    value: TASK_FIELDS_LENGTH.description.min,
    message: getMinLengthText(TASK_FIELDS_LENGTH.description.min),
  },
  maxLength: {
    value: TASK_FIELDS_LENGTH.description.max,
    message: getMaxLengthText(TASK_FIELDS_LENGTH.description.max),
  },
};
