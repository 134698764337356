import { ReactElement } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { COMPLETED_TARGET_FORMAT } from 'constants/index';
import { useAuth } from 'hooks';
import { Links } from 'settings';
import { Target, TargetValueType } from 'types';
import { formattedDate } from 'utils';

import { ProgressBar } from '../ProgressLine';
import { ContentW, DateRow, DatesW, MainInfoW, ProgressW, Title, Value } from './styled';

interface TargetItemProps extends Target {
  isPreview?: boolean;
  isFromPost?: boolean;
  isDragging?: boolean;
}

export function TargetItem({
  title,
  value,
  color,
  created_at,
  completed_at,
  target,
  type,
  id,
  isPreview = false,
  isFromPost = false,
  isDragging,
}: TargetItemProps): ReactElement {
  const navigate = useNavigate();
  const { isManager } = useAuth();

  const percents = (value / target) * 100;
  const detailsLink = generatePath(Links.we.kpi.targetDetails, {
    targetId: id,
  });
  const isClickNotAllowed = isDragging || !isManager || completed_at || isFromPost;
  const cursor = isClickNotAllowed ? 'default' : 'pointer';

  function handleClick() {
    if (isClickNotAllowed) {
      return;
    }
    navigate(detailsLink);
  }

  return (
    <ContentW onClick={handleClick} isPreview={isPreview} sx={{ cursor }}>
      <Title>{title}</Title>
      <MainInfoW>
        <ProgressW>
          <ProgressBar color={color} value={percents} />
        </ProgressW>
        <Value>
          {value}
          {type === TargetValueType.percentage && <span>%</span>}
        </Value>
      </MainInfoW>

      {completed_at && !isFromPost && (
        <DatesW>
          <DateRow>
            Creation date: {formattedDate(new Date(created_at || ''), COMPLETED_TARGET_FORMAT)}
          </DateRow>
          <DateRow>
            Completion date: {formattedDate(new Date(completed_at || ''), COMPLETED_TARGET_FORMAT)}
          </DateRow>
        </DatesW>
      )}
    </ContentW>
  );
}
