import { LinearProgress } from '@mui/material';
import { ReactElement } from 'react';

import { LoaderW } from './styled';

export function FullPageProgress(): ReactElement {
  return (
    <LoaderW>
      <LinearProgress color="info" />
    </LoaderW>
  );
}
