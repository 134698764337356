import { LoginResponse } from 'api/types';

export interface SignInFormValues {
  email: string;
  password: string;
}

export interface TeamSelectFormValues {
  selectedTeam: string;
}

export enum AuthPageLink {
  signIn = 'signIn',
  signUp = 'signUp',
  resetPassword = 'resetPassword',
}

export interface UserAuthData {
  user: LoginResponse['user'];
}

export type AuthContextValues = {
  user: UserAuthData['user'] | null;
  isManager: boolean;
  removeCurrentUser: () => void;
  saveUser: (data: UserAuthData) => void;
  saveLocation: (location: string) => void;
  location: string;
};

export enum ConfirmEvent {
  USER_REGISTRATION = 'user_registration',
  COMPANY_REGISTRATION = 'company_registration',
  PASSWORD_RESET = 'password_reset',
  EMPLOYEE_ACTIVATION = 'employee_activation',
}

export interface SignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  countryCode: string;
}

export interface PasswordResetFormValues {
  email: string;
}

export interface CreatePasswordFormValues {
  password: string;
  repeatPassword: string;
}

export interface ActivateUserPasswordFormValues {
  password: string;
}
