import parse from 'html-react-parser';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import TruncateMarkup from 'react-truncate-markup';

import { PostListItem as Post, PostSubTypes, ReactionStats } from 'api';
import { ReactComponent as DocumentIcon } from 'assets/icons/document-icon.svg';
import { ReactComponent as ProcessIcon } from 'assets/icons/process-automation.svg';
import { ReactComponent as WelcomeIcon } from 'assets/icons/welcomePostIcon.svg';
import { Avatar } from 'components';
import { ReactionsWrapper } from 'components/ReactionsWrapper';
import { DocumentCategoryColors } from 'constants/DocumentCategoryColors';
import { Tag } from 'features/documentList/components/DocumentMainInfo/styled';
import { useModal, useWindowSize } from 'hooks';
import { CategoriesListType, InViewItemRef } from 'types';

import { PostAttachments } from '../PostAttachments';
import { PostItemActions } from '../PostItemActions';
import { ReactionConfirmDialog } from '../ReactionConfirmDialog';
import { BirthdayPost } from './BirthdayPost';
import { KpiTargetPost } from './KpiTargetPost';
import { PollOptions } from './PollOptions';
import { PollPost } from './PollPost';
import {
  LeftSide,
  PostDescription,
  PostTitle,
  PostW,
  RightSide,
  IconW,
  Container,
  TagW,
  TagsCounter,
} from './styled';
import { usePostItem } from './usePostListItem';
import { WelcomePost } from './WelcomePost';

type PostListItemProps = Pick<
  Post,
  | 'id'
  | 'title'
  | 'description'
  | 'attachments'
  | 'author'
  | 'comments_count'
  | 'type'
  | 'meta'
  | 'poll'
> & {
  lastItemRef?: InViewItemRef;
  isPreview?: boolean;
  updated_at?: string;
  published_at?: string;
  tags?: string[];
  reactions_stats?: ReactionStats;
  categoryMap: CategoriesListType;
};

export function PostListItem({
  attachments,
  lastItemRef,
  comments_count,
  isPreview = false,
  author,
  id,
  meta,
  poll,
  reactions_stats,
  categoryMap,
  ...otherProps
}: PostListItemProps): ReactElement {
  const {
    authorName,
    postTitle,
    categoryTag,
    postRef,
    textWithNewLines,
    isDocumentPost,
    otherTagsCount,
    isWelcomePost,
    isBirthdayPost,
    isPollPost,
    handlePostClick,
    goToPostDetails,
    onNavigateToNewUser,
  } = usePostItem({
    author,
    isPreview,
    id,
    meta,
    categoryMap,
    ...otherProps,
  });
  const { isMobile } = useWindowSize();
  const isPoll = !!poll;

  const { t } = useTranslation();
  const { handleOpenModal, handleCloseModal, isOpen } = useModal();

  if (isWelcomePost) {
    return (
      <WelcomePost
        authorName={authorName}
        onNavigateToNewUser={onNavigateToNewUser}
        postRef={postRef}
        comments_count={comments_count}
        handlePostClick={handlePostClick}
        lastItemRef={lastItemRef}
        reactions={reactions_stats}
        id={id}
      />
    );
  }

  if (isBirthdayPost) {
    return (
      <BirthdayPost
        authorName={authorName}
        onNavigateToNewUser={onNavigateToNewUser}
        postRef={postRef}
        comments_count={comments_count}
        handlePostClick={handlePostClick}
        lastItemRef={lastItemRef}
        reactions={reactions_stats}
        id={id}
      />
    );
  }

  if (isPollPost && meta?.poll_id) {
    return (
      <PollPost
        postId={id}
        id={meta?.poll_id}
        postRef={postRef}
        comments_count={comments_count}
        handlePostClick={handlePostClick}
        lastItemRef={lastItemRef}
        reactions={reactions_stats}
      />
    );
  }

  if (meta?.kpi_target_id && meta.sub_type === PostSubTypes.KPI_TARGET) {
    return (
      <KpiTargetPost
        postRef={postRef}
        lastItemRef={lastItemRef}
        handlePostClick={handlePostClick}
        targetId={meta?.kpi_target_id}
        comments_count={comments_count}
        reactions={reactions_stats}
        id={id}
      />
    );
  }

  return (
    <Container ref={postRef}>
      {(isDocumentPost || isWelcomePost || isPollPost) && !isMobile && (
        <IconW>
          {isDocumentPost ? <DocumentIcon /> : <WelcomeIcon />}
          <ProcessIcon />
        </IconW>
      )}

      <PostW ref={lastItemRef} onClick={handlePostClick}>
        <div style={{ width: '100%', display: 'flex', flexWrap: 'nowrap' }}>
          <LeftSide>
            {attachments && (
              <PostAttachments
                goToPostDetails={goToPostDetails}
                attachments={attachments}
                postId={id}
                isPreview={isPreview}
              />
            )}

            <PostTitle>{postTitle}</PostTitle>
            <TruncateMarkup
              lines={
                isPoll && !!attachments?.length
                  ? 2
                  : Boolean(attachments && attachments.length > 0)
                  ? 3
                  : 13
              }
              ellipsis={<span>...</span>}
            >
              <PostDescription
                hasAttachments={Boolean(attachments && attachments.length > 0)}
                isPoll={isPoll}
              >
                {parse(textWithNewLines)}
              </PostDescription>
            </TruncateMarkup>

            {isDocumentPost && categoryTag && (
              <TagW>
                <Tag sx={{ backgroundColor: DocumentCategoryColors[categoryTag?.icon], margin: 0 }}>
                  {categoryTag.label}
                </Tag>
                {Boolean(otherTagsCount) && <TagsCounter>+{otherTagsCount}</TagsCounter>}
              </TagW>
            )}

            {poll && <PollOptions {...poll} postId={id} goToPollDetails={goToPostDetails} />}
          </LeftSide>

          <RightSide>
            <PostItemActions isPreview={isPreview} commentsCount={comments_count} />
            <Avatar
              userId={author.id}
              filename={author?.avatar?.small || ''}
              size={isMobile ? 'xs' : 's'}
              alt={author.first_name}
              onClick={(e) => e.stopPropagation()}
            />
          </RightSide>
        </div>

        <ReactionsWrapper
          reactions={reactions_stats}
          handleOpenModal={handleOpenModal}
          id={id}
          isPreview={isPreview}
        />
      </PostW>

      <ReactionConfirmDialog
        title={t('reactions.title')}
        onClose={handleCloseModal}
        postId={id}
        open={isOpen}
      />
    </Container>
  );
}
