import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog, PageHeaderBoldTitle } from 'components';

import { SubscriptionsList, PeriodSwitcher, UnauthorizedToast } from '../../components';
import { PageW } from './styled';
import { modalSx, modalTitleSx } from './styles';
import { useSubscriptionsPage } from './useSubscriptionsPage';

export function SubscriptionsPage(): ReactElement {
  const { t } = useTranslation();

  const {
    goBack,
    isExpired,
    open,
    period,
    setPeriod,
    handleCloseModal,
    isToastOpen,
    handleToastClose,
  } = useSubscriptionsPage();

  return (
    <PageW>
      <ConfirmDialog
        title={t('subscriptions.modals.onPaymentCompleted')}
        onSubmit={handleCloseModal}
        open={open}
        titleSx={modalTitleSx}
        withLogo
        dialogSx={modalSx}
      />
      <UnauthorizedToast open={isToastOpen} onClose={handleToastClose} />
      <PageHeaderBoldTitle
        title={t('subscriptions.pageTitle')}
        onClickCloseIcon={goBack}
        withCloseBtn={!isExpired}
        buttonPosition="static"
      />
      <PeriodSwitcher period={period} setPeriod={setPeriod} />
      <SubscriptionsList period={period} />

      <ConfirmDialog
        title={t('subscriptions.modals.onPaymentCompleted')}
        onSubmit={handleCloseModal}
        open={open}
        titleSx={modalTitleSx}
        withLogo
        dialogSx={modalSx}
      />
    </PageW>
  );
}
