import { useEffect } from 'react';
import { Control, useForm } from 'react-hook-form';

import { EMAIL_IS_USED } from 'constants/errorCodes';
import { ApiErrors, CompanyPersonalDataFormValues, RegisterCompanyFormSteps } from 'types';

interface UsePersonalDataFormReturn {
  handleFormSubmit: () => void;
  control: Control<CompanyPersonalDataFormValues>;
}

interface UsePersonalDataFormProps {
  setStep: (step: RegisterCompanyFormSteps) => void;
  defaultValues: CompanyPersonalDataFormValues;
  storeValues: (values: CompanyPersonalDataFormValues) => void;
  errors?: Record<string, ApiErrors['errors']> | null;
}

export function usePersonalDataForm({
  setStep,
  defaultValues,
  storeValues,
  errors,
}: UsePersonalDataFormProps): UsePersonalDataFormReturn {
  const { handleSubmit, control, setError } = useForm<CompanyPersonalDataFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (Boolean(errors && errors[EMAIL_IS_USED])) {
      setError('email', { message: '' });
    }
  }, [errors, setError]);

  const handleFormSubmit = handleSubmit((data) => {
    storeValues(data);
    setStep(RegisterCompanyFormSteps.COMPANY_PROFILE);
  });

  return { handleFormSubmit, control };
}
