import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useUsersListQuery } from 'api';
import { Spinner } from 'components';
import { useCreateDraft } from 'hooks';
import { TaskStatus } from 'types';

import { CreateTaskForm, PageHeader } from '../../components';
import { defaultValuesAdapter } from './adapter';
import { PageW } from './styled';

type LocationState = {
  status?: TaskStatus;
  assigneeId?: string;
};

export function CreateTaskPage(): ReactElement {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { draft, isLoading } = useCreateDraft();

  const { isLoading: isLoadingUsersList, usersList } = useUsersListQuery({});

  const status = (state as LocationState)?.status;
  const assigneeId = (state as LocationState)?.assigneeId;
  const defaultValues = defaultValuesAdapter({ draft, status, assigneeId });

  if (isLoading || isLoadingUsersList) {
    return <Spinner />;
  }

  return (
    <PageW>
      <PageHeader pageTitle={t('task.create.title')} />
      {draft && (
        <CreateTaskForm defaultValues={defaultValues} draftId={draft.id} usersList={usersList} />
      )}
    </PageW>
  );
}
