import { useMutation } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { API_HANDLERS } from 'api/apiHandlers';
import { ConfirmDialog } from 'components';

import { TextW, TitleW } from './styled';

interface ClearDataModalProps {
  isOpen: boolean;
  handleCloseModal: () => void;
}

export function ClearDataModal({ isOpen, handleCloseModal }: ClearDataModalProps): ReactElement {
  const { t } = useTranslation();
  const { mutateAsync: clearData, isLoading } = useMutation(API_HANDLERS.DEMO.CLEAR);

  async function handleSubmit() {
    await clearData();
    handleCloseModal();
  }

  return (
    <ConfirmDialog
      open={isOpen}
      onClose={handleCloseModal}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      contentSx={{ padding: 0, overflow: 'hidden' }}
    >
      <TitleW>{t('mockedData.modalTitle')}</TitleW>
      <TextW>{t('mockedData.modalDescription')}</TextW>
    </ConfirmDialog>
  );
}
