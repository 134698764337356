import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import Swiper, { Pagination } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';

import { TopMenu } from 'components';
import { TASK_MANAGEMENT_LIST_LIMIT } from 'constants/index';
import { TasksList, TasksTabs } from 'features/tasks/components';
import { useWindowSize } from 'hooks';
import { TaskStatus } from 'types';

import { PageHeader } from '../../components';
import { ListsW, PageW, MobileContainerW } from './styled';
import { listStyles } from './styles';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';
import { getTabs } from './utils';

type LocationState = {
  assigneeId?: string;
};

export function TaskManagementPage(): ReactElement {
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiperRef, setSwiperRef] = useState<Swiper | null>(null);
  const { state } = useLocation();

  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const assigneeId = (state as LocationState)?.assigneeId;

  const slideTo = (index: number) => {
    swiperRef?.slideTo(index);
  };

  return (
    <PageW>
      <TopMenu />
      <PageHeader />
      {isMobile ? (
        <MobileContainerW>
          <TasksTabs slideTo={slideTo} activeIndex={activeIndex} tabs={getTabs(activeIndex)} />
          <ListsW>
            <SwiperComponent
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
              onSwiper={(swiper) => setSwiperRef(swiper)}
              pagination={true}
              modules={[Pagination]}
              style={{ maxHeight: '100%' }}
            >
              <SwiperSlide style={{ height: swiperRef?.height || 'max-content' }}>
                <TasksList
                  status={TaskStatus.to_do}
                  title={t('tasks.statuses.toDo')}
                  limit={TASK_MANAGEMENT_LIST_LIMIT}
                  {...(assigneeId && { assigned: assigneeId })}
                />
              </SwiperSlide>
              <SwiperSlide style={{ height: swiperRef?.height || 'max-content' }}>
                <TasksList
                  status={TaskStatus.in_progress}
                  title={t('tasks.statuses.inProgress')}
                  limit={TASK_MANAGEMENT_LIST_LIMIT}
                  {...(assigneeId && { assigned: assigneeId })}
                />
              </SwiperSlide>
              <SwiperSlide style={{ height: swiperRef?.height || 'max-content' }}>
                <TasksList
                  status={TaskStatus.to_approve}
                  title={t('tasks.statuses.toApprove')}
                  limit={TASK_MANAGEMENT_LIST_LIMIT}
                  {...(assigneeId && { assigned: assigneeId })}
                />
              </SwiperSlide>{' '}
              <SwiperSlide style={{ height: swiperRef?.height || 'auto' }}>
                <TasksList
                  status={TaskStatus.done}
                  title={t('tasks.statuses.done')}
                  limit={TASK_MANAGEMENT_LIST_LIMIT}
                  {...(assigneeId && { assigned: assigneeId })}
                />
              </SwiperSlide>
            </SwiperComponent>
          </ListsW>
        </MobileContainerW>
      ) : (
        <ListsW>
          <TasksList
            status={TaskStatus.to_do}
            title={t('tasks.statuses.toDo')}
            limit={TASK_MANAGEMENT_LIST_LIMIT}
            {...(assigneeId && { assigned: assigneeId })}
            listWrapperSx={listStyles}
          />
          <TasksList
            status={TaskStatus.in_progress}
            title={t('tasks.statuses.inProgress')}
            limit={TASK_MANAGEMENT_LIST_LIMIT}
            {...(assigneeId && { assigned: assigneeId })}
            listWrapperSx={listStyles}
          />
          <TasksList
            status={TaskStatus.to_approve}
            title={t('tasks.statuses.toApprove')}
            limit={TASK_MANAGEMENT_LIST_LIMIT}
            {...(assigneeId && { assigned: assigneeId })}
            listWrapperSx={listStyles}
          />
          <TasksList
            status={TaskStatus.done}
            title={t('tasks.statuses.done')}
            limit={TASK_MANAGEMENT_LIST_LIMIT}
            {...(assigneeId && { assigned: assigneeId })}
            listWrapperSx={listStyles}
          />
        </ListsW>
      )}
    </PageW>
  );
}
