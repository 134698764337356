export const selectStyles = {
  '&.MuiInputBase-root': {
    borderRadius: '12px',

    '.MuiSelect-select.MuiSelect-select': {
      height: 'auto',
      minHeight: 'unset',
      padding: '6px 16px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '28px',
    },

    '.MuiSvgIcon-root': {
      right: '8px',
      fontSize: '24px',
    },
  },
};

export const optionStyles = {
  padding: '8px 16px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
};
