import { ChangeEvent, useCallback } from 'react';
import { Control, UseFormGetValues, UseFormSetError, useFieldArray } from 'react-hook-form';

import { EquipmentFormValues } from 'types';
import { REQUIRED } from 'validations';

import { getEmptyField } from './utils';

interface UseCustomFieldsProps {
  control: Control<EquipmentFormValues>;
  isSubmitted: boolean;
  getValues: UseFormGetValues<EquipmentFormValues>;
  setError: UseFormSetError<EquipmentFormValues>;
}

export function useCustomFields({
  control,
  isSubmitted,
  getValues,
  setError,
}: UseCustomFieldsProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customFields',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customFields = getValues('customFields') ?? [];

  const handleRemoveField = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const handleAddField = useCallback(() => {
    append({ key: '', value: '' });
  }, [append]);

  const handleFieldChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number, fieldName: 'key' | 'value') => {
      const otherFieldsFilled = [...customFields]
        .filter((_, i) => i !== index)
        .every(({ key, value }) => !!key && !!value);
      const allFieldsFilled = customFields.every(({ key, value }) => !!key && !!value);
      const secondField = fieldName === 'key' ? 'value' : 'key';

      const { isEmptyField, emptyFieldIndex } = getEmptyField(customFields);
      const isError = isSubmitted && !e.target.value && !!customFields?.[index][secondField];

      isError && setError(`customFields.${index}.key`, { message: REQUIRED });

      const shouldRemoveEmpty =
        (!e.target.value || !customFields?.[index][secondField]) &&
        isEmptyField &&
        customFields.length > 1 &&
        emptyFieldIndex !== index;

      const shouldRemoveCurrent =
        customFields.length > 1 &&
        !e.target.value &&
        !customFields?.[index][secondField] &&
        !otherFieldsFilled;

      allFieldsFilled && handleAddField();

      shouldRemoveEmpty
        ? handleRemoveField(emptyFieldIndex)
        : shouldRemoveCurrent
        ? handleRemoveField(index)
        : null;
    },
    [customFields, handleAddField, handleRemoveField, isSubmitted, setError],
  );

  return {
    fields,
    customFields,
    handleFieldChange,
  };
}
