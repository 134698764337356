import { styled, Dialog as DialogMUI, Button, Typography } from '@mui/material';

export const Dialog = styled(DialogMUI)(({ theme }) => ({
  '.MuiDialog-paper': {
    width: '900px',
    height: '507px',
    position: 'relative',
    backdropFilter: 'blur(10px)',
    borderRadius: '15px',
    display: 'flex',
    background: 'linear-gradient(to left, #ffffff 50%, rgba(255, 255, 255, 0.7) 50%)',
  },

  '.MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
  },

  [theme.breakpoints.down('desktop')]: {
    '.MuiDialog-paper': {
      padding: '16px',
      marginBottom: 'auto',
      margin: '8px',
      height: 'auto',
      background: 'rgba(255, 255, 255, 0.8)',
      backdropFilter: 'blur(20px)',
    },
  },
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  textTransform: 'none',
  fontSize: '24px',
  lineHeight: '24px',
  color: theme.palette.primary.p080,
  marginBottom: '56px',

  '.MuiButton-startIcon': {
    fontSize: '2rem',
  },

  '.MuiSvgIcon-root': {
    width: '1.5em',
    height: '1.5em',
  },
  [theme.breakpoints.down('desktop')]: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 500,
    marginBottom: '0px',
  },
}));

export const ContentW = styled('div')`
  display: flex;
  padding-bottom: 32px;
  @media (max-width: 1023px) {
    flex-direction: column;
    row-gap: 8px;
    padding-bottom: 0px;
  }
`;

export const HalfWidthW = styled('div')`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 72px;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 0px;
    background: #ffffff;
    box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
    border-radius: 20px;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.palette.info.main};
  }
`;

export const LeftSide = styled(HalfWidthW)`
  color: ${({ theme }) => theme.palette.primary.p040};
  @media (max-width: 1023px) {
    color: ${({ theme }) => theme.palette.primary.p080};
    border: none;
  }
`;

export const NoteW = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.primary.p040};
  margin: 40px 0 24px;
  @media (max-width: 1023px) {
    margin: 8px 0px 0px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const SubmitBtn = styled(Button)`
  margin-left: auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  border-radius: 12px;
  width: 265px;
  height: 64px;
  text-transform: none;
  box-shadow: none;
  @media (max-width: 1023px) {
    margin: 8px 0px 0px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    height: fit-content;
    padding: 10px;
  }
`;

export const Title = styled('div')`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SubTitle = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  @media (max-width: 1023px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
  }
`;

export const Plan = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.primary.p040};
}`;

export const MobileMainPrice = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 8px;
`;

export const Wrapper = styled('div')`
  position: relative;
  height: 100%;
`;

export const CloseButtonW = styled('div')`
  padding-top: 24px;
  padding-left: 24px;
  @media (max-width: 1023px) {
    padding: 0px;
  }
`;
