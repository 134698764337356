import { styled } from '@mui/material';

import BackgroundImg from 'assets/images/mainBackground.png';

export const PageW = styled('div')`
  height: 100vh;
  display: flex;
  overflow: hidden;

  /* The image used */
  background-image: url(${BackgroundImg});
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1023px) {
    padding: 0px 8px;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 100%;
  }
`;

export const PageContentW = styled('div')`
  width: 100%;
  overflow-y: auto;
  padding: 66px 88px 64px 0;

  @media (max-width: 1023px) {
    padding: 46px 0px 0px;
    height: 100vh;
  }

  & code {
    font-family: 'Outfit', sans-serif !important;
    color: #ff9900;
    background: rgba(239, 239, 239, 0.7);
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    line-height: 1;
    padding: 1px 2px;
  }

  & li:has(code) {
    margin-top: 2px;
  }

  & li:has(code) code {
    padding: 0px 2px;
  }
`;

export const NotificationW = styled('div')`
  max-width: 1105px;
`;
