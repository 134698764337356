import { styled } from '@mui/material';
import { ReactElement } from 'react';

import confluenceImg from 'assets/icons/quickLInks/confluence.png';
import { ReactComponent as FigmaIcon } from 'assets/icons/quickLInks/figma.svg';
import { ReactComponent as GithubIcon } from 'assets/icons/quickLInks/github.svg';
import { ReactComponent as GitlabIcon } from 'assets/icons/quickLInks/gitlab.svg';
import { ReactComponent as GmailIcon } from 'assets/icons/quickLInks/gmail.svg';
import { ReactComponent as GdriveIcon } from 'assets/icons/quickLInks/googleDrive.svg';
import { ReactComponent as GoogleMeetIcon } from 'assets/icons/quickLInks/googleMeet.svg';
import { ReactComponent as InvisionIcon } from 'assets/icons/quickLInks/invision.svg';
import jiraImg from 'assets/icons/quickLInks/jira.png';
import { ReactComponent as OneDriveIcon } from 'assets/icons/quickLInks/oneDrive.svg';
import { ReactComponent as OutlookIcon } from 'assets/icons/quickLInks/outlook.svg';
import skypeImg from 'assets/icons/quickLInks/skype.png';
import { ReactComponent as SlackIcon } from 'assets/icons/quickLInks/slack.svg';
import { ReactComponent as TeamsIcon } from 'assets/icons/quickLInks/teams.svg';
import trelloImg from 'assets/icons/quickLInks/trello.png';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/quickLInks/whatsApp.svg';
import zoomImg from 'assets/icons/quickLInks/zoom.png';

const ImgW = styled('div')`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    img {
      max-width: 100%;
    }
  }
`;

const PreviewImgW = styled('div')`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
`;

export const LINKS_ICONS: Record<string, ReactElement> = {
  jira: (
    <ImgW>
      <img src={jiraImg} alt="jira" />
    </ImgW>
  ),
  confluence: (
    <ImgW>
      <img src={confluenceImg} alt="confluence" />
    </ImgW>
  ),
  trello: (
    <ImgW>
      <img src={trelloImg} alt="trello" />
    </ImgW>
  ),
  teams: <TeamsIcon />,
  gmail: <GmailIcon />,
  outlook: <OutlookIcon />,
  gdrive: <GdriveIcon />,
  onedrive: <OneDriveIcon />,
  slack: <SlackIcon />,
  whatsapp: <WhatsAppIcon />,
  zoom: (
    <ImgW>
      <img src={zoomImg} alt="zoom" />
    </ImgW>
  ),
  gmeet: <GoogleMeetIcon />,
  skype: (
    <ImgW>
      <img src={skypeImg} alt="skype" />
    </ImgW>
  ),
  invision: <InvisionIcon />,
  figma: <FigmaIcon />,
  github: <GithubIcon />,
  gitlab: <GitlabIcon />,
};

export const PREVIEW_LINKS_ICONS: Record<string, ReactElement> = {
  jira: (
    <PreviewImgW>
      <img src={jiraImg} alt="jira" />
    </PreviewImgW>
  ),
  confluence: (
    <PreviewImgW>
      <img src={confluenceImg} alt="confluence" />
    </PreviewImgW>
  ),
  trello: (
    <PreviewImgW>
      <img src={trelloImg} alt="trello" />
    </PreviewImgW>
  ),
  teams: <TeamsIcon />,
  gmail: <GmailIcon />,
  outlook: <OutlookIcon />,
  gdrive: <GdriveIcon />,
  onedrive: <OneDriveIcon />,
  slack: <SlackIcon />,
  whatsapp: <WhatsAppIcon />,
  zoom: (
    <PreviewImgW>
      <img src={zoomImg} alt="zoom" />
    </PreviewImgW>
  ),
  gmeet: <GoogleMeetIcon />,
  skype: (
    <PreviewImgW>
      <img src={skypeImg} alt="skype" />
    </PreviewImgW>
  ),
  invision: <InvisionIcon />,
  figma: <FigmaIcon />,
  github: <GithubIcon />,
  gitlab: <GitlabIcon />,
};
