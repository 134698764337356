import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  GoalFormValues,
  UserSignature,
  useAchievementDetailsQuery,
  useEditGoalMutation,
} from 'api';
import { alert } from 'components';
import { TIME_DATE_MONTH_WITH_COMMA } from 'constants/dateFormats';
import { StatusDisplayValue } from 'features/career/constants';
import { RefetchGoalDetails } from 'features/career/types';
import { useAuth, useCallbackPrompt } from 'hooks';
import { useFormAttachments } from 'hooks/useFormAttachments';
import { Links } from 'settings';
import { Attachment, Goal, GoalStatus, SelectOption, User } from 'types';
import { formattedDate, getFullName, objectsDifferences } from 'utils';

import { defaultValuesAdapter } from '../adapters';
import { AllOption } from '../constants';

interface UseEditGoalFormReturn {
  control: Control<GoalFormValues>;
  isLoading: boolean;
  isDirty: boolean;
  attachedImages: Attachment[];
  attachedDocs: Attachment[];
  attachedVideos: Attachment[];
  isOpen: boolean;
  statusOptions: SelectOption[];
  assigneeOptions: SelectOption[];
  author?: UserSignature;
  createdAt?: string;
  isAssigneeFieldDisabled: boolean;
  progress: number;
  clearDeadline: () => void;
  handleFormSubmit: () => void;
  handleAddImages: (files: FileList) => Promise<void>;
  handleAddDocuments: (files: FileList) => Promise<void>;
  handleAddVideos: (files: FileList) => Promise<void>;
  handleCloseConfirmModal: () => void;
  handleSubmitConfirmModal: () => void;
  handleDeleteAttachment: (attachmentId: string, type: 'images' | 'docs' | 'videos') => void;
  handleAssigneeChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface UseEditGoalFormProps {
  data?: Goal;
  usersList: User[];
  refetchGoal: RefetchGoalDetails;
}

export function useEditGoalForm({
  data,
  usersList,
  refetchGoal,
}: UseEditGoalFormProps): UseEditGoalFormReturn {
  const { t } = useTranslation();
  const { achievementId = '', employeeId: userId = '', goalId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation() as {
    state: {
      prevRoute?: string;
      isAchievementCompleted: boolean;
    };
  };
  const { user: currentUser, isManager } = useAuth();

  const currentUserId = currentUser?.id ?? '';

  const statusArray = Object.entries(StatusDisplayValue);
  const statusOptions = statusArray.map(([key, val]) => ({ label: val, value: key }));

  const defaultFormValues = defaultValuesAdapter({ data });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { isSubmitting, isDirty },
  } = useForm<GoalFormValues>({
    defaultValues: defaultFormValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    reset(defaultFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const attachedImages = watch('images');
  const attachedDocs = watch('docs');
  const attachedVideos = watch('videos');
  const status = watch('status');
  const assignee = watch('assignee');

  const filesChanges = objectsDifferences(
    { images: attachedImages, docs: attachedDocs, videos: attachedVideos },
    {
      images: defaultFormValues.images,
      docs: defaultFormValues.docs,
      videos: defaultFormValues.videos,
    },
  );

  const isAchievementAccomplished =
    defaultFormValues.status !== GoalStatus.ACCOMPLISHED &&
    status === GoalStatus.ACCOMPLISHED &&
    !data?.editable;

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isAchievementAccomplished && (isDirty || Object.keys(filesChanges).length > 0),
  );

  const { data: achievement } = useAchievementDetailsQuery({
    userId,
    achievementId,
  });

  const achievementName = achievement?.title ?? '';

  const { editGoalMutation, isLoading: isEditGoalLoading } = useEditGoalMutation({
    invalidateQueries: ['goal-details'],
    onSuccessAction: () => {
      refetchGoal().then((refetch) => {
        const refetchedData = refetch?.data?.data;

        if (!!data?.editable && !refetchedData?.editable) {
          navigate(location?.state?.prevRoute || Links.profile.index, {
            state: {
              accomplishedAchievement: achievementName,
            },
          });
        } else {
          alert.success(t('toasts.achievement.success.edit'));
          reset(getValues());
        }
      });
    },
  });

  const {
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    isLoadingFileUpload,
    progress,
  } = useFormAttachments({
    setFormValue: setValue,
    attachedDocs,
    attachedImages,
    attachedVideos,
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    if (data.deadline && typeof data.deadline !== 'string') {
      const deadline = data.deadline as unknown as Date;
      const utcDeadline = new Date(
        Date.UTC(deadline?.getFullYear(), deadline?.getMonth(), deadline?.getDate()),
      );
      data = {
        ...data,
        deadline: utcDeadline.toISOString(),
      };
    }

    const attachment_ids = [...attachedImages, ...attachedDocs, ...attachedVideos].map(
      (file) => file.id,
    );
    const isAllSelected = assignee?.length === 1 && assignee.includes(AllOption.value);
    const assigned_to_ids = isAllSelected ? undefined : assignee;
    const assigned_type = isAllSelected ? 'all' : 'users';

    await editGoalMutation({
      userId,
      achievementId,
      goalId,
      data: {
        ...data,
        attachment_ids,
        assigned_to_ids,
        assigned_type,
      },
    });

    reset(data);
  });

  function clearDeadline() {
    setValue('deadline', null, { shouldDirty: true });
  }

  async function handleSubmitConfirmModal() {
    confirmNavigation();
  }

  const createdDate = data?.created_at ? new Date(data?.created_at) : '';
  const isCurrentYear = createdDate && createdDate.getFullYear() === new Date().getFullYear();
  const dateFormat = isCurrentYear ? TIME_DATE_MONTH_WITH_COMMA : TIME_DATE_MONTH_WITH_COMMA;
  const createdAt = !!createdDate ? formattedDate(createdDate, dateFormat) : '';
  const isChanges = isDirty || Object.keys(filesChanges).length > 0;
  const ownerId = data?.owner_id ?? '';

  const assigneeOptions = useMemo(
    () =>
      usersList.map((user) => ({
        value: user.id,
        label:
          user.firstName && user.firstName
            ? getFullName({ firstName: user.firstName, lastName: user.lastName })
            : user.email,
        disabled: user.id === userId && !assignee?.includes(AllOption.value),
      })),
    [assignee, userId, usersList],
  );

  const handleAssigneeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;

      if (!!value && value.length > 1) {
        if (value?.at(-1) === AllOption.value) {
          setValue('assignee', [AllOption.value]);
        } else if (value.includes(AllOption.value)) {
          setValue('assignee', [
            ...(value as unknown as string[]).filter(
              (value) => value !== userId && value !== AllOption.value,
            ),
            userId,
          ]);
        }
      } else {
        setValue('assignee', [userId]);
      }
    },
    [setValue, userId],
  );

  const isAssigneeFieldDisabled =
    (!isManager && ownerId !== currentUserId) ||
    (isManager && userId === currentUserId && ownerId !== currentUserId);

  return {
    control,
    isLoading: isEditGoalLoading || isSubmitting || isLoadingFileUpload,
    attachedImages,
    attachedDocs,
    attachedVideos,
    isOpen: showPrompt,
    statusOptions: [...statusOptions],
    assigneeOptions: [AllOption, ...assigneeOptions],
    createdAt,
    author: data?.author,
    isDirty: isChanges,
    isAssigneeFieldDisabled,
    progress,
    clearDeadline,
    handleAddImages,
    handleFormSubmit,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    handleSubmitConfirmModal,
    handleCloseConfirmModal: cancelNavigation,
    handleAssigneeChange,
  };
}
