export const submitButtonSx = {
  marginLeft: 'auto',
  marginTop: '32px',
};

export const titleFormGroupSx = (isMobile: boolean) => ({
  display: 'flex',
  gap: '16px',
  flexWrap: 'nowrap',
  alignItems: 'center',
  flexDirection: isMobile ? 'column' : 'row',
});

export const avatarWrapperSx = {
  position: 'absolute',
  bottom: '-18px',
  left: '-11px',
};

export const avatarWrapperMobileSx = {
  position: 'absolute',
  bottom: '-30px',
  left: '0px',
};
