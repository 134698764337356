import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import { API_HANDLERS } from 'api/apiHandlers';
import { ValidateOttParams } from 'api/types';
import { Links } from 'settings';
import { ConfirmEvent } from 'types';

interface UseValidateOttMutationReturn {
  validateOttMutation: UseMutateAsyncFunction<AxiosResponse, unknown, ValidateOttParams, unknown>;
  isLoading: boolean;
  isError: boolean;
}

interface UseValidateOttMutationProps {
  eventType: ConfirmEvent;
}

export function useValidateOttMutation({
  eventType,
}: UseValidateOttMutationProps): UseValidateOttMutationReturn {
  const navigate = useNavigate();

  const {
    mutateAsync: validateOttMutation,
    isLoading,
    isError,
  } = useMutation(API_HANDLERS.AUTH.VALIDATE_OTT, {
    onError: () => {
      if (eventType === ConfirmEvent.PASSWORD_RESET) {
        navigate(Links.auth.resetExpired, { state: { expired: true } });
        return;
      }

      navigate(Links.auth.signIn);
    },
  });

  return {
    validateOttMutation,
    isLoading,
    isError,
  };
}
