import { Tooltip } from '@mui/material';
import { ReactElement, useRef, useState, useEffect, ReactNode } from 'react';

interface OverflowTipProps {
  children: ReactNode;
  tip: string;
}

export function OverflowTip({ tip, children }: OverflowTipProps): ReactElement {
  const [isOverflowed, setIsOverflow] = useState(false);

  const textElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  }, []);

  return (
    <Tooltip title={tip} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
}
