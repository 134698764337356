import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DeleteCategoryRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

import { QUERIES } from '../../constants';

interface UseDeleteCategoryMutationReturn {
  deleteCategoryMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    DeleteCategoryRequestParams,
    unknown
  >;
  isLoading: boolean;
}

export function useDeleteCategoryMutation(): UseDeleteCategoryMutationReturn {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutateAsync: deleteCategoryMutation, isLoading } = useMutation(
    API_HANDLERS.CATEGORY.DELETE,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.TARGET_DETAILS, QUERIES.VISION_AND_TARGETS]);
        alert.success(t('toasts.target.success.delete'));
        navigate(-1);
      },
    },
  );

  return {
    deleteCategoryMutation,
    isLoading,
  };
}
