export const EDIT_FORM_FIELDS_LENGTH = {
  name: {
    min: 0,
    max: 30,
  },
  industry: {
    min: 0,
    max: 70,
  },
  vatNumber: {
    min: 0,
    max: 20,
  },
  companyNumber: {
    min: 1,
    max: 20,
  },
  address1: {
    min: 0,
    max: 70,
  },
  address2: {
    min: 0,
    max: 70,
  },
  city: {
    min: 0,
    max: 45,
  },
  province: {
    min: 0,
    max: 45,
  },
  postCode: {
    min: 0,
    max: 15,
  },
  companyEmail: {
    min: 3,
    max: 254,
  },
  supportEmail: {
    min: 3,
    max: 254,
  },
  website: {
    min: 0,
    max: 2048,
  },
  phone: {
    min: 3,
    max: 13,
  },
};
