import { styled } from '@mui/material';

export const PageW = styled('div')`
  padding-left: 160px;
  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 16px;
  }
`;

export const ContainerW = styled('div')`
  margin-top: 170px;
  @media (max-width: 1023px) {
    margin-top: 0px;
  }
`;
