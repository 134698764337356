import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButtonProps } from '@mui/material';
import { ReactElement } from 'react';

import { StyledIconBtn } from './styled';

export function CancelRoundedButton({
  type = 'submit',
  ...otherProps
}: IconButtonProps): ReactElement {
  return (
    <StyledIconBtn type={type} {...otherProps}>
      <CloseRoundedIcon fontSize="large" />
    </StyledIconBtn>
  );
}
