import { styled } from '@mui/material';

export const ButtonsW = styled('div')`
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    flex-direction: row;
    column-gap: 16px;
    align-items: baseline;
  }
`;
