import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';
import { ConfirmRegistrationParams } from 'api/types';

interface UseConfirmRegistrationMutationReturn {
  confirmRegistrationMutation: UseMutateAsyncFunction<
    AxiosResponse,
    unknown,
    ConfirmRegistrationParams,
    unknown
  >;
  isLoading: boolean;
}

export function useConfirmRegistrationMutation(): UseConfirmRegistrationMutationReturn {
  const { mutateAsync: confirmRegistrationMutation, isLoading } = useMutation(
    API_HANDLERS.AUTH.CONFIRM_REGISTER,
  );

  return {
    confirmRegistrationMutation,
    isLoading,
  };
}
