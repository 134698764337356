import { ReactElement } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectController } from 'components';
import { EquipmentFilterFormValues } from 'types';

import { AllOption, categoryOptions } from '../constants';
import { FormGroup } from './styled';
import { selectStyles, optionStyles } from './styles';

interface CategoryFilterProps {
  control: Control<EquipmentFilterFormValues>;
}

export function CategoryFilter({ control }: CategoryFilterProps): ReactElement {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <SelectController
        name="category"
        placeholder={t('equipments.create.form.placeholders.category')}
        options={[AllOption, ...categoryOptions]}
        control={control}
        sx={selectStyles}
        optionSx={optionStyles}
      />
    </FormGroup>
  );
}
