import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDeleteTargetMutation, useTargetDetailsQuery } from 'api';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ConfirmDialog, GoBackButtonWithTitle, PageWrapper, Spinner } from 'components';
import { useAuth, useModal } from 'hooks';

import { EditTargetForm } from '../../components';
import { ButtonW, FlexW } from './styled';

export function EditTargetPage(): ReactElement {
  const { t } = useTranslation();
  const { targetId = '' } = useParams<string>();
  const { isLoading, data } = useTargetDetailsQuery(targetId);
  const { deleteTargetMutation } = useDeleteTargetMutation();
  const { isManager } = useAuth();
  const { handleOpenModal, handleCloseModal, isOpen } = useModal();

  async function handleDeleteTarget() {
    await deleteTargetMutation({ targetId });
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageWrapper>
      <GoBackButtonWithTitle title={t('kpi.targetForm.editTitle')} />
      <FlexW>
        {data?.data && (
          <EditTargetForm
            targetId={targetId}
            title={data?.data.title}
            value={data?.data.value}
            target={data?.data.target}
            type={data?.data.type}
            handleOpenModal={handleOpenModal}
          />
        )}
        {isManager && (
          <ButtonW onClick={handleOpenModal} endIcon={<TrashIcon />}>
            {t('kpi.targetForm.removeButton')}
          </ButtonW>
        )}
      </FlexW>

      <ConfirmDialog
        title={t('kpi.targetForm.removeModal.title', { targetTitle: data?.data.title })}
        subTitle={t('kpi.targetForm.removeModal.subtitle')}
        open={isOpen}
        onSubmit={handleDeleteTarget}
        onClose={handleCloseModal}
      />
    </PageWrapper>
  );
}
