import { styled } from '@mui/material';

export const PageW = styled('div')`
  width: 100%;
  padding-left: 88px;
  @media (max-width: 1023px) {
    padding-left: 0px;
    padding-bottom: 16px;
  }
`;
