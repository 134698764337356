import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { CreateGoalRequestParams } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseCreateGoalMutationReturn {
  createGoal: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    CreateGoalRequestParams,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
}

interface UseCreateGoalMutationProps {
  successText?: string;
  onSuccessAction?: () => void;
}

export function useCreateGoalMutation({
  successText,
  onSuccessAction,
}: UseCreateGoalMutationProps): UseCreateGoalMutationReturn {
  const {
    mutateAsync: createGoal,
    isLoading,
    isSuccess,
  } = useMutation(API_HANDLERS.ACHIEVEMENTS.CREATE_GOAL, {
    onSuccess: () => {
      successText && alert.success(successText);
      onSuccessAction?.();
    },
  });

  return {
    isLoading,
    isSuccess,
    createGoal,
  };
}
