import { styled, Typography } from '@mui/material';

export const ContainerW = styled('div')`
  margin-right: 24px;
  padding: 24px 16px;
  width: 540px;
  height: 236px;
  border-radius: 15px;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
`;

export const HeaderW = styled('div')`
  margin-bottom: 26px;
`;

export const ActionsW = styled('div')`
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const ListW = styled('div')``;

export const EmptyStateTitle = styled(Typography)`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.p040};
  margin-top: 70px;
  white-space: break-spaces;
`;

export const AttachmentsWrapper = styled('div')`
  display: flex;
  column-gap: 17px;
  margin-top: 22px;
  @media (max-width: 1023px) {
    margin-top: 0px;
  }
`;
