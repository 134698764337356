import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState.svg';

export const NavLinkW = styled(NavLink, {
  shouldForwardProp: (propname) => propname !== 'isDisabled',
})<{ isDisabled: boolean }>`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.info.main};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

export const EmptyStateIconW = styled(EmptyStateIcon)`
  margin-bottom: 17px;
  opacity: 0.4;
`;

export const FlexW = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
