import { useEffect, useState } from 'react';

import { BREAKPOINTS } from 'constants/index';

interface WindowSizeStateType {
  width: number;
  height: number;
}

interface UseWindowSizeProps {
  initialWidth?: number;
  initialHeight?: number;
}

export const useWindowSize = (props?: UseWindowSizeProps) => {
  const [windowSize, setWindowSize] = useState<WindowSizeStateType>({
    width: props?.initialWidth ?? 0,
    height: props?.initialHeight ?? 0,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowSize,
    isMobile: BREAKPOINTS.web >= windowSize.width,
  };
};
