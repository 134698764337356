import { Link, styled, Typography } from '@mui/material';

export const LinkW = styled(Link)`
  margin-right: 8px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.p080};

  @media (max-width: 1023px) {
    margin-right: 4px;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const CardW = styled('div')`
  width: 72px;
  height: 92px;
  background: #ffffff;
  box-shadow: 0px 15px 8px -15px rgba(0, 155, 242, 0.3);
  border-radius: 10px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    width: 64px;
  }
`;

export const TitleW = styled(Typography)`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  width: 63px;
  max-width: 63px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  margin-top: 8px;

  @media (max-width: 1023px) {
    width: 48px;
  }
`;

export const SubtitleW = styled(Typography)`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  width: 63px;
  max-width: 63px;
  white-space: nowrap;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.light};

  @media (max-width: 1023px) {
    width: 48px;
  }
`;

export const IconW = styled('div')`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
`;
