import { Button, styled } from '@mui/material';

export const ButtonW = styled(Button)`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-transform: none;
  width: 265px;
  border-radius: 12px;
  padding: 14px 16px;
  @media (max-width: 1023px) {
    margin: 48px auto;
  }
`;
