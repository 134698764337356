import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { Links } from 'settings';

import { ArrowIcon, IconButtonW } from './styled';

interface GoBackArrowProps {
  onClick?: () => void;
}

export function GoBackArrow({ onClick }: GoBackArrowProps): ReactElement {
  const navigate = useNavigate();
  const handleClick = onClick
    ? onClick
    : () =>
        window.history.state && window.history.state.idx > 0 ? navigate(-1) : navigate(Links.index);
  return (
    <IconButtonW onClick={handleClick}>
      <ArrowIcon style={{ height: '24px' }} />
    </IconButtonW>
  );
}
