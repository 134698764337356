import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog, GoBackButtonWithTitle } from 'components';
import {
  RegisteredEquipmentsContainer,
  RequestedEquipmentsContainer,
} from 'features/equipments/components';
import { useSessionState } from 'hooks';
import { Language } from 'types';

import { useDeleteEquipment } from './hooks';
import { PageW } from './styled';

export function EquipmentsPage(): ReactElement {
  const { t } = useTranslation();
  const {
    isConfirmDeleteLoading,
    selectedEquipment,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    handleDeleteEquipment,
    onTrashIconClick,
  } = useDeleteEquipment();
  const [state] = useSessionState('i18nextLng');
  const isSwedish = state === Language.swedish;

  return (
    <PageW>
      <GoBackButtonWithTitle title={t('equipments.title')} />
      <RegisteredEquipmentsContainer isSwedish={isSwedish} onTrashIconClick={onTrashIconClick} />
      <RequestedEquipmentsContainer isSwedish={isSwedish} onTrashIconClick={onTrashIconClick} />

      {!!selectedEquipment && (
        <ConfirmDialog
          title={t('equipments.removeModal.title', { equipmentName: selectedEquipment.name })}
          subTitle={t('equipments.removeModal.subtitle')}
          open={isDeleteModalOpen}
          onSubmit={handleDeleteEquipment}
          onClose={handleCloseDeleteModal}
          isLoading={isConfirmDeleteLoading}
        />
      )}
    </PageW>
  );
}
