import { LinearProgress } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLogoutMutation } from 'api';
import { Avatar, AvatarEditor, ConfirmDialog, OverflowTip } from 'components';
import { useAuth, useModal, useWindowSize } from 'hooks';
import { Links } from 'settings';
import { UserExtendedDetails } from 'types';
import { getFullName } from 'utils';

import { ProfileCardActions } from '../ProfileCardActions';
import { ProfileCardMeta } from '../ProfileCardMeta';
import { MAX_AVATAR_SIZE } from './constants';
import { useProfileCard } from './hooks';
import {
  AvatarSectionW,
  ButtonW,
  CardW,
  NameValue,
  UserDetailsW,
  UserName,
  MobileProfileCardW,
  MobileCameraIcon,
} from './styled';

interface ProfileCardProps {
  privateView?: boolean;
  handleOpenModal: () => void;
  user: UserExtendedDetails;
}

export function ProfileCard({
  privateView = true,
  handleOpenModal,
  user,
}: ProfileCardProps): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { isLoading, logoutMutation } = useLogoutMutation();
  const { handleAvatarFile, isLoading: isLoadingAvatarUpdate } = useProfileCard();

  const { user: currentUser, removeCurrentUser } = useAuth();
  const {
    handleCloseModal: closeConfirmLogoutModal,
    isOpen: isOpenConfirmLogoutModal,
    handleOpenModal: openConfirmLogoutModal,
  } = useModal();

  const {
    id,
    firstName,
    lastName,
    phone,
    countryCode,
    email,
    city,
    position,
    address1,
    birthDate,
    postcode,
    department,
    address2,
    region,
    country,
    avatar,
  } = user;

  const isCurrentUser = id === currentUser?.id;
  const fullName = getFullName({ firstName, lastName });
  const isLogoutBtnVisible = privateView && id === currentUser?.id;

  async function handleConfirmLogout() {
    await logoutMutation();
    removeCurrentUser();
    closeConfirmLogoutModal();
    navigate(Links.auth.signIn);
    if (Notification.permission === 'granted') {
      window.location.reload();
    }
  }

  const avatarSize = privateView ? 'xl' : 'xxxl';
  const mobileAvatarSize = privateView ? 'lg' : 'xxl';

  return (
    <CardW privateView={privateView}>
      {isLoadingAvatarUpdate && (
        <LinearProgress
          color="info"
          sx={{ position: 'absolute', zIndex: '10', top: '0', width: '100%', left: '0' }}
        />
      )}
      <AvatarSectionW privateView={privateView}>
        <AvatarEditor
          multiple={false}
          acceptFormats="image/*"
          handleFile={handleAvatarFile}
          disabled={!isCurrentUser}
          maxAvatarSize={MAX_AVATAR_SIZE}
        >
          {isCurrentUser && <MobileCameraIcon />}
          <Avatar
            size={isMobile ? mobileAvatarSize : avatarSize}
            isAvailableToUpload={isCurrentUser}
            userId={id}
            filename={avatar?.small || ''}
            withNavigation={false}
          />
        </AvatarEditor>
        {isLogoutBtnVisible && !isMobile && (
          <ButtonW variant="text" color="info" onClick={openConfirmLogoutModal}>
            {t('profile.logout.text')}
          </ButtonW>
        )}
      </AvatarSectionW>
      <UserDetailsW privateView={privateView}>
        <UserName privateView={privateView}>
          {privateView ? (
            <>
              <NameValue>
                <OverflowTip tip={firstName}>{firstName}</OverflowTip>
              </NameValue>
              <NameValue>
                <OverflowTip tip={lastName}>{lastName}</OverflowTip>
              </NameValue>
            </>
          ) : (
            fullName
          )}
        </UserName>

        {privateView && <ProfileCardActions employeeId={id} handleOnEditClick={handleOpenModal} />}

        {isLogoutBtnVisible && isMobile && (
          <ButtonW variant="text" color="info" onClick={openConfirmLogoutModal}>
            {t('profile.logout.text')}
          </ButtonW>
        )}

        {!isMobile && (
          <ProfileCardMeta
            phone={phone}
            postcode={postcode}
            email={email}
            countryCode={countryCode}
            city={city}
            position={position}
            address1={address1}
            address2={address2}
            privateView={privateView}
            birthDate={birthDate}
            department={department}
            region={region}
            country={country}
          />
        )}
      </UserDetailsW>
      {isMobile && (
        <MobileProfileCardW>
          <ProfileCardMeta
            phone={phone}
            postcode={postcode}
            email={email}
            countryCode={countryCode}
            city={city}
            position={position}
            address1={address1}
            address2={address2}
            privateView={privateView}
            birthDate={birthDate}
            department={department}
            region={region}
            country={country}
          />
        </MobileProfileCardW>
      )}
      <ConfirmDialog
        title={t('profile.logout.confirmText')}
        subTitle={t('profile.logout.text')}
        open={isOpenConfirmLogoutModal}
        onClose={closeConfirmLogoutModal}
        onSubmit={handleConfirmLogout}
        isLoading={isLoading}
      />
    </CardW>
  );
}
