import { ReactElement } from 'react';

import { ListItemImageAttachment as Attachment } from 'components';
import { Attachment as AttachmentType } from 'types';

import { wrapperStyles, wrapperStylesOnlyImage } from '../styles';

interface OnlyImagesLayoutProps {
  images: AttachmentType[];
  documentId: string;
  onOpenGallery: () => void;
  hasMoreImagesClick: () => void;
}

const IMAGES_COUNT = 2;

export function OnlyImagesLayout({
  images,
  documentId,
  onOpenGallery,
  hasMoreImagesClick,
}: OnlyImagesLayoutProps): ReactElement {
  const twoImages = images.slice(0, IMAGES_COUNT);
  const moreImages = images.length > IMAGES_COUNT ? images.length - IMAGES_COUNT : null;

  if (images.length === 1) {
    const { id, file, file_url } = images[0];

    return (
      <Attachment
        attachmentId={id}
        fileName={file.small}
        fileUrl={file_url?.small}
        postId={documentId}
        wrapperSx={wrapperStylesOnlyImage}
        onOpenGallery={onOpenGallery}
        hasMoreImagesClick={hasMoreImagesClick}
      />
    );
  }

  return (
    <div>
      {twoImages.map(({ id, file, file_url }, idx) => (
        <Attachment
          key={id}
          attachmentId={id}
          fileName={file.small}
          fileUrl={file_url?.small}
          postId={documentId}
          wrapperSx={wrapperStyles}
          onOpenGallery={onOpenGallery}
          hasMoreImagesClick={hasMoreImagesClick}
          {...(idx === 1 && { moreImages })}
        />
      ))}
    </div>
  );
}
