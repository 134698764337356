import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES } from 'constants/index';
import { Company, CompanyDetails, SelectOption } from 'types';

interface UseCompanyListQueryReturn {
  companyList: SelectOption[];
  currentCompany?: Company;
}

export function useUsersCompanyListQuery(): UseCompanyListQueryReturn {
  const { data } = useQuery([QUERIES.USER_COMPANY_LIST], () => {
    return API_HANDLERS.USER.GET_COMPANIES();
  });

  const { data: companyDetailsResponse } = useQuery(['company-details'], () =>
    API_HANDLERS.COMPANY.DETAILS(),
  );

  const companyList = data?.data.map((company: CompanyDetails) => {
    return { label: company.name, value: company.id };
  });

  return {
    companyList,
    currentCompany: companyDetailsResponse?.data,
  };
}
