import { useTranslation } from 'react-i18next';

import { TargetFormValues } from 'types';
import { removeExtraSpaces } from 'utils';

import { useEditTargetMutation } from './useEditTargetMutation';

export function useEditTargetForm(targetId: string) {
  const { t } = useTranslation();

  const { editTargetMutation, isLoading } = useEditTargetMutation({
    successText: t('toasts.target.success.edit'),
    targetId,
  });

  async function handleSubmit(data: TargetFormValues) {
    const { value, target, title } = data;
    const titleValue = removeExtraSpaces(title);
    await editTargetMutation({
      ...data,
      value: Number(value),
      target: Number(target),
      targetId,
      title: titleValue,
    });
  }

  return {
    handleSubmit,
    isLoading,
  };
}
