export interface Target {
  color: string;
  value: number;
  target: number;
  title: string;
  id: string;
  created_at?: string;
  completed_at?: string;
  company_id: string;
  status: TargetStatus;
  type: TargetValueType;
}

export enum TargetStatus {
  active = 'active',
  completed = 'completed',
}

export enum TargetValueType {
  integer = 'integer',
  percentage = 'percentage',
}

export interface TargetFormValues {
  title: string;
  value: number | string;
  target: number | string;
  type: TargetValueType | string;
  targetId?: string;
}

export interface KpiDetails {
  vision: string;
  targets: string;
}
