import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';
import { DeactivateUserRequestParams } from 'api/types';

import { queryClient } from './../queryClient';

interface UseDeactivateUserMutationReturn {
  deactivateUserMutation: UseMutateAsyncFunction<
    AxiosResponse,
    unknown,
    DeactivateUserRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseDeactivateProps {
  onSuccessFunc: () => void;
  invalidateQuery: string;
}

export function useDeactivateUserMutation({
  onSuccessFunc,
  invalidateQuery,
}: UseDeactivateProps): UseDeactivateUserMutationReturn {
  const { mutateAsync: deactivateUserMutation, isLoading } = useMutation(
    API_HANDLERS.USER.DEACTIVATE,
    {
      onSuccess: () => {
        onSuccessFunc();
        queryClient.invalidateQueries([invalidateQuery]);
      },
    },
  );

  return {
    deactivateUserMutation,
    isLoading,
  };
}
