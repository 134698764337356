import { styled, Typography } from '@mui/material';

export const ContentW = styled('div')<{ isPreview?: boolean }>`
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 25px 12px -20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: ${({ isPreview }) => (isPreview ? '13px 16px 8px' : '16px')};
  margin-bottom: ${({ isPreview }) => (isPreview ? '6px' : '0')};
  max-hight: 56px;

  @media (max-width: 1023px) {
    padding: 8px;
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.p080};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

export const ProgressW = styled('div')`
  display: inline-block;
  width: 100%;
  max-width: 88%;
`;

export const Value = styled('span')`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.p080};
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  margin-left: 8px;
`;

export const DatesW = styled('div')``;

export const MainInfoW = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateRow = styled(Typography)`
  color: #9ca3af;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  &:first-of-type {
    margin: 8px 0;
  }
`;
