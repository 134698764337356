import i18n from 'i18n';
import { TaskStatus } from 'types';

export const statusOptions = [
  { value: TaskStatus.to_do, label: i18n.t('tasks.emptyState.statuses.toDo') },
  { value: TaskStatus.in_progress, label: i18n.t('tasks.emptyState.statuses.inProgress') },
  { value: TaskStatus.to_approve, label: i18n.t('tasks.emptyState.statuses.toApprove') },
  { value: TaskStatus.done, label: i18n.t('tasks.emptyState.statuses.done') },
];

export const TASK_FIELDS_LENGTH = {
  title: {
    min: 1,
    max: 70,
  },
  description: {
    min: 1,
    max: 2000,
  },
};

export const AllOption = {
  value: 'all',
  label: i18n.t('task.create.form.placeholders.all'),
};
