import { CSSProperties, ReactElement, SyntheticEvent } from 'react';

import { ReactComponent as RemoveIcon } from 'assets/icons/closeRounded.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';

import { PlayButtonContainer, RemoveCircle, StyledImage, Thumb, VideoW } from './styled';

interface VideoAttachmentProps {
  postId?: string;
  attachmentId: string;
  file?: File;
  handleDelete?: () => void;
  onOpenGallery?: () => void;
  wrapperSx?: CSSProperties;
}

export function VideoAttachment({
  attachmentId,
  handleDelete,
  onOpenGallery,
  wrapperSx,
}: VideoAttachmentProps): ReactElement {
  const handleAttachmentRemove = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (onOpenGallery) {
      onOpenGallery?.();
    } else {
      handleDelete?.();
    }
  };

  const videoThumbnailurl = attachmentId
    ? `https://customer-tr04cbm6ks3rgpwn.cloudflarestream.com/${attachmentId}/thumbnails/thumbnail.gif?time=1s&height=200&duration=4s`
    : '';

  return (
    <Thumb style={wrapperSx} onClick={handleAttachmentRemove}>
      <VideoW>
        <StyledImage src={videoThumbnailurl} />
      </VideoW>
      <PlayButtonContainer>
        <PlayIcon />
      </PlayButtonContainer>
      {!onOpenGallery && (
        <RemoveCircle className="remove-btn">
          <RemoveIcon style={{ opacity: 'unset' }} />
        </RemoveCircle>
      )}
    </Thumb>
  );
}
