import { useCallback, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { useConfirmRegistrationMutation, useValidateOttMutation, useLogoutMutation } from 'api';
import { useAuth } from 'hooks';
import i18n from 'i18n';
import { Links } from 'settings';
import { ConfirmEvent } from 'types';
import { validateTimestamp } from 'utils';

import { EXPIRED_LINKS_CONFIG } from './config';

interface UseConfirmRegistrationLogicReturn {
  handleConfirmationFlow: () => Promise<void>;
}

export function useConfirmRegistrationLogic(): UseConfirmRegistrationLogicReturn {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const event = searchParams.get('event');
  const ott = searchParams.get('ott');
  const company_id = searchParams.get('company_id') || '';
  const is_email_exist = searchParams.get('is_email_exist') || false;
  const timestamp = searchParams.get('expires');
  const lang = searchParams.get('lang');

  /*
  Get links for the appropriate flow:

   - Company or User registration,
   - User Activation,
   - Password Reset
  */
  const eventType = event as ConfirmEvent;
  const { link: expiredLink, state: expiredState } = EXPIRED_LINKS_CONFIG[eventType];

  const { validateOttMutation } = useValidateOttMutation({ eventType });

  const { confirmRegistrationMutation } = useConfirmRegistrationMutation();

  const { logoutMutation } = useLogoutMutation();

  const { user: currentUser, removeCurrentUser } = useAuth();

  const handleLogout = useCallback(async () => {
    await logoutMutation();
    removeCurrentUser();
  }, [logoutMutation, removeCurrentUser]);

  // Confirm-registration flow
  // 1. Validate if timestamp is not expired
  // 2. Validate ott
  // 3. Send request to confirm registration (or navigate to reset password)

  const isExpired = validateTimestamp(Number(timestamp));

  const handleCompanyConfirm = useCallback(
    async (ott: string) => {
      await confirmRegistrationMutation({ ott });

      if (currentUser) {
        await handleLogout();
      }

      navigate(Links.auth.signIn, { state: { companyRegistration: true } });
    },
    [confirmRegistrationMutation, currentUser, handleLogout, navigate],
  );

  const handleUserConfirm = useCallback(
    async (email: string) => {
      if (currentUser) {
        await handleLogout();
      }
      navigate(Links.auth.signUp.index, {
        state: { signUp: true, email, ott, company_id, is_email_exist },
      });
    },
    [currentUser, handleLogout, navigate, ott, company_id, is_email_exist],
  );

  const handleExpiredFlow = useCallback(async () => {
    if (currentUser) {
      await handleLogout();
    }
    navigate(expiredLink, { state: expiredState });
  }, [currentUser, expiredLink, expiredState, handleLogout, navigate]);

  const handleEvents = useCallback(
    async (ott: string) => {
      const { data } = await validateOttMutation({ ott });

      // 3.a Send request to confirm Company registration
      if (eventType === ConfirmEvent.COMPANY_REGISTRATION) {
        await handleCompanyConfirm(ott);
      }

      // 3.b Redirect to SignUp form to finish User registration
      if (eventType === ConfirmEvent.USER_REGISTRATION) {
        handleUserConfirm(data.email);
      }

      // 3.c Navigate to CreateNewPasswordForm
      if (eventType === ConfirmEvent.PASSWORD_RESET) {
        navigate(Links.auth.createPassword, { state: { ott } });
      }

      // 3.d Navigate to CreatePasswordForm
      if (eventType === ConfirmEvent.EMPLOYEE_ACTIVATION) {
        navigate(Links.auth.finishActivation, { state: { ott } });
      }
    },
    [eventType, handleCompanyConfirm, handleUserConfirm, navigate, validateOttMutation],
  );

  const handleConfirmationFlow = useCallback(async () => {
    // 1. handle expired flow
    if (isExpired || (!company_id && eventType === ConfirmEvent.USER_REGISTRATION)) {
      handleExpiredFlow();
      return;
    }

    // 2. validate ott
    if (ott) {
      handleEvents(ott);
    }
  }, [handleEvents, handleExpiredFlow, isExpired, company_id, eventType, ott]);

  useEffect(() => {
    if (lang) {
      const userLang = lang.replace('-SV', '');
      i18n.changeLanguage(userLang);
    }
  }, [lang]);

  return {
    handleConfirmationFlow,
  };
}
