import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { GoBackButtonWithTitle, PageWrapper, Spinner } from 'components';
import { CompanySelector } from 'features/companySelector';
import { NotificationPreview } from 'features/notifications';

import { Vision } from '../../components';
import { TargetsList } from '../TargetsList';
import { ContentW, TopMenuW } from './styled';
import { useKpiFullPageDetails } from './useKpiFullPageDetails';

export function KpiPage(): ReactElement {
  const { t } = useTranslation();
  const { isLoading, vision, activeTargets, completedTargets } = useKpiFullPageDetails();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageWrapper>
      <ContentW>
        <TopMenuW>
          <div style={{ flexGrow: 1 }}>
            <GoBackButtonWithTitle title={t('kpi.fullPage.pageTitle')} />
          </div>
          <CompanySelector />
          <NotificationPreview />
        </TopMenuW>
        <Vision vision={vision} />
        <TargetsList list={activeTargets} title={t('kpi.fullPage.activeKPI')} isActiveList />{' '}
        {completedTargets.length > 0 && (
          <TargetsList list={completedTargets} title={t('kpi.fullPage.completedKPI')} />
        )}
      </ContentW>
    </PageWrapper>
  );
}
