import { styled, Typography } from '@mui/material';

export const ContainerW = styled('div')`
  padding: 24px 16px;
  border-radius: 15px;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  position: relative;
`;

export const HeaderW = styled('div')`
  margin-bottom: 26px;
  @media (max-width: 1023px) {
    margin-bottom: 16px;
  }
`;

export const ActionsW = styled('div')`
  position: absolute;
  right: 12px;
  top: 12px;
  @media (max-width: 1023px) {
    top: 6px;
  }
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
