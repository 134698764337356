import { AxiosError } from 'axios';

import { alert } from 'components';
import { COMPANY_NAME_IS_USED } from 'constants/errorCodes';
import i18n from 'i18n';
import { Links } from 'settings';
import { ApiErrors } from 'types';

import { API_ERRORS_BY_CODES } from '../constants/toasterMessages';
import { handleInvalidInputError } from './handleInvalidInputError';
import { handleLogout } from './handleLogout';

export function showResponseError(error: AxiosError<ApiErrors>): void {
  if (error.response?.data.errors) {
    error.response?.data.errors.forEach((error) => {
      const extendedData = error.extended?.join(', ') || '';

      if (error.code === COMPANY_NAME_IS_USED) {
        return;
      }

      if (error.code === 4000) {
        handleInvalidInputError(error);
        return;
      }

      // subscription expired
      if (error.code === 4100) {
        window.location.replace(Links.actions.subscriptions);
      }

      // handle Invite existing user error
      if (error.code === 4222 && extendedData) {
        const errorText = i18n.t('toasts.codes.emailIsInUse');

        alert.error(`${errorText} ${extendedData}`);
        return;
      }

      if (error.code === 4003) {
        handleLogout();
        return;
      }

      if (API_ERRORS_BY_CODES[error.code]) {
        alert.error(API_ERRORS_BY_CODES[error.code]);
        return;
      }
    });
  }
}
