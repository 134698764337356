import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { DialogProps } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerBackdrop } from 'components';
import { useWindowSize } from 'hooks';
import { Subscription } from 'types';

import { SubscriptionDetails } from '../types';
import {
  CloseButton,
  ContentW,
  Dialog,
  HalfWidthW,
  LeftSide,
  NoteW,
  SubmitBtn,
  SubTitle,
  Title,
  CloseButtonW,
  Plan,
  MobileMainPrice,
} from './styled';
import { useUpgradePlanDialog } from './useUpgradePlanDialog';

interface UpgradePlanDialogProps extends DialogProps {
  onClose: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  newPlan: SubscriptionDetails;
  currentSubscription: Subscription;
}

export function UpgradePlanDialog({
  onClose,
  open,
  onSubmit,
  newPlan,
  currentSubscription,
  isLoading,
}: UpgradePlanDialogProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const { isLoading: isLoadingProductDetails, currentProductDetails } = useUpgradePlanDialog({
    currentSubscription,
    isOpen: open,
  });

  const { name, mainPrice, duration, extraPrice, teamSize } = newPlan;

  const loading = isLoading || isLoadingProductDetails;

  return (
    <Dialog open={open}>
      {isLoading && <ContainerBackdrop />}

      <CloseButtonW>
        <CloseButton
          variant="text"
          startIcon={<KeyboardBackspaceRoundedIcon fontSize="large" />}
          onClick={onClose}
        >
          {t('subscriptions.modals.upgradePlan.title')}
        </CloseButton>
      </CloseButtonW>

      <ContentW>
        <LeftSide>
          {!isMobile && <SubTitle>{t('subscriptions.modals.upgradePlan.currentPlan')}</SubTitle>}
          <Title>
            {currentProductDetails.name}{' '}
            {isMobile ? (
              <Plan>{t('subscriptions.modals.upgradePlan.currentPlan')}</Plan>
            ) : (
              currentProductDetails.mainPrice
            )}
          </Title>
          <SubTitle>{currentProductDetails.teamSize}</SubTitle>
          <SubTitle>{currentProductDetails.duration}</SubTitle>
          <SubTitle>{currentProductDetails.extraPrice}</SubTitle>
          {isMobile && <MobileMainPrice>{currentProductDetails.mainPrice}</MobileMainPrice>}
        </LeftSide>

        <HalfWidthW>
          {!isMobile && <SubTitle>{t('subscriptions.modals.upgradePlan.newPlan')}</SubTitle>}
          <Title>
            {name}{' '}
            {isMobile ? <Plan>{t('subscriptions.modals.upgradePlan.newPlan')}</Plan> : mainPrice}
          </Title>
          <SubTitle>{teamSize}</SubTitle>
          <SubTitle>{duration}</SubTitle>
          <SubTitle>{extraPrice}</SubTitle>
          {isMobile && <MobileMainPrice>{mainPrice}</MobileMainPrice>}

          {!isMobile && (
            <>
              <NoteW>{t('subscriptions.modals.upgradePlan.note')}</NoteW>
              <SubmitBtn color="info" variant="contained" onClick={onSubmit} disabled={loading}>
                {t('subscriptions.modals.upgradePlan.submitBtn')}
              </SubmitBtn>
            </>
          )}
        </HalfWidthW>
        {isMobile && (
          <>
            <NoteW>{t('subscriptions.modals.upgradePlan.note')}</NoteW>
            <SubmitBtn color="info" variant="contained" onClick={onSubmit} disabled={loading}>
              {t('subscriptions.modals.upgradePlan.submitBtn')}
            </SubmitBtn>
          </>
        )}
      </ContentW>
    </Dialog>
  );
}
