import { useState } from 'react';

import { useActivateUserMutation, useDeactivateUserMutation } from 'api';
import { EMPTY_SPACES_REGEX, QUERIES } from 'constants/index';
import { useModal } from 'hooks';
import { UserStatus } from 'types';
import { REQUIRED } from 'validations';

export const useEmployeeExtendedDetails = (userId: string, userStatus?: UserStatus) => {
  const [deletedComment, setDeletedComment] = useState<string>('');
  const [deletedCommentError, setDeletedCommentError] = useState<string>('');
  const [isFocusedDeletedComment, setIsFocusedDeletedComment] = useState<boolean>(false);

  const { isOpen, handleOpenModal, handleCloseModal } = useModal();
  const { deactivateUserMutation } = useDeactivateUserMutation({
    onSuccessFunc: handleCloseModal,
    invalidateQuery: QUERIES.USER_DETAILS,
  });
  const { activateUserMutation } = useActivateUserMutation({
    onSuccessFunc: handleCloseModal,
    invalidateQuery: QUERIES.USER_DETAILS,
  });

  const onHandleOpenChangeStatusModal = () => {
    handleOpenModal();
  };

  const onHandleCloseModal = () => {
    setDeletedCommentError('');
    setDeletedComment('');
    handleCloseModal();
  };

  const onChangeEmployeeStatus = async () => {
    setDeletedCommentError('');
    setDeletedComment('');
    if (!userId || !userStatus) {
      return;
    }
    if (
      !deletedComment.replace(EMPTY_SPACES_REGEX, '') &&
      (userStatus === UserStatus.Active || userStatus === UserStatus.Registered)
    ) {
      setDeletedCommentError(REQUIRED);
      return;
    }
    userStatus === UserStatus.Active
      ? deactivateUserMutation({ userId: userId, comment: deletedComment })
      : activateUserMutation({ userId: userId, user: { comment: deletedComment } });
  };

  const onHandleFocusTextArea = () => {
    setDeletedCommentError('');
    if (
      isFocusedDeletedComment &&
      !deletedComment.replace(EMPTY_SPACES_REGEX, '') &&
      (userStatus === UserStatus.Active || userStatus === UserStatus.Registered)
    ) {
      setDeletedCommentError(REQUIRED);
    }
    setIsFocusedDeletedComment(!isFocusedDeletedComment);
  };

  return {
    deletedComment,
    deletedCommentError,
    onHandleOpenChangeStatusModal,
    handleCloseModal: onHandleCloseModal,
    onChangeEmployeeStatus,
    setDeletedComment,
    isOpenModal: isOpen,
    onHandleFocusTextArea,
  };
};
