import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { BoxProps, DialogProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { ReactComponent as LogoIcon } from 'assets/icons/logos/starLogo105.svg';
import { SubmitRoundedButton, CancelRoundedButton } from 'components';

import { Dialog, DialogTitle, DialogActions, CloseButton, DialogContent } from './styled';

interface ConfirmDialogProps extends DialogProps {
  onClose?: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  subTitle?: string;
  children?: ReactNode;
  dialogSx?: BoxProps['sx'];
  titleSx?: BoxProps['sx'];
  contentSx?: BoxProps['sx'];
  withLogo?: boolean;
  isContentOverflow?: boolean;
}

export function ConfirmDialog({
  title,
  onClose,
  onSubmit,
  open,
  isLoading,
  subTitle,
  dialogSx,
  titleSx,
  children,
  withLogo = false,
  isContentOverflow,
  contentSx,
  ...otherProps
}: ConfirmDialogProps): ReactElement {
  return (
    <Dialog open={open} sx={dialogSx} {...otherProps}>
      {withLogo && <LogoIcon style={{ margin: '0 auto' }} />}

      {subTitle && (
        <div>
          <CloseButton
            variant="text"
            startIcon={<KeyboardBackspaceRoundedIcon fontSize="large" />}
            onClick={onClose}
          >
            {subTitle}
          </CloseButton>
        </div>
      )}

      {title && <DialogTitle sx={titleSx}>{title}</DialogTitle>}
      {children && (
        <DialogContent sx={contentSx} isContentOverflow={isContentOverflow}>
          {children}
        </DialogContent>
      )}
      <DialogActions>
        <SubmitRoundedButton onClick={onSubmit} disabled={isLoading} />
        {onClose && <CancelRoundedButton onClick={onClose} />}
      </DialogActions>
    </Dialog>
  );
}
