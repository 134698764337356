import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Backdrop,
  BaseTextFieldController,
  DatePickerWithIcon,
  FileUploadButtons,
  Form,
  FormAttachments,
  FormGroup,
  RichEditorController,
  SelectController,
  SubmitRoundedButton,
} from 'components';
import { POLL_FORM_FIELDS_LENGTH } from 'constants/fieldsLength';
import { useWindowSize } from 'hooks';
import { textFieldStyles, textFieldMobileStyles, richEditorStyles } from 'styles';
import { PollFormValues, PostDraft } from 'types';

import { initialWindowSizeProps } from '../constants';
import { OptionsFields } from '../OptionsFields';
import { AllOption } from './constants';
import { usePollForm } from './hooks';
import { ButtonsW } from './styled';
import { multiSelectStyles, submitButtonSx } from './styles';
import { descriptionFieldRules, titleFieldRules, visibilityFieldRules } from './validation';

interface PollFormProps {
  isLoading: boolean;
  defaultValues: (draft?: PostDraft) => PollFormValues;
  onSubmit: (data: PollFormValues, attachmentIds: string[]) => void;
  draft?: PostDraft | null;
  mode?: 'create' | 'edit';
}

export function PollForm({
  isLoading: isPollMutationLoading,
  defaultValues,
  onSubmit,
  draft,
  mode = 'create',
}: PollFormProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize(initialWindowSizeProps);
  const isEditMode = mode === 'edit';

  const {
    isLoading,
    isSubmitting,
    isDirty,
    isSubmitted,
    isValidOptionsNumber,
    control,
    attachedImages,
    attachedDocs,
    attachedVideos,
    usersOptions,
    progress,
    getValues,
    clearDeadline,
    handleFormSubmit,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    handleAssigneeChange,
  } = usePollForm({
    draft,
    isPollMutationLoading,
    isEditMode,
    defaultValues,
    onSubmit,
  });

  const isSubmitButtonDisabled = (isEditMode && !isDirty) || isSubmitting || isPollMutationLoading;
  const formGroupMarginBottom = isMobile ? 16 : 24;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;

  return (
    <>
      {(isLoading || isSubmitting) && <Backdrop progress={progress} />}

      <>
        <Form onSubmit={handleFormSubmit}>
          <FormGroup marginBottom={formGroupMarginBottom}>
            <BaseTextFieldController
              name="title"
              control={control}
              placeholder={t('poll.create.form.placeholders.title')}
              sx={fieldStyles}
              maxLength={POLL_FORM_FIELDS_LENGTH.title.max}
              rules={titleFieldRules}
              disabled={isEditMode}
            />
          </FormGroup>

          <FormGroup marginBottom={formGroupMarginBottom}>
            <SelectController
              name="visibility"
              control={control}
              placeholder={t('poll.create.form.placeholders.visibility')}
              options={[AllOption, ...usersOptions]}
              multipleSelect
              sx={multiSelectStyles(isMobile)}
              handleChange={handleAssigneeChange}
              type="poll"
              rules={visibilityFieldRules}
            />
          </FormGroup>

          <FormGroup marginBottom={formGroupMarginBottom} row>
            {!isMobile && (
              <FormAttachments
                attachedImages={attachedImages}
                attachedDocs={attachedDocs}
                attachedVideos={attachedVideos}
                handleDeleteAttachment={handleDeleteAttachment}
                draftId={draft?.id}
              />
            )}

            {isMobile && (
              <FormAttachments
                attachedImages={attachedImages}
                attachedDocs={attachedDocs}
                attachedVideos={attachedVideos}
                handleDeleteAttachment={handleDeleteAttachment}
                draftId={draft?.id}
              />
            )}

            <RichEditorController
              name="description"
              control={control}
              placeholder={t('poll.create.form.placeholders.description')}
              maxLength={POLL_FORM_FIELDS_LENGTH.description.max}
              rules={descriptionFieldRules}
              sx={richEditorStyles(isMobile)}
            />
            <ButtonsW>
              <DatePickerWithIcon
                control={control}
                name="deadline"
                label={t('poll.create.form.placeholders.deadline')}
                clearValue={clearDeadline}
              />
              <FileUploadButtons
                handleAddImages={handleAddImages}
                handleAddDocuments={handleAddDocuments}
                handleAddVideos={handleAddVideos}
                classNames="notMarginTop"
              />
            </ButtonsW>
          </FormGroup>

          <OptionsFields
            control={control}
            getValues={getValues}
            isSubmitted={isSubmitted}
            isValidOptionsNumber={isValidOptionsNumber}
            disabled={isEditMode}
          />

          {!isMobile && (
            <SubmitRoundedButton disabled={isSubmitButtonDisabled} sx={submitButtonSx} />
          )}
        </Form>

        {isMobile && (
          <SubmitRoundedButton
            disabled={isSubmitButtonDisabled}
            sx={submitButtonSx}
            className="fixed-bottom"
            onClick={handleFormSubmit}
          />
        )}
      </>
    </>
  );
}
