import { Button, styled } from '@mui/material';

export const ToggleBtn = styled(Button)`
  width: 130px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
  backdrop-filter: blur(10px);
  box-shadow: none;
  border-radius: 12px;
  text-transform: none;
  height: 48px;
  padding-left: 8px;
  padding-right: 16px;
  justify-content: space-between;

  &:hover {
    background-color: rgba(0, 0, 0, 0.01);
  }
  @media (max-width: 1023px) {
    width: 80px;
  }
`;

export const Triangle = styled('span')`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #000000 transparent transparent transparent;
`;

export const OptionBtn = styled(ToggleBtn)`
  width: fit-content;
`;
