import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, FormGroup, SubmitRoundedButton, TextFieldController } from 'components';
import { useWindowSize } from 'hooks';
import { PasswordResetFormValues } from 'types';

import { emailFieldRules } from './rules';
import { FooterW } from './styled';
import { useRestorePasswordForm } from './useRestorePasswordForm';

const EMAIL_MAX_LENGTH = 254;

interface PasswordResetFormProps {
  setEmail: (email: string) => void;
  isLoading: boolean;
  requestPasswordResetMutation: (data: PasswordResetFormValues) => void;
}

export function PasswordResetForm({
  setEmail,
  requestPasswordResetMutation,
}: PasswordResetFormProps): ReactElement {
  const { control, handleFormSubmit } = useRestorePasswordForm({
    setEmail,
    requestPasswordResetMutation,
  });
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  return (
    <Form sx={{ marginTop: isMobile ? '32px' : '56px' }} onSubmit={handleFormSubmit}>
      <FormGroup marginBottom={isMobile ? 24 : 56}>
        <TextFieldController
          name="email"
          id="email"
          control={control}
          type="email"
          label={t('restorePassword.form.label')}
          placeholder={t('restorePassword.form.label')}
          rules={emailFieldRules}
          maxLength={EMAIL_MAX_LENGTH}
        />
      </FormGroup>

      <FooterW>
        <div>{t('restorePassword.footer')}</div>
        <SubmitRoundedButton />
      </FooterW>
    </Form>
  );
}
