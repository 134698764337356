import { Checkbox, styled } from '@mui/material';

export const ButtonsW = styled('div')`
  display: flex;
  gap: 8px;
`;

export const CheckboxW = styled('div')`
  display: flex;
  align-items: center;
`;

export const CheckboxInput = styled(Checkbox)`
  width: 20px;
  height: 20px;
`;

export const LabelW = styled('label')`
  margin-left: 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.primary.main};

  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;
