import { useQuery } from '@tanstack/react-query';

import { API_HANDLERS } from 'api/apiHandlers';

interface UseGoalDetailsQueryProps {
  userId: string;
  achievementId: string;
  goalId: string;
}

export function useGoalDetailsQuery({ userId, achievementId, goalId }: UseGoalDetailsQueryProps) {
  const { isLoading, isSuccess, data, refetch } = useQuery(
    ['goal-details', achievementId, goalId],
    () => API_HANDLERS.ACHIEVEMENTS.GOAL_DETAILS({ userId, achievementId, goalId }),
    {
      enabled: !!userId && !!achievementId && !!goalId,
    },
  );

  return {
    isLoading,
    isSuccess,
    data: data?.data,
    refetch,
  };
}
