import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';

import { CreateCategoryRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import * as ERROR_CODES from 'constants/errorCodes';
import { ApiErrors } from 'types';

interface UseEditCategoryMutationReturn {
  editCategoryMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    CreateCategoryRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseEditCategoryMutationProps {
  successText: string;
  invalidateQueries?: string[];
  setShouldReset?: (value: boolean) => void;
  setIsSuccess?: Dispatch<SetStateAction<boolean>>;
}

export function useEditCategoryMutation({
  successText,
  invalidateQueries,
  setShouldReset,
  setIsSuccess,
}: UseEditCategoryMutationProps): UseEditCategoryMutationReturn {
  const { mutateAsync: editCategoryMutation, isLoading } = useMutation(API_HANDLERS.CATEGORY.EDIT, {
    onSuccess: () => {
      setIsSuccess && setIsSuccess(true);
      alert.success(successText);

      if (invalidateQueries) {
        queryClient.invalidateQueries(invalidateQueries);
      }
    },

    onError: (error: AxiosError<ApiErrors>) => {
      if (error.response?.data.errors[0].code === ERROR_CODES.UPDATE_NOT_ALLOWED) {
        setShouldReset?.(true);
      }
      if (error.response?.data.errors[0].code === ERROR_CODES.INVALID_REQUEST) {
        alert.error(error.response?.data.errors[0].description);
      }
    },
  });

  return {
    editCategoryMutation,
    isLoading,
  };
}
