import { Typography, styled } from '@mui/material';

export const SliderLabel = styled(Typography)`
  font-size: 18px;
  line-height: 18px;
  @media (max-width: 1023px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
