import { ReactElement, SyntheticEvent } from 'react';

import { EquipmentTab, EquipmentTabsValues } from 'types';

import { TabW, TabsW } from './styled';
import { tabsStyles } from './styles';

interface TabsProps {
  tabs: EquipmentTab[];
  activeTab: EquipmentTabsValues;
  handleChange: (e: SyntheticEvent<Element, Event>, newValue: EquipmentTabsValues) => void;
}

export function Tabs({ activeTab, tabs, handleChange }: TabsProps): ReactElement {
  return (
    <TabsW
      value={activeTab}
      onChange={handleChange}
      sx={tabsStyles}
      TabIndicatorProps={{
        style: {
          transition: 'none',
        },
      }}
    >
      {tabs.map(({ value, label }) => (
        <TabW key={value} value={value} label={label} />
      ))}
    </TabsW>
  );
}
