import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CreatePostDraftResponse } from 'api';
import {
  Backdrop,
  BaseTextFieldController,
  ConfirmDialog,
  Form,
  FormGroup,
  SubmitRoundedButton,
  FileUploadButtons,
  FormAttachments,
  RichEditorController,
  SelectController,
} from 'components';
import { POST_FIELDS_LENGTH } from 'constants/fieldsLength';
import { PostListItem } from 'features/postsList';
import { useWindowSize } from 'hooks';
import { textFieldStyles, textFieldMobileStyles, richEditorStyles } from 'styles';
import { InstanceType } from 'types';

import { AllOption } from './constants';
import { useCreatePostForm } from './hooks';
import { descriptionFieldRules, titleFieldRules, visibilityFieldRules } from './rules';
import { FormAndPreviewW, FromContainer, PreviewTitle } from './styled';
import { descriptionWStyles, multiSelectStyles } from './styles';

interface CreatePostFormProps {
  draft: CreatePostDraftResponse;
}

export function CreatePostForm({ draft }: CreatePostFormProps): ReactElement {
  const { t } = useTranslation();

  const {
    control,
    handleFormSubmit,
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    attachedImages,
    attachedDocs,
    attachedVideos,
    handleDeleteAttachment,
    isLoading,
    isOpen,
    handleSubmitConfirmModal,
    postTitle,
    postDescription,
    author,
    attachments,
    usersOptions,
    categoryMap,
    progress,
    handleAssigneeChange,
    handleCloseConfirmModal,
  } = useCreatePostForm({ draft });

  const { isMobile } = useWindowSize();

  const formGroupMarginBottom = isMobile ? 8 : 36;
  const fieldStyles = isMobile ? textFieldMobileStyles : textFieldStyles;
  const descriptionStyles = isMobile ? undefined : descriptionWStyles;

  return (
    <>
      {isLoading && <Backdrop progress={progress} />}

      <FormAndPreviewW>
        <FromContainer>
          <Form onSubmit={handleFormSubmit}>
            <FormGroup marginBottom={formGroupMarginBottom}>
              <BaseTextFieldController
                name="title"
                control={control}
                placeholder={t('post.create.form.placeholders.title')}
                sx={fieldStyles}
                maxLength={POST_FIELDS_LENGTH.title.max}
                rules={titleFieldRules}
              />
            </FormGroup>

            <FormGroup marginBottom={formGroupMarginBottom}>
              <SelectController
                name="visibility"
                control={control}
                placeholder={t('poll.create.form.placeholders.visibility')}
                options={[AllOption, ...usersOptions]}
                multipleSelect
                sx={multiSelectStyles(isMobile)}
                handleChange={handleAssigneeChange}
                type="poll"
                rules={visibilityFieldRules}
              />
            </FormGroup>

            <FormGroup marginBottom={formGroupMarginBottom} row sx={descriptionStyles}>
              <RichEditorController
                name="description"
                control={control}
                placeholder={t('post.create.form.placeholders.description')}
                maxLength={POST_FIELDS_LENGTH.description.max}
                rules={descriptionFieldRules}
                sx={richEditorStyles(isMobile)}
              />

              {isMobile && (
                <FormAttachments
                  attachedImages={attachedImages}
                  attachedDocs={attachedDocs}
                  attachedVideos={attachedVideos}
                  handleDeleteAttachment={handleDeleteAttachment}
                  draftId={draft.id}
                />
              )}
              <FileUploadButtons
                handleAddImages={handleAddImages}
                handleAddDocuments={handleAddDocuments}
                handleAddVideos={handleAddVideos}
                classNames="notMarginTop"
              />
            </FormGroup>

            {!isMobile && (
              <FormAttachments
                attachedImages={attachedImages}
                attachedDocs={attachedDocs}
                attachedVideos={attachedVideos}
                handleDeleteAttachment={handleDeleteAttachment}
                draftId={draft.id}
              />
            )}
            {!isMobile && <SubmitRoundedButton sx={{ marginLeft: 'auto', marginTop: '32px' }} />}
          </Form>
        </FromContainer>

        {isMobile && (
          <SubmitRoundedButton
            sx={{ marginLeft: 'auto', marginTop: '32px' }}
            className="fixed-bottom"
            onClick={handleFormSubmit}
          />
        )}

        {author && (postTitle || postDescription || attachments) && !isMobile && (
          <Box position="relative">
            <PreviewTitle>{t('post.create.preview')}</PreviewTitle>
            <PostListItem
              id={draft.id}
              title={postTitle}
              description={postDescription}
              author={author}
              isPreview
              {...(attachments && { attachments })}
              type={InstanceType.news}
              categoryMap={categoryMap}
            />
          </Box>
        )}
      </FormAndPreviewW>

      <ConfirmDialog
        subTitle={t('post.create.modal.exit')}
        title={t('post.create.modal.title')}
        open={isOpen}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
