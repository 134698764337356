import { BoxProps } from '@mui/material';
import { ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { Attachment } from 'types';

import { MoreImages, PlayButtonContainer, StyledImage, Thumb, VideoW } from './styled';

interface VideoAttachmentProps {
  postId?: string;
  video: Attachment;
  wrapperSx?: BoxProps['sx'];
  moreVideos?: number | null;
  hasMoreImagesClick?: () => void;
  onOpenGallery?: () => void;
  expandViewClick?: () => void;
}

export function ListItemVideoAttachment({
  video,
  wrapperSx,
  moreVideos,
  onOpenGallery,
  expandViewClick,
}: VideoAttachmentProps): ReactElement {
  const { ref } = useInView();
  const { t } = useTranslation();

  const hasMoreVideos = Boolean(moreVideos);

  let videoThumbnailurl = '';
  if (video?.file_url.original) {
    const videoId = video?.file.video_uid;
    videoThumbnailurl = `https://customer-tr04cbm6ks3rgpwn.cloudflarestream.com/${videoId}/thumbnails/thumbnail.gif?time=1s&height=200&duration=4s`;
  }

  function handleVideoClick(e: SyntheticEvent) {
    e.stopPropagation();
    onOpenGallery ? onOpenGallery() : expandViewClick?.();
  }

  return (
    <Thumb ref={ref} sx={wrapperSx} onClick={handleVideoClick}>
      <VideoW>
        {videoThumbnailurl && <StyledImage src={videoThumbnailurl} />}
        <PlayButtonContainer>
          <PlayIcon />
        </PlayButtonContainer>
        {hasMoreVideos && (
          <MoreImages>{t('common.moreImages', { count: moreVideos as number })}</MoreImages>
        )}
      </VideoW>
    </Thumb>
  );
}
