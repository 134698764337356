import { BoxProps } from '@mui/material';
import { ReactElement, SyntheticEvent } from 'react';

import { useAttachmentDetails } from 'api';
import { ReactComponent as RemoveIcon } from 'assets/icons/closeRounded.svg';
import { handleFileDownload } from 'utils';

import {
  DocumentW,
  DocumentNameW,
  DocumentNameWIn,
  Wrapper,
  DownloadIconW,
  RemoveDocBtn,
  OuterW,
} from './styled';

interface DocumentAttachmentProps {
  postId?: string;
  attachmentId: string;
  fileName: string;
  originalFileUrl: string;
  handleDelete?: () => void;
  hasDownload?: boolean;
  wrapperSx?: BoxProps['sx'];
  originalFileName: string;
  blockDelete?: boolean;
  isPreview?: boolean;
}

export function DocumentAttachment({
  fileName,
  postId,
  attachmentId,
  handleDelete,
  hasDownload,
  wrapperSx,
  originalFileName,
  originalFileUrl,
  isPreview,
  blockDelete,
}: DocumentAttachmentProps): ReactElement {
  const { refetch } = useAttachmentDetails({
    postId,
    attachmentId: attachmentId,
    fileName: originalFileName,
    fileUrl: originalFileUrl,
    enabled: false,
  });
  async function handleAttachmentRemove() {
    handleDelete?.();
  }
  async function handleDownloadClick(e: SyntheticEvent) {
    e.stopPropagation();
    const { data } = await refetch();

    if (data?.data && !isPreview) {
      await handleFileDownload({ blobFile: data.data, fileName });
    }
  }

  return (
    <OuterW>
      <DocumentW sx={wrapperSx} canDownload={!!hasDownload} onClick={handleDownloadClick}>
        <DocumentNameW className="file-name">{fileName}</DocumentNameW>
        <Wrapper
          className="download-icon-wrapper"
          onClick={!!hasDownload ? handleDownloadClick : undefined}
        >
          <DocumentNameWIn>{fileName}</DocumentNameWIn>
          <DownloadIconW className="download-icon" sx={{ height: '21px', width: '21px' }} />
        </Wrapper>
      </DocumentW>
      {!blockDelete && (
        <RemoveDocBtn onClick={handleAttachmentRemove}>
          <RemoveIcon />
        </RemoveDocBtn>
      )}
    </OuterW>
  );
}
