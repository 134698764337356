import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { CreatePostDraftResponse } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UseCreateDraftMutationReturn {
  createDraftMutation: UseMutateAsyncFunction<
    AxiosResponse<CreatePostDraftResponse, unknown>,
    unknown,
    void,
    unknown
  >;
  isLoading: boolean;
}

export function useCreateDraftMutation(): UseCreateDraftMutationReturn {
  const { mutateAsync: createDraftMutation, isLoading } = useMutation(
    API_HANDLERS.POST.CREATE_DRAFT,
  );

  return {
    createDraftMutation,
    isLoading,
  };
}
