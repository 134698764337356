export enum SubscriptionsPeriod {
  month = 'month',
  year = 'year',
}

export interface SubscriptionPlan {
  id: string;
  name: ProductName;
  prices: {
    [key in SubscriptionsPeriod]: {
      [key: string]: { currency: 'string'; price: number }[];
    };
  };
}

export enum ProductName {
  FRIEND = 'FRIEND',
  FRIEND_EXTRA = 'FRIEND-EXTRA',
  PARTNER = 'PARTNER',
  PARTNER_EXTRA = 'PARTNER-EXTRA',
  PROBATION = 'PROBATION',
  PROBATION_EXTRA = 'PROBATION-EXTRA',
}

export enum SubscriptionType {
  trial = 'trial',
  friend = 'friend',
  partner = 'partner',
  probation = 'probation',
}

export enum SubscriptionStatus {
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  trialing = 'trialing',
  active = 'active',
  past_due = 'past_due',
  canceled = 'canceled',
  unpaid = 'unpaid',
}

export interface Product {
  id: string;
  name: ProductName;
  price: {
    id: string;
    currency: string;
    price: number;
  };
  isActive: boolean;
  isCanceled?: boolean;
  isBlocked?: boolean;
  disabled: boolean;
}

export interface StripeProduct {
  id: string;
  name: ProductName;
  price: {
    id: string;
    currency: string;
    price: number;
  };
}

export interface ProductDetails {
  id: string;
  name: string;
  prices: {
    month: {
      [key: string]: {
        currency: string;
        price: number;
      }[];
    };
    year: {
      [key: string]: {
        currency: string;
        price: number;
      }[];
    };
  };
}

export interface Subscription {
  type: SubscriptionType;
  status: SubscriptionStatus;
  period: SubscriptionsPeriod;
  currency: string;
  extra_places?: number;
  expires_at: string;
  next_payment_at?: string;
  canceled_at?: string;
  current_payment_method_id: string;
  users_count: 0;
  created_at: string;
  started_at: string;
  updated_at: string;
  comment: string;
}

export interface SubscriptionContextValues {
  subscription: Subscription | null;
  isTrial: boolean;
  isVisibleTrialNotification: boolean;
  isVisibleMockDataBanner: boolean;
  isExpired: boolean;
  isCanceled: boolean;
  saveSubscription: (data: Subscription) => void;
  closeNotification: () => void;
  addNotification: () => void;
  closeMockDataBanner: () => void;
  addMockDataBanner: () => void;
}

export type ProductType = 'extra' | 'main';

export interface GroupedProduct {
  id: string;
  name: ProductName;
  price: {
    id: string;
    currency: string;
    price: number;
  };
}
