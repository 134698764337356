import { Box, styled } from '@mui/material';

export const Row = styled(Box)`
  display: flex;
  margin-top: 24px;
  @media (max-width: 1023px) {
    flex-direction: column;

    &.no-margin-top {
      margin-top: 0px;
    }
  }
`;

export const FirstRow = styled(Row)`
  @media (min-width: 1024px) {
    width: 1106px;
  }
`;
