import { ReactElement } from 'react';

import { ReactComponent as AdligoStarLogo } from 'assets/icons/logos/starLogo63.svg';
import { ReactComponent as AdligoTextLogo } from 'assets/icons/logos/textLogo.svg';
import { useWindowSize } from 'hooks';
import { AuthPageLink } from 'types';

import { pageLinks } from './config';
import { HeaderW, SignInLink } from './styled';

interface PageHeaderProps {
  link?: AuthPageLink;
}

export function PageHeader({ link }: PageHeaderProps): ReactElement {
  const { isMobile } = useWindowSize();
  const logoStyles = {
    marginRight: isMobile ? '16px' : '33px',
    width: isMobile ? '36px' : 'unset',
    height: isMobile ? '36px' : 'unset',
  };
  const logoTextStyles = {
    width: isMobile ? '70px' : 'unset',
    height: isMobile ? '40px' : 'unset',
  };
  return (
    <HeaderW>
      <AdligoStarLogo style={logoStyles} />
      <AdligoTextLogo style={logoTextStyles} />
      {link && <SignInLink to={pageLinks[link].link}>{pageLinks[link].text}</SignInLink>}
    </HeaderW>
  );
}
