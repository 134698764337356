import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES } from 'constants/index';
import { InViewItemRef, Notification } from 'types';

interface UseNotificationsQueryReturn {
  isLoading: boolean;
  notifications: Notification[];
  lastItemRef: InViewItemRef;
  isFetchingNextPage: boolean;
  total: number;
  isEmptyList: boolean;
  lastItemId: string;
  refetch: () => void;
}

interface UseNotificationsQueryProps {
  limit: number;
  since_id?: number;
}

export function useNotificationsQuery({
  since_id,
  limit,
}: UseNotificationsQueryProps): UseNotificationsQueryReturn {
  const lastItemIdRef = useRef('');

  const { ref, inView } = useInView();

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } =
    useInfiniteQuery(
      [QUERIES.NOTIFICATIONS_LIST, limit, since_id],
      ({ pageParam }) => {
        return API_HANDLERS.NOTIFICATIONS.GET_LIST({
          ...(limit && { limit }),
          ...(since_id && { since_id }),
          ...(pageParam && pageParam),
        });
      },
      {
        getNextPageParam: (lastPage, allPages) => {
          const total = lastPage.data.total;
          if (!total) return;

          const loadedItemsCount = allPages.reduce((prev, curr) => prev + curr.data.data.length, 0);
          lastItemIdRef.current = lastPage.data.data[lastPage.data.data.length - 1].id;

          return total > loadedItemsCount ? { since_id: lastItemIdRef.current, limit } : undefined;
        },
      },
    );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage]);

  const isEmptyList = data?.pages.length === 1 && data?.pages[0].data.data === null;
  const notificationsList = isEmptyList ? [] : data?.pages.map((page) => page.data.data).flat();

  return {
    isLoading,
    notifications: notificationsList || [],
    lastItemRef: ref,
    isFetchingNextPage,
    total: data?.pages[0].data.total || 0,
    isEmptyList,
    lastItemId: lastItemIdRef.current,
    refetch,
  };
}
