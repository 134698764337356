import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextFieldController, Form, FormGroup, Slider, SubmitRoundedButton } from 'components';
import { LanguageSelector } from 'features/languageSelector';
import { useWindowSize } from 'hooks';
import { ApiErrors, CompanyInfoFormValues, CompanySize } from 'types';

import { TermsAndConditions } from '../TermsAndConditions';
import { useCompanyProfileForm } from './hooks';
import { companyNameRules, companyNumberRules, industryRules } from './rules';
import { SliderLabel } from './styled';

interface CompanyProfileFormProps {
  defaultValues: CompanyInfoFormValues;
  storeValues: (values: CompanyInfoFormValues) => void;
  handleRegisterSubmit: () => void;
  errors?: Record<string, ApiErrors['errors']> | null;
}

export function CompanyProfileForm({
  defaultValues,
  storeValues,
  handleRegisterSubmit,
  errors,
}: CompanyProfileFormProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { handleFormSubmit, control } = useCompanyProfileForm({
    defaultValues,
    storeValues,
    handleRegisterSubmit,
    errors,
  });

  const companySizeValues = Object.values(CompanySize);
  const companySizeValuesCount = companySizeValues.length - 1;

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormGroup marginBottom={isMobile ? 24 : 56}>
        <TextFieldController
          name="companyName"
          control={control}
          label={t('companyRegistration.companyProfileForm.companyName')}
          placeholder={t('companyRegistration.companyProfileForm.companyName')}
          rules={companyNameRules}
          maxLength={40}
        />
      </FormGroup>
      <FormGroup marginBottom={isMobile ? 24 : 56}>
        <TextFieldController
          name="number"
          control={control}
          label={t('companyRegistration.companyProfileForm.number')}
          placeholder={t('companyRegistration.companyProfileForm.number')}
          rules={companyNumberRules}
          maxLength={20}
        />
      </FormGroup>
      <FormGroup marginBottom={isMobile ? 50 : 86}>
        <TextFieldController
          name="industry"
          control={control}
          label={t('companyRegistration.companyProfileForm.industry')}
          placeholder={t('companyRegistration.companyProfileForm.industry')}
          rules={industryRules}
          maxLength={70}
        />
      </FormGroup>
      <FormGroup marginBottom={24}>
        <Controller
          control={control}
          name="companySize"
          render={({ field: { value, onChange } }) => (
            <Slider
              min={0}
              max={companySizeValuesCount}
              step={1}
              onChange={onChange}
              value={value}
              valueLabelFormat={(x) => `${companySizeValues[x]}`}
              aria-label="company-size"
            />
          )}
        />
        <SliderLabel>{t('companyRegistration.companyProfileForm.howMany')}</SliderLabel>
      </FormGroup>
      <FormGroup marginBottom={24}>
        <TermsAndConditions />
      </FormGroup>
      <FormGroup
        marginBottom={isMobile ? 24 : 48}
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        row
      >
        <LanguageSelector />
        <SubmitRoundedButton />
      </FormGroup>
    </Form>
  );
}
