import { isEqual } from 'date-fns';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, generatePath } from 'react-router-dom';

import { PostListItem, PostSubTypes } from 'api';
import { Links } from 'settings';
import { InstanceType, CategoriesListType } from 'types';
import { getFullName } from 'utils';

interface UsePostItemProps {
  author: PostListItem['author'];
  meta?: PostListItem['meta'];
  tags?: string[];
  id: string;
  isPreview: boolean;
  description: string;
  type: string;
  published_at?: string;
  updated_at?: string;
  title: string;
  categoryMap: CategoriesListType;
}

export function usePostItem({
  author,
  meta,
  tags,
  id,
  isPreview,
  description,
  type,
  published_at,
  updated_at,
  title,
  categoryMap,
}: UsePostItemProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const isWelcomePost = meta?.sub_type === PostSubTypes.WELCOME;
  const isBirthdayPost = meta?.sub_type === PostSubTypes.BIRTHDAY;
  const isPollPost = meta?.sub_type === PostSubTypes.POLL;
  const pollId = meta?.poll_id;
  const postRef = useRef<HTMLDivElement>(null);

  const offset = searchParams.get('offset');
  const searchPostId = searchParams.get('postId');

  const textWithNewLines = (description || '').replace(/\n/g, '<br />');
  const isDocumentPost = type === InstanceType.document && !isPreview;
  const documentTitle = isEqual(new Date(published_at || ''), new Date(updated_at || ''))
    ? t('post.listItem.documentTitle', { title })
    : t('post.listItem.updatedDocumentTitle', { title });

  const authorName = getFullName({
    firstName: author.first_name,
    lastName: author.last_name,
  });
  const TITLE = isDocumentPost ? documentTitle : title;
  const firstTag = tags && tags[0];
  const otherTagsCount = tags && tags.length - 1;
  const tagName = firstTag && firstTag.replace('-and-', ' & ');
  const categoryTag = tagName && categoryMap[tagName];

  function goToPostDetails() {
    navigate(
      generatePath(Links.we.posts.index, {
        postId: id,
      }),
      { state: offset ? { offset: offset.replace(/\D/g, '') } : {} },
    );
  }

  useEffect(() => {
    if (postRef.current && searchPostId === id) {
      postRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [id, searchPostId]);

  const handlePostClick = isPreview ? undefined : goToPostDetails;

  const onNavigateToNewUser = () => {
    navigate(
      generatePath(Links.we.employees.employee.index, {
        employeeId: author.id,
      }),
    );
  };

  return {
    authorName,
    postRef,
    categoryTag,
    otherTagsCount,
    textWithNewLines,
    postTitle: TITLE,
    isDocumentPost,
    isWelcomePost,
    isBirthdayPost,
    isPollPost,
    pollId,
    goToPostDetails,
    handlePostClick,
    onNavigateToNewUser,
  };
}
