import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { PostListItem } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { QUERIES } from 'constants/index';
import { InViewItemRef, InstanceType, InstanceLevelType } from 'types';

interface UseDocumentsListQueryReturn {
  isLoading: boolean;
  documents: PostListItem[];
  lastItemRef: InViewItemRef;
  isFetchingNextPage: boolean;
  total: number;
  isEmptyList: boolean;
}

interface UseDocumentListQueryProps {
  type: InstanceType;
  limit: number;
  offset?: number;
  level?: InstanceLevelType[];
  tag?: string | string[];
  assigned?: string;
}

export function useDocumentsListQuery({
  type,
  limit,
  offset,
  level,
  tag,
  assigned,
}: UseDocumentListQueryProps): UseDocumentsListQueryReturn {
  const { ref, inView } = useInView();

  const { data, isLoading, isFetching, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      [QUERIES.DOCUMENTS_LIST, type, limit, offset, level, tag, assigned],
      ({ pageParam }) =>
        API_HANDLERS.POST.GET_LIST({
          type,
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(level && { level }),
          ...(tag && { tag }),
          ...(assigned && { assigned }),
          ...(pageParam && pageParam),
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const total = lastPage.data.total;
          if (!total) return;
          const loadedItems = allPages.reduce((prev, curr) => prev + curr.data.data.length, 0);
          return total > loadedItems ? { offset: loadedItems, limit } : undefined;
        },
      },
    );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage]);

  const isEmptyList = data?.pages.length === 1 && data?.pages[0].data.data === null;
  const documentsList = isEmptyList ? [] : data?.pages.map((page) => page.data.data).flat();

  return {
    isLoading: isFetching || isLoading,
    documents: documentsList || [],
    lastItemRef: ref,
    isFetchingNextPage,
    total: data?.pages[0].data.total || 0,
    isEmptyList,
  };
}
