import { styled } from '@mui/material';

export const ContentW = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const FormW = styled('div')`
  width: 672px;
  position: relative;
  border-radius: 15px;
  padding: 32px 24px 40px;

  &:before {
    content: '';
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 15px;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
  }
`;
