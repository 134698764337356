import { ReactElement } from 'react';

import { Backdrop } from 'components';
import { User } from 'types';

import { EquipmentForm } from '../EquipmentForm';
import { useEditEquipmentForm } from './hooks';
import { ContentW, FormW } from './styled';

interface EditEquipmentFormProps {
  usersList: User[];
}

export function EditEquipmentForm({ usersList }: EditEquipmentFormProps): ReactElement {
  const { isDetailsLoading, isDetailsLoaded, isEditEquipmentLoading, defaultValues, handleSubmit } =
    useEditEquipmentForm();

  return (
    <>
      {isDetailsLoading && <Backdrop />}

      {isDetailsLoaded && (
        <ContentW>
          <FormW>
            <EquipmentForm
              mode="edit"
              isLoading={isEditEquipmentLoading}
              usersList={usersList}
              defaultValues={defaultValues}
              onSubmit={handleSubmit}
            />
          </FormW>
        </ContentW>
      )}
    </>
  );
}
