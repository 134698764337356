import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';

import { API_HANDLERS } from 'api/apiHandlers';
import { Spinner } from 'components';
import { useAuth } from 'hooks';
import { ProductName, SubscriptionsPeriod } from 'types';

import { CalculationBanner } from '../CalculationBanner';
import { useStripeProducts } from '../hooks';
import { Subscription } from '../Subscription';
import { PRODUCTS_IMAGES } from './config';
import { ContainerW, ListW } from './styled';

interface SubscriptionsListProps {
  period: SubscriptionsPeriod;
}

export function SubscriptionsList({ period }: SubscriptionsListProps): ReactElement {
  const { saveLocation } = useAuth();

  const { data, isLoading: isLoadingLocation } = useQuery(
    ['location'],
    () => API_HANDLERS.LOCATION.GET_LOCATION(),
    {
      onSuccess: (data) => {
        saveLocation(data.data.country);
      },
    },
  );

  const { isLoading: isLoadingProductsList, products } = useStripeProducts({
    period,
    location: data?.data.country,
  });

  const { ON_PROBATION, FRIEND, PARTNER } = PRODUCTS_IMAGES;

  if (isLoadingProductsList || isLoadingLocation) {
    return <Spinner />;
  }

  if (products) {
    return (
      <ContainerW>
        <ListW>
          <Subscription
            product={products[ProductName.PROBATION]}
            extraProduct={products[ProductName.PROBATION_EXTRA]}
            image={ON_PROBATION}
            period={period}
          />
          <Subscription
            product={products[ProductName.PARTNER]}
            extraProduct={products[ProductName.PARTNER_EXTRA]}
            image={FRIEND}
            period={period}
          />
          <Subscription
            product={products[ProductName.FRIEND]}
            extraProduct={products[ProductName.FRIEND_EXTRA]}
            image={PARTNER}
            period={period}
          />
        </ListW>
        <CalculationBanner />
      </ContainerW>
    );
  }

  return <></>;
}
