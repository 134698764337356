import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { EmptyState } from 'components';
import { Links } from 'settings';

import { EmptyStateIconW, NavLinkW } from './styled';

export function LinksEmptyState(): ReactElement {
  return (
    <EmptyState>
      <EmptyStateIconW />
      <div>
        <Trans i18nKey="quickLinks.emptyState">
          Nothing is here yet.
          <NavLinkW to={Links.we.quickLinks.createLink}>Add a Quick link</NavLinkW>!
        </Trans>
      </div>
    </EmptyState>
  );
}
