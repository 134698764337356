import { Subscription, SubscriptionsPeriod, SubscriptionType, ProductName } from 'types';

const YEAR_PLANS: Record<string, string[]> = {
  [SubscriptionType.probation]: [
    `${SubscriptionType.partner}_year`,
    `${SubscriptionType.friend}_year`,
  ],
  [SubscriptionType.partner]: [`${SubscriptionType.friend}_year`],
  [SubscriptionType.friend]: [''],
};

const MONTH_PLANS: Record<string, string[]> = {
  [SubscriptionType.probation]: [
    `${SubscriptionType.partner}_month`,
    `${SubscriptionType.friend}_month`,
    `${SubscriptionType.probation}_year`,
    `${SubscriptionType.partner}_year`,
    `${SubscriptionType.friend}_year`,
  ],
  [SubscriptionType.partner]: [
    `${SubscriptionType.friend}_month`,
    `${SubscriptionType.partner}_year`,
    `${SubscriptionType.friend}_year`,
  ],
  [SubscriptionType.friend]: [`${SubscriptionType.friend}_year`],
};

interface IsProductDisabledProps {
  productName: ProductName;
  activeProduct?: Subscription;
  period: SubscriptionsPeriod;
  isActivePlan: boolean;
  isTrialPeriod: boolean;
  subscriptionIsCanceled: boolean;
}

export function isProductDisabled({
  productName,
  activeProduct,
  period,
  isActivePlan,
  isTrialPeriod,
  subscriptionIsCanceled,
}: IsProductDisabledProps) {
  const name = productName.split('-')[0].toLowerCase();

  if (isActivePlan || isTrialPeriod || subscriptionIsCanceled) {
    return false;
  }

  if (activeProduct?.period === SubscriptionsPeriod.month && activeProduct) {
    return !MONTH_PLANS[activeProduct.type].includes(`${name}_${period}`);
  }

  if (activeProduct?.period === SubscriptionsPeriod.year && activeProduct) {
    return !YEAR_PLANS[activeProduct.type].includes(`${name}_${period}`);
  }

  return false;
}
