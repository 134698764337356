import { Attachment } from 'types';
export interface CreatePostFormValues {
  title: string;
  visibility?: string[];
  description: string;
  level?: DocumentsType;
  tags?: string[];
  images: Attachment[];
  docs: Attachment[];
  videos: Attachment[];
}

export interface PostDraft {
  created_at: string;
  id: string;
  level: string; // 'public'
  status: string; // 'draft'
  updated_at: string;
  attachments?: Attachment[];
}

export enum DocumentsType {
  PUBLIC = 'public',
  PRIVATE = 'private',
  SHARED = 'shared',
}

export interface CreatePostProps {
  typeOfPost?: TypeOfPost;
}

export enum TypeOfPost {
  POST = 'post',
  DOCUMENT = 'document',
  TASK = 'task',
}
