import { styled, Typography } from '@mui/material';

export const CardW = styled('div')<{ isPreview?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 18px;
  box-shadow: 0px 25px 12px -20px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.palette.white.main};
`;

export const Title = styled(Typography)`
  width: calc(100% - 40px);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-break: break-word;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const DescriptionW = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.primary.p050};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  @media (max-width: 1023px) {
    -webkit-line-clamp: 3;
  }

  & p {
    display: inline;
    margin: 0;

    &:has(code) {
      margin: 4px 0;
    }
  }

  & a {
    color: ${({ theme }) => theme.palette.info.main};
    z-index: 9999;
  }

  & h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    font-size: inherit;
  }
`;

export const DateContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const DateW = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const FooterW = styled('div')<{ isAssignee: boolean }>`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: ${({ isAssignee }) => isAssignee && 'space-between'};
`;

export const FooterWMax = styled('div')`
  display: grid;
  align-items: center;
  gap: 16px;
`;

export const FooterInner = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconW = styled('div')`
  position: relative;
  height: 20px;
`;

export const CountW = styled('span')`
  position: absolute;
  font-size: 11px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
`;

export const CommentsButtonW = styled('div')`
  position: absolute;
  top: 16px;
  right: 16px;
  width: min-content;
`;
