import { styled, Box } from '@mui/material';

export const TextW = styled(Box)`
  width: 467px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.primary.p080};
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
    width: unset;
  }
`;

export const ResendButton = styled('span')`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.info.main};
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-transform: none;
  margin-bottom: 7px;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const EmailW = styled('span')`
  font-weight: bold;
`;
