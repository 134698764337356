import { RegisterOptions } from 'react-hook-form';

import { ACHIEVEMENT_FORM_FIELDS_LENGTH } from 'constants/index';
import { getMaxLengthText, getMinLengthText, required } from 'validations';

export const titleFieldRules: RegisterOptions = {
  ...required,
  minLength: {
    value: ACHIEVEMENT_FORM_FIELDS_LENGTH.title.min,
    message: getMinLengthText(ACHIEVEMENT_FORM_FIELDS_LENGTH.title.min),
  },
  maxLength: {
    value: ACHIEVEMENT_FORM_FIELDS_LENGTH.title.max,
    message: getMaxLengthText(ACHIEVEMENT_FORM_FIELDS_LENGTH.title.max),
  },
};

export const descriptionFieldRules: RegisterOptions = {
  minLength: {
    value: ACHIEVEMENT_FORM_FIELDS_LENGTH.description.min,
    message: getMinLengthText(ACHIEVEMENT_FORM_FIELDS_LENGTH.description.min),
  },
  maxLength: {
    value: ACHIEVEMENT_FORM_FIELDS_LENGTH.description.max,
    message: getMaxLengthText(ACHIEVEMENT_FORM_FIELDS_LENGTH.description.max),
  },
};
