import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PostListItem, useEditPostMutation } from 'api';
import { TIME_DATE_FORMAT_WITH_COMMA, TIME_DATE_MONTH_WITH_COMMA } from 'constants/index';
import { TaskFormValues, User } from 'types';
import { formattedDate, getFullName } from 'utils';

import { TaskForm } from '../TaskForm';
import { defaultValuesAdapter } from './adapter';

interface EditTaskFormProps {
  task: PostListItem;
  usersList: User[];
  canDelete: boolean;
  handleOpenModal: () => void;
}

export function EditTaskForm({
  task,
  usersList,
  canDelete,
  handleOpenModal,
}: EditTaskFormProps): ReactElement {
  const [shouldReset, setShouldReset] = useState(false);

  const { t } = useTranslation();

  const { editPostMutation: editTaskMutation, isLoading } = useEditPostMutation({
    successText: t('toasts.task.success.edit'),
    invalidateQueries: ['task-details', task.id],
    setShouldReset,
  });

  const defaultValues = defaultValuesAdapter(task, usersList);

  const { updated_by, published_at, updated_at, id, author } = task;

  const updatedBy = updated_by
    ? getFullName({
        firstName: updated_by.first_name,
        lastName: updated_by.last_name,
      })
    : '';
  const updatedAt = updated_by ? updated_at : '';

  const publishedAt = published_at ? new Date(published_at) : '';
  const isCurrentYear = publishedAt && publishedAt.getFullYear() === new Date().getFullYear();
  const dateFormat = isCurrentYear ? TIME_DATE_MONTH_WITH_COMMA : TIME_DATE_FORMAT_WITH_COMMA;
  const publishDate = formattedDate(new Date(publishedAt), dateFormat);

  async function handleSubmit(data: Partial<TaskFormValues>, taskId: string) {
    await editTaskMutation({ post: data, postId: taskId });
  }

  return (
    <TaskForm
      mode="edit"
      defaultValues={defaultValues}
      draftId={id}
      handleSubmit={handleSubmit}
      isSubmitting={isLoading}
      updatedBy={updatedBy}
      updatedAt={updatedAt}
      createdAt={publishDate}
      author={author}
      usersList={usersList}
      canDelete={canDelete}
      handleOpenModal={handleOpenModal}
      shouldReset={shouldReset}
      setShouldReset={setShouldReset}
    />
  );
}
