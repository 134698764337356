import { styled } from '@mui/material';

export const FooterW = styled('div')`
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  white-space: break-spaces;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.p050};
`;
