import { ReactElement } from 'react';

import { EquipmentFormValues, PostDraft, User } from 'types';

import { EquipmentForm } from '../EquipmentForm';
import { useRequestEquipmentForm } from './hooks';

interface RequestEquipmentFormProps {
  draft?: PostDraft | null;
  usersList: User[];
  defaultValues: (isDefaultEditable: boolean, draft?: PostDraft) => EquipmentFormValues;
}

export function RequestEquipmentForm({
  draft,
  usersList,
  defaultValues,
}: RequestEquipmentFormProps): ReactElement {
  const { handleSubmit, isLoading } = useRequestEquipmentForm();

  return (
    <EquipmentForm
      isRequestForm
      draft={draft}
      isLoading={isLoading}
      usersList={usersList}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    />
  );
}
