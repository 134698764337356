import { ReactElement } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import {
  Backdrop,
  BaseTextFieldController,
  ConfirmDialog,
  Form,
  FormGroup,
  SelectController,
  SubmitRoundedButton,
} from 'components';
import { CUSTOM_LINK_ICONS as ICONS } from 'constants/index';
import { useAuth, useWindowSize } from 'hooks';
import { textFieldMobileStyles, textFieldStyles } from 'styles';
import { QuickLinkFormValues } from 'types';
import { required } from 'validations';

import { linkRules, LINK_FIELDS_LENGTH as RULES, titleRules } from './rules';
import {
  CheckboxContainer,
  FormContainer,
  InputW,
  LabelW,
  RemoveButtonW,
  SubmitBlock,
  SubmitBtnW,
} from './styled';
import { dropdownContainerSx, inputTypeSx, optionSx, selectMobileSx, selectSx } from './styles';
import { useQuickLinkForm } from './useQuickLinkForm';

interface QuickLinkFormProps {
  mode: 'create' | 'edit';
  handleSubmit: (data: QuickLinkFormValues) => void;
  defaultValues: QuickLinkFormValues;
  isSubmitting?: boolean;
  handleOpenModal?: () => void;
}

export function QuickLinkForm({
  defaultValues,
  mode,
  handleSubmit,
  isSubmitting,
  handleOpenModal,
}: QuickLinkFormProps): ReactElement {
  const { t } = useTranslation();
  const {
    isDirty,
    control,
    handleFormSubmit,
    isLoading,
    isOpen,
    handleSubmitConfirmModal,
    handleCloseConfirmModal,
  } = useQuickLinkForm({ defaultValues, handleSubmit, mode });

  const { isMobile } = useWindowSize();
  const { isManager } = useAuth();

  const loading = isLoading || isSubmitting;
  const formGroupMarginBottom = isMobile ? 8 : 24;
  const isEditMode = mode === 'edit';
  const fieldStyles = isMobile ? textFieldMobileStyles : { ...textFieldStyles, ...inputTypeSx };

  const iconsOptions = Object.keys(ICONS).map((name) => ({
    value: name,
    label: ICONS[name],
  }));
  const isCustomLink = ICONS[defaultValues.icon];

  const {
    field: { onChange, value: formValue },
  } = useController({
    name: 'access',
    control,
  });

  const value = typeof formValue === 'boolean' ? formValue : formValue === 'public';
  return (
    <>
      {loading && <Backdrop />}

      <FormContainer>
        <Form onSubmit={handleFormSubmit}>
          <FormGroup marginBottom={formGroupMarginBottom} row>
            <BaseTextFieldController
              name="title"
              control={control}
              placeholder={t('quickLinks.customLinkForm.title')}
              sx={fieldStyles}
              maxLength={RULES.title.max}
              rules={titleRules}
              preventSpaceOnStart
            />
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom} row>
            <BaseTextFieldController
              name="url"
              control={control}
              placeholder={t('quickLinks.customLinkForm.link')}
              sx={fieldStyles}
              maxLength={RULES.link.max}
              rules={linkRules}
              preventSpaceOnStart
            />
          </FormGroup>
          <FormGroup marginBottom={formGroupMarginBottom} row>
            <SelectController
              name="icon"
              control={control}
              placeholder={t('quickLinks.customLinkForm.icon')}
              options={iconsOptions}
              sx={isMobile ? selectMobileSx : selectSx}
              rules={required}
              optionSx={optionSx}
              disabled={isEditMode && !isCustomLink}
              dropdownContainerSx={dropdownContainerSx(isMobile)}
            />
          </FormGroup>

          {isEditMode && (
            <RemoveButtonW onClick={handleOpenModal} endIcon={<TrashIcon />}>
              {t('kpi.targetForm.removeButton')}
            </RemoveButtonW>
          )}

          <SubmitBlock>
            {isManager && (
              <CheckboxContainer>
                <InputW
                  type="checkbox"
                  name="access"
                  onChange={onChange}
                  checked={value}
                  value={value.toString()}
                />
                <LabelW>{t('quickLinks.customLinkForm.publicLink')}</LabelW>
              </CheckboxContainer>
            )}

            <SubmitBtnW>
              <SubmitRoundedButton disabled={isEditMode && !isDirty} />
            </SubmitBtnW>
          </SubmitBlock>
        </Form>
      </FormContainer>

      <ConfirmDialog
        subTitle={t('task.create.modal.exit')}
        title={t('task.create.modal.title')}
        open={isOpen}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmitConfirmModal}
      />
    </>
  );
}
