import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';

import { PostListItem } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { InViewItemRef, InstanceType, InstanceLevelType } from 'types';

interface UsePostsListQueryReturn {
  isLoading: boolean;
  posts: PostListItem[];
  lastItemRef: InViewItemRef;
  isFetchingNextPage: boolean;
  total: number;
  isEmptyList: boolean;
}

interface UsePostsListQueryProps {
  type: InstanceType[] | InstanceType;
  limit: number;
  offset?: number;
  level?: InstanceLevelType[];
  assigned?: string;
  tag?: string;
  include_reactions?: boolean;
}

export function usePostsListQuery({
  type,
  limit,
  offset,
  level,
  assigned,
  tag,
  include_reactions,
}: UsePostsListQueryProps): UsePostsListQueryReturn {
  const { ref, inView } = useInView();

  const [searchParams, setSearchParams] = useSearchParams();

  const postIdSearch = searchParams.get('postId');
  const limitFromSearch = searchParams.get('limit');

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery(
    ['posts', type, limit, offset, level, assigned, tag, include_reactions],
    ({ pageParam }) => {
      setSearchParams(pageParam);

      return API_HANDLERS.POST.GET_LIST({
        type,
        ...(limit && { limit: limitFromSearch || limit }),
        ...(offset && { offset }),
        ...(level && { level }),
        ...(assigned && { assigned }),
        ...(tag && { tag }),
        ...(include_reactions && { include_reactions }),
        ...(pageParam && pageParam),
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const total = lastPage.data.total;
        if (!total) return;
        const loadedItems = allPages.reduce((prev, curr) => prev + curr.data.data.length, 0);
        return total > loadedItems ? { offset: loadedItems, limit } : undefined;
      },
      onSuccess: () => {
        if (postIdSearch) {
          searchParams.delete('postId');
          searchParams.delete('limit');
          setSearchParams(searchParams);
        }
      },
    },
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage]);

  const isEmptyList = data?.pages.length === 1 && data?.pages[0].data.data === null;
  const postsList = isEmptyList ? [] : data?.pages.map((page) => page.data.data).flat();

  return {
    isLoading,
    posts: postsList || [],
    lastItemRef: ref,
    isFetchingNextPage,
    total: data?.pages[0].data.total || 0,
    isEmptyList,
  };
}
