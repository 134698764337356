import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useQuickLinksQuery } from 'api';
import { PreviewContainer, Spinner } from 'components';
import { useWindowSize } from 'hooks';
import { Links } from 'settings';
import { getArrayChunks } from 'utils';

import { LinksEmptyState, QuickLinkPreview } from '../../components';
import { ArrowLeft, ArrowRight, ArrowW, ListW, PreviewW, Wrapper } from './styled';

export function QuickLinksPreview(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, links } = useQuickLinksQuery();
  const { isMobile } = useWindowSize();
  const [chunk, setChunk] = useState(1);

  const ITEMS_LIMIT = isMobile ? 3 : 6;
  const chunks = getArrayChunks(links, ITEMS_LIMIT);
  const showLeftArrow = chunk > 1;
  const showRightArrow = chunk < Object.keys(chunks).length;

  function showNext() {
    setChunk((prev) => prev + 1);
  }

  function showPrevious() {
    setChunk((prev) => prev - 1);
  }

  const goToQuickLinksPage = () => {
    navigate(Links.we.quickLinks.index);
  };

  const previewContent =
    links.length > 0 ? (
      <PreviewW>
        {showLeftArrow && (
          <div>
            <ArrowLeft onClick={showPrevious} />
          </div>
        )}
        <Wrapper showLeftArrow={showLeftArrow} showRightArrow={showRightArrow}>
          <ListW>
            {chunks[chunk].map((link) => (
              <QuickLinkPreview key={link.id} {...link} />
            ))}
          </ListW>
        </Wrapper>
        {showRightArrow && (
          <ArrowW>
            <ArrowRight onClick={showNext} />
          </ArrowW>
        )}
      </PreviewW>
    ) : (
      <LinksEmptyState />
    );

  return (
    <PreviewContainer
      title={t('quickLinks.preview')}
      handleTitleClick={goToQuickLinksPage}
      addIconLink={Links.we.quickLinks.createLink}
      maximizeIconLink={Links.we.quickLinks.index}
      containerSx={{ padding: '16px' }}
    >
      {isLoading && <Spinner />}
      {previewContent}
    </PreviewContainer>
  );
}
