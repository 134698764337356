import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { GoalFormValues } from 'api';
import { useCreateGoalMutation } from 'api/mutations/useCreateGoalMutation';
import { StatusDisplayValue } from 'features/career/constants';
import { useCallbackPrompt } from 'hooks';
import { useFormAttachments } from 'hooks/useFormAttachments';
import { Attachment, GoalStatus, SelectOption, User } from 'types';
import { getFullName, objectsDifferences } from 'utils';

import { AllOption } from '../constants';

interface UseCreateGoalFormProps {
  usersList: User[];
}

interface UseCreateGoalFormReturn {
  control: Control<GoalFormValues>;
  isLoading: boolean;
  attachedImages: Attachment[];
  attachedDocs: Attachment[];
  attachedVideos: Attachment[];
  attachments?: Attachment[];
  isOpen: boolean;
  goalTitle: string;
  goalDescription: string;
  statusOptions: SelectOption[];
  assigneeOptions: SelectOption[];
  progress: number;
  goBack: () => void;
  clearDeadline: () => void;
  handleFormSubmit: () => void;
  handleAddImages: (files: FileList) => Promise<void>;
  handleAddDocuments: (files: FileList) => Promise<void>;
  handleAddVideos: (files: FileList) => Promise<void>;
  handleCloseConfirmModal: () => void;
  handleSubmitConfirmModal: () => void;
  handleDeleteAttachment: (attachmentId: string, type: 'images' | 'docs' | 'videos') => void;
  handleAssigneeChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function useCreateGoalForm({ usersList }: UseCreateGoalFormProps): UseCreateGoalFormReturn {
  const statusArray = Object.entries(StatusDisplayValue);
  const statusOptions = statusArray.map(([key, val]) => ({ label: val, value: key }));
  const { employeeId: userId = '', achievementId = '' } = useParams();

  const defaultValues = {
    title: '',
    description: '',
    status: GoalStatus.PENDING,
    images: [],
    docs: [],
    videos: [],
    assignee: [userId],
  } as GoalFormValues;
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, isDirty, isSubmitted, errors },
  } = useForm<GoalFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const attachedImages = watch('images');
  const attachedDocs = watch('docs');
  const attachedVideos = watch('videos');
  const goalTitle = watch('title');
  const goalDescription = watch('description');
  const assignee = watch('assignee');

  const filesChanges = objectsDifferences(
    { images: attachedImages, docs: attachedDocs, videos: attachedVideos },
    {
      images: defaultValues.images,
      docs: defaultValues.docs,
      videos: defaultValues.videos,
    },
  );

  const navigate = useNavigate();

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    (isDirty || Object.keys(filesChanges).length > 0) && !isSubmitted,
  );

  const { createGoal, isLoading: isCreateGoalLoading } = useCreateGoalMutation({
    successText: t('toasts.goal.successCreate'),
    onSuccessAction: () => setIsSuccess(true),
  });

  const attachments =
    [...attachedDocs, ...attachedImages, ...attachedVideos].length > 0
      ? [...attachedDocs, ...attachedImages, ...attachedVideos]
      : undefined;

  const {
    handleAddImages,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    isLoadingFileUpload,
    progress,
  } = useFormAttachments({
    setFormValue: setValue,
    attachedDocs,
    attachedImages,
    attachedVideos,
  });

  const handleFormSubmit = handleSubmit(async ({ assignee, ...data }) => {
    if (data.deadline && typeof data.deadline !== 'string') {
      const deadline = data.deadline as unknown as Date;
      const utcDeadline = new Date(
        Date.UTC(deadline?.getFullYear(), deadline?.getMonth(), deadline?.getDate()),
      );
      data = {
        ...data,
        deadline: utcDeadline.toISOString(),
      };
    }

    const attachment_ids = [...attachedImages, ...attachedDocs, ...attachedVideos].map(
      (file) => file.id,
    );
    const isAllSelected = assignee?.length === 1 && assignee.includes(AllOption.value);
    const assigned_to_ids = isAllSelected ? undefined : assignee;
    const assigned_type = isAllSelected ? 'all' : 'users';

    await createGoal({
      userId: userId as string,
      achievementId: achievementId as string,
      data: {
        ...data,
        attachment_ids,
        assigned_to_ids,
        assigned_type,
      },
    });
  });

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  function clearDeadline() {
    setValue('deadline', null, { shouldDirty: true });
  }

  async function handleSubmitConfirmModal() {
    confirmNavigation();
  }

  useEffect(() => {
    if (isSubmitted && Object.keys(errors).length === 0 && isSuccess) {
      goBack();
    }
  }, [isSubmitted, goBack, errors, isSuccess]);

  const assigneeOptions = useMemo(
    () =>
      usersList.map((user) => ({
        value: user.id,
        label:
          user.firstName && user.firstName
            ? getFullName({ firstName: user.firstName, lastName: user.lastName })
            : user.email,
        disabled: defaultValues.assignee?.includes(user.id) && !assignee?.includes(AllOption.value),
      })),
    [assignee, defaultValues.assignee, usersList],
  );

  const handleAssigneeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;

      if (!!value && value.length > 1) {
        if (value?.at(-1) === AllOption.value) {
          setValue('assignee', [AllOption.value]);
        } else if (value.includes(AllOption.value)) {
          setValue('assignee', [
            ...(value as unknown as string[]).filter(
              (value) => value !== userId && value !== AllOption.value,
            ),
            userId,
          ]);
        }
      } else {
        setValue('assignee', [userId]);
      }
    },
    [setValue, userId],
  );

  return {
    control,
    isLoading: isSubmitting || isCreateGoalLoading || isLoadingFileUpload,
    attachments,
    attachedImages,
    attachedDocs,
    attachedVideos,
    isOpen: showPrompt,
    goalTitle,
    goalDescription,
    statusOptions: [...statusOptions],
    assigneeOptions: [AllOption, ...assigneeOptions],
    progress,
    clearDeadline,
    goBack,
    handleAddImages,
    handleFormSubmit,
    handleAddDocuments,
    handleAddVideos,
    handleDeleteAttachment,
    handleSubmitConfirmModal,
    handleCloseConfirmModal: cancelNavigation,
    handleAssigneeChange,
  };
}
