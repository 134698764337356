import { useTranslation } from 'react-i18next';

import { VisionFormPayload } from 'types';

import { useCreateVisionMutation } from '../../hooks';

export function useCreateVisionForm() {
  const { t } = useTranslation();

  const { createVisionMutation, isLoading } = useCreateVisionMutation({
    successText: t('toasts.vision.success.create'),
  });

  async function handleSubmit(data: VisionFormPayload) {
    await createVisionMutation(data);
  }

  const defaultValues = { vision: '' };

  return {
    handleSubmit,
    isLoading,
    defaultValues,
  };
}
