import { queryClient, useDeletePostMutation } from 'api';
import { QUERIES } from 'constants/index';
import { useModal } from 'hooks';

export const useDocumentActions = (documentId: string) => {
  const { handleOpenModal, handleCloseModal, isOpen: isOpenDeleteModal } = useModal();

  const { deletePostMutation } = useDeletePostMutation(() => {
    queryClient.invalidateQueries([QUERIES.DOCUMENTS_LIST]);
  });

  const onDeleteDocument = async () => {
    await deletePostMutation({ postId: documentId });
    handleCloseModal();
  };

  return {
    isOpenDeleteModal,
    handleCloseDeleteModal: handleCloseModal,
    handleOpenDeleteModal: handleOpenModal,
    onDeleteDocument,
  };
};
