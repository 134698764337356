import { styled } from '@mui/material';

export const TabsW = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const Tab = styled('div')`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  opacity: 0.4;
  &.active {
    opacity: 1;
    color: ${({ theme }) => theme.palette.primary.p080};
  }
`;
