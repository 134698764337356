import { RegisterCompanyParams } from 'api';
import { CompanyInfoFormValues, CompanyPersonalDataFormValues, CompanySize } from 'types';
import { removeExtraSpaces, getUserLanguage } from 'utils';

type RegisterRequestPayloadAdapterProps = CompanyInfoFormValues & CompanyPersonalDataFormValues;

export function registerRequestPayloadAdapter({
  email,
  password,
  industry,
  firstName,
  lastName,
  companyName,
  companySize,
  number,
}: RegisterRequestPayloadAdapterProps): RegisterCompanyParams {
  const companySizeValues = Object.values(CompanySize);

  return {
    email: removeExtraSpaces(email),
    password: removeExtraSpaces(password),
    industry: removeExtraSpaces(industry),
    first_name: removeExtraSpaces(firstName),
    last_name: removeExtraSpaces(lastName),
    name: removeExtraSpaces(companyName),
    size: companySizeValues[companySize],
    lang: getUserLanguage(),
    company_number: removeExtraSpaces(number),
  };
}
