import { AxiosResponseSuccess, ClientConfig } from 'api/types';

import { makeHttpRequest } from './utils';

export function getData<SuccessPayload>(
  url: string,
  config?: ClientConfig,
): AxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'GET', ...config });
}

export function postData<SuccessPayload>(
  url: string,
  config?: ClientConfig,
): AxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'POST', ...config });
}

export function putData<SuccessPayload>(
  url: string,
  config?: ClientConfig,
): AxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'PUT', ...config });
}

export function deleteData<SuccessPayload>(
  url: string,
  config?: ClientConfig,
): AxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'DELETE', ...config });
}

export function patchData<SuccessPayload>(
  url: string,
  config?: ClientConfig,
): AxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'PATCH', ...config });
}
