import { Attachment, PostDraft } from 'types';

interface DefaultValuesAdapterProps {
  draft: PostDraft;
}

interface DefaultValuesAdapterReturn {
  title: string;
  description: string;
  images: Attachment[];
  docs: Attachment[];
  videos: Attachment[];
}

export function defaultValuesAdapter({
  draft,
}: DefaultValuesAdapterProps): DefaultValuesAdapterReturn {
  const imagesList = draft.attachments?.filter((file) => file.type === 'image') || [];
  const documentsList = draft.attachments?.filter((file) => file.type === 'document') || [];
  const videosList = draft.attachments?.filter((file) => file.type === 'video') || [];

  return {
    title: '',
    description: '',
    images: imagesList,
    docs: documentsList,
    videos: videosList,
  };
}
