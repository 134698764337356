import { t } from 'i18next';

import { ReactComponent as PollIcon } from 'assets/icons/actionsPage/chart.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/actionsPage/clipboardTickYellow.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/actionsPage/documentText.svg';
import { ReactComponent as EquipmentsIcon } from 'assets/icons/actionsPage/equipments.svg';
import { ReactComponent as PostIcon } from 'assets/icons/actionsPage/gallery.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/actionsPage/notificationCustom.svg';
import { ReactComponent as EmployeeIcon } from 'assets/icons/actionsPage/profileAdd.svg';
import { ReactComponent as OffboardingIcon } from 'assets/icons/actionsPage/profileRemove.svg';
import AdligoLogo from 'assets/images/startLogo.png';
import { Links } from 'settings';

import { LogoImage } from './styled';

export const ACTIONS_CONFIG = (isManager: boolean) => {
  return {
    POST: {
      label: t(isManager ? 'actions.manager.labels.post' : 'actions.employee.labels.post'),
      icon: <PostIcon />,
      link: Links.actions.createPost,
    },
    EMPLOYEE: {
      label: t(
        isManager ? 'actions.manager.labels.onboarding' : 'actions.employee.labels.employee',
      ),
      icon: <EmployeeIcon />,
      link: Links.actions.employeesInvite,
    },
    ...(isManager && {
      OFFBOARDING: {
        label: t('actions.manager.labels.offboarding'),
        icon: <OffboardingIcon />,
        link: Links.actions.offboarding,
      },
    }),
    DOCUMENT: {
      label: t(isManager ? 'actions.manager.labels.document' : 'actions.employee.labels.document'),
      icon: <DocumentIcon />,
      link: Links.actions.createDocument,
    },
    TASK: {
      label: t(isManager ? 'actions.manager.labels.task' : 'actions.employee.labels.task'),
      icon: <TaskIcon />,
      link: Links.actions.createTask,
    },
    ...(isManager && {
      SUBSCRIPTIONS: {
        label: t('actions.manager.labels.subscriptions'),
        icon: <LogoImage src={AdligoLogo} alt="logo" />,
        link: Links.actions.subscriptions,
      },
    }),
    ...(isManager && {
      NOTIFICATIONS: {
        label: t('actions.manager.labels.notifications'),
        icon: <NotificationIcon />,
        link: Links.actions.createNotification,
      },
    }),
    EQUIPMENTS: {
      label: t('actions.manager.labels.equipments'),
      icon: <EquipmentsIcon />,
      link: Links.actions.createEquipment,
    },
    POLL: {
      label: t(isManager ? 'actions.manager.labels.poll' : 'actions.employee.labels.poll'),
      icon: <PollIcon />,
      link: Links.actions.createPoll,
    },
  };
};
