import { PostListItem } from 'api';

import { Attachment } from './attachment';

export interface TaskFormValues {
  title: string;
  description: string;
  images: Attachment[];
  docs: Attachment[];
  videos: Attachment[];
  assignee?: string[];
  deadline?: string | null;
  status: string; // todo | in progress
}

export enum TaskStatus {
  to_do = 'to_do',
  in_progress = 'in_progress',
  to_approve = 'to_approve',
  done = 'done',
}

export interface Task {
  status: TaskStatus;
  deadline?: string;
  created_at: string;
  updated_at?: string;
}

export interface TaskListItem {
  id: string;
  title: PostListItem['title'];
  assignee?: PostListItem['assigned_to'];
  deadline?: PostListItem['task']['deadline'];
  description?: PostListItem['description'];
  comments_count?: PostListItem['comments_count'];
}
