import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/icons/closeNote.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg';
import { useSubscription, useWindowSize } from 'hooks';
import { Links } from 'settings';

import { getTimeLeftText } from './getTimeLeftText';
import { ExpiresInW, FlexW, LinkW, NotificationBar, ExpiresInMainW } from './styled';

interface FreeTrialNotificationProps {
  expiresAt?: string;
}

export function FreeTrialNotification({
  expiresAt = '',
}: FreeTrialNotificationProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { closeNotification } = useSubscription();

  const { text, days } = getTimeLeftText(expiresAt);

  return (
    <NotificationBar>
      <FlexW>
        <NotificationIcon />
        <ExpiresInMainW>
          {days ? (
            <ExpiresInW>{t(text, { count: days })}</ExpiresInW>
          ) : (
            <ExpiresInW>{t(text)}</ExpiresInW>
          )}
          {isMobile && (
            <LinkW to={Links.actions.subscriptions}>{t('freeTrial.manageSubscriptionsLink')}</LinkW>
          )}
        </ExpiresInMainW>
      </FlexW>

      <FlexW className={isMobile ? 'positionTop' : ''}>
        {!isMobile && (
          <LinkW to={Links.actions.subscriptions}>{t('freeTrial.manageSubscriptionsLink')}</LinkW>
        )}
        <IconButton sx={isMobile ? { paddingTop: '0px' } : {}} onClick={closeNotification}>
          <CloseIcon />
        </IconButton>
      </FlexW>
    </NotificationBar>
  );
}
