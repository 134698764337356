import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CUSTOM_LINK_ICONS } from 'constants/index';
import { QuickLink as IQuickLink, QuickLinkAccess } from 'types';

import { PREVIEW_LINKS_ICONS } from '../../constants';
import { CardW, IconW, LinkW, TitleW, SubtitleW } from './styled';

interface QuickLinkProps extends IQuickLink {
  isEditable?: boolean;
}

export function QuickLinkPreview({ title, icon, url, access }: QuickLinkProps): ReactElement {
  const { t } = useTranslation();
  const ICONS = { ...PREVIEW_LINKS_ICONS, ...CUSTOM_LINK_ICONS };

  const isPublicLink = access === QuickLinkAccess.public;

  return (
    <LinkW href={url} target="_blank">
      <CardW>
        <IconW>{ICONS[icon]}</IconW>
        <TitleW>{title}</TitleW>
        {isPublicLink && <SubtitleW>{t('quickLinks.publicLink')}</SubtitleW>}
      </CardW>
    </LinkW>
  );
}
