import { DAYS_ALLOWED_FOR_CANCEL } from '../constants';

interface CheckIsCancelableProps {
  months?: number;
  days?: number;
  years?: number;
}

export function checkIsCancelable({ months = 0, days = 0, years = 0 }: CheckIsCancelableProps) {
  if (months > 0 || years > 0) {
    return true;
  }

  if (days >= DAYS_ALLOWED_FOR_CANCEL) {
    return true;
  }

  return false;
}
