import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

import { QUERIES } from '../../constants';

interface UseDeleteLinkMutationReturn {
  deleteLinkMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    string,
    unknown
  >;
  isLoading: boolean;
}

export function useDeleteLinkMutation(): UseDeleteLinkMutationReturn {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutateAsync: deleteLinkMutation, isLoading } = useMutation(
    API_HANDLERS.QUICK_LINKS.DELETE_LINK,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.QUICK_LINKS_LIST]);
        alert.success(t('toasts.quickLinks.success.delete'));
        navigate(-1);
      },
    },
  );

  return {
    deleteLinkMutation,
    isLoading,
  };
}
