import { Box, styled, Typography } from '@mui/material';

import { theme } from 'styles';

export const IconW = styled(Box)`
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
`;

export const LabelW = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.primary.p080};
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const listItemStyles = {
  p: '7px 5px',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '8px',
  color: `${theme.palette.primary.p080}`,
  opacity: '0.4',

  '.MuiTypography-root': {
    color: theme.palette.primary.p080,
  },

  '&.active': {
    opacity: '1',
    color: theme.palette.info.main,
    '.MuiAvatar-root': {
      backgroundColor: theme.palette.info.main,
    },
    '.MuiTypography-root': {
      color: theme.palette.primary.p080,
    },
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: '0px',
  },
};
