import { styled, Typography } from '@mui/material';

export const FormContainer = styled('div')`
  width: 671px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 32px 24px;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px 8px;
    margin-bottom: 110px;
    &.editMode {
      margin-bottom: 0px;
    }
  }
`;

export const FormFooter = styled('div')`
  display: flex;
`;

export const FormTitle = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
`;
