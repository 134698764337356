import { styled } from '@mui/material';

export const ContainerW = styled('div')`
  height: 8px;
  width: 100%;
  background: #f3f4f6;
  border-radius: 8px;
`;

export const Filler = styled('div')<{ value: number; color: string }>(({ value, color }) => ({
  height: '100%',
  width: `${value}%`,
  backgroundColor: color,
  borderRadius: 'inherit',
}));
