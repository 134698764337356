import { ReactNode } from 'react';

import { Attachment } from './attachment';

export enum EquipmentCategory {
  HARDWARE = 'hardware',
  SOFTWARE = 'software',
  LICENSE = 'license',
  SUBSCRIPTION = 'subscription',
  PERMISSION = 'permission',
  CERTIFICATION = 'certification',
  COMPETENCE = 'competence',
}

export enum EquipmentStatus {
  ACTIVE = 'active',
  REQUESTED = 'requested',
}

export interface EquipmentPayload {
  id: string;
  user_id: string;
  author_id: string;
  name: string;
  category: EquipmentCategory;
  status: EquipmentStatus;
  expire_at: string;
  ordered_at: string;
  serial: string;
  comment: string;
  is_editable: boolean;
  custom_fields?: CustomFieldItem[];
  attachments: Attachment[];
  attachment_order: string[];
  assigned_to_id?: string;
}

export interface Equipment {
  id: string;
  userId: string;
  authorId: string;
  name: string;
  category: EquipmentCategory;
  status: EquipmentStatus;
  expireAt: string;
  orderedAt: string;
  serial: string;
  comment: string;
  isEditable: boolean;
  customFields?: CustomFieldItem[];
  attachments: Attachment[];
  attachmentOrder: string[];
  assignedToId?: string;
}

export type EquipmentsTableFields = {
  name: ReactNode;
  expiryDate: string;
  orderedOn: string;
  serial: string;
  attachments: ReactNode;
  actions: ReactNode;
};

export type EquipmentsMobileTableFields = {
  name: ReactNode;
  expiryDate: string;
  orderedOn: string;
  serial: string;
  attachments: ReactNode;
  actions: ReactNode;
};

export interface RegisterEquipmentRequest {
  name: string;
  category: EquipmentCategory;
  expire_at?: string;
  ordered_at?: string;
  serial: string;
  comment: string;
  is_editable: boolean;
  custom_fields?: CustomFieldItem[];
  attachment_ids?: string[];
}

export interface CustomFieldItem {
  key: string;
  value: string;
}

export interface EquipmentFormValues {
  title: string;
  category: string;
  user: string;
  assignee?: string;
  expireAt: string;
  orderedAt: string;
  serial: string;
  comment: string;
  isEditable: boolean;
  images: Attachment[];
  docs: Attachment[];
  videos: Attachment[];
  customFields?: CustomFieldItem[];
}

export interface EquipmentFilterFormValues {
  category: EquipmentCategory | 'all';
}

export enum EquipmentTabsValues {
  REGISTER = 'register',
  REQUEST = 'request',
}

export interface EquipmentTab {
  label: string;
  value: EquipmentTabsValues;
}

export interface RequestEquipmentRequest {
  name: string;
  category: EquipmentCategory;
  assigned_to_id: string;
  expire_at?: string;
  ordered_at?: string;
  serial: string;
  comment: string;
  is_editable: boolean;
  custom_fields?: CustomFieldItem[];
  attachment_ids?: string[];
}
