import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { DeleteEquipmentRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

import { QUERIES } from '../../constants';

interface UseDeleteEquipmentMutationProps {
  onSuccessHandler?: () => void;
}

interface UseDeleteEquipmentMutationReturn {
  deleteEquipmentMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    DeleteEquipmentRequestParams,
    unknown
  >;
  isLoading: boolean;
}

export function useDeleteEquipmentMutation({
  onSuccessHandler,
}: UseDeleteEquipmentMutationProps): UseDeleteEquipmentMutationReturn {
  const { mutateAsync: deleteEquipmentMutation, isLoading } = useMutation(
    API_HANDLERS.EQUIPMENTS.DELETE,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.EQUIPMENTS_LIST]);
        onSuccessHandler?.();
      },
    },
  );

  return {
    deleteEquipmentMutation,
    isLoading,
  };
}
