import { styled, Typography, Link } from '@mui/material';

export const ContainerW = styled('div')`
  width: 100%;
`;

export const DetailsCard = styled('div')`
  margin-top: 28px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  position: relative;
  @media (max-width: 1023px) {
    padding: 16px;
    margin-top: 16px;
  }
`;

export const DescriptionW = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.p080};
  overflow: hidden;
  white-space: break-spaces;
  word-wrap: break-word;

  & a {
    color: ${({ theme }) => theme.palette.info.main};
  }

  & p {
    margin: 12px 0;

    &:has(code) {
      margin: 8px 0;
    }
  }

  & h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    font-size: inherit;
  }
`;

export const AttachmentsW = styled('div')`
  margin-top: 17px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 8px;
  @media (max-width: 1023px) {
    gap: 4px;
    margin-top: 12px;
  }
`;

export const AuthorName = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;

export const FooterW = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  padding-left: 22px;
  @media (max-width: 1023px) {
    margin-top: 13px;
    padding-left: 31px;
  }
`;

export const DateTime = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p030};
  margin-left: auto;
`;

export const LinkW = styled(Link)`
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
  text-decoration-color: ${({ theme }) => theme.palette.info.main};
`;

export const WelcomeImage = styled('img')`,
  height: 118px;
  width: 118px;
  display: block;
  margin-top: 17px;
  @media (max-width: 1023px) {
    height: 87px;
    width: 87px;
    margin-top: 12px;
  }
`;
export const BirthdayImage = styled(WelcomeImage)``;

export const TargetW = styled('div')`
  margin: 24px 0 16px;
`;

export const PollDescription = styled('div')`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.p080};
`;
