import { styled, Typography } from '@mui/material';

export const ContentW = styled('div')`
  position: relative;
  height: 526px;
  width: 472px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  overflow: hidden;

  @media (max-width: 1023px) {
    width: auto;
    max-width: 450px;
  }

  @media (max-width: 520px) {
    width: auto;
  }
`;

export const TitleW = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 16px;
  background: #f5f5f5;
`;

export const FooterW = styled('div')`
  height: 56px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px -4px 20px rgba(0, 76, 189, 0.08);
  border-radius: 0px 0px 16px 16px;

  a {
    color: ${({ theme }) => theme.palette.info.main};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ListW = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  height: 410px;
  padding: 0 16px;
  background: #f5f5f5;
`;
