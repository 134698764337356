import { ReactElement, useState } from 'react';

import { useCurrentSubscriptionQuery } from 'api';
import { Spinner, TopMenu } from 'components';
import { EmployeeFullDetails } from 'features/employeeDetails';
import { LanguageSelector } from 'features/languageSelector';
import { useAuth, useSubscription } from 'hooks';

import { PageW, ToggleW } from './styled';

export function ProfilePage(): ReactElement {
  const { user } = useAuth();
  const { isTrial } = useSubscription();
  const [isLoading, setIsLoading] = useState(false);
  useCurrentSubscriptionQuery();

  return (
    <PageW>
      {user && (
        <div>
          <ToggleW>
            <LanguageSelector spinnerSwitcher={() => setIsLoading(true)} />
            <TopMenu />
          </ToggleW>
          {isTrial && isLoading ? <Spinner /> : <EmployeeFullDetails employeeId={user.id} />}
        </div>
      )}
    </PageW>
  );
}
