import { Button, Menu } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSessionState } from 'hooks';

import { actionsOptions } from './constants';
import { Wrapper, StyledArrowIcon, ActionItem } from './styled';
import { popoverStyles, buttonStyles } from './styles';

export function ActionsSelect(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state] = useSessionState('i18nextLng');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;

  return (
    <Wrapper>
      <Button onClick={handleClick} sx={buttonStyles} endIcon={<StyledArrowIcon isOpen={open} />}>
        {t('we.posts.createButton')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 56,
          horizontal: state === 'en' ? -320 : -298,
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: popoverStyles,
          id,
        }}
      >
        {actionsOptions.map(({ label, link, icon }) => (
          <ActionItem key={label} onClick={() => navigate(link)}>
            {icon}
            {label}
          </ActionItem>
        ))}
      </Menu>
    </Wrapper>
  );
}
