import { useMutation, UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { AddAttachmentResponse, AddVideoAttachmentRequestParams } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UseUploadAttachmentReturn {
  isLoading: boolean;
  uploadVideoAttachmentMutation: UseMutateAsyncFunction<
    AxiosResponse<AddAttachmentResponse, unknown>,
    unknown,
    AddVideoAttachmentRequestParams,
    unknown
  >;
}

export function useVideoUploadAttachment(): UseUploadAttachmentReturn {
  const { mutateAsync: uploadVideoAttachmentMutation, isLoading } = useMutation(
    API_HANDLERS.ATTACHMENTS.VIDEO_UPLOAD,
  );

  return { uploadVideoAttachmentMutation, isLoading };
}
