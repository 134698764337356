import { ReactElement } from 'react';

import { QuickLinkForm } from '../QuickLinkForm';
import { defaultValues } from './constants';
import { useCreateLink } from './useCreateLink';

export function CreateQuickLinkForm(): ReactElement {
  const { handleSubmit, isLoading } = useCreateLink();

  return (
    <QuickLinkForm
      mode="create"
      handleSubmit={handleSubmit}
      isSubmitting={isLoading}
      defaultValues={defaultValues}
    />
  );
}
