import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { API_HANDLERS } from 'api/apiHandlers';

interface UseChangeAvatarMutationReturn {
  changeAvatarMutation: UseMutateAsyncFunction<AxiosResponse, unknown, FormData, unknown>;
  isLoading: boolean;
}

export const useChangeAvatarMutation = (): UseChangeAvatarMutationReturn => {
  const { mutateAsync: changeAvatarMutation, isLoading } = useMutation(
    API_HANDLERS.USER.CHANGE_AVATAR,
    {
      onSuccess: () => {
        window.location.reload();
      },
    },
  );

  return {
    changeAvatarMutation,
    isLoading,
  };
};
