import { SxProps, Theme } from '@mui/material';

import { GoalStatus } from 'types';

export const styles: Record<GoalStatus, SxProps<Theme>> = {
  [GoalStatus.ACCOMPLISHED]: {
    backgroundColor: 'rgba(0, 211, 47, 0.10)',
    color: '#0AA62D',
  },
  [GoalStatus.BLOCKED]: {
    backgroundColor: 'rgba(242, 0, 0, 0.10)',
    color: '#F20000',
  },
  [GoalStatus.IN_PROGRESS]: {
    backgroundColor: 'rgba(255, 153, 0, 0.10)',
    color: '#EF9100',
  },
  [GoalStatus.PENDING]: {
    backgroundColor: 'rgba(0, 114, 227, 0.10)',
    color: '#006BD4',
  },
};
