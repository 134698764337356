import { ConfirmRegistrationParams } from 'api';
import { SignUpFormValues } from 'types';

export function confirmSignUpRequestAdapter({
  firstName,
  lastName,
  countryCode,
  password,
  phone,
}: SignUpFormValues): ConfirmRegistrationParams['user'] {
  // email is not passed
  return {
    first_name: firstName,
    last_name: lastName,
    country_code: Number(countryCode),
    phone,
    password,
  };
}
