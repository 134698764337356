import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useCategoriesListQuery } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { PreviewContainer, Spinner } from 'components';
import { LAST_UPDATED_DOCUMENTS_LIMIT, QUERIES } from 'constants/index';
import { useWindowSize } from 'hooks';
import { Links } from 'settings';
import { CategoriesListType, InstanceLevelType, InstanceType } from 'types';

import { EmptyState, DocumentListItem } from '../../components';
import { ListW } from './styled';

export function LastUpdatedDocuments(): ReactElement {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const queryParams = {
    type: InstanceType.document,
    level: [InstanceLevelType.public],
    limit: LAST_UPDATED_DOCUMENTS_LIMIT,
  };

  const { data, isLoading } = useQuery(
    [QUERIES.LAST_UPDATED_DOCS],
    () => API_HANDLERS.POST.GET_LIST(queryParams),
    {
      cacheTime: 0,
    },
  );

  const {
    categoryMap,
    isLoading: isCategoryListLoading,
  }: { categoryMap: CategoriesListType; isLoading: boolean } = useCategoriesListQuery();

  const total = data?.data.total;
  const documents = data?.data.data || [];

  const containerHeight = total === 0 ? '342px' : '356px';
  const mobileHeight = '632px';
  const height = width < 768 ? mobileHeight : containerHeight;

  const goToPublicDocsPage = () => {
    navigate(Links.we.publicDocuments);
  };

  const handleViewMoreClick = (tags: string[]) => {
    navigate(generatePath(Links.we.publicDocuments), {
      state: {
        tags,
      },
    });
  };

  return (
    <PreviewContainer
      title={t('we.lastUpdatedDocuments.title')}
      handleTitleClick={goToPublicDocsPage}
      addIconLink={Links.we.createDocument}
      maximizeIconLink={Links.we.publicDocuments}
      containerSx={{ height }}
    >
      {(isLoading || isCategoryListLoading) && <Spinner />}

      {!(isLoading || isCategoryListLoading) && total === 0 && <EmptyState />}

      {documents.length > 0 && (
        <ListW>
          {documents.map((document) => (
            <DocumentListItem
              key={document.id}
              document={document}
              handleViewMoreClick={handleViewMoreClick}
              categoryMap={categoryMap}
            />
          ))}
        </ListW>
      )}
    </PreviewContainer>
  );
}
