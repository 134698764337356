import { TIME_DATE_FORMAT_WITH_COMMA } from 'constants/index';
import { formattedDate } from 'utils';

interface GetDateValueProps {
  isUpdated: boolean;
  published_at: string;
  updated_at: string;
}

export function getDateValue({ isUpdated, published_at, updated_at }: GetDateValueProps): string {
  const date = isUpdated ? updated_at : published_at;
  const dateValue = formattedDate(new Date(date), TIME_DATE_FORMAT_WITH_COMMA);

  return dateValue;
}
