import { useCallback, useEffect, useRef, useState } from 'react';

import { CreatePostDraftResponse, useCreateDraftMutation } from 'api';

interface UseCreateDraftReturn {
  isLoading: boolean;
  draft: CreatePostDraftResponse | null;
}

export function useCreateDraft(isEnabled = true): UseCreateDraftReturn {
  const [isLoading, setIsLoading] = useState(true);
  const [draft, setDraft] = useState<CreatePostDraftResponse | null>(null);

  const { createDraftMutation } = useCreateDraftMutation();

  const isLoaded = useRef(false);

  const createDraft = useCallback(async () => {
    const { data: draftData } = await createDraftMutation();

    setDraft(draftData);
    setIsLoading(false);
  }, [createDraftMutation]);

  useEffect(() => {
    if (!isLoaded.current && isEnabled) {
      createDraft();
      isLoaded.current = true;
      return;
    }
    setIsLoading(false);
  }, [createDraft, isEnabled]);

  return { draft, isLoading };
}
