import { styled } from '@mui/material';

export const ListW = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (max-width: 1023px) {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    & > div {
      margin-right: 15px;
    }
  }
`;
