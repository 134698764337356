import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsSelect } from '../ActionsSelect';
import { BarW, Text } from './styled';

export function CreatePostActionBar(): ReactElement {
  const { t } = useTranslation();

  return (
    <BarW>
      <Text>{t('we.posts.mobileTitle')}</Text>
      <ActionsSelect />
    </BarW>
  );
}
