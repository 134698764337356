import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, NavLink, useParams } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { Spinner } from 'components';
import { useWindowSize } from 'hooks';
import { Links } from 'settings';
import { Equipment, EquipmentsTableFields, EquipmentTabsValues } from 'types/equipments';

import { useEquipmentFilter } from '../../hooks';
import { CategoryFilter } from '../CategoryFilter';
import { DEFAULT_EQUIPMENTS_FIELDS, MOBILE_EQUIPMENTS_FIELDS } from '../config';
import { initialWindowSizeProps } from '../constants';
import { EmptyState } from '../EmptyState';
import { ContainerW, ActionsW, TitleW, TitleIconW, AddButtonW, SpinnerW } from '../styled';
import { Table } from '../Table';
import { useRequestedEquipmentTable } from './hooks/useRequestedEquipmentTable';

interface RequestedEquipmentsContainerProps {
  isSwedish: boolean;
  onTrashIconClick: (equipment: Pick<Equipment, 'id' | 'name'>) => void;
}

export function RequestedEquipmentsContainer({
  isSwedish,
  onTrashIconClick,
}: RequestedEquipmentsContainerProps): ReactElement {
  const { employeeId = '' } = useParams<string>();
  const { category, control } = useEquipmentFilter();
  const { isLoading, equipmentList, isFilterShown } = useRequestedEquipmentTable({
    userId: employeeId,
    category,
    isSwedish,
    onTrashIconClick,
  });
  const { isMobile } = useWindowSize(initialWindowSizeProps);
  const { t } = useTranslation();

  const requestEquipmentPath = generatePath(Links.profile.equipmentList.createEquipment, {
    employeeId,
  });

  const requestEquipmentLink = {
    pathname: requestEquipmentPath,
    search: `?tab=${EquipmentTabsValues.REQUEST}`,
  };

  const webColumns = DEFAULT_EQUIPMENTS_FIELDS;
  const mobileColumns = MOBILE_EQUIPMENTS_FIELDS;

  return (
    <>
      <ContainerW isListData={!!equipmentList.length}>
        <TitleW>{t('equipments.request.title')}</TitleW>

        <ActionsW>
          {!isMobile && isFilterShown && <CategoryFilter control={control} />}

          <AddButtonW>
            <TitleIconW>{t('kpi.add')}</TitleIconW>
            <NavLink to={requestEquipmentLink}>
              <IconButton>
                <AddIcon style={{ opacity: '0.6' }} />
              </IconButton>
            </NavLink>
          </AddButtonW>
        </ActionsW>

        {isMobile && isFilterShown && <CategoryFilter control={control} />}

        {isLoading && (
          <SpinnerW>
            <Spinner />
          </SpinnerW>
        )}

        {!isLoading && !equipmentList.length && <EmptyState isRequestedState />}

        {!isLoading && !!equipmentList.length && (
          <Table<EquipmentsTableFields, string>
            isLoading={isLoading}
            columns={isMobile ? mobileColumns : webColumns}
            data={equipmentList}
          />
        )}
      </ContainerW>
    </>
  );
}
