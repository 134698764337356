import { styled } from '@mui/material';

export const StyledTagLabel = styled('div')`
  padding: 8px 16px 8px 8px;

  background: rgba(0, 155, 242, 0.2);
  border-radius: 20px;
  rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

export const LabelW = styled('div')<{ type?: string }>`
  width: ${({ type }) => (type ? 'unset' : '85%')};
  min-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: ${({ type }) => type && '#B3E1FB'};
  border-radius: ${({ type }) => type && '20px'};
  padding: ${({ type }) => type && '4px 8px'};
  font-weight: ${({ type }) => type && '400'};
  font-size: ${({ type }) => type && '24px'};
  line-height: ${({ type }) => type && '32px'};

  @media (max-width: 1023px) {
    font-size: ${({ type }) => type && '16px'};
    line-height: ${({ type }) => type && '24px'};
    max-width: ${({ type }) => type && '190px'};
  }
`;

export const IconLabelW = styled('div')`
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;
