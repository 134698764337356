import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { QUERIES } from 'constants/index';
import { TargetFormValues } from 'types';

interface UseEditTargetMutationReturn {
  editTargetMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    TargetFormValues,
    unknown
  >;
  isLoading: boolean;
}

interface UseEditTargetMutationProps {
  successText: string;
  targetId: string;
}

export function useEditTargetMutation({
  successText,
  targetId,
}: UseEditTargetMutationProps): UseEditTargetMutationReturn {
  const { mutateAsync: editTargetMutation, isLoading } = useMutation(API_HANDLERS.KPI.EDIT_TARGET, {
    onSuccess: () => {
      alert.success(successText);
      Promise.all([
        queryClient.invalidateQueries([QUERIES.TASKS_LIST]),
        queryClient.invalidateQueries([QUERIES.TARGET_DETAILS, targetId]),
      ]);
    },
  });

  return {
    editTargetMutation,
    isLoading,
  };
}
