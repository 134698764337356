import { generatePath, useNavigate } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { ReactComponent as EditIcon } from 'assets/icons/containerActions/edit.svg';
import { DocumentCategoryIcons } from 'constants/DocumentCategoryIcons';
import { useWindowSize, useAuth } from 'hooks';
import { Links } from 'settings';
import { DocumentCategory } from 'types';

import {
  CardW,
  TitleW,
  TitleText,
  DocumentCategoryHeaderW,
  DocumentCategoryActionW,
} from './styled';

type DocumentCategoryItemTypes = {
  isExpanded: boolean;
  toggleExpand: () => void;
} & DocumentCategory;

export const DocumentCategoryItem = ({
  id,
  label,
  icon,
  description,
  tag,
  isExpanded,
  toggleExpand,
}: DocumentCategoryItemTypes) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { isManager } = useAuth();

  const onClickTag = () => {
    if (isMobile && !isExpanded) {
      toggleExpand();
      return;
    }

    navigate(
      generatePath(Links.resources.documents.tag, {
        tag,
      }),
      {
        state: {
          tag,
        },
      },
    );
  };

  const onClickEditResource = () => {
    navigate(
      generatePath(Links.resources.edit.categoryId, {
        categoryId: id,
      }),
    );
  };

  const onClickAddDocument = () => {
    navigate(generatePath(Links.resources.createTaggedDocument.tagged.tag, { tag }));
  };

  const Icon = DocumentCategoryIcons[icon];
  return (
    <CardW onClick={onClickTag} className={!isExpanded ? 'collapsed' : ''}>
      <DocumentCategoryHeaderW>
        <TitleW>
          <span>{Icon}</span>
          {(!isMobile || isExpanded) && <TitleText>{label}</TitleText>}
        </TitleW>
        {(isExpanded || (!isMobile && label !== 'Untagged')) && (
          <DocumentCategoryActionW>
            {isManager && (
              <EditIcon
                onClick={(e) => {
                  e.stopPropagation();
                  onClickEditResource();
                }}
                style={{ opacity: '0.4' }}
              />
            )}

            <AddIcon
              onClick={(e) => {
                e.stopPropagation();
                onClickAddDocument();
              }}
              style={{ opacity: '0.4' }}
            />
          </DocumentCategoryActionW>
        )}
      </DocumentCategoryHeaderW>
      {!isMobile && (
        <div
          dangerouslySetInnerHTML={{
            __html: description || '',
          }}
          style={{
            marginTop: '10px',
            fontSize: '12px',
            lineHeight: '14px',
          }}
        />
      )}{' '}
    </CardW>
  );
};
