import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { EditGoalRequestParams, queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';

interface UseEditGoalMutationReturn {
  editGoalMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    EditGoalRequestParams,
    unknown
  >;
  isLoading: boolean;
}

interface UseEditGoalMutationProps {
  invalidateQueries?: string[];
  onSuccessAction?: () => void;
}

export function useEditGoalMutation({
  invalidateQueries,
  onSuccessAction,
}: UseEditGoalMutationProps): UseEditGoalMutationReturn {
  const { mutateAsync: editGoalMutation, isLoading } = useMutation(
    API_HANDLERS.ACHIEVEMENTS.EDIT_GOAL,
    {
      onSuccess: async () => {
        invalidateQueries && queryClient.invalidateQueries(invalidateQueries);
        onSuccessAction?.();
      },
    },
  );

  return {
    editGoalMutation,
    isLoading,
  };
}
