import { RegisterOptions } from 'react-hook-form';

import { POLL_FORM_FIELDS_LENGTH } from 'constants/index';
import { getMaxLengthText, getMinLengthText, required } from 'validations';

export const titleFieldRules: RegisterOptions = {
  ...required,
  minLength: {
    value: POLL_FORM_FIELDS_LENGTH.title.min,
    message: getMinLengthText(POLL_FORM_FIELDS_LENGTH.title.min),
  },
  maxLength: {
    value: POLL_FORM_FIELDS_LENGTH.title.max,
    message: getMaxLengthText(POLL_FORM_FIELDS_LENGTH.title.max),
  },
};

export const descriptionFieldRules: RegisterOptions = {
  ...required,
  minLength: {
    value: POLL_FORM_FIELDS_LENGTH.description.min,
    message: getMinLengthText(POLL_FORM_FIELDS_LENGTH.description.min),
  },
  maxLength: {
    value: POLL_FORM_FIELDS_LENGTH.description.max,
    message: getMaxLengthText(POLL_FORM_FIELDS_LENGTH.description.max),
  },
};

export const optionFieldRules: RegisterOptions = {
  minLength: {
    value: POLL_FORM_FIELDS_LENGTH.option.min,
    message: getMinLengthText(POLL_FORM_FIELDS_LENGTH.option.min),
  },
  maxLength: {
    value: POLL_FORM_FIELDS_LENGTH.option.max,
    message: getMaxLengthText(POLL_FORM_FIELDS_LENGTH.option.max),
  },
};

export const visibilityFieldRules = {
  ...required,
};
