import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'assets/icons/containerActions/addSquare.svg';
import { ReactComponent as MaximizeIcon } from 'assets/icons/containerActions/maximize.svg';

import { ActionsW } from './styled';

interface AddAndExtendActionsProps {
  addIconLink?: string;
  maximizeIconLink?: string;
  addLinkState?: unknown;
  maximizeLinkState?: unknown;
}

export function AddAndExtendActions({
  addIconLink,
  maximizeIconLink,
  addLinkState,
  maximizeLinkState,
}: AddAndExtendActionsProps): ReactElement {
  return (
    <ActionsW>
      {addIconLink && (
        <NavLink to={addIconLink} state={addLinkState}>
          <IconButton>
            <AddIcon style={{ opacity: '0.4' }} />
          </IconButton>
        </NavLink>
      )}
      {maximizeIconLink && (
        <NavLink to={maximizeIconLink} state={maximizeLinkState}>
          <IconButton>
            <MaximizeIcon style={{ opacity: '0.4' }} />
          </IconButton>
        </NavLink>
      )}
    </ActionsW>
  );
}
