import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'hooks';
import { SubscriptionsPeriod } from 'types';

import { ButtonsW, ButtonW, ContentW, SaveLabel } from './styled';

interface PeriodSwitcherProps {
  period: SubscriptionsPeriod;
  setPeriod: (period: SubscriptionsPeriod) => void;
}

export function PeriodSwitcher({ period, setPeriod }: PeriodSwitcherProps): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  function setYear() {
    setPeriod(SubscriptionsPeriod.year);
  }

  function setMonth() {
    setPeriod(SubscriptionsPeriod.month);
  }

  return (
    <ContentW>
      <ButtonsW>
        <ButtonW
          variant="contained"
          left
          isActive={period === SubscriptionsPeriod.month}
          onClick={setMonth}
        >
          {t('subscriptions.togglePlans.monthly')}
        </ButtonW>
        <ButtonW
          variant="contained"
          right
          isActive={period === SubscriptionsPeriod.year}
          onClick={setYear}
        >
          {t('subscriptions.togglePlans.yearly')}
        </ButtonW>
        {!isMobile && (
          <SaveLabel isYear={period === SubscriptionsPeriod.year} onClick={setYear}>
            {t('subscriptions.togglePlans.saveLabel')}
          </SaveLabel>
        )}
      </ButtonsW>
      {isMobile && (
        <SaveLabel isYear={period === SubscriptionsPeriod.year} onClick={setYear}>
          {t('subscriptions.togglePlans.saveLabel')}
        </SaveLabel>
      )}
    </ContentW>
  );
}
