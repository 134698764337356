import { styled } from '@mui/material';

export const Thumb = styled('div')`
  height: 56px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  flex: 1 0 56px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);

  &:hover {
    .remove-btn {
      display: flex;
    }
  }
`;

export const StyledImage = styled('img')`
  width: 100%;
  // height: 56px;
  object-fit: cover;
`;

export const MoreImages = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.white.main};
`;
