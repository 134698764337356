import { Button, styled } from '@mui/material';

export const CardW = styled('div')`
  margin-right: 24px;
  padding: 31px 36px 17px 36px;
  width: 541px;
  border-radius: 15px;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
`;

export const ActionsW = styled('div')`
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const ButtonW = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.primary.p080};
  margin-right: 4px;
`;
